import { isEmpty } from "lodash";

export function getStringValue(data) {
  if (isEmpty(data)) return "";
  return `${data} `;
}

export const ALLOWED_HISTORY_STATUSES_ADMINISTRATIVE = ["PROPOSED", "APPROVED", "COMPLETE", "DENIED", "CANCELLED", "EXPIRED"];

export const ALLOWED_HISTORY_STATUSES_BATCH = ["PROPOSED", "APPROVED", "COMPLETE"];

export const TRANSFER_DETAILS_COMPONENTS = {
  CLUSTER_DETAILS: "clusterDetails",
  BATCH_DETAILS: "batchDetails",
  REPLENISHMENT_CLUSTER_DETAILS: "clusterDetailsReplenishment",
};
