import useAuth from 'hooks/useAuth';
import {useEffect, useContext} from 'react';
import { useDispatch } from 'react-redux';
import {UNSAFE_NavigationContext as NavigationContext} from 'react-router-dom'
import { removeAccessToken } from 'store/authSlice';
import { resetUserState } from 'store/userHomeSlice';

export function useBlocker(blocker, when) {
	const {navigator} = useContext(NavigationContext)
	const { signOut } = useAuth();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!when) return;

		const unblock = navigator.block((tx) => {
			// If not, apply the custom blocker logic
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock();
					tx.retry();
					if(tx.location.pathname === '/') {
						signOut();
						removeAccessToken();
						dispatch(resetUserState());
					}
				},
			};
			blocker(autoUnblockingTx);
		});
		return () => {
			unblock();
		};
	}, [when, navigator]);
}

export function useUnloadConfirmationDialog(message) {
	// useEffect(() => {
	// 	const handleBeforeUnload = (e) => {
	// 		console.log("in reload")
	// 		e.preventDefault();
	// 		e.returnValue = message; // Set the custom message
	// 	};
	//
	// 	// window.addEventListener('beforeunload', handleBeforeUnload);
	//
	// 	return () => {
	// 		// window.removeEventListener('beforeunload', handleBeforeUnload);
	// 	};
	// }, [message]);
}

export default useUnloadConfirmationDialog;
