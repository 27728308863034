import { Box, Typography } from "@mui/material";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  defaultORGID,
  enLanguage,
  formatDate,
  formatDateTime,
  getAllLabels,
  t,
} from "utils/constants";
import { useTranslation } from "react-i18next";
import Style from "./ApplicationChecklist.style";
import SiteLogo from "../../../../assets/images/logo.png";

const PageName = "Page_ApplicationChecklist";

export const ApplicationChecklist = React.forwardRef((props, ref) => {
  const { t: t1 } = useTranslation(PageName);
  const { entityId, lastUpdated } = props;
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);

  return (
    <Box ref={ref} id="applicationChecklist">
      <Box sx={Style.mainBox}>
        <Box sx={Style.header}>
          <Typography sx={Style.headertext}>{formatDateTime(new Date(), "/")}</Typography>
          <Typography sx={Style.headerMiddleText}>{t(t1, "REGISTRY_ACCOUNT_APPLICATION_CHECKLIST")}</Typography>
        </Box>
        <Box>
          <Box sx={Style.heading}>
            <img src={SiteLogo} alt="logo" width="115" height="75" />
          </Box>
          <Box sx={Style.contentBox}>
            <Typography sx={Style.contentBoxTitle}>
              {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_TITLE")}
            </Typography>
            <Typography sx={Style.contentBoxTitleText}>
              {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_DESCRIPTION")}
            </Typography>
            <Typography my={2}>
              <strong>{t(t1, "CITSS_ENTITY_ID")}:</strong>&nbsp;{entityId}
            </Typography>
            <Typography sx={Style.checklistDescription}>
              {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_DESCRIPTION_2")}
            </Typography>
            <Typography sx={Style.checklistItem}>
              <Typography sx={Style.checkIcon}>❏</Typography> {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_INSTRUCTIONS_1")}
            </Typography>
            <Typography sx={Style.checklistItem}>
              <Typography sx={Style.checkIcon}>❏</Typography> {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_INSTRUCTIONS_2")}
            </Typography>
            <Typography sx={Style.checklistItemIndented}>
              <Typography sx={Style.checkIcon}>❏</Typography> {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_INSTRUCTIONS_3")}
            </Typography>
            <Typography sx={Style.checklistItem}>
              <Typography sx={Style.checkIcon}>❏</Typography> {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_INSTRUCTIONS_4")}
            </Typography>
            <Typography sx={Style.checklistItemIndented}>
              <Typography sx={Style.checkIcon}>❏</Typography> {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_INSTRUCTIONS_5")}
            </Typography>
            <Typography sx={Style.checklistItemIndented}>
              <Typography sx={Style.checkIcon}>❏</Typography> {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_INSTRUCTIONS_6")}
            </Typography>
            <Typography sx={Style.checklistItem}>
              <Typography sx={Style.checkIcon}>❏</Typography> {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_INSTRUCTIONS_7")}
            </Typography>
            <Typography sx={Style.lastRevised}>
              {`${t(t1, "ACCOUNT_APPLICATION_CHECKLIST_LAST_REVISION")}: ${formatDate(new Date(lastUpdated), "/")}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
