import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { setModelMessage, setResponseID } from "store/commonSlice";
import { getCAGGroupLists, setRevertFalse, setDeletedCAGMember } from "store/entityProfileSlice";
import {
  APIS,
  COMPONENT_NAMES, COMPONENT_NAME_FOR_ROLE, ENTITY_CHANGES_ACTIONS, ENTITY_SECTION_TYPES, ENTITY_TYPE_NAMES,
  ERROR, isEmpty,
  JURISDICTIONS_NAMES, RESPONSE_MESSAGE_KEYS, SUCCESS, SUCCESSFULLY_SAVED, t,
  handleEntityStatusError
} from "utils/constants";
import { fetcherDelete, fetcherGet, fetcherPost, fetcherPut, localFetcherPut } from "utils/fetcher";
import logger from "utils/logger";
import { getIdentifiers, getOrganizationID, getStateCountryID, mapGetFacility, processProposedChangeFacilityData, sanityPermissionPostPutData } from "utils/prepareData.js";
import { setLoader } from "./commonSlice";
import { getFacilityOwnerHistory, getFacilities } from "./entityProfileSlice";

const defaultSteps = [
  {
    label: "Select Jurisdiction",
    key: "SELECT_JURISDICTION",
    pageTitle: "SELECT_JURISDICTION",
    nextPage: "SELECT_ENTITY_TYPE",
    stepNumber: 1,
    selected: true,
    submitted: false,
    filledData: {},
    isEditField: false,
    editMode: true,
    link: true,
    skip: false,
  },
  {
    label: "Select Entity Type",
    key: "SELECT_ENTITY_TYPE",
    pageTitle: "SELECT_ENTITY_TYPE",
    nextPage: "ENTITY_INFORMATION",
    stepNumber: 2,
    selected: false,
    submitted: false,
    filledData: {},
    isEditField: false,
    link: true,
    skip: false,
  },
  {
    label: "Enter Entity Information",
    key: "ENTER_ENTITY_INFORMATION",
    pageTitle: "ENTER_ENTITY_INFORMATION",
    nextPage: "ENTITY_INFORMATION",
    stepNumber: 3,
    selected: false,
    link: false,
    submitted: false,
    filledData: {},
    isEditField: false,
    skip: false,
    items: ["ENTITY_INFORMATION", "CONTACT_INFORMATION", "ADD_FACILITIES", "ADD_REPRESENTATIVES", "AUCTION"],
  },
  {
    subStep: true,
    label: "Entity Information",
    key: "ENTITY_INFORMATION",
    pageTitle: "ENTER_ENTITY_INFORMATION",
    nextPage: "CONTACT_INFORMATION",
    stepNumber: 3,
    selected: false,
    submitted: false,
    filledData: {},
    isEditField: false,
    link: true,
    skip: false,
  },
  {
    subStep: true,
    label: "Contact Information",
    key: "CONTACT_INFORMATION",
    pageTitle: "ENTER_ENTITY_INFORMATION",
    nextPage: "ADD_FACILITIES",
    stepNumber: 3,
    selected: false,
    submitted: false,
    filledData: {},
    isEditField: false,
    link: true,
    skip: false,
  },
  {
    subStep: true,
    label: "Add Facilities",
    key: "ADD_FACILITIES",
    pageTitle: "ENTER_ENTITY_INFORMATION",
    nextPage: "ADD_REPRESENTATIVES",
    stepNumber: 3,
    selected: false,
    submitted: false,
    filledData: [],
    allData: [],
    isEditField: false,
    link: true,
    skip: true,
  },
  {
    subStep: true,
    label: "Add Representatives",
    key: "ADD_REPRESENTATIVES",
    pageTitle: "ENTER_ENTITY_INFORMATION",
    nextPage: "AUCTION",
    stepNumber: 3,
    selected: false,
    submitted: false,
    filledData: {},
    isEditField: false,
    link: true,
    skip: false,
  },
  {
    subStep: true,
    label: "Auction",
    key: "AUCTION",
    pageTitle: "ENTER_ENTITY_INFORMATION",
    nextPage: "REVIEW_AND_SUBMIT",
    stepNumber: 3,
    selected: false,
    submitted: false,
    filledData: {},
    isEditField: false,
    link: true,
    skip: false,
  },
  {
    label: "Review and Submit",
    key: "REVIEW_AND_SUBMIT",
    pageTitle: "REVIEW_AND_SUBMIT",
    nextPage: "SUBMIT_REQUIRED_DOCUMENTS",
    stepNumber: 4,
    selected: false,
    submitted: false,
    filledData: {},
    isEditField: false,
    link: true,
    skip: false,
  },
  {
    label: "Submit Required Documents",
    key: "SUBMIT_REQUIRED_DOCUMENTS",
    pageTitle: "SUBMIT_REQUIRED_DOCUMENTS",
    selected: false,
    stepNumber: 5,
    submitted: false,
    filledData: {},
    isEditField: false,
    link: true,
    skip: false,
  },
];

const initialState = {
  steps: defaultSteps,
  EntitiesData: {
    data: [],
    metaData: [],
    fieldAccess: []
  },
  SingleEntityData: [],
  selectedJurisdiction: [],
  selectedJurisdictionID: null,
  jurisdictions: [],
  selectedEntity: null,
  enableSaveBtnOnReview: true,
  Entities: [],
  Purpose: [],
  UserClassification: [],
  SelectedClassification: [],
  Country: [],
  FacilityCountry: [],
  Facilities: [],
  FacilitiesList: {},
  Facility: [],
  StateProvince: [],
  StateProvinceforMailing: [],
  StateProvinceFacility: [],
  StateProvinceFacilityforMailing: [],
  StateProvinceIdentifiers: [],
  Organization: [],
  SelectedOrganization: [],
  isProfileEdit: false,
  Roles: [],
  SelfInformation: [],
  EntityId: [],
  Representative: [],
  ProposedRepresentative: [],
  originalRep: [],
  RepresentativesByGET: [],
  RepresentativeOnLocal: [],
  ProposedRepresentatives: [],
  NAICS: [],
  AlternateContact: {},
  EntityIsAuction: false,
  RemoveFacility: {},
  IdentifierCountry: "",
  JuryDisable: false,
  RevisionRequestedComment: "",
  isLocked: null,
  ShortEntityID: [],
  EditAccess: false,
  FacilityAddMode: true,
  RepresentativeStatusHistory: {},
  showCagMembers: [],
  updatedRetiredCagMembers: [],
  catchNavigate: false,
  RecheckDuplicateAR: null,
  CancelAlternateContact: null,
  resetAlternateContactForm: null,
  fieldPermissions: [],
  entityTypeChangeHistory: null,
  nameChangeHistory: null,
  handleRepReset: false,
  errorOnAddRepresentativeForm: false,
  generalAccordion: false,
  contactInfoAccordion: false,
  facilityAccordion: false,
  ARAccordion: false,
  loadingJurisdictions: false,
  lockResponse: [],
  columnPLError: false,
  columnHLError: false,
  disabledFacilitySubmitBtn: null,
  maillingBtnDisable: false,
  lockIntervalId: null,
};

const entityApplicationSlice = createSlice({
  name: "entityApplication",
  initialState,
  reducers: {
    setEntities: (state, action) => {
      state.EntitiesData = action.payload;
    },
    resetEntitiesData: (state, action) => {
      state.EntitiesData = { ...initialState.EntitiesData };
    },
    setSingleFacility: (state, action) => {
      state.SingleEntityData = action.payload;
    },
    setAlternateContact: (state, action) => {
      state.AlternateContact = action.payload;
    },
    setFilledData: (state, action) => {
      // state.formSteps.filledData[action.payload.step] = action.payload.data;
      const newArr = state.steps.map((item) => {
        if (item.stepNumber === action.payload.step) {
          item.filledData = action.payload.data;
        }
        return item;
      });
    },
    setSelectedStep: (state, action) => {
      const newSteps = state.steps.map((item) => {
        item.selected = false;
        if (item.key === action.payload.key) {
          item.selected = true;
        }
        return item;
      });
      state.steps = newSteps;
    },

    setSelectedStepByStepNumber: (state, action) => {
      const newSteps = state.steps.map((item, index) => {
        item.selected = false;
        if (item.key === action.payload) {
          item.selected = true;
        }
        return item;
      });
      state.steps = newSteps;
    },
    
    setSubmittedOfSelectedStep: (state, action) => {
      let newSteps = state.steps.map((item) => {
        if (item.selected) {
          item.submitted = action.payload;
        }
        return item;
      });

      const condition = isEmpty(newSteps.filter((item) => (item.pageTitle === "ENTER_ENTITY_INFORMATION" && item.key !== "ENTER_ENTITY_INFORMATION" && !item.submitted && !item.skip)));

      if (condition) {
        newSteps = newSteps.map((item) => {
          if (item.key === "ENTER_ENTITY_INFORMATION") {
            item.submitted = true;
          }
          return item;
        })
      }

      state.steps = newSteps;
    },
    setFormValueOfStep: (state, action) => {
      const newSteps = state.steps.map((item) => {
        if (item.selected) {
          item.filledData = action.payload;
        }
        return item;
      });
      state.steps = newSteps;
    },
    setFormValueSubmittedOfStep: (state, action) => {
      const newSteps =  [...state.steps];
      newSteps.find(step => step.key === action.payload.page).submitted = action.payload.isSubmitted
      state.steps = newSteps;
    },
    setFormValueOfStepByStepName: (state, action) => {

      let newSteps = state.steps.map((item) => {
        if (item.key === action.payload.stepName) {
          item.filledData = action.payload.data;
          if (!isEmpty(item.filledData)) {
            if (item?.filledData?.representative) {
              if (!Object.keys(item.filledData.representative).length) {
                item.submitted = false;
              } else {
                item.submitted = true;
              }
            } else if (item.key === 'ENTITY_INFORMATION') {
              if (state.selectedEntity === 'GENERAL_MARKET_PARTICIPANT_ORGANIZATION' && isEmpty(item.filledData.organisationType) && isEmpty(state.SelectedOrganization)) {
                item.submitted = false;
                item.forceEnable = true;
              } else {
                item.submitted = true;
              }
            } else if (item.key === "AUCTION") {
              if (item?.filledData?.isAuction) {
                item.submitted = true;
              } else if (item?.filledData?.isAuction === false) {
                item.submitted = true;
              } else {
                item.submitted = false;
              }
            } else {
              item.submitted = true;
            }
          } else {
            item.submitted = false;
          }
        }
        return item;
      });

      const condition = isEmpty(newSteps.filter((item) => (item.pageTitle === "ENTER_ENTITY_INFORMATION" && item.key !== "ENTER_ENTITY_INFORMATION" && !item.submitted && !item.skip)));

      if (condition) {
        newSteps = newSteps.map((item) => {
          if (item.key === "ENTER_ENTITY_INFORMATION") {
            item.submitted = true;
          }
          return item;
        })
      }

      state.steps = newSteps;

      // below logit is to mark step 3 done based on substep values

      const arr = ["ENTITY_INFORMATION", "CONTACT_INFORMATION", "ADD_FACILITIES", "ADD_REPRESENTATIVES", "AUCTION"];

      let entityInformationStep = true;
      state.steps.map((item) => {
        if (arr.includes(item.key) && !item.skip && !item.submitted) {
          entityInformationStep = false
        }
        return item
      })
      newSteps = state.steps.map((item) => {
        if (item.key === "ENTER_ENTITY_INFORMATION") item.submitted = entityInformationStep;
        return item;
      })

      state.steps = newSteps;
    },
    setEntityIsAuction: (state, action) => {
      state.EntityIsAuction = action.payload;
    },
    setHandleRepReset: (state, action) => {
      state.handleRepReset = action.payload;
    },
    setRetireSingleFacility: (state, action) => {
      state.RemoveFacility = action.payload;
    },
    resetSteps: (state) => {
      state.steps = defaultSteps;
    },
    setNextStepSelect: (state, action) => {
      const alereadySelected = [...action.payload.steps].find((item) => item.selected);

      const getNextStep = (steps, nextPageObj) => {
        let found = steps.find((step) => step.key === nextPageObj.nextPage);
        if (found && found.skip) {
          found = getNextStep(steps, found);
        }
        return found;
      };

      const nextStep = getNextStep([...action.payload.steps], alereadySelected);

      const newSteps = state.steps.map((item) => {
        item.selected = false;
        if (item.key === nextStep.key) {
          item.selected = true;
        }
        return item;
      });
      state.steps = newSteps;
    },
    setNextActiveStep: (state) => {
      if (state.formSteps.activeStep === 1) {
        state.formSteps.activeStep += 2;
      } else {
        state.formSteps.activeStep += 1;
      }
    },
    updateEditFieldState: (state, action) => {
      state.formSteps.isEditField[action.payload.step] = action.payload.value;
    },
    setSubmittedState: (state, action) => {
      state.formSteps.submitted[action.payload.step] = action.payload.data;
    },
    setJurisdictions: (state, action) => {
      state.jurisdictions = action.payload;
    },
    setJurisdiction: (state, action) => {
      state.selectedJurisdiction = action.payload;
    },
    setJurisdictionId: (state, action) => {
      state.selectedJurisdictionID = action.payload;
    },
    setEnableSaveBtnOnReview: (state, action) => {
      state.enableSaveBtnOnReview = action.payload;
    },
    setEntityTypes: (state, action) => {
      state.Entities = action.payload;
    },
    setEntityType: (state, action) => {
      state.selectedEntity = action.payload;
    },
    setEntityID: (state, action) => {
      state.EntityId = action.payload;
    },
    setPurpose: (state, action) => {
      state.Purpose = action.payload;
    },
    setClassification: (state, action) => {
      state.UserClassification = action.payload;
    },
    setFacilityCountry: (state, action) => {
      state.FacilityCountry = action.payload;
    },
    setCountry: (state, action) => {
      state.Country = action.payload;
    },
    setStateProvince: (state, action) => {
      state.StateProvince = action.payload;
    },
    setStateProvinceIdentifiers: (state, action) => {
      state.StateProvinceIdentifiers = action.payload;
    },
    setStateProvinceforMailing: (state, action) => {
      state.StateProvinceforMailing = action.payload;
    },
    setFacilities: (state, action) => {
      state.Facilities = action.payload;
    },
    setFacilitiesList: (state, action) => {
      state.FacilitiesList = action.payload;
    },
    setSelectedFacility: (state, action) => {
      state.Facility = action.payload;
    },
    setStateProvinceFacility: (state, action) => {
      state.StateProvinceFacility = action.payload;
    },
    setStateProvinceFacilityforMailing: (state, action) => {
      state.StateProvinceFacilityforMailing = action.payload;
    },

    setOrganization: (state, action) => {
      state.Organization = action.payload;
    },
    setSelectedOrganization: (state, action) => {
      state.SelectedOrganization = action.payload;
    },
    setSelectedClassification: (state, action) => {
      state.SelectedClassification = action.payload;
    },
    setJurisdictionProfileEdit: (state, action) => {
      state.isProfileEdit = action.payload;
    },
    setRoles: (state, action) => {
      state.Roles = action.payload;
    },
    setSelfInformation: (state, action) => {
      state.SelfInformation = action.payload;
    },
    setProposedRepresentatives: (state, action) => {
      state.Representative = [...state.Representative, ...action.payload];
    },
    setProposedRepresentativeRoleByIndex: (state, action) => {
      const list = [...state.Representative];
      const item = action?.payload?.id ? list.find(rep => rep.id === action?.payload?.id) : list[action?.payload?.index]
      if (item) {
        item.representativesRole = action.payload.value;
        item.role = action?.payload?.role?.id
        // item.role.name = action?.payload?.role?.name
        item.isEdited = true;
      }
      state.Representative = list;
    },
    removeProposedRepresentativeByIndex: (state, action) => {
      let localRepresentatives = [...state.RepresentativeOnLocal]
      localRepresentatives = localRepresentatives.filter((rep) => rep?.userReferenceCode !== action?.payload?.userReferenceCode)
      const newList = [...state.Representative];
      newList.splice(action.payload.index, 1);
      state.Representative = newList;
      state.RepresentativeOnLocal = localRepresentatives;
    },
    setRepresentative: (state, action) => {
      if (action?.payload) {
        state.Representative = [...action.payload, ...state.RepresentativeOnLocal];
      } else {
        state.Representative = [];
      }
    },
    setOriginalRep: (state, action) => {
      state.originalRep = action.payload
    },
    resetRepresentative: (state, action) => {
      if (action?.payload) {
        state.Representative = [...action.payload];
      } else {
        state.Representative = [];
      }
    },
    setRepresentativesByGET: (state, action) => {
      state.RepresentativesByGET = action.payload;
    },
    setRepresentativeOnLocal: (state, action) => {
      state.RepresentativeOnLocal = action.payload;
    },
    setNAICS: (state, action) => {
      state.NAICS = action.payload;
    },
    updateEntityEditFieldState: (state, action) => {
      const allSteps = state.steps.map((item) => {
        if (item.key === action.payload.stepName) {
          item.editMode = action.payload.value;
        }
        return item;
      });
      state.steps = allSteps;
    },
    setSkipStepByKey: (state, action) => {
      const allSteps = state.steps.map((item) => {
        if (item.key === action.payload.stepName) {
          item.skip = action.payload.skip;
        }
        return item;
      });
      state.steps = allSteps;
    },
    setIdentifierCountry: (state, action) => {
      state.IdentifierCountry = action.payload;
    },
    setJuryDisable: (state, action) => {
      state.JuryDisable = action.payload;
    },
    setRevisionComment: (state, action) => {
      state.RevisionRequestedComment = action.payload;
    },
    setIsLocked: (state, action) => {
      state.isLocked = action.payload;
      state.EntitiesData.data.isLocked = action.payload;
    },
    setShortEntityID: (state, action) => {
      state.ShortEntityID = action.payload;
    },
    setEditAccess: (state, action) => {
      state.EditAccess = action.payload;
    },
    setFacilityAddMode: (state, action) => {
      state.FacilityAddMode = action.payload;
    },
    setRepresentativeStatusHistory: (state, action) => {
      if (action?.payload?.data) {
        state.RepresentativeStatusHistory = action.payload;
      } else {
        state.RepresentativeStatusHistory = [];
      }
    },
    setCagMembers: (state, action) => {
      state.showCagMembers = action?.payload;
    },
    setUpdatedRetiredCagMembers: (state, action) => {
      state.updatedRetiredCagMembers = action?.payload || [];
    },
    setCatchNavigate: (state, action) => {
      state.catchNavigate = action?.payload;
    },
    setRecheckDuplicateAR: (state, action) => {
      state.RecheckDuplicateAR = action?.payload;
    },
    setCancelAlternateContact: (state, action) => {
      state.CancelAlternateContact = action?.payload;
    },
    setResetAlternateContactForm: (state, action) => {
      state.resetAlternateContactForm = action?.payload;
    },
    setFieldPermissions: (state, action) => {
      state.fieldPermissions = action?.payload
    },
    setEntityTypeChangeHistory: (state, action) => {
      state.entityTypeChangeHistory = action?.payload;
    },
    setNameChangeHistory: (state, action) => {
      state.nameChangeHistory = action?.payload;
    },
    setErrorOnAddRepresentativeForm: (state, action) => {
      state.errorOnAddRepresentativeForm = action.payload
    },
    setGeneralAccordion: (state, action) => {
      state.generalAccordion = action.payload
    },
    setcontactInfoAccordion: (state, action) => {
      state.contactInfoAccordion = action.payload
    },
    setFacilityAccordion: (state, action) => {
      state.facilityAccordion = action.payload
    },
    setARAccordion: (state, action) => {
      state.ARAccordion = action.payload
    },
    setLoadingJurisdictions: (state, action) => {
      state.loadingJurisdictions = action.payload
    },
    setLockResponse: (state, action) => {
      state.lockResponse = action.payload
    },
    setHLColumnError: (state, action) => {
      state.columnHLError = action?.payload;
    },
    setPLColumnError: (state, action) => {
      state.columnPLError = action?.payload;
    },
    setDisabledFacilitySubmitBtn: (state, action) => {
      state.disabledFacilitySubmitBtn = action?.payload;
    },
    setDisabledCAGDetailsSubmitBtn: (state, action) => {
      state.disabledCAGDetailsSubmitBtn = action?.payload;
    },
    setMaillingBtnDisable: (state, action) => {
      state.maillingBtnDisable = action.payload;
    },
    setChangeProposedRepresentatives: (state, action) => {
      state.ProposedRepresentative = [...action.payload];
    },
    setFacilityLoader: (state, action) => {
      state.facilityLoader = action.payload;
    },
    setLockIntervalId: (state, action) => {
      state.lockIntervalId = action.payload;
    },
  },
});

export default entityApplicationSlice.reducer;

export function getJurisdictions() {
  return (dispatch) => {
    dispatch(setLoadingJurisdictions(true));
    fetcherGet(APIS.JURISDICTIONS)
      .then((response) => {
        dispatch(setJurisdictions(response.data));
        dispatch(setLoadingJurisdictions(false));
      })
      .catch((error) => {
        logger({ error, name: "getJurisdictions" });
        dispatch(setLoadingJurisdictions(false));
      });
  };
}

export function getEntities() {
  return (dispatch) => {
    fetcherGet(APIS.ENTITY_TYPES, {})
      .then((response) => {
        dispatch(setEntityTypes(response.data));
      })
      .catch((error) => {
        logger({ error, name: "getEntities" });
      });
  };
}

export function getPurpose() {
  return (dispatch) => {
    return fetcherGet(APIS.PORPOSES, {})
      .then((response) => {
        dispatch(setPurpose(response.data));
      })
      .catch((error) => {
        logger({ error, name: "getPurpose" });
      });
  };
}

export function getUserClassification() {
  return (dispatch) => {
    return fetcherGet(APIS.CLASIFICATIONS, {})
      .then((response) => {
        dispatch(setClassification(response.data));
      })
      .catch((error) => {
        logger({ error, name: "getUserClassification" });
      });
  };
}

export function getFacilityCountry() {
  return (dispatch) => {
    fetcherGet(APIS.FACILITY_COUNTRIES, {})
      .then((response) => {
        dispatch(setFacilityCountry(response.data));
      })
      .catch((error) => {
        logger({ error, name: "getCountry" });
      });
  };
}

export function getCountry() {
  return (dispatch) => {
    return fetcherGet(APIS.ENTITY_COUNTRIES, {})
      .then((response) => {
        dispatch(setCountry(response.data));
      })
      .catch((error) => {
        logger({ error, name: "getCountry" });
      });
  };
}

export function getStateProvince() {
  return (dispatch) => {
    fetcherGet(APIS.ENTITY_STATES, {})
      .then((response) => {
        dispatch(setStateProvince(response.data));
        dispatch(setStateProvinceforMailing(response.data));
        dispatch(setStateProvinceFacility(response.data));
        dispatch(setStateProvinceFacilityforMailing(response.data));
        dispatch(setStateProvinceIdentifiers(response.data))
        return response.data;
      })
      .catch((error) => {
        logger({ error, name: "getStateProvince" });
      });
  };
}

export function getOrganization() {
  return (dispatch) => {
    return fetcherGet(APIS.ORGANIZATION_TYPES, {})
      .then((response) => {
        dispatch(setOrganization(response.data));
      })
      .catch((error) => {
        logger({ error, name: "getOrganization" });
      });
  };
}

export function getRoles() {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherGet(APIS.GET_ROLES, {})
      .then((response) => {
        dispatch(setRoles(response.data));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        logger({ error, name: "getRoles" });
        dispatch(setLoader(false));
      });
  };
}

export function getSelfInformation(callback = () => { }) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherGet(APIS.REPRESENTATIVE_SELF, {})
      .then((response) => {
        dispatch(setSelfInformation(response.data));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        logger({ error, name: "SelfInformation" });
        dispatch(setLoader(false));
      })
      .finally(callback);
  };
}

export function getRepresentative(EntityId, pageDetails = { componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE, pageName: "Page_EntityProfile" }, enableLoader = true) {
  return (dispatch) => {
    if(enableLoader) {
      dispatch(setLoader(true));
    }
    return fetcherGet(`${APIS.REPRESENTATIVES}/${EntityId}`, {}, pageDetails)
      .then((response) => {
        dispatch(setRepresentative(response?.data));
        dispatch(setOriginalRep(response?.data))
        dispatch(setRepresentativesByGET(response?.data));
        if(enableLoader) {
          dispatch(setLoader(false));
        }
        dispatch(
          setFormValueOfStepByStepName({
            stepName: "ADD_REPRESENTATIVES",
            data: {
              representative: response.data,
            },
          })
        );
      })
      .catch((error) => {
        logger({ error, name: "getRepresentative" });
          dispatch(setRepresentative([]));
        if(enableLoader) {
          dispatch(setLoader(false));
        }
      });
  };
}

export function deleteRepresentative(representativeId, EntityId) {
  return (dispatch) => {
    fetcherDelete(`${APIS.REPRESENTATIVE}/${representativeId}`, {})
      .then((response) => {
        dispatch(getRepresentative(EntityId));
        dispatch(getRepresentativeStatusHistory(EntityId));
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        setTimeout(() => {
          dispatch(setLoader(false));
          dispatch(setRecheckDuplicateAR(data))
        }, [2000])

      })
      .catch((error) => {
        const data = {
          title: ERROR,
          error: true,
          isDefaultDisplay: false,
          multipleErrorKeys: error?.response?.data?.data || []
        };
        dispatch(setModelMessage(data));
        logger({ error, name: "getRepresentative" });
        dispatch(setLoader(false));
      });
  };
}


export function activateRepresentative(representativeId, EntityId, comment) {
  return (dispatch) => {
    fetcherPut(`${APIS.ACTIVATE_REPRESENTATIVE}/${representativeId}`, { comment })
      .then((response) => {
        dispatch(getRepresentative(EntityId));
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        const multiple = error?.response?.data?.data?.map(item => item.messageKey)
        const data = {
          title: ERROR,
          error: true,
          isDefaultDisplay: false,
          multipleErrorKeys: error?.response?.data?.data || []
        };
        dispatch(setModelMessage(data));
        logger({ error, name: "getRepresentative" });
        dispatch(setLoader(false));
      });
  };
}


export function suspendRepresentative(representativeId, EntityId, comment) {
  return (dispatch) => {
    fetcherPut(`${APIS.SUSPEND_REPRESENTATIVE}/${representativeId}`, { comment })
      .then((response) => {
        dispatch(getRepresentative(EntityId));
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        const data = {
          title: ERROR,
          error: true,
          isDefaultDisplay: false,
          multipleErrorKeys: error?.response?.data?.data || []
        };
        dispatch(setModelMessage(data));
        logger({ error, name: "getRepresentative" });
        dispatch(setLoader(false));
      });
  };
}


export function retireRepresentative(
  representativeId,
  EntityId,
  onSuccess = () => { }
) {
  return (dispatch) => {
    fetcherPost(`${APIS.RETIRE_REPRESENTATIVE}/${representativeId}`, {})
      .then((response) => {
        dispatch(getRepresentative(EntityId));
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
        dispatch(getRepresentativeStatusHistory(EntityId));
        onSuccess();
      })
      .catch((error) => {
        const data = {
          title: ERROR,
          error: true,
          isDefaultDisplay: false,
          multipleErrorKeys: error?.response?.data?.data || []
        };
        dispatch(setModelMessage(data));
        logger({ error, name: "getRepresentative" });
        dispatch(setLoader(false));
      });
  };
}


export function cancelRepresentative(representativeId, EntityId) {
  return (dispatch) => {
    fetcherPut(`${APIS.CANCEL_REPRESENTATIVE}/${representativeId}`, { entityId: EntityId})
      .then((response) => {
        dispatch(getRepresentative(EntityId));
        dispatch(getProposedChangeRepresentatives(EntityId));
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        const hasNavigated = handleEntityStatusError(error, EntityId, dispatch);
        if (hasNavigated) return;
        const data = {
          title: ERROR,
          error: true,
          isDefaultDisplay: false,
          multipleErrorKeys: error?.response?.data?.data || []
        };
        dispatch(setModelMessage(data));
        logger({ error, name: "getRepresentative" });
        dispatch(setLoader(false));
      });
  };
}
export function getValidateReferenceCode(data) {
  return fetcherGet(`${APIS.REPRESENTATIVE_REFERENCECODE}/${data}`, {});
}

export function getNAICS(id) {
  return (dispatch) => {
    fetcherGet(`${APIS.ENTITY}/${id}${"/naicscodes"}`, {})
      .then((response) => {
        dispatch(setNAICS(response.data));
      })
      .catch((error) => {
        logger({ error, name: "getNAICS code" });
      });
  };
}
// POST APIs will be here after
// modify this function to make all data preparation and  posting to API

export function saveContactFormData(data, navigate, id = null, fieldPermissions = []) {
  // GMPI
  let gmpiData = {
    entity: {
      jurisdiction: data.newStepsData?.SELECT_JURISDICTION?.jurisdiction?.ID,
      entityType: data.newStepsData?.SELECT_ENTITY_TYPE?.entityType?.ID,
      legalName: data.newStepsData?.ENTITY_INFORMATION?.legalName,
    },
    physical: {
      street1: data.newStepsData?.CONTACT_INFORMATION?.physicalAddress.street1,
      street2: data.newStepsData?.CONTACT_INFORMATION?.physicalAddress.street2,
      city: data.newStepsData?.CONTACT_INFORMATION?.physicalAddress.city,
      state: getStateCountryID(
        data.StateProvince,
        data.newStepsData?.CONTACT_INFORMATION?.physicalAddress.stateOrProvince
      ),
      region: data.newStepsData?.CONTACT_INFORMATION?.physicalAddress.region, // TBI in UI
      postalCode: data.newStepsData?.CONTACT_INFORMATION?.physicalAddress.postalCode,
      country: getStateCountryID(data.Country, data.newStepsData?.CONTACT_INFORMATION?.physicalAddress.country),
      sameAsPhysicalAddress: data.newStepsData?.CONTACT_INFORMATION?.mailingSameAsPhysical
        ? data.newStepsData?.CONTACT_INFORMATION?.mailingSameAsPhysical
        : false,
      type: "Physical",
    },
    mailing: {
      street1: data.newStepsData?.CONTACT_INFORMATION?.mailingAddress.street1,
      street2: data.newStepsData?.CONTACT_INFORMATION?.mailingAddress.street2,
      city: data.newStepsData?.CONTACT_INFORMATION?.mailingAddress.city,
      state: getStateCountryID(
        data.StateProvinceforMailing,
        data.newStepsData?.CONTACT_INFORMATION?.mailingAddress.stateOrProvince
      ),
      region: data.newStepsData?.CONTACT_INFORMATION?.mailingAddress.region, // TBI in UI
      postalCode: data.newStepsData?.CONTACT_INFORMATION?.mailingAddress.postalCode,
      country: getStateCountryID(data.Country, data.newStepsData?.CONTACT_INFORMATION?.mailingAddress.country),
      sameAsPhysicalAddress: data.newStepsData?.CONTACT_INFORMATION?.mailingSameAsPhysical
        ? data.newStepsData?.CONTACT_INFORMATION?.mailingSameAsPhysical
        : false,
      type: "Mailing",
    },
    entityContact: {
      telephoneNumber: !isEmpty(data.newStepsData?.CONTACT_INFORMATION?.contactInfo?.teleNumber?.trim())
        ? data.newStepsData?.CONTACT_INFORMATION?.contactInfo?.teleNumber?.trim()
        : null,
      ext: data.newStepsData?.CONTACT_INFORMATION?.contactInfo?.ext,
      mobilePhone: !isEmpty(data.newStepsData?.CONTACT_INFORMATION?.contactInfo?.mobilePhone?.trim())
        ? data.newStepsData?.CONTACT_INFORMATION?.contactInfo?.mobilePhone?.trim()
        : null,
      facsimileNumber: data.newStepsData?.CONTACT_INFORMATION?.contactInfo?.facsimileNumber?.trim(),
      email: data.newStepsData?.CONTACT_INFORMATION?.contactInfo?.email,
      websiteAddress: data.newStepsData?.CONTACT_INFORMATION?.contactInfo?.website,
      consent: "consent",
    },
  };

  if (
    data.newStepsData?.SELECT_ENTITY_TYPE?.entityType?.Name ===
    ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY
  ) {
    gmpiData.identifiers = getIdentifiers(
      data.incorporationCountry,
      data.incorporationStateOrProvince,
      data.newStepsData,
      data.newStepsData?.SELECT_JURISDICTION?.jurisdiction?.Name
    );
    gmpiData.identifiers.dateOfIncorporation = data.newStepsData?.ENTITY_INFORMATION?.dateOfIncorporation;
    gmpiData.identifiers.dunsNumber = data.newStepsData?.ENTITY_INFORMATION?.dunsNumber;
    gmpiData.entity.operatingName = data.newStepsData?.ENTITY_INFORMATION?.operationName;
  }
  if (
    data.newStepsData?.SELECT_ENTITY_TYPE?.entityType?.Name === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL
  ) {
    gmpiData.entity.purposeOfAccount = getStateCountryID(data.Purpose, data.newStepsData?.ENTITY_INFORMATION?.purposeOfAccount);
    gmpiData.entity.userClassification = getStateCountryID(
      data.UserClassification,
      data.newStepsData?.ENTITY_INFORMATION?.userClassification
    );
    gmpiData.entity.classificationDetails = data.newStepsData?.ENTITY_INFORMATION?.otherClassification; // TBD - in UI
  }
  if (
    data.newStepsData?.SELECT_ENTITY_TYPE?.entityType?.Name ===
    ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION
  ) {
    gmpiData.identifiers = getIdentifiers(
      data.Country,
      data.StateProvinceIdentifiers,
      data.newStepsData,
      data.newStepsData?.SELECT_JURISDICTION?.jurisdiction?.Name
    );
    gmpiData.identifiers.dateOfIncorporation = data.newStepsData?.ENTITY_INFORMATION?.dateOfIncorporation;
    gmpiData.identifiers.dunsNumber = data.newStepsData?.ENTITY_INFORMATION?.dunsNumber;
    gmpiData.identifiers.governmentIssedTaxpayerOrEmployerIdentificationNumber = data.newStepsData?.ENTITY_INFORMATION?.IdentificationNumber;
    gmpiData.entity.classificationDetails = data.newStepsData?.ENTITY_INFORMATION?.otherOrganization;
    gmpiData.entity.operatingName = data.newStepsData?.ENTITY_INFORMATION?.operationName;
    // gmpiData.entity.organizationTypeDetails = data.newStepsData?.ENTITY_INFORMATION?.otherOrganization; needed for further dev
    gmpiData.entity.typeOfOrganization = getOrganizationID(
      data.Organization,
      data.newStepsData?.ENTITY_INFORMATION?.organisationType
    );
  }

  gmpiData = sanityPermissionPostPutData(gmpiData, fieldPermissions)

  // Need this to show actul data posting
  if (isEmpty(id)) {
    // Api call for create entity contact info
    return fetcherPost(APIS.ENTITY, gmpiData, { pageName: "Page_EntityRegistration", componentName: "entity,identifiers,physical,mailing,entityContact" });
  }
  // For edit entity API Call
  return fetcherPut(`${APIS.ENTITY}/${id}`, gmpiData, { pageName: "Page_EntityRegistration", componentName: "entity,identifiers,physical,mailing,entityContact" });
}

export function checkGHGIDExists(data) {
  return fetcherPost(APIS.FACILITY_VERIFY, data, {});
}

export function cancelEntityApplication() {
  return (dispatch) =>
    fetcherPost(APIS.REQUEST_STATUSES, {})
      .then((response) => {
        dispatch(setLoader(false));
      })
      .catch(() => {
        dispatch(setLoader(false));
      });
}

export function addProposedRepresentatives(proposedReps = [], EntityId = null, representativeOnLocal = []) {

  return (dispatch) => {
    dispatch(setLoader(false))
    dispatch(setProposedRepresentatives(proposedReps));
    dispatch(setRepresentativeOnLocal([...proposedReps, ...representativeOnLocal]));
    const data = {
      title: SUCCESS,
      message1: "SUCCESSFULLY_ADDED_NEW_REPRESENTATIVE",
      error: false,
      isDefaultDisplay: false,
    };
    dispatch(setModelMessage(data));
    dispatch(setLoader(false));
  };
}

export function saveRepresentative(data, id) {
  return fetcherPost(APIS.REPRESENTATIVES, data, {
    pageName: "Page_EntityRegistration",
    componentName: "representative"
  });
}

export function updateRepresentative(data, id) {
  return fetcherPut(APIS.REPRESENTATIVE, data, {
    pageName: "Page_EntityRegistration",
    componentName: "representative"
  });
}

export function deleteProposedRepresentative(rep) {
  return (dispatch) => {
    dispatch(removeProposedRepresentativeByIndex(rep));
    const data = {
      title: SUCCESS,
      message1: "SUCCESSFULLY_REMOVED_NEW_REPRESENTATIVE",
      error: false,
      isDefaultDisplay: false,
    };
    dispatch(setModelMessage(data));
  };
}
export function updateProposedRepresentative(data, id) {
  return fetcherPut(`${APIS.PROPOSED_REPRESENTATIVE}/${id}`, data, {});
}

export function updateEntity(data, id, isProposed, pageDetail) {

  // for proposed entity page names not yet implemented so ignoring by below condition
  if (isProposed) {
    return fetcherPut(`${isProposed ? APIS.PURPOSE_ENTITY : APIS.REVIEW_AND_SUBMIT}/${id}`, data);
  }
  return fetcherPut(`${isProposed ? APIS.PURPOSE_ENTITY : APIS.REVIEW_AND_SUBMIT}/${id}`, data, pageDetail);
}

export function updateAuction(status, id) {
  return (dispatch) => {
    fetcherPut(`${APIS.AUCTION}/${id}`, status, {})
      .then(() => {
        // TBD - After saved successfully need to notify
        dispatch(setEntityIsAuction(status));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
        const hasNavigated = handleEntityStatusError(error, id, dispatch);
        if (hasNavigated) return;
        logger({ error, name: "updateAuction" });
      });
  };
}
export function retiredSingleFacility({ id, comment, entityId }) {
  return (dispatch) => {
    fetcherPut(`${APIS.REMOVE_FACILITY}/${id}${"/retire"}`, { comment })
      .then(() => {
        dispatch(setRetireSingleFacility({ id, comment }));
        dispatch(getFacilityOwnerHistory(id));
        dispatch(setLoader(false));
        dispatch(getEntitySingleFacilityById(id, entityId))
        dispatch(getEnityFacilityById(entityId));
        if (!isEmpty(entityId)) {
          dispatch(getEntityContactById(entityId));
        }

        const data = {
          title: SUCCESS,
          message1: "FACILITY_SUCCESS",
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      })
      .catch((error) => {
        logger({ error, name: "removeFacility" });
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
          isDefaultDisplay: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export const saveFacility = (entityId, rows, NAICS, t1, dontSetStepSubmit = false) => {
  const facilitiesObjectForAddition = rows?.filter((item) => !item?.id).map((item) => ({
    facilityPhysicalAddress: {
      street1: item?.physicalAddress?.street1,
      street2: item?.physicalAddress?.street2,
      city: item?.physicalAddress?.city,
      state: item?.physicalAddress?.stateOrProvince,
      region: item?.physicalAddress?.region,
      postalCode: item?.physicalAddress?.postalCode,
      country: item?.physicalAddress?.Country?.ID || item?.physicalAddress?.country,
      sameAsPhysicalAddress: item?.mailingSameAsPhysical,
      type: "Physical",
    },
    facilityMailingAddress: {
      street1: item?.mailingAddress?.street1,
      street2: item?.mailingAddress?.street2,
      city: item?.mailingAddress?.city,
      state: item?.mailingAddress?.stateOrProvince,
      region: item?.mailingAddress?.region,
      postalCode: item?.mailingAddress?.postalCode,
      country: item?.mailingAddress?.country,
      sameAsPhysicalAddress: item?.mailingSameAsPhysical,
      type: "Mailing",
    },
    facilityContact: {
      mobileNumber: item.contactInfo?.mobilePhone?.trim() || null,
      telephoneNumber: item.contactInfo?.phone?.trim() || null,
      firstName: item.contactInfo?.firstName,
      lastName: item.contactInfo?.lastName,
      emailAddress: item.contactInfo?.email,
      jobTitle: item.contactInfo?.jobTitle,
      statusId: null,
    },
    facility: {
      entityId,
      // ...(item?.facilityId ? { facility_id: item?.facilityId } : {}),
      isFacility: item.isFacilityInRegistry,
      isUseEntity: item?.useEntityData ? item?.useEntityData : item?.isUseEntity,
      // naicsCode: null,
      naicsCode: item?.NAICSCode?.id || item?.NAICSCode, // to work further:>  naics code will be null
      ghgEmissionsReportingId: item?.ghgEmissionsReportingId,
      facilityStatus: null,
      facilityName: item?.facilityName,
      operatingName: item?.operatingName,
      entityType: "d601fc39-a224-4593-80b1-cff41777b0df",
    },
  }));

  const facilitiesObjectForUpdation = rows?.filter((item) => item?.id).map((item) =>
  (
    {
      facilityPhysicalAddress: {
        street1: item?.physicalAddress?.street1,
        street2: item?.physicalAddress?.street2,
        city: item?.physicalAddress?.city,
        state: item?.physicalAddress?.stateOrProvince ? item?.physicalAddress?.stateOrProvince : item?.physicalAddress?.state?.id,
        region: item?.physicalAddress?.region,
        postalCode: item?.physicalAddress?.postalCode,
        country: item?.physicalAddress?.country?.id ? item?.physicalAddress?.country?.id : item?.physicalAddress?.country,
        sameAsPhysicalAddress: item?.mailingSameAsPhysical || item?.mailingAddress?.sameAsPhysicalAddress,
        id: item?.physicalAddress?.id,
        type: "Physical",
      },
      facilityMailingAddress: {
        street1: item?.mailingAddress?.street1,
        street2: item?.mailingAddress?.street2,
        city: item?.mailingAddress?.city,
        state: item?.mailingAddress?.stateOrProvince ? item?.mailingAddress?.stateOrProvince : item?.mailingAddress?.state?.id,
        region: item?.mailingAddress?.region,
        postalCode: item?.mailingAddress?.postalCode,
        country: item?.mailingAddress?.country?.id ? item?.mailingAddress?.country?.id : item?.mailingAddress?.country,
        sameAsPhysicalAddress: item?.mailingSameAsPhysical || item?.mailingAddress?.sameAsPhysicalAddress,
        id: item?.mailingAddress?.id,
        type: "Mailing",
      },
      facilityContact: {
        mobileNumber: item.contactInfo?.mobilePhone ? item.contactInfo?.mobilePhone?.trim() : item?.contact?.mobileNumber?.trim() || null,
        telephoneNumber: item.contactInfo?.phone ? item.contactInfo?.phone?.trim() : item?.contact?.telephoneNumber?.trim() || null,
        firstName: item.contactInfo?.firstName ? item.contactInfo?.firstName : item?.contact?.firstName,
        lastName: item.contactInfo?.lastName ? item.contactInfo?.lastName : item?.contact?.lastName,
        emailAddress: item.contactInfo?.email ? item.contactInfo?.email : item?.contact?.emailAddress,
        jobTitle: item.contactInfo?.jobTitle ? item.contactInfo?.jobTitle : item?.contact?.jobTitle,
        id: item?.contactInfo?.id ? item?.contactInfo?.id : item?.contact?.id,
      },
      facility: {
        entityId,
        // statusId: null,
        // ...(item?.facilityId ? { facility_id: item?.facilityId } : {}),
        isFacility: item.isFacilityInRegistry ? item.isFacilityInRegistry : item.facilityIsInRegistry,
        isUseEntity: item?.isUseEntity ? item?.isUseEntity : item?.useEntityData,
        facilityStatus: null,
        naicsCode: item?.NAICSCode || item?.naicsCode?.id,
        ghgEmissionsReportingId: item?.ghgEmissionsReportingId,
        facilityName: item?.facilityName,
        operatingName: item?.operatingName,
        entityType: "d601fc39-a224-4593-80b1-cff41777b0df", // TODO: instead use get entity type ID from API
        id: item?.id,
        isUpdateGhg: item?.is_updateGHG
      },
    }));

  let userNotified = false;

    return async (dispatch) => {
      dispatch(setLoader(true));
      try {
        if (!isEmpty(facilitiesObjectForAddition)) {
          const res = await fetcherPost(APIS.UPDATE_FACILITY, facilitiesObjectForAddition, {
            componentName: "facility,facilityPhysicalAddress,facilityMailingAddress,facilityContact"
          });
          
          dispatch(getEntityContactById(entityId));
          dispatch(getEnityFacilityById(entityId));
          dispatch(setDisabledFacilitySubmitBtn(false));
          const data = {
            title: SUCCESS,
            message1: res?.messageKey,
            error: false,
            isDefaultDisplay: false,
          };
          if (!userNotified) {
            dispatch(setModelMessage(data));
            userNotified = true;
          }
          if (dontSetStepSubmit) {
            dispatch(setSubmittedOfSelectedStep(true));
          }
          if (res?.messageKey === 'FACILITY_ADDED_SUCCESSFULLY') {
            dispatch(setLoader(false));
          }
          return res;  // Return the response
        }
  
        if (!isEmpty(facilitiesObjectForUpdation)) {
          const res = await fetcherPut(APIS.UPDATE_FACILITY, facilitiesObjectForUpdation, {
            componentName: "facility,facilityPhysicalAddress,facilityMailingAddress,facilityContact"
          });
          
          dispatch(getEntityContactById(entityId));
          const data = {
            title: SUCCESS,
            message1: "FACILITY_UPDATE_ADD_GENERIC_MESSAGE",
            error: false,
            isDefaultDisplay: false,
          };
          if (!userNotified) {
            dispatch(setModelMessage(data));
            userNotified = true;
          }
          dispatch(getEnityFacilityById(entityId));
          dispatch(setLoader(false));
          return res;  // Return the response
        }
      } catch (error) {
        const hasNavigated = handleEntityStatusError(error, entityId, dispatch);
        if (hasNavigated) throw error;
        dispatch(getEntityContactById(entityId));
        dispatch(getEnityFacilityById(entityId));
        logger({ error, name: "saveFacility failed" });
        const data = {
          title: ERROR,
          message1: t(t1, error?.response?.data?.data || error?.response?.data?.messageKey),
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        if (dontSetStepSubmit) {
          dispatch(setSubmittedOfSelectedStep(false));
        }
        dispatch(setLoader(false));
        throw error;  // Rethrow the error to be handled in onSubmit
      }
    };
  };

export const proposeSingleFacilityUpdate = (entityId, rows, NAICS, singleFacilityEntityId) => {
  const facilitiesObject = rows?.map((item) => ({
    physical: {
      street1: item?.physicalAddress?.street1,
      street2: item?.physicalAddress?.street2,
      city: item?.physicalAddress?.city,
      state: item?.physicalAddress?.stateOrProvince,
      region: item?.physicalAddress?.region,
      postalCode: item?.physicalAddress?.postalCode,
      country: item?.physicalAddress?.country,
      sameAsPhysicalAddress: item?.mailingSameAsPhysical,
      type: "Physical",
    },
    mailing: {
      street1: item?.mailingAddress?.street1,
      street2: item?.mailingAddress?.street2,
      city: item?.mailingAddress?.city,
      state: item?.mailingAddress?.stateOrProvince,
      region: item?.mailingAddress?.region,
      postalCode: item?.mailingAddress?.postalCode,
      country: item?.mailingAddress?.country,
      sameAsPhysicalAddress: item?.mailingSameAsPhysical,
      type: "Mailing",
    },
    facilityContact: {
      statusId: null,
      mobileNumber: item.contactInfo?.mobilePhone?.trim() || null,
      telephoneNumber: item.contactInfo?.phone?.trim() || null,
      firstName: item.contactInfo?.firstName,
      lastName: item.contactInfo?.lastName,
      emailAddress: item.contactInfo?.email,
      jobTitle: item.contactInfo?.jobTitle,
    },
    facility: {
      entityId,
      statusId: null,
      isFacility: item?.isFacilityInRegistry,
      isUseEntity: item?.useEntityData,
      naicsCode: NAICS,
      ghgEmissionsReportingId: item?.ghgEmissionsReportingId,
      facilityName: item?.facilityName,
      operatingName: item?.operatingName,
      entityType: "d601fc39-a224-4593-80b1-cff41777b0df",
      action: item?.ACTION,
      facilityId: item?.status === "ACTIVE" ? item?.id : null,
      proposedFacilityId: item?.status === "PROPOSED" ? item?.id : null,
    },
  }));

  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherPut(`${APIS.PURPOSE_FACILITY}/${entityId}`, facilitiesObject, {
      pageName: "Page_EntityRegistration",
      componentName: "facility,physical,mailing,facilityContact"
    })
      .then((response) => {
        // TBD - After saved successfully need to notify
        dispatch(getEntityContactById(entityId));
        dispatch(getEntitySingleFacilityById(singleFacilityEntityId, entityId));         // upon sending a PUT request need to wait for changes to be effective
        dispatch(setLoader(false));
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(getEntitySingleFacilityById(singleFacilityEntityId, entityId));  // dispatching to get single facility on facility details page
        dispatch(setModelMessage(data));
      })
      .catch((error) => {
        logger({ error, name: "saveFacility failed" });
        dispatch(getEntityContactById(entityId));
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey, // Hiding generic error message, since we have multiple error messages
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));

        dispatch(setLoader(false));
      });
  };
};
export const updateSingleFacility = (entityId, rows, facilityId, NAICS) => {

  const facilitiesObject = rows?.map((item) => ({

    facilityPhysicalAddress: {
      street1: item?.physicalAddress?.street1,
      street2: item?.physicalAddress?.street2,
      city: item?.physicalAddress?.city,
      state: item?.physicalAddress?.stateOrProvince,
      region: item?.physicalAddress?.region,
      postalCode: item?.physicalAddress?.postalCode,
      country: item?.physicalAddress?.country,
      sameAsPhysicalAddress: item?.mailingSameAsPhysical || item?.mailingAddress?.sameAsPhysicalAddress,
      type: "Physical",
      id: item?.physicalAddress?.id,
    },
    facilityMailingAddress: {
      street1: item?.mailingAddress?.street1,
      street2: item?.mailingAddress?.street2,
      city: item?.mailingAddress?.city,
      state: item?.mailingAddress?.stateOrProvince,
      region: item?.mailingAddress?.region,
      postalCode: item?.mailingAddress?.postalCode,
      country: item?.mailingAddress?.country,
      sameAsPhysicalAddress: item?.mailingSameAsPhysical || item?.mailingAddress?.sameAsPhysicalAddress,
      id: item?.mailingAddress?.id,
      type: "Mailing",
    },
    facilityContact: {
      mobileNumber: item.contactInfo?.mobilePhone?.trim() || null,
      telephoneNumber: item.contactInfo?.phone?.trim() || null,
      firstName: item.contactInfo?.firstName,
      lastName: item.contactInfo?.lastName,
      emailAddress: item.contactInfo?.email,
      jobTitle: item.contactInfo?.jobTitle,
      id: item?.contactInfo?.id,
    },
    facility: {
      entityId,
      isUpdateGhg: false,
      id: item?.id,
      facilityStatus: null,
      naicsCode: NAICS,
      isFacility: item?.isFacilityInRegistry,
      isUseEntity: item?.useEntityData,
      ghgEmissionsReportingId: item?.ghgEmissionsReportingId,
      facilityName: item?.facilityName,
      operatingName: item?.operatingName,
      entityType: "d601fc39-a224-4593-80b1-cff41777b0df",
    },
  }));

  return (dispatch) => {
    fetcherPut(APIS.UPDATE_FACILITY, facilitiesObject, {
      componentName: "facility,facilityPhysicalAddress,facilityMailingAddress,facilityContact",
      pageName: "Page_EntityRegistration",
    })

      .then((res) => {
        // TBD - After saved successfully need to notify
        dispatch(getEntitySingleFacilityById(res?.data, entityId));
        dispatch(setLoader(false));
        const data = {
          title: SUCCESS,
          message1: "SUCCESSFULLY_UPDATED_FACILITY",
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      })
      .catch((error) => {
        dispatch(getEntityContactById(entityId));

        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey, // Hiding generic error message, since we have multiple error messages
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));

        logger({ error, name: "saveFacility failed" });
        dispatch(setLoader(false));
      });
  };
};

export const saveProposeFacility = (entityId, rows, NAICS) => {
  const facilitiesObject = rows?.map((item) => ({

    physical: {
      street1: item?.physicalAddress?.street1,
      street2: item?.physicalAddress?.street2,
      city: item?.physicalAddress?.city,
      state: item?.physicalAddress?.stateOrProvince,
      region: item?.physicalAddress?.region,
      postalCode: item?.physicalAddress?.postalCode,
      country: item?.physicalAddress?.country,
      sameAsPhysicalAddress: item?.mailingSameAsPhysical,
      type: "Physical",
    },
    mailing: {
      street1: item?.mailingAddress?.street1,
      street2: item?.mailingAddress?.street2,
      city: item?.mailingAddress?.city,
      state: item?.mailingAddress?.stateOrProvince,
      region: item?.mailingAddress?.region,
      postalCode: item?.mailingAddress?.postalCode,
      country: item?.mailingAddress?.country,
      sameAsPhysicalAddress: item?.mailingSameAsPhysical,
      type: "Mailing",
    },
    facilityContact: {
      statusId: null,
      mobileNumber: item.contactInfo?.mobilePhone?.trim() || null,
      telephoneNumber: item.contactInfo?.phone?.trim() || null,
      firstName: item.contactInfo?.firstName,
      lastName: item.contactInfo?.lastName,
      emailAddress: item.contactInfo?.email,
      jobTitle: item.contactInfo?.jobTitle,
    },
    facility: {
      entityId,
      statusId: null,
      isFacility: item?.isFacilityInRegistry,
      isUseEntity: item?.useEntityData,
      naicsCode: item?.NAICSCode?.id || item?.NAICSCode || null,
      ghgEmissionsReportingId: item?.ghgEmissionsReportingId,
      facilityName: item?.facilityName,
      operatingName: item?.operatingName,
      entityType: "d601fc39-a224-4593-80b1-cff41777b0df",
      action: item?.ACTION || "add",
      facilityId: item?.proposedFacilityId ? null : (item?.facility_id || item?.id || null),
      proposedFacilityId: item?.proposedFacilityId,
    },
  }));

  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherPut(`${APIS.PURPOSE_FACILITY}/${entityId}`, facilitiesObject, {})
      .then((response) => {
        // TBD - After saved successfully need to notify
        dispatch(getEntityContactById(entityId));
        dispatch(getEnityFacilityById(entityId));
        dispatch(setLoader(false));
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      })
      .catch((error) => {
        logger({ error, name: "saveFacility failed" });
        dispatch(getEntityContactById(entityId));
        dispatch(getEnityFacilityById(entityId));
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey, // Hiding generic error message, since we have multiple error messages
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));

        dispatch(setLoader(false));
      });
  };
};


export function saveAlternateContact(data, id, pageDetails = {}, reThrowException = false) {
  return (dispatch) => {
    const contactData = {
      contact: {
        ...data?.contact,
        mobilePhone: data?.contact?.mobilePhone?.trim(),
        telephoneNumber: data?.contact?.telephoneNumber?.trim(),
      }
    }
    return fetcherPost(APIS.ALTERNATE_CONTACT, contactData, {
      pageName: "Page_EntityRegistration",
      componentName: "contact"
    })
      .then(() => {
        // TBD - After saved successfully need to notify
        dispatch(setLoader(false));
        dispatch(getAlternateContact(id, pageDetails));
        dispatch(setResetAlternateContactForm(new Date().getTime()));
      })
      .catch((error) => {
        logger({ error, name: "saveAlternateContact" });
        dispatch(setLoader(false));
        if (reThrowException) {
          throw new Error(`ALTERNATE_CONTACT_ERROR:${error?.response?.data?.messageKey}`)
        }
      });
  };
}
export function updateAlternateContact(data, id, pageDetails, reThrowException = false) {
  const contactData = {
    contact: {
      ...data?.contact,
      mobilePhone: data?.contact?.mobilePhone?.trim(),
      telephoneNumber: data?.contact?.telephoneNumber?.trim(),
    }
  }
  return (dispatch) => {
    if (data?.contact?.createdAt)
      delete data.contact.createdAt;
    if (data?.contact?.created_by)
      delete data.contact.created_by;
    if (data?.contact?.deleted_by)
      delete data.contact.deleted_by;
    if (data?.contact?.deleted_by)
      delete data.contact.deleted_by;
    if (data?.contact?.updatedAt)
      delete data.contact.updatedAt;
    if (data?.contact?.updated_by)
      delete data.contact.updated_by;

    return fetcherPut(`${APIS.ALTERNATE_CONTACT}`, contactData, {
      pageName: "Page_EntityRegistration",
      componentName: "contact"
    })

      .then(() => {
        // TBD - After saved successfully need to notify
        dispatch(getAlternateContact(id, pageDetails));
        dispatch(setResetAlternateContactForm(new Date().getTime()));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
        const hasNavigated = handleEntityStatusError(error, id, dispatch);
        if (hasNavigated) return;
        logger({ error, name: "saveAlternateContact" });
        if (reThrowException) {
          throw new Error(`ALTERNATE_CONTACT_ERROR:${error?.response?.data?.messageKey}`)
        }
      });
  };

}

export function getAlternateContact(id, pageDetails = {}) {
  return (dispatch) => {
    return fetcherGet(`${APIS.ALTERNATE_CONTACT}/${id}`, {},
      { componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON, pageName: "Page_EntityProfile" })
      .then((response) => {
        // TBD - After saved successfully need to notify
        dispatch(setAlternateContact(response));
      })
      .catch((error) => {
        dispatch(setAlternateContact({}));
        logger({ error, name: "getAlternateContact" });
      });
  };
}

export function getEntityContactById(id, redirect = false, isEntityRegistration = false, enableLoader = true) {
  return (dispatch) => {
    if(enableLoader) {
      dispatch(setLoader(true));
    }
    dispatch(setRevisionComment(""));
    return fetcherGet(`${APIS.ENTITY}/${id}`, {}, { pageName: 'Page_EntityProfile', componentName: "entityDetails,generalEntityDetails,identifiers,physicalAddress,mailingAddress,additionalContactInformation" })
      .then((response) => {
        // set All data
        dispatch(setEntities(response));

        // set jurisdiction data

        dispatch(setJurisdiction(response?.data?.jurisdiction.name));
        dispatch(setEntityType(response?.data?.entityType.name));
        dispatch(setRevisionComment(response?.data?.comment));

        if (response?.data?.entityType.name === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY) {
          dispatch(setSkipStepByKey({ stepName: COMPONENT_NAMES.ADD_FACILITIES, skip: false }));
        } else {
          dispatch(setSkipStepByKey({ stepName: COMPONENT_NAMES.ADD_FACILITIES, skip: true }));
        }
        if (response?.data?.jurisdiction.name !== JURISDICTIONS_NAMES.QUEBEC) {
          dispatch(setSkipStepByKey({ stepName: COMPONENT_NAMES.AUCTION, skip: false }));
        } else {
          dispatch(setSkipStepByKey({ stepName: COMPONENT_NAMES.AUCTION, skip: true }));
        }

        dispatch(setEntityID(response?.data?.entityType?.id));
        dispatch(setShortEntityID(response?.data?.entityId));

        dispatch(setEntityIsAuction({ is_auction: response?.data?.isAuction }));
        if (response?.data?.isAuction === true || response?.data?.isAuction === false) {
          dispatch(
            setFormValueOfStepByStepName({
              stepName: "AUCTION",
              data: {
                isAuction: response?.data?.isAuction,
              },
            })
          );
        }
        dispatch(
          setFormValueOfStepByStepName({
            stepName: "SELECT_JURISDICTION",
            data: {
              jurisdiction: {
                ID: response?.data?.jurisdiction.id,
                Name: response?.data?.jurisdiction.name,
              },
            },
          })
        );
        // set Entity type data
        dispatch(
          setFormValueOfStepByStepName({
            stepName: "SELECT_ENTITY_TYPE",
            data: {
              entityType: {
                ID: response?.data?.entityType.id,
                Name: response?.data?.entityType.name,
              },
            },
          })
        );

        // set Entity Info data
        dispatch(
          setFormValueOfStepByStepName({
            stepName: "ENTITY_INFORMATION",
            data: {
              legalName: response?.data?.legalName,
              operationName: response?.data?.operatingName,
              country: response?.data?.entityIdentifier?.incorporationCountry?.id,
              province: response?.data?.entityIdentifier?.incorporationStateOrProvince?.id,
              dateOfIncorporation: response?.data?.entityIdentifier?.dateOfIncorporation,
              incorporationRegion: response?.data?.entityIdentifier?.incorporationRegion,
              organisationType: response.data?.typeOfOrganization?.id,
              otherOrganization: response.data?.organizationTypeDetails?.subClassification,
              purposeOfAccount: response.data?.purposeOfAccount?.id,
              userClassification: response.data?.userClassification?.id,
              otherClassification: response?.data?.classificationDetails?.subClassification || response?.data?.organizationTypeDetails?.subClassification,
              dunsNumber: response?.data?.entityIdentifier?.dunsNumber,
              québecEnterpriseNumber: response?.data?.entityIdentifier?.québecEnterpriseNumber,
              IDByAgency: response?.data?.entityIdentifier?.idNumberAssignedByIncorporatingAgency,
              IdentificationNumber: response?.data?.entityIdentifier?.governmentIssedTaxpayerOrEmployerIdentificationNumber,
              registryID: response?.data?.entityIdentifier?.registryIdAssignedByServiceNovaScotiaUnderTheRegistryOfJointStockCompanies,
              businessNumber: response?.data?.entityIdentifier?.businessNumberAssignedByTheCanadaRevenueAgency,
              idCalifornia: response?.data?.entityIdentifier?.idNumberAssignedByTheCaliforniaSecretaryOfState,
              UnitedBusinessID: response?.data?.entityIdentifier?.washingtonUnifiedBusinessIdentifierNumberUBI,
              ...response?.data?.entityIdentifier,
            },
          })
        );

        // set Contact info data
        dispatch(
          setFormValueOfStepByStepName({
            stepName: "CONTACT_INFORMATION",
            data: {
              mailingSameAsPhysical: response?.data?.mailingAddress?.sameAsPhysicalAddress,
              physicalAddress: {
                street1: response?.data?.physicalAddress?.street1,
                street2: response?.data?.physicalAddress?.street2,
                city: response?.data?.physicalAddress?.city,
                country: response?.data?.physicalAddress?.country?.id,
                stateOrProvince: response?.data?.physicalAddress?.state?.id,
                postalCode: response?.data?.physicalAddress?.postalCode,
                region: response?.data?.physicalAddress?.region,
                ...response?.data?.physicalAddress,
              },
              mailingAddress: {
                street1: response?.data?.mailingAddress?.street1,
                street2: response?.data?.mailingAddress?.street2,
                city: response?.data?.mailingAddress?.city,
                country: response?.data?.mailingAddress?.country?.id,
                stateOrProvince: response?.data?.mailingAddress?.state?.id,
                postalCode: response?.data?.mailingAddress?.postalCode,
                region: response?.data?.mailingAddress?.region,
                ...response?.data?.mailingAddress,
              },
              contactInfo: {
                teleNumber: response?.data?.additionalContactInformation?.telephoneNumber,
                website: response?.data?.additionalContactInformation?.websiteAddress,
                email: response?.data?.additionalContactInformation?.emailAddress,
                ...response.data?.additionalContactInformation
              },
            },
          })
        );
        const url = isEntityRegistration ? `${APIS.ENTITY}/${id}/facility?isEntityRegistration=${isEntityRegistration}` : `${APIS.ENTITY}/${id}/facility`

        return fetcherGet(url, {}, { componentName: "addedFacilities", pageName: "Page_EntityProfile" }).then((responseFacility) => {
          dispatch(setFacilities(responseFacility.data));
          // set Contact info data
          const formattedFacility = mapGetFacility(responseFacility.data);
          dispatch(
            setFormValueOfStepByStepName({
              stepName: "ADD_FACILITIES",
              data: formattedFacility,
            })
          );
          if(enableLoader) {
            dispatch(setLoader(false));
          }


          return fetcherGet(`${APIS.REPRESENTATIVES}/${id}`, {}, { componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE, pageName: "Page_EntityProfile" }).then((responseReps) => {
            dispatch(setRepresentative(responseReps.data));

            dispatch(
              setFormValueOfStepByStepName({
                stepName: "ADD_REPRESENTATIVES",
                data: {
                  representative: responseReps.data,
                },
              })
            );
            if (redirect) {
              if (!isEmpty(response.data) && (response.data?.isAuction === true || response?.data?.isAuction === false)) {
                const step = defaultSteps.find((item) => item.key === COMPONENT_NAMES.REVIEW_AND_SUBMIT);
                dispatch(setSelectedStep(step));
              } else if (!isEmpty(responseReps.data)) {
                if (response?.data?.jurisdiction.name === JURISDICTIONS_NAMES.QUEBEC) {
                  const step = defaultSteps.find((item) => item.key === COMPONENT_NAMES.REVIEW_AND_SUBMIT);
                  dispatch(setSelectedStep(step));
                } else {
                  const step = defaultSteps.find((item) => item.key === COMPONENT_NAMES.AUCTION);
                  dispatch(setSelectedStep(step));
                }
              } else if (!isEmpty(responseReps.data) || [ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL, ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION].includes(response?.data?.entityType.name)) {
                const step = defaultSteps.find((item) => item.key === COMPONENT_NAMES.ADD_REPRESENTATIVES);
                dispatch(setSelectedStep(step));
              } else if (!isEmpty(responseFacility.data)) {
                const step = defaultSteps.find((item) => item.key === COMPONENT_NAMES.ADD_REPRESENTATIVES);
                dispatch(setSelectedStep(step));
              } else if (!isEmpty(responseFacility.data) || response?.data?.entityType.name === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY) {
                const step = defaultSteps.find((item) => item.key === COMPONENT_NAMES.ADD_FACILITIES);
                dispatch(setSelectedStep(step));
              } else if (!isEmpty(response.data)) {
                const step = defaultSteps.find((item) => item.key === COMPONENT_NAMES.CONTACT_INFORMATION);
                dispatch(setSelectedStep(step));
              }
            }
            if(enableLoader) {
              dispatch(setLoader(false));
            }
          });
        })
          .catch((error) => {
            logger({ error, name: "facility" });
            if(enableLoader) {
              dispatch(setLoader(false));
            }
          });
      })
      .catch((error) => {
        dispatch(setEntityIsAuction(false));
        logger({ error, name: "getEntityContactById" });
        if(enableLoader) {
          dispatch(setLoader(false));
        }
      });
  };
}

export function getLockStatus(id) {
  return (dispatch) => {
    fetcherGet(`${APIS.ENTITY}/${id}`, {}, { componentName: "entityDetails,generalEntityDetails,identifiers,physicalAddress,mailingAddress,additionalContactInformation" })
      .then((response) => {
        dispatch(setIsLocked(!!response?.data?.is_locked));
      })
      .catch((error) => {
        logger({ error, name: "get lock status failed" });
      });
  };
}

export function getEnityFacilityById(id, pageDetails = { componentName: "addedFacilities", pageName: "Page_EntityProfile" }, isEntityRegistration = false) {
  return (dispatch) => {
    const url = isEntityRegistration ? `${APIS.ENTITY}/${id}/facility?isEntityRegistration=${isEntityRegistration}` : `${APIS.ENTITY}/${id}/facility`
    return fetcherGet(url, {}, pageDetails)
      .then((response) => {
        dispatch(setFacilities(response.data));
        // For roles & privilege on profile page.
        dispatch(setFacilitiesList(response));
        const formattedFacility = mapGetFacility(response.data);
        dispatch(
          setFormValueOfStepByStepName({
            stepName: "ADD_FACILITIES",
            data: formattedFacility,
          })
        );
      })
      .catch((error) => {
        logger({ error, name: "getEntityContactById" });
      });
  };
}
export function getEntitySingleFacilityById(id, entityId, navigateAway = () => { }) {
  return (dispatch) => {
    fetcherGet(`${APIS.FACILITY}/${id}?entityId=${entityId}`, {})
      .then((response) => {
        if (response?.data?.proposeChanges) {
          const processedFacilityData = processProposedChangeFacilityData(response?.data);
          dispatch(setSingleFacility(processedFacilityData));
        } else {
          dispatch(setSingleFacility(response.data));
        }
      })
      .catch((error) => {
        if (error?.response?.data?.messageKey === "USER_IS_NOT_AUTHORIZED") {
          navigateAway();
        }
        logger({ error, name: "getEntityContactById" });
      });
  };
}

export function changeStatusToSubmit(id) {

  return fetcherPut(`${APIS.IS_SUMITTED}/${id}`, {}, {})

}

export function removeFacilityById(id, EntityId) {

  return (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setDisabledFacilitySubmitBtn(true));
    fetcherDelete(`${APIS.UPDATE_FACILITY}/${id}?entityId=${EntityId}`, {})
      .then((response) => {

        dispatch(getEnityFacilityById(EntityId));
        dispatch(getEntityContactById(EntityId));
        dispatch(getFacilities(EntityId));
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));

        dispatch(setLoader(false));
        // dispatch(setDisabledFacilitySubmitBtn(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
        const hasNavigated = handleEntityStatusError(error, EntityId, dispatch);
        if(hasNavigated) return;
        logger({ error, name: "removeFacility" });
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function retireFacilityById(id, entityId) {
  return (dispatch) => {
    fetcherPut(`${APIS.UPDATE_FACILITY}/${id}${"/retire"}`, { comment: " " })
      .then(() => {
        dispatch(getEnityFacilityById(entityId));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        logger({ error, name: "retireFacility" });
        dispatch(setLoader(false));
      });
  };
}

export function checkIsEditAccess(entityid) {
  return (dispatch) => {
    return fetcherGet(`${APIS.IS_EDIT_ACCESS}/${entityid}`, {})
      .then((response) => {
        dispatch(setEditAccess(response?.data?.is_allowed_access));
      })
      .catch((error) => {
        logger({ error, name: "editAccess" });
      });
  };
}

export function updateLock(id, isLocked) {
  return (dispatch) => {
    fetcherPut(`${APIS.UPDATE_LOCK}/${id}`, { is_locked: isLocked }, {})
      .then((response) => {
        dispatch(setLockResponse(response));
        dispatch(getLockStatus(id));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLockResponse(error))
        logger({ error, name: "status Update" });
        dispatch(setLoader(false));
      });
  };
}
const representativeHistorypageDetails = { componentName: 'userRepresentativeAssignmentHistory', pageName: "Page_EntityProfile" }
export function revertChanges(id, payload) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherPost(`${APIS.REVERT_CHANGES}/${id}`, payload, {})
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1: RESPONSE_MESSAGE_KEYS.REVERT_CHANGES_SUCCESSFULLY,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));

        // Dispatch API calls one by one
        dispatch(getRepresentative(id));
        dispatch(getEnityFacilityById(id));
        dispatch(getRepresentativeStatusHistory(id, representativeHistorypageDetails));
        dispatch(getEntityContactById(id));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        logger({ error, name: "Revert Changes" });
        dispatch(setLoader(false));
      });
  };
}

export function approveDenyChanges(id, payload) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherPost(`${APIS.APPROVE_DENY_CHANGES}/${id}`, payload, {})
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        payload?.sections?.map((item) => {
          switch (item?.section) {
            case ENTITY_SECTION_TYPES.REPRESENTATIVES:
              dispatch(getRepresentative(id));
              dispatch(getRepresentativeStatusHistory(id));
              break;
            case ENTITY_SECTION_TYPES.REPRESENTATIVESTATUSHISTORY:
              dispatch(getRepresentativeStatusHistory(id));
              break;
            case ENTITY_SECTION_TYPES.FACILITIES:
              dispatch(getEnityFacilityById(id));
              break;
            default:
              break;
          }
          return null;
        });
        dispatch(getEntityContactById(id));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        const data = error?.response?.data;
        let errorMessage = '';

        if (data?.messageKey === 'REPRESENTATIVE_VALIDATIONS_FAILED') {
          if (data && data.data && data.data.length > 0) {
            data.data.forEach(errorObj => {
              switch (errorObj.messageKey) {
                case 'PAR_MINIMUM_VALIDATION_FAILED':
                  errorMessage += `PAR Minimum value has not reached. Min PAR required is ${errorObj.parCountMinimumSettings}\n`;
                  break;
                case 'PAR_MAXIMUM_VALIDATION_FAILED':
                  errorMessage += `PAR Maximum value reached. Max PAR allowed is ${errorObj.parCountMaximumSettings}\n`;
                  break;
                case 'AAR_MINIMUM_VALIDATION_FAILED':
                  errorMessage += `AAR Minimum value has not reached. Min AAR required is ${errorObj.aarCountMinimumSettings}\n`;
                  break;
                case 'AAR_MAXIMUM_VALIDATION_FAILED':
                  errorMessage += `AAR Maximum value reached. Max AAR allowed is ${errorObj.aarCountMaximumSettings}\n`;
                  break;
                case 'AVA_MINIMUM_VALIDATION_FAILED':
                  errorMessage += `AVA Minimum value has not reached. Min AVA required is ${errorObj.avaCountMinimumSettings}\n`;
                  break;
                case 'AVA_MAXIMUM_VALIDATION_FAILED':
                  errorMessage += `AVA Maximum value reached. Max AVA allowed is ${errorObj.avaCountMaximumSettings}\n`;
                  break;
                default:
                  errorMessage += 'Unknown error\n';
                  break;
              }
            });
          }
        } else if (data?.data === 'REPRESENTATIVE_STATUS_IS_NOT_ACTIVE') {
          errorMessage = 'Representative Not Active';
        } else {
          const CustomErrorMsg = `Facility ID(s) ${error?.response?.data?.data} associated with another entity.`
          errorMessage = error?.response?.data?.messageKey === 'FACILITY_ALREADY_ASSOCIATED_TO_AN_ENTITY' ? CustomErrorMsg : error?.response?.data?.messageKey;
        }

        const modelData = {
          title: ERROR,
          message1: errorMessage,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(modelData));
        logger({ error, name: "Approve/Deny Update" });
        dispatch(setLoader(false));
      });
  };
}



export function revisionRequested(data, id) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherPut(`${APIS.REVISIONS_REQUESTED}/${id}`, data, {})
      .then(() => {
        dispatch(getEntityContactById(id));
        const data = {
          title: SUCCESS,
          message1: SUCCESSFULLY_SAVED,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      });
  };
}

export function getRepresentativeStatusHistory(id, pageDetails = { componentName: 'representativeStatusHistory' }) {
  return (dispatch) => {
    fetcherGet(`${APIS.REPRESENTATIVE_STATUS_HISTORY}/${id}`, {}, pageDetails)
      .then((response) => {
        dispatch(setRepresentativeStatusHistory(response));
      })
      .catch((error) => {
        logger({ error, name: "getRepresentativeStatusHistory" });
        if (error?.response?.data?.messageKey === "DATA_NOT_FOUND") {
          dispatch(setRepresentativeStatusHistory([]));
        }
        dispatch(setLoader(false));
      });
  };
}

export const addGroupName = (payload) => (dispatch) => {
  fetcherPost(`${APIS.CAG_DETAILS}`, payload)
    .then((response) => {
      const data = {
        title: SUCCESS,
        message1: RESPONSE_MESSAGE_KEYS.CAG_GROUP_ADDED_SUCCESSFULLY,
        error: false,
        isDefaultDisplay: false,
        responseId: response.data
      };
      dispatch(setModelMessage(data));
      dispatch(setResponseID(data.responseId.id));
      dispatch(setCagMembers([]));
      dispatch(getCAGGroupLists())
      dispatch(setLoader(false))
    })
    .catch((error) => {
      logger({ error });
      const data = {
        title: ERROR,
        message1: error?.response?.data?.messageKey,
        error: true,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      dispatch(setLoader(false))
    });
}

export const addCagMember = (payload) => (dispatch) => {
  fetcherPost(`${APIS.ADD_CAG_MEMBERS}`, payload?.membersData)
    .then((response) => {

      dispatch(setCagMembers(response?.data?.map((member) => {
        if (member?.existingHlValue || member?.existingPlValue) {
          return { ...member, holdingLimitSharePercent: member?.existingHlValue, purchaseLimitSharePercent: member?.existingPlValue, updatedHL: member?.holdingLimitSharePercent, updatedPL: member?.purchaseLimitSharePercent }
        }
        return member
      })));
      dispatch(setLoader(false))
      const data = {
        title: SUCCESS,
        message1: response?.messageKey,
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      payload?.successCallback?.()
    })
    .catch((error) => {
      payload?.callBack();
      logger({ error });
      let CagError = error?.response?.data?.messageKey;
      if (error?.response?.data?.messageKey === "CAG_MEMBER_ALREADY_EXIST") {
        CagError = "The Entity is Already in Group";
      }
      const groupNumber = error?.response?.data?.data;
      const data = {
        title: ERROR,
        message1: groupNumber ? `${CagError} ${groupNumber}` : CagError,
        error: true,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      dispatch(setLoader(false));
    });
}

export const UpdateCAGChanges = (cagId, payload) => {
  return (dispatch) => {
    fetcherPut(`${APIS.CAG_DETAILS}/${cagId}`, payload).then((response) => {
      dispatch(setLoader(false));
      const data = {
        title: SUCCESS,
        message1: response?.messageKey,
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      dispatch(setCatchNavigate(true));
      dispatch(setCagMembers([]));
      dispatch(setDeletedCAGMember(null));
    })
      .catch((error) => {
        logger({ error });
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false))
        dispatch(setCatchNavigate(false))
      });
  }
}

export const getEntityTypeChangeHistory = (id) => {
  return (dispatch) => {
    fetcherGet(`${APIS.ENTITY_TYPE_CHANGE_HISTORY}/${id}`, {})
      .then((response) => {
        dispatch(setEntityTypeChangeHistory(response.data));
      })
      .catch((error) => {
        logger({ error, name: "getEntityTypeChangeHistory" });
        if (error?.response?.data?.messageKey === "DATA_NOT_FOUND") {
          dispatch(setEntityTypeChangeHistory([]));
        }
        dispatch(setLoader(false));
      });
  };
}

export const getNameChangeHistory = (id) => {
  return (dispatch) => {
    fetcherGet(`${APIS.NAME_CHANGE_HISTORY}/${id}`, {})
      .then((response) => {
        dispatch(setNameChangeHistory(response.data));
      })
      .catch((error) => {
        logger({ error, name: "getEntityTypeChangeHistory" });
        if (error?.response?.data?.messageKey === "DATA_NOT_FOUND") {
          dispatch(setNameChangeHistory([]));
        }
        dispatch(setLoader(false));
      });
  };
}


export const verifyEnteredGroupName = (payload) => {
  return fetcherPost(`${APIS.VERIFY_GROUP_NAME}`, payload)
}

export const verifyEnteredGroupRef = (payload) => {
  return fetcherPost(`${APIS.VERIFY_GROUP_REF}`, payload)
}


export function getFieldPermission(data, pageDetails = {}) {
  return (dispatch) => {
    fetcherGet(`${APIS.FIELD_PERMISSIONS}`, data, pageDetails)
      .then((response) => {
        dispatch(setFieldPermissions(response.data));
      })
      .catch((error) => {
        logger({ error, name: "getAlternateContact" });
      });
  };
}

export function getProposedChangeRepresentatives(id) {
  return (dispatch) => {
    fetcherGet(`${APIS.GET_PROPOSED_REPRESENTATIVE}/${id}`, {})
      .then((response) => {
        dispatch(setChangeProposedRepresentatives(response.data))
      })
      .catch((error) => {
        logger({ error, name: "getProposedChangeRepresentatives" });
        dispatch(setLoader(false));
      });
  };
}

export const {
  setFormValueSubmittedOfStep,
  setNextStepSelect,
  setEntityIsAuction,
  setRetireSingleFacility,
  resetSteps,
  setFormValueOfStep,
  setFormValueOfStepByStepName,
  setAlternateContact,
  setSubmittedOfSelectedStep,
  setSelectedStep,
  setSelectedStepByStepNumber,
  setNextActiveStep,
  updateEditFieldState,
  setJurisdictions,
  setJurisdiction,
  setJurisdictionId,
  setEnableSaveBtnOnReview,
  setEntityType,
  setEntityTypes,
  setEntityID,
  setSubmittedState,
  setFilledData,
  setPurpose,
  setClassification,
  setFacilityCountry,
  setCountry,
  setStateProvince,
  setStateProvinceforMailing,
  setStateProvinceFacility,
  setStateProvinceFacilityforMailing,
  setStateProvinceIdentifiers,
  setFacilities,
  setFacilitiesList,
  setSingleFacility,
  setSelectedFacility,
  setOrganization,
  setSelectedOrganization,
  setRoles,
  setSelfInformation,
  setProposedRepresentatives,
  removeProposedRepresentativeByIndex,
  setProposedRepresentativeRoleByIndex,
  setRepresentative,
  setOriginalRep,
  resetRepresentative,
  setRepresentativesByGET,
  setRepresentativeOnLocal,
  setRepresentativeStatusHistory,
  setNAICS,
  setAddFacilityStep,
  removeAddFacilityStep,
  setSkipStepByKey,
  updateEntityEditFieldState,
  setIdentifierCountry,
  setJuryDisable,
  setRevisionComment,
  setIsLocked,
  setSelectedClassification,
  setShortEntityID,
  setEditAccess,
  setEntities,
  resetEntitiesData,
  setFacilityAddMode,
  setCagMembers,
  setUpdatedRetiredCagMembers,
  setCatchNavigate,
  setRecheckDuplicateAR,
  setCancelAlternateContact,
  setResetAlternateContactForm,
  setFieldPermissions,
  setNameChangeHistory,
  setEntityTypeChangeHistory,
  setHandleRepReset,
  setErrorOnAddRepresentativeForm,
  setGeneralAccordion,
  setcontactInfoAccordion,
  setFacilityAccordion,
  setARAccordion,
  setLoadingJurisdictions,
  setLockResponse,
  setHLColumnError,
  setPLColumnError,
  setDisabledFacilitySubmitBtn,
  setDisabledCAGDetailsSubmitBtn,
  setMaillingBtnDisable,
  setChangeProposedRepresentatives,
  setLockIntervalId
} = entityApplicationSlice.actions;
