import { Typography, Grid, Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {  t, getCurrentPageName } from "utils/constants";

function PrivilegeHeader(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());

  return (
    <Grid item sm={12}>
      <div className="titalwithbtn">
        <Typography variant="h3">{t(t1, "PM_H1_PRIVILEGE_MANAGEMENT")}</Typography>
      </div>
    </Grid>
  );
}

PrivilegeHeader.propTypes = {
  subTitle: PropTypes.string,
  buttonValue: PropTypes.string,
};

export default PrivilegeHeader;
