import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {  t, getCurrentPageName } from "utils/constants";
import Style from "./CustomizeSelect.style";

export default function CustomizeTextfield(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; 
  const {
    formSteps: { isEditField, activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { name, type = "text", label, rules, noValueText, options, stepNumber, InputProps, privId="", zeroAccepted=false, isScrollable=false, customStyle, isRequiredMsg='' } = props;

    
  let readOnly = false;
  if (privId === "") {
    if (PrivAttributes?.[name]?.PrivRole === "NA") {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === "RO";
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === "NA") {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === "RO";
  }

  if (rules && rules?.required && label) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }

  return (
    <>
      {label ? (
        <InputLabel htmlFor="customize-select" sx={Style.FieldLabel}>
          {label}
          {rules?.required ? (
            <Typography
              variant="body2"
              sx={{
                display: "inline-block",
                color: "common.red",
                ml: 0.3,
                fontWeight: 700,
              }}
            >
              *
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      {(!isEditField[stepNumber] && activeStep === 4) || readOnly ? (
        <Typography variant="body2" sx={Style.valuetext}>
          {getValues(name) || "N/A"}
        </Typography>
      ) : (
        <Controller
          name={name}
          type={type}
          rules={rules}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <Select
                sx={{
                  ...Style.formField,
                  ...customStyle
                }}
                {...field}
                displayEmpty
                inputProps={{ "data-testid": noValueText, ...InputProps }}
                htmlFor="customize-select"
                error={Boolean(fieldState.error)}
                MenuProps={isScrollable && {
                  PaperProps: { sx: { maxHeight: 230 }}
                }}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  props.onChange(event);
                }} // updates the value of select field and calls onChange passed in prop
                value={(zeroAccepted && !props.value) ? props.value : (props.value || "")}
              >
                <MenuItem value="" disabled>{props.fieldValue || noValueText}</MenuItem>
                {options?.map((option) => (
                  <MenuItem key={option.title} value={option.title}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
              {/* {fieldState?.error?.message ? <FormHelperText error>{fieldState?.error?.message}</FormHelperText> : null} */}
              <FormHelperText error>{fieldState?.error?.message || " "}</FormHelperText>
            </>
          )}
        />
      )}
    </>
  );
}
