export const prepareDataForSaveOrPublishDynamicColumn = (unsavedData, isJSAUser, isPublish, searchedData, hasUnpublishedChanges) => {
  const keysArray = Object.keys(unsavedData);
  const keyDataToUpdate = [];
  if (isPublish && hasUnpublishedChanges) {
    searchedData.forEach((keyInfo) => {
      if (keyInfo?.hasUnpublishedChanges) {
        // This check is for denial reason type data as it has 'otherLangItems' array only n no other types
        if (keyInfo?.otherLangItems?.length > 0) {
          const denialObjArray = [];
          keyInfo?.otherLangItems?.forEach((langData) => {
            const obj = {
              defaultItemId: langData.defaultItemId,
              baseItemId: langData.baseItemId,
              languageId: langData.languageId
            }
            if (isJSAUser) {
              obj.juriItemId = langData.juriItemId;
              obj.displayedValue = langData.displayedValue;
            } else {
              obj.displayedValue = langData.displayedValue;
            }
            denialObjArray.push(obj);
          });
          keyDataToUpdate.push({
            otherLangItems: denialObjArray
          })
        }
      }
    });
  }
  keysArray.forEach((key) => {
    const denialObjArray = [];
    const keyInfo = unsavedData[key];
    keyInfo?.otherLangItems?.forEach((langData) => {
      const obj = {
        defaultItemId: langData.defaultItemId,
        baseItemId: langData.baseItemId,
        languageId: langData.languageId,
      }
      if (isJSAUser) {
        obj.juriItemId = langData.juriItemId;
        obj.displayedValue = keyInfo[`display-${langData.languageId}`];
      } else {
        obj.displayedValue = keyInfo[`display-${langData.languageId}`];
      }
      denialObjArray.push(obj);
    });
    keyDataToUpdate.push({
      otherLangItems: denialObjArray
    })
  });
  return keyDataToUpdate;
}

export const prepareDataForSaveOrPublish = (unsavedData, isJSAUser, isPublish, searchedData, hasUnpublishedChanges, isForSearchAndReplace) => {
  const keysArray = Object.keys(unsavedData);
  const keyDataToUpdate = [];
  if (isPublish) {
    searchedData.forEach((keyInfo) => {
      if (keyInfo?.hasUnpublishedChanges || isForSearchAndReplace) {
        const finalKeyInfo = {
          defaultItemId: keyInfo.defaultItemId,
          baseItemId: keyInfo.baseItemId,
        }
        if (isJSAUser) {
          finalKeyInfo.juriItemId = keyInfo.juriItemId;
          finalKeyInfo.displayedValue = keyInfo.displayedValue;
        } else {
          finalKeyInfo.displayedValue = keyInfo.displayedValue;
        }
        keyDataToUpdate.push(finalKeyInfo);
      }
    });
  }
  keysArray.forEach((key) => {
    const keyInfo = unsavedData[key];
    const finalKeyInfo = {
      defaultItemId: keyInfo.defaultItemId,
      baseItemId: keyInfo.baseItemId,
    }
    if (isJSAUser) {
      finalKeyInfo.juriItemId = keyInfo.juriItemId;
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    } else {
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    }
    keyDataToUpdate.push(finalKeyInfo);
  });
  return keyDataToUpdate;
}

export const prepareDataForSaveOrPublishForPageContentSection = (editedDataForPageContentSection, isJSAUser, isPublish, searchedData, hasUnpublishedChanges) => {
  const displayedValueEditedKeys = Object.keys(editedDataForPageContentSection?.currentEditedDisplayedValueData);
  const displayedIIconsEditedKeys = Object.keys(editedDataForPageContentSection?.currentEditedDisplayedIIconsData);
  const cardsKeys = Object.keys(editedDataForPageContentSection?.editedCardsData);
  const succesToastKeys = Object.keys(editedDataForPageContentSection?.editedSuccessToastsData);
  const errorToastKeys = Object.keys(editedDataForPageContentSection?.editedErrorToastsData);
  const otherCardsKeys = Object.keys(editedDataForPageContentSection?.editedOtherCardsData);
  const otherErrorCardsKeys = Object.keys(editedDataForPageContentSection?.editedOtherErrorCardsData);
  const denialReasonKeys = Object.keys(editedDataForPageContentSection?.currentEditedDenialReasonData);
  const keyDataToUpdate = [];

  if (isPublish && hasUnpublishedChanges) {
    searchedData.forEach((keyInfo) => {
      if (keyInfo?.hasUnpublishedChanges) {
        // This check is for denial reason type data as it has 'otherLangItems' array only n no other types
        if (keyInfo?.otherLangItems?.length > 0) {
          const denialObjArray = [];
          keyInfo?.otherLangItems?.forEach((langData) => {
            const obj = {
              defaultItemId: langData.defaultItemId,
              baseItemId: langData.baseItemId,
              languageId: langData.languageId
            }
            if (isJSAUser) {
              obj.juriItemId = langData.juriItemId;
              obj.displayedValue = langData.displayedValue;
            } else {
              obj.displayedValue = langData.displayedValue;
            }
            denialObjArray.push(obj);
          });
          keyDataToUpdate.push({
            otherLangItems: denialObjArray
          })
        } else {
          const finalKeyInfo = {
            defaultItemId: keyInfo.defaultItemId,
            baseItemId: keyInfo.baseItemId,
          }
          if (isJSAUser) {
            finalKeyInfo.juriItemId = keyInfo.juriItemId;
            finalKeyInfo.displayedValue = keyInfo.displayedValue;
          } else {
            finalKeyInfo.displayedValue = keyInfo.displayedValue;
          }
          keyDataToUpdate.push(finalKeyInfo);
        }
      }
    });
  }

  displayedValueEditedKeys.forEach((key) => {
    const keyInfo = editedDataForPageContentSection?.currentEditedDisplayedValueData[key];
    const finalKeyInfo = {
      defaultItemId: keyInfo.defaultItemId,
      baseItemId: keyInfo.baseItemId,
    }
    if (isJSAUser) {
      finalKeyInfo.juriItemId = keyInfo.juriItemId;
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    } else {
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    }
    keyDataToUpdate.push(finalKeyInfo);
  });
  displayedIIconsEditedKeys.forEach((key) => {
    const keyInfo = editedDataForPageContentSection?.currentEditedDisplayedIIconsData[key];
    const finalKeyInfo = {
      defaultItemId: keyInfo.defaultItemId,
      baseItemId: keyInfo.baseItemId,
    }
    if (isJSAUser) {
      finalKeyInfo.juriItemId = keyInfo.juriItemId;
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    } else {
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    }
    keyDataToUpdate.push(finalKeyInfo);
  });
  cardsKeys.forEach((key) => {
    const keyInfo = editedDataForPageContentSection?.editedCardsData[key];
    const finalKeyInfo = {
      defaultItemId: keyInfo.defaultItemId,
      baseItemId: keyInfo.baseItemId,
    }
    if (isJSAUser) {
      finalKeyInfo.juriItemId = keyInfo.juriItemId;
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    } else {
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    }
    keyDataToUpdate.push(finalKeyInfo);
  });
  succesToastKeys.forEach((key) => {
    const keyInfo = editedDataForPageContentSection?.editedSuccessToastsData[key];
    const finalKeyInfo = {
      defaultItemId: keyInfo.defaultItemId,
      baseItemId: keyInfo.baseItemId,
    }
    if (isJSAUser) {
      finalKeyInfo.juriItemId = keyInfo.juriItemId;
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    } else {
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    }
    keyDataToUpdate.push(finalKeyInfo);
  });
  errorToastKeys.forEach((key) => {
    const keyInfo = editedDataForPageContentSection?.editedErrorToastsData[key];
    const finalKeyInfo = {
      defaultItemId: keyInfo.defaultItemId,
      baseItemId: keyInfo.baseItemId,
    }
    if (isJSAUser) {
      finalKeyInfo.juriItemId = keyInfo.juriItemId;
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    } else {
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    }
    keyDataToUpdate.push(finalKeyInfo);
  });
  otherCardsKeys.forEach((key) => {
    const keyInfo = editedDataForPageContentSection?.editedOtherCardsData[key];
    const finalKeyInfo = {
      defaultItemId: keyInfo.defaultItemId,
      baseItemId: keyInfo.baseItemId,
    }
    if (isJSAUser) {
      finalKeyInfo.juriItemId = keyInfo.juriItemId;
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    } else {
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    }
    keyDataToUpdate.push(finalKeyInfo);
  });
  otherErrorCardsKeys.forEach((key) => {
    const keyInfo = editedDataForPageContentSection?.editedOtherErrorCardsData[key];
    const finalKeyInfo = {
      defaultItemId: keyInfo.defaultItemId,
      baseItemId: keyInfo.baseItemId,
    }
    if (isJSAUser) {
      finalKeyInfo.juriItemId = keyInfo.juriItemId;
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    } else {
      finalKeyInfo.displayedValue = keyInfo.displayedValue;
    }
    keyDataToUpdate.push(finalKeyInfo);
  });

  denialReasonKeys.forEach((key) => {
    const denialObjArray = [];
    const keyInfo = editedDataForPageContentSection?.currentEditedDenialReasonData[key];
    keyInfo?.otherLangItems?.forEach((langData) => {
      const obj = {
        defaultItemId: langData.defaultItemId,
        baseItemId: langData.baseItemId,
        languageId: langData.languageId,
        isShown: keyInfo.isShown,
      }
      if (isJSAUser) {
        obj.juriItemId = langData.juriItemId;
        obj.displayedValue = keyInfo[`display-${langData.languageId}`];
      } else {
        obj.displayedValue = keyInfo[`display-${langData.languageId}`];
      }
      denialObjArray.push(obj);
    });
    keyDataToUpdate.push({
      otherLangItems: denialObjArray
    })
  });
  console.log('keyDataToUpdate=', keyDataToUpdate)
  return keyDataToUpdate;
}

/**
 * 
 * @param {*} inputStr 
 * @param {*} indexOf 
 * @returns 
 * 
 * For example:
 * inputStr = "English#L001"
 * indexOf = 0
 * output = English
 * 
 * inputStr = "English#L001"
 * indexOf = 1
 * output = L001
 */
export const decode = (inputStr, indexOf ) => {
  const str = inputStr?.split("($%&)")?.[indexOf] || "";
  return str;
}
