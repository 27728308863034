import { JURISDICTION_TRANSFER_TYPES_BE, JURISDICTION_TRANSFER_TYPES_FE, t } from "utils/constants";

export const SET_UP_JURI_ACCOUNTS_COMPONENTS = {
  JURISDICTION_INFO: "JurisdictionInfo",
  DEFAULT_ACCOUNTS: "DefaultAccounts",
  ACCOUNT_NAME_LANGUAGES: "AccountNameLanguages",
  ADD_NEW_JURISDICTION_ACCOUNT: "AddNewJurisdictionAccount",
  ADD_DEFAULT_ACCOUNT: "addDefaultAccounts", // post to add juri default accounts
};

export const SET_UP_JURI_ACCOUNTS_PAGES = {
  SET_UP_JURI_ACCOUNTS: "Page_SetupJurisdictionAccounts",
  ADD_NEW_JURISDICTION_ACCOUNTS: "Page_AddNewJurisdictionAccount",
  ADD_DEFAULT_JURISDICTION_ACCOUNTS: "Page_AddJurisdictionAccounts",
};

export const transformLanguages = (data) =>
  data?.map((item) => ({
    id: item?.LanguageDetails?.id,
    name: item?.LanguageDetails?.name,
    displayName: item?.LanguageDetails?.display_name,
  })).sort((a, b) => a.displayName.localeCompare(b.displayName));

export const transformDefaultAcc = (data) =>
  data?.map((item) => ({
    id: item?.account_type_id,
    accountType: item?.account_type_name,
    accountName: item?.account_type_name,
    isSelected: item?.isSelected || item?.account_type_name === "Issuance",
  }));

export const transformComplianceInstru = (data) =>
  data?.map((item) => ({
    id: item?.id,
    value: item?.id,
    displayName: item?.name,
    disabled: true,
  }));

export const transformTransfersList = (data) =>
  data
    // ?.filter((i) => JURISDICTION_TRANSFER_TYPES_BE[i?.name])
    ?.map((item) => ({
      id: item?.id,
      value: item?.id,
      displayName: JURISDICTION_TRANSFER_TYPES_FE[item?.name],
      name: item?.name,
    }));

export const defaultSelected = (data) => data?.filter((item) => item?.isSelected === true)?.map((i) => i?.id);

export function getFilteredAccounts(idsToFilter, accounts) {
  // Use the filter method to get accounts with matching ids
  const filteredAccounts = accounts?.filter((account) => idsToFilter.includes(account.id));
  return filteredAccounts;
}

export const showAccountName = (id, arr) => arr?.find((i) => i?.id === id)?.accountType || "";

export const prefillInstruments = (data) =>
  data[0]?.complianceInstrumentIdArray?.map((i) => i?.jurisdictionComplianceInstrumentId);

export const prefillTransfers = (data) =>
  data[0]?.transferTypeIdArray
    ?.filter((item) => JURISDICTION_TRANSFER_TYPES_BE[item?.transferTypeName])
    ?.map((i) => i?.transfer_type_id);

export function filterDataByAccountId(accountTypeId, jsonData) {
  // Use the filter method to get objects with matching jurisdiction_account_type_id
  const filteredData = jsonData.filter((item) => {
    const complianceInstrumentIdArray = item.complianceInstrumentIdArray || [];
    return complianceInstrumentIdArray.some(
      (complianceInstrument) => complianceInstrument.jurisdiction_account_type_id === accountTypeId
    );
  });

  return filteredData;
}

export const getDistinctArray = (arr1, arr2) => arr1.filter((value) => !arr2.includes(value));

export const ACCOUNT_NAMES = {
  issuance: "Issuance",
  allocation: "Allocation",
};

const transferFromAccount = (transfersList, accName) => {
  let updatedtransferList = [];
  if (accName === ACCOUNT_NAMES.allocation) {
    // allocation
    updatedtransferList = transfersList?.filter(
      (item) =>
        item?.name === JURISDICTION_TRANSFER_TYPES_BE.JURISDICTION_BATCH_TRANSFER ||
        item?.name === JURISDICTION_TRANSFER_TYPES_BE.Replenishment
    );
  }
  if (accName === ACCOUNT_NAMES.issuance) {
    // Issuance
    updatedtransferList = transfersList?.filter(
      (item) =>
        item?.name === JURISDICTION_TRANSFER_TYPES_BE.JURISDICTION_BATCH_TRANSFER ||
        item?.name === JURISDICTION_TRANSFER_TYPES_BE.JURISDICTION_BATCH_TRANSFER_PCRA ||
        item?.name === JURISDICTION_TRANSFER_TYPES_BE.AllowanceIssuance
    );
  } 
  if(accName !== ACCOUNT_NAMES.issuance && accName !== ACCOUNT_NAMES.allocation) {
    // other accounts
    updatedtransferList = transfersList?.filter(
      (item) => item?.name === JURISDICTION_TRANSFER_TYPES_BE.JURISDICTION_BATCH_TRANSFER
    );
  }
  return updatedtransferList;
};
// Func: will return array with same obj just adding "disabled: true" if mandatory flag is true
export function getFilteredTransfer(transfersList, mantatoryTransfers, accName) {
  const resultArray = transferFromAccount(transfersList, accName)?.map((item1) => {
    const matchingItem = mantatoryTransfers?.find(
      (item2) => item2.transfer_type_id === item1.id && item2.mandatory === true
    );
    // If a match is found, append 'disabled: true' to the item
    if (matchingItem) {
      return { ...item1, disabled: true };
    }
    // If no match is found, return the item as is
    return item1;
  });

  return resultArray;
}

// func: will return the payload of account name details | array of objects
export const createPayloadAccNameDetails = (languages, state) =>
  languages?.map((lang) => state[`langDetails${lang?.name}`]);

// func to send offset id as well in payload. since we're showing only allowance
export function createTransfersPayload(transfersState, allTransfers) {
  const hasAllowanceIssuance = allTransfers.some(
    (obj) => obj.name === 'AllowanceIssuance' && transfersState.includes(obj.id)
  );
  const offsetObj = allTransfers.find((o) => o.name === 'OffsetIssuance');
  if (hasAllowanceIssuance && offsetObj && !transfersState.includes(offsetObj.id)) {
    return [...transfersState, offsetObj.id];
  }
  return transfersState;
}

const ACCOUNT_NAME_ALREADY_EXISTS = "JURISDICTION_ACCOUNT_NAME_ALREADY_EXIST";
const ACCOUNT_TYPE_ALREADY_EXISTS = "JURISDICTION_ACCOUNT_TYPE_ALREADY_EXIST";

export function getMultipleErrorStringFromResponse(errors, t1){
  const accNameVals = [];
  const accTypeVals = [];
  
  if (Array.isArray(errors) && errors.length) {
    const errorsTransformed = errors.map(errorMessage => {
      const errorkey = errorMessage.split('::');
      if (errorkey[1]) {
        if (errorkey[0]?.trim() === ACCOUNT_NAME_ALREADY_EXISTS) {
          accNameVals.push(errorkey[1]);
        } else if (errorkey[0]?.trim() === ACCOUNT_TYPE_ALREADY_EXISTS) {
          accTypeVals.push(errorkey[1]);
        } else {
          return `${t(t1, errorkey[0]?.trim())} : ${errorkey[1]?.trim() || ""}`;
        }
      } else {
        return `${t(t1, errorkey[0]?.trim())}`;
      }
      
      return '';
    }).filter(str => str !== '');
    
    if (accNameVals?.length !== 0) {
      errorsTransformed.push(`${t(t1, ACCOUNT_NAME_ALREADY_EXISTS)}: ${accNameVals.join(', ')}`);
    }
    if (accTypeVals?.length !== 0) {
      errorsTransformed.push(`${t(t1, "ACCOUNT_TYPE_ALREADY_EXIST")}: ${accTypeVals.join(', ')}`);
    }
    return errorsTransformed;
  }
  if (typeof errors === "string") return t(t1, errors);
  return null;
}
