import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { DialogMessage } from "components";
import { ButtonFill } from "components/ButtonFill";
import { ButtonFillEdit } from "components/ButtonFillEdit";
import { ButtonOutline } from "components/ButtonOutline";
import { CustomeDialogMessage } from "components/CustomeDialogMessage";
/* Conflict Resolution Candidate
import useAuth from "hooks/useAuth";
import { CustomizeButton } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilledData,
  setInitialPrimaryChecked,
  setPrimaryChecked,
  updateEditFieldState,
  updateEditFieldUserProfileState,
} from "store/createUserProfileSlice";
import { setApprovalFields, setApprovalValues } from "store/userSlice";
import {
  t,
  getCurrentPageName,
  stepWiseFieldUserRegistration,
  stepWiseFieldUserProfile,
  compareTwoArrays,
  isEmpty,
  setApprovedArrayValues,
} from "utils/constants";
 */

import { CustomizeButton } from "components/CustomizeButton";
import { setDialogMessage, setIsFormEditable, setModelMessage } from "store/commonSlice";
import { setEditMode, setInitialPrimaryChecked, setPrimaryChecked, updateEditFieldState } from "store/createUserProfileSlice";
import { COMPONENT_MODES, COMPONENT_NAMES, getCurrentPageName, SUCCESS, t } from "utils/constants";

import Style from "./FormCard.style";

export default function FormCard({
  customClass,
  children,
  title = "",
  subTitle = "",
  indicateRequired = true,
  formLabel = "",
  handleSameAddress,
  setDefaultValues,
  stepNumber = 0,
  disableElevation,
  defaultValues,
  customeStyle = "",
  saveDisabled = false,
  methods = null,
  disabledCheck = true,
  disabledAddMemberButton,
  editDisabled,
/* Conflict Resolution Candidate
  methods = {},
}) {
  const { t: t1 } = useTranslation(getCurrentPageName());
 */
  defaultChecked = false,
  mode = COMPONENT_MODES.ADD,
  handleEnableEdit = null,
  stepData = null,
  handleSubmitUpdate,
  check = false,
  cancelContactEdit = null,
  isRevisionRequested = false,
  isEditAccess = true,
  cancelAuction = null,
  cancelRegistraionContactEdit = null,
  cancelEntityIdentifiers = null,
  showCancelPopup = false,
  showSavePopup = false,
  hideCancelSaveBtn = false,
  hideEditBtn = false,
  SaveCancelBtnForFacilities = false,
  handleCancelFacility = false,
  handleCancelRepresentative = false,
  cancelEntityType = false,
  handleCancelAlternateRepresentative = false,
  renderContent = false,
  stepName = null,
  addMembers = false,
  handleCancelCAG = false,
  noMargin = false,
  showAddMemberButton = false,
  showCancelSaveForFacility = true,
  setShowFormInFacility = null,
  hideCard = false,
  hideCancel,
  setMailDisabled = null,
  formCardId = null,
  auctionPage = false,
  titleSize= false,
  isEntityActive= false,
}) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { submitted, filledData } = useSelector(({ createUserProfile }) => createUserProfile);
  const {
    formSteps: { activeStep, isEditField },
    primaryChecked,
    initialPrimaryChecked,
    /* Conflict Resolution Candidate
        filledData,
      } = useSelector(({ createUserProfile }) => createUserProfile);
      const dispatch = useDispatch();
      const { isSignedIn } = useAuth();
      const [checked, setChecked] = useState(primaryChecked);
     */
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { dialogMessage } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(initialPrimaryChecked || defaultChecked);
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [isEdit, setIsEdit] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  /* Conflict Resolution Candidate
  
    const { approvalFields, approvalValues } = useSelector(({ user }) => user);
   */
  const [CancelModal, setCancelModal] = useState(false)
  const [SaveModal, setSaveModal] = useState(false)


  useEffect(() => {
    closeEditAction();
    if (defaultValues) {
      /* Conflict Resolution Candidate
            setChecked(primaryChecked);
          }
        }, []);
      
        useEffect(() => {
          // console.log('primary checked use');
          setChecked(initialPrimaryChecked);
        }, [initialPrimaryChecked, isEdit]);
      
        // console.log(primaryChecked,"primaryChecked")
        const handleCheckbox = (e) => {
          const { checked } = e.target;
          // setChecked(checked);
          dispatch(setPrimaryChecked(checked));
          if (activeStep === 4 && !checked) {
            // setDefaultValues();
            handleSameAddress(checked);
          } else {
            // dispatch(setInitialPrimaryChecked(checked))
       */
      setChecked(initialPrimaryChecked);
    }
  }, []);

  const handleCheckbox = (e) => {
    const { checked } = e.target;
    setChecked(checked);
    if (activeStep === 4 && !checked) {
      handleSameAddress(checked);
    } else {
      // dispatch(setInitialPrimaryChecked(checked));
      handleSameAddress(checked);
    }
  };

  const handleEdit = () => {
    dispatch(setEditMode(+1));  // RPM-2030
    /* Conflict Resolution Candidate
        dispatch(updateEditFieldUserProfileState(true));
        dispatch(updateEditFieldState({ step: stepNumber, value: true }));
        setIsEdit({ ...isEdit, [stepNumber]: true });
        if (isSignedIn && stepNumber === 4) handleSameAddress(primaryChecked);
      };
    
      const handleEditCancel = () => {
        // dispatch(updateEditFieldUserProfileState(false));
        setDefaultValues(stepNumber);
        closeEditAction();
        // dispatch(setPrimaryChecked(initialPrimaryChecked))
        // setChecked(initialPrimaryChecked)
        if (stepNumber === 4) {
          dispatch(setPrimaryChecked(initialPrimaryChecked));
          if (handleSameAddress) handleSameAddress(initialPrimaryChecked);
        }
      };
     */
    if (window?.location?.pathname === '/change-jurisdiction') {
      setShowDisablePopup(true);
    } else {
      // added to show error on edit/review page for residance n mailing section
      // when residence fields hav errors
      if (methods) {
        methods?.setValue("mailingAddress", {
          country: methods.getValues(`mailingAddress.country`),
          stateOrProvince: methods.getValues(`mailingAddress.stateOrProvince`),
          street1: methods.getValues(`mailingAddress.street1`),
          street2: methods.getValues(`mailingAddress.street2`),
          city: methods.getValues(`mailingAddress.city`),
          region: methods.getValues(`mailingAddress.region`),
          postalCode: methods.getValues(`mailingAddress.postalCode`),
        }, { shouldValidate: true });
        setTimeout(methods?.trigger, 0);
      }
      dispatch(setPrimaryChecked(initialPrimaryChecked));
      dispatch(updateEditFieldState({ step: stepNumber, value: true }));
      setIsEdit({ ...isEdit, [stepNumber]: true });
      if (submitted?.[stepNumber] || stepNumber === 4) {
        methods?.reset(filledData?.[stepNumber === 4 ? 3 : stepNumber])
      }
    }
  };

  const handleEntityEdit = () => {
    if (handleEnableEdit) {
      handleEnableEdit(true);
    }
    setShowFormInFacility?.(true);
  };

  const closeEntityEditAction = () => {
    if (handleEnableEdit) {
      handleEnableEdit(false);
    }
  };

  const handleSaveData = (data) => {
    if (handleSubmitUpdate) handleSubmitUpdate(data);
  };

  const ResetToOldValues = () => {
    // closeEntityEditAction(false);
    closeEntityEditAction();
    if (cancelContactEdit) {
      cancelContactEdit();
    }
    if (cancelAuction) {
      cancelAuction();
    }
    if (cancelRegistraionContactEdit) {
      cancelRegistraionContactEdit();
    }
    if (cancelEntityIdentifiers) {
      cancelEntityIdentifiers();
    }
    if (cancelEntityType) {
      cancelEntityType();
    }
    if (handleCancelAlternateRepresentative) {
      handleCancelAlternateRepresentative();
    }
    if (handleCancelCAG) {
      handleCancelCAG()
    }
    if (handleCancelRepresentative) {
      handleCancelRepresentative();
    }
  };

  const handleSaveBtn = (data) => {
    if (check) {
      setConfirmPopup(true);
    } else {
      handleFinalConfirm(data)
    }
  };

  const handleFinalConfirm = (data) => {
    if (showSavePopup) {
      dispatch(setDialogMessage(true));
      setSaveModal(true);
      setCancelModal(false);
      setConfirmPopup(false)
    } else {
      handleSaveData(data);
      setConfirmPopup(false);
    }
  }

  const handleEntityEditCancel = () => {
    if (showCancelPopup) {
      dispatch(setDialogMessage(true))
      setCancelModal(true);
      setSaveModal(false);
    }
    else {
      ResetToOldValues();
    }
    dispatch(setIsFormEditable(false));
  }

  const onClickDisbalePopupBtn = () => {
    setShowDisablePopup(false);
  }

  const onClickCancelModal = () => {
    const data = {
      title: SUCCESS,
      message1: t(t1, "SUCCESSFULLY_CANCELLED"),
      error: false,
      isDefaultDisplay: false,
    };
    dispatch(setModelMessage(data));
    ResetToOldValues();
    if (handleCancelFacility) {
      handleCancelFacility();
    }
    setCancelModal(false);
    handleModalCancel()
  }

  const handleModalCancel = () => {
    if (addMembers) {
      addMembers(false);
    }
    setCancelModal(false);
  }

  const handleModalSave = () => {
    if(stepName === 'CAG'){
      closeEntityEditAction()
    } else {
      handleSaveData();
    }
  }

  const handleEditCancel = () => {
    dispatch(setEditMode(-1)); // RPM-2030
    setDefaultValues(formCardId);
    closeEditAction();
    dispatch(setInitialPrimaryChecked(primaryChecked));
    dispatch(setPrimaryChecked(primaryChecked));
    setChecked(primaryChecked);
    // this check verifies if mail section is disabled but checkbox is unchecked then mail section is enabled
    // to allow user to edit mail section
    if (setMailDisabled && !primaryChecked) {
      setMailDisabled(false);
    }
  };

  const closeEditAction = () => {
    dispatch(updateEditFieldState({ step: stepNumber, value: false }));
    setIsEdit({ ...isEdit, [stepNumber]: false });
  };

  const handleUpdateCB = () => {
    dispatch(setEditMode(-1)); // RPM-2030
    /* Conflict Resolution Candidate
        // dispatch(updateEditFieldUserProfileState(false));
        if (!isEmpty(methods)) {
        const updatedValue = {};
        const updatedApprovalValue = { ...approvalValues };
        const dataArray = isSignedIn ? stepWiseFieldUserProfile : stepWiseFieldUserRegistration;
        // const stepNum = !isSignedIn && stepNumber === 4 ? 3 : stepNumber;
        // update the value of the change JU edit profile
        if (isSignedIn) {
          dispatch(setApprovalValues(setApprovedArrayValues(approvalFields, updatedApprovalValue, methods, defaultValues,stepNumber)));
          dataArray?.[stepNumber]?.forEach((field) => {
    
            if (!approvalFields?.AttributeValue?.includes(field?.replace('Date','BirthDate'))) {
              if(field === "ConfirmEmail"){
                updatedValue[field] = defaultValues?.[field];
                methods.setValue(field, defaultValues?.[field])
              } else {
                updatedValue[field] = methods.getValues(field);
                methods.setValue(field, methods.getValues(field))
              }
            }
            else {
                updatedValue[field] = defaultValues?.[field]
                methods.setValue(field, defaultValues?.[field])
              }
          });
        }
        dispatch(setFilledData({ step: stepNumber, data: updatedValue }));
        // else {
        //   console.log("handleUpdateCB is not signed in")
        //   dataArray?.[stepNum]?.forEach((field) => {
        //     updatedValue[field] = methods.getValues(field);
        //   });
        // }
        }
        closeEditAction();
        dispatch(setPrimaryChecked(primaryChecked));
        dispatch(setInitialPrimaryChecked(primaryChecked));
     */
    closeEditAction();
    dispatch(setPrimaryChecked(checked));
    dispatch(setInitialPrimaryChecked(checked));
    if (checked) {
      methods?.setValue("mailingAddress", methods.getValues("primaryResidence"), {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  };

  const content =
    <>
      {/* Conflict Resolution Candidate
  <Grid container spacing={2.5}>
    <Grid item md={9}>
      {title ? (
        <Typography variant="h2" component="div" sx={{ mb: 0.5 }}>
          {title}
        </Typography>
      ) : null}
      {indicateRequired ? (
        <Typography variant="caption" component="div" display="block" sx={{ mb: 3 }} className="indicate_fields">
  */}
      <Grid
        container
        spacing={2.5}
      >
        <Grid
          item
          md={9}
          sx={{ marginBottom: auctionPage ? "20px" : "" }}
        >
          {title ? (
            <Typography
              variant="h2"
              component="div"
              sx={{ mb: 0.5, fontSize: titleSize && "16px" }}
            >
              {title}
            </Typography>
          ) : null}

          {subTitle ? (
            <Typography
              variant="subtitle3"
              component="div"
              sx={{ mb: 0.5 }}
            >
              {subTitle}
            </Typography>
          ) : null}

          {indicateRequired ? (
            <Typography
              variant="caption"
              component="div"
              display="block"
              sx={{ mb: 3 }}
              className="indicate_fields"
            >
              <Typography
                variant="body2"
                sx={{
                  display: "inline-block",
                  color: "common.red",
                  mr: 0.5,
                  fontWeight: 700,
                  mb: "0px",
                }}
              >
                *
              </Typography>
              {/* Conflict Resolution Candidate
          {t(t1, "INDICATE_REQUIRED_FIELDS")}
        </Typography>
      ) : null}
    </Grid>
    {activeStep === 4 && !isEdit[stepNumber] && title !== t(t1, "SELECTED_JURISDICTION") && stepNumber !== 0 ? (
      <Grid item md={3} sx={{ textAlign: "right" }}>
        <ButtonFillEdit styleSx={{ py: 0.4, px: 2, minWidth: 80, minHeight:33 }} handleClick={handleEdit}>
      */}
              {t(t1, "INDICATE_REQUIRED_FIELDS") || "Indicate Required Fields"}
            </Typography>
          ) : null}
        </Grid>

        {stepData && !stepData.editMode && mode === COMPONENT_MODES.EDIT && !isRevisionRequested && isEditAccess && (
          <Grid
            item
            md={3}
            sx={{ textAlign: "right" }}
            style={{ marginBottom: noMargin ? 0 : "20px" }}
            className="edit-btn-wrapper"
          >
            <ButtonFillEdit
              styleSx={{ py: 0.25, px: 2, minWidth: 80, width: 80, height: 30 }}
              handleClick={handleEntityEdit}
            >
              {t(t1, "EDIT")}
            </ButtonFillEdit>
          </Grid>
        )}
        {renderContent &&
          <Grid
            item
            md={3}
            sx={{ textAlign: "right" }}
            className="edit-btn-wrapper"
          >
            {renderContent}
          </Grid>
        }

        {stepData && !stepData.editMode && mode === COMPONENT_MODES.EDIT && isRevisionRequested && (
          <Grid
            item
            md={3}
            sx={{ textAlign: "right" }}
          >
            {isEditAccess && !isEntityActive &&
              <ButtonFillEdit
                variant="outlined"
                styleSx={{ py: 0.4, px: 2, minWidth: 150, height: 40, mb: 3, mt: 1, textTransform: "uppercase !important" }}
                handleClick={handleEntityEdit}
              >
                {t(t1, "ADD_FACILITY")}
              </ButtonFillEdit>
            }
          </Grid>
        )}

        {activeStep === 4 && !isEdit[stepNumber] && title !== t(t1, "SELECTED_JURISDICTION") && stepNumber !== 0 && !hideEditBtn && isEditAccess ? (
          <Grid
            item
            md={3}
            sx={{ textAlign: "right" }}
          >
            <ButtonFillEdit
              styleSx={{ py: 0.4, px: 2, minWidth: 80 }}
              handleClick={handleEdit}
              disabled={editDisabled}
            >
              {t(t1, "EDIT")}
            </ButtonFillEdit>
          </Grid>
        ) : null}
      </Grid>
      {formLabel ? (
        /* Conflict Resolution Candidate
            <FormGroup sx={{ display: "inline-block" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={primaryChecked}
                    onChange={handleCheckbox}
        */
        <FormGroup>
          <FormControlLabel
            style={{ pointerEvents: "none" }}
            control={
              <Checkbox
                style={{ pointerEvents: "auto" }}
                checked={checked}
                onChange={(e) => handleCheckbox(e)}
                disabled={activeStep === 4 && !isEdit[stepNumber]}
              />
            }
            label={<span style={Style.customCheckboxLabel}>{formLabel}</span>}
          />
        </FormGroup>
      ) : null}
      <Box className="formContent">{children}</Box>
      {stepData && stepData.editMode && mode === COMPONENT_MODES.EDIT && !hideCancelSaveBtn && stepName === COMPONENT_NAMES.ADD_FACILITIES && !hideCancel &&
        <Box sx={{ mt: 0, textAlign: "right" }} className="cancel-save-btn-wrapper">
          <ButtonOutline
            styleSx={{ py: 0.7, px: 2, minWidth: 80, width: 80, mr: 1, height: 30, mt: 0 }}
            handleClick={handleEntityEditCancel}
            className="cancelBtnAction"
          >
            {t(t1, "CANCEL")}
          </ButtonOutline>
        </Box>}
      {/* Conflict Resolution Candidate
  {activeStep === 4 && isEdit[stepNumber] ? (
    <Box sx={{ mt: 2, textAlign: "right" }}>
      <ButtonOutline styleSx={{ py: 0.4, px: 2, minWidth: 80, mr: 2,minHeight:33 }} handleClick={handleEditCancel}>
  */}

      {stepData && stepData.editMode && mode === COMPONENT_MODES.EDIT && !hideCancelSaveBtn && (
        <Box sx={{ mt: 2.5, textAlign: "right" }} className="cancel-save-btn-wrapper">
          {(addMembers && showAddMemberButton) || (!addMembers && showAddMemberButton && showCancelPopup) ? (
            <ButtonOutline
              disabled={disabledAddMemberButton}
              styleSx={{ mr: 1, float: "left" }}
              handleClick={() => { addMembers(true) }}
            >
              {t(t1, "ADD_MEMBER")}
            </ButtonOutline>) : null
          }

          {showCancelSaveForFacility && (<> <ButtonOutline
            styleSx={stepName === "CAG" ? {} : { py: 0.7, px: 2, minWidth: 80, width: 80, mr: 1, height: 30 }}
            handleClick={handleEntityEditCancel}
            className="cancelBtnAction"
          >
            {t(t1, "CANCEL")}
          </ButtonOutline>
        
          <CustomizeButton
            type="submit"
            name="priv_btn_save"
            styleSx={{ py: 0.4, px: 2, minWidth: 80 }}
            handleClick={handleSaveBtn}
            disabled={stepName==="CAG" ? disabledCheck : saveDisabled}
            label={addMembers ? t(t1, "CHECK") : t(t1, "SAVE")}
            className="btn-primary-sm"
            customStyle={stepName==="CAG" ? {mr:1, width:150, height:40 } : { py: 0.4, px: 2, ml:0, minHeight:'auto', height:30, minWidth: 80 }}
          >
            {t(t1, "SAVE")}
          </CustomizeButton></>) }

          {/* Cancel dialog msg */}
          {showCancelPopup && dialogMessage && CancelModal ? (
            <DialogMessage
              warning
              title={t(t1, "WARNING")}
              message1={t(t1, "CANCEL_REVERT_MSG")}
              buttonMessage={t(t1, "CANCEL_CONTINUE_REVERT_MSG")}
              handleModalClick={onClickCancelModal}
            />
          ) : null}

          {/* Save dialog msg */}
          {showSavePopup && dialogMessage && SaveModal ? (
            <DialogMessage
              warning
              title={t(t1, "SAVE")}
              message1={t(t1, "CANCEL_REVERT_MSG")}
              buttonMessage={t(t1, "SAVE_CONTINUE_MSG")}
              handleModalClick={handleModalSave}
              borderColor='#4d8da4'
            />
          ) : null}

        </Box>)}

      {(activeStep === 4 && isEdit[stepNumber]) ? (
        <Box sx={{ mt: 2.5, textAlign: "right" }} className="cancel-save-btn-wrapper">
          <ButtonOutline
            styleSx={{ py: 0.4, px: 1.5, minWidth: 78, mr: 2, height: 30 }}
            handleClick={handleEditCancel}
            className="cancelBtnAction"
          >
            {t(t1, "CANCEL")}
          </ButtonOutline>
          <ButtonFill
            name="priv_btn_save"
            /* Conflict Resolution Candidate
                    styleSx={{ py: 0.4, px: 2, minWidth: 80,minHeight:33 }}
                    stepNumber={stepNumber}
            */
            styleSx={{ py: 0.4, px: 2, minWidth: 78, height: 30 }}
            stepNumber={stepNumber === 4 ? 3 : stepNumber}
            handleUpdateCB={handleUpdateCB}
            saveDisabled={saveDisabled}
          >
            {t(t1, "SAVE")}
          </ButtonFill>
        </Box>
      ) : null}
    </>

  return (
    <>
      {hideCard ? content : <Paper
        className={customClass}
        elevation={disableElevation ? 0 : 2}
        sx={{ ...Style?.formCardWrapper, ...customeStyle }}
      >{content}
      </Paper>}
      {showDisablePopup ? (
        <CustomeDialogMessage
          open={showDisablePopup}
          disabled={"No"}
          title={t(t1, "DIALOG_EDIT_DISABLED_TITLE")}
          message1={t(t1, "DIALOG_EDIT_DISABLED_MESSAGE")}
          buttonDisplay="DIALOG_EDIT_DISBALED_CONFIRM_BUTTON_MSG"
          handleButtonClick={onClickDisbalePopupBtn}
        />
      ) : null}

      {confirmPopup ? (
        <CustomeDialogMessage
          title={t(t1, "WARNING")}
          handleContinue={handleFinalConfirm}
          handleClose={() => setConfirmPopup(false)}
          open={confirmPopup}
          message1={t(t1, "WARNING_MESSAGE_FOR_REMOVE_MEMBER")}
          borderColor={"#ca2c1c"}
        />
      ) : null}
    </>
  );
}
