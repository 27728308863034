import { Button, Typography } from "@mui/material";
import { StyledCard } from "pages/AccountDetails/AccountDetails.style";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrentPageName, t } from "utils/constants";
import Style from "./TextTruncate.style";

function TextTruncate(props) {
  const { content = "", truncateLength = 366, textVariant = "body2", animate = false, style = {}, textStyles={} } = props;
  const [truncated, setTruncated] = useState(true);
  const shouldTruncate = useMemo(() => content?.length > truncateLength, [content, truncateLength]);
  const { t: t1 } = useTranslation(getCurrentPageName());

  // eslint-disable-next-line
  const _TypographyEle = () => (
    <Typography variant={textVariant} sx={{ maxHeight: "100px", overflowY: "auto", ...textStyles }}>
      {truncated && shouldTruncate ? content.slice(0, truncateLength) : content}
      {shouldTruncate && (
        <Button
          variant="text"
          tabIndex={0}
          disableElevation
          sx={Style.ExpandButton}
          onClick={() => setTruncated(!truncated)}
        >
          {truncated ? t(t1, "TRUNCATE_TEXT_READ_MORE") : t(t1, "TRUNCATE_TEXT_READ_LESS")}
        </Button>
      )}
    </Typography>
  );

  if (animate) return <StyledCard style={style}>{_TypographyEle()}</StyledCard>;
  return _TypographyEle();
}

export default TextTruncate;
