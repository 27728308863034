import BannerImage from "../../assets/images/banner-image.png";

const Style = {
  welcome: {
    width: "1199px",
    height: "89px",
    padding: "20px 32px 20px 32px",
    backgroundcolor: "rgba(255, 255, 255, 0)",
    boxsizing: "borderBox",
    fontfamily: "OpenSans-Light, Open Sans Light, Open Sans, sans - serif",
    fontweight: "200",
    color: `#333333`,
    textalign: "left",
    lineheight: "normal",
  },
  WelcomeText: {
    paddingTop: "0px",
  },
  Banner: {
    backgroundImage: `url(${BannerImage})`,
    backgroundSize: "cover",
    height: "360px",
    padding: "2px 0px",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 899px)": {
      height: "420px",
    },
  },
  Platform: {
    color: "#fff",
    fontWeight: "200",
    fontSize: "48px",
    "& p":{
      margin:0
    },
    "@media (max-width: 899px)": {
      textAlign: "center",
      fontSize: "36px",
    },
  },
  JurisdictionText: {
    paddingTop: "40px",
    paddingBottom: "140px",
    "@media (max-width: 1023px)": {
      paddingBottom: "40px",
    },
  },
  WelcomeTextTital: {
    fontWeight: "300",
    color: "#333",
    lineHeight: "normal",
    mb: 0,
    "& p": {
      my: 2,
    }
  },
  noticebox: {
    background: "rgba(128, 174, 190, 0.2)",
    width: "340px",
    height: "290px",
    overflow: "auto",
    marginLeft: "auto",
    borderTop: "3px solid #4d8da4",
    borderBottom: "3px solid #4d8da4",
    paddingRight: "30px",
    paddingLeft: "15px",
    "&::-webkit-scrollbar": {
      width: "12px",
      background: "#F5F5F5",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
      borderRadius: "10px",
      background: "rgba(128, 174, 190, 0.2)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
      background: "#4d8da4",
    },

    "& h4": {
      color: "#005c7d",
      fontWeight: "700",
      fontSize: "14px",
      textAlign: "left",
      paddingTop: "5px",
      paddingBottom: "5px",
      marginBottom: "0px",
      borderBottom: "2px solid #4d8da4",
    },
    "& h5": {
      color: "#005c7d",
      fontWeight: "700",
      fontSize: "14px",
      textAlign: "left",
      paddingTop: "5px",
      paddingBottom: "5px",
      marginBottom: "0px",
      borderTop: "2px solid #4d8da4",
      borderBottom: "2px solid #4d8da4",
    },
    "& ul": {
      padding: "0px",
      paddingTop: "10px",
      paddingBottom: "10px",
      borderBottom: "1px solid #d3dfe4",
      "& li": {
        padding: "0px",
        fontSize: "13px",
      },
    },
    "@media (max-width: 1350px)": {
      width: "100%",
    },
    "@media (max-width: 899px)": {
      margin: "0 auto",
      width: "340px",
      display: "block",
      marginTop: "15px",
    },
  },
};
export default Style;
