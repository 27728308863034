export const LIMITED_EXEMPTION_COMPONENTS = {
  CREATE_BATCH: "createBatch",
  BATCH_LIST: "getBatchList",
  EXEMPTIONS: "getLimitedExemptions",
  EDIT_BATCH: "editBatch",
  BATCH_HISTORY: "getBatchHistory",
  ADD_EXEMPTION: "createLimitedExemption",
  GET_BUDGET_YEARS: "getBudgetYears",
  STATUS_UPDATE: "batchStatusUpdate",
  BULK_UPLOAD: "limitedExemptionBulkUpload",
};

export const LIMITED_EXEMPTION_PAGE = "Page_ManageLimitedExemption";

export const flattenStatusObject = (data) => {
  if (!data?.length) return [];
  return data?.map((item) => ({
    ...item,
    Status: item?.Status?.name,
  }));
};
