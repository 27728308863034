const Style = {
  FieldLabel: {
    fontSize: "0.875rem",
    color: "common.textColor",
    fontWeight: 700,
    mb: 0.5,
    whiteSpace:"normal",
    marginTop: "4%"
  },
  formField: {
    width: 1,
    color: "common.textColor",
    "&.fc-select-select" : {
      "& .MuiOutlinedInput-input" : {
        background: '#fff',
        minHeight: '40px',
        py: 0,
        display:'block',
        paddingLeft: '10px',
      }
    },
  
    "&.fc-select-rounded-select" : {
      ".MuiOutlinedInput-input": {
        py: 0.4,
        px: '10px',
        fontSize: "0.875rem",
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderRadius: "4px",
      },
    },
    ".MuiOutlinedInput-input": {
      px: 0.6,
      py:0.45,
      fontSize: "0.875rem",
      maxWidth: "300px",
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px",
      borderColor: "common.GrayColor",
    },
    "&.MuiOutlinedInput-root": {
      "&.MuiInputBase-root.Mui-focused": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#333333",
          borderWidth: 1,
        },
      },
    },
  },
  valuetext: {
    paddingLeft: "0"
  },
  updatedFieldBox: { display: "flex", justifyContent: "start", alignItems: "center" },
    coloredBox: {
      padding: "2px 4px",
      width: "95%",
      border: "1.5px solid",
      borderColor: "common.warning",
      color: "common.warning",
      "& .MuiTypography-root": { fontWeight: 600, fontSize: "14px" }
    },

    noValueText:{
      color:"#aaaaaa"
    },
    
    placeholder:{
      opacity:1,
      background:"none"
    }
  };

export default Style;