import authRoles from "core/auth/authRole";
import ManageBatchTransfer from "./ManageBatchTransfer";


const ManageBatchTransferConfig = {
  route: {
    path: "/batch-transfer/:id",
    element: <ManageBatchTransfer />,
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.admin,
};

export default ManageBatchTransferConfig;