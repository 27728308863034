const COMMON = {
  ID: 'id',
  UPDATED_BY: 'updatedBy',
  UPDATED_AT: 'updatedAt',
  CREATED_BY: 'createdBy',
  CREATED_AT: 'createdAt',
  NAME: 'name',
  PUBLIC_USER: 'PUBLIC_USER',
  DYNAMO_ID: 'dynamo_id',
  DYNAMO_ROLE_ID: 'dynamo_role_id',
  DESCRIPTION: 'description',
  DISPLAY_NAME: 'displayName',
  ROLE_NAME: 'RoleName',
  KEY: 'key',
  ACTIVE_STATUS: 'Active',
  PENDING_STATUS: 'Pending',
  LANGUAGE_CODE: 'languageCode',
  DEFAULT_LANGUAGE_CODE: 'L001',
  VERSION: 'version',
};

const PENDING_STATUSES = [
  'PENDING',
  'PENDING COJ',
  'PENDING CROSS JURISDICTION',
  'PROPOSED CROSS JURISDICTION',
  'PROPOSED USER DETAILS CHANGE',
  'PROPOSED TO AUTHORITY',
  'PROPOSED ROLE CHANGE',
];

module.exports = {
  DB_CONSTANT: {
    ...COMMON,
    PENDING_STATUSES,
  },
};