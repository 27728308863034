import React from "react";
import authRoles from "core/auth/authRole";
import CreateUserProfile from "./CreateUserProfile";

const CreateUserProfileConfig = {
  route: {
    path: "/user-registration",
    element: <CreateUserProfile />,
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.onlyGuest,
};

export default CreateUserProfileConfig;
