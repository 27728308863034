import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { getCurrentPageName, isEmpty, t, REFRESH_REQUIRED, JURISDICTION_STATUS } from "utils/constants";
import { PageTitle } from "components";
import { CustomizeButton } from "components/CustomizeButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useMemo, useEffect } from "react";
import {
  setCancelBudgetDialogMessage,
  getWCIProposedBudgetRecords,
  setEditBudgetRecords,
  getEffectiveBudgetRecords,
  getAllowanceHistory,
  getBudgetDetails,
  getStatus,
  setISLoadingBudgetDetails,
} from "store/manageJurisdictionBudgetSlice";
import { setModelMessage } from "store/commonSlice";
import { fetcherPatch } from "utils/fetcher";
import { useEffectOnce } from "hooks/useEffectOnce";
import { getJurisdictionInformation } from "store/onboardJurisdictionSlice";
import { PermissionDenied } from "components/PermissionDenied";
import Style from "./ManageJurisdictionBudget.style";
import { Loader } from "../../components/Loader";
import BudgetDetailsCard from "./components/BudgetDetailsCard";
import JurisdictionAllowanceHistoryCard from "./components/JurisdictionAllowanceHistoryCard";
import EffectiveBudgetRecordsCard from "./components/EffectiveBudgetRecordsCard";
import ProposedBudgetRecordsCard from "./components/ProposedBudgetRecordsCard";
import { isRolesAssigned, USER_ROLES, USER_ROLE_TYPES, APIS, statusTypes } from "../../utils/constants";
import Form from "./components/Form";
import CustomCancelDialogMessage from "./components/CustomCancelDialogMessage";
import { JURISDICTION_BUDGET_COMPONENTS_FLP, getFLPHeaders } from "./constant";

export default function ManageJurisdictionBudget({ propose }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const navigate = useNavigate();
  const { loader } = useSelector(({ common }) => common);
  const {
    cancelBudgetDialogMessage,
    status,
    isLoadingBudgetDetails,
    isLoadingEffectiveBudgetRecords,
    isLoadingProposedBudgetRecords,
    isLoadingAllowanceHistory,
    flp,
  } = useSelector(({ ManageJurisdictionBudget }) => ManageJurisdictionBudget);
  const isLoading = useMemo(
    () =>
      isLoadingBudgetDetails ||
      isLoadingEffectiveBudgetRecords ||
      isLoadingProposedBudgetRecords ||
      isLoadingAllowanceHistory,
    [isLoadingBudgetDetails, isLoadingEffectiveBudgetRecords, isLoadingProposedBudgetRecords, isLoadingAllowanceHistory]
  );

  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isWCIAdmin } = useMemo(
    () => ({
      isWCIAdmin: isRolesAssigned(userDetails, [USER_ROLE_TYPES.SYSTEM_USER], [USER_ROLES.WCI_ADMIN_DEFAULT_ORG]),
    }),
    [userDetails]
  );

  const { isJurisdictionUser } = useMemo(
    () => ({
      isJurisdictionUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.ADMIN, USER_ROLES.USER]
      ),
    }),
    [userDetails]
  );
  const { editBudgetRecords } = useSelector(({ ManageJurisdictionBudget }) => ManageJurisdictionBudget);
  const dispatch = useDispatch();
  const { id: jurisdictionId } = useParams();
  useEffect(() => {
    if (!userDetails?.loading && !isWCIAdmin && !isJurisdictionUser) {
      navigate("/home");
    }
  }, [isJurisdictionUser, isWCIAdmin, navigate, userDetails]);
  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch]);

  const onPropose = async () => {
    const { id, annualAllowanceBudget, reserveAdjustedAllowanceBudget } = editBudgetRecords;
    const statusId = status.find(({ Name }) => Name === statusTypes.CANCELLED)?.ID;
    const body = [{ id, statusId, annualAllowanceBudget, reserveAdjustedAllowanceBudget, comment:"" }];
    dispatch(setISLoadingBudgetDetails(true));

    await fetcherPatch(`${APIS.BUDGETS}/jurisdictions/${jurisdictionId}/budget-record`, {
      jurisdictioBudgetRecord: body,
    },
    getFLPHeaders(JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_RECORD))
      .then(() => {
        const data = {
          title: `${t(t1, "SUCCESS")}`,
          message1: t(t1, "CANCEL_RECORD_SUCCESS_MESSAGE"),
          error: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setISLoadingBudgetDetails(false));
        dispatch(getAllowanceHistory(jurisdictionId));
        dispatch(getBudgetDetails(jurisdictionId));
        dispatch(getEffectiveBudgetRecords(jurisdictionId));
        dispatch(getWCIProposedBudgetRecords(jurisdictionId));
      })
      .catch((error) => {
        const data = {
          title: `${t(t1, "ERROR")}`,
          message1: error?.response?.data?.data===REFRESH_REQUIRED ? t(t1, "REFRESH_ERROR_MESSAGE") : error?.response?.data?.data,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setISLoadingBudgetDetails(false));
      });
    dispatch(setCancelBudgetDialogMessage(false));
    dispatch(setEditBudgetRecords(null));
  };

  useEffectOnce(() => {
    dispatch(getJurisdictionInformation(jurisdictionId, t1));
  });

  const { jurisdictioninformation } = useSelector(
    ({ onboardJurisdiction }) => onboardJurisdiction
  );
  
  if(jurisdictioninformation?.Status?.Name === JURISDICTION_STATUS.OFFBOARDED){
    return <PermissionDenied />;
  }

  return (
    <Container
      maxWidth="xl"
      sx={Style.spacing}
    >
      <Grid
        container
        spacing={2.5}
      >
        <Grid
          item
          md={12}
        >
          <Box sx={{ pt: 2.5 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
              {isWCIAdmin && (
                <>
                  <PageTitle
                    customeStyle={{ marginBottom: "0px" }}
                    title={`${t(t1, "MANAGE_JURISDICTION_BUDGET")}`}
                  />
                  <CustomizeButton
                    customStyle={{
                      marginRight: "0px!important",
                      padding: "2px",
                      textAlign: "center",
                      width: "150px",
                      height: "40px",
                      lineHeight: "normal",
                    }}
                    variant="contained"
                    name="add-btn"
                    label={t(t1, "ADD_BUDGET_RECORDS")}
                    handleClick={() => {
                      navigate(`/budgets/${jurisdictionId}/add`);
                    }}
                  />
                </>
              )}
              {isJurisdictionUser &&
                (propose ? (
                  <>
                    <PageTitle
                      customeStyle={{ marginBottom: "0px" }}
                      title={`${t(t1, "BUDGET_RECORDS")}`}
                    />
                    <CustomizeButton
                      customStyle={{
                        marginRight: "0px!important",
                        padding: "2px",
                        textAlign: "center",
                        width: "150px",
                        height: "40px",
                        lineHeight: "normal",
                      }}
                      variant="contained"
                      name="view-all-records-btn"
                      label={t(t1, "VIEW_ALL_RECORDS")}
                      handleClick={() => {
                        navigate(`/budgets/${jurisdictionId}`);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <PageTitle
                      customeStyle={{ marginBottom: "0px" }}
                      title={`${t(t1, "ALL_BUDGET_RECORDS")}`}
                    />
                    <CustomizeButton
                      customStyle={{
                        marginRight: "0px!important",
                        padding: "2px",
                        textAlign: "center",
                        width: "150px",
                        height: "40px",
                        lineHeight: "normal",
                      }}
                      variant="contained"
                      name="view-proposed-btn"
                      label={t(t1, "VIEW_PROPOSED_BUDGETS")}
                      handleClick={() => {
                        navigate(`/budgets/${jurisdictionId}/propose`);
                      }}
                    />
                  </>
                ))}
            </Box>

            <BudgetDetailsCard flp={flp[JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_DETAILS]}/>

            <EffectiveBudgetRecordsCard flp={flp[JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS]}/>

            <ProposedBudgetRecordsCard ProposePage={propose} flp={flp[JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS]}/>

            {isWCIAdmin && editBudgetRecords && !cancelBudgetDialogMessage && <Form />}

            <JurisdictionAllowanceHistoryCard flp={flp[JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_HISTORY]}/>
          </Box>
          {isLoading && <Loader loader={isLoading} />}
          {cancelBudgetDialogMessage ? (
            <CustomCancelDialogMessage
              warning
              title={t(t1, "WARNING")}
              buttonMessage={false}
              open={cancelBudgetDialogMessage}
              handleContinue={onPropose}
              handleClose={() => {
                dispatch(setCancelBudgetDialogMessage(false));
                dispatch(setEditBudgetRecords(null));
              }}
              disableContinue={loader}
              message1={t(t1, "CANCEL_BUDGET_RECORD_MESSAGE")}
            />
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
}
