import { createSlice } from "@reduxjs/toolkit";
import {setModelMessage} from "store/commonSlice";
import {APIS, SUCCESS} from "utils/constants";
import { fetcherGet, fetcherPut, fetcherPost, fetcherPatch } from "utils/fetcher";
import logger from "utils/logger";

const initialState = {
  NAICSCodeList: [],
  NAICSCodeDetails: null,
  isLoadingNAICSCodeList: false,
  isLoadingNAICSCodeDetails: false,
  isUpdatingNAICSCode: false,
  isAddingNAICSCode: false,
};

const NAICSCodeSlice = createSlice({
  name: "NAICSCode",
  initialState,
  reducers: {
    setNAICSCodeList: (state, action) => {
      state.NAICSCodeList = action.payload;
    },
    setNAICSCodeDetails: (state, action) => {
      state.NAICSCodeDetails = action.payload;
    },
    setIsLoadingNAICSCodeList: (state, action) => {
      state.isLoadingNAICSCodeList = action.payload;
    },
    setISLoadingNAICSCodeDetails: (state, action) => {
      state.isLoadingNAICSCodeDetails = action.payload;
    },
    setIsUpdatingNAICSCode: (state, action) => {
      state.isUpdatingNAICSCode = action.payload;
    },
    setIsAddingNAICSCode: (state, action) => {
      state.isAddingNAICSCode = action.payload;
    },
    resetNAICSCodeData: (state) => {
      state.NAICSCodeDetails = initialState.NAICSCodeDetails;
      state.NAICSCodeList = initialState.NAICSCodeList;
    }
  },
});

export const {
  setNAICSCodeList,
  setNAICSCodeDetails,
  setIsLoadingNAICSCodeList,
  setISLoadingNAICSCodeDetails,
  setIsUpdatingNAICSCode,
  setIsAddingNAICSCode,
  resetNAICSCodeData
} = NAICSCodeSlice.actions;
export default NAICSCodeSlice.reducer;

export const getNAICSCodeList = () => (dispatch) => {
  dispatch(setIsLoadingNAICSCodeList(true));
  fetcherGet(`${APIS.NAICS_CODE}`)
    .then((response) => {
      dispatch(setNAICSCodeList(response?.data));
      dispatch(setIsLoadingNAICSCodeList(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingNAICSCodeList(false));
    });
}

export const getNAICSCodeDetails = (id) => (dispatch) => {
  dispatch(setISLoadingNAICSCodeDetails(true));
  fetcherGet(`${APIS.NAICS_CODE}/${id}`)
    .then((response) => {
      dispatch(setNAICSCodeDetails(response?.data));
      dispatch(setISLoadingNAICSCodeDetails(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setISLoadingNAICSCodeDetails(false));
    });
}

export const updateNAICSCode = (id, payload, successCallback) => (dispatch) => {
  dispatch(setIsUpdatingNAICSCode(true));
  fetcherPut(`${APIS.NAICS_CODE}/${id}`, payload)
    .then((response) => {
      const data = {
        title: SUCCESS,
        message1: response?.messageKey,
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      dispatch(setIsUpdatingNAICSCode(false));
      successCallback?.();
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsUpdatingNAICSCode(false));
    });
}

export const addNAICSCode = (payload, successCallback) => (dispatch) => {
  dispatch(setIsAddingNAICSCode(true));
  fetcherPost(`${APIS.NAICS_CODE}`, payload)
    .then((response) => {
      const data = {
        title: SUCCESS,
        message1: response?.messageKey,
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      dispatch(setIsAddingNAICSCode(false));
      successCallback?.();
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsAddingNAICSCode(false));
    });
}

export const patchNAICSCode = (id, payload) => (dispatch) => {
  dispatch(setIsUpdatingNAICSCode(true));
  fetcherPatch(`${APIS.NAICS_CODE}/${id}`, payload)
    .then((response) => {
      const data = {
        title: SUCCESS,
        message1: response?.messageKey,
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      dispatch(getNAICSCodeList());
      dispatch(setIsUpdatingNAICSCode(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsUpdatingNAICSCode(false));
    });
}

export const checkNAICSCodeExists = (payload) => {
  return fetcherPost(`${APIS.NAICS_CODE}/verify`, payload);
}

export const resetData = () => (dispatch) => {
    dispatch(resetNAICSCodeData());
}
