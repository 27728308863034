import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  t,
  getCurrentPageName,
  getAllLang,
  isEmpty,
  getFormLabels,
  enLanguage,
  defaultORGID,
  docFilesPageName,
  languageCode,
} from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getJurisdictions,
  getJurisdictionsDetails,
  getSelectedJurisdictionName,
  setJurisdiction,
} from 'store/jurisdictionSlice';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { checkIsAuthenticated, getMenuAccess, getUserDetails } from 'store/authSlice';
import { getCMSDetails, getFileUrl } from 'store/configSlice';
import { useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { Typography } from '@mui/material';
import { setDialogMessage } from 'store/commonSlice';
import { Loader } from 'components/Loader';
import useCommonFunc from 'hooks/useCommonFunc';
import { withoutLoginPages } from '../../../../utils/pages';

export default function SelectJurisdiction(props) {
  const { t: t1, i18n } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const [loaderState, setLoaderState] = useState(false);
  const { Jurisdictions, selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const {
    formSteps: { activeStep },
    prePopulatedDetails: { data },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { user, isSignedIn } = useAuth();
  const location = useLocation();
  const { displayJurisdictionName } = useCommonFunc();
  const searchParams = new URLSearchParams(location.search);
  const linkId = searchParams.get('LinkID');

  const { loggedInJurisdiction = '' } = props;

  const handleChange = (event) => {
    if (location.pathname === '/create-user-profile' && activeStep !== 6) {
      dispatch(setDialogMessage(true));
    } else {
      const { value } = event.target;
      dispatch(setJurisdiction(value));
      const LanguageParams = {
        OrgID: value,
        QueryType: getAllLang,
      };
      dispatch(getCMSDetails(LanguageParams));

      dispatch(
        getFileUrl({
          OrgID: value || defaultORGID,
          PageLanguage: enLanguage(),
          Page: docFilesPageName,
          QueryType: getFormLabels,
        }),
      );
      localStorage.setItem('i18nextLng', enLanguage());
      i18n.changeLanguage(`${value}#${languageCode()}`);
    }
  };

  useEffect(() => {
    dispatch(getJurisdictions());
  }, []);

  useEffect(() => {
    if (shouldPullJurisdictionDetails()) {
      dispatch(getJurisdictionsDetails());
      dispatch(getSelectedJurisdictionName(localStorage.getItem('jurisdiction')));
    }
  }, [dispatch, selectedJurisdiction]);

  useEffectOnce(() => {
    if (shouldPullJurisdictionDetails()) {
      dispatch(getJurisdictionsDetails());
    }
  }, []);

  useEffect(() => {
    if (user && user.userId && !isEmpty(selectedJurisdiction)) {
      if (!isEmpty(userDetails?.OrgID)) dispatch(setJurisdiction(userDetails?.OrgID));
      dispatch(
        getUserDetails({
          jurisdiction: selectedJurisdiction,
          roleId: encodeURIComponent(`U#${user?.userId}`),
          pageName: getCurrentPageName(),
        }),
      );
    }

    if (!checkIsAuthenticated() && isEmpty(user) && !isEmpty(selectedJurisdiction))
      dispatch(getUserDetails({ jurisdiction: selectedJurisdiction, pageName: getCurrentPageName() }));
  }, [user, location, selectedJurisdiction]);

  // We only want to pull jurisdiction details when we are logged in to the site
  const shouldPullJurisdictionDetails = () => {
    return !withoutLoginPages.includes(getCurrentPageName());
  };

  if (linkId)
    return (
      <Typography
        variant="body2"
        sx={{ marginLeft: '.3rem' }}
      >
        {data?.Jurisdiction?.display_name}
      </Typography>
    );

  return (
    <FormControl>
      {isSignedIn ? (
        <Typography
          variant="body2"
          sx={{ marginLeft: '.3rem' }}
        >
          {displayJurisdictionName() ? t(t1, displayJurisdictionName()) : ''} {/* {loggedInJurisdiction} */}
        </Typography>
      ) : (
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={(selectedJurisdiction === undefined ||
            selectedJurisdiction === '' ||
            Jurisdictions.length === 0) ? '' : selectedJurisdiction}
          onChange={(e) => handleChange(e)}
          disabled={isSignedIn}
          sx={{
            fontSize: '0.875rem',
            color: 'common.textColor',
            ml: 0.5,
            '.MuiSelect-select': {
              p: 0,
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
              outline: 'none',
            },
          }}
        >
          {Jurisdictions &&
            Jurisdictions?.filter((jurisdiction) => jurisdiction.IsDefault === false)?.map((jurisdiction, index) => (
              <MenuItem
                key={`jurisdiction_option_${index}`}
                value={jurisdiction.OrgID}
              >
                {`${t(t1, `${jurisdiction.OrgName}`)}`}
              </MenuItem>
            ))}
        </Select>
      )}
      {loaderState && <Loader loader={loaderState} />}
    </FormControl>
  );
}
