export const MOF_COMPONENTS = {
  OFFSET_PROJECT_NAME_MANAGE_TABLE: "OffsetProjectName",
  OFFSET_PROJECT_OPERATOR_MANAGE_TABLE: "OffsetProjectOperator",
  VERIFICATION_BODY_MANAGE_TABLE: "VerificationBody",
  ADD_OFFSET_PROJECT_NAME_FORM: "AddOffsetProjectName",
  ADD_OFFSET_PROJECT_OPERATOR_FORM: "AddOffsetProjectOperator",
  ADD_VERIFICATION_BODY_FORM: "AddVerificationBody",
  OFFSET_ISSUANCE_REPORT_TABLE:"OffsetIssuanceReport",
  OFFSET_TRACKING_RECORD_TABLE: "OffsetTrackingRecord",

  OFFSET_PROJECT_NAME_DATA: "offsetProjectNameData",
  OFFSET_PROJECT_NAME_HISTORY: "offsetProjectNameHistory",
  OFFSET_PROJECT_OPERATOR_DATA: "offsetProjectOperatorData",
  OFFSET_PROJECT_OPERATOR_HISTORY: "offsetProjectOperatorHistory",
  OFFSET_VERIFICATION_BODY_DATA: "offsetVerificationBodyData",
  OFFSET_VERIFICATION_BODY_HISTORY: "offsetVerificationBodyHistory",

  OFFSET_TRACKING_RECORD: "offsetTrackingRecord",
  JURISDICTION_OFFSET_CREDIT: "jurisdictionOffsetCredit",
  AFFECTED_OFFSET_CREDIT: "affectedOffsetCredit",
  OFFSET_TRACKING_RECORD_HISTORY: "offsetTrackingRecordHistory",
  AFFECTED_OFFSET_ACCOUNTS: "affectedOffsetAccount",
  AFFECTED_OFFSET_ACCOUNTS_COMPLIANCE: "affectedOffsetAccountsCompliance",
  REVISION_REQUESTED: "revisonsRequested",
  GENERIC_OTR_DETAILS: "genericOTRdetails",
  CREATE_OTR_INFO: "OffsetTrackingRecordInformation",

  ADD_MRT_COMPONENT: "AddModifiedRecordTransfer",
  MANAGE_MRT_COMPO: "modifiedRecordsTransfers",
  MRT_EDIT_HISTORY: "modifiedTransfersRecordsHistory",
  EDIT_MRT_DATA: "modifiedTransfersRecords",
  MANAGE_MRT_GENERIC: "genericMRTDetails",
  CREATE_MRT_POST: "CreateModifiedRecordTransfer",
  EDIT_MRT_PUT: "UpdateModifiedRecordTransfer"
};

export const MOF_PAGES = {
  MANAGE_OFFSET_PROJECT_NAME_PAGE: "Page_OffsetProjectNameManagement",
  OFFSET_PROJECT_OPERATOR_MANAGE_PAGE: "Page_OffsetProjectOperatorManagement",
  VERIFICATION_BODY_MANAGE_PAGE: "Page_VerificationBodyManagement",
  ADD_OFFSET_PROJECT_NAME_PAGE: "Page_AddOffsetProjectName",
  ADD_OFFSET_PROJECT_OPERATOR_PAGE: "Page_AddOffsetProjectOperator",
  ADD_VERIFICATION_BODY_PAGE: "Page_AddVerificationBody",
  OFFSET_ISSUANCE_REPORT_PAGE: "Page_OffsetIssuanceReport",
  OFFSET_TRACKING_RECORD: "Page_OffsetTrackingRecord",

  EDIT_PROJECT_NAME_PAGE: "Page_EditProjectName",
  EDIT_PROJECT_OPERATOR_PAGE: "Page_EditProjectOperator",
  EDIT_VERIFICATION_BODY: "Page_EditVerificationBody",

  OTR_DETAILS: "Page_OffsetTrackingRecordDetails",
  PROPOSE_OTR: "Page_ProposeOTR",
  CREATE_OTR: "Page_CreateOffsetTrackingRecord",

  ADD_MRT: "Page_AddModifiedRecordTransfer",
  MANAGE_MRT: "Page_ModifiedRecordTransfer",
  EDIT_MRT: "Page_EditModifiedRecordTransfer"
};
