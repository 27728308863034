import React, { useState , useRef, useEffect} from "react";
import { StyledErrorListItems } from "pages/AddBudgetRecord/AddBudgetRecord.style";
import { Button } from "@mui/material";
import Style1 from './ErrorCardWithScroll.style';

function ErrorCardWithScroll({ fileUploadErrors , customeStyle}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef();

  useEffect(() => {
    const textHeight = textRef.current.scrollHeight;
    const lineHeight = parseFloat(getComputedStyle(textRef.current)['line-height']);
    setIsOverflowing(textHeight > lineHeight * 2);
  }, []);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <ul ref={textRef} style={{ ...(isExpanded ? Style1.limitedLinesExpanded : Style1.limitedLines), ...customeStyle }}>
        {fileUploadErrors.map((error) => (
          <StyledErrorListItems sx={ {marginBottom: '0'}} key={error.messageKey}>{error.messageKey}</StyledErrorListItems>
        ))}
      </ul>
      {isOverflowing && 
        <div style={{textAlign: "Right"}}>
        <Button
          variant="text"
          tabIndex={0}
          disableElevation
          sx={Style1.ExpandButton}
          onClick={toggleExpanded}
        >
          {!isExpanded ? 'Read more': 'Read less'}
          </Button>
        </div>
      }
    </>
  );
}

export default ErrorCardWithScroll;
