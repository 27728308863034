const Style = {
/* Conflict Resolution Candidate
  ModelWrapper: {
    "& .MuiDialog-paper": {
      boxShadow: 2,
      border: "2px solid",
      borderRadius: 3,
      py: 2,
      px: 2.5,
    },
    ".MuiBackdrop-root": {
      bgcolor: "common.warning04",
    },
  },
 */
  ModelWarning: {
    ".MuiDialog-paper": {
      borderColor: "common.warning",
      borderRadius: "8px",
    },
  },
  ModelError: {
    ".MuiDialog-paper": {
      borderColor: "common.red",
      borderRadius: "8px",
    },
  },
  messageModel: {
    margin: "20px 25px",
    borderRadius: 8,
    //
    "& .MuiTypography-subtitle1": {
      fontSize: "24px",
      lineHeight:"24px",
      marginBottom: "15px",
    },
    "& .MuiTypography-body2": {
      marginTop: "24px",
      lineHeight:"24px"
    },
    "& .bottom-btn": {
      marginTop: "15px",
      display: "flex",
      justifyContent: "flex-end",
      "@media (max-width: 1100px)": {
        marginTop: "30px",
      },
      "& .MuiButton-root": {
        height: "40px",
        marginLeft: "10px",
        boxShadow: "none",
        outline: 0,
      },
      "& .submitBtn": {
        fontWeight: 400
      },
    },
  },
};

export default Style;
