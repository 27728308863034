import { Box, styled, Typography, Link, FormControlLabel, FormLabel } from "@mui/material";
import { motion } from "framer-motion";


export const StyledDetailsContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridGap: theme.spacing(2),
}));

export const StyledTitleLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.light,
  textDecoration: 'underline',
  fontWeight: 300,
}));

export const StyledTitleAccordionLink = styled(Link)(() => ({
  fontSize: '1rem',
  textDecoration: 'none',
  fontWeight: 400
}));

export const StyledCard = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.35)',
  border: `1px solid ${theme.palette.common.GrayColor}`,
  margin: theme.spacing(2.5, 0),
  padding: theme.spacing(2.5),
  paddingBottom: "40px"
}));

export const StyledFormCard = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.35)',
  border: `1px solid ${theme.palette.common.GrayColor}`,
  margin: theme.spacing(2.5, 0),
  padding: theme.spacing(2.5, 2.5, 5, 2.5),
}));

export const StyledCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  margin: theme.spacing(0, 0, 1, 0),
  fontWeight: 300,
  color: theme.palette.common.black,
}));

export const StyledEntryDetailContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

export const StyledDetailKey = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: "14px",
  fontFamily: "Open Sans",
  lineHeight: "normal",
}));

export const StyledTitleMessageKey = styled(Typography)(() => ({
  fontWeight: 650,
  fontSize: "14px",
  fontFamily: "Open Sans",
  lineHeight: "normal",
}));

export const StyledDetailValue = styled(Typography)(() => ({
  paddingTop: "3px",
  fontSize: "14px",
  overflow: 'auto',
  whiteSpace: 'wrap',
  wordBreak: 'break-word',
  height: '24px',

}));

export const StyledTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(3)
}));

export const StyleFormControlLabel = styled(FormControlLabel)(() => ({
  fontSize: "0.825rem",
  maxHeight: '24px',
}));

export const StyleFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.common.black,
  marginBottom: theme.spacing(1),
  fontWeight: "700"
}));

export const StyledQuickAction = styled(Typography)(() => ({
  textTransform: "uppercase",
}));

export const StyledGroupLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.light,
  textDecoration: 'underline',
  fontWeight: 400,
}));


export const TransferTypeModalStyles = {
  radioGroup: {
    ".MuiFormControlLabel-root": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "3px",
    },
    ".MuiTypography-root": {
      fontSize: "14px",
    },
    "& .MuiFormControlLabel-root": { marginBottom: 2 },
  },

  radioSize: {
    pt: "5px",
    "& .MuiFormControlLabel-label": {
      // paddingBottom: '3px'
      lineHeight: "19px",
    },
    "& .MuiRadio-root": {
      padding: "0",
    },
    "& .MuiSvgIcon-root path": {
      transform: "scale(1.08)",
    },
  },
};