import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getInitialUsersJuriHomeAPI } from "store/userSlice";
import { ShowInlineAddressForTable, USER_ACCOUNT_STATUSES, displayEmployerList, formatDate, getCMSKeySnakeCase, getCurrentPageName, t } from "utils/constants";
import { getJurisdictionUUID } from "utils/support";

const useJurisdictionHome = () => {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();

  const { Jurisdictions, jurisdictionDetails } = useSelector(({ jurisdiction }) => jurisdiction);
  const { initialUsers } = useSelector(({ user }) => user);
  
  useEffect(() => {
    dispatch(getInitialUsersJuriHomeAPI(getJurisdictionUUID()))
  }, [dispatch])

  const transformInitialUsersJuriHome = () =>
  initialUsers?.data?.map((item) => ({
    id: item?.id,
        Name: item?.first_name,
        FamilyName: item?.last_name,
        jurisdiction: item?.Jurisdiction?.Name,
        Email: item?.email_address,
        RoleName: t(t1, `${getCMSKeySnakeCase(`${item?.Roles?.name}`)}`),
        ReferenceCode: item?.reference_code,
        AccountStatus: USER_ACCOUNT_STATUSES.NOT_ACTIVATED,
        UserLoginStatus: t(t1, USER_ACCOUNT_STATUSES.ACTIVE),
        
        // below data is not getting in users lambda as of now
        UserStatus: USER_ACCOUNT_STATUSES.ACTIVE,
        birthDate: formatDate(item?.BirthDate),
        Employer: displayEmployerList(item?.Employer),
        UserID: item?.Username,
        RequestStatus: item?.RequestStatus,
        RoleType: item?.RoleDetail?.RoleType?.map((eachRole) => eachRole.name).join(", "),
        RegStartDate: formatDate(item?.RegistrationDate),
        PhysicalAddress: ShowInlineAddressForTable(item, t, t1),
        TelephoneNumberChangeRequest: item?.TelephoneNumberChangeRequest,
        EmailChangeRequest: item?.EmailChangeRequest,
        TelephoneNumber: item?.TelephoneNumber,
        IsUser: item?.IsUser,
  }));
  
  const transformUsersList = (data) => {
    if (!data || !data?.length) return [];
    return data.map((item) => {
      const jurisName = jurisdictionDetails?.find((each) => each.dynamoId === item?.OrgID);
      return {
        id: item?.UID?.split("#")[1],
        Name: item?.Name,
        FamilyName: item?.FamilyName,
        jurisdiction: jurisName?.Name,
        Email: item?.Email,
        birthDate: formatDate(item?.BirthDate),
        UserStatus: item?.UserAccountStatus,
        RoleName: item?.RoleDetail?.Role?.map((eachRole) => t(t1, `${getCMSKeySnakeCase(`${eachRole.name}`)}`)).join(", "),
        AccountStatus: item?.ApplicantAccountStatus,
        ReferenceCode: item?.ReferenceCode,
        Employer: displayEmployerList(item?.Employer),
        UserID: item?.Username,
        RequestStatus: item?.RequestStatus,
        RoleType: item?.RoleDetail?.RoleType?.map((eachRole) => t(t1, `${getCMSKeySnakeCase(`${eachRole.name}`)}`)).join(", "),
        RegStartDate: formatDate(item?.RegistrationDate),
        PhysicalAddress: ShowInlineAddressForTable(item, t, t1),
        UserLoginStatus: item?.UserLoginStatus,
        TelephoneNumberChangeRequest: item?.TelephoneNumberChangeRequest,
        EmailChangeRequest: item?.EmailChangeRequest,
        TelephoneNumber: item?.TelephoneNumber,
        IsUser: item?.IsUser,
      };
    });
  };

  return {
    transformUsersList,
    transformInitialUsersJuriHome
  }
}

export default useJurisdictionHome

