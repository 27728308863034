import { createSlice } from "@reduxjs/toolkit";
import { MET_COMPONENT, MET_PAGES } from "pages/EntityTransfers/support";
import { isAdminOrReplenishmentTransfer, isBatchOrPCRA } from "pages/ManageBatchTransfer/constants";
import { TRANSFER_DETAILS_COMPONENTS } from "pages/TransferDetails/utils";
import { APIS, COMPONENT_NAME_FOR_ROLE, JURISDICTION_TRANSFER_TYPES } from "utils/constants";
import fetcher, { fetcherGet, fetcherPost } from "utils/fetcher";
import logger from "utils/logger";
import {
  getPermissionObj,
  transformActiveAR,
  transformEntityTransferRes,
  transformHoldingsRes,
  transformJurisdictionUserHoldingsRes,
  transformVrtList,
} from "utils/support";
import { setLoader } from "./commonSlice";

const initialState = {
  transferDetailsInfo: { isLoading: false, data: null },
  exchangeAgreeTransferInfo: { isLoading: false, data: null },
  otcTransferInfo: { isLoading: false, data: null },
  pricingInfo: { isLoading: false, data: null },
  transferringAccDetails: { isLoading: false, data: [] },
  receivingAccDetails: { isLoading: false, data: null },
  transferEventHistory: { isLoading: false, data: [] },
  transferAccountsTableData: { isLoading: false, data: [] },
  entityAccountHoldings: { isLoading: false, data: [] },
  entityAccountTransfers: { isLoading: false, data: [] },
  pendingTransfers: { isLoading: false, data: [] },
  complianceInstrumentTableData: { isLoading: false, data: [] },
  detailPageToggler: false,
  entityJurisdictionAccounts: { isLoading: false, data: [] },
  agreementTypes: { isLoading: false, data: [] },
  transferredBatchDetails: { isLoading: false, data: null },
  vrtList: { isLoading: false, data: [] },
  receivingEntityAR: { isLoading: false, data: [] },
  receivingEntityDetails: { isLoading: false, data: [] },
  cwiStatusHistory: { isLoading: false, data: [] },
  exchangeContractCodesMET: { isLoading: false, data: null },
  transferWarningStatus: { isLoading: false, data: false },
  entityTransferDetailId: { isLoading: false, data: null },
};

const entityTransferSlice = createSlice({
  name: "entityTransfer",
  initialState,
  reducers: {
    setTransferDetailsInfo: (state, action) => {
      state.transferDetailsInfo = action.payload;
    },
    setExchangeAgreeTransferInfo: (state, action) => {
      state.exchangeAgreeTransferInfo = action.payload;
    },
    setOtcTransferInfo: (state, action) => {
      state.otcTransferInfo = action.payload;
    },
    setPricingInfo: (state, action) => {
      state.pricingInfo = action.payload;
    },
    setTransferringAccDetails: (state, action) => {
      state.transferringAccDetails = action.payload;
    },
    setReceivingAccDetails: (state, action) => {
      state.receivingAccDetails = action.payload;
    },
    setTransferEventHistory: (state, action) => {
      state.transferEventHistory = action.payload;
    },
    setTransferAccountsTableData: (state, action) => {
      state.transferAccountsTableData = action.payload;
    },
    setPendingTransfers: (state, action) => {
      state.pendingTransfers = action.payload;
    },
    setEntityAccountHoldings: (state, action) => {
      state.entityAccountHoldings = action.payload;
    },
    setEntityAccountTransfers: (state, action) => {
      state.entityAccountTransfers = action.payload;
    },
    setDetailPageToggler: (state, action) => {
      state.detailPageToggler = action.payload;
    },
    setComplianceInstrInfoMET: (state, action) => {
      state.complianceInstrumentTableData = action.payload;
    },
    setEntityJurisdictionAccounts: (state, action) => {
      state.entityJurisdictionAccounts = action.payload;
    },
    setAgreementTypes: (state, action) => {
      state.agreementTypes = action.payload;
    },
    setTransferredBatchDetails: (state, action) => {
      state.transferredBatchDetails = action.payload;
    },
    setVrtList: (state, action) => {
      state.vrtList = action.payload;
    },
    setRecevingEntityAR: (state, action) => {
      state.receivingEntityAR = action.payload;
    },
    setRecevingEntityDetails: (state, action) => {
      state.receivingEntityDetails = action.payload;
    },
    setCWIStatusHistory: (state, action) => {
      state.cwiStatusHistory = action.payload;
    },
    setExchangeContractCodesMET: (state, action) => {
      state.exchangeContractCodesMET = action.payload;
    },
    setTransferWarningStatus: (state, action) => {
      state.transferWarningStatus = action.payload;
    },
    setEntityTransferDetailId: (state, action) => {
      state.entityTransferDetailId = action.payload;
    },
  },
});

export default entityTransferSlice.reducer;

export const {
  setTransferDetailsInfo,
  setExchangeAgreeTransferInfo,
  setOtcTransferInfo,
  setPricingInfo,
  setTransferringAccDetails,
  setReceivingAccDetails,
  setTransferEventHistory,
  setTransferAccountsTableData,
  setEntityAccountHoldings,
  setEntityAccountTransfers,
  setPendingTransfers,
  setDetailPageToggler,
  setComplianceInstrInfoMET,
  setEntityJurisdictionAccounts,
  setAgreementTypes,
  setVrtList,
  setRecevingEntityAR,
  setRecevingEntityDetails,
  setTransferredBatchDetails,
  setCWIStatusHistory,
  setExchangeContractCodesMET,
  setTransferWarningStatus,
  setEntityTransferDetailId,
} = entityTransferSlice.actions;

// **********    MANAGE ENTITY TRANSFERS (MET)   *********
export function getTransferDetailsInfoApi(
  transactionId,
  entityId,
  pageName = MET_PAGES.COMPLIANCE_TRANSFER_DETAIL_PAGE,
  componentName = MET_COMPONENT.TRANSFER_DETAIL
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setTransferDetailsInfo({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.GET_ENTITY_TRANSFER_DETAILS_API}/${transactionId}/${entityId}`,
        {},
        { pageName, componentName }
      );
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setTransferDetailsInfo({
          isLoading: false,
          data: {
            data: data[0],
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
      const { id, transfer_type_name: transferTypeName } = response.data[0];
      if (isAdminOrReplenishmentTransfer(transferTypeName)) {
        dispatch(getClusterDetailInfo(id, transferTypeName));
      }
      if (isBatchOrPCRA(transferTypeName)) {
        dispatch(getBatchDetailInfo(id));
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTransferDetailsInfo({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

export function getExchangeAgreementTransferInfoApi(
  transactionId,
  entityId,
  pageName = MET_PAGES.Page_GeneralExchangeNotToECSPTransferDetails,
  componentName = MET_COMPONENT.EXCHANE_AGG_COMPO
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setExchangeAgreeTransferInfo({ isLoading: true, data: null }));
    try {
      const languageValue = localStorage.getItem('i18nextLng');
      const languageCode = languageValue ? languageValue.slice(0, 3).toLowerCase() : 'eng';
      const response = await fetcherGet(
        `${APIS.GET_EXCHANGE_AGREE_TRANSFER_API}/${transactionId}/${entityId}/${languageCode}`,
        {},
        { pageName, componentName }
      );
      const { data, fieldAccess, metaData } = response;

      dispatch(
        setExchangeAgreeTransferInfo({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setExchangeAgreeTransferInfo({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

export function getOTCTransferInfoApi(
  transactionId,
  entityId,
  pageName = MET_PAGES.Page_OTCGT3TransferDetails,
  componentName = MET_COMPONENT.OTC_TRANSFER_DETAILS
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setOtcTransferInfo({ isLoading: true, data: null }));
    try {
      const response = await fetcherGet(
        `${APIS.GET_OTC_TRANSFER_API}/${transactionId}/${entityId}`,
        {},
        { pageName, componentName }
      );
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setOtcTransferInfo({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setOtcTransferInfo({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

// get pricing info - OTC > 3 days
export function getPricingInfoApi(
  transactionId,
  entityId,
  pageName = MET_PAGES.OTC_GT_TRANSFER_DETAIL_PAGE,
  componentName = MET_COMPONENT.PRICING_INFO
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setPricingInfo({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.GET_PRICING_INFO_API}/${transactionId}/${entityId}`,
        {},
        { pageName, componentName }
      );
      dispatch(setPricingInfo({ isLoading: false, data: response?.data }));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setPricingInfo({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

// get exchange contract codes list for MET
export function getAllExchangeContractCodesMET(
  entityId,
  pageDetails = {
    pageName: "Page_ManageExchangeContractCode",
    componentName: "exchangeAndContractDesciptionCodes",
  }
) {
  return async (dispatch) => {
    dispatch(setExchangeContractCodesMET({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_EXCHANGE_CONTRACT_CODES_MET}/${entityId}`, {}, pageDetails);
      dispatch(setExchangeContractCodesMET({ isLoading: false, data: response }));
    } catch (error) {
      dispatch(setExchangeContractCodesMET({ isLoading: false, data: [] }));
    }
  };
}

// compliance instruments MET
export function getComplianceInstrumentsInfoMET(id, pageName, componentName) {
  return async (dispatch) => {
    dispatch(setComplianceInstrInfoMET({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_COMPLIANCE_INSTR_MET}/${id}`, {}, { pageName, componentName });
      dispatch(setComplianceInstrInfoMET({ isLoading: false, data: response }));
    } catch (error) {
      dispatch(setComplianceInstrInfoMET({ isLoading: false, data: [] }));
    }
  };
}

// get receiving entityAR list
export function getReceivingEntityAR(
  entityId,
  pageDetails = { componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE, pageName: "Page_EntityProfile" }
) {
  return async (dispatch) => {
    dispatch(setRecevingEntityAR({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.REPRESENTATIVES}/${entityId}`, {}, pageDetails);
      dispatch(setRecevingEntityAR({ isLoading: false, data: transformActiveAR(response?.data) }));
    } catch (error) {
      dispatch(setRecevingEntityAR({ isLoading: false, data: [] }));
    }
  };
}

export const getReceivingEntityById = (id) => (dispatch) => {
  dispatch(setRecevingEntityDetails({ isLoading: true, data: [] }));
  fetcherGet(
    `${APIS.ENTITY}/${id}`,
    {},
    {
      componentName:
        "entityDetails,generalEntityDetails,identifiers,physicalAddress,mailingAddress,additionalContactInformation",
      pageName: "Page_EntityProfile",
    }
  )
    .then((response) => {
      dispatch(setRecevingEntityDetails({ isLoading: false, data: response }));
    })
    .catch((error) => {
      dispatch(setRecevingEntityDetails({ isLoading: false, data: [] }));
      logger({ error });
    });
};

// Get VRT list to show in Table
export function getVrtListApi() {
  return async (dispatch) => {
    dispatch(setVrtList({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_VRT_LIST_API}`, {});
      dispatch(setVrtList({ isLoading: false, data: transformVrtList(response?.data) }));
    } catch (error) {
      dispatch(setVrtList({ isLoading: false, data: [] }));
    }
  };
}

// transfer event history
export function getTransferEventHistoryApi(transferId, entityId, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setTransferEventHistory({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.GET_TRANSFER_EVENT_HISTORY}/${transferId}/${entityId}`,
        {},
        pageDetails
      );
      dispatch(
        setTransferEventHistory({
          isLoading: false,
          data: response,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTransferEventHistory({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

// transfer Accounts Table Data
export function getTransferAccountsApi(
  transferId,
  entityId,
  pageName = MET_PAGES.COMPLIANCE_TRANSFER_DETAIL_PAGE,
  componentName = MET_COMPONENT.ACCOUNTS
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setTransferAccountsTableData({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.GET_TRANSFER_ACCOUNTS}/${transferId}/${entityId}`,
        {},
        { pageName, componentName }
      );
      dispatch(setTransferAccountsTableData({ isLoading: false, data: response }));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTransferAccountsTableData({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

// Approve transfer entity
export function transferAprrovalApi(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.APPROVE_TRANSFER_API}/${payload.transferId}`,
      {
        ...payload.data,
      },
      pageDetails
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// Deny transfer entity
export function transferDenyApi(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.DENY_TRANSFER_API}/${payload.transferId}`,
      {
        ...payload.data,
      },
      pageDetails
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// Accept transfer entity
export function transferAcceptApi(payload, pageDetail) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.ACCEPT_TRANSFER_API}/${payload.transferId}`,
      {
        ...payload.data,
      },
      pageDetail
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// Decline transfer entity
export function transferDeclineApi(payload, pageDetail) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.DECLINE_TRANSFER_API}/${payload.transferId}`,
      {
        ...payload.data,
      },
      pageDetail
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getEntityAccountHoldings(accountId, pageDetails, jurisdictionUser = false) {
  return (dispatch) => {
    dispatch(setEntityAccountHoldings({ isLoading: true, data: [] }));
    fetcherGet(
      `${(jurisdictionUser ? APIS.GET_JURISDICTION_USERS_HOLDINGS : APIS.GET_ENTITY_ACCOUNT_HOLDINGS).replace(
        ":accountId",
        accountId
      )}`,
      {},
      pageDetails
    )
      .then((response) => {
        const { data, metaData, fieldAccess } = response;
        dispatch(
          setEntityAccountHoldings({
            isLoading: false,
            data: {
              data: jurisdictionUser ? transformJurisdictionUserHoldingsRes(data) : transformHoldingsRes(data),
              metaData,
              fieldAccess,
            },
          })
        );
      })
      .catch((error) => {
        logger({ error });
        dispatch(setEntityAccountHoldings({ isLoading: false, data: [] }));
      });
  };
}

// cancel proposed transfer API call
export function cancelProposeTransferApi(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.CANCEL_PROPOSE_TRANSFER_API}/${payload.transferId}`,
      payload.data,
      pageDetails
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getTransferringAccDetails(entityAccId, holdingsId, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setTransferringAccDetails({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.API_GET_TRANSFERRING_ACCOUNT_DETAILS}/${entityAccId}/${holdingsId}`,
        {},
        pageDetails
      );
      if (response) {
        const { data, fieldAccess } = response;
        dispatch(
          setTransferringAccDetails({
            isLoading: false,
            data: {
              data,
              fieldAccess: getPermissionObj(fieldAccess, "transferringAccountDetails"),
            },
          })
        );
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTransferringAccDetails({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
      logger({
        error,
      });
    }
  };
}

export function getReceivingAccDetails(entityId, transferType, flpHeaders) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setReceivingAccDetails({
        isLoading: true,
        data: null,
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.API_GET_RECEIVING_ACCOUNT_DETAILS}/${entityId}/${transferType}`,
        {},
        {
          ...flpHeaders,
        }
      );
      if (response) {
        const { data, metaData, fieldAccess } = response;
        dispatch(
          setReceivingAccDetails({
            isLoading: false,
            data: {
              data,
              fieldAccess: getPermissionObj(fieldAccess, flpHeaders.componentName),
              metaData,
            },
          })
        );
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setReceivingAccDetails({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({
        error,
      });
    }
  };
}
export function getPendingTransfers(pageDetails = { pageName: "Page_UserHome", componentName: "pendingTransfers" }) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setPendingTransfers({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(`${APIS.API_GET_PENDING_TRANSFER}`, {}, pageDetails);
      dispatch(
        setPendingTransfers({
          isLoading: false,
          data: response,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setPendingTransfers({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
      logger({
        error,
      });
    }
  };
}

export function getEntityAccountTransfers(
  accountId,
  pageDetails = { componentName: "transfers", pageName: "Page_AccountDetails" }
) {
  return async (dispatch) => {
    dispatch(setEntityAccountTransfers({ isLoading: true, data: [] }));
    fetcherGet(`${APIS.GET_ENTITY_ACCOUNT_TRANSFERS.replace(":accountId", accountId)}`, {}, pageDetails)
      .then((response) => {
        const { data, metaData, fieldAccess } = response;
        dispatch(
          setEntityAccountTransfers({
            isLoading: false,
            data: {
              data: transformEntityTransferRes(data),
              metaData,
              fieldAccess,
            },
          })
        );
      })
      .catch((error) => {
        logger({ error });
        dispatch(setEntityAccountTransfers({ isLoading: false, data: [] }));
      });
  };
}

export function getJurisdictionAccountsByEntityId(entityId) {
  return async (dispatch) => {
    dispatch(setEntityJurisdictionAccounts({ isLoading: true, data: [] }));
    fetcherGet(`${APIS.JURISDICTION_ACCOUNTS_BY_ENTITY_ID.replace(":entityId", entityId)}`)
      .then((response) => {
        dispatch(setEntityJurisdictionAccounts({ isLoading: false, data: response?.data }));
      })
      .catch((error) => {
        logger({ error });
        dispatch(setEntityJurisdictionAccounts({ isLoading: false, data: [] }));
      });
  };
}

export function getAgreementTypes(jurisdictionId) {
  return async (dispatch) => {
    dispatch(setAgreementTypes({ isLoading: true, data: [] }));
    fetcherGet(`${APIS.GET_AGREEMENT_TYPES.replace(":jurisdictionId", jurisdictionId)}`)
      .then((response) => {
        dispatch(
          setAgreementTypes({
            isLoading: false,
            data: response.data.map((item, index) => ({
              id: index,
              name: item.OverTheCounterTransferDescription.name,
            })),
          })
        );
      })
      .catch((error) => {
        logger({ error });
        dispatch(setAgreementTypes({ isLoading: false, data: [] }));
      });
  };
}

export function getClusterDetailInfo(transferId, transferType) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setTransferredBatchDetails({ isLoading: true, data: [] }));
    fetcherGet(
      `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/transfer/${transferId}/clusterTransferDetails`,
      {},
      {
        componentName:
          transferType === JURISDICTION_TRANSFER_TYPES.REPLENISHMENT
            ? TRANSFER_DETAILS_COMPONENTS.REPLENISHMENT_CLUSTER_DETAILS
            : TRANSFER_DETAILS_COMPONENTS.CLUSTER_DETAILS,
      }
    )
      .then((response) => {
        dispatch(
          setTransferredBatchDetails({
            isLoading: false,
            ...response,
          })
        );
      })
      .catch((error) => {
        logger({ error });
        dispatch(setTransferredBatchDetails({ isLoading: false, data: [] }));
      });
  };
}

export function getBatchDetailInfo(transferId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setTransferredBatchDetails({ isLoading: true, data: [] }));
    fetcherGet(
      `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/transfer/${transferId}/batchDetails`,
      {},
      { componentName: TRANSFER_DETAILS_COMPONENTS.BATCH_DETAILS }
    )
      .then((response) => {
        dispatch(
          setTransferredBatchDetails({
            isLoading: false,
            ...response,
          })
        );
      })
      .catch((error) => {
        logger({ error });
        dispatch(setTransferredBatchDetails({ isLoading: false, data: [] }));
      });
  };
}

export function getCWIStatusHistoryByAccountId(accountId) {
  return async (dispatch) => {
    dispatch(setCWIStatusHistory({ isLoading: true, data: [] }));
    fetcherGet(`${APIS.CWI_STATUS_HISTORY_BY_ACCOUNT_ID.replace(":accountId", accountId)}`)
      .then((response) => {
        dispatch(setCWIStatusHistory({ isLoading: false, data: response?.data }));
      })
      .catch((error) => {
        logger({ error });
        dispatch(setCWIStatusHistory({ isLoading: false, data: [] }));
      });
  };
}

export function getEntityTransferWarningStatus(transferId) {
  return (dispatch) => {
    dispatch(setTransferWarningStatus({ isLoading: true, data: false }));
    fetcherGet(`${APIS.GET_ENTITY_TRANSFER_WARNING}/${transferId}`)
      .then((response) => {
        dispatch(setTransferWarningStatus({ isLoading: false, data: response?.data }));
      })
      .catch((error) => {
        logger({ error });
        dispatch(setTransferWarningStatus({ isLoading: true, data: false }));
      });
  }
}

export function getEntityTransferDetailId(accId) {
  return (dispatch) => {
    dispatch(setEntityTransferDetailId({ isLoading: true, data: null }));
    fetcherGet(`${APIS.GET_ENTITY_TRANSFER_DETAIL_ID}/${accId}`)
      .then((response) => {
        dispatch(setEntityTransferDetailId({ isLoading: false, data: response?.data?.Entity }));
      })
      .catch((error) => {
        logger({ error });
        dispatch(setEntityTransferDetailId({ isLoading: true, data: null }));
      });
  }
}