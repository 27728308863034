import ErrorIcon from "@mui/icons-material/Error";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Visibility } from "assets/icons/Visibility";
import { VisibilityOff } from "assets/icons/VisibilityOff";
import { CustomizeSelect } from "components/CustomizeSelect";
import { CustomizeTextfield } from "components/CustomizeTextfield";
import { FormActions } from "components/FormActions";
import { FormCard } from "components/FormCard";
import { Loader } from "components/Loader";
import useAuth from "hooks/useAuth";
import { useEffectOnce } from "hooks/useEffectOnce";
import { readSecurityQuestions } from "store/commonSlice";
import { setUserRegCaptcha } from "store/createUserProfileSlice";
import { displayParsedString, getCurrentPageName, nonPrintableChar, nonPrintingPattern, numbersAndCharacters, numbersImportant, passwordValidater, QUESTION_ONE_OPTIONS, QUESTION_THREE_OPTIONS, QUESTION_TWO_OPTIONS, scrollToTop, spaceImportant, specialCharImportant, stringImportant, t, USER_ID_VALIDATION, validateSecurityAnswer, validateUserIDPattern } from "utils/constants";
import { getCMSTnC } from "store/cmsSlice";
import { Faq } from "./Faq";

export default function UserLogin() {

  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const { validateUniqueUser } = useAuth();
  const [verifyCaptcha, setVerifyCaptcha] = useState(false);
  const captchaRef = useRef(null);
  /* Conflict Resolution Candidate

    const textRefPass = useRef();
    const textRefConfirmPass = useRef();

   */
  const { loader, securityQuestions} = useSelector(({ common }) => common);
  const {
    filledData,
    submitted,
    isValid,
    formSteps: { activeStep },
    userRegCaptcha,
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    alphaNum: false,
    upperLowercase: false,
    number: false,
    speciaChar: false,
    nonPrinting: false,
  });

  // security question field to dynamically change number of available questions based on the values selected in other security question fields implementation.
  

  const questions = readSecurityQuestions(securityQuestions) // security questions
  

  const [selectedValues,setSelectedValues] = useState([
    null,
    null,
    null
  ]) // stores the values selected in security questions fields 1,2 and 3

  const [remainingQuestions, setRemainingQuestions] = useState(questions) // Stores the set of questions that are not selected

  const handleValueChange = (e, fieldIndex) => {

    // updating the selected Values array
    const tempSelectedValues = selectedValues;
    tempSelectedValues[fieldIndex] = e.target.value;
    setSelectedValues(tempSelectedValues)

    // to update the remaining questions array to exclude the selected questions
    updateRemainingQuestions()
  }

  const updateRemainingQuestions = () => {
    setRemainingQuestions(
        questions.filter(questionOption => selectedValues.indexOf(questionOption.title) === -1))
  }

  useEffect(() => {
    updateRemainingQuestions();
  }, [t1, t]);

  // end of implementation

  const defaultValues = submitted?.[5]
    ? filledData?.[5]
    : {
      securityQuestion1: "",
      securityQuestion2: "",
      securityQuestion3: "",
    };

  useEffectOnce(() => {
    dispatch(getCMSTnC());
    scrollToTop()
  }, [])

  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues,
  });

  const { watch, trigger,clearErrors } = methods;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userIdError, setUserIdError] = useState(null);
  const watchShowUserId = watch("createUserID", false);
  /* Conflict Resolution Candidate
    const watchShowConfirmPassphrase = watch("confirmPassphrase", false);

    const changePasswordVisibility = (type) => {
      if (type === "password") {
        changeRefs(textRefPass);
        setShowPassword(!showPassword);
      } else {
        changeRefs(textRefConfirmPass);
   */

  const changePasswordVisibility = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  useEffect(() => {
    methods.resetField("createUserID");
    methods.resetField("passphrase");
  }, [])

  const passwordAdornments = (type) => {
    const show = type === "password" ? showPassword : showConfirmPassword;
    return (
      <InputAdornment position="end">
        <IconButton onClick={() => changePasswordVisibility(type)}>
          {/* Conflict Resolution Candidate
          {show ? <Visibility />:<VisibilityOff />}
          */}
          {show ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );
  };

  /* Conflict Resolution Candidate

    // ================for password hover========================
    const changeRefs = (refValue) => {
      refValue?.current?.focus();
      if (typeof window.getSelection !== "undefined" && typeof document.createRange !== "undefined") {
        const range = document.createRange();
        range.selectNodeContents(refValue.current);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange !== "undefined") {
        const textRange = document.body.createTextRange();
        textRange.moveToElementText(refValue.current);
        textRange.collapse(false);
        textRange.select();
      }
    };

   */
  const verifyCaptchaFun = () => {
    dispatch(setUserRegCaptcha(true));
    setVerifyCaptcha(true);
  };

  const handleCaptchaExpired = () => {
    dispatch(setUserRegCaptcha(false));
    setVerifyCaptcha(false);
  }

  const userIsUnique = async (value, type) => {
    if (!validateUserIDPattern(value)) {
      // eslint-disable-next-line consistent-return
      return `${t(t1, "ERR_ENTER_VALID_USER_ID")}`;
    }
    
    if (type === "checkValidUser" && watchShowUserId !== value) {
      const isValid = await validateUniqueUser({ username: value.toLowerCase() });
      if (!isValid ) {
        setUserIdError(`${t(t1, "ERR_USER_ID_IS_NOT_AVAILABLE")}`);
        return `${t(t1, "ERR_USER_ID_IS_NOT_AVAILABLE")}`;
      }
      if (isValid && methods?.getValues("createUserID") === value) {
        setUserIdError(null);
        return null;
      }
    }
    return userIdError;
  };

  const passwordValidate = (value) => {
    let message = null;
    if (value.startsWith(" ") || value.endsWith(" ")) {
      return displayParsedString(t1, "NO_TRAILING_SPACE_IN_PWD_ALLOWED");
    }
    const errorObject = passwordValidater(value);

    setPasswordValidation(errorObject);
    if (Object.values(errorObject).includes(false)) {
      message = `${t(t1, "ERR_INVALID_PASSPHRASE")}`;
    }
    if (methods.getValues("confirmPassphrase")?.length > 0) {
      if (methods.getValues("confirmPassphrase") !== value) {
        trigger("confirmPassphrase");  
      }
      if (methods.getValues("confirmPassphrase") === value) {
        clearErrors("confirmPassphrase");
      }
      
    }

    return message;
  };
  const confirmPaswordValidate=(value)=>{
    // let message = null;
    if (value.startsWith(" ") || value.endsWith(" ")) {
      return displayParsedString(t1, "NO_TRAILING_SPACE_IN_PWD_ALLOWED");
    }
    if(value !== methods.getValues('passphrase')) {
      return t(t1, "PASSPHARASE_MATCH_MESSAGE")
    } 
  }
  const [acceptTAndC, setAcceptTAndC] = useState(filledData?.[4].acceptTAndC || false);

  const isDisabled = !(submitted?.[1] && submitted?.[2] && submitted?.[3] && acceptTAndC);
  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <FormProvider {...methods}>
      <FormCard title={t(t1, "CREATE_USER_LOG_IN")} customeStyle={{ "& .indicate_fields": { mb: 2 } }}>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={2.5} direction="row" justifyContent="center" alignItems="center">
            <Grid item md={8.5}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2.5}>
                    <Grid item xs={12}>
                      <CustomizeTextfield
                        name="createUserID"
                        control={methods?.control}
                        label={t(t1, "CREATE_USER_ID")}
                        subLabel={`${t(t1, "USER_ID_MIN_MAX_CHAR")}`}
                        privId="priv_txt_createUserId"
                        defaultValue=''
                        InputProps={{
                          endAdornment:
                            <StyledTooltip title={<Typography color="black" sx={{ fontSize: "14px" }}>{t(t1, "EDIT_USER_ID_TOOLTIP_TXT")}</Typography>}>
                              <InputAdornment position="end">
                                <ErrorIcon sx={{ color: "#4d8da4", fontSize: "20px", mr: 1 }} />
                              </InputAdornment>
                            </StyledTooltip>
                        }}
                        rules={{
                          required: true,
                          // pattern: {
                          //   value: allowCommaDot,
                          //   message: `${t(t1, "VALIDATION_MESSAGE") + t(t1, "USER_ID")}`,
                          // },
                          validate: () =>
                            methods?.getValues("createUserID")
                              ? userIsUnique(methods?.getValues("createUserID"), "checkValidUser")
                              : null,
                          minLength: {
                            value: USER_ID_VALIDATION.USER_ID_MIN_LENGTH,
                            message: `${t(t1, "ERR_USER_ID_MUST_BE_CHAR_LONG")}`,
                          },
                          maxLength: {
                            value: USER_ID_VALIDATION.USER_ID_MAX_LENGTH,
                            message: `${t(t1, "ERR_USER_ID_MAX_LENGTH_CHAR")}`,
                          },
                        }}
                        isRequiredMsg={'ERR_CREATE_USER_ID_IS_REQUIRED'}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ pt: "5px !important" }}>
                      <CustomizeTextfield
                        inputClass="password-tooltip"
                        validation={passwordValidation}
                        type={showPassword ? "text" : "password"}
                        name="passphrase"
                        defaultValue=''
                        label={t(t1, "PASSPHRASE")}
                        privId="priv_txt_Passphrase"
                        subLabel={`${t(t1, "PASSPHRASE_MIN_MAX_CHAR")}`}
                        onCut={handleChange}
                        onCopy={handleChange}
                        onPaste={handleChange}
                        /* Conflict Resolution Candidate
                                                inputRef={textRefPass}
                         */
                        rules={{
                          required: true,
                          validate: passwordValidate,
                          // pattern: {
                          //   value:/^(?=[a-zA-Z0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{7,50}[a-zA-Z0-9]$/,
                          //   // value:/^(?=.*\d{1})(?=.*[a-z]{1})(?=.*[A-Z]{1})(?=.*[!@#$%^&*{|}?~_=+.-]{1})(?=.*[^a-zA-Z0-9]).{8,50}$/,
                          //   message:  `${t(t1, "PASSPHRASE") + t(t1, 'IS_INVALID')}`,
                          // }
                        }}
                        isRequiredMsg={'ERR_PASSPHRASE_IS_REQUIRED'}
                        InputProps={{
                          endAdornment: passwordAdornments("password"),
                          "aria-required": "true",
                        }}
                      /* Conflict Resolution Candidate
                                              onKeyUp={() => {
                                                    if(!isEmpty(watchShowConfirmPassphrase)) methods?.trigger("confirmPassphrase")
                                              }}
                       */
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ pt: "5px !important" }}>
                      <CustomizeTextfield
                        inputClass="password-tooltip"
                        validation={passwordValidation}
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassphrase"
                        label={t(t1, "CONFIRM_PASSPHRASE")}
                        subLabel={`${t(t1, "PASSPHRASE_MIN_MAX_CHAR")}`}
                        privId="priv_txt_confirmPassphrase"
                        onCut={handleChange}
                        onCopy={handleChange}
                        onPaste={handleChange}
                        rules={{
                          required: true,
                          validate: {
                            passEqual: (value) =>
                              value === methods?.getValues("passphrase") || t(t1, "PASSPHARASE_MATCH_MESSAGE"),
                          },
                        }}
                        isRequiredMsg={'ERR_CONFIRM_PASSPHRASE_IS_REQUIRED'}
                        /* Conflict Resolution Candidate
                                                inputRef={textRefConfirmPass}
                         */
                        InputProps={{
                          endAdornment: passwordAdornments("confirmPassword"),
                          "aria-required": "true",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CustomizeSelect
                    name="securityQuestion1"
                    label={`${t(t1, "LABEL_SECURITY_QUESTION_1")}`}
                    privId="priv_select_Question1"
                    options={remainingQuestions}
                    rules={{ required: true }}
                    noValueText={`${t(t1, "SELECT_SECURITY_QUESTION_1")}`}
                    onChange = {e => {
                      handleValueChange(e, 0); // introduced to handle dynamic changing of questions list
                    }}
                    isRequiredMsg={'ERR_SECURITY_QUESTION_1_IS_REQUIRED'}
                    fieldValue = {selectedValues[0]}
                  />
                </Grid>
                <Grid item xs={12} sx={{ pt: "0px !important" }}>
                  <CustomizeTextfield
                    name="answer1"
                    label={`${t(t1, "ANSWER_1")}`}
                    privId="priv_txt_Answer1"
                    rules={{
                      required: true,
                      // minLength: {
                      //   value: 4,
                      //   message: `${t(t1, "ERR_ANSWER_1_MIN_MSG")}`,
                      // },
                      maxLength: {
                        value: 64,
                        message: `${t(t1, "ERR_ANSWER_1_MAX_LENGTH_CHAR")}`,
                      },
                      // pattern: {
                      //   value: answerField,
                      //   message: `${t(t1, "VALIDATION_MESSAGE")} ${t(t1, "ANSWER")} 1`,
                      // },
                      validate: {
                        quesEqual: (value) =>
                          (value.trim().toLowerCase() !== methods.getValues("answer2").trim().toLowerCase() &&
                            value.trim().toLowerCase() !== methods.getValues("answer3").trim().toLowerCase()) ||
                          `${t(t1, "ERR_ANSWER_1_UNIQUE_MESSAGE")}`,
                        isValid: (v) =>
                          methods?.getValues("answer1") ? validateSecurityAnswer(v, `${t(t1, "ANSWER_1")}`, t1,`${t(t1, "ERR_ANSWER_1_MIN_MSG")}`, `${t(t1, "ERR_INVALID_ANSWER_1")}`,  nonPrintableChar) : null,
                      },
                    }}
                    isRequiredMsg={'ERR_ANSWER_1_IS_REQUIRED'}
                  /* Conflict Resolution Candidate
                                      onKeyUp={() => {
                                                if(!isEmpty(methods.getValues("answer2"))) methods?.trigger("answer2")
                                                if(!isEmpty(methods.getValues("answer3"))) methods?.trigger("answer3")
                                              }}
                   */
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomizeSelect
                    name="securityQuestion2"
                    label={`${t(t1, "LABEL_SECURITY_QUESTION_2")}`}
                    privId="priv_select_Question2"
                    options={remainingQuestions}
                    rules={{ required: true }}
                    isRequiredMsg={'ERR_SECURITY_QUESTION_2_IS_REQUIRED'}
                    noValueText={`${t(t1, "SELECT_SECURITY_QUESTION_2")}`}
                    onChange = {e => {
                      handleValueChange(e, 1); // introduced to handle dynamic changing of questions list
                    }}
                    fieldValue = {selectedValues[1]}
                  />
                </Grid>
                <Grid item xs={12} sx={{ pt: "0px !important" }}>
                  <CustomizeTextfield
                    name="answer2"
                    label={`${t(t1, "ANSWER_2")}`}
                    privId="priv_txt_Answer2"
                    rules={{
                      required: true,
                      // minLength: {
                      //   value: 4,
                      //   message: `${t(t1, "ERR_ANSWER_2_MIN_MSG")}`,
                      // },
                      maxLength: {
                        value: 64,
                        message: `${t(t1, "ERR_ANSWER_2_MAX_LENGTH_CHAR")}`,
                      },
                      // pattern: {
                      //   value: answerField,
                      //   message: `${t(t1, "VALIDATION_MESSAGE")} ${t(t1, "ANSWER")} 2`,
                      // },
                      validate: {
                        quesEqual: (value) =>
                          (value.trim().toLowerCase() !== methods.getValues("answer1").trim().toLowerCase() &&
                            value.trim().toLowerCase() !== methods.getValues("answer3").trim().toLowerCase()) ||
                          `${t(t1, "ERR_ANSWER_2_UNIQUE_MESSAGE")}`,
                        isValid: (v) =>
                           methods?.getValues("answer2") ? validateSecurityAnswer(v, `${t(t1, "ANSWER_2")}`, t1,`${t(t1, "ERR_ANSWER_2_MIN_MSG")}`, `${t(t1, "ERR_INVALID_ANSWER_2")}`, nonPrintableChar) : null,
                      },
                    }}
                    isRequiredMsg={'ERR_ANSWER_2_IS_REQUIRED'}
                  /* Conflict Resolution Candidate
                                      onKeyUp={() => {
                                                if(!isEmpty(methods.getValues("answer1"))) methods?.trigger("answer1")
                                                if(!isEmpty(methods.getValues("answer3"))) methods?.trigger("answer3")
                                              }}
                   */
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomizeSelect
                    name="securityQuestion3"
                    label={`${t(t1, "LABEL_SECURITY_QUESTION_3")}`}
                    privId="priv_select_Question3"
                    options={remainingQuestions}
                    rules={{ required: true }}
                    isRequiredMsg={'ERR_SECURITY_QUESTION_3_IS_REQUIRED'}
                    noValueText={`${t(t1, "SELECT_SECURITY_QUESTION_3")}`}
                    onChange = {e => {
                      handleValueChange(e, 2); // introduced to handle dynamic changing of questions list
                    }}
                    fieldValue = {selectedValues[2]} // the value selected by user
                  />
                </Grid>
                <Grid item xs={12} sx={{ pt: "0px !important" }}>
                  <CustomizeTextfield
                    name="answer3"
                    label={`${t(t1, "ANSWER_3")}`}
                    privId="priv_txt_Answer3"
                    rules={{
                      required: true,
                      // minLength: {
                      //   value: 4,
                      //   message: `${t(t1, "ERR_ANSWER_3_MIN_MSG")}`,
                      // },
                      maxLength: {
                        value: 64,
                        message: `${t(t1, "ERR_ANSWER_3_MAX_LENGTH_CHAR")}`,
                      },
                      // pattern: {
                      //   value: answerField,
                      //   message: `${t(t1, "VALIDATION_MESSAGE")} ${t(t1, "ANSWER")} 3`,
                      // },
                      validate: {
                        quesEqual: (value) =>
                          (value.trim().toLowerCase() !== methods.getValues("answer2").trim().toLowerCase() &&
                            value.trim().toLowerCase() !== methods.getValues("answer1").trim().toLowerCase()) ||
                          `${t(t1, "ERR_ANSWER_3_UNIQUE_MESSAGE")}`,
                        isValid: (v) =>
                          methods?.getValues("answer3") ? validateSecurityAnswer(v, `${t(t1, "ANSWER_3")}`, t1,`${t(t1, "ERR_ANSWER_3_MIN_MSG")}`, `${t(t1, "ERR_INVALID_ANSWER_3")}`, nonPrintableChar) : null,
                      },
                    }}
                    isRequiredMsg={'ERR_ANSWER_3_IS_REQUIRED'}
                  /* Conflict Resolution Candidate
                                      onKeyUp={() => {
                                                if(!isEmpty(methods.getValues("answer2"))) methods?.trigger("answer2")
                                                if(!isEmpty(methods.getValues("answer1"))) methods?.trigger("answer1")
                                              }}
                   */
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              // style={{ paddingTop: "0px !important", marginTop: "-20px !important" }}
              sx={{ mt: {md:"-45px !important",xs:0}, pt: "0px !important" }}
              md={3.5}
            >
              <Faq />
            </Grid>
          </Grid>
        </Box>
      </FormCard>
      <Box sx={{ ml: "20px", display: "flex", alignTtems: "center", justifyContent: "flex-end" }}>
        <ReCAPTCHA ref={captchaRef} onChange={verifyCaptchaFun} sitekey={process.env.REACT_APP_SITE_KEY} onExpired={handleCaptchaExpired} />
      </Box>
      <FormActions stepNumber={5} />
      {loader && <Loader loader={loader} />}
    </FormProvider>
  );
}

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      padding: "1px 7px",
      border: "1px solid #000",
      borderRadius: "0px",
      maxWidth: 500,
      // margin:"20px 0px 0px !important",
      marginTop: "20px !important"
    },
  })
);
