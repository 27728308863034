import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

// Simulate click on Space or Enter
function AccessibleLink({ to, children, ...props }) {
  const handleKeyDown = (event) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault(); 
      event.target.click(); 
    }
  };

  return (
    <Link
      component={RouterLink}
      to={to}
      onKeyDown={handleKeyDown}
      {...props} 
    >
      {children}
    </Link>
  );
}

export default AccessibleLink;
