const Style = {
  mainBox: {
    margin: "30px 60px 40px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  headertext: {
    fontSize: "14px",
  },
  contentBox: {
    margin: "25px 10px 0px",
  },
  heading: {
    display: "flex",
  },
  registrationBox:{
    marginTop:"20px"
  },
  formField:{
    width: "100%",
    display: "flex",
    justifyContent: "space-between", 
    paddingRight:"10px"
  },
  labelText:{
    fontSize:"15px"
  },
  labelValue:{ width: "60%", borderBottom: "1px solid black",fontSize: "15px",whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis" }
};

export default Style;
