import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { MOF_COMPONENTS, MOF_PAGES } from "pages/OffsetManagement/constants";
import {
  transformAffectedOffsetCredit,
  transformOTRDataEdit,
  transformOffsetDataEdit,
  transformOffsetTrackingRecords,
} from "pages/OffsetManagement/support";
import { APIS, ERROR } from "utils/constants";
import fetcher, { fetcherGet } from "utils/fetcher";
import logger from "utils/logger";
import {
  getJurisdictionUUID,
  getPermissionObj,
  transFormVintageYearToDropdownOptions,
  transformOTRApprovalsRes,
  transformProjectSites,
  updateOTRRecords,
} from "utils/support";
import { setLoader, setModelMessage } from "./commonSlice";

const initialState = {
  projectOperators: { isLoading: false, data: [] },
  projectName: { isLoading: false, data: [] },
  verificationBody: { isLoading: false, data: [] },
  vintageYear: { isLoading: false, data: [] },
  offsetTypes: { isLoading: false, data: [] },
  projectSites: { isLoading: false, data: [] },
  specialCategories: { isLoading: false, data: null },
  acquiringAccInfo: { isLoading: false, data: [] },
  offsetInfoEdit: { isLoading: false, data: [] },
  projectNameList: { isLoading: false, data: null },
  projectOperatorList: { isLoading: false, data: null },
  verificationBodyList: { isLoading: false, data: null },
  // edit project name
  projectNameEditInfo: { isLoading: false, data: null },
  projectNameHistoryTableData: { isLoading: false, data: [] },

  // edit project operator
  projectOperatorEditInfo: { isLoading: false, data: [] },
  projectOperatorHistoryTableData: { isLoading: false, data: [] },

  // edit verification body
  verificationBodyEditInfo: { isLoading: false, data: [] },
  verificationBodyHistoryTableData: { isLoading: false, data: [] },

  // Offset tracking record
  otrEditRecordInfo: { isLoading: false, data: null, error: null },
  juriOffsetCreditsTableData: { isLoading: false, data: [] },
  affectedOffsetCreditsTableData: { isLoading: false, data: [] },
  affectedOffsetCreditsInitialData: [],
  affectedAccounts: { isLoading: false, data: [] },
  affectedOffsetAccountsCompliance: { isLoading: false, data: [] },
  offsetRequestRevComment: { isLoading: false, data: [] },

  otrStatusHistoryTableData: { isLoading: false, data: [] },
  offsetTrackingRecords: { isLoading: false, data: [] },
  otrApprovals: { isLoading: false, data: [] },
  // offset issuance report
  offsetIssuanceReport: { isLoading: false, data: null },
};

const offsetManagementSlice = createSlice({
  name: "offsetManagement",
  initialState,
  reducers: {
    setProjectOperators: (state, action) => {
      state.projectOperators = action.payload;
    },
    setProjectName: (state, action) => {
      state.projectName = action.payload;
    },
    setVerificationBody: (state, action) => {
      state.verificationBody = action.payload;
    },
    setVintageYear: (state, action) => {
      state.vintageYear = action.payload;
    },
    setOffsetTypes: (state, action) => {
      state.offsetTypes = action.payload;
    },
    setProjectSites: (state, action) => {
      state.projectSites = action.payload;
    },
    setSpecialCategories: (state, action) => {
      state.specialCategories = action.payload;
    },
    setAcquiringAccInfo: (state, action) => {
      state.acquiringAccInfo = action.payload;
    },
    setProjectNameList: (state, action) => {
      state.projectNameList = action.payload;
    },
    setProjectOperatorList: (state, action) => {
      state.projectOperatorList = action.payload;
    },
    setVerificationBodyList: (state, action) => {
      state.verificationBodyList = action.payload;
    },
    setOffsetInfoEdit: (state, action) => {
      state.offsetInfoEdit = action.payload;
    },

    // edit project name
    setProjectNameEditInfo: (state, action) => {
      state.projectNameEditInfo = action.payload;
    },
    setProjectNameTableData: (state, action) => {
      state.projectNameHistoryTableData = action.payload;
    },

    // edit project operator
    setProjectOperatorEditInfo: (state, action) => {
      state.projectOperatorEditInfo = action.payload;
    },
    setProjectOperatorTableData: (state, action) => {
      state.projectOperatorHistoryTableData = action.payload;
    },

    // edit verification Body
    setVerificationBodyEditInfo: (state, action) => {
      state.verificationBodyEditInfo = action.payload;
    },
    setVerificationBodyTableData: (state, action) => {
      state.verificationBodyHistoryTableData = action.payload;
    },

    // offset tracking record
    setOTReditInfoData: (state, action) => {
      state.otrEditRecordInfo = action.payload;
    },
    setOffsetCreditData: (state, action) => {
      state.juriOffsetCreditsTableData = action.payload;
    },
    setAffectedOffsetCreditData: (state, action) => {
      state.affectedOffsetCreditsTableData = action.payload;
    },
    setInitialCreditData: (state, action) => {
      state.affectedOffsetCreditsInitialData = action.payload;
    },
    setOTRstatusHistoryData: (state, action) => {
      state.otrStatusHistoryTableData = action.payload;
    },

    setOffsetTrackingRecords: (state, action) => {
      state.offsetTrackingRecords = action.payload;
    },
    setAffectedAccounts: (state, action) => {
      state.affectedAccounts = action.payload;
    },
    setOffsetRequestRevComment: (state, action) => {
      state.offsetRequestRevComment = action.payload;
    },

    setAffectedOffsetAccountsCompliance: (state, action) => {
      state.affectedOffsetAccountsCompliance = action.payload;
    },
    setOTRApprovals: (state, action) => {
      state.otrApprovals = action.payload;
    },

    // offset Issuance report
    setOffsetIssuanceReport: (state, action) => {
      state.offsetIssuanceReport = action.payload;
    },
  },
});

export default offsetManagementSlice.reducer;

export const {
  setProjectOperators,
  setProjectName,
  setVerificationBody,
  setVintageYear,
  setOffsetTypes,
  setProjectSites,
  setSpecialCategories,
  setAcquiringAccInfo,
  setProjectNameList,
  setProjectOperatorList,
  setVerificationBodyList,
  setProjectNameEditInfo,
  setOffsetInfoEdit,
  setProjectNameTableData,
  setProjectOperatorEditInfo,
  setProjectOperatorTableData,
  setVerificationBodyEditInfo,
  setVerificationBodyTableData,
  setOTReditInfoData,
  setOffsetRequestRevComment,
  setOffsetCreditData,
  setAffectedOffsetCreditData,
  setOTRstatusHistoryData,
  setOffsetTrackingRecords,
  setOffsetIssuanceReport,
  setInitialCreditData,
  setAffectedAccounts,
  setAffectedOffsetAccountsCompliance,
  setOTRApprovals,
} = offsetManagementSlice.actions;

// **********    OFFSET ISSUANCE    *********

export function getProjectOperators(
  pageName = MOF_PAGES.OFFSET_PROJECT_OPERATOR_MANAGE_PAGE,
  componentName = MOF_COMPONENTS.OFFSET_PROJECT_OPERATOR_MANAGE_TABLE
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setProjectOperators({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(APIS.GET_PROJECT_OPERATORS_LIST, {}, { pageName, componentName });
      const { data, fieldAccess, metaData } = response;
      dispatch(setLoader(false));
      dispatch(
        setProjectOperators({
          isLoading: false,
          data: {
            data: data ? [...response.data, { id: "", name: "Add a New Offset Project Operator" }] : [],
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setProjectOperators({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

export function getProjectName(
  pageName = MOF_PAGES.MANAGE_OFFSET_PROJECT_NAME_PAGE,
  componentName = MOF_COMPONENTS.OFFSET_PROJECT_NAME_MANAGE_TABLE
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setProjectName({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(APIS.API_GET_ALL_PROJECT_NAME, {}, { pageName, componentName });
      const { data, fieldAccess, metaData } = response;
      dispatch(setLoader(false));
      dispatch(
        setProjectName({
          isLoading: false,
          data: {
            data: data ? [...data, { id: "", name: "Add a New Offset Project Name" }] : [],
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setProjectName({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}
export function getVerificationBody(
  pageName = MOF_PAGES.VERIFICATION_BODY_MANAGE_PAGE,
  componentName = MOF_COMPONENTS.VERIFICATION_BODY_MANAGE_TABLE
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setVerificationBody({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(APIS.GET_VERIFICATION_BODY_LIST, {}, { pageName, componentName });
      const { data, fieldAccess, metaData } = response;
      dispatch(setLoader(false));
      dispatch(
        setVerificationBody({
          isLoading: false,
          data: {
            data: data ? [...response.data, { id: "", name: "Add a New Verification Body" }] : [],
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setVerificationBody({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}
export function getVintageYear(pageName, componentName) {
  return async (dispatch) => {
    const jurisdictionId = getJurisdictionUUID();
    dispatch(setLoader(true));
    dispatch(
      setVintageYear({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(`${APIS.VINTAGE_YEAR}/${jurisdictionId}`, {}, { pageName, componentName });
      const { data, fieldAccess, metaData } = response;
      dispatch(setLoader(false));
      dispatch(
        setVintageYear({
          isLoading: false,
          data: {
            data: transFormVintageYearToDropdownOptions(data),
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setVintageYear({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

export function getOffsetType(pageName, componentName) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setOffsetTypes({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(`${APIS.OFFSET_TYPE_API}`, {}, { pageName, componentName });
      const { data, fieldAccess, metaData } = response;
      dispatch(setLoader(false));
      dispatch(
        setOffsetTypes({
          isLoading: false,
          data: {
            data: data?.map((item, index) => ({ id: index, name: item.offsetType })),
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setOffsetTypes({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

export function getProjectSite() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setProjectSites({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcher(`${APIS.ENTITY_STATES}`, "GET", {}, false, {});
      dispatch(setLoader(false));
      dispatch(
        setProjectSites({
          isLoading: false,
          data: {
            data: transformProjectSites(response?.data),
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setProjectSites({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

export function getSpecialCategories(pageName, componentName) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setSpecialCategories({
        isLoading: true,
        data: null,
      })
    );
    try {
      const response = await fetcherGet(`${APIS.SPECIAL_CATEGORY}`, {}, { pageName, componentName });
      const { data, fieldAccess, metaData } = response;
      dispatch(setLoader(false));
      dispatch(
        setSpecialCategories({
          isLoading: false,
          data: {
            data: data?.map((item, index) => ({ id: index, name: item.specialCategory })),
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setSpecialCategories({ isLoading: false, data: null }));
      logger({ error });
    }
  };
}

export function getAcquiringAccInfo(accNo) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setAcquiringAccInfo({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcher(`${APIS.ACQUIRING_ACCOUNT_GET}/${accNo}`, "GET", {}, false, {});
      dispatch(setLoader(false));
      dispatch(
        setAcquiringAccInfo({
          isLoading: false,
          data: response?.data,
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setAcquiringAccInfo({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

// get offset info API to pre-populate offset issuance form
export function getOffsetInfoEdit(transferId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setOffsetInfoEdit({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcher(`${APIS.GET_OFFSET_ISSUANCE_INFO}/${transferId}`, "GET", {}, false, {});
      dispatch(setLoader(false));
      dispatch(
        setOffsetInfoEdit({
          isLoading: false,
          data: response?.data,
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setOffsetInfoEdit({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

// to prepopulate edit fields in project name
export function getProjectNameEditInfoAPI(
  ProjectNameId,
  pageName = MOF_PAGES.EDIT_PROJECT_NAME_PAGE,
  componentName = MOF_COMPONENTS.OFFSET_PROJECT_NAME_DATA
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setProjectNameEditInfo({
        isLoading: true,
        data: null,
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.GET_PROJECT_NAME_EDIT_INFO}/${ProjectNameId}`,
        {},
        { pageName, componentName }
      );
      const { data, fieldAccess, metaData } = response;
      dispatch(setLoader(false));
      dispatch(
        setProjectNameEditInfo({
          isLoading: false,
          data: {
            data: transformOffsetDataEdit(data?.[0]),
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setProjectNameEditInfo({ isLoading: false, data: null }));
      logger({ error });
    }
  };
}

export function getProjectNameHistoryTableDataAPI(
  ProjectNameId,
  pageName = MOF_PAGES.EDIT_PROJECT_NAME_PAGE,
  componentName = MOF_COMPONENTS.OFFSET_PROJECT_NAME_HISTORY
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setProjectNameTableData({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.GET_PROJECT_NAME_HISTORY_API}/${ProjectNameId}`,
        {},
        { pageName, componentName }
      );
      dispatch(setLoader(false));
      dispatch(
        setProjectNameTableData({
          isLoading: false,
          data: response,
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setProjectNameTableData({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

// update the offset project name
export function editProjectNameAPI(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.EDIT_PROJECT_NAME_API}`, "PUT", payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}
// add new offset project name
export function postOffsetProjectName(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.POST_OFFSET_PROJECT_NAME_API}`, "POST", payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// add new offset project operator
export function postOffsetProjectOperator(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.POST_OFFSET_PROJECT_OPERATOR_API}`, "POST", payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// add new verification body
export function postVerificationBody(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.POST_VERIFICATION_BODY_API}`, "POST", payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// to prepopulate project opeartor edit fields
export function getProjectOperatorEditInfoAPI(
  ProjectOperatorId,
  pageName = MOF_PAGES.EDIT_PROJECT_OPERATOR_PAGE,
  componentName = MOF_COMPONENTS.OFFSET_PROJECT_OPERATOR_DATA
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setProjectOperatorEditInfo({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.GET_PROJECT_OPERATOR_EDIT_INFO}/${ProjectOperatorId}`,
        {},
        { pageName, componentName }
      );
      const { data, fieldAccess } = response;
      dispatch(setLoader(false));
      dispatch(
        setProjectOperatorEditInfo({
          isLoading: false,
          data: {
            data: transformOffsetDataEdit(data[0]),
            fieldAccess: getPermissionObj(fieldAccess, componentName),
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setProjectOperatorEditInfo({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

export function getProjectOperatorHistoryTableDataAPI(
  ProjectOperatorId,
  pageName = MOF_PAGES.EDIT_PROJECT_OPERATOR_PAGE,
  componentName = MOF_COMPONENTS.OFFSET_PROJECT_OPERATOR_HISTORY
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setProjectOperatorTableData({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.GET_PROJECT_OPERATOR_HISTORY_API}/${ProjectOperatorId}`,
        {},
        { pageName, componentName }
      );
      dispatch(setLoader(false));
      dispatch(
        setProjectOperatorTableData({
          isLoading: false,
          data: response,
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setProjectOperatorTableData({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

// update the offset project operator
export function editProjectOperatorAPI(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.EDIT_PROJECT_OPERATOR_API}`, "PUT", payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// get all the offset project names
export function getProjectNameList() {
  return (dispatch) => {
    dispatch(setProjectNameList({ isLoading: true, data: null }));
    const pageName = MOF_PAGES.MANAGE_OFFSET_PROJECT_NAME_PAGE;
    const componentName = MOF_COMPONENTS.OFFSET_PROJECT_NAME_MANAGE_TABLE;
    fetcher(`${APIS.API_GET_ALL_PROJECT_NAME}`, "GET", {}, false, { pageName, componentName })
      .then((response) => {
        dispatch(
          setProjectNameList({
            isLoading: false,
            data: response,
          })
        );
      })
      .catch(() => {
        dispatch(setProjectNameList({ isLoading: false, data: null }));
      });
  };
}

// get all the offset project operators
export function getProjectOperatorList() {
  return (dispatch) => {
    dispatch(setProjectOperatorList({ isLoading: true, data: null }));
    const pageName = MOF_PAGES.OFFSET_PROJECT_OPERATOR_MANAGE_PAGE;
    const componentName = MOF_COMPONENTS.OFFSET_PROJECT_OPERATOR_MANAGE_TABLE;
    fetcher(`${APIS.API_GET_ALL_PROJECT_OPERATOR}`, "GET", {}, false, { pageName, componentName })
      .then((response) => {
        dispatch(
          setProjectOperatorList({
            isLoading: false,
            data: response,
          })
        );
      })
      .catch(() => {
        dispatch(setProjectOperatorList({ isLoading: false, data: null }));
      });
  };
}
// get all the verification bodies
export function getVerificationBodyList() {
  return (dispatch) => {
    dispatch(setVerificationBodyList({ isLoading: true, data: null }));
    const pageName = MOF_PAGES.VERIFICATION_BODY_MANAGE_PAGE;
    const componentName = MOF_COMPONENTS.VERIFICATION_BODY_MANAGE_TABLE;
    fetcher(`${APIS.API_GET_ALL_VERIFICATION_BODY}`, "GET", {}, false, { pageName, componentName })
      .then((response) => {
        dispatch(
          setVerificationBodyList({
            isLoading: false,
            data: response,
          })
        );
      })
      .catch(() => {
        dispatch(setVerificationBodyList({ isLoading: false, data: null }));
      });
  };
}

// to prepopulate verification edit fields
export function getVerificationBodyEditInfoAPI(
  verificationBodyId,
  pageName = MOF_PAGES.EDIT_VERIFICATION_BODY,
  componentName = MOF_COMPONENTS.OFFSET_VERIFICATION_BODY_DATA
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setVerificationBodyEditInfo({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.GET_VERIFICATION_BODY_EDIT_INFO}/${verificationBodyId}`,
        {},
        { pageName, componentName }
      );
      const { data, fieldAccess } = response;
      dispatch(setLoader(false));
      dispatch(
        setVerificationBodyEditInfo({
          isLoading: false,
          data: {
            data: transformOffsetDataEdit(data?.[0]),
            fieldAccess: getPermissionObj(fieldAccess, componentName),
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setVerificationBodyEditInfo({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

export function getVerificationBodyHistoryTableDataAPI(
  verificationBodyId,
  pageName = MOF_PAGES.EDIT_VERIFICATION_BODY,
  componentName = MOF_COMPONENTS.OFFSET_VERIFICATION_BODY_HISTORY
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setVerificationBodyTableData({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.GET_VERIFICATION_BODY_HISTORY_API}/${verificationBodyId}`,
        {},
        { pageName, componentName }
      );
      dispatch(setLoader(false));
      dispatch(
        setVerificationBodyTableData({
          isLoading: false,
          data: response,
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setVerificationBodyTableData({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

// update the verification body
export function editVerificationBodyAPI(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.EDIT_VERIFICATION_BODY}`, "PUT", payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// add new offset tracking record
export function postOffsetTrackingRecord(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.OFFSET_TRACKING_RECORD}`, "POST", payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// propose offset tracking record
export function proposeOffsetTrackingRecord(params) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(
      `${APIS.PROPOSE_OFFSET_TRACKING_RECORD_API}/${params.jurisdictionId}/freezeProposeOffsetTrackingRecord/${params.otrId}`,
      "POST",
      {},
      false,
      {}
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// save offset tracking record,,,, addd
export function saveOffsetTrackingRecord(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.OFFSET_TRACKING_RECORD}/${payload.otrId}`, "PUT", payload.data, false, pageDetails).finally(
      () => {
        dispatch(setLoader(false));
      }
    );
  };
}

// delete offset tracking record
export function deleteOffsetTrackingRecord(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.DELETE_OTR}`, "POST", payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// get Offset Tracking Records
export function getOffsetTrackingRecords(retries = 3) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setOffsetTrackingRecords({
        isLoading: true,
        data: null,
      })
    );
    try {
      const pageName = MOF_PAGES.OFFSET_TRACKING_RECORD;
      const componentName = MOF_COMPONENTS.OFFSET_TRACKING_RECORD_TABLE;
      const response = await fetcher(`${APIS.OFFSET_TRACKING_RECORD}`, "GET", {}, false, { pageName, componentName });
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setOffsetTrackingRecords({
          isLoading: false,
          data: {
            data: transformOffsetTrackingRecords(data),
            metaData,
            fieldAccess,
          },
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      if (retries > 0) {
        // Retry the request
        getOffsetTrackingRecords(retries - 1);
      } else {
        dispatch(setLoader(false));
        dispatch(setOffsetTrackingRecords({ isLoading: false, data: null }));
        logger({ error });
      }
    }
  };
}

// to prepopulate otr info propose/edit fields
export function getOtrEditInfoAPI(
  otrID,
  pageName = MOF_PAGES.OTR_DETAILS,
  componentName = MOF_COMPONENTS.OFFSET_TRACKING_RECORD
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setOTReditInfoData({
        isLoading: true,
        data: null,
      })
    );
    try {
      const response = await fetcherGet(`${APIS.OFFSET_TRACKING_RECORD}/${otrID}`, {}, { pageName, componentName });
      const { data, fieldAccess } = response;

      dispatch(setLoader(false));
      dispatch(
        setOTReditInfoData({
          isLoading: false,
          data: {
            data: transformOTRDataEdit(data),
            fieldAccess: getPermissionObj(fieldAccess, componentName),
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setOTReditInfoData({ isLoading: false, data: null, error: error?.response?.data }));
      logger({ error });
    }
  };
}

export function getOtrOffsetCreditsAPI(
  juriId,
  pageName = MOF_PAGES.PROPOSE_OTR,
  componentName = MOF_COMPONENTS.JURISDICTION_OFFSET_CREDIT
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setOffsetCreditData({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(`${APIS.GET_OFFSET_CREDITS}/${juriId}`, {}, { pageName, componentName });

      dispatch(setLoader(false));
      dispatch(
        setOffsetCreditData({
          isLoading: false,
          data: response,
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setOffsetCreditData({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

export function getOtrAffectedOffsetCreditsAPI(
  otrID,
  pageName = MOF_PAGES.OTR_DETAILS,
  componentName = MOF_COMPONENTS.AFFECTED_OFFSET_CREDIT
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setAffectedOffsetCreditData({
        isLoading: true,
        data: [],
      })
    );
    dispatch(setInitialCreditData([]));
    try {
      const response = await fetcherGet(
        `${APIS.GET_AFFECTED_OFFSET_CREDITS}/${otrID}`,
        {},
        { pageName, componentName }
      );
      dispatch(setLoader(false));
      dispatch(
        setAffectedOffsetCreditData({
          isLoading: false,
          data: response,
        })
      );
      dispatch(setInitialCreditData(transformAffectedOffsetCredit(response?.data)));
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setAffectedOffsetCreditData({ isLoading: false, data: [] }));
      dispatch(setInitialCreditData([]));
      logger({ error });
    }
  };
}

export function getOtrStatusHistoryAPI(
  otrID,
  pageName = MOF_PAGES.OTR_DETAILS,
  componentName = MOF_COMPONENTS.OFFSET_TRACKING_RECORD_HISTORY
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setOTRstatusHistoryData({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(`${APIS.GET_OTR_STATUS_HISTORY}/${otrID}`, {}, { pageName, componentName });
      dispatch(setLoader(false));
      dispatch(
        setOTRstatusHistoryData({
          isLoading: false,
          data: response,
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setOTRstatusHistoryData({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

export function getOffsetIssuanceReport() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setOffsetIssuanceReport({
        isLoading: true,
        data: null,
      })
    );
    try {
      const pageName = MOF_PAGES.OFFSET_ISSUANCE_REPORT_PAGE;
      const componentName = MOF_COMPONENTS.OFFSET_ISSUANCE_REPORT_TABLE;
      const response = await fetcher(`${APIS.OFFSET_ISSUANCE_REPORT}`, "GET", {}, false, { pageName, componentName });
      dispatch(
        setOffsetIssuanceReport({
          isLoading: false,
          data: response,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setOffsetIssuanceReport({ isLoading: false, data: null }));
      logger({
        error,
      });
    }
  };
}

// To add offset affected credit
export function addOffsetCreditsAPI(payload, pageDetails) {
  return async (dispatch, getState) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.ADD_OFFSET_AFFECTED_CREDITS}`, "POST", payload.data, false, pageDetails)
      .then(() => {
        // update the state and change Add button flag
        const data = updateOTRRecords(
          payload.data?.offsetTrackingTransferIdArr,
          getState().offsetManagement.juriOffsetCreditsTableData.data?.data
        );
        dispatch(
          setOffsetCreditData({
            isLoading: false,
            data: {
              ...getState().offsetManagement.juriOffsetCreditsTableData.data,
              data: data ?? [],
            },
          })
        );

        // add record in affected offset credit table data
        dispatch(
          setAffectedOffsetCreditData({
            isLoading: false,
            data: {
              ...getState().offsetManagement.affectedOffsetCreditsTableData.data,
              data: [
                ...getState().offsetManagement.affectedOffsetCreditsTableData.data.data,
                ...payload.foundMatchingEle,
              ],
            },
          })
        );
        // dispatch(getOtrOffsetCreditsAPI(getJurisdictionUUID()));
        // dispatch(getOtrAffectedOffsetCreditsAPI(id, MOF_PAGES.PROPOSE_OTR, MOF_COMPONENTS.AFFECTED_OFFSET_CREDIT));
      })
      .catch((error) => {
        const data = {
          title: ERROR,
          message1: `${error.response?.data?.data || error?.message}`,
          error: true,
          isDefaultDisplay: true,
        };
        dispatch(setModelMessage(data));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

// To delete offset affected credit
export function removeAfectedOffsetCreditAPI(payload) {
  return async (dispatch, getState) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.ADD_OFFSET_AFFECTED_CREDITS}`, "DELETE", payload.data, false, {})
      .then(() => {
        dispatch(
          setAffectedOffsetCreditData({
            isLoading: false,
            data: {
              ...getState().offsetManagement.affectedOffsetCreditsTableData.data,
              data: payload.foundMatchingEle,
            },
          })
        );

        const data = updateOTRRecords(
          payload.data?.offsetTrackingTransferIdArr,
          getState().offsetManagement.juriOffsetCreditsTableData.data?.data
        );
        dispatch(
          setOffsetCreditData({
            isLoading: false,
            data: {
              ...getState().offsetManagement.juriOffsetCreditsTableData.data,
              data: data ?? [],
            },
          })
        );
        // dispatch(getOtrOffsetCreditsAPI(getJurisdictionUUID()));
        // dispatch(getOtrAffectedOffsetCreditsAPI(id, MOF_PAGES.PROPOSE_OTR, MOF_COMPONENTS.AFFECTED_OFFSET_CREDIT));
      })
      .catch((error) => {
        const data = {
          title: ERROR,
          message1: `${error.response?.data?.data || error?.message}`,
          error: true,
          isDefaultDisplay: true,
        };
        dispatch(setModelMessage(data));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getAffectedAccounts(
  otrID,
  pageName = MOF_PAGES.OTR_DETAILS,
  componentName = MOF_COMPONENTS.AFFECTED_OFFSET_ACCOUNTS
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setAffectedAccounts({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(`${APIS.API_GET_AFFECTED_ACCOUNTS}/${otrID}`, {}, { pageName, componentName });
      dispatch(setLoader(false));
      dispatch(
        setAffectedAccounts({
          isLoading: false,
          data: response,
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setAffectedAccounts({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

export function getAffectedOffsetAccountsCompliance(
  otrID,
  pageName = MOF_PAGES.OTR_DETAILS,
  componentName = MOF_COMPONENTS.AFFECTED_OFFSET_ACCOUNTS_COMPLIANCE
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setAffectedOffsetAccountsCompliance({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.GET_AFFECTED_OFFSET_ACCOUNTS_COMPLIANCE}/${otrID}`,
        {},
        { pageName, componentName }
      );
      dispatch(setLoader(false));
      dispatch(
        setAffectedOffsetAccountsCompliance({
          isLoading: false,
          data: response,
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

export function getOTRApprovals(jurisdictionId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setOTRApprovals({ isLoading: true, data: [] }));
    try {
      if (jurisdictionId) {
        const response = await fetcher(`${APIS.API_GET_OTR_APPROVALS}/${jurisdictionId}`, "GET", {}, false, {});
        dispatch(setOTRApprovals({ isLoading: false, data: transformOTRApprovalsRes(response?.data) }));
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setOTRApprovals({ isLoading: false, data: [] }));
      logger({ error });
    }
  };
}

export function getOTRRequestRevisionsComment(
  id,
  pageName = MOF_PAGES.OTR_DETAILS,
  componentName = MOF_COMPONENTS.REVISION_REQUESTED
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setOffsetRequestRevComment({
        isLoading: true,
        data: null,
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.API_GET_OTR_REQUEST_REV_COMMENT}/${id}`,
        {},
        { pageName, componentName }
      );

      const { data, fieldAccess } = response;

      dispatch(setLoader(false));
      dispatch(
        setOffsetRequestRevComment({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, componentName),
          },
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setOffsetRequestRevComment({ isLoading: false, data: null }));
      logger({ error });
    }
  };
}
