import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ButtonFill } from "components/ButtonFill";
import { ButtonFillEdit } from "components/ButtonFillEdit";
import { ButtonOutline } from "components/ButtonOutline";
import { Button } from "@mui/material";
import { CustomizeButton } from "components/CustomizeButton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessage } from "components";
import { setInitialPrimaryChecked, setPrimaryChecked, updateEditFieldState } from "store/createUserProfileSlice";
import { COMPONENT_MODES, getCurrentPageName, t } from "utils/constants";
import { setDialogMessage } from "store/commonSlice";

import Style from "./FormCard.style";

export default function FormCardAddFacility({
  customClass,
  children,
  title = "",
  indicateRequired = true,
  formLabel = "",
  handleSameAddress,
  setDefaultValues,
  stepNumber = 0,
  disableElevation,
  defaultValues,
  customeStyle = "",
  saveDisabled = false,
  defaultChecked,
  mode = COMPONENT_MODES.ADD,
  handleEnableEdit = null,
  stepData = null,
  handleSubmitUpdate,
  cancelContactEdit = null,
  isRevisionRequested = false,
  isEditAccess = true,
  cancelAuction = null,
  cancelRegistraionContactEdit = null,
  cancelEntityIdentifiers = null,
  showCancelPopup = false,
  showSavePopup = false,
  disableCheck=false,
  disableCheckOnReview = false,
  isFacilityInRegistry= false,
  setCustomDefaultValues,
  useEntityChecked,
}) {
  const { t: t1 } = useTranslation(getCurrentPageName());

  const {
    formSteps: { activeStep },
    primaryChecked,
    initialPrimaryChecked,
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { dialogMessage, dialogMessageForSave } = useSelector(({ common }) => common);
  const { maillingBtnDisable } = useSelector(({ entityApplication }) => entityApplication);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);
  const [isEdit, setIsEdit] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const [CancelModal, setCancelModal] = useState(false);
  const [SaveModal, setSaveModal] = useState(false);

  useEffect(() => {
    closeEditAction();
    if (defaultValues) {
      setChecked(defaultValues?.Enabled);
    }
  }, []);

  const handleCheckbox = (e) => {
    const { checked } = e.target;
    setChecked(checked);
    if (activeStep === 4 && !checked) {
      handleSameAddress(checked);
    } else {
      dispatch(setInitialPrimaryChecked(checked));
      handleSameAddress(checked);
    }
    if(setCustomDefaultValues && !checked && useEntityChecked === false){
      setCustomDefaultValues();
      }
  };

  const handleCheckboxOnEnter = (e) => {
    const { checked } = e.target;
    setChecked((val) => !val);
    if (activeStep === 4 && !checked) {
      handleSameAddress(!checked);
    } else {
      dispatch(setInitialPrimaryChecked(!checked));
      handleSameAddress(!checked);
    }
    if(setCustomDefaultValues && !checked && useEntityChecked === false){
      setCustomDefaultValues();
    }
  };

  const handleEdit = () => {
    dispatch(updateEditFieldState({ step: stepNumber, value: true }));
    setIsEdit({ ...isEdit, [stepNumber]: true });
  };

  const handleEntityEdit = () => {
    if (handleEnableEdit) {
      handleEnableEdit(true);
    }
  };

  const closeEntityEditAction = () => {
    if (handleEnableEdit) {
      handleEnableEdit(false);
    }
  };

  const handleSaveData = (data) => {
    if (handleSubmitUpdate) handleSubmitUpdate(data);
  };

  const ResetToOldValues = () => {
    // closeEntityEditAction(false);
    closeEntityEditAction();
    if (cancelContactEdit) {
      cancelContactEdit();
    }
    if (cancelAuction) {
      cancelAuction();
    }
    if (cancelRegistraionContactEdit) {
      cancelRegistraionContactEdit();
    }
    if (cancelEntityIdentifiers) {
      cancelEntityIdentifiers();
    }
  };

  const handleSaveBtn = (data) => {
    if (showSavePopup) {
      dispatch(setDialogMessage(true));
      setSaveModal(true);
      setCancelModal(false);
    } else {
      handleSaveData(data);
    }
  };

  const handleEntityEditCancel = () => {
    if (showCancelPopup) {
      dispatch(setDialogMessage(true));
      setCancelModal(true);
      setSaveModal(false);
    } else {
      ResetToOldValues();
    }
  };

  const onClickCancelModal = () => {
    ResetToOldValues();
  };

  const handleModalSave = () => {
    handleSaveData();
  };

  const handleEditCancel = () => {
    setDefaultValues();
    closeEditAction();
    dispatch(setPrimaryChecked(initialPrimaryChecked));
    setChecked(initialPrimaryChecked);
  };

  const closeEditAction = () => {
    dispatch(updateEditFieldState({ step: stepNumber, value: false }));
    setIsEdit({ ...isEdit, [stepNumber]: false });
  };

  const handleUpdateCB = () => {
    closeEditAction();
    dispatch(setInitialPrimaryChecked(checked));
  };

  return (
    <Paper
      className={customClass}
      elevation={disableElevation ? 0 : 2}
      sx={{ ...Style?.formCardWrapper, ...customeStyle }}
    >
      <Grid
        container
        spacing={2.5}
      >
        <Grid
          item
          md={9}
        >
          {title ? (
            <Typography
              variant="h2"
              component="div"
              sx={{ mb: 0.5 }}
            >
              {title}
            </Typography>
          ) : null}

          {indicateRequired ? (
            <Typography
              variant="caption"
              component="div"
              display="block"
              sx={{ mb: 3 }}
              className="indicate_fields"
            >
              <Typography
                variant="body2"
                sx={{
                  display: "inline-block",
                  color: "common.red",
                  mr: 0.5,
                  fontWeight: 700,
                  mb: "0px",
                }}
              >
                *
              </Typography>
              {t(t1, "INDICATE_REQUIRED_FIELDS")}
            </Typography>
          ) : null}
        </Grid>

        {stepData && !stepData.editMode && mode === COMPONENT_MODES.EDIT && !isRevisionRequested && isEditAccess && (
          <Grid
            item
            md={3}
            sx={{ textAlign: "right" }}
          >
            <ButtonFillEdit
              styleSx={{ py: 0.25, px: 2, minWidth: 80, widht: 80, height: 30 }}
              handleClick={handleEntityEdit}
            >
              {t(t1, "EDIT")}
            </ButtonFillEdit>
          </Grid>
        )}

        {stepData && !stepData.editMode && mode === COMPONENT_MODES.EDIT && isRevisionRequested && (
          <Grid
            item
            md={3}
            sx={{ textAlign: "right" }}
          >
            <ButtonFillEdit
              variant="outlined"
              styleSx={{ py: 0.4, px: 2, minWidth: 130, mb: 3, mt: 1, textTransform: "uppercase !important" }}
              handleClick={handleEntityEdit}
            >
              {t(t1, "ADD_FACILITY")}
            </ButtonFillEdit>
          </Grid>
        )}

        {false && (
          <Grid
            item
            md={3}
            style={{ textAlign: "right" }}
          >
            <ButtonOutline
              styleSx={{ py: 0.6, px: 2, mb: 2, minWidth: 80 }}
              handleClick={handleEntityEdit}
            >
              {t(t1, "ASSOCIATE_FACILITY")}
            </ButtonOutline>
          </Grid>
        )}
        {activeStep === 4 && !isEdit[stepNumber] && title !== t(t1, "SELECTED_JURISDICTION") && stepNumber !== 0 ? (
          <Grid
            item
            md={3}
            sx={{ textAlign: "right" }}
          >
            <ButtonFillEdit
              styleSx={{ py: 0.4, px: 2, minWidth: 80 }}
              handleClick={handleEdit}
            >
              {t(t1, "EDIT")}
            </ButtonFillEdit>
          </Grid>
        ) : null}
      </Grid>
      {formLabel ? (
        <FormGroup>
          <FormControlLabel
            style={{ pointerEvents: "none"}}
            classes={{ label: stepNumber === 3 ? 'disabled-label-primary' : ''}}
            control={
              <Checkbox
                className={stepNumber === 3 ? 'disabled-checkbox-primary' : ''}
                style={{ pointerEvents: "auto" }}
                checked={checked || checked === "true" || isFacilityInRegistry}
                onChange={(e) => handleCheckbox(e)}
                disabled={(activeStep === 4 && !isEdit[stepNumber]) || disableCheck || disableCheckOnReview}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleCheckboxOnEnter(e);
                  }
                }}
              />
            }
            label={formLabel}
          />
        </FormGroup>
      ) : null}
      <Box className="formContent">{children}</Box>

      {/* if mailling address checked then it will hide the save button */}
      {stepData && stepData.editMode && mode === COMPONENT_MODES.EDIT && (
        <Box sx={{ mt: 2.5, textAlign: "right" }} className="cancel-save-btn-wrapper">
          <ButtonOutline
            styleSx={{ py: 0.7, px: 2, minWidth: 80, width: 80, mr: 1, height: 30 }}
            handleClick={handleEntityEditCancel}
            className="cancelBtnAction"
          >
            {t(t1, "CANCEL")}
          </ButtonOutline>
          <CustomizeButton
            type="submit"
            name="priv_btn_save"
            styleSx={{ py: 0.4, px: 2, minWidth: 80 }}
            handleClick={handleSaveBtn}
            disabled={saveDisabled}
            label={t(t1, "SAVE")}
            className="btn-primary-sm"
            customStyle={Style.btnSm}
          >
            {t(t1, "SAVE")}
          </CustomizeButton>

          {/* Cancel dialog msg */}
          {showCancelPopup && dialogMessage && CancelModal ? (
            <DialogMessage
              warning
              title={t(t1, "WARNING")}
              message1={t(t1, "CANCEL_REVERT_MSG")}
              buttonMessage={t(t1, "CANCEL_CONTINUE_REVERT_MSG")}
              handleModalClick={onClickCancelModal}
            />
          ) : null}

          {/* Save dialog msg */}
          {showSavePopup && dialogMessage && SaveModal ? (
            <DialogMessage
              warning
              title={"Save"}
              message1={t(t1, "CANCEL_REVERT_MSG")}
              buttonMessage={t(t1, "SAVE_CONTINUE_MSG")}
              handleModalClick={handleModalSave}
            />
          ) : null}
        </Box>
      )}
      {activeStep === 4 && isEdit[stepNumber] ? (
        <Box sx={{ mt: 2.5, textAlign: "right" }} className="cancel-save-btn-wrapper">
          <ButtonOutline
            styleSx={{ py: 0.4, px: 2, minWidth: 80, mr: 2 }}
            handleClick={handleEditCancel}
          >
            {t(t1, "CANCEL")}
          </ButtonOutline>
          <ButtonFill
            name="priv_btn_save"
            styleSx={{ py: 0.4, px: 2, minWidth: 80 }}
            stepNumber={stepNumber === 4 ? 3 : stepNumber}
            handleUpdateCB={handleUpdateCB}
            saveDisabled={saveDisabled}
          >
            {t(t1, "SAVE")}
          </ButtonFill>
        </Box>
      ) : null}
    </Paper>
  );
}
