import { Box, Checkbox, Typography } from "@mui/material";
import parse from "html-react-parser";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { defaultORGID, enLanguage, formatDate, getAllLabels, getCurrentPageName, HomePageName, COUNTRY_KEY_NAMES, parseOptions, t } from "utils/constants";

import SiteLogo from "../../assets/images/logo.png";
import Style from "./FormPrintStructure.style";

// formPrintStructure constant
const PageName = "Page_LandingPageKnown";

export const FormPrintStructure = React.forwardRef((props, ref) => {
  const { t: t1 } = useTranslation(getCurrentPageName());
/* Conflict Resolution Candidate
  const { parentPageName = "" } = props;
  const { cmsLandingConfig } = useSelector(({ config }) => config);
  const { filledData, userReferenceCode } = useSelector(({ createUserProfile }) => createUserProfile);
  // const userDetails = useSelector(({ userHome }) => userHome);
  const { userDetails, referenceCode } = useSelector(({ userHome }) => userHome);
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);

  const getUserDetails = { ...userDetails };

  getUserDetails.Name = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Name)[0]?.Name ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Name)[0]?.Name : userDetails?.Name;

  getUserDetails.MiddleName = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MiddleName)[0]?.MiddleName ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MiddleName)[0]?.MiddleName : userDetails?.MiddleName;

  getUserDetails.FamilyName = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.FamilyName)[0]?.FamilyName ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.FamilyName)[0]?.FamilyName : userDetails?.FamilyName;

  getUserDetails.BirthDate = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.BirthDate)[0]?.BirthDate ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.BirthDate)[0]?.BirthDate : userDetails?.BirthDate;

  getUserDetails.TelephoneNumber = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.TelephoneNumber)[0]?.TelephoneNumber ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.TelephoneNumber)[0]?.TelephoneNumber : userDetails?.TelephoneNumber;

  getUserDetails.Salutation = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Salutation)[0]?.Salutation ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Salutation)[0]?.Salutation : userDetails?.Salutation;

  getUserDetails.Suffix = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Suffix)[0]?.Suffix ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Suffix)[0]?.Suffix : userDetails?.Suffix;

  getUserDetails.PhoneNumber = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.PhoneNumber)[0]?.PhoneNumber ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.PhoneNumber)[0]?.PhoneNumber : userDetails?.PhoneNumber;

  getUserDetails.EmailLanguage = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.EmailLanguage)[0]?.EmailLanguage ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.EmailLanguage)[0]?.EmailLanguage : userDetails?.EmailLanguage;

  getUserDetails.Ext = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Ext)[0]?.Ext ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Ext)[0]?.Ext : userDetails?.Ext;

  getUserDetails.Email = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Email)[0]?.Email ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Email)[0]?.Email : userDetails?.Email;

  getUserDetails.Employer = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Employer)[0]?.Employer ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Employer)[0]?.Employer : userDetails?.Employer;

  getUserDetails.JobTitle = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.JobTitle)[0]?.JobTitle ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.JobTitle)[0]?.JobTitle : userDetails?.JobTitle;


  getUserDetails.FaxNumber = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.FaxNumber)[0]?.FaxNumber ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.FaxNumber)[0]?.FaxNumber : userDetails?.FaxNumber;



  getUserDetails.ResStreet1 = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResStreet1)[0]?.ResStreet1 ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResStreet1)[0]?.ResStreet1 : userDetails?.ResStreet1;



  getUserDetails.MailStreet1 = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailStreet1)[0]?.MailStreet1 ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailStreet1)[0]?.MailStreet1 : userDetails?.MailStreet1;



  getUserDetails.ResStreet2 = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResStreet2)[0]?.ResStreet2 ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResStreet2)[0]?.ResStreet2 : userDetails?.ResStreet2;



  getUserDetails.MailStreet2 = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailStreet2)[0]?.MailStreet2 ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailStreet2)[0]?.MailStreet2 : userDetails?.MailStreet2;



  getUserDetails.ResCity = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResCity)[0]?.ResCity ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResCity)[0]?.ResCity : userDetails?.ResCity;


  getUserDetails.MailCity = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailCity)[0]?.MailCity ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailCity)[0]?.MailCity : userDetails?.MailCity;

  getUserDetails.ResState = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResState)[0]?.ResState ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResState)[0]?.ResState : userDetails?.ResState;


  getUserDetails.MailState = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailState)[0]?.MailState ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailState)[0]?.MailState : userDetails?.MailState;



  getUserDetails.ResRegion = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResRegion)[0]?.ResRegion ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResRegion)[0]?.ResRegion : userDetails?.ResRegion;



  getUserDetails.MailRegion = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailRegion)[0]?.MailRegion ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailRegion)[0]?.MailRegion : userDetails?.MailRegion;



  getUserDetails.ResPinCode = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResPinCode)[0]?.ResPinCode ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResPinCode)[0]?.ResPinCode : userDetails?.ResPinCode;



  getUserDetails.MailPinCode = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailPinCode)[0]?.MailPinCode ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailPinCode)[0]?.MailPinCode : userDetails?.MailPinCode;



  getUserDetails.ResCountry = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResCountry)[0]?.ResCountry ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResCountry)[0]?.ResCountry : userDetails?.ResCountry;

  getUserDetails.MailCountry = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailCountry)[0]?.MailCountry ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailCountry)[0]?.MailCountry : userDetails?.MailCountry;

  getUserDetails.Username = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Username)[0]?.Username ? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Username)[0]?.Username : userDetails?.Username;

  const dispatch = useDispatch();
  // const enLanguage = enLanguage();
 */
  const { parentPageName } = props;
  const { cmsLandingConfig } = useSelector(({ config }) => config);
  const { filledData, userReferenceCode } = useSelector(({ createUserProfile }) => createUserProfile);
  // const userDetails = useSelector(({ userHome }) => userHome);
  const { userDetails,referenceCode } = useSelector(({ userHome }) => userHome);
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);

  const getUserDetails = {...userDetails};

  getUserDetails.Name = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Name)[0]?.Name? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Name)[0]?.Name : userDetails?.Name;

  getUserDetails.MiddleName = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MiddleName)[0]?.MiddleName? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MiddleName)[0]?.MiddleName : userDetails?.MiddleName;

  getUserDetails.FamilyName = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.FamilyName)[0]?.FamilyName? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.FamilyName)[0]?.FamilyName : userDetails?.FamilyName;

  getUserDetails.BirthDate = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.BirthDate)[0]?.BirthDate? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.BirthDate)[0]?.BirthDate : userDetails?.BirthDate;

  getUserDetails.TelephoneNumber = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.TelephoneNumber)[0]?.TelephoneNumber? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.TelephoneNumber)[0]?.TelephoneNumber : userDetails?.TelephoneNumber;

  getUserDetails.Salutation = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Salutation)[0]?.Salutation? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Salutation)[0]?.Salutation : userDetails?.Salutation;

 getUserDetails.Suffix = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Suffix)[0]?.Suffix? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Suffix)[0]?.Suffix : userDetails?.Suffix;

 getUserDetails.PhoneNumber = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.PhoneNumber)[0]?.PhoneNumber? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.PhoneNumber)[0]?.PhoneNumber : userDetails?.PhoneNumber;

 getUserDetails.EmailLanguage = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.EmailLanguage)[0]?.EmailLanguage? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.EmailLanguage)[0]?.EmailLanguage : userDetails?.EmailLanguage;

  getUserDetails.Ext = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Ext)[0]?.Ext? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Ext)[0]?.Ext : userDetails?.Ext;

  getUserDetails.Email = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Email)[0]?.Email? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Email)[0]?.Email : userDetails?.Email;

  getUserDetails.Employer = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Employer)[0]?.Employer? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Employer)[0]?.Employer : userDetails?.Employer;

  getUserDetails.JobTitle = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.JobTitle)[0]?.JobTitle? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.JobTitle)[0]?.JobTitle : userDetails?.JobTitle;


  getUserDetails.FaxNumber = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.FaxNumber)[0]?.FaxNumber? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.FaxNumber)[0]?.FaxNumber : userDetails?.FaxNumber;



  getUserDetails.ResStreet1 = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResStreet1)[0]?.ResStreet1? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResStreet1)[0]?.ResStreet1 : userDetails?.ResStreet1;



  getUserDetails.MailStreet1 = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailStreet1)[0]?.MailStreet1? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailStreet1)[0]?.MailStreet1 : userDetails?.MailStreet1;



  getUserDetails.ResStreet2 = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResStreet2)[0]?.ResStreet2? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResStreet2)[0]?.ResStreet2 : userDetails?.ResStreet2;



  getUserDetails.MailStreet2 = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailStreet2)[0]?.MailStreet2? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailStreet2)[0]?.MailStreet2 : userDetails?.MailStreet2;



  getUserDetails.ResCity = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResCity)[0]?.ResCity? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResCity)[0]?.ResCity : userDetails?.ResCity;


  getUserDetails.MailCity = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailCity)[0]?.MailCity? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailCity)[0]?.MailCity : userDetails?.MailCity;

  getUserDetails.ResState = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResState)[0]?.ResState? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResState)[0]?.ResState : userDetails?.ResState;


  getUserDetails.MailState = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailState)[0]?.MailState? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailState)[0]?.MailState : userDetails?.MailState;



  getUserDetails.ResRegion = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResRegion)[0]?.ResRegion? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResRegion)[0]?.ResRegion : userDetails?.ResRegion;



  getUserDetails.MailRegion = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailRegion)[0]?.MailRegion? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailRegion)[0]?.MailRegion : userDetails?.MailRegion;



  getUserDetails.ResPinCode = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResPinCode)[0]?.ResPinCode? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResPinCode)[0]?.ResPinCode : userDetails?.ResPinCode;



  getUserDetails.MailPinCode = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailPinCode)[0]?.MailPinCode? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailPinCode)[0]?.MailPinCode : userDetails?.MailPinCode;



  getUserDetails.ResCountry = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResCountry)[0]?.ResCountry? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.ResCountry)[0]?.ResCountry : userDetails?.ResCountry;

  getUserDetails.MailCountry = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailCountry)[0]?.MailCountry? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.MailCountry)[0]?.MailCountry : userDetails?.MailCountry;

  getUserDetails.Username = userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Username)[0]?.Username? userDetails?.HistoryData?.RegistrationData?.NewValue?.filter(i => i.Username)[0]?.Username : userDetails?.Username;


  // condition for the registration page
  const homeCondition = (parentPageName !== HomePageName);

  // fetch the data for the form
  const UserProfile = {
    refCode: homeCondition ? referenceCode?.ReferenceCode : getUserDetails?.ReferenceCode || "",
    email: homeCondition ? filledData?.[2]?.email : getUserDetails?.Email || "",
    firstName: homeCondition ? filledData?.[2]?.firstName : getUserDetails?.Name || "",
    middleName: homeCondition ? filledData?.[2]?.middleName : getUserDetails?.MiddleName || "",
    lastName: homeCondition ? filledData?.[2]?.lastName : getUserDetails?.FamilyName || "",
    birthDate: homeCondition
/* Conflict Resolution Candidate
      ? formatDate(filledData?.[2].date, "-")
 */
      ? `${filledData?.[2]?.year}-${filledData?.[2]?.month > 9 ? filledData?.[2]?.month : `0${filledData?.[2]?.month}`
      }-${filledData?.[2]?.day > 9 ? filledData?.[2]?.day : `0${filledData?.[2]?.day}`}`
      : getUserDetails?.BirthDate || "",
    telephoneNumber: homeCondition ? `${filledData?.[2]?.telephoneNumber}` : getUserDetails?.TelephoneNumber || "",
    salutation: homeCondition ? filledData?.[2]?.salutation : getUserDetails?.Salutation || "",
    suffix: homeCondition ? filledData?.[2]?.suffix : getUserDetails?.Suffix || "",
    mobilePhone: homeCondition ? filledData?.[2]?.mobilePhone : getUserDetails?.PhoneNumber || "",
    emailLanguagePreference: homeCondition
      ? `${filledData?.[2]?.emailLanguagePreference}`
      : getUserDetails?.EmailLanguage || "",
    faxNumber: homeCondition ? filledData?.[2]?.faxNumber : getUserDetails?.FaxNumber || "",
    // orgId:  homeCondition ?filledData?.[1]?.jurisdiction?.OrgID : getUserDetails?.OrgID || "",
    jurisdiction: t(t1, homeCondition
      ? filledData?.[1]?.jurisdiction?.OrgName
      : getUserDetails?.OrgDetails?.[0]?.OrgName) || "",
    ext: homeCondition ? filledData?.[2]?.ext : getUserDetails?.Ext || "",
    employer: homeCondition ? filledData?.[2]?.employer : getUserDetails?.Employer || "",
    jobTitle: homeCondition ? filledData?.[2]?.jobTitle : getUserDetails?.JobTitle || "",
    resStreet1: homeCondition ? filledData?.[3]?.primaryResidence?.street1 : getUserDetails?.ResStreet1 || "",
    resStreet2: homeCondition ? filledData?.[3]?.primaryResidence?.street2 : getUserDetails?.ResStreet2 || "",
    resCity: homeCondition ? filledData?.[3]?.primaryResidence?.city : getUserDetails?.ResCity || "",
    resState: t(t1, homeCondition ? `${filledData?.[3]?.primaryResidence?.stateOrProvince}` : getUserDetails?.ResState) || "",
    resRegion: homeCondition ? `${filledData?.[3]?.primaryResidence?.region}` : getUserDetails?.ResRegion || "",
    resCountry: t(t1, homeCondition ? `${filledData?.[3]?.primaryResidence?.country}` : getUserDetails?.ResCountry) || "",
    resPostalCode: homeCondition ? filledData?.[3]?.primaryResidence?.postalCode : getUserDetails?.ResPinCode || "",
    mailStreet1: homeCondition ? filledData?.[3]?.mailingAddress?.street1 : getUserDetails?.MailStreet1 || "",
    mailStreet2: homeCondition ? filledData?.[3]?.mailingAddress?.street2 : getUserDetails?.MailStreet2 || "",
    mailCity: homeCondition ? filledData?.[3]?.mailingAddress?.city : getUserDetails?.MailCity || "",
    mailState: t(t1, homeCondition ? `${filledData?.[3]?.mailingAddress?.stateOrProvince}` : getUserDetails?.MailState) || "",
    mailRegion: homeCondition ? `${filledData?.[3]?.mailingAddress?.region}` : getUserDetails?.MailRegion || "",
    mailCountry: t(t1, homeCondition ? `${filledData?.[3]?.mailingAddress?.country}` : getUserDetails?.MailCountry) || "",
    mailPostalCode: homeCondition ? filledData?.[3]?.mailingAddress?.postalCode : getUserDetails?.MailPinCode || "",
    createUserID: homeCondition ? filledData?.[5]?.createUserID : getUserDetails?.Username || "",
/* Conflict Resolution Candidate
    revisionDate: homeCondition ? formatDate(new Date()) : formatDate(getUserDetails?.RegistrationDate) || "",
 */
    revisionDate: getUserDetails?.RegistrationDate ?  formatDate(getUserDetails?.RegistrationDate): '',
  };

  // destructure the form data
  const {
    refCode,
    jurisdiction,
    birthDate,
    createUserID,
    department,
    email,
    emailLanguagePreference,
    ext,
    faxNumber,
    firstName,
    jobTitle,
    lastName,
    mailCity,
    mailCountry,
    mailPostalCode,
    mailState,
    mailRegion,
    mailStreet1,
    mailStreet2,
    middleName,
    mobilePhone,
    orgId,
    resCity,
    resCountry,
    resPostalCode,
    resState,
    resRegion,
    resStreet1,
    resStreet2,
    salutation,
    suffix,
    telephoneNumber,
    revisionDate,
  } = UserProfile;

  let employer = UserProfile?.employer;
  try {
    employer = typeof employer === "string" ?  JSON.parse(employer) : employer;
  } catch (e) {
    employer = [{ title: employer }];
  }
  // conditions for the visibility of the state or the region
  const resStateVisibility = resCountry === t(t1,COUNTRY_KEY_NAMES.CANADA) || resCountry === t(t1,COUNTRY_KEY_NAMES.UNITED_STATES);
  const mailStateVisibility = mailCountry === t(t1,COUNTRY_KEY_NAMES.CANADA) || mailCountry ===t(t1, COUNTRY_KEY_NAMES.UNITED_STATES);

  // checkbox
  const checked =
    resCity === mailCity &&
    resCountry === mailCountry &&
    resPostalCode === mailPostalCode &&
    resRegion === mailRegion &&
    resState === mailState &&
    resStreet1 === mailStreet1 &&
    resStreet2 === mailStreet2;

  return (
    <Box ref={ref} id="downloadContent">
      <Box sx={Style.mainBox}>
        <Box sx={Style.header}>
          <Typography sx={Style.headertext}>{t(t1, "FORM_SECOND_WCI_USER_REGISTRATION_FORM")}</Typography>
          {/* <Typography sx={Style.headertext}>1 of 4</Typography> */}
        </Box>
        <Box sx={Style.contentBox}>
          <Box sx={Style.heading}>
            <img src={SiteLogo} alt="logo" width="115" height="75" />
          </Box>
          <Box sx={Style.registrationBox}>
            <Typography sx={{ fontSize: "17px", fontWeight: 700 }}>
              {t(t1, "FORM_SECOND_USER_REGISTRATION_FORM")}
            </Typography>
            <Typography sx={{ fontSize: "13px", fontStyle: "italic", mt: 2 }}>
              {t(t1, "FORM_SECOND_USER_REGISTRATION_FORM_TXT")}
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Box>
              <Box sx={{ display: "flex" }}>
                <Box>
                  <Typography sx={{ fontWeight: 700, fontSize: "15px" }}>
                    {t(t1, "FORM_SECOND_WCI_USER_REFERENCE_CODE")} #:
                  </Typography>
                </Box>
                <Box sx={{ width: "60%", borderBottom: "1px solid black" }}>
                  <Typography sx={{ pl: 1 }}>{refCode}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ my: 2 }}>
              <Typography sx={{ fontSize: "13px" }}>{t(t1, "FORM_SECOND_MAIN_FORM_TXT")}</Typography>
            </Box>
          </Box>
          <Box sx={{ mx: 2, mt: 3 }}>
            <Box sx={{ ...Style.header, my: 1 }}>
              <Typography sx={{ width: "50%", fontSize: "15px", fontWeight: 700 }}>
                {t(t1, "FORM_SECOND_USER_INFORMATION")}
              </Typography>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_SALUTATION")}:</Typography>
                <Typography sx={Style.labelValue}>{salutation}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_FIRST_NAME")}:</Typography>
                <Typography sx={Style.labelValue}>{firstName}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_MIDDLE_NAME")}:</Typography>
                <Typography sx={Style.labelValue}>{middleName}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_LAST_NAME")}:</Typography>
                <Typography sx={Style.labelValue}>{lastName}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_SUFFIX")}:</Typography>
                <Typography sx={Style.labelValue}>{suffix}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_DATE_OF_BIRTH")}:</Typography>
                <Typography sx={Style.labelValue}>{birthDate}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_JOB_TITLE")}:</Typography>
                <Typography sx={Style.labelValue}>{jobTitle}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_ORGANIZATION")}:</Typography>
                <Typography sx={Style.labelValue}>{jurisdiction}</Typography>
              </Box>
              {/* <Typography sx={{ width: "50%", fontSize: "14px", fontWeight: 700 }}>Mailing Address</Typography> */}
            </Box>
            {/* <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_DEPARTMENT")}:</Typography>
                <Typography sx={Style.labelValue}>{department}</Typography>
              </Box>
            </Box> */}
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_TELEPHONE")} #:</Typography>
                <Typography sx={Style.labelValue}>{telephoneNumber}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_TELEPHONE_EXT")}:</Typography>
                <Typography sx={Style.labelValue}>{ext}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_MOBILE_TELEPHONE")} #:</Typography>
                <Typography sx={Style.labelValue}>{mobilePhone}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_FAX")} #:</Typography>
                <Typography sx={Style.labelValue}>{faxNumber}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_EMAIL_ADDRESS")}:</Typography>
                <Typography sx={Style.labelValue}>{email}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_EMAIL_LANGUAGE_PREFERENCE")}:</Typography>
                <Typography sx={Style.labelValue}>{emailLanguagePreference}</Typography>
              </Box>
            </Box>

            {
              employer && employer?.length > 0 && employer?.map((employerItem, index) => (
                  <Box sx={Style.header} key={`employer-${index}`}>
                    <Box sx={Style.formField}>
                      <Typography sx={Style.labelText}>{t(t1, index === 0 ? "FORM_SECOND_EMPLOYER" : "FORM_SECOND_ADDITIONAL_EMPLOYER")}:</Typography>
                      <Typography sx={Style.labelValue}>{employerItem?.title}</Typography>
                    </Box>
                  </Box>
                ))
            }

            <Box sx={{ ...Style.header, mt: 3, mb: 1 }}>
              <Typography sx={{ width: "50%", fontSize: "15px", fontWeight: 700 }}>
                {t(t1, "FORM_SECOND_PRIMARY_RESIDENCE")}
              </Typography>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_STREET_1")}:</Typography>
                <Typography sx={Style.labelValue}>{resStreet1}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_STREET_2")}:</Typography>
                <Typography sx={Style.labelValue}>{resStreet2}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_CITY")}:</Typography>
                <Typography sx={Style.labelValue}>{resCity}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>
                  {resStateVisibility
                    ? `${t(t1, "FORM_SECOND_STATE_OR_PROVINCE")}:`
                    : `${t(t1, "FORM_SECOND_REGION")}:`}
                </Typography>
                <Typography sx={Style.labelValue}>{resStateVisibility ? resState : resRegion}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_POSTAL_CODE")}:</Typography>
                <Typography sx={Style.labelValue}>{resPostalCode}</Typography>
              </Box>
            </Box>
            <Box sx={{ ...Style.header, mb: 8 }}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_COUNTRY")}:</Typography>
                <Typography sx={Style.labelValue}>{resCountry}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ ...Style.mainBox, paddingTop: "30px" }}>
        <Box sx={Style.header}>
          <Typography sx={Style.headertext}>{t(t1, "FORM_SECOND_WCI_USER_REGISTRATION_FORM")}</Typography>
          {/* <Typography sx={Style.headertext}>1 of 4</Typography> */}
        </Box>
        <Box sx={Style.contentBox}>
          <Box sx={{ mx: 2, mt: 3 }}>
            <Box sx={{ ...Style.header, mb: 1 }}>
              <Typography sx={{ width: "50%", fontSize: "15px", fontWeight: 700 }}>
                {t(t1, "FORM_SECOND_MAILING_ADDRESS")}
              </Typography>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>
                  <Checkbox size="small" checked={checked} sx={{ m: 0, p: 0, mr: 1 }} />
                  {t(t1, "FORM_SECOND_CHECKBOX_TXT")}
                </Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_STREET_1")}:</Typography>
                <Typography sx={Style.labelValue}>{mailStreet1}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_STREET_2")}:</Typography>
                <Typography sx={Style.labelValue}>{mailStreet2}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_CITY")}:</Typography>
                <Typography sx={Style.labelValue}>{mailCity}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>
                  {mailStateVisibility
                    ? `${t(t1, "FORM_SECOND_STATE_OR_PROVINCE")}:`
                    : `${t(t1, "FORM_SECOND_REGION")}:`}
                </Typography>
                <Typography sx={Style.labelValue}>{mailStateVisibility ? mailState : mailRegion}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_POSTAL_CODE")}:</Typography>
                <Typography sx={Style.labelValue}>{mailPostalCode}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_COUNTRY")}:</Typography>
                <Typography sx={Style.labelValue}>{mailCountry}</Typography>
              </Box>
            </Box>

            <Box sx={{ ...Style.header, mt: 3 }}>
              <Typography sx={{ width: "50%", fontSize: "15px", fontWeight: 700 }}>
                {t(t1, "FORM_SECOND_LOGIN_INFORMATION")}
              </Typography>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_JURISDICTION")}:</Typography>
                <Typography sx={Style.labelValue}>{jurisdiction}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_USER_ID")}:</Typography>
                <Typography sx={Style.labelValue}>{createUserID}</Typography>
              </Box>
            </Box>
            <Box sx={Style.header}>
              <Box sx={Style.formField}>
                <Typography sx={Style.labelText}>{t(t1, "FORM_SECOND_USER_REFERENCE_CODE")}:</Typography>
                <Typography sx={Style.labelValue}>{refCode}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 5 }}>
          <Typography sx={Style.headertext}>
            {t(t1, "FORM_SECOND_REVISION")}: {revisionDate}
          </Typography>
        </Box>
        {t(t1, "FORM_TERMS_AND_CONDITIONS_CONTENT")}
      </Box>
    </Box>
  );
});
