import { useEffectOnce } from "hooks/useEffectOnce";
import { useDispatch } from "react-redux";
import { getPagePermissions } from "store/permissionSlice";
import { getCurrentPageName } from "utils/constants";

function WithPermissions({ children, pageName, componentName, entityId }) {
  const dispatch = useDispatch();
  useEffectOnce(() => {
    dispatch(getPagePermissions(pageName || getCurrentPageName(), componentName, entityId));
  });
  return children;
}

export default WithPermissions;
