import React,{useEffect,useMemo} from 'react'
import { FormCard } from "components";
import Box from "@mui/material/Box";
import { MUITableGrid } from "components/MUITableGrid";
import {getCurrentPageName, t, } from "utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {useParams} from "react-router-dom";
import { getAllowanceHistory } from "../../../store/manageJurisdictionBudgetSlice";
import Style from "../ManageJurisdictionBudget.style";
import {budgetStatusHistory} from './ColumnSchema';
import { flattenJurisdictionBudgetStatusObject } from '../constant'

function JurisdictionAllowanceHistory({flp}) {
    const { t: t1 } = useTranslation(getCurrentPageName());
    const {
        allowanceHistory,
    } = useSelector(({ ManageJurisdictionBudget }) => ManageJurisdictionBudget);
    const dispatch = useDispatch();
    const { id:jurisdictionId } = useParams();
    useEffect(() => {
        dispatch(getAllowanceHistory(jurisdictionId));

    }, [dispatch,jurisdictionId]);
    const column=useMemo(()=>budgetStatusHistory(t1, flattenJurisdictionBudgetStatusObject(allowanceHistory)),[allowanceHistory, t1]);
    return (
        <FormCard
            title={t(t1, "JURISDICTION_ALLOWANCE_REQUEST_STATUS_HISTORY")}
            indicateRequired={false}
            customeStyle={{ paddingBottom: "40px" }}
        >
            <Box sx={{ ...Style.userRegistrationPage, paddingTop: "16px" }}>
                <MUITableGrid
                    fileName={t(t1, "JURISDICTION_ALLOWANCE_REQUEST_STATUS_HISTORY")}
                    rows={allowanceHistory || []}
                    columns={column}
                    cellClick={false}
                    count={5}
                    isExport
                    isSearch
                    isSettings
                    enablePrintView
                    fieldsMetaData={flp?.metaData}
                />
            </Box>
        </FormCard>
    )
}

export default JurisdictionAllowanceHistory