import { Loadable } from "components/Loadable";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { lazy } from "react";

const EventListing = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EventListing,
    }))
  )
);
const AddEventCreationAndManagement = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.AddEventCreationAndManagement,
    }))
  )
);

const EventViewAndManagement = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EventViewAndManagement,
    }))
  )
);

const EditEventAndManage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EditEventAndManage,
    }))
  )
);

const EventReserveSaleViewAndManagement = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EventReserveSaleViewAndManagement,
    }))
  )
);

export const eventCreationAndManagementRoutes = {
  path: "/event-creation-and-management",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_view_auction_event"}>
          <EventListing />
        </RoleBasedGuard>
      ),
    },
    {
      path: "create",
      element: (
        <RoleBasedGuard privId={"priv_lnk_create_edit_event"}>
          <AddEventCreationAndManagement />
        </RoleBasedGuard>
      ),
    },
    {
      // path: "view-auction-events/:id",
      path: "auction-events/:id",
      element: (
        <RoleBasedGuard privId={"priv_lnk_view_auction_event"}>
          <EventViewAndManagement />
        </RoleBasedGuard>
      ),
    },
    {
      path: "reserve-sale-events/:id",
      // path: "view-reserve-sale-events/:id",
      element: (
        <RoleBasedGuard privId={"priv_lnk_view_auction_event"}>
        <EventReserveSaleViewAndManagement/>
        </RoleBasedGuard>
      ),
    },
    {
      path: "edit/:id",
      element: (
        <RoleBasedGuard privId={"priv_lnk_create_edit_event"}>
          <EditEventAndManage />
        </RoleBasedGuard>
      ),
    },
  ],
};
