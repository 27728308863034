const Style = {
   
    formField: {
      width: 1,
      color: "common.textColor",
      ".MuiOutlinedInput-input": {
        py: 0.5,
        fontSize: "14px",
        "& .MuiTypography-root": {
          fontSize: "14px"
        }
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderRadius: "0",
        borderColor: "common.GrayColor",
      },
      "&.MuiOutlinedInput-root": {
        "&.MuiInputBase-root.Mui-focused": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#333",
            borderWidth: 1,
          },
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: '4px'
      },
      "& .Mui-error": {
        marginLeft: 0
      }
    },
    ".MuiList-root li span.MuiTypography-root":{
      fontWeight: 400,
    },
    inputStyle: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
  };
  
  export default Style;
  