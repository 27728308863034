const Style = {
    userRegistrationPage: {
        '.list-item-btn .MuiListItemButton-root':{
            cursor:'text',
        },
        '.list-item-btn .MuiListItemButton-root:hover': {
            backgroundColor:'transparent',
        }
    },
    listButton: {
        padding: '0 0 10px 0',
        "& .MuiListItemButton-root": {
            display: 'flex',
            alignItems: 'flex-start',
            "& .MuiBox-root" : {
                width: '50px',
                height: '50px',
                backgroundColor: '#fff',
                border: '1px solid #333',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '40px',
                position: 'relative',
                top: '10px'
            }
        },
        "& .MuiListItem-root": {
            position: 'relative'
        },
        "& .MuiListItem-root:first-child::before": {
            content: '""',
            width: '1px',
            height: '100%',
            position: 'absolute',
            left: '40px',
            top: '50px',
            backgroundColor: '#000'
        },

        "& .MuiListItem-root:nth-child(2)": {
            borderBottom: "1px solid",
            borderColor: "common.textLightGray",
            paddingBottom: '20px',
            marginBottom: '20px'
        },
        '& .MuiListItemText-primary': {
            fontWeight: 600,
            marginBottom: "8px",
        }
    },
};

export default Style;
