import { Popper } from "@mui/material";
import { useEffect, useState } from "react";

const CustomPopper = function (props) {
    const { className, anchorEl, style, ...rest } = props
    const bound = anchorEl.getBoundingClientRect();
    const [state, setState] = useState({
      width: null
    })
    const handleResize = () => {
      const boundNew = anchorEl.getBoundingClientRect();
      if (state.width !== boundNew.width) {
        setState(prev => ({...prev, width: boundNew.width}))
      }
    };
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return (<Popper {...props} 
        style={{ width: state.width ? state.width : bound.width }}
        placement="bottom-start" />);
  };

  export default CustomPopper;