import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SelectLanguage } from "components/SelectLanguage";
import { useSelector } from "react-redux";
import SelectJurisdiction from "./SelectJurisdiction/SelectJurisdiction";
import { UsersMenu } from "./UsersMenu";

export default function Category({ label, name, selectBox = false, loggedInJurisdiction = "" }) {
  const { header } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = header;
  return (
    <Typography
      className="category"
      variant="body2"
      component="div"
      sx={{
        ml: 5,
        color: "common.textGray",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        position: 'relative',
        left: '8px'
      }}
    >
      {label}:
      {String(selectBox) === "language" ? (
        PrivAttributes?.priv_select_language?.PrivRole !== "NA" && <SelectLanguage />
      ) : (
        PrivAttributes?.priv_select_Jurisdiction?.PrivRole !== "NA" && <SelectJurisdiction loggedInJurisdiction={loggedInJurisdiction} />
      )}

    </Typography>
  );
}
