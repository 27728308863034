import { createSlice } from "@reduxjs/toolkit";
import { setLoader } from "store/commonSlice";
import { APIS } from "utils/constants";
import fetcher, { fetcherGet, fetcherPost } from "utils/fetcher";
import { getJurisdictionUUID } from "utils/support";

const url = APIS.EVENT_API;

const initialState = {
  registryEntityFacilityReport: { isLoading: false, data: [] },
  linkForDownload: { isloading: false, data: [] },
  reportEPListingData: { isloading: false, data: [] },
  reportEADERListingData: { isloading: false, data: [] },
  entityLegalOpNameChangeReport: { isloading: false, data: [] },
  facilityGHGIdentifierChangeReport: { isloading: false, data: [] },
  facilityLegalOpNameChangeReport: { isloading: false, data: [] },
  reportQBRListingData: { isloading: false, data: [] },
  reportBidLimitationsReconciliationListingData: { isloading: false, data: [] },
  reportEntityUserRelationshipsReconciliationListingData: { isloading: false, data: [] },
  wiringInstructionsStatusReport: { isloading: false, data: [] },
  emissionsReportList: { isloading: false, data: [] }
};

const eventAllReportSlice = createSlice({
  name: "eventAllReportSlice",
  initialState,
  reducers: {
    setRegistryEntityFacilityReport: (state, action) => {
      state.registryEntityFacilityReport = action.payload;
    },
    setLinkForDownoad: (state, action) => {
      state.linkForDownload = action.payload;
    },
    setReportEPListingData: (state, action) => {
      state.reportEPListingData = action.payload;
    },
    setReportEADERListingData: (state, action) => {
      state.reportEADERListingData = action.payload;
    },
    setReportQBRListingData: (state, action) => {
      state.reportQBRListingData = action.payload;
    },
    setReportBidLimitationsReconciliationListingData: (state, action) => {
      state.reportBidLimitationsReconciliationListingData = action.payload;
    },
    setReportEntityUserRelationshipsReconciliationListingData: (state, action) => {
      state.reportEntityUserRelationshipsReconciliationListingData = action.payload;
    },
    setEntityLegalOpNameChangeReport: (state, action) => {
      state.entityLegalOpNameChangeReport = action.payload;
    },
    setFacilityGHGIdentifierChangeReport: (state, action) => {
      state.facilityGHGIdentifierChangeReport = action.payload;
    },
    setFacilityLegalOpNameChangeReport: (state, action) => {
      state.facilityLegalOpNameChangeReport = action.payload;
    },
    setWiringInstructionsStatusReport: (state, action) => {
      state.wiringInstructionsStatusReport = action.payload;
    },
    setEmissionsReportList: (state, action)=>{
      state.emissionsReportList = action.payload
    }
  },
});

export default eventAllReportSlice.reducer;
export const {
  setRegistryEntityFacilityReport,
  setLinkForDownoad,
  setReportEPListingData,
  setReportEADERListingData,
  setFacilityGHGIdentifierChangeReport,
  setFacilityLegalOpNameChangeReport,
  setReportQBRListingData,
  setReportBidLimitationsReconciliationListingData,
  setReportEntityUserRelationshipsReconciliationListingData,
  setEntityLegalOpNameChangeReport,
  setWiringInstructionsStatusReport,
  setEmissionsReportList
} = eventAllReportSlice.actions;

let jurisdictionID = getJurisdictionUUID();
// GET Registry Entity Facility Report

export function getRegistryEntityFacilityReport(jurisdictionId) {
  return (dispatch) => {
    dispatch(setRegistryEntityFacilityReport({ isLoading: true, data: [] }));
    fetcher(`${url}/${jurisdictionId}/${APIS.REGISTRYENTITY_REPORT}`, "GET", {}, false)
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setRegistryEntityFacilityReport({ isLoading: false, data: response?.data }));
        }
      })
      .catch((err) => {
        dispatch(setRegistryEntityFacilityReport({ isLoading: false, data: [] }));
      });
  };
}

export function generateReport(payload) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    jurisdictionID = getJurisdictionUUID();
    return fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-generate`, {
      ...payload,
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function reportListingEADER() {
  jurisdictionID = getJurisdictionUUID();
  const payload = {
    reportName: "EVENT_APPLICATIONS_DATA_EXPORT_REPORT",
  };
  return (dispatch) => {
    dispatch(setReportEADERListingData({ isLoading: true, data: [] }));
    fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-list`, { ...payload })
      .then((response) => {
        dispatch(setReportEADERListingData({ isLoading: false, data: response?.data }));
      })
      .catch((err) => {
        dispatch(setReportEADERListingData({ isLoading: false, data: [] }));
      });
  };
}

export function reportListingEventParticipant() {
  jurisdictionID = getJurisdictionUUID();
  const payload = {
    reportName: "EVENT_PARTICIPATION_REPORT",
  };
  return (dispatch) => {
    dispatch(setReportEPListingData({ isLoading: true, data: [] }));
    fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-list`, { ...payload })
      .then((response) => {
        dispatch(setReportEPListingData({ isLoading: false, data: response?.data }));
      })
      .catch((err) => {
        dispatch(setReportEPListingData({ isLoading: false, data: [] }));
      });
  };
}

export function reportQualifiedBiddersReconciliation() {
  jurisdictionID = getJurisdictionUUID();
  const payload = {
    reportName: "QUALIFIED_BIDDERS_RECONCILIATION_REPORT",
  };
  return (dispatch) => {
    dispatch(setReportQBRListingData({ isLoading: true, data: [] }));
    fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-list`, { ...payload })
      .then((response) => {
        dispatch(setReportQBRListingData({ isLoading: false, data: response?.data }));
      })
      .catch((err) => {
        dispatch(setReportQBRListingData({ isLoading: false, data: [] }));
      });
  };
}

export function getEmissionsReportList() {
  const jurisdictionId = localStorage.getItem("jurisdictionID");
  const payload = {
    reportName: "FACILITY_EMISSIONS_REPORT",
  };
  return (dispatch) => {
    dispatch(setEmissionsReportList({ isLoading: true, data: [] }));
    fetcherPost(`${APIS.REPORTS_API}/${jurisdictionId}/report-list`, { ...payload })
      .then((response) => {
        dispatch(setEmissionsReportList({ isLoading: false, data: response?.data }));
      })
      .catch((err) => {
        dispatch(setEmissionsReportList({ isLoading: false, data: [] }));
      });
  };
}

export function reportBidLimitationsReconciliationReport() {
  jurisdictionID = getJurisdictionUUID();
  const payload = {
    reportName: "BID_LIMITATIONS_RECONCILIATION_REPORT",
  };
  return (dispatch) => {
    dispatch(setReportBidLimitationsReconciliationListingData({ isLoading: true, data: [] }));
    fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-list`, { ...payload })
      .then((response) => {
        dispatch(setReportBidLimitationsReconciliationListingData({ isLoading: false, data: response?.data }));
      })
      .catch((err) => {
        dispatch(setReportBidLimitationsReconciliationListingData({ isLoading: false, data: [] }));
      });
  };
}

export function reportListingEntityUserRelationshipsReconciliation() {
  jurisdictionID = getJurisdictionUUID();
  const payload = {
    reportName: "ENTITY_USER_RELATIONSHIPS_RECONCILIATION_REPORT",
  };
  return (dispatch) => {
    dispatch(setReportEntityUserRelationshipsReconciliationListingData({ isLoading: true, data: [] }));
    fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-list`, { ...payload })
      .then((response) => {
         dispatch(setReportEntityUserRelationshipsReconciliationListingData({ isLoading: false, data: response?.data }));
      })
      .catch((err) => {
        dispatch(setReportEntityUserRelationshipsReconciliationListingData({ isLoading: false, data: [] }));
      });
  };
}

export const getLinkForDownload = (downloadLinkId) => (dispatch) => {
  jurisdictionID = getJurisdictionUUID();
  return fetcherGet(`${APIS.REPORTS_API}/${jurisdictionID}/report-download/${downloadLinkId}`).finally(() => {
    dispatch(setLoader(false));
  });
};

export function getEntityLegalOpNameChangeReport(jurisdictionId) {
  return (dispatch) => {
    dispatch(setEntityLegalOpNameChangeReport({ isLoading: true, data: [] }));
    fetcher(`${url}/${jurisdictionId}/${APIS.ENTITY_LEGAL_OP_NAME_REPORT}`, "GET", {}, false)
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setEntityLegalOpNameChangeReport({ isLoading: false, data: response?.data }));
        }
      })
      .catch((err) => {
        dispatch(setEntityLegalOpNameChangeReport({ isLoading: false, data: [] }));
      });
  };
}

export function getFacilityGHGIdentifierChangeReport(jurisdictionId) {
  return (dispatch) => {
    dispatch(setFacilityGHGIdentifierChangeReport({ isLoading: true, data: [] }));
    fetcher(`${url}/${jurisdictionId}/${APIS.FACILITY_GHG_REPORT}`, "GET", {}, false)
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setFacilityGHGIdentifierChangeReport({ isLoading: false, data: response?.data }));
        }
      })
      .catch((err) => {
        dispatch(setFacilityGHGIdentifierChangeReport({ isLoading: false, data: [] }));
      });
  };
}

export function getFacilityLegalOpNameChangeReport(jurisdictionId) {
  return (dispatch) => {
    dispatch(setFacilityLegalOpNameChangeReport({ isLoading: true, data: [] }));
    fetcher(`${url}/${jurisdictionId}/${APIS.FACILITY_NAME_CHANGE_REPORT}`, "GET", {}, false)
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setFacilityLegalOpNameChangeReport({ isLoading: false, data: response?.data }));
        }
      })
      .catch((err) => {
        dispatch(setFacilityLegalOpNameChangeReport({ isLoading: false, data: [] }));
      });
  };
}

export function getWiringInstructionsStatusReport(isFsa, PageName) {
  const pageDetails = {
    componentName: "viewReport",
    pageName: PageName,
  };
  return (dispatch) => {
    dispatch(setWiringInstructionsStatusReport({ isLoading: true, data: [] }));
    fetcher(`events/api/${APIS.WIRING_INST_STATUS_REPORT}?fsa=${isFsa}`, "GET", {}, false, pageDetails)
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setWiringInstructionsStatusReport({ isLoading: false, data: response?.data }));
        }
      })
      .catch((err) => {
        dispatch(setWiringInstructionsStatusReport({ isLoading: false, data: [] }));
      });
  };
}


