import { Box, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import {  t, getCurrentPageName } from "utils/constants";

export default function NotFound() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  return (
    <Box sx={{ width: "100%" }}>
      <Typography align="center" variant="h5" component="div" gutterBottom>
        {t(t1, "NO_DATA_FOUND_TXT")}
      </Typography>
    </Box>
  );
}