import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Button, Grid, OutlinedInput } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import { t, getCurrentPageName } from "utils/constants";

export default function CounterField(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, setValue } = useFormContext();
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;

  const {
    name,
    type = "text",
    label = "",
    defaultValue = '0',
    rules,
    placeholder,
    InputProps,
    privId = "",
    inputClass = "",
    isRememberMeDisabled = false,
    updateValue,
    minValue = 1,
    isRequiredMsg='',
    maxValue = '0',
    disabled = false,
  } = props;

  const [btnValue, setBtnValue] = useState(defaultValue || '0');
  const [errorMsg, setErrorMsg] = useState("");

  // setValue(name,defaultValue);
  const handleOnClick = (calType) => {
    const parsedBtnValue = parseInt(btnValue, 10)
    if (calType === "plus") {
      const parsedMaxValue = parseInt(maxValue, 10)
      const condition = parsedMaxValue ? parsedBtnValue + 1 <= parsedMaxValue : true;
      if (condition) {
        setBtnValue(String(parsedBtnValue + 1));
        updateValue(name, String(parsedBtnValue + 1));
      }
    } else if (calType === "minus") {
      if (parsedBtnValue > minValue) {
        setBtnValue(String(parsedBtnValue - 1));
        updateValue(name, String(parsedBtnValue - 1));
      }
    }
  };

  // useEffect(() => {
  //   setValue(name, btnValue);
  // }, [btnValue, name, setValue]);

  useEffect(() => {
    setTimeout(() => {
      setBtnValue(defaultValue);
    }, 500);
  }, [defaultValue]);

  if (PrivAttributes?.[privId]?.PrivRole === "NA") {
    return null;
  }

  const readOnly = PrivAttributes?.[privId]?.PrivRole === "RO" || disabled; // This will set read only field

  if (rules && label && rules.required === true) {
    rules.required = `* ${t(t1, isRequiredMsg)}`;
  }
  if (readOnly || isRememberMeDisabled) {
    return (
      <>
        <Button disabled>
          <RemoveCircleIcon color="white" onClick={() => handleOnClick("minus")} />
        </Button>
        <OutlinedInput disabled placeholder="0" value={btnValue} />
        <Button disabled>
          <AddCircleIcon color="white" onClick={() => handleOnClick("plus")} />
        </Button>
        <Typography className={readOnly || isRememberMeDisabled ? "disabled-cs-text" : ""} variant="p">
          {label}
        </Typography>
      </>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* Conflict Resolution Candidate
        <Button disabled={readOnly}>
          <RemoveCircleIcon color="white" onClick={() => handleOnClick("minus")} data-testid={`${name}_minus`} />
        </Button>
        <Controller
          name={name}
          type={type}
          defaultValue={defaultValue}
          rules={rules}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              disabled={readOnly}
              type={type}
              className={inputClass}
              id={name}
              variant="outlined"
              placeholder={placeholder}
              // onChangeCapture={(e)=>{
              //   setBtnValue(e.target.value);
              // }}
              onInput={(e) => {
                setBtnValue(e.target.value);
                updateValue(name, String(parseInt(e.target.value, 10)));
              }}
              InputProps={{
                ...InputProps,
                inputProps: { "data-testid": `${name}_text` },
              }}
              value={btnValue}
              error={Boolean(fieldState.error)}
              // helperText={fieldState?.error?.message || ""}
              helperText={fieldState?.error?.message ? setErrorMsg(fieldState?.error?.message) : setErrorMsg("")}
              {...field}
            />
          )}
        />
        <Button disabled={readOnly}>
          <AddCircleIcon disabled color="white" onClick={() => handleOnClick("plus")} data-testid={`${name}_plus`} />
        </Button>
        <Typography variant="p">{label}</Typography>
      </Grid>
      {errorMsg ? <Grid item xs={12}>
        <Box sx={{ color: 'rgb(202, 44, 28)', fontSize: '11px' }}>{errorMsg}</Box>
      </Grid> : null}
        */}
        <Button disabled={readOnly} onClick={() => handleOnClick("minus")}>
          <RemoveCircleIcon color="white"  data-testid={`${name}_minus`} />
        </Button>
        <Controller
          name={name}
          type={type}
          defaultValue={defaultValue}
          rules={rules}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              disabled={readOnly}
              type={type}
              className={inputClass}
              id={name}
              variant="outlined"
              placeholder={placeholder}
              // onChangeCapture={(e)=>{
              //   setBtnValue(e.target.value);
              // }}
              onChange={(e) => {
                if (parseInt(e.target.value, 10) > minValue) {
                  setBtnValue(e.target.value);
                  updateValue(name, e.target.value);
                  field.onChange(e);
                }
              }}
              onInput={(e) => {
                setBtnValue(e.target.value);
                updateValue(name, e.target.value);
              }}
              InputProps={{
                ...InputProps,
                inputProps: { "data-testid": `${name}_text` },
              }}
              value={btnValue}
              error={Boolean(fieldState.error)}
              // helperText={fieldState?.error?.message || ""}
              helperText={fieldState?.error?.message ? setErrorMsg(fieldState?.error?.message) : setErrorMsg("")}
              {...field}
            />
          )}
        />
        <Button disabled={readOnly} onClick={() => handleOnClick("plus")} >
          <AddCircleIcon disabled color="white" data-testid={`${name}_plus`} />
        </Button>
        <Typography variant="p">{label}</Typography>
      </Grid>
      {errorMsg ? <Grid item xs={12}>
        <Box sx={{ color: 'rgb(202, 44, 28)', fontSize: '11px' }}>{errorMsg}</Box>
      </Grid> : null}
    </Grid>
  );
}
