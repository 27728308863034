/* Conflict Resolution Candidate
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { ButtonOutline } from "components/ButtonOutline";
import { CustomizeButton } from "components/CustomizeButton";
import { useTranslation } from "react-i18next";
import { getCurrentPageName, t } from "utils/constants";
import { useTheme } from '@mui/material/styles';
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDialogMessage } from "store/commonSlice";
import { resetCreaeUserProfileState } from "store/createUserProfileSlice";
import Style from "./CustomeDialogMessage.style";

export default function CustomeDialogMessage({ open, title, message1 = "", message2 = "", warning = false, buttonMessage = false, continueBtn = "", borderColor = '', children, handleClose, handleContinue, disableContinue = false }) {

 */

import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { CustomizeButton } from "components/CustomizeButton";
import { useTranslation } from "react-i18next";
import { getCurrentPageName, t } from "utils/constants";
import Style from "./CustomeDialogMessage.style";

export default function CustomeDialogMessage({
  open = null,
  handleModalCancel,
  title,
  message1 = "",
  message2 = "",
  message3 = "",
  message4 = "",
  message3Style = {},
  warning = false,
  buttonMessage = false,
  continueBtn = "",
  cancelBtn = "",
  borderColor = "",
  children,
  handleClose,
  handleContinue = () => {},
  disableContinue = false,
  buttonDisplay = false,
  closeBtnDisplay = false,
  disabled,
  handleButtonClick,
  customStyling = false,
  multipleErrorKeys = [],
  maxWidth = "sm",
  customDialogStyle = {},
  juriActionButton = false,
  disableButtonJuri = false,
  disableEnforceFocus = false
}) {
  const { t: t1 } = useTranslation(getCurrentPageName());

  const styleSx = warning ? { ...Style?.ModelWarning } : { ...Style?.ModelError };

  const submitButton = () => {
    if (juriActionButton) {
      return (
        <Box sx={{ textAlign: "right", mb: 0 }} className="bottom-btn">
          <CustomizeButton
            variant="contained"
            label={t(t1, buttonDisplay)}
            name="priv_btn_Reset"
            disabled={disableButtonJuri}
            handleClick={handleButtonClick}
          />
        </Box>
      )
    }
    return (
      <Box sx={{ textAlign: "right", mb: 0 }} className="bottom-btn">
        <CustomizeButton
          variant="contained"
          label={t(t1, buttonDisplay)}
          name="priv_btn_Reset"
          disabled={!disabled}
          handleClick={handleButtonClick}
        />
      </Box>
    )
  }

  return (
    <Dialog
      sx={{
        ...Style?.ModelError,
        ...styleSx,
        ".messageModel": {
          margin: "20px",
          borderRadius: 8,
          //
          "& .MuiTypography-subtitle1": {
            fontSize: "24px",
            lineHeight: "24px",
            marginBottom: "15px",
          },
          "& .bottom-btn": {
            marginTop: "15px",
            display: "flex",
            justifyContent: "flex-end",
            "@media (max-width: 1100px)": {
              marginTop: "30px",
            },
            "& .MuiButton-root": {
              // width: "150px",
              height: "40px",
              marginLeft: "8px",
              boxShadow: "none",
              border: "1px solid #d7d7d7",
              lineHeight: 'normal',
              /* Conflict Resolution Candidate
                            outline: 0
                          },
                          
                          "& .submitBtn": {
                            fontWeight: 400,
                            '&:hover': {
                              fontWeight: 650,
                            }
                          }
                        },
                      }
               */
              outline: 0,
            },

            "& .submitBtn": {
              fontWeight: 400
            },
          },
        },
        ...customDialogStyle
      }}
      fullWidth
      maxWidth={maxWidth}
      // open={dialogMessage}
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleModalCancel()
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={customStyling}
      scroll={'body'}
      disableEnforceFocus={disableEnforceFocus}
    >
      <Box
        sx={{
          border: "2px solid",
          borderColor: borderColor || "#4d8da4",
          borderRadius: "8px",
        }}
      >
        <Box className="messageModel">
          <Typography variant="subtitle1" sx={{ fontWeight: 300 }}>
            {title}
          </Typography>
          {(closeBtnDisplay && buttonDisplay) ? (<IconButton
            aria-label="close"
            onClick={handleModalCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>) : null}
          {message1 ? (
            <Typography variant="body2" sx={{ mb: 1, width: "686px" }}>
              {message1}
            </Typography>
          ) : null}
          {message2 ? (
            <Typography variant="body2" sx={{ mb: 1, width: "686px" }}>
              {message2}
            </Typography>
          ) : null}
          {message3 ? (
            <Typography variant="body2" sx={{ mb: 1, width: "686px", ...message3Style }}>
              {message3}
            </Typography>
          ) : null}
          {message4 ? (
            <Typography variant="body2" sx={{ mb: 1, width: "686px", fontWeight: 600 }}>
              {message4}
              <span style={{ color: "red" }}> *</span>
            </Typography>
          ) : null}
          {multipleErrorKeys?.map((message) => {
            return (<Typography variant="body2" sx={{ mb: 1 }}>
              {message}
            </Typography>)
          })}
          {buttonMessage ? (
            <Typography variant="body2" sx={{ my: 4 }}>
              {buttonMessage || `${t(t1, "DIALOG_BUTTONS_INFORMATION_MESSAGE")}`}
            </Typography>
          ) : null}
          {children}

          {buttonDisplay ? (
            submitButton()
          ) : (
            <Box sx={{ textAlign: "right", mb: 0 }} className="bottom-btn">
              <CustomizeButton
                variant="outlined"
                csName="rc"
                label={cancelBtn ? t(t1, cancelBtn) : `${t(t1, "CANCEL")}`}
                name="priv_btn_Reset"
                handleClick={handleClose}
              />
              <CustomizeButton
                csName="submitBtn"
                customStyle={continueBtn ? { width: "auto", fontSize: "14px", mr: 0, marginLeft: "8px" } : { marginLeft: "8px" }}
                variant="contained"
                disableElevation
                type="submit"
                label={continueBtn ? t(t1, continueBtn) : `${t(t1, "CONTINUE")}`}
                name="priv_btn_Search"
                handleClick={handleContinue}
                disabled={disableContinue}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
