
import { createSlice } from "@reduxjs/toolkit";
import fetcher, { fetcherGet, fetcherPost } from "utils/fetcher";
import { APIS, FSI_COMPONENT_NAMES } from "utils/constants";
import { getJurisdictionUUID, getPermissionObj } from "utils/support";
import { setLoader } from "./commonSlice";

const initialState = {
  mfsFinancialServicesDetails: {
    isLoading: false,
    data: [],
  },
  selectedEntityEventDetail: {
    isLoading: false,
    data: [],
  },
  Country: [],
};


const mfsFinancialServicesSlice = createSlice({
  name: "mfsFinancialServices",
  initialState,
  reducers: {
    setmfsFinancialServicesDetails: (state, action) => {
      state.mfsFinancialServicesDetails = action.payload;
    },
    setCountry: (state, action) => {
      state.Country = action.payload;
    },
    // get
    setSelectedEntityEventDetail: (state, action) => {
      state.selectedEntityEventDetail = action.payload;
    },
    // end get
  },
});
 
export default mfsFinancialServicesSlice.reducer;
export const {
  setmfsFinancialServicesDetails,
  setCountry,
  // get
  setSelectedEntityEventDetail,
  // end get
} = mfsFinancialServicesSlice.actions;

 
export function addMFSForm(payload, jurisdictionId, PageName) {
  const pageDetails = { componentName: FSI_COMPONENT_NAMES.FSI_UPDATE_DETAILS, pageName: PageName };
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(`${APIS.EVENT_API}/${jurisdictionId}/fsi`, {
      ...payload,
    }, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// GET Country and state
export function getCountry() {
  return (dispatch) => {
    fetcherGet(APIS.ENTITY_COUNTRIES, {})
      .then((response) => {
        dispatch(setCountry(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

// GET
export function getMFSFinancialSericesDetails(jurisdictionId, PageName) {
  const pageDetails = { componentName: FSI_COMPONENT_NAMES.FSI_VIEW_DETAILS, pageName: PageName };
  return (dispatch) => {
    dispatch(setSelectedEntityEventDetail({ isLoading: true, data: {} }));
    fetcher(
      `${APIS.EVENT_API}/${jurisdictionId}/fsi?eventId=`,
      "GET",
      {},
      false,
      pageDetails
    )
      .then((response) => {
        dispatch(setSelectedEntityEventDetail({ isLoading: false, data: response }));
      })
      .catch((err) => {
        dispatch(setSelectedEntityEventDetail({ isLoading: false, data: {} }));
      });
  };
}
