import { useMemo } from "react";
import { useSelector } from "react-redux";
import { PERMISSION_NAMES } from "utils/constants";
import { getPermissionObj } from "utils/support";


export default function usePermissions(componentName) {
    const { fieldLevelPermissions } = useSelector(({ permissions }) => permissions);
    const permissions = useMemo(() => {
        const permissionObj = getPermissionObj(fieldLevelPermissions, componentName);
        const fields = Object.keys(permissionObj).reduce((acc, curr) => {
          if(permissionObj[curr]?.includes(PERMISSION_NAMES.WRITE)) {
            acc.write[curr] = true
          }
          if(permissionObj[curr]?.includes(PERMISSION_NAMES.READ)) {
            acc.readOnly[curr] = true
          }
          if(permissionObj[curr]?.includes(PERMISSION_NAMES.MANDATORY)) {
            acc.readOnly[curr] = true
          }
          acc.metaData = [...new Set([...acc.metaData, curr])]
          return acc;
        }, {
            readOnly: {},
            write: {},
            mandatory: {},
            metaData: []
        });
        return fields
      }, [fieldLevelPermissions, componentName])

  return permissions;
}
