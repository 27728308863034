import { Amplify, Auth } from 'aws-amplify';
import axios from 'axios';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Cookies } from 'react-cookie';

import awsmobile from 'aws-exports';
import { checkIsAuthenticated, getMenuAccess, removeAccessToken, storeAccessToken } from 'store/authSlice';
import {
  ERROR,
  getUniqueUsername,
  RESET_EXPIRE_LOGIN_MESSAGE_DISPLAYED_KEY,
  SET_EXPIRE_PASSWORD_IS_DISPLAYED_KEY,
  SET_SELECTED_DEFAULT_ACC,
  SUCCESS,
  userNotAuthorizedException,
  userNotFoundException,
} from 'utils/constants';
import { decrypt, encrypt } from 'utils/EncryptDecrypt';
import fetcher from 'utils/fetcher';

const cookies = new Cookies();
Auth.configure(awsmobile);

export const AuthContext = createContext();

// Wrap your app with <ProvideAuth />
export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

function useAuthProvider() {
  const [user, setUser] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    /* Conflict Resolution Candidate
    const handleInvalidToken = (e) => {
      if (e.key === "access_token" && e.oldValue && e.newValue === null) {
        signOut();
      }
      if (e.key === "access_token" && e.newValue && e.oldValue === null) {
        window.location.reload();
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      // window.removeEventListener('storage', handleInvalidToken)
    };
  }, []);
     */
    const handleInvalidToken = e => {
      if (e.key === 'access_token' && e.oldValue && e.newValue === null) {
        signOut();
      }
      if (e.key === 'access_token' && e.newValue && e.oldValue === null) {
        window.location.reload();
      }
    };
    window.addEventListener('storage', handleInvalidToken);
    return function cleanup() {
      // window.removeEventListener('storage', handleInvalidToken)
    };
  }, []);

  useEffect(() => {
    // NOTE: check for user or risk an infinite loop
    if (!user) {
      // On component mount
      // If a session cookie exists
      // Then use it to reset auth state
      Auth.currentSession()
        .then((session) => {
          const { idToken, accessToken } = session;
          // localStorage.setItem("jurisdiction", idToken?.payload?.["custom:orgId"]);
          localStorage.setItem('jurisdiction', idToken?.payload?.['custom:orgId']);
          // Define your user schema per your needs
          const user = {
            email: idToken.payload.email,
            username: idToken?.payload?.['cognito:username'],
            userId: idToken.payload.sub,
            accessToken: idToken.jwtToken,
            name: idToken.payload.name,
            mobile: idToken.payload.phone_number,
            OrgID: idToken?.payload?.['custom:orgId'],
          };
          setIsSignedIn(true);
          setUser(user);
          storeAccessToken(idToken.jwtToken);
        })
        .catch((err) => {
          // handle it
        });
    }
    // if(user === null) {
    //   console.log('user aws null',user);
    //   Auth.signOut({ global: true });
    // }
  }, [user]);

  const signIn = ({ email, password, remember, clientMetaData }) => {
    const firstTimeLoginCookies = cookies.get('FirstTimeLogin') ? cookies.get('FirstTimeLogin') : [];
    if (firstTimeLoginCookies.filter((firstTimeLoginCookie) => (decrypt(firstTimeLoginCookie) === email)).length > 0) {
      Auth.configure(awsmobile);
    } else
      Auth.configure({ ...awsmobile, authenticationFlowType: 'CUSTOM_AUTH' });

    const userDetail = Auth.signIn(email.trim()?.toLowerCase(), password.trim(), clientMetaData).then((cognitoUser) => {
      if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
        const { attributes } = cognitoUser;
        // localStorage.setItem("jurisdiction", attributes?.["custom:orgId"]);
        cookies.set('TempFirstTimeLogin', encrypt(`${cognitoUser.username}::${email}`), {
          path: '/',
          maxAge: 1000 * 60 * 5,
          sameSite: 'strict',
          secure: true,
        });
        return { challenge: true, cognitoUser };
      }
      // Set user data and access token to memory
      const {
        attributes,
        signInUserSession: { idToken },
      } = cognitoUser;

      // localStorage.setItem("jurisdiction", attributes?.["custom:orgId"]);
      localStorage.setItem('jurisdiction', attributes?.['custom:orgId']);

      // console.log(attributes, attributes?.["custom:orgId"], "Logged");
      const user = {
        email: attributes.email,
        username: cognitoUser.username,
        userId: attributes.sub,
        accessToken: idToken.jwtToken,
        name: attributes.name,
        mobile: attributes.phone_number,
        OrgID: attributes?.['custom:orgId'],
      };

      if (remember) {
        Auth.rememberDevice().then((result) => console.log(result));
      }
      setIsSignedIn(true);
      setUser(user);
      storeAccessToken(user.accessToken);

      return user;
      /* Conflict Resolution Candidate

      });

      return userDetail;
    };

    const sendCustomChallengeAnswer = (cognitoUser, challengeResponse) => {
      setLoader(true);
      return Auth.sendCustomChallengeAnswer(cognitoUser, challengeResponse)
        .then((cognitoUser) => {
          setLoader(false);
          // Set user data and access token to memory
          if (cognitoUser.challengeName === "CUSTOM_CHALLENGE") {
            return { challenge: true, cognitoUser };

       */

    });

    return userDetail;
  };

  const sendCustomChallengeAnswer = (cognitoUser, challengeResponse) => {
    setLoader(true);
    return Auth.sendCustomChallengeAnswer(cognitoUser, challengeResponse)
      .then(cognitoUser => {
        setLoader(false);
        // Set user data and access token to memory
        if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
          return { challenge: true, cognitoUser };
        }
        // const user = {
        //   email: cognitoUser.email,
        //   username: cognitoUser.username,
        //   userId: cognitoUser.userId,
        //   accessToken: cognitoUser.accessToken,
        //   name: cognitoUser.name,
        //   mobile: cognitoUser.mobile,
        // };
        const {
          attributes,
          signInUserSession: { idToken },
        } = cognitoUser;

        const user = {
          email: attributes.email,
          username: cognitoUser.username,
          userId: attributes.sub,
          accessToken: idToken.jwtToken,
          name: attributes.name,
          mobile: attributes.phone_number,
        };

        setIsSignedIn(true);
        setUser(user);
        storeAccessToken(user.accessToken);

        return user;
        /* Conflict Resolution Candidate
              })
              .catch((err) => {
                setLoader(false);
                // console.log(err)
                throw err;
              });
          };
         */

      })
      .catch(err => {
        setLoader(false);
        console.log(err);
        throw err;
      });
  };

  const signOut = () =>
    Auth.signOut().then(() => {
      setIsSignedIn(false);
      // remove key used to display expire remaining days in popup
      localStorage?.removeItem(RESET_EXPIRE_LOGIN_MESSAGE_DISPLAYED_KEY);
      // remove key used to display expire pwd for single time
      sessionStorage?.removeItem(SET_EXPIRE_PASSWORD_IS_DISPLAYED_KEY);
      sessionStorage?.removeItem(SET_SELECTED_DEFAULT_ACC);

      setUser(null);
      removeAccessToken();
      window.location.reload();
    });

  const signUp = (userProfile) => {
    /* Conflict Resolution Candidate
        if (userProfile?.mobilePhone?.toString().length > 0) {
     */
    let middleName = userProfile.middleName?.trim();
    if (middleName?.length === 0) {
      middleName = ' ';
    }
    if (userProfile.mobilePhone.toString().length > 0) {
      return Auth.signUp({
        // adding unique username for cognito validation
        username: getUniqueUsername(),
        password: userProfile.passphrase.trim(),
        attributes: {
          // this is actual entered by user in form
          'custom:p_username': userProfile.createUserID?.toLowerCase(),
          email: userProfile.email?.trim(),
          name: userProfile.firstName?.trim(),
          middle_name: middleName,
          family_name: userProfile.lastName?.trim(),
          birthdate: userProfile.birthDate,
          phone_number: userProfile.mobilePhone.includes('+')
            ? userProfile.mobilePhone.replace(/[^\d+]/g, '')
            : `+${userProfile.mobilePhone.replace(/[^\d+]/g, '')}`,
          gender: 'Male',
          'custom:salutation': userProfile.salutation,
          'custom:suffix': userProfile.suffix,
          // 'custom:telephone' : userProfile.telephoneNumber,
          'custom:mobilePhone': userProfile.telephoneNumber,
          'custom:emailLanguage': userProfile.emailLanguagePreference,
          'custom:employer': userProfile.employer?.trim(),
          'custom:department': userProfile.department?.trim(),
          'custom:faxNumber': userProfile.faxNumber,
          'custom:orgId': userProfile.orgId,
          'custom:ext': userProfile.ext,
          'custom:jobTitle': userProfile.jobTitle?.trim(),
          'custom:resStreet1': userProfile.resStreet1,
          'custom:resStreet2': userProfile.resStreet2,
          ...(userProfile.referenceCode ? { 'custom:referenceCode': userProfile.referenceCode } : {}),
          ...(userProfile.roleName ? { 'custom:roleName': userProfile.roleName } : {}),
          'custom:resCity': userProfile.resCity,
          'custom:resState': userProfile.resState,
          'custom:resRegion': userProfile.resRegion,
          'custom:resCountry': userProfile.resCountry,
          'custom:resPinCode': userProfile.resPostalCode,
          'custom:mailStreet1': userProfile.mailStreet1,
          'custom:mailStreet2': userProfile.mailStreet2,
          'custom:mailCity': userProfile.mailCity,
          'custom:mailState': userProfile.mailState,
          'custom:mailRegion': userProfile.mailRegion,
          'custom:mailCountry': userProfile.mailCountry,
          'custom:mailPinCode': userProfile.mailPostalCode,
          'custom:securityQ1': userProfile.securityQuestion1,
          'custom:securityQ2': userProfile.securityQuestion2,
          'custom:securityQ3': userProfile.securityQuestion3,
          'custom:securityA1': userProfile.answer1,
          'custom:securityA2': userProfile.answer2,
          'custom:securityA3': userProfile.answer3,
          'custom:acceptTAndC': userProfile.acceptTAndC,
          'custom:status': 'Pending',
        },
      });
    }
    // eslint-disable-next-line no-else-return
    else {
      const sinUpPayload = {
        // adding unique username for cognito validation
        username: getUniqueUsername(),
        password: userProfile.passphrase.trim(),
        attributes: {
          // this is actual entered by user in form
          'custom:p_username': userProfile.createUserID?.toLowerCase(),
          email: userProfile.email,
          name: userProfile.firstName,
          middle_name: middleName,
          family_name: userProfile.lastName,
          birthdate: userProfile.birthDate,
          gender: 'Male',
          'custom:salutation': userProfile.salutation,
          'custom:suffix': userProfile.suffix,
          // 'custom:telephone' : userProfile.telephoneNumber,
          'custom:mobilePhone': userProfile.telephoneNumber,
          'custom:emailLanguage': userProfile.emailLanguagePreference,
          'custom:employer': userProfile.employer,
          'custom:department': userProfile.department,
          'custom:faxNumber': userProfile.faxNumber,
          'custom:orgId': userProfile.orgId,
          'custom:ext': userProfile.ext,
          'custom:jobTitle': userProfile.jobTitle,
          'custom:resStreet1': userProfile.resStreet1,
          'custom:resStreet2': userProfile.resStreet2,
          'custom:resCity': userProfile.resCity,
          'custom:resState': userProfile.resState,
          'custom:resRegion': userProfile.resRegion,
          'custom:resCountry': userProfile.resCountry,
          'custom:resPinCode': userProfile.resPostalCode,
          ...(userProfile.referenceCode ? { 'custom:referenceCode': userProfile.referenceCode } : {}),
          ...(userProfile.roleName ? { 'custom:roleName': userProfile.roleName } : {}),
          'custom:mailStreet1': userProfile.mailStreet1,
          'custom:mailStreet2': userProfile.mailStreet2,
          'custom:mailCity': userProfile.mailCity,
          'custom:mailState': userProfile.mailState,
          'custom:mailRegion': userProfile.mailRegion,
          'custom:mailCountry': userProfile.mailCountry,
          'custom:mailPinCode': userProfile.mailPostalCode,
          'custom:securityQ1': userProfile.securityQuestion1,
          'custom:securityQ2': userProfile.securityQuestion2,
          'custom:securityQ3': userProfile.securityQuestion3,
          'custom:securityA1': userProfile.answer1,
          'custom:securityA2': userProfile.answer2,
          'custom:securityA3': userProfile.answer3,
          'custom:acceptTAndC': userProfile.acceptTAndC,
          'custom:status': 'Pending',
        },
      };
      return Auth.signUp(sinUpPayload);
    }
  };
  const forgotpassword = async (username) => {
    const response = await Auth.forgotPassword(username.trim()?.toLowerCase())
      .then((data) => {
        const jsonObject = {
          title: SUCCESS,
          message1: 'MSG_FORGOT_PASS_SUCCESS',
          error: false,
          statusCode: 200,
        };
        return jsonObject;
      })
      .catch((error) => {
        /* Conflict Resolution Candidate
                // console.log(error?.message,error?.code,error?.name, "error");
                const jsonObject = {
                  title: "ERROR",
                  error: true,
                  message1: "",
                };
                // for user not found
                if(error?.code === userNotFoundException){
                  jsonObject.message1 = "MSG_FORGOT_PASS_SUCCESS"
                  jsonObject.error = false
                  jsonObject.title = "SUCCESS"
                }else if(error?.message === "User is disabled."){
                  jsonObject.message1 = "MSG_ERR_USER_DISABLED"
                }
                else{
                  jsonObject.message1 = error?.message?.split(" ")?.pop()?.replace(/\.$/, "")
                }
        */
        let jsonObject;
        if (error?.name === userNotFoundException || error?.name === userNotAuthorizedException) {
          jsonObject = {
            title: SUCCESS,
            message1: 'MSG_FORGOT_PASS_SUCCESS',
            error: false,
            statusCode: 200,
          };
        } else {
          jsonObject = {
            title: ERROR,
            error: true,
            message1: error?.message,
            isDefaultDisplay: true,
          };
        }
        return jsonObject;
      });
    return response;
  };

  const forgotUserID = async (email) => {
    const response = fetcher(`public/forgotuserid`, 'POST', { email })
      .then((data) => {
        const jsonObject = {
          title: SUCCESS,
          data,
          /* Conflict Resolution Candidate
                    message1: data?.message,
           */
          message1: 'MSG_FORGOT_USERID',
        };
        return jsonObject;
      })
      .catch((error) => {
        const jsonObject = {
          title: ERROR,
          error: true,
          message1: error?.response?.data?.message,
        };
        return jsonObject;
      });
    return response;
  };

  const forgotpasswordSubmit = (username, code, NewPassword) =>
    Auth.forgotPasswordSubmit(username, code, NewPassword)
      .then((data) => data)
      .catch((error) => {
        // console.log(error);
        throw error;
      });

  const validateUniqueUser = async (params) => {
    if (params.username) {
      params = `username=${params.username.toLowerCase()}`;
    } else if (params.email) {
      params = `email=${params.email.toLowerCase()}`;
    } else {
      return true;
    }
    const response = await fetcher(`public/checkuserexist?${params}`, 'GET', {}, false);
    if (response.message === 'MSG_USER_FOUND') {
      return false;
    }
    return true;
  };
  const resetPassphrase = async (oldPassphrase, newPassphrase) => {
    await Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, oldPassphrase, newPassphrase))
      .then((data) =>
        data,
      )
      .catch((error) => {
        // console.log(error);
        throw error;
      });
  };

  const checkPasswordHistory = async (userId, newPassword, isPrePasswordChange) => {
    const payload = {
      UID: `U#${userId}`,
      newPassword,
      isPrePasswordChange,
    };
    await fetcher(`public/passwordhistory`, 'POST', payload, false)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };

  const memoedValue = useMemo(
    () => ({
      user,
      isSignedIn,
      signIn,
      signUp,
      signOut,
      forgotpassword,
      forgotUserID,
      forgotpasswordSubmit,
      validateUniqueUser,
      resetPassphrase,
      sendCustomChallengeAnswer,
      loader,
      checkPasswordHistory,
    }),
    [user, isSignedIn, loader],
  );

  // return {
  //   user,
  //   isSignedIn,
  //   signIn,
  //   signOut,
  //   signUp,
  //   forgotpassword,
  //   forgotpasswordSubmit,
  //   forgotUserID,
  //   validateUniqueUser,
  //   resetPassphrase,
  //   sendCustomChallengeAnswer,
  // };

  return memoedValue;
}
