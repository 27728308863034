import useAuth from "hooks/useAuth";
import { useJurisdictionUserRole } from "hooks/useUserRole";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrentPageName, JSA_ROLE, JurisdictionUser, t, WCIIncAdmin } from "utils/constants";

const useReports = () => {
  const { t: t1 } = useTranslation(getCurrentPageName());

  const { isSignedIn } = useAuth();
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isAdmin, isAuthority, isJurisdictionUser, isFSA, isFSAQA, isAAR, isPAR, isAVA, isPublicUser, isApplicant, isWCIincAdmin, isIndividualUser, isMarketMonitor, isAuctionAdministrator } = useJurisdictionUserRole();
  const jurisdictionId = localStorage.getItem("jurisdictionID");

  const routesReports = [

    {
      id: 1,


      privId: "priv_h6_USER_MANAGEMENT",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      routes: [
        {
          id: "simple-tabpanel-reports",
          customStyle: true,
          name: "ALL REPORTS",
          path: "/all-reports",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],


    },
    {
      id: 2,
      // name:  t(t1, "COMPLIANCE_REPORT"),
      name: "COMPLIANCE REPORTS",
      privId: "priv_btn_Home",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      routes: [
        {
          id: "obligation-management",
          name: "Obligation Management",
          // name: t(t1, "OBLIGATION_MANAGEMENT"),
          path: "/obligation-management",
          display: (isSignedIn && isJurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "entity-approved-obligation-report",
          name: "Entity Approved Obligation Report",
          // name: t(t1, "ENTITY_APPROVED_OBLIGATION_REPORT"),
          path: `/entity-approved-obligation-report`,
          display: (isSignedIn && (isAdmin || isAuthority)) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "obligation-period-management",
          name: "Obligation Period Management",
          // name: t(t1, "OBLIGATION_PERIOD_MANAGEMENT"),
          path: "/obligation-period-management",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "compliance-status-details",
          name: "Compliance Status Details",
          // name: t(t1, "COMPLIANCE_STATUS_DETAILS"),
          path: "/compliance-status-details",
          display: !!isAuthority,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "eligible-instruments-for-retirement",
          name: "Eligible Instruments for Retirement",
          // name: t(t1, "ELIGIBLE_INSTRUMENTS_FOR_RETIREMENT"),
          path: "/eligible-instruments-for-retirement",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "more-options-in-complaiance-reports",
          name: "More Options in Compliance Reports",
          // name: t(t1, "MORE_OPTIONS_IN_COMPLIANCE_REPORTS"),
          path: "/more-options-in-complaiance-reports",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: true,
          privId: "priv_btn_Home",
          subRoutes: [

            {
              id: "compliance-obligations-data-export",
              name: "Compliance Obligations (Data Export)",
              // name: t(t1, "COMPLIANCE_OBLIGATIONS_DATA_EXPORT"),
              path: "/compliance-obligations-data-export",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "entity-obligation-period-holdings-report",
              name: "Entity Obligation Period Holdings Report",
              // name: t(t1, "ENTITY_OBLIGATION_PERIOD_HOLDINGS_REPORT"),
              path: "/entity-obligation-period-holdings-report",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "total-quantity-retired-by-obligation-period",
              name: "Total Quantity Retired by Obligation Period",
              // name: t(t1, "TOTAL_QUANTITY_RETIRED_BY_OBLIGATION_PERIOD"),
              path: "/total-quantity-retired-by-obligation-period",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "summary-of-instruments-retired-by-obligation-period-vintage-and-type",
              name: "Summary of Instruments Retired by Obligation Period, Vintage, and Type",
              // name: t(t1, "SUMMARY_OF_INSTRUMENTS_RETIRED_BY_OBLIGATION_PERIOD_VINTAGE_AND_TYPE"),
              path: "/summary-of-instruments-retired-by-obligation-period-vintage-and-type",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
          ],
        },
      ],

    },
    {
      id: 11,
      name: "EVENT REPORT",
      // name: t(t1, "EVENT_REPORTS"),
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "event-application-reports",
          name: "Event Applications",
          // name: t(t1, "EVENT_APPLICATIONS"),
          path: "/ear-reports",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "event-applications-data-export-report",
          name: "Event Applications (Data Export) Report",
          // name: t(t1, "EVENT_APPLICATIONS_DATA_EXPORT_REPORT"),
          path: "/event-application-data-export-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "event-participant-report",
          name: "Event Participant Report",
          // name: t(t1, "EVENT_PARTICIPANT_REPORT"),
          path: "/event-participant-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "wiring-instructions-status-report",
          name: "Wiring Instructions Status Report",
          // name: t(t1, "Wiring Instructions Status Report"),
          path: "/wiring-instructions-status-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser && userDetails?.OrgDetails[0]?.OrgName === "California") || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "more-options-in-vent-reports",
          name: "More Options In Event Report",
          // name: t(t1, "MORE_OPTIONS_IN_EVENT_REPORT"),
          path: "/more-options-in-vent-reports",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: true,
          privId: "priv_btn_Home",
          subRoutes: [
            {
              id: "auction-and-reserve-sale-information",
              name: "Auction and Reserve Sale Information",
              // name:  t(t1, "AUCTION_AND_RESERVE_SALE_INFORMATION"),
              path: "/eauction-and-reserve-sale-information",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "auction-holding-limit-for-auction-participants",
              name: "Auction: Holding Limit for Auction Participants",
              // name: t(t1, "AUCTION_HOLDING_LIMIT_FOR_AUCTION_PARTICIPANTS"),
              path: "/auction-holding-limit-for-auction-participants",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "auction-current-vintage-purchase-limit",
              name: "Auction: Current Vintage Purchase Limit for Auction Participants",
              // name: t(t1, "AUCTION_CURRENT_VINTAGE_PURCHASE_LIMIT_FOR_AUCTION_PARTICIPANTS"),
              path: "/auction-current-vintage-purchase-limit",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "auction-future-vintage-purchase-limit",
              name: "Auction: Future Vintage Purchase Limit for Auction Participants",
              // name: t(t1, "AUCTION_FUTURE_VINTAGE_PURCHASE_LIMIT_FOR_AUCTION_PARTICIPANTS"),
              path: "/auction-future-vintage-purchase-limit",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "auction-purchase-limit",
              name: "Auction Purchase Limit/Holding Limit Combined Report for Auction Participants",
              // name: t(t1, "AUCTION_PURCHASE_LIMIT_HOLDING_LIMIT_COMBINED_REPORT_FOR_AUCTION_PARTICIPANTS"),
              path: "/auction-purchase-limit",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "EMISSIONS",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "facility-emissions-report",
          name: "Facility Emissions Report",
          // name: t(t1, "FACILITY_EMISSIONS_REPORT"),
          path: "/facility-emissions-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },
    {
      id: 4,
      name: "Batch True-Up Quantity",
      // name: t(t1, "BATCH_TRUE_UP_QUANTITY"),
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "batch-true-up-quantity",
          name: "Batch True-Up Quantity",
          // name: t(t1, "BATCH_TRUE_-_UP_QUANTITY"),
          path: "/batch-true-up-quantity",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "true-up-quantitys",
          name: "True-Up Quantity",
          // name: t(t1, "TRUE_-_UP_QUANTITY"),
          path: "/true-up-quantitys",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },
    {
      id: 5,
      name: "User Audit Report",
      // name: t(t1, "USERS_AUDIT_REPORT"),
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "user-audit-report",
          name: "User Audit Reports",
          // name: t(t1, "USER_AUDIT_REPORT"),
          path: "/user-audit-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "user-audit-log-report",
          name: "User Audit Log Report",
          // name: t(t1, "USER_AUDIT_LOG_REPORT"),
          path: "/user-audit-log-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "user-changes-report",
          name: "User Changes Report",
          // name: t(t1, "USER_CHANGES_REPORT"),
          path: "/user-changes-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "user-change-comment-report",
          name: "User Change Comment Report",
          // name: t(t1, "USER_CHANGE_COMMENT_REPORT"),
          path: "/user-change-comment-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },
    {
      id: 6,
      name: "FACILITY AUDIT REPORTS",
      // name: t(t1, "FACILITY_AUDIT_REPORT"),
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "facility-ghg-identifier-change",
          name: "Facility GHG Identifier Change",
          // name: t(t1, "FACILITY_GHG_IDENTIFIER_CHANGE"),
          path: "/facility_ghg_identifier_change",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "facility-name-operating-name-change",
          name: "Facility Name/Operating Name Change",
          // name: t(t1, "FACILITY_NAME_OPERATING_NAME_CHANGE"),
          path: "/facility_name_operating_name_change",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },
    {
      id: 7,
      name: "Entity Audit Reports",
      // name: t(t1,"ENTITY_AUDIT_REPORT"),
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "entity-audit",
          // name: t(t1,"ENTITY_AUDIT"),
          name: "Entity Audit",
          path: "/entity-audit",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "entity-change-comment-report",
          // name: t(t1,"ENTITY_CHANGE_COMMENT_REPORT"),
          name: "Entity Change Comment Report",
          path: "/entity-change-comment-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "entity-legal-name-operating-name-change",
          // name: t(t1,"ENTITY_LEGAL_NAME_OPERATING_NAME_CHANGE"),
          name: "Entity Legal Name/Operating Name Change",
          path: "/entity_legal_name_operating_name_change",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "more-options-in-entity-audit-reports",
          // name: t(t1,"MORE_OPTIONS_IN_ENTITY_AUDIT_REPORTS"),
          name: "More Options in Entity Audit Reports",
          path: "/more-options-in-entity-audit-reports",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: true,
          privId: "priv_btn_Home",
          subRoutes: [
            {
              id: "entity-type-history",
              // name: t(t1,"ENTITY_TYPE_HISTORY"),
              name: "Entity Type History",
              path: "/entity-type-history",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "entity-name-history",
              // name: t(t1,"ENTITY_NAME_HISTORY"),
              name: "Entity Name History",
              path: "/entity-name-history",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "suspended-representative-history",
              // name: t(t1,"SUSPENDED_REPRESENTATIVE_HISTORY"),
              name: "Suspended Representative History",
              path: "/suspended-representative-history",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
          ],
        },
      ],
    },
    {
      id: 8,
      // name: t(t1,"ENTITY_INFORMATIONS"),
      name: "ENTITY INFORMATION",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "registered-entity-facility",
          // name: t(t1,"REGISTERED_ENTITY_-_FACILITY"),
          name: "Registered Entity - Facility",
          path: "/registered_entity_facility",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "entity-representatives",
          // name: t(t1,"ENTITY_REPRESENTATIVES"),
          name: "Entity Representatives",
          path: "/entity-representatives",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "cross-jurisdiction-entity-representatives",
          // name: t(t1,"CROSS_JURISDICTION_ENTITY_REPRESENTATIVES"),
          name: "Cross Jurisdiction Entity Representatives",
          path: "/cross-jurisdiction-entity-representatives",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "cross-jurisdiction-users",
          // name: t(t1,"CROSS_JURISDICTION_USERS"),
          name: "Cross Jurisdiction Users",
          path: "/cross-jurisdiction-users",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "more-options-in-entity-information",
          // name: t(t1,"MORE_OPTIONS_IN_ENTITY_INFORMATION"),
          name: "More Options in Entity Information",
          path: "/more-options-in-entity-information",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: true,
          privId: "priv_btn_Home",
          subRoutes: [
            {
              id: "holding-limit-and-limited-exemption",
              // name: t(t1,"HOLDING_LIMIT_AND_LIMITED_EXEMPTION"),
              name: "Holding Limit and Limited Exemption",
              path: "/reports/holding-limit-limited-exemption",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "purchase-limits",
              // name: t(t1,"PURCHASE_LIMITS"),
              name: "Purchase Limits",
              path: "/reports/purchase-limits",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
          ],
        },
      ],
    },

    {
      id: 12,
      // name:  t(t1,"EVENT_RECONCILIATION_REPORT"),
      name: "EVENT RECONCILIATION REPORTS",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "entity-user-relationships-reconciliation-report",
          // name: t(t1,"ENTITY_USER_RELATIONSHIPS_RECONCILIATION_REPORT"),
          name: "Entity User Relationships Reconciliation Report",
          path: "/entity-user-relationships-reconciliation-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "qualified-bidders-reconciliation-report",
          // name: t(t1,"QUALIFIED_BIDDERS_RECONCILIATION_REPORT"),
          name: "Qualified Bidders Reconciliation Report",
          path: "/qualified-bidders-reconciliation-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "bid-limitations-reconciliation-report",
          // name: t(t1,"BID_LIMITATIONS_RECONCILIATION_REPORT"),
          name: "Bid Limitations Reconciliation Report",
          path: "/bid-limitations-reconciliation-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },

    {
      id: 10,
      // name:  t(t1,"TRANSFER_REPORTS"),
      name: "TRANSFER REPORTS",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "transfer-history",
          // name: t(t1,"TRANSFER_HISTORY"),
          name: "Transfer History",
          path: "/transfer-history",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "jurisdiction-batch-transfer",
          // name: t(t1,"JURISDICTION_BATCH_TRANSFER"),
          name: "Jurisdiction Batch Transfer",
          path: "/reports/jurisdiction-batch-transfer",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "administrative-transfers",
          // name: t(t1,"ADMINISTRATIVE_TRANSFERS"),
          name: "Administrative Transfers",
          path: "/administrative-transfers",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "issuance",
          // name: t(t1,"ISSUANCE"),
          name: "ISSUANCE",
          path: "/issuance",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },

    {
      id: 12,
      // name: t(t1,"NOTIFICATION"),
      name: "NOTIFICATIONS",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "notifications",
          // name: t(t1,"NOTIFICATIONS"),
          name: "NOTIFICATIONS",
          path: "/enotifications",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },

    // FSA Report 
    {
      id: 15,
      name: "",
      privId: "priv_btn_Home",
      display: isSignedIn && (isFSA || isFSAQA || false),
      isSubmenu: false,

      routes: [
        {
          id: "simple-fsa-reports",
          name: t(t1, "ALL_REPORT"),
          path: "/fsa-all-reports",
          display: isSignedIn && (isFSA || isFSAQA || false),
          isSubmenu: false,
          customStyle: true,
          privId: "priv_btn_Home",
        },

      ],

    },

    {
      id: 16,
      name: t(t1, "FSA_REPORTS"),
      display: isSignedIn && (isFSA || isFSAQA || false),
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "fsa-report",
          name: t(t1, "EVENT_APPLICATIONS_FSA_REPORT"),
          path: "/fsa-report",
          display: isSignedIn && (isFSA || isFSAQA || false),
          isSubmenu: false,
          privId: "priv_btn_Home",
        }
      ],
    },

    {
      id: 21,
      name: t(t1, "EVENT_REPORTS"),
      display: isSignedIn && (isFSA || isFSAQA || false),
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "fsa-wiring-instructions-status-report",
          name: t(t1, "WIRING_INSTRUCTIONS_STATUS_REPORT"),
          path: "/fsa-wiring-instructions-status-report",
          display: isSignedIn && (isFSA || isFSAQA || false),
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "consignment-wiring-instructions-report",
          name: t(t1, "CONSIGNMENT_WIRING_INSTRUCTIONS_REPORT"),
          path: "/consignment-wiring-instructions-report",
          display: isSignedIn && (isFSA || isFSAQA || false),
          isSubmenu: false,
          privId: "priv_btn_Home",
        }
      ],
    },

    // Market Monitor
    {
      id: 22,
      name: "",
      privId: "priv_btn_Home",
      display: isSignedIn && (isMarketMonitor),
      isSubmenu: false,

      routes: [
        {
          id: "simple-fsa-reports",
          name: t(t1, "ALL_REPORT"),
          path: "/market-monitor-all-reports",
          display: isSignedIn && (isMarketMonitor),
          isSubmenu: false,
          customStyle: true,
          privId: "priv_btn_Home",
        },
      ],
    },

    // auction administrator


    {
      id: 24,
      name: "",
      privId: "priv_btn_Home",
      display: isSignedIn && (isAuctionAdministrator),
      isSubmenu: false,

      routes: [
        {
          id: "simple-fsa-reports",
          name: t(t1, "ALL_REPORT"),
          path: "/all-report-auction-administrator",
          display: isSignedIn && (isAuctionAdministrator),
          isSubmenu: false,
          customStyle: true,
          privId: "priv_btn_Home",
        },
      ],
    },

    {
      id: 25,
      name: t(t1, "EVENT_RECONCILIATION_REPORT"),
      display: isSignedIn && (isAuctionAdministrator),
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "entity-user-relationships-reconciliation-report",
          name: t(t1, "ENTITY_USER_RELATIONSHIPS_RECONCILIATION_REPORT"),
          path: "/entity-user-relationships-reconciliation-report",
          display: isSignedIn && (isAuctionAdministrator),
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "qualified-bidders-reconciliation-report",
          name: t(t1, "QUALIFIED_BIDDERS_RECONCILIATION_REPORT"),
          path: "/qualified-bidders-reconciliation-report",
          display: isSignedIn && (isAuctionAdministrator),
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "bid-limitations-reconciliation-report",
          name: t(t1, "BID_LIMITATIONS_RECONCILIATION_REPORT"),
          path: "/bid-limitations-reconciliation-report",
          display: isSignedIn && (isAuctionAdministrator),
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },


    {
      id: 23,
      name: t(t1, "EVENT_REPORTS"),
      display: isSignedIn && (isMarketMonitor),
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "event-applications-data-export-report",
          name: t(t1, "EVENT_APPLICATIONS_DATA_EXPORT_REPORT"),
          path: "/event-application-data-export-report",
          display: isSignedIn && (isMarketMonitor),
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "event-participant-report",
          name: t(t1, "EVENT_PARTICIPANT_REPORT"),
          path: "/event-participant-report",
          display: isSignedIn && (isMarketMonitor),
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },

    // INDIVIDUAL USER REPORTS 

    {
      id: 17,
      name: "",
      privId: "priv_btn_Home",
      display: isSignedIn && (isAAR || isPAR || isAVA || isPublicUser || isApplicant || isIndividualUser || false),
      isSubmenu: false,

      routes: [
        {
          id: "simple-iu-reports",
          name: t(t1, "ALL_REPORT"),
          path: "/iu-all-reports",
          display: isSignedIn && (isAAR || isPAR || isAVA || isPublicUser || isApplicant || isIndividualUser || false),
          isSubmenu: false,
          privId: "priv_btn_Home",
        },

      ],

    },
    {
      id: 2,
      // name:  t(t1, "COMPLIANCE_REPORT"),
      name: "COMPLIANCE REPORTS",
      privId: "priv_btn_Home",
      display: (isAAR || isPAR || isIndividualUser || isAVA) || false,
      routes: [
        {
          id: "obligation-management",
          name: "Compliance Obligations",
          // name: t(t1, "OBLIGATION_MANAGEMENT"),
          path: "/reports/compliance-obligations",
          display: (isAAR || isPAR || isIndividualUser || isAVA) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "compliance-obligations-data-export",
          name: "Compliance Obligations (Data Export)",
          // name: t(t1, "COMPLIANCE_OBLIGATIONS_DATA_EXPORT"),
          path: "/reports/compliance-obligations-data-export",
          display: (isAAR || isPAR || isIndividualUser || isAVA) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "eligible-instruments-for-retirement",
          name: "Eligible Instruments for Retirement",
          // name: t(t1, "ELIGIBLE_INSTRUMENTS_FOR_RETIREMENT"),
          path: "/reports/eligible-instruments-for-retirement",
          display: (isAAR || isPAR || isIndividualUser || isAVA) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "emissions",
          name: "Emissions",
          // name: t(t1, "EMISSIONS"),
          path: `/reports/emissions`,
          display: (isAAR || isPAR || isIndividualUser || isAVA) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "notifications",
          name: "Notifications",
          // name: t(t1, "OBLIGATION_PERIOD_MANAGEMENT"),
          path: "/reports/notifications",
          display: (isAAR || isPAR || isIndividualUser || isAVA) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "true-up-quantity",
          name: "True-Up Quantity",
          // name: t(t1, "COMPLIANCE_STATUS_DETAILS"),
          path: "/reports/true-ups",
          display: (isAAR || isPAR || isIndividualUser || isAVA) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },
    {
      id: 18,
      name: t(t1, "TRANSFER_REPORTS_LABEL"),
      display: (isAAR || isPAR || isAVA || isPublicUser || isApplicant || isIndividualUser || false),
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "transfer-event-audit",
          name: t(t1, "TRANSFER_EVENT_AUDIT"),
          path: "/reports/transfer-event-audits",
          display: (isAAR || isPAR || isAVA || isPublicUser || isApplicant || isIndividualUser || false),
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "transfer-history",
          name: t(t1, "TRANSFER_HISTORY"),
          path: "/transfer-history",
          display: (isAAR || isPAR || isAVA || isPublicUser || isApplicant || isIndividualUser || false),
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "administrative-transfers",
          name: t(t1, "ADMINISTRATIVE_TRANSFERS"),
          path: "/administrative-transfers",
          display: isJurisdictionUser,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "issuance",
          name: t(t1, "ISSUANCE"),
          path: "/issuance",
          display: (isAAR || isPAR || isAVA || isPublicUser || isApplicant || isIndividualUser || false),
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },

    {
      id: 9,
      // name: t(t1,"ACCOUNTS_BALANCE"),
      name: "ACCOUNT BALANCE REPORTS",
      display: (isJurisdictionUser || isAAR || isPAR || isAVA || isPublicUser || isApplicant || isIndividualUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "entity-account-balance-reports",
          // name: t(t1,"ENTITY_ACCOUNT_BALANCE_REPORTS"),
          name: "Entity Account Balance",
          path: "/reports/entity-account-balance",
          display: (isJurisdictionUser || isAAR || isPAR || isAVA || isPublicUser || isApplicant || isIndividualUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "jurisdiction-account-balance",
          // name: t(t1,"JURISDICTION_ACCOUNT_BALANCE"),
          name: "Jurisdiction Account Balance",
          path: "/ejurisdiction-account-balance",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },

    {
      id: 13,        
      // name: t(t1, "REPRESENTATIVE_REPORTS"),
      name:"REPRESENTATIVE REPORTS",
      display: (isAAR || isPAR || isIndividualUser) || false,
      isSubmenu: true,
      privId: "priv_btn_Home",    
      routes: [
        {
          id: "event-applications-ar-reports",
          name: "Event Applications Report",
          // name: t(t1, "EAREPORT"),
          path: "/event-applications-ar",
          display: true,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],    
    },


    {
      id: 19,
      name: t(t1, "CMS_REPORTS"),
      display: (isSignedIn && userDetails?.RoleDetail?.Role.filter((item) => [JSA_ROLE, WCIIncAdmin].includes(item?.name))?.length > 0 || false),
      isSubmenu: true,
      privId: "priv_btn_Home",
      routes: [
        {
          id: "cms-compare-reports-route",
          name: t(t1, "COMPARE_REPORT"),
          path: "/cms-compare-reports",
          display: true,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
      ],
    },
    {
      id: 20,
      name: '',
      display: true,
      isSubmenu: false,
      privId: "priv_btn_Home",
    },
  ];
  return {
    routesReports,
  };
};

export default useReports;


