import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { setModelMessage } from "store/commonSlice";
import { getCurrentPageName, t } from "utils/constants";

import Style from "./ModelMessage.style";

function ModelMessage({ multipleErrorKeys = [], title = "", message1 = "", groupId = "", error = false, isDefaultDisplay = false }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();

  const { modelMessage } = useSelector(({ common }) => common);
  const styleSx = error
    ? { ...Style?.ModelWrapper, ...Style?.ModelError }
    : { ...Style?.ModelWrapper, ...Style?.ModelSuccess };
  const handleClose = () => {
    document.querySelector(".messageModel").style.animation = "revMyEffect 500ms ease-in"
    // document.querySelector(".messageModel").style.top = "-20%"
    setTimeout(() => {
      dispatch(setModelMessage(false));
    }, 500)
  };

  // RPM-2023 solution implementation
  // Defining a variable message which will store localized message to be displayed.
  // This ensures that correct message is displayed.
  const [message, setMessage] = useState(isDefaultDisplay ? message1 : t(t1, message1));

  // this will run whenever a new value is received for the variable message1
  useEffect(() => {
    setMessage(isDefaultDisplay ? message1 : t(t1, message1))
  }, [message1])
  // End implementation RPM-2023

  return (
    <Modal className="messageModel"
      // open={showAPIModal}
      /* Conflict Resolution Candidate
            open={!isDefaultDisplay ? (t(t1, message1) !== '' && modelMessage !== false) : modelMessage !== false}
       */
      open={modelMessage !== false}
      onClose={handleClose}
    >
      <Box className="messageModel" sx={styleSx}>
        <IconButton onClick={handleClose} sx={{ position: "absolute", right: 10, top: 10 }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="subtitle1" sx={{ fontWeight: 300 }}>
          {t(t1, title)}
          {/* {title} */}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, whiteSpace: "pre-line" }}>
          {isDefaultDisplay ? <div>{message1}</div> : message} {groupId}
        </Typography>

        {typeof multipleErrorKeys !== "string" && multipleErrorKeys?.map((item, index) => {
          const IsCountMax = item.countMaximumSettings ? item.countMaximumSettings : "";
          const IsCountMin = item.countMinimumSettings ? item.countMinimumSettings : "";
          return (<Typography variant="body2" sx={{ mb: 1 }} key={`modal-message-${index}`}>
            {isDefaultDisplay ? item : `${t(t1, item.messageKey)} ${IsCountMax}${IsCountMin}. `}
          </Typography>)
        })}

        {multipleErrorKeys && typeof multipleErrorKeys === "string" &&
          <Typography>{t(t1, multipleErrorKeys)}</Typography>
        }

      </Box>
    </Modal>
  );
}

export default memo(ModelMessage);