import { v4 as uuidv4 } from 'uuid';

// const uuidv4 = require('uuid/v4');

function clientTokenCandidate() {
    if (!localStorage.getItem('clientTokenCandidate')) {
        const token = uuidv4();
        localStorage.setItem('clientTokenCandidate', token);

    }
}
export default clientTokenCandidate;
