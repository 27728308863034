import { Loadable } from "components/Loadable";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { lazy } from "react";

const EventApplicationManagement = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EventApplicationManagement,
    }))
  )
);
const EventApplicationManagementDetails = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EventApplicationManagementDetails,
    }))
  )
);

export const eventApplicationManagementRoutes = {
  path: "/event-application-management",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_event_application_management"}>
          <EventApplicationManagement />
        </RoleBasedGuard>
      ),
    },
    {
      path: ":eventName/:id",
      // path: "view-reserve-sale-events/:id",
      element: (
        <RoleBasedGuard privId={"priv_lnk_event_application_management"}>
          <EventApplicationManagementDetails />
        </RoleBasedGuard>
      ),
    },
  ],
};
