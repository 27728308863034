import { ListItem } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export function AccordionLink({ to, customStyle, children }) {
  return (
    <ListItem sx={{ cursor: "pointer" }}>
      <Link
        to={to}
        style={{ textDecoration: "none", userSelect: "none", color: "#333", ...customStyle }}
      >
        {children}
      </Link>
    </ListItem>
  );
}

