import { useRef, useState } from "react";
import { Chip, Grid } from "@mui/material";
import {
  LicenseInfo,
} from "@mui/x-data-grid-premium";
import CloseIcon from '@mui/icons-material/Close';
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "hooks";
import { QuickLinksMenu } from "components/QuickLinksMenu";
import { CustomeDialogMessage } from "components/CustomeDialogMessage";
import { GridTextfield } from "components/GridTextfield";
import { MUITableGrid } from "components/MUITableGrid";
import { CONFIG_NOTI_SETTINGS_FIELDS, emailPattern, getCurrentPageName, REACT_APP_MUI_LICENSE_KEY, t } from "utils/constants";
import {FormProvider,useForm} from "react-hook-form";
import Style from "./NotificationSettingsTable.style";

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE_KEY);

export default function NotificationSettingsTable({ modalDataObj, setModalDataObj, rowData, setRowData }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const subMenuRef = useRef(null);
  const [openValue, setOpenValue] = useState(null);
  const [open, setOpen] = useState(false);
  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur"
  });
  const { resetField } = methods;

  const handleClick = (id) => {
    if (id === openValue) {
      setOpenValue(null);
      setOpen(false);
    } else {
      setOpenValue(id);
      setOpen(true);
    }
  };

  useClickOutside(subMenuRef, handleClick);

  const handleDelete = (rowKeyIndex, email) => {
    const tempRowData = [...rowData];
    const finalEmails = tempRowData[rowKeyIndex]?.emails?.filter(item => item !== email);
    tempRowData[rowKeyIndex] = {
      ...rowData[rowKeyIndex],
      emails: finalEmails,
    }
    setRowData(tempRowData);
  };

  const tableColumns = [
    {
      id: 1,
      field: "role",
      headerName: t(t1, "ROLE"),
      flex: 2,
      align: "left",
      renderCell: (cellValues) => t(t1, `${cellValues?.row?.role}`)
    },
    {
      id: 2,
      field: "emails",
      headerName: t(t1, "EMAIL"),
      flex: 8,
      align: "left",
      renderCell: (cellValues) => (<div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll',
      }}>{cellValues?.row?.emails?.map((email, index) => {
        return (
          <div
            key={`${email}-${cellValues?.row?.id}`}
            style={index > 0 ? { marginLeft: 10 } : {}}
          >
            <Chip
              label={email}
              sx={{
                margin: 1 / 4,
                py: 1,
                backgroundColor: "#E0EBEF",
                border: "1px solid #4D8DA4",
              }}
              variant="outlined"
              deleteIcon={<CloseIcon sx={Style.deleteIcon} onMouseDown={(event) => event.stopPropagation()} />}
              onDelete={() => handleDelete(cellValues?.row?.key, email)} />
          </div>
        )
      })
        }
      </div>
      )
    },
    {
      id: 3,
      field: "quickactions",
      hideable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      headerName: t(t1, "QUICK_ACTIONS"),
      align: "center",
      className: Style.QuickActionCell,
      disableExport: true,
      renderCell: (cellValues) => {
        const quickLinkList = [
          {
            name: t(t1, "ADD_USER_TO_GROUP")?.toString()?.toUpperCase(),
            Access: true,
            onLinkClick: () => handleAddEmailToGroupModal(cellValues?.row?.key, cellValues?.row?.role),
          },
        ];
        return (
          <QuickLinksMenu
            subMenuRef={subMenuRef}
            iconClassName="dot-icon"
            popupClassName="quick-link-style"
            onMenuDotsClick={() => handleClick(cellValues.id)}
            openMenu={open && openValue === cellValues.id}
            linksList={quickLinkList}
            isDotIcon
          />
        );
      },
    },
  ];

  const addEmailToGroup = (rowKeyIndex, email) => {
    const tempRowData = [...rowData];
    tempRowData[rowKeyIndex] = {
      ...tempRowData[rowKeyIndex],
      emails: [...(tempRowData[rowKeyIndex]?.emails || []), email],
    }
    setRowData(tempRowData);
    setModalDataObj(); // used to hide modal
  }

  const handleAddEmailToGroupModal = (rowKey, roleName) => {
    const emailsToCheck = rowData[rowKey]?.emails || [];
    const modalData = {};
    modalData.title = `${t(t1, roleName)} ${t(t1, 'EMAILS')}`;
    modalData.message = "";
    modalData.buttonMessage = "";
    modalData.buttonText = "CONTINUE";
    modalData.showComentText = true;
    modalData.placeholder = "ENTER_USER_EMAIL";
    modalData.textBoxName = "ADD_USER_TO_GROUP";
    modalData.rules = {
      required: true,
      maxLength: {
        value: CONFIG_NOTI_SETTINGS_FIELDS.EMAIL_MAX_CHAR,
        message: `${t(t1, "ERR_EMAIL_MAX_LENGTH_CHAR")}`,
      },
      validate: (email) => {
        if (emailPattern?.test(email)) {
          // check if email already exists in roles emails
          if (emailsToCheck?.includes(email)) {
            return `${t(t1, "ERR_EMAIL_ALREADY_EXISTS")}`;
          }
          return null;
        }
        return `${t(t1, "ERR_INVALID_EMAIL")}`;
      }
    };
    modalData.privId = "priv_linkmodal_txt_user_notification_email"
    modalData.handleModalContinue = addEmailToGroup;
    modalData.rowKey = rowKey;
    setModalDataObj(modalData);
    resetField("notification_email");
  };

  return (
    <>
      <MUITableGrid
        rows={rowData}
        customTableStyle={Style.CustomChip}
        columns={tableColumns}
        count={rowData?.length}
        isSearch
        customPinnedColumns={{}}
      />
      {modalDataObj && <FormProvider {...methods}>
        <CustomeDialogMessage
          title={modalDataObj?.title}
          message1={t(t1, modalDataObj?.message)}
          buttonMessage={t(t1, modalDataObj?.buttonMessage)}
          continueBtn={modalDataObj?.buttonText}
          handleClose={() => setModalDataObj()}
          open={Boolean(modalDataObj)}
          handleContinue={() => modalDataObj?.handleModalContinue(modalDataObj?.rowKey, methods.getValues('notification_email'))}
          disableContinue={!methods.formState.isValid}
        >
          <Grid item>
            <GridTextfield
              gridMd={8}
              customeClass=""
              className="input-group"
              inputClass=""
              placeholder={t(t1, modalDataObj?.placeholder)}
              rules={modalDataObj?.rules}
              label={t(t1, modalDataObj?.textBoxName)}
              name={"notification_email"}
              privId={modalDataObj?.privId}
              InputProps={{
                "aria-required": "true",
              }}
            />
          </Grid>
        </CustomeDialogMessage>
      </FormProvider>}
    </>
  );
}
