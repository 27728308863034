import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ButtonOutline } from "components/ButtonOutline";
import { useEffectOnce } from "hooks/useEffectOnce";
import { setDialogMessage, setLoader } from "store/commonSlice";
import { getFileUrl } from "store/configSlice";
import { setFilledData, setNextActiveStep, setRedirectionPath, setSubmittedState } from "store/createUserProfileSlice";
import { AllPageName, defaultORGID, docFilesPageName, enLanguage, getCurrentPageName, getFormLabels, scrollToTop, t } from "utils/constants";

import { PersonalInformation } from "../PersonalInformation";
import { ResidenceAndMailing } from "../ResidenceAndMailing";
import { SelectJurisdiction } from "../SelectJurisdiction";

export default function EditAndReview() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  /* Conflict Resolution Candidate
    const { submitted, filledData,formSteps: { isEditField } } = useSelector(({ createUserProfile }) => createUserProfile);
   */
  const { submitted, filledData } = useSelector(({ createUserProfile }) => createUserProfile);
  const { defaultFileUrl } = useSelector(({ config }) => config);
  const [acceptTAndC, setAcceptTAndC] = useState(filledData?.[4].acceptTAndC || false);
  const [filteredForms, setFilteredForms] = useState([]);

  const initialFunctionCall = () => {
    const getDocFilePayload = {
      OrgID: filledData?.[1]?.jurisdiction?.OrgID || defaultORGID,
      QueryType: getFormLabels,
      PageLanguage: enLanguage(),
      Page: docFilesPageName,
    };
    dispatch(getFileUrl(getDocFilePayload));
  };

  useEffectOnce(() => {
    initialFunctionCall();
    scrollToTop()
  }, []);

  const formsLabelList = ["TERMS_AND_CONDITIONS_TEXT"];

  useEffect(() => {
    const filterForms = defaultFileUrl?.data?.filter((eachFile) =>
      formsLabelList?.includes(eachFile?.SK?.split("#")?.pop())
    );
    setFilteredForms(filterForms);
  }, [defaultFileUrl]);

  const handleTAndC = (e) => {
    const { checked } = e.target;
    setAcceptTAndC(checked);
  };
  useEffect(() => {
    dispatch(setLoader(false));
  }, []);

  /* Conflict Resolution Candidate
    const disabledContinueEditFields = useMemo(
      () => Object.keys(stepWiseFieldUserProfile)?.some((step) => isEditField?.[step]),
      [isEditField]
    );
    const isDisabled = !(submitted?.[1] && submitted?.[2] && submitted?.[3] && acceptTAndC) || disabledContinueEditFields;
   */
  const isDisabled = !(submitted?.[1] && submitted?.[2] && submitted?.[3] && !submitted?.editMode && acceptTAndC);

  const handleSubmit = () => {
    dispatch(setFilledData({ step: 4, data: { acceptTAndC } }));
    dispatch(setSubmittedState({ step: 4, data: true }));
    dispatch(setNextActiveStep());
  };

  const handleCancel = () => {
    /* Conflict Resolution Candidate
        dispatch(setRedirectionPath({route : '/' ,jurisdiction :""}))
     */
    dispatch(setDialogMessage(true));
  };

  return (
    <>
      <SelectJurisdiction />
      <PersonalInformation />
      <ResidenceAndMailing />
      <Box
        sx={{
          mt: 5,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FormControlLabel
          style={{ pointerEvents: "none" }}
          sx={{
            mr: "-5px",
            "& .MuiFormControlLabel-label": { color: "text.primary", fontWeight: 600, fontSize: "14px", pointerEvents: "auto" },
          }}
          control={<Checkbox onChange={handleTAndC} style={{ pointerEvents: "auto" }} checked={acceptTAndC} />}
          label={t(t1, "JURISDICTION_TERMS_AND_CONDITIONS")}
        />
        <a
          href={filteredForms?.[0]?.LabelPublished?.replace("#", "%23")}
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: "9px", color: "#4d8da4", fontSize: "14px", fontWeight: 600 }}
        >
          {t(t1, "TERMS_AND_CONDITIONS_TEXT")}
        </a>
        <Box sx={{ ml: "auto" }}>
          {/* Conflict Resolution Candidate
          <ButtonOutline styleSx={{ mr: 1,minHeight:42.6 }} handleClick={handleCancel}>
*/}
          <ButtonOutline styleSx={{ mr: 1 }} handleClick={handleCancel}>
            {t(t1, "CANCEL")}
          </ButtonOutline>
          <Button
            variant="contained"
            disableElevation
            disabled={isDisabled}
            onClick={handleSubmit}
            sx={{
              py: 0.85,
              minWidth: 150,
              /* Conflict Resolution Candidate
                            minHeight:42.6,
               */
              border: "1px solid",
              borderColor: "primary.main",
              "&:hover": {
                bgcolor: "primary.light",
                borderColor: "primary.light",
              },
              "&.Mui-disabled": {
                border: "1px solid",
                borderColor: "common.borderButtonColor",
                color: "common.white",
                backgroundColor: "common.textLightGray",
                textFillColor: "#fff !important"
              },
            }}
          >
            {t(t1, "CONTINUE")}
          </Button>
        </Box>
      </Box>
    </>
  );
}
