import { createSlice } from "@reduxjs/toolkit";
import { APIS, ERROR, SUCCESS } from "utils/constants";
import fetcher from "utils/fetcher";
import { setModelMessage } from "./commonSlice";

const initialState = {
  roles: [],
  roleDetails: {},
  role: [],
  addRole: [],
  postRole: [],
  sysRoles: [],
  selectedRoleTypes: [],
  selectedRoles: [],
  loading: false,
  isSysRole: false,
  isBlankRole: false,
  modalDetails:{},
  Pages:{},
  PageControls: {}
};

const privilegeSlice = createSlice({
  name: "privilege",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.roles = action.payload;
    },
    setRoleDetails: (state, action) => {
      state.roleDetails = action.payload;
    },
    setPages: (state, action) => {
      state.Pages = action.payload;
    },
    setPageControls: (state, action) => {
      state.PageControls = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.role = action.payload;
    },
    setNewRole: (state, action) => {
      state.addRole = action.payload;
    },
    setNewPostRole: (state, action) => {
      state.postRole = action.payload;
    },
    setSystemRoles: (state, action) => {
      state.sysRoles = action.payload;
    },
    setIsSysRole: (state, action) => {
      state.isSysRole = action.payload;
    },
    setIsBlankRole: (state, action) => {
      state.isBlankRole = action.payload;
    },
    setSelectedRoleTypeFilter: (state, action) => {
      state.selectedRoleTypes = action.payload;
    },
    setSelectedRolesFilter: (state, action) => {
      state.selectedRoles = action.payload;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setModalDetails:(state, action)=>{
      state.modalDetails = action.payload;
    }
  },
});

export const { setRole, setRoleDetails, setSelectedRole, setNewRole, setNewPostRole, setSystemRoles, setSelectedRoleTypeFilter, setSelectedRolesFilter, setLoader, setIsSysRole, setIsBlankRole, setModalDetails,setPages,setPageControls } = privilegeSlice.actions;
export default privilegeSlice.reducer;

export function publishAllRoles(to) {
  return (dispatch) => {
    const data = {
      from: decodeURIComponent(process.env.REACT_APP_DEFAULT_ORGID),
      to
    }
    dispatch(setLoader(true));
    fetcher(`auth/publishallroles`, "POST", data, false)
      .then((response) => {
        // console.log(response);
        dispatch(setIsBlankRole(false))
        dispatch(setLoader(false));
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false
        }
        dispatch(setModelMessage(data));
      })
      .catch((err) => {
        dispatch(setIsBlankRole(true))
        dispatch(setLoader(false));
        
        const data = {
          title: ERROR,
          message1: (err) ? err?.response?.data?.errors : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      });
  };
}

export function getRoles(jurisdiction) {
  let jurisdictionId = (jurisdiction || process.env.REACT_APP_DEFAULT_ORGID);
  jurisdictionId = jurisdictionId.replace("#", "%23");
  return (dispatch) => {
    fetcher(`${APIS.ROLE}?PK=${jurisdictionId || process.env.REACT_APP_DEFAULT_ORGID}&SK=Roles`, "GET", {}, false, { componentName: 'getRole' })
      .then((response) => {
        if (response.Items.length > 0) {
          dispatch(setRole(response.Items[0].PrivAttributes));
          if (jurisdiction?.length > 0) {
            dispatch(setIsBlankRole(false))
          }
        }
        else {
          dispatch(setRole([]))
          if (jurisdiction?.length > 0) {
            dispatch(setIsBlankRole(true))
          }
        }
        if (jurisdiction?.length > 0) {
          dispatch(setIsSysRole(false))
        }
        else {
          dispatch(setIsSysRole(true))
        }
      })
      .catch((err) => {
        
      });
  };
}

export function getSysRoles() {
  return (dispatch) => {
    fetcher(`${APIS.ROLE}?PK=${process.env.REACT_APP_DEFAULT_ORGID}&SK=Roles`, "GET", {}, false)
      .then((response) => {
        if (response.Items.length > 0) {
          dispatch(setSystemRoles(response.Items[0].PrivAttributes));
        }
      })
      .catch((err) => {
        
      });
  };
}

export function getRolesDetails(jurisdiction, roleId = 'Pages') {
  let jurisdictionId = (jurisdiction || process.env.REACT_APP_DEFAULT_ORGID);
  jurisdictionId = jurisdictionId.replace("#", "%23");
  roleId = roleId.replace("#", "%23");
  return (dispatch) => {
    dispatch(setRoleDetails([]));
    dispatch(setLoader(true));
    fetcher(`${APIS.ROLE_PAGE}?PK=${jurisdictionId}&SK=${roleId}`, "GET", {}, false, { componentName: 'getRolePage' })
      .then((response) => {
        dispatch(setRoleDetails(response));
/* Conflict Resolution Candidate
        // Added timeout because Page Access component taking more time to render please donot remove
        setTimeout(() => {
          dispatch(setLoader(false));
        }, 7000)
 */
        setTimeout(() => {
          dispatch(setLoader(false));
        }, 1000)
      })
      .catch((err) => {
        dispatch(setLoader(false));
        
      });
  };
}

export function getPageRoleDetail() {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`${APIS.PAGE}?PK=PageName`, "GET", {}, false, { componentName: 'getPage', pageName: 'Page_PM_Home' })
      .then((response) => {
        dispatch(setPages(response));
        setTimeout(() => {
          dispatch(setLoader(false));
        }, 1000)
      })
      .catch((err) => {
        dispatch(setLoader(false));
        
      });
  };
}

export function searchPageControl(data) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`${APIS.SEARCH_PAGES}`, "POST", data, false, { componentName: 'getSearchPages' })
      .then((response) => {
        // console.log(response);
        dispatch(setPageControls(response.Items));
        setTimeout(() => {
          dispatch(setLoader(false));
        }, 1000)
      })
      .catch((err) => {
        dispatch(setLoader(false));
        
      });
  };
}

export function addRoleType(params) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`${APIS.ROLE_TYPE}`, "POST", params, false, { componentName: 'createRoleType' })
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false
        }
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        
        const data = {
           title: ERROR,
          message1: (err?.response?.data) ? err.response.data.message : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      });
  };
}

export function postRoleDetails(isPublish,params, callBackFun) {
  return (dispatch) => {
    dispatch(setLoader(true));
    const endPoint = isPublish ? `${APIS.ROLE}?Publish=${isPublish}` : `${APIS.ROLE}`
    fetcher(endPoint, "POST", params, false, { componentName: 'createRole' })
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false
        }
        if(callBackFun){
          callBackFun()
        }
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((err) => {
/* Conflict Resolution Candidate
        const data = {
           title: ERROR,
          message1: (err?.response?.data) ? err.response.data.message : "MSG_ERR_INTERNAL_SERVER",
 */
        dispatch(setModelMessage(err.message));
        const data = {
           title: ERROR,
          message1: (err?.response?.data) ? err.response.data.errors : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      });
  };
}

export function updateRole(id,isPublish, params, callBackFun) {
  const roleid = id?.replace('#', '%23');
  return (dispatch) => {
    dispatch(setLoader(true));
    const endPoint = isPublish ? `${APIS.ROLE}?roleid=${roleid}&Publish=${isPublish}` : `${APIS.ROLE}?roleid=${roleid}`
    fetcher(endPoint, "PUT", params, false, { componentName: 'UpdatePermission'})
      .then((response) => {
        dispatch(getRolesDetails(params.org_id,id))
        if(callBackFun){
          callBackFun();
        }
        dispatch(setNewPostRole(response));        
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false
        }        
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        
        const data = {
           title: ERROR,
          message1: (err?.response?.data) ? err.response.data.message : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      });
  };
}