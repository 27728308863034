import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import { FormActions } from "components/EntityFormActions";
import { FormCard } from "components/FormCard";
import { useEffect, useState, useRef, useMemo } from 'react';
import { TextTruncate } from "components/TextTruncate";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { setLoader, setIsFormEditable } from 'store/commonSlice';
import {
  COMPONENT_MODES,
  COMPONENT_NAMES, getCurrentPageName, t
} from "utils/constants";

import { setNextStepSelect, updateAuction, resetSteps, setEntityType, setEntityID } from 'store/entityApplicationSlice';
import Style from "./Auction.style";
import UnsavedPrompt from "../../../../../components/UnsavedDialog";
import "../../ComponentTextTruncate.css";


export default function Auction({
  displayHead = true, mode = COMPONENT_MODES.ADD, isEditAccess, onRevisionRequested = false,
  setEditButton, revisionRequestePage = false, updateCardEdit = () => { } }
) {
  const { user } = useSelector(({ auth }) => auth);
  const { t: t1, i18n } = useTranslation(getCurrentPageName());
  const theme = useTheme();
  const themeColor = theme?.palette?.primary?.main;
  const dispatch = useDispatch();
  const { EntityIsAuction, steps, EditAccess, EntitiesData, lockResponse } = useSelector(({ entityApplication }) => entityApplication);
  const defaultValues = EntityIsAuction
  const [selAuctionRadio, setSelAuctionRadio] = useState(null)
  const [stepData, setStepData] = useState({ editMode: false })
  const [renderAuction, setRenderAuction] = useState(true)
  const hideCancelSaveBtn = true;
  const hideEditBtn = true;
  const methods = useForm({
    mode: "all",
    defaultValues,
  });
  const { id } = useParams();
  const [isRadioFieldTouched, setRadioFieldTouched] = useState(false)
  const [isExitInProgress, setIsExitInProgress] = useState(false);
  const navigate = useNavigate();


  useMemo(() => {
    if (onRevisionRequested && lockResponse?.response?.data?.messageKey === 'APPLICATION_IS_LOCKED_BY_ANOTHER_USER') {
      window.location.reload();
    }
  }, [lockResponse]);
  
  const resetData = () => {
    if (id) {
      dispatch(resetSteps());
      dispatch(setEntityType(""));
      dispatch(setEntityID(0));
      navigate("/start-entity-registration");
    }
  }

  const handleRadioChange = (event) => {
    setRadioFieldTouched(true)
    methods.setValue('is_auction', event.target.value)
    setSelAuctionRadio(event.target.value);
  };
  const setDefaultValues = (defaultValues) => {
    methods?.reset(defaultValues);
  };
  const handleSubmitUpdate = () => {
    const values = methods.getValues()
    dispatch(setLoader(true));
    dispatch(updateAuction(values, id))
    handleEnableEdit(false)
    updateCardEdit({ cardName: "AUCTION", isEditing: false });
    dispatch(setIsFormEditable(false));
  }

  const handleCancelAuction = () => {
    setRadioFieldTouched(false)
    AuctionRerendering();
    methods?.reset(defaultValues);
    setSelAuctionRadio(EntityIsAuction?.is_auction);
    updateCardEdit({ cardName: "AUCTION", isEditing: false });
    dispatch(setIsFormEditable(false));
  }

  const handleEnableEdit = (value) => {
    setRadioFieldTouched(false)
    setStepData({ ...stepData, editMode: value })
    updateCardEdit({ cardName: "AUCTION", isEditing: value });
    dispatch(setIsFormEditable(true));
  }

  useEffect(() => {
    setDefaultValues(EntityIsAuction)
    setSelAuctionRadio(EntityIsAuction?.is_auction)
  }, [EntityIsAuction])

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const AuctionRerendering = () => {
    setRenderAuction(false)
    // timeout requires to reset to original values for radio group
    setTimeout(() => {
      setRenderAuction(true)
    });
  }
  const viewOnly = !stepData.editMode;
  const isEditable = EditAccess;

  if (renderAuction) {
    const isContinueDisabled = !selAuctionRadio;
    return (
      <FormProvider {...methods}>
        {displayHead && (
          <Typography style={Style.contentTextCard}>
            <div className="contentTextCardTwo" style={Style.contentTextCard}>
              <TextTruncate
                content={t(t1, "FOOTER_DISCLAIMER_CONTENT")}
                animate
              />
            </div>
          </Typography>
        )
        }
        <FormCard
          customeStyle={Style.FormCardStyle}
          indicateRequired={false}
          setDefaultValues={setDefaultValues}
          stepNumber={1}
          mode={setEditButton && mode}
          handleEnableEdit={handleEnableEdit}
          handleSubmitUpdate={handleSubmitUpdate}
          stepData={stepData}
          stepName={COMPONENT_NAMES.SELECT_ENTITY_TYPE}
          saveDisabled={!methods?.formState?.isValid || !isRadioFieldTouched} 
          cancelAuction={handleCancelAuction}
          isEditAccess={isEditAccess}
          showCancelPopup
          showSavePopup
          hideCancelSaveBtn={!setEditButton && hideCancelSaveBtn}
          hideEditBtn={hideEditBtn}
        >
          <div className={`MODE_${mode} ${viewOnly ? "auction-viewonly" : "auction-editonly"
            }`}>
            <FormCard
              titleSize
              customClass={revisionRequestePage ? "customFormCardRevisionRequest" : "customFormCard"}
              indicateRequired={false}
              stepNumber={1}
              customeStyle={viewOnly ? Style.styleForPendingandRevision : Style.formCardPadding}
              title={t(t1, "AUCTION_AND_RESERVE_SALE_INFORMATION")}
              mode={!setEditButton && mode}
              handleEnableEdit={handleEnableEdit}
              handleSubmitUpdate={handleSubmitUpdate}
              saveDisabled={!isRadioFieldTouched}
              stepData={stepData}
              stepName={COMPONENT_NAMES.SELECT_ENTITY_TYPE}
              cancelAuction={handleCancelAuction}
              isEditAccess={!setEditButton && isEditable}
              hideCancelSaveBtn={setEditButton && hideCancelSaveBtn}
              auctionPage
            >
              <RadioGroup
                row
                onChange={handleRadioChange}
                name='is_auction'
                style={{ marginTop: "10px" }}
                required
                value={methods?.getValues("is_auction") ?? EntityIsAuction?.is_auction}
                defaultValue={EntityIsAuction?.is_auction}
              >
                <Box sx={{ display: "flex" }}>
                  <FormControlLabel value style={{ pointerEvents: "none" }} control={<Radio
                    disabled={mode === COMPONENT_MODES.EDIT && !stepData.editMode || !isEditable}
                    sx={{ mb: "0", color: '#283459 !important' }}
                    icon={<RadioButtonUncheckedIcon color={themeColor} />}
                    checkedIcon={<RadioButtonCheckedIcon color={themeColor} />}
                    style={{ pointerEvents: "auto" }}
                  />}
                    label={
                      <ListItemText
                        disableTypography
                        className="auctionText"
                        style={{ fontWeight: 400, fontSize:"14px", marginBottom:"-1.5rem" }}
                        primary={t(t1, "AUCTION_AND_RESERVE_SALE_INFORMATION_YES")}
                      />
                    }
                  />
                </Box>
                <Box sx={{ display: "flex", }}>
                  <FormControlLabel value={false} style={{ pointerEvents: "none" }} control={
                    <Radio
                      disabled={mode === COMPONENT_MODES.EDIT && !stepData.editMode || !isEditable}
                      sx={{ mb: "0", color: '#283459 !important' }}
                      icon={<RadioButtonUncheckedIcon color={themeColor} />}
                      checkedIcon={<RadioButtonCheckedIcon color={themeColor} />}
                      style={{ pointerEvents: "auto" }}
                    />}
                    label={
                      <ListItemText
                        className="auctionText"
                        disableTypography
                        style={{ fontWeight: 400, fontSize:"14px" }}
                        primary={t(t1, "AUCTION_AND_RESERVE_SALE_INFORMATION_NO")}
                      />
                    }
                  />
                </Box>
              </RadioGroup>
            </FormCard>
          </div>
        </FormCard>
        {mode === COMPONENT_MODES.ADD && <FormActions handleSubmit={(data) => {
          dispatch(setLoader(true));
          dispatch(updateAuction(data, id))
          dispatch(setNextStepSelect({ steps }));
          AuctionRerendering();
        }} stepNumber={3} continueDisabled={!methods?.formState.isValid && methods?.getValues("is_auction") || selAuctionRadio == null}
          onExitClick={() => setIsExitInProgress(true)}
          onCancelExit={() => setIsExitInProgress(false)} />}
        <UnsavedPrompt id={id} when={!isExitInProgress && displayHead} title={t(t1, "WARNING")} message={t(t1, "CONFIRM_LEAVE_PAGE")} onSuccess={resetData} />
      </FormProvider>
    );
  }
}
