const Style = {
  FieldLabel: {
    fontSize: "0.875rem",
    color: "common.textColor",
    fontWeight: 700,
    mb: 0.5,
    display: 'flex',
    alignItems: 'center',
    span: {
      fontWeight: 400,
      ml: 0.5,
      color: "common.textGray",
    },
  },
  formField: {
    width: 1,
    color: "common.black",
    ".MuiFormHelperText-root": {
      mx: 0,
    },
    input: {
      "&::-webkit-outer-spin-button": {
        // WebkitAppearance: "none",
        margin: "0",
      },
      "&::-webkit-inner-spin-button": {
        // WebkitAppearance: "none",
        margin: "0",
      },
      "&[type=number]": {
        // MozAppearance: "textfield",
      },
    },
    ".MuiOutlinedInput-input": {
      p: 0.6,
      fontSize: "0.875rem",
      position: 'relative'
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px",
      borderColor: "common.GrayColor",
    },
    // ".MuiInputBase-root.Mui-focused": {
    //   border: '1px solid #333333',
    // },
    ".MuiOutlinedInput-root": {
      "&.Mui-focused": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "common.textColor",
          borderWidth: 1,
        },
      },
    },
   

    "&.password-tooltip .MuiTooltip-popper" : {
      inset: '-47px auto auto 0px !important'
    },
    "&.password-tooltip .Mui-error .MuiTooltip-popper" : {
      inset: '-71px auto auto 0px !important'
    },
    "&.password-tooltip.password-label .MuiTooltip-popper" : {
      inset: '-40px auto auto 0px !important'
    },
    "&.password-tooltip.password-label .Mui-error .MuiTooltip-popper" : {
      inset: '-61px auto auto 0px!important'
    },



    "&.password-tooltip-user .MuiTooltip-popper" : {
      inset: '-47px auto auto 0px !important'
    },
    "&.password-tooltip-user .Mui-error .MuiTooltip-popper" : {
      inset: '-71px auto auto 0px !important'
    },
    "&.password-tooltip-user.password-label .MuiTooltip-popper" : {
      inset: '-40px auto auto 0px !important'
    },
    "&.password-tooltip-user.password-label .Mui-error .MuiTooltip-popper" : {
      inset: '-61px auto auto 0px!important'
    },
    
  },
  valuetext: {
    textOverflow: "hidden",
    overflow: "hidden",
    wordWrap: "break-word",
    wordBreak: "break-word",
  },
  popperStyle: {
    px: 0.5, 
    display: 'flex', 
    alignItems: 'center',
    // textAlign: 'justify', 
    color: 'theme.text.primary', 
    fontSize: 13,
    "& span > span" : {
      display: 'block',
      background: '#ededed',
      padding: '2px 10px',
      borderRadius: '4px',
      margin: '5px 0'
    },
    '& .MuiSvgIcon-root': {
      height:"14px",
      width:"14px"
    },
    '& .MuiTooltip-tooltip': {
      maxWidth:"412px",
      width:'100%'
    }
  },
};

export default Style;
