// Library hooks
import { useTranslation } from "react-i18next";

// Redux hooks and states
import { useSelector } from "react-redux";

// MUI Hooks and Components
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { StyledRequiredAstrik } from "pages/NAICSCodeManagement/NAICSCodeManagement.style";
import { getCurrentPageName, t } from "utils/constants";
import Style from "./GridRadioButton.style";

export default function GridRadioButton(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());

  // Redux state de-structure
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write

  // Props de-structure
  const {
    privId = "",
    name,
    label,
    rules,
    radios,
    gridMd = 4,
    className = "",
    onRadioChange,
    labelSx = "",
    sx = "",
    selectedRadio = "",
    LabelShowAsName = false,
    DisabledClick = false,
    disabled = false,
    isLabelRequired = false,
    customTrigger = () => {},
    isRequiredMsg = "",
  } = props;

  // console.log(props, radios);
  let readOnly = false;

  if (DisabledClick) {
    readOnly = true;
  } else {
    readOnly = false;
  }

  if (privId === "") {
    if (PrivAttributes?.[name]?.PrivRole === "NA") {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === "RO";
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === "NA") {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === "RO";
  }

  if (rules && rules?.required && label) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }

  return (
    <Grid
      item
      // md={gridMd}
      className={className}
      sx={sx}
    >
      <FormControl>
        <FormLabel
          sx={{
            color: "common.black",
            fontWeight: 700,
            "&.Mui-focused": {
              color: "common.black",
            },
            ...labelSx,
          }}
        >
          {label}
          {isLabelRequired && <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>}
        </FormLabel>
        <RadioGroup
          // disableRipple
          name={name}
          sx={Style.RadioSize}
        >
          {radios?.map((role, index) => {
            if (PrivAttributes?.[role.privId]?.PrivRole === "NA" || !role.isDisplay) {
              return null;
            }
            // if (role.privId === "priv_radio_RequestRevisions" || role.privId === "priv_radio_CancelUserRegistration") {
            //   return null;
            // }

            // This is a special case, added to disable radio request revision btn when logged in user has dual
            // roles as registrar n authority and request status is revision requested
            if (role?.privId === "RO") {
              readOnly = true;
            } else readOnly = PrivAttributes?.[role.privId]?.PrivRole === "RO";
            return (
              <Box
                key={`${role.value}_${index}`}
                sx={{ display: "flex", alignItems: "center", pointerEvents: "none" }}
              >
                <FormControlLabel
                  key={`${role.value}_${index}`}
                  value={role.value}
                  sx={{
                    marginLeft: 0,
                    marginBottom: 0.875,
                    pointerEvents: "auto",
                  }}
                  control={
                    <Radio
                      size="small"
                      name={role.name}
                      disabled={readOnly || disabled || role?.disabled}
                      checked={role?.value === selectedRadio}
                      checkedIcon={<CheckCircleIcon />}
                      sx={{
                        "&:hover": {
                          bgcolor: "transparent",
                        },
                        marginRight: 1,
                        pointerEvents: "auto",
                      }}
                      onChange={async (e) => {
                        await onRadioChange(e, name);
                        customTrigger();
                      }}
                    />
                  }
                  label={LabelShowAsName ? role.name : role.value}
                />
              </Box>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}
