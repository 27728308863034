import { Loadable } from "components/Loadable";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { lazy } from "react";

const AddOffsetProjectName = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.AddOffsetProjectName,
    }))
  )
);

const EditOffsetProjectName = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EditOffsetProjectName,
    }))
  )
);

const ManageOffsetProjectName = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.ManageOffsetProjectName,
    }))
  )
);

const AddOffsetProjectOperator = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.AddOffsetProjectOperator,
    }))
  )
);

const EditOffsetProjectOperator = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EditOffsetProjectOperator,
    }))
  )
);

const ManageOffsetProjectOperator = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.ManageOffsetProjectOperator,
    }))
  )
);
const AddVerificationBody = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.AddVerificationBody,
    }))
  )
);

const EditVerificationBody = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EditVerificationBody,
    }))
  )
);

const ManageVerificationBody = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.ManageVerificationBody,
    }))
  )
);
const OffsetIssuanceReport = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.OffsetIssuanceReport,
    }))
  )
);
const OffsetTrackingRecordDetails = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.OffsetTrackingRecordDetails,
    }))
  )
);

const AddOffsetTrackingRecord = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.AddOffsetTrackingRecord,
    }))
  )
);

const ManageOffsetTrackingRecord = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.ManageOffsetTrackingRecord,
    }))
  )
);

const ProposeOffsetTrackingRecord = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.ProposeOffsetTrackingRecord,
    }))
  )
);

export const offsetManagementRoutes = {
  path: "/offset-management",
  children: [
    {
      path: "project-name",
      children: [
        {
          path: "manage",
          element: (
            <RoleBasedGuard privId={""}>
              <ManageOffsetProjectName />
            </RoleBasedGuard>
          ),
        },
        {
          path: "add",
          element: (
            <RoleBasedGuard privId={""}>
              <AddOffsetProjectName />
            </RoleBasedGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RoleBasedGuard privId={""}>
              <EditOffsetProjectName />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: "project-operator",
      children: [
        {
          path: "manage",
          element: (
            <RoleBasedGuard privId={""}>
              <ManageOffsetProjectOperator />
            </RoleBasedGuard>
          ),
        },
        {
          path: "add",
          element: (
            <RoleBasedGuard privId={""}>
              <AddOffsetProjectOperator />
            </RoleBasedGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RoleBasedGuard privId={""}>
              <EditOffsetProjectOperator />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: "verification-body",
      children: [
        {
          path: "manage",
          element: (
            <RoleBasedGuard privId={""}>
              <ManageVerificationBody />
            </RoleBasedGuard>
          ),
        },
        {
          path: "add",
          element: (
            <RoleBasedGuard privId={""}>
              <AddVerificationBody />
            </RoleBasedGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RoleBasedGuard privId={""}>
              <EditVerificationBody />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: "issuance-report",
      element: (
        <RoleBasedGuard privId={""}>
          <OffsetIssuanceReport />
        </RoleBasedGuard>
      ),
    },

    // ToDo: have to replace with corresponding page level components
    {
      path: "tracking-record",
      children: [
        {
          path: "add",
          element: (
            <RoleBasedGuard privId={""}>
              <AddOffsetTrackingRecord />
            </RoleBasedGuard>
          ),
        },
        {
          path: "manage",
          element: (
            <RoleBasedGuard privId={""}>
              <ManageOffsetTrackingRecord />
            </RoleBasedGuard>
          ),
        },
        {
          path: "details/:id",
          element: (
            <RoleBasedGuard privId={""}>
              <OffsetTrackingRecordDetails />
            </RoleBasedGuard>
          ),
        },
        {
          path: "propose/:id",
          element: (
            <RoleBasedGuard privId={""}>
              <ProposeOffsetTrackingRecord />
            </RoleBasedGuard>
          ),
        },
      ],
    },
  ],
};
