import { Icon } from "@iconify/react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import { DialogMessage, FormCard, GridTextfield } from "components";
import { CustomizeButton } from "components/CustomizeButton";
import { Loader } from "components/Loader";
import { TextTruncate } from "components/TextTruncate";
import useAuth from "hooks/useAuth";
import { useEffectOnce } from "hooks/useEffectOnce";
import parse from "html-react-parser";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAccessToken } from "store/authSlice";
import { setDialogMessage, setIsFormEditable, setLoader, setModelMessage } from "store/commonSlice";
import {
  changeStatusToSubmit,
  getRepresentative,
  resetSteps,
  saveFacility,
  setEntityID,
  setEntityType,
  setHandleRepReset,
  setNextStepSelect,
  setRepresentative,
  setRepresentativeOnLocal,
  setSubmittedOfSelectedStep,
  updateLock,
  updateRepresentative
} from "store/entityApplicationSlice";
import {
  COMPONENT_MODES,
  COMPONENT_NAMES,
  COMPONENT_NAME_FOR_ROLE,
  ENTITY_TYPE_NAMES, ERROR,
  JURISDICTIONS_NAMES,
  REVIEW_PAGE_COMPONENTS,
  STATUS_IDS, SUCCESS,
  USER_ROLES, USER_ROLE_TYPES,
  getCurrentPageName,
  handleEntityStatusError,
  handleRepresentativeError,
  isEmpty,
  isRolesAssigned,
  parseOptions,
  t
} from "utils/constants";
import logger from "utils/logger";
import { RichTextWithScrollStyle } from "config/common.styles";
import UnsavedPrompt from "../../../../../components/UnsavedDialog";
import { StyledCardTitle } from "../../../../AccountDetails/AccountDetails.style";
import { StyledRequiredAstrik } from "../../../../WiringInstructions/WiringInstructions.style";
import "../../ComponentTextTruncate.css";
import AddFacility from '../AddFacility/AddFacility';
import { AlternateContactForm } from "../AddRepresentative/AddAlternateContact";
import AddRepresentative from '../AddRepresentative/AddRepresentative';
import Auction from '../Auction/Auction';
import ContactInformation from '../ContactInformation/ContactInformation';
import EnterEntityInformation from '../EnterEntityInformation/EnterEntityInformation';
import SelectEntityType from '../SelectEntityType/SelectEntityType';
import SelectJurisdiction from '../SelectJurisdiction/SelectJurisdiction';
import Style from './ReviewSubmitStyle';

export default function ReviewandSubmit({ displayHead = true }) {   
  const { id } = useParams();
  const [formState, setFormState] = useState(null)
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const { steps, selectedEntity, Representative, NAICS, Facilities, EntityIsAuction, selectedJurisdiction, EditAccess, RepresentativesByGET, enableSaveBtnOnReview, EntitiesData, disabledFacilitySubmitBtn, lockIntervalId } = useSelector(
    ({ entityApplication }) => entityApplication
  );
  const [stepData, setStepData] = useState({ editMode: false });
  const [stepRepresentativeData, setStepRepresentativeData] = useState({ editMode: false });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loginMessage, setLoginMessage] = useState(false);
  const [openEaaSuccessDialog, setOpenEaaSuccessDialog] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const textRef = useRef();
  const [EntityInformationError, setEntityInformationError] = useState(false);
  const [AlternateContactError, setAlternateContactError] = useState(false);
  const [facilityMode, setFacilityMode] = useState(COMPONENT_MODES.EDIT);
  const cardsValues = REVIEW_PAGE_COMPONENTS.map((item) => { return { cardName: item, isEditing: false } })
  const [cardEdit, setCardEdit] = useState(cardsValues)
const [customLoader, setCustomLoader] = useState(false)
  const [isExitInProgress, setIsExitInProgress] = useState(false);
  const [enableSaveBtnOnRepresentative, setEnableSaveBtnOnRepresentative] = useState(false);
  const [newRepAdded, setNewRepAdded] = useState(false)
  const [repEdited, setRepEdited] = useState(false);

  
  const representativeAddBtn = () => {
    setEnableSaveBtnOnRepresentative(true)
  }
  
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isIndividualUser, isJurisdictionRegistrarUser } = useMemo(
    () => ({
      isIndividualUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]),
      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR]
      ),
    }),
    [userDetails]
  );

  let representativesPageDetail = { componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE, pageName: "Page_EntityProfile" };
  if (isJurisdictionRegistrarUser) {
    representativesPageDetail = { componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE, pageName: "Page_JurisdictionEntityProfile" }
  }

  const { signIn, user, ...rest } = useAuth();
  useEffectOnce(() => {
    const defaultValues = steps.find((item) => item.key === COMPONENT_NAMES.SELECT_JURISDICTION).filledData;
    methods?.reset(defaultValues);
  }, []);

  useEffect(() => {
    if (EntitiesData?.data?.entityStatus?.name === "PENDING") {
      navigate(`/entity-profile/${id}`)
    }
  }, [EntitiesData])
  const navigate = useNavigate();
  const resetData = () => {
    if (id) {
      dispatch(resetSteps());
      dispatch(setEntityType(""));
      dispatch(setEntityID(0));
      navigate("/start-entity-registration");
    }
  }
  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {},
  });
  const handleSuccessOk = () => {
    setOpenEaaSuccessDialog(false);
    dispatch(setNextStepSelect({ steps }));
  };

  const handleClose = () => {
    setOpenEaaSuccessDialog(false);
  };

  const handleCloseDialouge = () => {
    methods.reset();
    textRef.current.value = "";
    setOpenDialog(false);
  };

  const handleEditSaveClick = () => {
    setLoader(true)
    const countOfenabledButton = document.getElementsByClassName("cancelBtnAction");
    if (!isEmpty(countOfenabledButton)) {
      Object?.keys(countOfenabledButton)?.forEach((ele) => document.getElementsByClassName("cancelBtnAction")[ele].click());
    }
    setShowPassword(false);
    setOpenDialog(true);
    methods.reset();
  };

  const handleCancelModal = () => {
    navigate("/home");
  };

  const handleEnableEdit = (value) => {
    setStepData({ ...stepData, editMode: value });
    updateCardEdit({ cardName: "FACILITY", isEditing: value });
    dispatch(setIsFormEditable(true));
    if (value) {
      const facility = methods.getValues();
      methods.reset();
      Object.keys(facility).forEach((fieldName) => {
          methods.setValue(fieldName, facility[fieldName]);
      });
    }
  };

  const verifyNewRepAdded = (value) => {
    setNewRepAdded(value);
  }

  const verifyRepEdited = (value) => {
    setRepEdited(value);
  }


  const handleResetEdit = () => {
    setStepData({ ...stepData, editMode: false });
    updateCardEdit({ cardName: "FACILITY", isEditing: false });
    dispatch(setIsFormEditable(false));
  }
  //
  const handleSubmitUpdate = async(facilityData) => {
    try{
    const defaultValue = steps.find((item) => item.key === COMPONENT_NAMES.ADD_FACILITIES).filledData
    const updatedValue = facilityData?.map((item, index) => {
      const newItem = { ...item, is_updateGHG: false }
      if (newItem?.GHGID !== defaultValue[index]?.GHGID) {
        newItem.is_updateGHG = true
      }
      return newItem
    })
    dispatch(setLoader(true));
    await dispatch(saveFacility(id, updatedValue, NAICS, t1, false));
    handleEnableEdit(false);
    dispatch(setIsFormEditable(false));
    updateCardEdit({ cardName: "FACILITY", isEditing: false });
  } catch(error){
    console.error('Error saving facility:', error);
    handleEnableEdit(false);
    dispatch(setIsFormEditable(false));
    updateCardEdit({ cardName: "FACILITY", isEditing: false });
  }
  };
  const updateCardEdit = (editingCard) => {
    setCardEdit((currentCardState) => {
      return currentCardState.map((card) => {
        if (card.cardName === editingCard.cardName) {
          return { ...editingCard }
        }
        return card
      })
    });
  }

  const {
    register,
    formState: { errors },
  } = methods;
  const { dialogMessage, isFormEditable, loader } = useSelector(({ common }) => common);

  const passphraseFocus = () => {
    setShowPassword(!showPassword);
    textRef.current.focus();
    if (typeof window.getSelection !== "undefined" && typeof document.createRange !== "undefined") {
      const range = document.createRange();
      range.selectNodeContents(textRef.current);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== "undefined") {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(textRef.current);
      textRange.collapse(false);
      textRange.select();
    }
  };

  const inputProps = {
    autoComplete: "new-password",
  };
  const handleCancel = () => {
    dispatch(setDialogMessage(true));
    setShowModal(true);
  };

  const loginVerify = async () => {
    setCustomLoader(true)
    dispatch(setLoader(true));
    if (textRef?.current?.value?.length >= 8 && user.username) {
      setOpenDialog(false);
      await signIn({
        email: user.username,
        password: textRef?.current?.value,
        clientMetaData: {
          token: getAccessToken(),
        },
      })
        .then(() => {
          dispatch(setLoader(true));
          setCustomLoader(true)
          changeStatusToSubmit(id).then(response => {
            if(response?.messageKey === "APPLICATION_SUBMITTED_SUCCESSFULLY"){
            setCustomLoader(false)
            }
            clearInterval(lockIntervalId);
            dispatch(updateLock(id, false));
            dispatch(setSubmittedOfSelectedStep(true));
            setLoginMessage("");
            setOpenDialog(false);
            // setOpenEaaSuccessDialog(true)
            dispatch(setNextStepSelect({ steps }));
            const data = {
              title: SUCCESS,
              message1: 'A_CONFIRMATION_HAS_BEEN_EMAILED_TO_THE_ADDRESS_PROVIDED',
              error: false,
              isDefaultDisplay: false,
            };
            dispatch(setModelMessage(data))
            dispatch(setLoader(false));

          }).catch((error) => {
            dispatch(setLoader(false));
            const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
            if (hasNavigated) return;
            AddRepresentativeShowError(error)
          });
        })
        .catch((error) => {
          setLoginMessage("");
          const data = {
            title: ERROR,
            // eslint-disable-next-line no-nested-ternary
            message1: error?.message === `${t(t1, ('MSG_ERR_USER_NOT_EXIST'))}.` || error?.message === `${t(t1, ('MSG_ERR_INCORRECT_USR_PASS'))}.` || error?.message === `${t(t1, ('MSG_ERR_USER_DISABLED'))}.` ? t(t1, "INVALID_PASSPHRASE") : (error?.message.includes("##") ? parse(error?.message.split("##")[1], parseOptions) : parse(error?.message, parseOptions)),
            error: true,
            isDefaultDisplay: true
          };
          dispatch(setModelMessage(data));
          dispatch(setLoader(false));
          setCustomLoader(false)

        });
    }
  };

  const handleConfirmDialouge = () => {
    loginVerify();
  };

  // Repsentative

  const handleEnableEditReps = (value) => {
    setEnableSaveBtnOnRepresentative(false)
    setStepRepresentativeData({ ...stepRepresentativeData, editMode: value });
    updateCardEdit({ cardName: "REPRESENTATIVE", isEditing: value });
    dispatch(setIsFormEditable(value));
    if (value) {
      const representativeField = methods.getValues();
      methods.reset();
      Object.keys(representativeField).forEach((fieldName) => {
        methods.setValue(fieldName, representativeField[fieldName]);
      });
    }
    setNewRepAdded(false);
    setRepEdited(false)
  };

  
  const AddRepresentativeShowError = (error) => {
    handleRepresentativeError(error, dispatch, logger);
  };
 
  const handleSubmitUpdateReps = () => {
    dispatch(setLoader(true));
    const reps = Representative?.map((item) => {
      return {
        id: item.id,
        role: (item.role?.id) ? item.role?.id : item.role,
        userReferenceCode: item.userReferenceCode,
        isAllowedAccess: item.isAllowedAccess,
        name: item.name,
        entityId: id,
        email: item.email,
        status: STATUS_IDS.PROPOSED_STATUS_ID,
      };
    });
    const postData = {
      representative: reps,
    };
    if (id) {
      dispatch(setRepresentativeOnLocal([]));
      updateRepresentative(postData, id).then((response) => {
        // TBD - After saved successfully need to notify
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setRepresentativeOnLocal([]))
        dispatch(getRepresentative(id, representativesPageDetail));
        dispatch(setLoader(false));
        dispatch(setIsFormEditable(false));
        handleEnableEditReps(false);
        updateCardEdit({ cardName: "REPRESENTATIVE", isEditing: false });
      })
        .catch((error) => {
          dispatch(setLoader(false));
          const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
          if (hasNavigated) return;
          AddRepresentativeShowError(error)
        });
    }
    dispatch(setHandleRepReset(true));
  };

  const handleCancelRepresentative = () => {
    setEnableSaveBtnOnRepresentative(false)
    dispatch(setRepresentativeOnLocal([]));
    dispatch(setRepresentative(RepresentativesByGET));
    updateCardEdit({ cardName: "REPRESENTATIVE", isEditing: false });
    dispatch(setHandleRepReset(true));
  }
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useMemo(() => {
    if (selectedEntity === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY) {
      setEntityInformationError(false);
    }
  }, [selectedEntity])

  const getEntityError = (data) => {
    if (selectedEntity === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION) {
      if (isEmpty(data)) {
        setEntityInformationError(true);
      }
      else {
        setEntityInformationError(false);
      }
    }
  }

  const [checkFacility, setCheckFacility] = useState(Facilities);

  const checkAlternateValidation = (value) => {
    setAlternateContactError(value);
  }

  const isEditable = EditAccess;
  const facilityModeShift = (value) => {
    setFacilityMode(value);
  }

  const hideCancel = isEmpty(steps.find((item) => item.key === COMPONENT_NAMES.ADD_FACILITIES).filledData)

  return (
    <>
      {
        displayHead && (
          <Typography style={Style.contentTextCard}>
            <div className="contentTextCardTwo" style={Style.contentTextCard}>
              <TextTruncate
                content={t(t1, "FOOTER_DISCLAIMER_CONTENT")}
                animate
              />
            </div>
          </Typography>
        )
      }
      {<SelectJurisdiction displayHead={false} mode={COMPONENT_MODES.EDIT} updateCardEdit={updateCardEdit} />}
      {<SelectEntityType displayHead={false} mode={COMPONENT_MODES.EDIT} updateCardEdit={updateCardEdit} />}
      {<EnterEntityInformation displayHead={false} mode={COMPONENT_MODES.EDIT} isReviewAndSubmit getEntityError={getEntityError} updateCardEdit={updateCardEdit} />}
      {<ContactInformation displayHead={false} mode={COMPONENT_MODES.EDIT} isReviewAndSubmit combineAddressUpdate updateCardEdit={updateCardEdit} />}
      {selectedEntity === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY && (
        <div className={stepData.editMode ? "facility-editonly" : "facility-viewonly"}>
          <FormCard
            title={t(t1, "FACILITY_INFORMATION")}
            mode={facilityMode}
            handleEnableEdit={handleEnableEdit}
            handleSubmitUpdate={handleSubmitUpdate}
            saveDisabled={!stepData.editMode}
            stepData={stepData}
            stepName={COMPONENT_NAMES.ADD_FACILITIES}
            customClass={"customFormCard add-facilities"}
            hideCancelSaveBtn
            customeStyle={!stepData.editMode ? Style.paddingBottom : Style.paddingBottom20}
            showCancelSaveForFacility={false}
            hideCancel={hideCancel}
            isEditAccess={EditAccess}
          >
            <AddFacility displayHead={false} handleResetEdit={handleResetEdit} usage={COMPONENT_NAMES.REVIEW_AND_SUBMIT} mode={COMPONENT_MODES.EDIT} stepData={stepData} setCheckFacility={setCheckFacility} handleSubmitChanges={handleSubmitUpdate} editModeforReviewandSubmit={stepData?.editMode} switchFacilityMode={facilityModeShift} hideCard />

          </FormCard>
        </div>
      )}

      {
        <div className={stepRepresentativeData.editMode ? "representative-editonly" : "representative-viewonly"}>
          <FormCard
            title={stepRepresentativeData.editMode ? "" : t(t1, "REPRESENTATIVES")}
            mode={COMPONENT_MODES.EDIT}
            handleEnableEdit={handleEnableEditReps}
            handleSubmitUpdate={handleSubmitUpdateReps}
            handleCancelRepresentative={handleCancelRepresentative}
            saveDisabled={Representative?.length === 0 || !(repEdited || newRepAdded)}
            stepData={stepRepresentativeData}
            stepName={COMPONENT_NAMES.ADD_REPRESENTATIVES}
            customClass={"customFormCard add-representatives"}
            isEditAccess={EditAccess}            
            indicateRequired={!stepRepresentativeData.editMode}
            customeStyle={!stepRepresentativeData.editMode ? Style.paddingBottom : Style.paddingBottom20}
          >
            <AddRepresentative
              showRepTableTitle={stepRepresentativeData.editMode}
              title={false}
              representativeAddFunc={representativeAddBtn}
              removeViwingAgent
              displayHead={false}
              mode={COMPONENT_MODES.EDIT}
              stepData={stepRepresentativeData}
              showUpdateByColumn
              hideAlternateContactForm={false}
              onValidationChange={(isValid) => {
                setFormState(isValid)

              }}
              disabledOnReviewAndSubmit
              hideCard
              isReviewAndSubmit
              verifyNewRepAdded={verifyNewRepAdded}
              verifyRepEdited={verifyRepEdited}
            />
          </FormCard>
        </div>
      }

      <AlternateContactForm mode={COMPONENT_MODES.EDIT} updateCardEdit={updateCardEdit} />
      {selectedJurisdiction !== JURISDICTIONS_NAMES.QUEBEC && <Auction displayHead={false} mode={COMPONENT_MODES.EDIT} updateCardEdit={updateCardEdit} />}
      <FormProvider {...methods}>
        <Box
          sx={{
            mt: 5,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >

          <Box sx={{ marginLeft: "auto" }} className="btn-wrapper">

            <CustomizeButton
              customStyle={{ mr: 1 }}
              variant="outlined"
              csName="rc"
              label={t(t1, 'EXIT_APPLICATION')}
              name="priv_modal_Cancel"
              handleClick={handleCancel}
            />
            {isEditable &&
              <Button
                variant="contained" color="primary"
                disabled={((selectedEntity === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY && checkFacility?.length === 0) || Representative?.length === 0 || EntityInformationError || AlternateContactError || !isEmpty(cardEdit.filter((card) => card.isEditing))) || disabledFacilitySubmitBtn}
                onClick={handleEditSaveClick}
                autoFocus
                styleSx={{ height: "40px" }}
                sx={{ width: 150 }}
                className="btn-primary"
              >
                {
                  t(t1, "SUBMIT")
                }
              </Button>
            }

          </Box>

          <Dialog
            indicateRequired={false}
            open={openDialog}
            fullWidth
            disableBackdropClick
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "8px",
              },
            }}
          >
            <span style={{
              borderColor: "#283459",
              borderStyle: "solid", borderRadius: "8px", color: "black"
            }}>
              <DialogTitle>
                <StyledCardTitle sx={{ fontSize: 24, fontWeight: 300, mb: 0, color: "#333333" }}>{t(t1, "ENTER_PASSPHRASE")}</StyledCardTitle>
                <Typography
                  variant="caption"
                  component="div"
                  display="block"
                >
                  <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>
                  {t(t1, "INDICATE_REQUIRED_FIELDS")}
                </Typography>
              </DialogTitle>
              <DialogContent style={{ paddingBottom: "0" }}>
                <Typography
                  sx={{ ...RichTextWithScrollStyle }}
                  component="p"
                  variant="body2"
                  color="text.primary"
                  >
                  {
                    t(t1, 'IC_T_AND_C_ON_SUBMIT_APPLICATION_IN_PROGRESS')
                  }
                </Typography>  

                <GridTextfield

                  autoComplete="password"
                  gridMd={6}
                  refField={textRef}
                  privId="priv_txt_newPassphrase"
                  type={showPassword ? "text" : "password"}
                  name="newPassphrase"
                  label={t(t1, "ENTER_PASSPHRASE_TO_SUBMIT")}
                  rules={{
                    required: `${t(t1, "PASSPHRASE_REQUIRED")}`,
                    minLength: {
                      value: 8,
                      message: ` ${t(t1, "ERR_PASSPHRASE_REQUIRED_MIN_LENGTH_CHAR")}`,
                    },
                    maxLength: {
                      value: 50,
                      message: `${t(t1, "ERR_PASSPHRASE_REQUIRED_MAX_LENGTH_CHAR")}`,
                    },
                  }}
                  isRequiredMsg={'PASSPHRASE_REQUIRED'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={passphraseFocus} edge="end">
                          <Icon icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                    ...inputProps,
                  }
                  }
                />
                <div>{loginMessage}</div>
              </DialogContent>
              <DialogActions sx={{ mb: 2, mr: 2, pt: 0 }}>

                <Button
                  variant="outlined"
                  onClick={handleCloseDialouge}
                  sx={{ width: 150, height: "40px", color: "#4D8DA4", backgroundColor: "#FFFFFF", border: "1px solid #C7C8CA", "&:hover": { backgroundColor: "#FFFFFF", border: "1px solid #C7C8CA" } }}
                >
                  {t(t1, "CANCEL")}
                </Button>
                <Button variant="contained" onClick={handleConfirmDialouge}
                  autoFocus
                  sx={{
                    width: 150, height: "40px",
                    "&.Mui-disabled": {
                      backgroundColor: "#aaaaaa",
                      textFillColor: "#ffffff !important"
                    },
                    '&:hover': {
                      backgroundColor: '#4D8DA4',
                    },
                  }}
                  disabled={!methods?.formState.isValid}>
                  {t(t1, "CONTINUE")}
                </Button>

              </DialogActions>
            </span>
          </Dialog>
        </Box>
        <UnsavedPrompt id={id} when={!isExitInProgress} title={t(t1, "WARNING")} message={t(t1, "CONFIRM_LEAVE_PAGE")} onSuccess={resetData} />        
      </FormProvider>

      <Dialog
        open={openEaaSuccessDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(t1, "A_CONFIRMATION_HAS_BEEN_EMAILED_TO_THE_ADDRESS_PROVIDED")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleSuccessOk() }}>{t(t1, "OK")}</Button>
        </DialogActions>
      </Dialog>

      {dialogMessage && showModal ? (
        <DialogMessage
          borderColor="#ca2c1c"
          title={t(t1, "EXIT_APPLICATION")}
          message1={t(t1, "EXIT_APPLICATION_WARNING_CONTENT1")}
          message2={t(t1, "EXIT_APPLICATION_WARNING_CONTENT2")}
          buttonMessage={t(t1, "EXIT_APPLICATION_WARNING_CONTENT3")}
          handleModalClick={handleCancelModal}
        />
      ) : null}
      <Loader loader={customLoader}/>
    </>
  );
}
