import { useSelector } from "react-redux";
import { PERMISSION_NAMES } from "utils/constants";

function PermissionWrapper({ children, componentName, fieldName }) {
  const { fieldLevelPermissions } = useSelector(({ permissions }) => permissions);
  const component = fieldLevelPermissions?.find((item) => item?.componentName === componentName);
  const field = component?.componentFields?.find((item) => item?.fieldName === fieldName);
  if (
    field?.fieldPermission?.includes(PERMISSION_NAMES.READ) ||
    field?.fieldPermission?.includes(PERMISSION_NAMES.WRITE)
  ) {
    return children;
  }
  return "";
}

export default PermissionWrapper;
