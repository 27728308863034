import { createSelector, createSlice } from "@reduxjs/toolkit";
import {setModelMessage, setLoader} from "store/commonSlice";
import {APIS, ERROR, SUCCESS} from "utils/constants";
import {fetcherGet, fetcherPost, fetcherPut} from "utils/fetcher";
import logger from "utils/logger";
import {getAccountById} from "./entityAccountSlice";

const initialState = {
  wiringDetails: [],
  statusHistory: [],
  isLoadingWiringDetails: false,
  isLoadingStatusHistory: false,
  isAddingWiringDetails: false,
  isUpdatingWiringDetails: false,
  isVerifyingWiringDetails: false,
  isInvalidatingAllWiringDetails: false,
};

const entityAccountWiringSlice = createSlice({
  name: "wiring",
  initialState,
  reducers: {
    setWiringDetails: (state, action) => {
      state.wiringDetails = action.payload;
    },
    setStatusHistory: (state, action) => {
      state.statusHistory = action.payload;
    },
    setIsLoadingWiringDetails: (state, action) => {
      state.isLoadingWiringDetails = action.payload;
    },
    setIsLoadingStatusHistory: (state, action) => {
      state.isLoadingStatusHistory = action.payload;
    },
    setIsAddingWiringDetails: (state, action) => {
      state.isAddingWiringDetails = action.payload;
    },
    setIsUpdatingWiringDetails: (state, action) => {
      state.isUpdatingWiringDetails = action.payload;
    },
    setIsVerifyingWiringDetails: (state, action) => {
      state.isVerifyingWiringDetails = action.payload;
    },
    setIsInvalidatingAllWiringDetails: (state, action) => {
      state.isInvalidatingAllWiringDetails = action.payload;
    },
    resetWiringData: (state) => {
      state.wiringDetails = initialState.wiringDetails;
      state.statusHistory = initialState.statusHistory;
    }
  },
});

export const {
  setWiringDetails,
  setStatusHistory,
  setIsLoadingWiringDetails,
  setIsLoadingStatusHistory,
  setIsAddingWiringDetails,
  setIsUpdatingWiringDetails,
  setIsVerifyingWiringDetails,
  setIsInvalidatingAllWiringDetails,
  resetWiringData,
} = entityAccountWiringSlice.actions;
export default entityAccountWiringSlice.reducer;

export const getWiringDetailsById = (id,navigateAway) => (dispatch) => {
    dispatch(setIsLoadingWiringDetails(true));
    fetcherGet(`${APIS.CWI}/${id}`)
      .then((response) => {
        dispatch(setWiringDetails(response.data));
        dispatch(setIsLoadingWiringDetails(false));
      })
      .catch((error) => {
        if(error?.response?.data?.messageKey==="USER_IS_NOT_AUTHORIZED"){
        navigateAway();
        }
        logger({ error });
        dispatch(setIsLoadingWiringDetails(false));
      });
  };
export const getStatusHistoryByAccountId = (id) => (dispatch) => {
    dispatch(setIsLoadingStatusHistory(true));
    fetcherGet(`${APIS.CWI}/status-history/${id}`)
      .then((response) => {
        dispatch(setStatusHistory(response?.data));
        dispatch(setIsLoadingStatusHistory(false));
      })
      .catch((error) => {
        logger({ error });
        dispatch(setIsLoadingStatusHistory(false));
      });
}

export const addWiringDetails = (payload, pageDetail, successCallback) => (dispatch) => {
    dispatch(setIsAddingWiringDetails(true));
    fetcherPost(`${APIS.CWI}`, payload)
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setIsAddingWiringDetails(false));
        dispatch(getWiringDetailsById(payload?.accountId));
        dispatch(getAccountById(payload?.accountId, pageDetail));
        dispatch(getStatusHistoryByAccountId(payload?.accountId));
        successCallback?.();
      })
      .catch((error) => {
        logger({ error });
        dispatch(setIsAddingWiringDetails(false));
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      });
}

export const updateWiringDetails = (id, payload, pageDetail, successCallback) => (dispatch) => {
    dispatch(setIsUpdatingWiringDetails(true));
    fetcherPut(`${APIS.CWI}/${id}`, payload)
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setIsUpdatingWiringDetails(false));
        dispatch(getWiringDetailsById(payload?.accountId));
        dispatch(getAccountById(payload?.accountId, pageDetail));
        dispatch(getStatusHistoryByAccountId(payload?.accountId));
        successCallback?.();
      })
      .catch((error) => {
        logger({ error });
        dispatch(setIsUpdatingWiringDetails(false));
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      });
}

// Verify API needed
export const verifyDenyWiringDetails = (id, payload, pageDetail, showErrorMsg=false) => (dispatch) => {
  dispatch(setIsVerifyingWiringDetails(true));
    fetcherPut(`${APIS.CWI}/deny-verify/${id}`, payload)
      .then((response) => {

        if(showErrorMsg){
          const data = {
            title: ERROR,
            message1: 'WCI_INCORRECT_PASSPHRASE',
            error: true,
            isDefaultDisplay: false,
          }
          dispatch(setModelMessage(data));
        } else {
          const data = {
            title: SUCCESS,
            message1: response?.messageKey,
            error: false,
            isDefaultDisplay: false,
          };
          dispatch(setModelMessage(data));
          dispatch(setIsVerifyingWiringDetails(false));
          dispatch(getWiringDetailsById(payload?.accountId));
          dispatch(getStatusHistoryByAccountId(payload?.accountId));
          dispatch(getAccountById(payload?.accountId, pageDetail))
        }
        dispatch(setLoader(false))
      })
      .catch((error) => {
        logger({ error });
        dispatch(setIsVerifyingWiringDetails(false));
        if(showErrorMsg){
          const combineError = `${error?.response?.data?.messageKey}_AND_INCORRECT_PASSPHRASE` 
          const data = {
            title: ERROR,
            message1: combineError,
            error: true,
            isDefaultDisplay: false,
          }
          dispatch(setModelMessage(data));
        } else {
          const data = {
            title: ERROR,
            message1: 'INCORRECT_BENEFICIARY_ACCOUNT_NUMBER',
            error: true,
            isDefaultDisplay: false,
          };
          dispatch(setModelMessage(data));
        }
        
      });
}

export const invalidateAllWiringDetails = () => (dispatch) => {
  dispatch(setIsInvalidatingAllWiringDetails(true));
    fetcherPost(`${APIS.CWI}/invalidate`)
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setIsInvalidatingAllWiringDetails(false));
      })
      .catch((error) => {
        logger({ error });
        dispatch(setIsInvalidatingAllWiringDetails(false));
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      });
}

export const resetData = () => (dispatch) => {
    dispatch(resetWiringData());
}
