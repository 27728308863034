export const PURCHASE_LIMIT_COMPONENTS_FLP = {
    CREATE_PURCHASE_LIMIT_WCI: "createPurchaseLimit",
    UPDATE_DEFAULT_PURCHASE_LIMIT_WCI: "updateDefaultPurchaseLimit",
    PURCHASE_LIMIT_TABLE_WCI: "getPurchaseLimitPercent",
    PURCHASE_LIMIT_TABLE_HISTORY_WCI: "getPurchaseLimitHistory",
    VIEW_ENTITY_PURCHASE_LIMIT:"getEntityPurchaseLimit",
    PURCHASE_LIMIT_DROPDOWN:"getPurchaseLimitDropDown",
    EDIT_PURCHASE_LIMIT_PERCENTAGE:"updateEntityPurchaseLimitByPercentage",
    BULK_VALIDATE: "validateEntityPurchaseLimitByValue",
    BULK_UPDTAE: "updateEntityPurchaseLimitByValue"
  };
  export const getFLPHeaders = (componentName) => ({ pageName: "Page_ManagePurchaseLimit", componentName });