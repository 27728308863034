import {
  FormControl, Menu, Button, Grid, MenuItem, Paper, Select, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import {
  DataGridPremium,
  deDE,
  enUS,
  esES,
  frFR,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarColumnsButton,
  GridCell,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
  useKeepGroupedColumnsHidden,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
  getGridStringQuickFilterFn,
} from '@mui/x-data-grid-premium';
import { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  getCurrentPageName,
  getTimeStampForExportedFile,
  PAGE_SIZE,
  PINNED_COLUMN_MAX_WIDTH,
  showOnlyPermittedFields,
  DOWNLOAD_FORMAT,
  excelPostProcess,
  t,
} from 'utils/constants';
import PropTypes from 'prop-types';
import Style, { CommonFontFamily } from './MUITableGrid.style';

const tableGridLocalization = {
  English: enUS,
  French: frFR,
  German: deDE,
  Spanish: esES,
};

const rowsPerPageOptions = [5, 10, 25, 100];

export default function MUITableGrid(props) {
  const language = localStorage?.getItem('i18nextLng');
  const {
    page,
    pageSize = PAGE_SIZE,
    customRowHeight = 42,
    columns = [],
    rows = [],
    isSearch = false,
    isExport = false,
    isRefreshExport = false,
    exportAllColumns = false,
    isSettings = false,
    dataRefreshHandler = null,
    setCurrentPage,
    currentPage,
    fileName = document.title,
    customInitialState = {},
    initialSortedFields = [],
    count = -1,
    access = '',
    cellClick = true,
    isPageChangeAPI = '',
    setPageSize = null,
    handleFilterChange = null,
    pagination = true,
    customTableStyle = {},
    getRowId,
    getRowClassName,
    showFooter = true,
    customLocaleText = {},
    hideColumns = {},
    processRowUpdate,
    className,
    enablePrintView = false,
    cellModesModel,
    onCellModesModelChange,
    onCellClick,
    fieldsMetaData,
    hidePinnedColumns = false,
    customPinnedColumns = { left: ['Name'], right: ['quickactions'] }, // set pinned by default, to change this set prop as per it
    onColumnVisibilityModelChange = () => {
    },
    ...others
  } = props;
  const { apiRef } = props;
  const [gridPageSize, setGridPageSize] = React.useState(pageSize);
  const [rowData, setRowData] = React.useState(rows);
  const [loading, setLoading] = React.useState(true);
  let langText = '';
  const apiRefOptional = useGridApiRef();
  const [tableState, setTableState] = React.useState({});
  const tableRef = useRef();
  const { selectedLanguage } = useSelector(({ jurisdiction }) => jurisdiction);

  // This is for tracking the previous pinned columns state
  const prevPinnedColumns = useRef({ left: [], right: [] });

  React.useEffect(() => {
    prevPinnedColumns.current = apiRefOptional?.current?.getPinnedColumns?.();
  }, []);

  React.useEffect(() => {
    if (setPageSize) {
      setPageSize(gridPageSize);
    }
  }, [gridPageSize]);

  // For filtering columns with Field Level Permissions
  const permittedColumns = useMemo(() => {
    let filteredColumns = [];
    const validPinnedColumns = {};
    const tableColumns = columns.map((col) => ({
      ...(col.type === 'singleSelect' && { getApplyQuickFilterFn: getGridStringQuickFilterFn }),
      ...col,
    }));
    if (Array.isArray(fieldsMetaData) && fieldsMetaData?.length) {
      filteredColumns = showOnlyPermittedFields(tableColumns, fieldsMetaData);
    } else {
      filteredColumns = tableColumns.map((item) => {
        const actionFields = ['quickactions', 'action', 'actions'];

        if (actionFields.includes(item?.field?.toLowerCase())) {
          item.width = 120;
          item.resizable = false;
          delete item.flex;
        }

        return {
          ...item,
          minWidth: item.minWidth || 100,
          maxWidth: 1000,
          groupable: false,
        };
      });
    }
    ;

    ['left', 'right'].forEach((side) => {
      validPinnedColumns[side] = customPinnedColumns?.[side]?.filter((col) => {
        const foundColumn = filteredColumns.find(c => c.field === col);
        if (foundColumn) {
          // Ensure that default pinned columns do not have the option to be unpinned.
          foundColumn.pinnable = false;
          return true;
        }
        return false;
      }) ?? [];

      if (validPinnedColumns[side].length === 0) {
        delete validPinnedColumns[side];
      }
    });

    return { filteredColumns, validPinnedColumns };
  }, [columns, fieldsMetaData]);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef: apiRef || apiRefOptional,
    initialState: {
      sorting: {
        sortModel: [...initialSortedFields],
      },
      pagination: {
        pageSize: PAGE_SIZE,
      },
      columns: {
        columnVisibilityModel: hideColumns && hideColumns.columnVisibilityModel,
      },
      ...(!hidePinnedColumns ? { pinnedColumns: permittedColumns.validPinnedColumns } : {}),
      ...customInitialState,
    },
  });

  React.useEffect(() => {
    setRowData(rows);
    if (rows?.length > 0 || count === 0) {
      setLoading(false);
    }
    if (rows?.length === 0) {
      setLoading(false);
    }
  }, [count, rows]);

  langText = tableGridLocalization[language]?.components.MuiDataGrid.defaultProps.localeText;
  const tableColumns = (apiRef || apiRefOptional)?.current?.getVisibleColumns?.() || [];
  const tableRows = (apiRef || apiRefOptional)?.current?.getVisibleRowModels?.() || new Map();

  const updateColumnMaxWidth = (column) => {
    if (!column) {
      return null;
    }
    const currentColumn = (apiRef || apiRefOptional)?.current?.getColumn?.(column);
    if (currentColumn) {
      (apiRef || apiRefOptional)?.current?.updateColumn?.({
        ...currentColumn,
        maxWidth: PINNED_COLUMN_MAX_WIDTH,
      });
    }
    return currentColumn;
  };

  const handlePinnedColumnChange = (pinnedColumns) => {
    // OnChange of pinned column, use apiRef to set the max width of that column
    if (pinnedColumns?.left?.length > 0) {
      pinnedColumns.left.forEach(column => {
        updateColumnMaxWidth(column);
      });
    }
    if (pinnedColumns?.right?.length > 0) {
      pinnedColumns.right.forEach(column => {
        updateColumnMaxWidth(column);
      });

      // Ensure the initial sticky right columns would always be on extreme right
      const pinnedRightLength = pinnedColumns?.right?.length || 0;
      const initialRightLength = initialState?.pinnedColumns?.right?.length || 0;
      const prevRightLength = prevPinnedColumns?.current?.right?.length || 0;

      if (pinnedRightLength > initialRightLength && pinnedRightLength > prevRightLength) {
        const latestMovedCol = pinnedColumns.right.pop();
        pinnedColumns.right.unshift(latestMovedCol);
      }

      prevPinnedColumns.current = apiRefOptional.current.getPinnedColumns();
    }
  };

  const handleScroll = () => {
    const popperElement = document.querySelector('.MuiPopper-root.MuiDataGrid-panel') || document.querySelector('.base-Popper-root.MuiDataGrid-panel');
    const eleHasAttr = popperElement?.hasAttribute('data-popper-reference-hidden');
    if (popperElement && eleHasAttr && (apiRefOptional)) {
      (apiRefOptional)?.current?.hideFilterPanel();
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Box sx={Style.TableBox}>
      {enablePrintView && (
        <div
          className="printable-table"
          style={{ display: 'none' }}
        >
          <Paper
            className="datatable-main"
            sx={{ width: '100%', overflow: 'hidden' }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableColumns.map(({ field, headerName }) => (
                      <TableCell key={field}>{headerName || field}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from(tableRows, ([, rowData]) => ({ ...rowData }))
                    .slice(
                      tableState?.pagination?.page ?? 0 * (tableState?.pagination?.pageSize ?? 0),
                      (tableState?.pagination?.page ?? 0 * (tableState?.pagination?.pageSize ?? 0)) +
                      (tableState?.pagination?.pageSize ?? 0),
                    )
                    .map(({ id }) => (
                      <TableRow key={id}>
                        {tableColumns.map(({ field }) => {
                          if (!id) return <TableCell />;
                          const cellValue = (apiRef || apiRefOptional)?.current?.getCellValue(id, field);
                          return <TableCell key={field}>{typeof cellValue !== 'object' && cellValue}</TableCell>;
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
      <DataGridPremium
        sx={{
          ...Style.TableGrid,
          ...(tableState?.rowsMeta?.currentPageTotalHeight === 0 && Style.NoRows),
          ...(enablePrintView && Style.PrintTableGrid),
          ...customTableStyle,
        }}
        rows={rowData}
        headerHeight={customRowHeight}
        rowHeight={customRowHeight}
        autoHeight
        disableColumnReorder // to disable reordering columns
        disableRowGrouping // to disable Group By
        disableSelectionOnClick
        onPinnedColumnsChange={handlePinnedColumnChange}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        columns={permittedColumns.filteredColumns}
        getRowClassName={getRowClassName}
        getRowId={getRowId}
        apiRef={apiRef || apiRefOptional}
        loading={loading}
        initialState={initialState}
        filterMode={handleFilterChange ? 'server' : 'client'}
        onFilterModelChange={handleFilterChange}
        pagination={pagination}
        pageSize={gridPageSize}
        components={{
          Toolbar: CustomToolbar, Footer: CustomFooter,
          Cell: CustomCell,
        }}
        componentsProps={{
          toolbar: {
            isSettings,
            isExport,
            isSearch,
            fileName,
            dataRefreshHandler,
            isRefreshExport,
            apiRefOptional,
            selectedLanguage,
            apiRef,
          },
          footer: { gridPageSize, setGridPageSize, pagination, showFooter, tableRef },
          baseCheckbox: {
            tabIndex: 0,
            onKeyDown: (event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                event.target.click();
              }
            },
          },
          baseSwitch: {
            onKeyDown: (event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                event.target.click();
              }
            },
          },
          panel: {
            sx: {
              '& .MuiDataGrid-filterFormColumnInput': {
                minWidth: '150px',
              },
              '& .MuiDataGrid-filterFormOperatorInput': {
                minWidth: '120px',
              },
            },
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        localeText={{ ...langText, ...customLocaleText }}
        processRowUpdate={processRowUpdate}
        className={className}
        onStateChange={setTableState}
        cellModesModel={cellModesModel}
        onCellModesModelChange={onCellModesModelChange}
        onCellClick={onCellClick}
        ref={tableRef}
        {...others}
      />
    </Box>
  );
}

function CustomExportMenuItem(props) {
  const toolbarRef = useGridApiContext();

  const { hideMenu, fileName, apiRefOptional, selectedLanguage, apiRef } = props;

  function exceljsPreProcess({ worksheet }) {
    worksheet.name = `${fileName}_${getTimeStampForExportedFile()}`; // Modify worksheet name
  }

  function exceljsPostProcess({ worksheet, workbook }) {
    excelPostProcess(workbook, worksheet, apiRef || apiRefOptional, selectedLanguage);
  }

  const handleScroll = () => {
    const menuElement = document.querySelector('.base-Popper-root.MuiDataGrid-menu') || document.querySelector('.MuiPopper-root.MuiDataGrid-menu');
    const menuEleHasAttr = menuElement?.hasAttribute('data-popper-reference-hidden');
    if (menuElement && menuEleHasAttr) {
      hideMenu?.();
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (

    <>
      <GridCsvExportMenuItem
        options={{
          fileName: `${props.fileName}_${getTimeStampForExportedFile()}`,
          allColumns: props.exportAllColumns,
          utf8WithBom: true,
        }}
        hideMenu={props.hideMenu}
        disabled={toolbarRef?.current?.getVisibleRowModels()?.size === 0 || toolbarRef?.current?.getVisibleColumns()?.length === 0}
        printOptions={{ disableToolbarButton: true }}
        sx={CommonFontFamily}
        autoFocus
      />
      <GridExcelExportMenuItem
        options={{
          fileName: `${props.fileName}_${getTimeStampForExportedFile()}`,
          allColumns: props.exportAllColumns,
          exceljsPreProcess,
          exceljsPostProcess,
        }}
        hideMenu={props.hideMenu}
        disabled={toolbarRef?.current?.getVisibleRowModels()?.size === 0 || toolbarRef?.current?.getVisibleColumns()?.length === 0}
        printOptions={{ disableToolbarButton: true }}
        sx={CommonFontFamily}
      />
    </>
  );
};

CustomExportMenuItem.propTypes = {
  hideMenu: PropTypes.func,
};

function CustomToolbar(props) {
  const toolbarRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t: t1 } = useTranslation(getCurrentPageName());

  return (
    <GridToolbarContainer
      sx={[
        Style.Table,
        {
          justifyContent: 'space-between',
          display: !(props.isSearch || props.isExport || props.isSearch) ? 'none' : '',
        },
      ]}
    >
      <Box>
        {props.isSettings && (
          <>
            <GridToolbarColumnsButton sx={CommonFontFamily} />
            <GridToolbarFilterButton sx={CommonFontFamily} />
          </>
        )}
        {props.isExport && (
          <GridToolbarExportContainer>
            <CustomExportMenuItem {...props} />
          </GridToolbarExportContainer>
        )}
        {props.isRefreshExport && (
          <>
            <Button sx={{ paddingLeft: '1px' }}
                    disabled={toolbarRef?.current?.getVisibleRowModels()?.size === 0 || toolbarRef?.current?.getVisibleColumns()?.length === 0}
                    className="regular-label-weight" onClick={(e) => setAnchorEl(e.currentTarget)}>
              <FileDownloadOutlinedIcon />
              <Typography sx={{ fontWeight: '400', fontSize: '0.875rem', paddingLeft: '4px' }}>
                {t(t1, 'EXPORT')}
              </Typography>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              MenuListProps={{ 'aria-labelledby': 'basic-button' }}
              onClose={() => setAnchorEl(null)}
              open={open}
            >
              <MenuItem onClick={() => {
                props.dataRefreshHandler(DOWNLOAD_FORMAT.CSV);
              }}>
                {t(t1, 'DOWNLOAD_AS_CSV')}
              </MenuItem>
              <MenuItem onClick={() => {
                props.dataRefreshHandler(DOWNLOAD_FORMAT.EXCEL);
              }}>
                {t(t1, 'DOWNLOAD_AS_EXCEL')}
              </MenuItem>
            </Menu>
          </>
        )}

      </Box>
      {props.isSearch && (
        <Box sx={{ display: 'flex', ...CommonFontFamily }}>
          <GridToolbarQuickFilter
            sx={CommonFontFamily}
            debounceMs={1000}
            quickFilterParser={(searchInput) => [searchInput.trim()]}
            quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(' ')}
          />
        </Box>
      )}
    </GridToolbarContainer>
  );
}

function CustomCell(props) {
  return (<GridCell title={props.formattedValue || props.value} {...props} />
  );
}

function CustomFooter(props) {
  const { gridPageSize, setGridPageSize, pagination, showFooter = true, tableRef } = props;
  const { t: t1 } = useTranslation(getCurrentPageName());
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const count = apiRef?.current?.getVisibleRowModels().size;
  const onPageChange = useCallback(() => {
    if (tableRef && tableRef.current) {
      if (tableRef.current?.getBoundingClientRect()?.top < 0) {
        setTimeout(() => tableRef.current.scrollIntoView(), 500);
      }
    }
  }, [tableRef]);

  return (
    <Grid
      container
      sx={Style.Pagination}
    >
      <Grid
        item
        sx={{ display: 'flex', alignItems: 'center', ml: 1 }}
      >
        {showFooter && (
          <Typography sx={{ color: '#4D8DA4', fontSize: '14px', fontWeight: 600 }}>
            {t(t1, 'MUI_TOTAL_ROWS')} : {count}
          </Typography>
        )}
      </Grid>
      {pagination && (
        <>
          <Grid
            item
            sx={{ margin: 'auto 0px', pl: 9 }}
          >
            <Pagination
              color="standard"
              showFirstButton
              showLastButton
              count={pageCount}
              pagesize={12}
              page={page + 1}
              onChange={(event, value) => {
                apiRef.current.setPage(value - 1);
                onPageChange();
              }}

            />
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
            <Typography
              variant="body1"
              sx={{ color: '#4D8DA4', fontSize: '14px', fontWeight: 600, mr: 1 }}
            >
              {t(t1, 'MUI_ROWS_PER_PAGE')} :
            </Typography>
            <FormControl size="small">
              <Select
                sx={{
                  '& .MuiSelect-select': {
                    padding: '6px 30px 6px 12px',
                  },
                }}
                value={gridPageSize}
                onChange={(e) => {
                  setGridPageSize(e.target.value);
                  onPageChange();
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {rowsPerPageOptions &&
                  rowsPerPageOptions?.map((value, index) => (
                    <MenuItem
                      value={value}
                      key={index}
                    >
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
}
