import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getCurrentPageName,
  t,
} from "../../utils/constants";

export function EmailLanguagePreferenceSelector(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  return (
    <Controller
      control={props.methods.control}
      name={`languagePage`}
      rules={props.rules}
      render={({ field: { onChange, onBlur, value, ...field }, fieldState }) => (
        <>
          <Autocomplete
            id="language_preference"
            options={props?.languages?.map((item) => ({ name: item.name, value: item.languageCode })) || []}
            onChange={(_, data) => {
              onChange(data);
              props.onChangeValue(data?.value);
              return data?.name;
            }}
            value={value}
            disabled={props.disabled}
            getOptionLabel={(item) => (item.name ? item.name : "")}
            renderInput={(params) => (
              <TextField
                error={Boolean(fieldState?.error)}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: t(t1, 'SELECT_LANGUAGE'),
                }}
                margin="normal"
                variant="outlined"
                size="small"
                sx={{ mt: 0, width: '347px', mb: 0 }}
              />
            )}
          />

          {props.rules && (
            <FormHelperText error>
              {fieldState?.error ? fieldState?.error?.message : " "}
            </FormHelperText>
          )}
        </>
      )}
    />
  )
}