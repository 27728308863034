import { FieldLabel } from "config/common.styles";

const Style = {
  otherCard:{paddingBottom:"20px"},
  marginBottomForFormCard: { marginBottom: "20px !important", paddingBottom:"20px", minHeight:"181px" },
  FieldLabel: {
    ...FieldLabel,
  },
  formField: {
    width: 1,
    color: "common.textColor",
    ".MuiOutlinedInput-input": {
      py: 0.6,
      fontSize: "14px",
      "& .MuiTypography-root": {
        fontSize: "14px"
      }
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderRadius: "0",
      borderColor: "common.GrayColor",
    },
    "&.MuiOutlinedInput-root": {
      "&.MuiInputBase-root.Mui-focused": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#333",
          borderWidth: 1,
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: '4px'
    },
    "& .Mui-error": {
      marginLeft: 0
    }
  },
  inputStyle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
};

export default Style;
