import { isEmpty, uniqBy } from "lodash";
import { JURISDICTION_STATUS_FE } from "utils/constants";

export const TRANSFER_CARD = {
  MANAGE_ALL_TRANSFERS: "MANAGE_ALL_TRANSFERS",
  MANAGE_TRANSFERS: "MANAGE_TRANSFERS",
};

export const INTER_JURISDICTIONAL_TRANSFERS = {
  INTER_JURISDICTIONAL: "INTER_JURISDICTIONAL",
  CROSS_JURISDICTIONAL: "CROSS_JURISDICTIONAL",
};

export function getTransformedLinkedJuri(data, jurisdictionId) {
  if (isEmpty(data)) return [];
  const filteredData = data
    .filter((i) => i?.id !== jurisdictionId)
    ?.map((item, idx) => ({
      id: item?.id || idx + 1,
      name: item?.name,
      status: JURISDICTION_STATUS_FE.LINKED,
    }));
  return uniqBy(filteredData, "id");
}

export const getIdToPass = (data) => (isEmpty(data) ? "" : data?.[0]?.id);
