import { Loadable } from "components/Loadable";
import { lazy } from "react";

const ManageTransfers = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.ManageTransfers,
    }))
  )
);

export const manageTransfersRoutes = {
  path: "/manage-transfers",
  children: [
    {
      path: ":jurisdictionId",
      index: true,
      element: <ManageTransfers />,
    },
  ],
};
