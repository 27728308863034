
import Tooltip, {  tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';





export const CustomizeToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
     ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: '#000',
      // minWidth: 800,
      // maxWidth: 800,
      fontSize: theme.typography.pxToRem(15),
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontWeight:'300'
    },
  }));