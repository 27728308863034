import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  COUNTRIES_WITH_STATES,
  t,
} from "utils/constants";
import Style from "./ApplicationChecklist.style";

const PageName = "Page_ApplicationWithAttenstation";
const EntityRegistrationPage = "Page_EntityRegistration";

function EntityAddress({ entityData }) {
  const {
    physicalAddress,
    mailingAddress,
    proposeChanges,
  } = entityData || {};

  const { 
    physical: proposedPhysical, 
    mailing: proposedMailing, 
  } = proposeChanges || {};

  const physicalStreet1 = proposedPhysical?.street1 || physicalAddress?.street1
  const physicalStreet2 = proposedPhysical?.street2 || physicalAddress?.street2
  const physicalCity = proposedPhysical?.city || physicalAddress?.city
  const physicalState = proposedPhysical?.state?.name || physicalAddress?.state?.name
  const physicalRegion = proposedPhysical?.region || physicalAddress?.region
  const physicalPostalCode = proposedPhysical?.postalCode || physicalAddress?.postalCode
  const physicalCountry = proposedPhysical?.country?.name || physicalAddress?.country?.name

  const mailingStreet1 = proposedMailing?.street1 || mailingAddress?.street1
  const mailingStreet2 = proposedMailing?.street2 || mailingAddress?.street2
  const mailingCity = proposedMailing?.city || mailingAddress?.city
  const mailingState = proposedMailing?.state?.name || mailingAddress?.state?.name
  const mailingRegion = proposedMailing?.region || mailingAddress?.region
  const mailingPostalCode = proposedMailing?.postalCode || mailingAddress?.postalCode
  const mailingCountry = proposedMailing?.country?.name || mailingAddress?.country?.name

  const { t: t1 } = useTranslation(PageName);
  const { t: tEntityRegistration } = useTranslation(EntityRegistrationPage);
  return <>
    <Typography sx={Style.entityIdentification}>{t(t1, "PHYSICAL_ADDRESS")}</Typography>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "ADDRESS_LINE_1")}</Typography>
      <Typography sx={Style.entryValue}>{physicalStreet1}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "ADDRESS_LINE_2")}</Typography>
      <Typography sx={Style.entryValue}>{physicalStreet2}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "CITY")}</Typography>
      <Typography sx={Style.entryValue}>{physicalCity}</Typography>
    </Box>
    {COUNTRIES_WITH_STATES.includes(physicalCountry) ?
      <Box sx={Style.entry}>
        <Typography sx={Style.entryAttribute}>{t(t1, "PROVINCE_OR_STATE")}</Typography>
        <Typography sx={Style.entryValue}>{t(tEntityRegistration, physicalState) || "NA"}</Typography>
      </Box>
      :
      <Box sx={Style.entry}>
        <Typography sx={Style.entryAttribute}>{t(tEntityRegistration, "REGION")}</Typography>
        <Typography sx={Style.entryValue}>{physicalRegion}</Typography>
      </Box>
    }
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "POSTCODE_OR_ZIP_CODE")}</Typography>
      <Typography sx={Style.entryValue}>{physicalPostalCode}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "COUNTRY")}</Typography>
      <Typography sx={Style.entryValue}>{t(tEntityRegistration, physicalCountry) || "NA"}</Typography>
    </Box>

    <Typography sx={Style.entityIdentification}>{t(t1, "MAILING_ADDRESS")}</Typography>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "ADDRESS_LINE_1")}</Typography>
      <Typography sx={Style.entryValue}>{mailingStreet1}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "ADDRESS_LINE_2")}</Typography>
      <Typography sx={Style.entryValue}>{mailingStreet2}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "CITY")}</Typography>
      <Typography sx={Style.entryValue}>{mailingCity}</Typography>
    </Box>
    {COUNTRIES_WITH_STATES.includes(mailingCountry) ?
      <Box sx={Style.entry}>
        <Typography sx={Style.entryAttribute}>{t(t1, "PROVINCE_OR_STATE")}</Typography>
        <Typography sx={Style.entryValue}>{t(tEntityRegistration, mailingState) || "NA"}</Typography>
      </Box>
      :
      <Box sx={Style.entry}>
        <Typography sx={Style.entryAttribute}>{t(tEntityRegistration, "REGION")}</Typography>
        <Typography sx={Style.entryValue}>{mailingRegion}</Typography>
      </Box>
    }
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "POSTCODE_OR_ZIP_CODE")}</Typography>
      <Typography sx={Style.entryValue}>{mailingPostalCode}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "COUNTRY")}</Typography>
      <Typography sx={Style.entryValue}>{t(tEntityRegistration, mailingCountry) || "NA"}</Typography>
    </Box>
  </>;
}

export default EntityAddress;
