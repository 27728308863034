import { Grid, Box } from "@mui/material";
import { GridTextfield } from "components";
import { CustomizeButton } from "components/CustomizeButton";
import { FormProvider } from "components/FormProvider";
import { useJurisdictionUserRole } from "hooks/useUserRole";
import { StyledCardTitle, StyledForm } from "pages/ManageBatchTransfer/ManageBatchTransfer.style";
import { BATCH_TRANSFER_COMPONENTS_FLP, isEditable } from "pages/ManageBatchTransfer/constants";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { TextTruncate } from "components/TextTruncate";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getBatchandAccountDetailsById, setIsEditMode, updateTransferBatch } from "store/batchTransferSlice";
import { setModelMessage } from "store/commonSlice";
import {
  BATCH_TRANSFER_STATUS,
  ERROR,
  getCurrentPageName,
  isEmpty,
  t,
  COMMENT_MAX_CHAR_LENGTH,
  TEXT_MAX_CHAR_LENGTH,
} from "utils/constants";
import { CustomeDialogMessage } from "components/CustomeDialogMessage";
import usePermissions from "hooks/usePermissions";

/**
 * Batch details for the selected Batch Transfer
 * @returns {ReactElement}
 */
function BatchTransferInformationForm({ methods, Status, batchName, comment, transferType }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { isAdmin } = useJurisdictionUserRole();
  const { t: t1 } = useTranslation(getCurrentPageName());
  const [editMode, setEditMode] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const formRef = useRef(null);

  const batchTransferDetailsPermission = usePermissions(BATCH_TRANSFER_COMPONENTS_FLP.BATCH_ACCOUNT_DETAILS);

  const {
    reset,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
  } = methods;

  useEffect(() => {
    reset({ batchName, comment });
  }, [batchName, comment, reset, editMode]);

  useEffect(() => {
    setEditMode(Status.name === BATCH_TRANSFER_STATUS.ACTIVE);
  }, [Status.name]);

  const onSubmit = useCallback(() => {
    if (isEmpty(errors) && Status.name !== BATCH_TRANSFER_STATUS.ACTIVE) {
      const payload = {
        ...getValues(),
        status: Status?.ID,
      };
      dispatch(updateTransferBatch({ id: params?.id, payload, transferTypeId: transferType?.id }))
        .then(() => {
          setEditMode(false);
          dispatch(setIsEditMode({ key: "editBatchDetails", value: false }));
          dispatch(getBatchandAccountDetailsById(params.id));
        })
        .catch((e) => {
          const data = {
            title: ERROR,
            message1: t(t1, e?.response?.data?.data),
            error: true,
            isDefaultDisplay: false,
          };
          dispatch(setModelMessage(data));
        });
    }
  }, [Status?.ID, Status.name, dispatch, errors, getValues, params.id, t1, transferType?.id]);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box ref={formRef}>
        <StyledForm style={{ paddingBottom: Status?.name !== BATCH_TRANSFER_STATUS.ACTIVE && editMode ? "40px" : "20px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <StyledCardTitle variant="h2">{t(t1, "BATCH_TRANSFER_INFORMATION")}</StyledCardTitle>
            {!editMode && !!isAdmin && isEditable(Status.name) && (
              <CustomizeButton
                variant="contained"
                disableElevation
                label={t(t1, "EDIT")}
                name="priv_modal_Edit"
                handleClick={() => {
                  setEditMode(true);
                  dispatch(setIsEditMode({ key: "editBatchDetails", value: true }));
                  formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }}
              />
            )}
          </Box>
          <Box
            sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", sm: "1fr 2fr" } }}
            gap={2.5}
          >
            {batchTransferDetailsPermission?.readOnly?.batchName && (
              !editMode ?
                <Box sx={{ paddingTop: "20px" }}>
                  <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>{t(t1, "BATCH_NAME_LABEL")}</Typography>
                  <TextTruncate content={getValues().batchName || batchName || "N/A"} variant="subtitle2" />
                </Box>
                :
                <GridTextfield
                  gridMd={4}
                  type="text"
                  name="batchName"
                  label={t(t1, "BATCH_NAME_LABEL")}
                  placeholder={t(t1, "BATCH_NAME_PLACEHOLDER")}
                  rules={{
                    ...(editMode && {
                      required: true,
                      maxLength: {
                        value: TEXT_MAX_CHAR_LENGTH,
                        message: `${t(t1, "ERR_BATCH_NAME_MAX_LENGTH_CHAR")}`,
                      },
                    }),
                  }}
                  isRequiredMsg={'ERR_BATCH_NAME_LABEL_IS_REQUIRED'}
                  trimLeadingSpaces
                  disabled={!batchTransferDetailsPermission?.write?.batchName}
                />
            )}
            {batchTransferDetailsPermission?.readOnly?.comment && (
              !editMode ?
                <Box sx={{ paddingLeft: "20px", paddingTop: "20px", wordBreak: "break-all" }}>
                  <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>{t(t1, "COMMENTS_LABEL")}</Typography>
                  <TextTruncate content={getValues().comment || comment || "N/A"} variant="subtitle2" />
                </Box>
                :
                <GridTextfield
                  gridMd={8}
                  type="text"
                  multiline
                  rows={2}
                  name="comment"
                  label={t(t1, "COMMENTS_LABEL")}
                  placeholder={t(t1, "COMMENTS_PLACEHOLDER")}
                  rules={{
                    maxLength: {
                      value: COMMENT_MAX_CHAR_LENGTH,
                      message: `${t(t1, "ERR_COMMENTS_MAX_LENGTH_CHAR")}`,
                    },
                  }}
                  trimLeadingSpaces
                  isViewOnly={!editMode}
                  customTextFieldStyle={{
                    "& .MuiFormHelperText-root": {
                      height: "20px",
                    },
                  }}
                  sx={{ paddingBottom: "20px" }}
                  disabled={!batchTransferDetailsPermission?.write?.batchName}
                />
            )}
          </Box>
          {Status.name !== BATCH_TRANSFER_STATUS.ACTIVE && editMode && (
            <Grid
              container
              justifyContent="end"
            >
              <Grid item>
                <CustomizeButton
                  variant="outlined"
                  csName="rc"
                  handleClick={() => {
                    setOpenConfirmDialog(true);
                  }}
                  label={t(t1, "CANCEL_BUTTON")}
                />
              </Grid>
              <Grid item>
                <CustomizeButton
                  label={t(t1, "SAVE_EDIT_BUTTON")}
                  type="submit"
                  disabled={!isEmpty(errors) || !isDirty}
                  customStyle={{
                    padding: 0,
                    width: "150px",
                    lineHeight: 1,
                    ml: '8px'
                  }}
                />
              </Grid>
            </Grid>
          )}
          <CustomeDialogMessage
            title={t(t1, "CANCEL_CREATE_MODAL_TITLE")}
            continueBtn={t(t1, "CONTINUE")}
            open={openConfirmDialog}
            handleModalCancel={() => {
              setOpenConfirmDialog(false);
            }}
            handleClose={() => {
              setOpenConfirmDialog(false);
            }}
            message1={t(t1, "CONFIRM_DELETE_MESSAGE")}
            handleContinue={() => {
              reset();
              setEditMode(false);
              dispatch(setIsEditMode({ key: "editBatchDetails", value: false }));
              setOpenConfirmDialog(false);
            }}
            borderColor="#CA2C1C"
            warning
          />
        </StyledForm>
      </Box>
    </FormProvider>
  );
}

export default BatchTransferInformationForm;
