import { LoadingScreen } from "components/LoadingScreen";
import { Suspense } from "react";
import { useLocation } from "react-router-dom";

export const Loadable = (Component) =>
   // eslint-disable-next-line func-names
   function(props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}>
        <Component {...props} />
      </Suspense>
    );
  };
