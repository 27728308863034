import { createSlice } from '@reduxjs/toolkit';
import {
  APIS,
  ERROR,
  JURISDICTION_STATUS,
  MSG_ERR_INTERNAL_SERVER,
  MSG_ERR_USER_BLOCKED,
  SUCCESS,
  t,
  unzip,
  USER_ID_OR_PASSPHRASE_ENTERED_IS_INVALID,
} from 'utils/constants';
import fetcher from 'utils/fetcher';
import sleep from 'utils/sleep';

import { setLoader, setModelMessage } from './commonSlice';
import { DB_CONSTANT } from '../utils/databaseConstants';

const initialState = {
  selectedUserDetails: {},
  searchUserDetails: {},
  duplicateUserDetails: {},
  approveRegistration: {},
  searchPendingUserDetails: {},
  userStatusHistory: {},
  userRoleHistory: {},
  denialReasons: {},
  routeState: {
    path: '',
    toggle: false,
  },
  accountStatusHistory: [],
  loadingUserDetails: false,
  initialUsers: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedUserDetails: (state, action) => {
      state.selectedUserDetails = action.payload;
    },
    setSearchUserRegDetails: (state, action) => {
      state.searchUserDetails = action.payload;
    },
    setSearchPendingUserRegDetails: (state, action) => {
      state.searchPendingUserDetails = action.payload;
    },
    setDuplicateUserDetails: (state, action) => {
      state.duplicateUserDetails = action.payload;
    },
    setApproveRegistration: (state, action) => {
      state.approveRegistration = action.payload;
    },
    setUserStatusHistory: (state, action) => {
      state.userStatusHistory = action.payload;
    },
    setUserRoleHistory: (state, action) => {
      state.userRoleHistory = action.payload;
    },
    setDenialReasons: (state, action) => {
      state.denialReasons = action.payload;
    },
    setInitialUsersJuriHome: (state, action) => {
      state.initialUsers = action.payload;
    },

    setRouteState: (state, action) => {
      state.routeState = {
        path: action.payload?.path,
        toggle: action.payload?.toggle,
      };
    },
    setAccountStatusHistory: (state, action) => {
      state.accountStatusHistory = action.payload;
    },
    setLoadingUserDetails: (state, action) => {
      state.loadingUserDetails = action.payload;
    },
  },
});

export const {
  setSelectedUserDetails,
  setSearchUserRegDetails,
  setDuplicateUserDetails,
  setSearchPendingUserRegDetails,
  setApproveRegistration,
  setUserStatusHistory,
  setUserRoleHistory,
  setRouteState,
  setAccountStatusHistory,
  setLoadingUserDetails,
  setInitialUsersJuriHome,
} = userSlice.actions;
export default userSlice.reducer;

// To fetch user details when selected from DataGrid Table by jurisdiction admin.
export function getSelectedUserDetails(UID) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(
      `registration/registrationstatus?UID=U%23${UID}&UType=User&LanguageCode=${localStorage.getItem('languageCode')}`,
      'GET',
      {},
      false,
    )
      .then((response) => {
        dispatch(setSelectedUserDetails(response.Items[0]));
        dispatch(setLoader(false));
      })
      .catch(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getSearchUserDetailsAllAndPending(params) {
  return (dispatch) => {
    dispatch(setLoadingUserDetails(true));
    const pageDetails = { pageName: 'Page_JurisdictionUserHome', componentName: 'searchUsers' };
    fetcher(`registration/search_users`, 'POST', params, false, pageDetails)
      .then(async (response) => {
        const finalParsedResponse = JSON.parse(unzip(response));
        await dispatch(setSearchUserRegDetails(finalParsedResponse));
        const pendingUsersItems = finalParsedResponse.items.filter(userDetails => (
            DB_CONSTANT.ACTIVE_STATUS.toUpperCase() === userDetails.UserAccountStatus &&
            DB_CONSTANT.PENDING_STATUSES.includes(userDetails.RequestStatus)) ||
          DB_CONSTANT.PENDING_STATUS.toUpperCase() === userDetails.ApplicantAccountStatus);
        await dispatch(setSearchPendingUserRegDetails({ items: pendingUsersItems, count: pendingUsersItems.length }));
        dispatch(setLoader(false));
        dispatch(setLoadingUserDetails(false));
      })
      .catch(() => {
        dispatch(setLoadingUserDetails(false));
      });
  };
}

export function getSearchUserDetails(params) {
  return (dispatch) => {
    dispatch(setLoadingUserDetails(true));
    const pageDetails = { pageName: 'Page_JurisdictionUserHome', componentName: 'searchUsers' };
    fetcher(`registration/search_users`, 'POST', params, false, pageDetails)
      .then(async (response) => {
        const finalParsedResponse = JSON.parse(unzip(response));
        if (params?.search_pending) {
          await dispatch(setSearchPendingUserRegDetails(finalParsedResponse));
        } else {
          await dispatch(setSearchUserRegDetails(finalParsedResponse));
        }
        dispatch(setLoader(false));
        dispatch(setLoadingUserDetails(false));
      })
      .catch(() => {
        dispatch(setLoadingUserDetails(false));
      });
  };
}

export function getDuplicateUsers(UID) {
  return (dispatch) => {
    const pageDetails = { pageName: 'Page_JurisdictionUserActions', componentName: 'getDuplicateUsers' };
    fetcher(`registration/duplicate-users/${UID}`, 'GET', {}, false, pageDetails)
      .then((response) => {
        dispatch(setDuplicateUserDetails(response?.items));
      })
      .catch(() => {
        setDuplicateUserDetails({});
      });
  };

}

export function getInitialUsersJuriHomeAPI(jurisdictionId) {
  return (dispatch) => {
    fetcher(`${APIS.INITIAL_USERS_JURI_HOME}/${jurisdictionId}`, 'GET', {}, false)
      .then((response) => {
        dispatch(setInitialUsersJuriHome(response));
      }).catch(() => {
      dispatch(setInitialUsersJuriHome([]));
    });
  };

}

export function approveUserRegistration(UID, status, params, navigate, navigateUrl = '/jurisdiction-user') {
  return (dispatch) => {
    dispatch(setLoader(true));
    const pageMappings = {
      CrossJurisdictionRequest: 'Page_CrossJurisdiction',
      CrossJurisdictionRequestCancel: 'Page_CrossJurisdiction',
      ChangeJurisdictionRequest: 'Page_ChangeJusrisdiction',
      CancelChangeJurisdictionRequest: 'Page_ChangeJusrisdiction',
      DenyChangeJurisdictionRequest: 'Page_ChangeJusrisdiction',
      ApproveChangeJurisdictionRequest: 'Page_ChangeJusrisdiction',
    };
    const componentMappings = {
      Approved: 'initiateApproveJurisdiction',
      Cancel: 'initiateCancelRegistration',
      RequestRevision: 'initiateRequestRevision',
      Pending: 'initiateRequestPending',
      ChangeJurisdictionRequest: 'userChangeJurisdiction',
      CrossJurisdictionRequest: 'userCrossJurisdiction',
      ApproveChangeJurisdictionRequest: 'userChangeJurisdiction',
      ApproveCrossJurisdictionRequest: 'userCrossJurisdiction',
      DenyChangeJurisdictionRequest: 'userChangeJurisdiction',
      DenyCrossJurisdictionRequest: 'userCrossJurisdiction',
      CancelChangeJurisdictionRequest: 'userChangeJurisdiction',
      CrossJurisdictionRequestCancel: 'userCrossJurisdiction',
      UnshareCrossJurisdictionRequest: 'userCrossJurisdiction',
      Deny: 'initiateDenyUser',
      CancelEmailAndTelephoneRequest: 'initiateDenyUser',
      DenyEmailAndTelephoneRequest: 'initiateDenyUser',
      ApprovedEmailAndTelephoneRequest: 'approveEmailTelephone',
    };
    const pageDetails = {
      pageName: pageMappings[status] || 'Page_JurisdictionUserActions',
      componentName: componentMappings[status],
    };
    const url = `registration/registrationstatus/U%23${UID}/${status}`;
    fetcher(
      url,
      'PUT',
      {
        ...params,
        LanguageCode: localStorage.getItem('languageCode'),
      },
      false,
      pageDetails,
    )
      .then((response) => {
        dispatch(setApproveRegistration(response));
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
        navigate(navigateUrl);
      })
      .catch((err) => {
        const data = {
          title: ERROR,
          message1: err ? err?.response?.data?.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      });
  };
}

// This api is called when ju user updates user's role and chooses unshare profile option
// at the same, so this combined api is getting called in this scenario
export function updateRoleWithUnshareRequest(UID, status, unsharePayload, updateRolePayload, navigate) {
  return (dispatch) => {
    dispatch(setLoader(true));
    const pageDetails = { pageName: 'Page_JurisdictionUserActions', componentName: 'userCrossJurisdiction' };
    fetcher(
      `registration/registrationstatus/U%23${UID}/${status}`,
      'PUT',
      {
        unsharePayload,
        updateRolePayload,
        LanguageCode: localStorage.getItem('languageCode'),
      },
      false,
      pageDetails,
    )
      .then((response) => {
        dispatch(setLoader(false));
        dispatch(setApproveRegistration(response));
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        dispatch(setModelMessage(data));
        navigate('/jurisdiction-user');
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err?.response?.data?.message || err?.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function approveUserUpdateRole(UID, params, navigate) {
  return (dispatch) => {
    dispatch(setLoader(true));
    const pageDetails = { pageName: 'Page_JurisdictionUserActions', componentName: 'initiateApproveJurisdiction' };
    fetcher(
      `registration/registrationstatus/U%23${UID}/UpdateRole`,
      'PUT',
      {
        ...params,
        LanguageCode: localStorage.getItem('languageCode'),
      },
      false,
      pageDetails,
    )
      .then((response) => {
        dispatch(setLoader(false));
        dispatch(setApproveRegistration(response));
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        dispatch(setModelMessage(data));
        navigate('/jurisdiction-user');
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err?.response?.data?.message || err?.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function getUsersHistory(params, flpData) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`actionrequest/user-history`, 'POST', params, false, flpData)
      .then((response) => {
        if (params.HType === 'StatusHistory') {
          dispatch(setUserStatusHistory({}));
          dispatch(setUserStatusHistory(response.Items ? response.Items : []));
        } else if (params.HType === 'AccountStatusHistory') {
          dispatch(setAccountStatusHistory([]));
          dispatch(setAccountStatusHistory(response?.Items || []));
        } else if (params.HType === 'RoleHistory') {
          dispatch(setUserRoleHistory({}));
          dispatch(setUserRoleHistory(response.Items ? response.Items : []));
        }
        dispatch(setLoader(false));
      })
      .catch(() => {
        dispatch(setLoader(false));
      });
  };
}

// ===============================for reset passphrase and security qa===================
export function userResetPassphraseAndSecurityQA(UID, navigate, orgId, isAPICall) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`public/resetpassphrase/U%23${UID}`, 'POST', false)
      .then((response) => {
        dispatch(setLoader(false));
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        dispatch(setModelMessage(data));
        if (data && isAPICall) {
          dispatch(getSearchUserDetails({ OrgID: orgId, search_pending: true }));
          dispatch(getSearchUserDetails({ OrgID: orgId }));
        } else {
          navigate('/jurisdiction-user');
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function userResetPassphraseAndSecurityQAVerify(UID, payload, callBack, checkPasswordHistory) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`public/resetpassphrase/verify/U%23${UID}`, 'POST', payload, false)
      .then((response) => {
        checkPasswordHistory(UID, payload?.NewPassword, false)
          .then(() => {
            dispatch(setLoader(false));
            const data = {
              title: SUCCESS,
              message1: response.message,
              error: false,
            };
            dispatch(setModelMessage(data));
            callBack();
          })
          .catch((error) => {
            console.log(error);
            dispatch(setLoader(false));
            const data = {
              title: ERROR,
              message1: error?.response?.data?.errors,
              error: true,
            };
            dispatch(setModelMessage(data));
          });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

// This API function is used to update param like telephone and email for user
export function userUpdateEmailOrTelephone(params, navigate, orgId, isAPICall, flpData) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`actionrequest/user-history`, 'PUT', params, false, flpData)
      .then((response) => {
        dispatch(setLoader(false));
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        dispatch(setModelMessage(data));
        if (data && isAPICall) {
          dispatch(getSearchUserDetails({ OrgID: orgId, search_pending: true }));
          dispatch(getSearchUserDetails({ OrgID: orgId }));
        } else {
          navigate('/jurisdiction-user');
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err.response?.data?.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function userUnblockLockedUser(params, navigate, orgId, isAPICall, flpData) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`actionrequest/user-history`, 'PUT', params, false, flpData)
      .then((response) => {
        dispatch(setLoader(false));
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        dispatch(setModelMessage(data));
        if (data && isAPICall) {
          dispatch(getSearchUserDetails({ OrgID: orgId, search_pending: true }));
          dispatch(getSearchUserDetails({ OrgID: orgId }));
        } else {
          navigate('/jurisdiction-user');
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function userInactiveProfile(params, historyParams, juriStatus, navigate, orgId, isAPICall) {
  return (dispatch) => {
    dispatch(setLoader(true));
    const pageDetails = { pageName: 'Page_JurisdictionUserHome', componentName: 'changeUserAccess' };
    fetcher(`auth/activatedeactivateuser`, 'POST', params, false, pageDetails)
      .then(async (response) => {
        if (juriStatus === JURISDICTION_STATUS.ONBOARDING)
          await fetcher(`${APIS.UPDATE_INITIAL_USER_HISTORY_ACT_DEACT}`, 'POST', historyParams, false);
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        dispatch(setModelMessage(data));
        if (data && isAPICall) {
          dispatch(getSearchUserDetails({ OrgID: orgId, search_pending: true }));
          dispatch(getSearchUserDetails({ OrgID: orgId }));
        } else {
          navigate('/jurisdiction-user');
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function userCloseAccount(params, historyParams, juriStatus, navigate, orgId, isAPICall, flpData) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`actionrequest/user-history`, 'PUT', params, false, flpData)
      .then(async (response) => {
        if (juriStatus === JURISDICTION_STATUS.ONBOARDING)
          await fetcher(`${APIS.UPDATE_INITIAL_USER_HISTORY_ACT_DEACT}`, 'POST', historyParams, false);
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        if (data && isAPICall) {
          await dispatch(getSearchUserDetails({ OrgID: orgId, search_pending: true }));
          dispatch(setLoader(true));
          await dispatch(getSearchUserDetails({ OrgID: orgId }));
        } else {
          navigate('/jurisdiction-user');
        }
        dispatch(setLoader(true));
        await sleep(5000);
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function userActiveDeactiveLogin(params, historyParams, juriStatus, navigate, orgId, isAPICall, flpData) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`actionrequest/user-history`, 'PUT', params, false, flpData)
      .then(async (response) => {
        if (juriStatus === JURISDICTION_STATUS.ONBOARDING)
          await fetcher(`${APIS.UPDATE_INITIAL_USER_HISTORY_ACT_DEACT}`, 'POST', historyParams, false);
        dispatch(setLoader(false));
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        dispatch(setModelMessage(data));
        if (data && isAPICall) {
          dispatch(getSearchUserDetails({ OrgID: orgId, search_pending: true }));
          dispatch(getSearchUserDetails({ OrgID: orgId }));
        } else {
          navigate('/jurisdiction-user');
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function activeUserLogin(params, historyParams, juriStatus, navigate, orgId, isAPICall, flpData) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`actionrequest/user-history`, 'PUT', params, false, flpData)
      .then(async (response) => {
        if (juriStatus === JURISDICTION_STATUS.ONBOARDING)
          await fetcher(`${APIS.UPDATE_INITIAL_USER_HISTORY_ACT_DEACT}`, 'POST', historyParams, false);
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        if (data && isAPICall) {
          await dispatch(getSearchUserDetails({ OrgID: orgId, search_pending: true }));
          dispatch(setLoader(true));
          await dispatch(getSearchUserDetails({ OrgID: orgId }));
        } else {
          navigate('/jurisdiction-user');
        }

        dispatch(setLoader(true));
        await sleep(5000);
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

// Check if user is blocked using user id and display message accordingly
export function checkUserIfIsBlocked(t1, userId) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(
      'public/isblocked',
      'POST',
      {
        userId,
      },
      false,
    )
      .then((response) => {
        // default message
        let data;
        // check if user is blocked
        if (response?.message === MSG_ERR_USER_BLOCKED) {
          data = {
            title: ERROR,
            message1: t(t1, MSG_ERR_USER_BLOCKED),
            error: true,
            isDefaultDisplay: true,
          };
        } else {
          data = {
            title: ERROR,
            message1: USER_ID_OR_PASSPHRASE_ENTERED_IS_INVALID,
            error: true,
          };
        }
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        const data = {
          title: ERROR,
          message1: err?.errors || MSG_ERR_INTERNAL_SERVER,
          error: true,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      });
  };
}
