export const PageAccessStyle = {
  panel: {
    "& .modal-panel-style": {
      maxHeight: "620px",
      overflow: "hidden",
      overflowY: "auto",
    },
    "& .MuiFormGroup-root": {
      width: "100%",
      justifyContent: "center",
    },
    "& .MuiFormGroup-root .MuiFormControlLabel-root": {
      width: "33.33%",
      margin: '0px !important',
      justifyContent: "center",
    },
    "& .MuiFormGroup-root .MuiFormControlLabel-root:last-child": {
      borderRight: "0",
    },

  },
  Checkbox: {
    "& .MuiFormControlLabel-root": {
      flexDirection: 'row-reverse',
      "& .MuiTypography-root" : {
        color: 'common.white',
        fontWeight: 600,
        fontSize: '16px',
        marginRight: '5px'
      }
    }
  }
};