import { createSlice } from "@reduxjs/toolkit";
import { transformProposedBatchEmissions } from "pages/Approvals/support";
import { BATCH_EMISSIONS_STATUSES, transformBatchData } from "pages/Emissions/constants";
import { APIS } from "utils/constants";
import { fetcherGet, fetcherPatch, fetcherPost } from "utils/fetcher";
import { getJurisdictionUUID } from "utils/support";
import { setLoader } from "./commonSlice";


const initialState = {
  batches: [],
  batch: {},
  batchViewMode: true,
  selectedEmissionRecordIds: "",
  batchEmissionRecords: { isLoading: false, data: [] },
  emissionsBatchHistoryList: { isLoading: false, data: [] },
  emissionsBatchInfo: { isLoading: false, data: [] },
  emissionsList: { isLoading: false, data: [] },
  emissionInfo: { isLoading: false, data: [] },
  fileUploadErrors: [],
  isEditEnabled : {
    informationCard: false,
    editableGrid: false,
    facilityForm: false,
  }
};

const EmissionsSlice = createSlice({
  name: "emissions",
  initialState,
  reducers: {
    setBatches: (state, action) => {
      state.batches = action.payload;
    },
    setBatch: (state, action) => {
      state.batch = action.payload;
    },
    setBatchViewMode: (state, action) => {
      state.batchViewMode = action.payload;
    },
    setBatchEmissionRecords: (state, action) => {
      state.batchEmissionRecords = action.payload;
    },
    setSelectedEmissionRecordIds: (state, action) => {
      state.selectedEmissionRecordIds = action.payload;
    },
    setEmissionsBatchHistoryList: (state, action) => {
      state.emissionsBatchHistoryList = action.payload;
    },
    setEmissionBatchInfo: (state, action) => {
      state.emissionsBatchInfo = action.payload;
    },
    setEmissionList: (state, action) => {
      state.emissionsList = action.payload;
    },
    setEmissionInfo: (state, action) => {
      state.emissionInfo = action.payload;
    },
    setFileUploadErrors: (state, action) => {
      state.fileUploadErrors = action.payload;
    },
    setIsEditEnabled: (state, action) => {
      const { key, value } = action.payload;
      state.isEditEnabled[key] = value;
    },
  },
});

export const {
  setBatches,
  setBatch,
  setBatchViewMode,
  setBatchEmissionRecords,
  setSelectedEmissionRecordIds,
  setEmissionsBatchHistoryList,
  setEmissionBatchInfo,
  setEmissionList,
  setEmissionInfo,
  setFileUploadErrors,
  setIsEditEnabled,
} = EmissionsSlice.actions;

export default EmissionsSlice.reducer;

// get batch emissions history
export function getEmissionsBatchHistoryAPI(jurisdictionId, batchId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setEmissionsBatchHistoryList({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/history`
      );
      dispatch(
        setEmissionsBatchHistoryList({
          isLoading: false,
          data: response?.data,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setEmissionsBatchHistoryList({ isLoading: false, data: [] }));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// get emissions records for a particular jurisdiction and batch id, batch id optional
// when batch id is null, gives all emission records for a jurisdiction
export function getEmissionsListAPI(jurisdictionId, batchId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setEmissionList({ isLoading: true, data: [] }));
    try {
      let api = `${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/emissionsRecords`;
      if (batchId) api = `${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/emissionsRecords`;
      const response = await fetcherGet(api);
      dispatch(
        setEmissionList({
          isLoading: false,
          data: response?.data,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setEmissionList({ isLoading: false, data: [] }));
    }
  };
}

// get batch details by id for a jurisdiction
export function getBatchDetailsById(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    dispatch(setBatch({}));
    const api = `${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}`;
    return fetcherGet(api)
      .then((response) => {
        dispatch(setBatch(transformBatchData(response)?.data));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

// get all batches of a jurisdiction
export function getBatches() {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    dispatch(setBatches([]));
    const api = `${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches`;
    return fetcherGet(api)
      .then((response) => {
        dispatch(setBatches(transformBatchData(response)?.data));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

// get proposed batches
export function getProposedBatches() {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    try {
      const response = await fetcherGet(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches`);
      const proposedRecords = transformProposedBatchEmissions(response.data)?.filter(
        (item) => item.status === BATCH_EMISSIONS_STATUSES.PROPOSED
      );
      dispatch(setBatches(proposedRecords));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// update batch name and comment
export function updateBatchInformation({ id, ...payload }) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${id}`, {
        ...payload,
      });
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// cancel batch
export function cancelBatch(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPatch(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/cancel`).finally(
      () => {
        dispatch(setLoader(false));
      }
    );
  };
}

// create batch for emissions
export function saveBatchEmission(payload) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    try {
      return await fetcherPost(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches`, {
        ...payload,
      });
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export function getEmissionInfo({ jurisdictionId, batchId, emissionId }) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setEmissionInfo({ isLoading: true, data: [] }));
    try {
      const api = `${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/emissionsRecords/${emissionId}`;
      const response = await fetcherGet(api);
      dispatch(
        setEmissionInfo({
          isLoading: false,
          data: response?.data,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setEmissionInfo({ isLoading: false, data: [] }));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export function updateEmissionInfo() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      // API call
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export function addEmissionRecords(batchId, payload) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    try {
      return await fetcherPost(
        `${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/emissionsRecords`, payload
      )
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// propose batch
export function proposeBatch(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPatch(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/propose`).finally(
      () => {
        dispatch(setLoader(false));
      }
    );
  };
}

// approve batch
export function approveBatchEmissionsRecord(batchId, payload) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/approve`, {
        ...payload,
      });
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// revision request batch
export function revisionRequestBatch(batchId, payload) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/revisionsRequest`, {
        ...payload,
      });
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// deny batch
export function denyBatch(batchId, payload) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/deny`, {
        ...payload
      });
    } finally {
      dispatch(setLoader(false));
    }
  }
}

// edit emission record grid
export function editEmissionRecordAPI(batchId, emissionId, payload, pgeDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(`${APIS.BATCH_EMISSIONS}/jurisdiction/${getJurisdictionUUID()}/batches/${batchId}/emissionsRecords/${emissionId}`, payload, pgeDetails);
    } finally {
      dispatch(setLoader(false));
    }
  }
}

// upload csv file to bucket
export async function uploadFileToBucket({ formData, fileName, batchId, signal}) {
  const jurisdictionId = localStorage.getItem("jurisdictionID");
  const response = await fetcherPost(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/uploadFile`, formData, {
    "File-Name": fileName,
    "Content-Type": undefined,
  },
  signal);
  return response;
}

// upload excel file to bucket
export async function uploadFileToBucketExcel({ file, fileName, batchId, signal }) {
  return new Promise((resolve, reject) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    const reader = new FileReader();
    reader.onload = async (event) => {
      const base64Data = event.target.result.split(",")[1]; // Remove the data URL prefix
      try{
        const response = await fetcherPost(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/uploadFile`, base64Data, {
          "File-Name": fileName,
        },
        signal);
        resolve(response);
      }catch(error){
        reject(error)
      }
    };
    reader.onerror = (event) => {
      reject(event.target.error);
    };
    reader.readAsDataURL(file);
  });
}

// get antivirus status of uploaded file
export async function getFileStatus({ headerOptions, signal, batchId }) {
  const jurisdictionId = localStorage.getItem("jurisdictionID");
  const response = await fetcherGet(
    `${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}}/getStatus`,
    {},
    {
      ...headerOptions,
    },
    signal
  );
  return response;
}

// process uploaded file
export function processUploadedFile(payload,batchId) {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    return fetcherPost(
      `${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/emissionsRecords/processFile`,
      {
        ...payload,
      },
      {}
    )
}

// remove emissions records (individual and bulk)
export function removeEmissionRecords({jurisdictionId, batchId, payload}){
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(`${APIS.BATCH_EMISSIONS}/jurisdiction/${jurisdictionId}/batches/${batchId}/emissionsRecords/delete`, {
        ...payload
      })
    } finally {
      dispatch(setSelectedEmissionRecordIds([])) 
      dispatch(setLoader(false));
    }
  }
}

