import { QuickLinksMenu } from "components/QuickLinksMenu";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCurrentPageName, t, JURISDICTION_STATUS, DEFAULT_ERROR_MSG, PL_PERCENTAGE } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { retryPeripheralsSetup, setDeleteJurisdictionId, setSelectedJurisdictionId } from "store/jurisdictionSlice";
import { setModelMessage } from "store/commonSlice";
import { getJurisdictions } from "store/entityApplicationSlice";
import { StyledQuickAction } from "../../AccountDetails/AccountDetails.style";

function QuickActionsMenu({ params }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jurisdictionPLStyle } = useSelector(({ onboardJurisdiction }) => onboardJurisdiction);

  const checkPercentage = jurisdictionPLStyle[params.row.dynamoId];

  const quickLinkList = React.useMemo(() => {
    const quickActionList = params.row?.isPeripheralSetupDone
      ? [
          {
            name: t(t1, "MANAGE_JURISDICTION_DETAILS")?.toUpperCase(),
            Access: null,
            onLinkClick: () => {
              navigate(`/manage-jurisdiction-details/${params.row.id}`);
            },
          },
          {
            name: t(t1, "MANAGE_BUDGET"),
            Access: [JURISDICTION_STATUS.OFFBOARDED].includes(params.row?.Status?.Name) ? "RO" : "",
            onLinkClick: () => {
              dispatch(setSelectedJurisdictionId(params.row.id));
              navigate(`/budgets/${params.row.id}`);
            },
          },
          ...(checkPercentage === PL_PERCENTAGE
            ? [
                {
                  name: t(t1, "MANAGE_PURCHASE_LIMITS"),
                  Access: [JURISDICTION_STATUS.OFFBOARDED].includes(params.row?.Status?.Name) ? "RO" : "",
                  onLinkClick: () => {
                    navigate(`/purchase-limits/${params.row.id}`);
                  },
                },
              ]
            : []),
          {
            name: params.row?.Status?.Name === JURISDICTION_STATUS.ONBOARDING
                  ? t(t1, "SETUP_INITIAL_USERS")?.toUpperCase()
                  : t(t1, "MANAGE_INITIAL_USERS")?.toUpperCase(),
            Access: null,
            onLinkClick: () => {
              navigate(`/set-up-initial-users/${params.row.id}`);
            },
          },
          {
            name: t(t1, "MANAGE_JURISDICTION_STATUS")?.toUpperCase(),
            Access: null,
            onLinkClick: () => {
              navigate(`/manage-jurisdiction-status/${params.row.id}`);
            },
          },
          {
            name: params.row?.Status?.Name === JURISDICTION_STATUS.ONBOARDING
                  ? t(t1, "SETUP_JURISDICTION_ACCOUNTS")?.toUpperCase()
                  : t(t1, "MANAGE_JURISDICTION_ACCOUNTS")?.toUpperCase(),
            Access: null,
            onLinkClick: () => {
              navigate(`/manage-jurisdiction-accounts/${params.row.id}`);
            },
          },
          {
            name: t(t1, "MANAGE_TRANSFERS")?.toUpperCase(),
            Access: [JURISDICTION_STATUS.ONBOARDING, JURISDICTION_STATUS.OFFBOARDED].includes(params.row?.Status?.Name)
              ? "RO"
              : "",
            onLinkClick: () => {
              navigate(`/manage-transfers/${params.row.id}`);
            },
          },
        ]
      : [
          {
            name: <StyledQuickAction>{t(t1, "RETRY")}</StyledQuickAction>,
            Access: null,
            onLinkClick: () => {
              dispatch(retryPeripheralsSetup({ orgId: params.row.dynamoId }))
                .then(() => {
                  dispatch(
                    setModelMessage({
                      title: t(t1, "SUCCESS_TITLE"),
                      message1: t(t1, "RETRY_SUCCESS_MESSAGE"),
                    })
                  );
                  dispatch(getJurisdictions());
                })
                .catch((error) => {
                  dispatch(
                    setModelMessage({
                      error: true,
                      title: t(t1, "ERROR_TITLE"),
                      message1: `${error.response?.data?.data || DEFAULT_ERROR_MSG}`,
                    })
                  );
                });
            },
          },
          {
            name: <StyledQuickAction>{t(t1, "DELETE")}</StyledQuickAction>,
            Access: null,
            onLinkClick: () => {
              dispatch(setDeleteJurisdictionId(params.row.ID));
            },
          },
        ];
    return quickActionList;
  }, [
    params.row?.isPeripheralSetupDone,
    params.row?.Status?.Name,
    params.row.id,
    params.row.Name,
    params.row.dynamoId,
    t1,
    checkPercentage,
    navigate,
    dispatch,
  ]);

  return (
    <QuickLinksMenu
      disabled={params?.row?.isPeripheralSetupDone === null}
      iconClassName={params?.row?.isPeripheralSetupDone ? "dot-icon" : "dot-icon-disabled"}
      popupClassName="quick-link-style"
      linksList={quickLinkList || []}
      isDotIcon
    />
  );
}

export default QuickActionsMenu;
