const Style = {
  mainBox: {
    margin: "30px 60px 40px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
  },
  
  headertext: {
    fontSize: "14px",
    marginRight: "auto", 
  },
  
  headerMiddleText: {
    textAlign: "center", 
    fontSize: "14px",
    marginRight: "6rem",
    flex: 1, 
  },

  heading: {
    display: "flex",
    py: 2,
    borderBottom: '2px solid black',
    alignItems: 'flex-end'
  },
  headingTitle: { fontSize: "54px", fontWeight: 700 },
  headingSubtitle: { fontSize: "16px", ml:2, maxWidth: '200px', textAlign: 'center' },
  contentBox:{
    marginTop:"20px"
  },
  contentBoxTitle: { fontSize: "24px", fontWeight: 700 },
  contentBoxTitleText: { fontSize: "13px", fontStyle: "italic", mt: 2 },
  checklistDescription: { fontSize: "12px", my: 2 },
  checklistItem: {
    ml: 3,
    my: 2,
    display: 'flex',
    fontSize: "14px",
  },
  checklistItemIndented: {
    ml: 7,
    my: 2,
    display: 'flex',
    fontSize: "14px",
  },
  checkIcon: {
    display: 'flex',
    mr: 2,
    fontSize: '24px'
  },
  lastRevised: {
    fontSize: '14px',
  },
  subTitle: {
    fontSize: '20px',
    fontWeight: '700',
  },
  entry: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    mb: 0.5,
  },
  entrySection:{
    display: 'flex',
    gridTemplateColumns: '2fr 3fr',
    mb: 0.5,
  },
  entryAttribute: {
    fontSize: "16px", fontWeight: 700, pr: 4,
  },
  entryValue: {
    fontSize: '16px',
    wordWrap: "break-word",
    overflow:"auto",
  },
  entityIdentification: {
    fontSize: '18px',
    fontWeight: 700,
    mt: 4,
    mb: 2,
  },
  entityIdentificationInline: {
    fontSize: '16px',
    fontWeight: 700,
  },
  underlineDiv: {
    height: '4px',
    width: '100%',
    border: '1px solid black',
    backgroundColor: 'white',
    mt: 1,
    mb: 2,
  }
};

export default Style;
