const Style = {
  SecurityQuestionModal: {
    width: "750px",
    minHeight: "290px",
    padding: "20px",
    border: "1px solid #333",
    borderRadius: "10px",
    background: "#ffffff",
    boxsizing: "border-box",
    margin: "0 auto",
    top: "50%",
    position: "absolute",
    outline: 'none',
    left: "0",
    right: "0",
    transform: "translateY(-50%)",
    fontWeight: "400",
    "@media(max-width: 814px)": {
      width: "700px",
    },
    "& h2 ": {
      color: "palette.text.primary",
      marginBottom: "25px",
      fontWeight: "600",
      fontSize: " 24px",
      display: "flex",
      justifyContent: "space-between",
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
      },
    },
    "& h6": {
      fontSize: "16px",
      color: "#333",
      marginBottom: "0px",
      fontWeight: "300",
    },
    "& h5": {
      fontSize: "18px",
      color: "#333",
      fontWeight: "500",
      marginBottom: "20px",
    },
    "& h4": {
      marginLeft: "55px",
      fontSize: "18px",
      color: "#333",
      fontWeight: "500",
      marginBottom: "20px",
    },
    "& .CustomSelect": {
      margin: "0px",
      " & .MuiOutlinedInput-root": {
        borderRadius: "0px",
      },
      "& .MuiSelect-select": {
        padding: "0px 15px !important",
        borderRadius: "0px ",
      },
    },
    " & .ContinueBtn": {
      position: "absolute",
      bottom: "20px",
      right: "20px",
      width: "139px",
      borderRadius: "5px",
    },

    " & .MuiOutlinedInput-root": {
      borderRadius: "4px !important",
      "& input": {
        padding: "0px 10px",
        width: "439px",
        height: "28px",
        fontSize: "14px",
        fontWeight: "400",
        color: "#000000",
      },
    },
  },
};

export default Style;
