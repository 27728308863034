import { Box, Typography } from "@mui/material";
import React, {useEffect, useMemo} from "react";
import { useEffectOnce } from "hooks/useEffectOnce";
import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getRepresentatives } from "store/entityProfileSlice";
import {
  COMPONENT_NAME_FOR_ROLE,
  defaultORGID,
  enLanguage,
  ENTITY_FLOW,
  ENTITY_TYPE_NAMES,
  formatDate,
  getAllLabels,
  JURISDICTIONS_NAMES,
  REPRESENTATIVES_ROLES,
  formatDateTime,
  t,
  isEmpty,
} from "utils/constants";
import Style from "./ApplicationChecklist.style";
import {getCMSLandingDetails} from "../../../../store/configSlice";
import EntityInformation from "./EntityInformation";
import EntityIdentification from "./EntityIdentification";
import EntityAddress from "./EntityAddress";
import EntityContactInformation from "./EntityContactInformation";
import EntityAuction from "./EntityAuction";
import EntityAlternateContact from "./EntityAlternateContact";
import EntityRepsList from "./EntityRepsList";
import EntityUserInputForm from "./EntityUserInputForm";
import EntityReps from "./EntityReps";
import EntityFacilities from "./EntityFacilities";
import SiteLogo from "../../../../assets/images/logo.png";

const PageName = "Page_ApplicationWithAttenstation";
const EntityRegistrationPage = "Page_EntityRegistration";

export const ApplicationWithAttestations = React.forwardRef((props, ref) => {
  const { t: t1 } = useTranslation(PageName);
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const { representatives } = useSelector(({ entityProfile }) => entityProfile);
  const { Representative } = useSelector(({ entityApplication }) => entityApplication);
  const dispatch = useDispatch();
  const {page,entities} = props;
  const entityData = entities?.data;
  const action = "representativesentityapprove"
  const { id } = useParams();
  useEffectOnce(() => {
    dispatch(getRepresentatives(id, action,  {componentName:COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE, pageName: "Page_EntityProfile" }));
  }, []);

  

  const {AARs, PARs, AARNames, PARNames} = useMemo(() => {
    let reps = [[], []];
    if (page === ENTITY_FLOW.PROFILE || page === ENTITY_FLOW.REGISTRATION  ) {
      
      reps = representatives?.reduce((acc, curr) => {

        if (curr?.role?.name === REPRESENTATIVES_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE) {
          acc[0].push(curr);
  
        } else if (curr?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE) {
          acc[1].push(curr);
        }
        return acc;
      }, reps);
    } else {
      reps = Representative?.data?.reduce((acc, curr) => {
        if (curr?.role?.name === REPRESENTATIVES_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE) {
          acc[0].push({
            id: curr?.id,
            name: curr?.name,
            referenceCode: curr?.reference_code,
          });
        } else if (curr?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE) {
          acc[1].push({
            id: curr?.id,
            name: curr?.name,
            referenceCode: curr?.reference_code,
          });
        }
        return acc;
      }, reps);
    }
   
    return {
      AARs: reps?.[0],
      PARs: reps?.[1],
      AARNames: reps?.[0]?.map((rep) => rep?.name)?.join(", "),
      PARNames: reps?.[1]?.map((rep) => rep?.name)?.join(", "),
    }
  }, [representatives, Representative?.data, page])


  const getPageMargins = () => {
    return `
    @page {size:auto; margin: 30px;} html{background-color: #FFFFFF; margin: 0px;} body{margin: 30px 0;}
    `;
  };

  return (
    <Box ref={ref} id="applicationWithAttestations">
      <style media="print">{getPageMargins()}</style>
      <Box sx={Style.mainBox}>
        <Box sx={Style.header}>
          <Typography sx={Style.headertext}>{formatDateTime(new Date(), "/")}</Typography>
          <Typography sx={Style.headerMiddleText}>{t(t1, "REGISTRY_ACCOUNT_APPLICATION_WITH_ATTESTATIONS")}</Typography>
        </Box>
        <Box>
          <Box sx={Style.heading}>
            <img src={SiteLogo} alt="logo" width="115" height="75" />
          </Box>
          <Box sx={Style.contentBox}>
            <Typography sx={Style.contentBoxTitle}>
              {t(t1, "ACCOUNT_APPLICATION_WITH_ATTESTATIONS")}
            </Typography>
            <Typography sx={Style.contentBoxTitleText}>
              {t(t1, "ACCOUNT_APPLICATION_WITH_ATTESTATIONS_DESCRIPTION_1")}
            </Typography>
            <Typography my={2}>
              <strong>{t(t1, "CITSS_ENTITY_ID")}:</strong>&nbsp;{entityData?.entityId}
            </Typography>
            <Typography sx={Style.checklistDescription}>
              {t(t1, "ACCOUNT_APPLICATION_WITH_ATTESTATIONS_DESCRIPTION_2")}
            </Typography>
            <Typography sx={Style.checklistDescription}>
              {t(t1, "ACCOUNT_APPLICATION_WITH_ATTESTATIONS_DESCRIPTION_3")}
            </Typography>

            <Typography sx={Style.subTitle} my={1}>
              {t(t1, "SECTION_1_REGISTRY_ACCOUNT_APPLICATION_INFORMATION")}
            </Typography>

            <EntityInformation entityData={entityData} />
            {![ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL].includes(isEmpty(entityData?.proposeChanges?.entity)  ? entityData?.entityType?.name : entityData?.proposeChanges?.entity?.entityType?.name) ?
            <EntityIdentification entityData={entityData} /> :
              null}
            <EntityAddress entityData={entityData} />
            <EntityContactInformation entityData={entityData} />
            <EntityRepsList AARNames={AARNames} PARNames={PARNames} />

            <EntityAlternateContact />
            {![JURISDICTIONS_NAMES.QUEBEC].includes(entityData?.jurisdiction?.name) ?
            <EntityAuction entityData={entityData} /> :
              null}
              <Typography sx={Style.entityIdentification}>
              {t(t1, "FACILITY_IDENTIFICATION")}
              </Typography>
              {[ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY].includes(isEmpty(entityData?.proposeChanges?.entity) ? entityData?.entityType?.name : entityData?.proposeChanges?.entity?.entityType?.name) ?
            <EntityFacilities entityData={entityData} /> :
              null}
            <Typography sx={Style.subTitle} my={2}>
              {t(t1, "SECTION_2_ADDITIONAL_REQUIRED_INFORMATION")}
            </Typography>
            <Typography sx={Style.checklistDescription}>
              {t(t1, "SECTION_2_ADDITIONAL_REQUIRED_INFORMATION_DESCRIPTION")}
            </Typography>
            <Typography sx={Style.checklistItem}>
              <Typography sx={Style.checkIcon}>❏</Typography>
              {t(t1, "SECTION_2_ADDITIONAL_REQUIRED_INFORMATION_DESCRIPTION_YES")}
            </Typography>
            <Typography sx={Style.checklistItem}>
              <Typography sx={Style.checkIcon}>❏</Typography>
              {t(t1, "SECTION_2_ADDITIONAL_REQUIRED_INFORMATION_DESCRIPTION_NO")}
            </Typography>
            <Typography sx={Style.contentBoxTitleText}>
              {t(t1, "SECTION_2_ADDITIONAL_REQUIRED_INFORMATION_DESCRIPTION_2")}
            </Typography>
            <Typography sx={Style.entityIdentificationInline}>
              {t(t1, "NAME_OF_AGENT_FOR_SERVICE_OF_PROCESS")}:
            </Typography>
            <Box sx={Style.underlineDiv} />
            <Typography sx={Style.entityIdentificationInline}>{t(t1, "AGENT_ADDRESS")}:</Typography>
            <Box sx={Style.underlineDiv} />
            <Typography sx={Style.entityIdentificationInline}>{t(t1, "AGENT_CITY_STATE_ZIP")}:</Typography>
            <Box sx={Style.underlineDiv} />

            <EntityReps
              entityID={entityData?.entityId}
              titleAttribute="SECTION_3_PRIMARY_ACCOUNT_REPRESENTATIVE_PAR_ATTESTATION"
              informationLabelAttribute="PAR_INFORMATION"
              reps={PARs}
            />
            <EntityReps
              entityID={entityData?.entityId}
              titleAttribute="SECTION_4_ALTERNATE_ACCOUNT_REPRESENTATIVE_AAR_ATTESTATION"
              informationLabelAttribute="AAR_INFORMATION"
              reps={AARs}
            />

            <Typography sx={Style.subTitle} my={2}>
              {t(t1, "SECTION_5_ENTITY_DIRECTOR_OR_OFFICER_ATTESTATION")}
            </Typography>
            <Box sx={Style.entrySection}>
              <Typography sx={Style.entryAttribute}>
                {t(t1, "CITSS_ENTITY_ID")}:
              </Typography>
              <Typography sx={Style.entryValue}>{entityData?.entityId}</Typography>
            </Box>
            <Typography sx={Style.checklistDescription}>
              {t(t1, "SECTION_5_ENTITY_DIRECTOR_OR_OFFICER_ATTESTATION_DESCRIPTION")}
            </Typography>
            <Typography sx={Style.checklistItem}>
              <Typography sx={Style.checkIcon}>❏</Typography>{t(t1, "SECTION_5_ENTITY_DIRECTOR_OR_OFFICER_ATTESTATION_CHECKLIST_1")}
            </Typography>
            <Typography sx={Style.checklistItem}>
              <Typography sx={Style.checkIcon}>❏</Typography>{t(t1, "SECTION_5_ENTITY_DIRECTOR_OR_OFFICER_ATTESTATION_CHECKLIST_2")}
            </Typography>
            <Typography sx={Style.contentBoxTitleText}>
              {t(t1, "SECTION_5_ENTITY_DIRECTOR_OR_OFFICER_ATTESTATION_DESCRIPTION_1")}
            </Typography>
            <Typography sx={Style.contentBoxTitleText}>
              {t(t1, "SECTION_5_ENTITY_DIRECTOR_OR_OFFICER_ATTESTATION_DESCRIPTION_2")}
            </Typography>
            <Typography sx={Style.checklistDescription}>
              {t(t1, "SECTION_5_ENTITY_DIRECTOR_OR_OFFICER_ATTESTATION_DESCRIPTION_3")}
            </Typography>
            <EntityUserInputForm />

            <Typography sx={Style.lastRevised}>
              {`${t(t1, "REVISION")}: ${formatDate(new Date(entityData?.updatedAt), "/")}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
