import { Typography, Box } from "@mui/material";
import { StyledRequiredAstrik } from "pages/ManageBatchTransfer/ManageBatchTransfer.style";
import { BATCH_TRANSFER_TYPES, CURRENCY_OPTIONS, CUSTOM_INPUT_ALLOWED_PATTERN, formatNumber, t, customSorting } from "utils/constants";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-premium";
import { CustomNumberTextField, CustomSelectField } from "./customCelEditor";

export const HoldingSchema = (t1, valueOptions = {}) => [
  {
    id: 1,
    field: "vintage",
    headerName: t(t1, "VINTAGE"),
    minWidth: 100,
    flex: 1,
    align: "center",
    renderCell: (cellValues) => <Typography variant="body2">{cellValues?.row?.vintage}</Typography>,
  },
  {
    id: 3,
    field: "type",
    headerName: t(t1, "TYPE"),
    minWidth: 100,
    align: "center",
    flex: 1,
    type: 'singleSelect',
    valueOptions: valueOptions?.type?.sort(),
  },
  {
    id: 4,
    field: "subType",
    headerName: t(t1, "SUB_TYPE_HEADER"),
    minWidth: 100,
    align: "center",
    flex: 1,
    type: 'singleSelect',
    valueOptions: valueOptions?.subType?.sort(),
  },
  {
    id: 5,
    field: "offsetType",
    headerName: t(t1, "OFFSET_TYPE"),
    minWidth: 100,
    align: "center",
    flex: 1,
  },
  {
    id: 6,
    field: "offsetProjectId",
    headerName: t(t1, "OFFSET_PROJECT_ID"),
    minWidth: 100,
    align: "center",
    flex: 1,
  },
  {
    id: 7,
    field: "specialCategory",
    headerName: t(t1, "SPECIAL_CATEGORY"),
    minWidth: 100,
    align: "center",
    flex: 1,
  },
  {
    id: 8,
    field: "availableHoldingQuantity",
    headerName: t(t1, "AVAILABLE_QUANTITY"),
    minWidth: 100,
    align: "center",
    flex: 1.2,
    sortComparator: customSorting,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    id: 9,
    field: "quantity",
    headerName: t(t1, "QUANTITY"),
    minWidth: 100,
    align: "center",
    flex: 1,
    valueFormatter: ({ value }) => formatNumber(value),
    sortComparator: customSorting,
  },
];

export const receivingAccountData = {
  id: 0,
  accountNumber: "",
  entityReference: "",
  proposedQuantity: "",
  price: "",
  currency: CURRENCY_OPTIONS[0].value,
};

export const ReceivingAccountSchema = (t1, currencyList, transferType, selectedLanguage) => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    hideable: false,
  },
  {
    id: 1,
    field: "accountNumber",
    hideable: false,
    headerName: t(t1, "ACCOUNT_NUMBER"),
    renderHeader: () => (
      <Box sx={{ fontWeight: 600 }}>
        {t(t1, "ACCOUNT_NUMBER")}
        {transferType === BATCH_TRANSFER_TYPES.BATCH_TRANSFER && <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>}
      </Box>
    ),
    renderCell: (params) => (transferType === BATCH_TRANSFER_TYPES.BATCH_TRANSFER ? (
      <div onDoubleClick={(event) => event.stopPropagation()}>
        <CustomNumberTextField
          name={`receivingAccountDetails.[${params.row.id}].accountNumber`}
          value={params.row.accountNumber ?? ""}
          regex={CUSTOM_INPUT_ALLOWED_PATTERN.alphaNumPattern}
          rules={{
            validate: {
              fieldRequired: () => {
                const { accountNumber } = params.row;
                return !accountNumber ? `${t(t1, "ERR_RECEIVING_ACCOUNT_NUMBER_IS_REQUIRED")}` : true;
              },
            },
          }}
          cursor={params.row.cursorPosition}
        />
      </div>
    ) :
      (
        <Typography variant="body2">{params?.row?.accountNumber}</Typography>
      )
    ),
    minWidth: 100,
    flex: 1,
  },
  ...(transferType === BATCH_TRANSFER_TYPES.BATCH_TRANSFER
    ? [
      {
        id: 2,
        field: "entityReference",
        headerName: t(t1, "ENTITY_REFERENCE"),
        renderCell: (params) => (
          <div onDoubleClick={(event) => event.stopPropagation()}>
            <CustomNumberTextField
              name={`receivingAccountDetails.[${params.row.id}].entityReference`}
              value={params.row.entityReference ?? ""}
              regex={CUSTOM_INPUT_ALLOWED_PATTERN.bigInt}
            />
          </div>
        ),
        minWidth: 100,
        flex: 1,
      },
    ]
    : []),
  {
    id: 3,
    field: "proposedQuantity",
    hideable: false,
    headerName: t(t1, "PROPOSED_QUANTITY"),
    renderHeader: () => (
      <Box sx={{ fontWeight: 600 }}>
        {t(t1, "PROPOSED_QUANTITY")}
        <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>
      </Box>
    ),
    renderCell: (params) => (
      <div onDoubleClick={(event) => event.stopPropagation()}>
        <CustomNumberTextField
          name={`receivingAccountDetails.[${params.row.id}].proposedQuantity`}
          value={params.row.proposedQuantity ?? ""}
          regex={CUSTOM_INPUT_ALLOWED_PATTERN.bigInt}
          rules={{
            validate: {
              fieldRequired: () => {
                const { proposedQuantity } = params.row;
                return !proposedQuantity
                  ? `${t(t1, "ERR_PROPOSED_QUANTITY_VALIDATION_BUDGET_REQUIRED")} `
                  : true;
              },
            },
          }}
        />
      </div>
    ),
    minWidth: 100,
    flex: 1,
  },
  ...(transferType === BATCH_TRANSFER_TYPES.BATCH_TRANSFER
    ? [
      {
        id: 4,
        field: "price",
        headerName: t(t1, "PRICE"),
        renderCell: (params) => (
          <div onDoubleClick={(event) => event.stopPropagation()}>
            <CustomNumberTextField
              name={`receivingAccountDetails.[${params.row.id}].price`}
              value={params.row.price ?? ""}
              regex={
                selectedLanguage === "French" ?
                  CUSTOM_INPUT_ALLOWED_PATTERN.floatFrench
                  : CUSTOM_INPUT_ALLOWED_PATTERN.float
              }
              rules={{
                validate: {
                  fieldRequired: () => {
                    const { price, currency, accountNumber } = params.row;
                    return currency && !price
                      ? `${t(t1, "ACCOUNT_NUMBER")} ${accountNumber} : ${t(t1, "ERR_PRICE_VALIDATION_PRICE_REQUIRED")} `
                      : true;
                  },
                  isValid: () => {
                    const { price, accountNumber } = params.row;
                    return price && price === "0"
                      ? `${t(t1, "ACCOUNT_NUMBER")} ${accountNumber} : ${t(t1, "ERR_PRICE_VALIDATION_PRICE_NON_ZERO")} `
                      : true;
                  },
                },
              }}
            />
          </div>
        ),
        minWidth: 100,
        flex: 1,
      },
      {
        id: 5,
        field: "currency",
        headerName: t(t1, "CURRENCY"),
        valueGetter: (params) => currencyList?.find((currency) => currency.value === params.row.currency)?.label ?? "",
        renderCell: (params) => (
          <div onDoubleClick={(event) => event.stopPropagation()}>
            <CustomSelectField
              name={`receivingAccountDetails.[${params.row.id}].currency`}
              value={params.row.currency}
              type="select"
              label={t(t1, t(t1, "SELECT_CURRENCY"))}
              options={[...currencyList]}
              placeholder={t(t1, CURRENCY_OPTIONS[0].label)}
              rules={{
                validate: {
                  fieldRequired: () => {
                    const { currency, price, accountNumber } = params.row;
                    return price && !currency
                      ? `${t(t1, "ACCOUNT_NUMBER")} ${accountNumber} : ${t(t1, "ERR_CURRENCY_VALIDATION_CURRENCY_REQUIRED")} `
                      : true;
                  },
                },
              }}
            />
          </div>
        ),
        minWidth: 100,
        flex: 1,
      },
    ]
    : []),
];
