import {Box, styled, Typography, InputLabel} from "@mui/material";

export const StyledDetailsContainer = styled(Box)(({theme}) => ({
	display: "grid",
	gridTemplateColumns: "repeat(3, 1fr)",
	gridGap: theme.spacing(2),
}));

export const StyledRequiredAstrik = styled(Typography)(({theme}) => ({
	display: "inline-block",
	color: theme.palette.common.red,
	margin: theme.spacing(0, 0.5, 0, 0.5),
	fontWeight: 700,
}));

export const StyledPassphraseLabel = styled(InputLabel)(({theme}) => ({
	fontSize: "0.875rem",
	color: theme.palette.common.textColor,
	fontWeight: 700,
	overflow: 'auto',
	whiteSpace: 'normal',
	textOverflow: 'initial',
	marginBottom: theme.spacing(0.5),
	span: {
		fontWeight: 600,
		color: theme.palette.common.textColor,
	},
	"&.grid-text-label": {
		fontWeight: "600",
	},
	"&.MuiInputLabel-root": {
		fontWeight: 700,
		whiteSpace: "normal",
	},
}));
