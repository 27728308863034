import {
  Typography,
  Grid,
  Button,
  Box,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  OutlinedInput,
} from "@mui/material";
/* Conflict Resolution Candidate
import React, { useEffect } from "react";
 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DialogMessage, FormCard, GridTextfield } from "components";
import { RadioButtonChecked } from "assets/icons/RadioButtonChecked";
import { setSelectedRole } from "store/privilegeSlice";
import { RadioButtonUnchecked } from "assets/icons/RadioButtonUnchecked";
import { CustomizeButton } from "components/CustomizeButton";
import { setDialogMessage } from "store/commonSlice";
import { CheckCircle } from "assets/icons";
/* Conflict Resolution Candidate
import {
  allowCommaDot,
  t,
  onlyTextWithSpacePattern,
  getCurrentPageName,
  validateFullNameValues,
} from "utils/constants";
 */
import { allowCommaDot, t, onlyTextWithSpacePattern, getCurrentPageName } from "utils/constants";
import { GridSelectRadioButton } from "components/GridSelectRadioButton";
import Style from "../../styles";

function RoleDetailBoxCard(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { roledetails, roles, selectValue, setSelectValue, type, customeStyles, setDefaultValues, methods = false, cancelDisabled = false, isAllReviewed } = props;
  const { user, userRole } = useSelector(({ auth }) => auth);
  const { role } = useSelector(({ privilege }) => privilege);
  const { dialogMessage, modelMessage } = useSelector(({ common }) => common);
  const theme = useTheme();

  // useEffect(() => {
  //   if(type === 'add'){
  //     dispatch(setSelectedRole(''));
  //   }
  // },[type])

  const handleBtnClick = () => {
/* Conflict Resolution Candidate
    navigate("/edit-role-details", { state: { data: role } });
    */

    setDefaultValues({
      RoleType: roles.find((v) => v.name === role?.roleType),
      role_name: role?.name,
      priv_txt_RoleDesc: role?.description,
    });
    navigate("/edit-role-details");
  };

  const handleModalSubmit = () => {
    if (location.pathname === "/create-role-details") {
      setDefaultValues({
/* Conflict Resolution Candidate
        RoleType: "",
        role_name: "",
        priv_txt_RoleDesc: "",
 */
        RoleType: '',
        role_name: '',
        priv_txt_RoleDesc: ''
      });
      navigate(location.pathname);
    } else {
      navigate("/role-details", { state: { data: role } });
    }
  };

  const setWarningModal = () => {
    dispatch(setDialogMessage(true));
  };

/* Conflict Resolution Candidate
  useEffect(() => {
    if (location.pathname !== "/create-role-details") {
      const routeState = { ...location?.state?.data };
      const roleObject = {
        name: routeState?.name,
        description: routeState?.description,
        id: routeState?.id,
        attributestatus: routeState?.attributestatus,
        roleType: routeState?.roleType,
      };
      dispatch(setSelectedRole(roleObject));
      setDefaultValues({
        RoleType: roles.find((v) => v.name === routeState?.roleType),
        role_name: routeState?.name,
        priv_txt_RoleDesc: routeState?.description,
      });
      // console.log(selectValue, 'selectValue');
    }
  }, [roles]);

  // console.log(roles.find((v) => v.name === selectValue),roles,'selectValue');
 */

  return (
    <>
      <Grid item sm={12} sx={customeStyles}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "40px",
            marginBottom: "20px",
          }}
        >
          <Typography sx={{ fontWeight: 200 }} variant="h5">
            {type === "add" ? t(t1, "PM_MENU_CREATE_ROLE") : t(t1, "RD_H5_ROLE_DETAILS")}
          </Typography>
          {type === "add" || type === "edit" ? (
            <div className="bottom-btn">
              <CustomizeButton
                disabled={cancelDisabled}
                disableElevation
                variant="outlined"
                csName="rc"
                handleClick={() => setWarningModal()}
                label={t(t1, "PM_BTN_CANCEL")}
                name="priv_btn_CancelRole"
              />
              <CustomizeButton
/* Conflict Resolution Candidate
                disabled={methods && !methods?.formState?.isValid}
 */
                customStyle={{ marginRight: "0px !important" }}
                disableElevation
                variant="contained"
                type="submit"
                label={isAllReviewed ? t(t1, "BTN_PUBLISH") : t(t1, "PM_BTN_SAVE")}
                name="priv_btn_SaveRole"
              />
            </div>
          ) : (
            <div className="bottom-btn">
              <CustomizeButton
                disableElevation
                variant="contained"
                csName="btn_hover"
                label={t(t1, "EDIT")}
                handleClick={handleBtnClick}
                name="priv_btn_EditRole"
              />
            </div>
          )}
        </Box>
        <Box component="form" noValidate autoComplete="off" className="remove-margin">
          <FormCard indicateRequired={false}>
            <Grid container spacing={0}>
              <Grid item md={12} sx={{ marginRight: '22px', maxWidth: '372px !important' }}>
                {type === "add" || type === "edit" ? (
                  <div className="select-box-main select-box-label">
                    {user?.PrivAttributes?.priv_lbl_SelectedRoleType?.PrivRole !== "NA" ? (
                      <GridSelectRadioButton
                        gridMd={12}
                        onClose={() => methods.trigger("RoleType")}
                        customClass="radio-select-lbl"
/* Conflict Resolution Candidate
                        errorLabelDisplay={t(t1, "ROLE_TYPE")}
                        selectValue={selectValue}
                        id="role-select"
                        sx={{
                          maxWidth: "372px !important",
                          "& .MuiTypography-root": {
                            fontSize: "14px !important",
*/
                        errorLabelDisplay={t(t1, 'ROLE_TYPE')}
                        selectValue={selectValue && roles.find((v) => v.name === selectValue)}
                        id="role-select"
                        sx={{
                          maxWidth: "372px !important",
                          '& .MuiTypography-root': {
                            fontSize: '14px !important'
                          },
                          "& .MuiSelect-select": {
                            paddingLeft: "5px !important",
                          },
                          "& .MuiInputBase-root": {
                            height: "25px !important",
                          },
                        }}
                        label={t(t1, "RD_MENU_SELECT_ROLE_TYPE")}
                        privId="priv_radio_RoleType"
                        name="RoleType"
                        noValueText={t(t1, "RD_MENU_SELECT_ROLE_TYPE")}
                        rules={{ required: true }}
                        isRequiredMsg = {'ERR_RD_MENU_SELECT_ROLE_TYPE_IS_REQUIRED'}
                        options={roles}
/* Conflict Resolution Candidate
                        disabled={user?.PrivAttributes?.priv_select_RoleType?.PrivRole === "RO" || type === "edit"}
 */
                        disabled={user?.PrivAttributes?.priv_select_RoleType?.PrivRole === "RO"}
                      />
                    ) : (
                      user?.PrivAttributes?.priv_lbl_SelectedRoleType?.PrivRole === "RO" && (
                        <>
                          {/* <Typography variant="label">{t(t1, "RD_LBL_SELECTED_ROLE_TYPE")}</Typography> */}
                          <FormControlLabel value={selectValue} control={<Radio checked />} label={selectValue} />
                        </>
                      )
                    )}
                  </div>
                ) : (
                  user?.PrivAttributes?.priv_lbl_RoleName?.PrivRole !== "NA" && (
                    <div className="box-main">
                      <Typography sx={{ fontWeight: "700 !important" }} variant="label">
                        {t(t1, "RD_LBL_SELECTED_ROLE_TYPE")}
                      </Typography>
                      <FormControlLabel
                        value={roledetails?.roleType}
                        control={<Radio checked />}
                        label={roledetails?.roleType}
                      />
                    </div>
                  )
                )}
              </Grid>
              <Grid item sm={8}>
                <div className="box-main-column m-0">
                  {type === "add" || type === "edit" ? (
                    <GridTextfield
                      customeStyle={{ fontWeight: "700 !important" }}
                      customClass="grid-text-label"
                      privId="priv_txt_RoleName"
                      name="role_name"
                      placeholder={t(t1, `RD_PH_ROLE_NAME`)}
                      label={t(t1, "RD_LBL_ROLE_NAME")}
                      rules={{
                        required: true,
/* Conflict Resolution Candidate
                        validate: (v) =>
                          methods?.getValues("role_name")
                            ? validateFullNameValues(
                                `${t(t1, "VALIDATION_MESSAGE")}`,
                                v,
                                `${t(t1, "RD_LBL_ROLE_NAME")}`
                              )
                            : null,
 */
                        pattern: {
                          value: allowCommaDot,
                          message: `${t(t1, "ERR_INVALID_RD_LBL_ROLE_NAME")}`,
                        },
                        maxLength: {
                          value: 50,
                          message: `${t(t1, "ERR_RD_LBL_ROLE_NAME_MAX_LENGTH_CHAR")}`,
                        },
                      }}
                      isRequiredMsg = {'ERR_RD_LBL_ROLE_NAME_IS_REQUIRED'}
/* Conflict Resolution Candidate
                      defaultValue={role?.id !== "role" ? role?.name : null}
 */
                      defaultValue={roledetails?.id !== "role" ? roledetails?.name : null}
                    />
                  ) : (
                    user?.PrivAttributes?.priv_lbl_RoleName?.PrivRole !== "NO" && (
                      <>
                        <Typography variant="label" sx={{ fontWeight: "700 !important" }} name="priv_lbl_RoleName">
                          {t(t1, "RD_LBL_ROLE_NAME")}
                        </Typography>
                        <Typography variant="p">{roledetails?.name} </Typography>
                      </>
                    )
                  )}
                </div>
              </Grid>
              <Grid item sm={4} sx={{ mt: 2, "& .MuiTextField-root": { width: "765px !important" } }}>
                <div className="box-main-column m-0">
                  {type === "add" || type === "edit" ? (
                    <GridTextfield
                      defaultValue={roledetails?.description}
                      customeStyle={{ fontWeight: "700 !important" }}
                      gridMd={9}
                      customClass="grid-text-label"
                      name="priv_txt_RoleDesc"
                      placeholder={t(t1, `RD_PH_ROLE_DESCRIPTION`)}
                      label={t(t1, "RD_LBL_NEW_ROLE_DESCRIPTION")}
                      rules={{
                        required: true,
/* Conflict Resolution Candidate
                        validate: (v) =>
                          methods?.getValues("priv_txt_RoleDesc")
                            ? validateFullNameValues(
                                `${t(t1, "VALIDATION_MESSAGE")}`,
                                v,
                                `${t(t1, "RD_LBL_NEW_ROLE_DESCRIPTION")}`
                              )
                            : null,
 */
                        pattern: {
                          value: allowCommaDot,
                          message: `${t(t1, "ERR_INVALID_RD_LBL_NEW_ROLE_DESCRIPTION")}`,
                        },
                        maxLength: {
                          value: 255,
                          message: `${t(t1, "ERR_RD_LBL_NEW_ROLE_DESCRIPTION_MAX_LENGTH_CHAR")}`,
                        },
                      }}
                      isRequiredMsg = {'ERR_RD_LBL_NEW_ROLE_DESCRIPTION_IS_REQUIRED'}
                    />
                  ) : (
                    user?.PrivAttributes?.priv_txt_RoleDesc?.PrivRole !== "NA" && (
                      <>
                        <Typography variant="label" sx={{ fontWeight: "700 !important" }} name="priv_txt_RoleDesc">
                          {t(t1, "RD_LBL_NEW_ROLE_DESCRIPTION")}
                        </Typography>
                        <Typography variant="p">{roledetails?.description}</Typography>
                      </>
                    )
                  )}
                </div>
              </Grid>
            </Grid>
          </FormCard>
        </Box>
      </Grid>
      {dialogMessage ? (
        <DialogMessage
          warning
          handleModalClick={handleModalSubmit}
          buttonMessage={false}
          title={t(t1, "WARNING")}
          message1={t(t1, "DIALOG_ERASE_MESSAGE")}
          message2={t(t1, "DIALOG_RESET_ROLE_DETAIL_MESSAGE")}
        // customeButtonMessage={t(t1, "DIALOG_RESET_CONTACT_US_MESSAGE")}
        />
      ) : null}
    </>
  );
}

export default  RoleDetailBoxCard;

