import {useEffect, useState, useRef} from "react";

export const useEffectOnce = (effect,depencency = [] ) => {

    const destroyFunc = useRef();
    const effectCalled = useRef(false);
    const renderAfterCalled = useRef(false);
    // eslint-disable-next-line no-unused-vars
    const [val, setVal] = useState(0);

    if (effectCalled.current) {
        renderAfterCalled.current = true;
    }

    useEffect(() => {
        if (!effectCalled.current) {
            destroyFunc.current = effect();
            effectCalled.current = true;
        }
        setVal(val => val + 1);

        return () => {
            if (!renderAfterCalled.current) {
                return;
            }
            if (destroyFunc.current) {
                destroyFunc.current();
            }
        };
        // eslint-disable-next-line
    }, depencency);
};