import { MUITableGrid } from "components/MUITableGrid";
import { StyledCardTitle, StyledForm } from "pages/ManageBatchTransfer/ManageBatchTransfer.style";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BATCH_TRANSFER_STATUS, ERROR, getCurrentPageName, t, getFilterForColumn } from "utils/constants";
import { Box, Grid } from "@mui/material";
import { CustomizeButton } from "components/CustomizeButton";
import { useDispatch } from "react-redux";
import { getAccountHoldings, getBatchHoldings, setIsEditMode, setSelectedHoldings, updateTransferBatch } from "store/batchTransferSlice";
import { useJurisdictionUserRole } from "hooks/useUserRole";
import { useParams } from "react-router-dom";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { setModelMessage } from "store/commonSlice";
import { holdingsCheckForPCRA, isEditable } from "pages/ManageBatchTransfer/constants";
import { CustomeDialogMessage } from "components/CustomeDialogMessage";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { sortHoldingsTable } from "utils/support";
import { SelectedHoldingsSchema } from "./columnSchema";
import { UploadTransfersModal } from "../UploadTransfersModal";

function SelectedHoldingsGrid({
  selectedHoldingsDetails: {
    selectedHoldings,
    juriAccount,
    accountHoldings,
    batchDetails,
    accountDetailPCRA,
    transferType,
  },
  batchStatus,
  flp
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const { isAdmin } = useJurisdictionUserRole();
  const { t: t1 } = useTranslation(getCurrentPageName());
  const [addNewRowsToggle, setAddNewRowsToggle] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const apiRef = useGridApiRef();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleAddNewRows = () => {
    dispatch(getAccountHoldings(juriAccount?.accountNumber));
    setAddNewRowsToggle(true);
    dispatch(setIsEditMode({ key: "editSelectedHoldings", value: true }));
  };

  const rows = useMemo(() => {
    if (addNewRowsToggle && !accountHoldings.isLoading) {
      const idSet = new Set(selectedHoldings.map((item) => item.id));
      return accountHoldings?.data?.filter(
        (item) =>
          !idSet.has(item.id) &&
          item?.availableHoldingQuantity > "0" &&
          holdingsCheckForPCRA({ type: item?.type, transferTypeName: transferType?.name, vintage: item?.vintage })
      );
    }
    return selectedHoldings;
  }, [accountHoldings?.data, accountHoldings.isLoading, addNewRowsToggle, selectedHoldings, transferType?.name]);

  const handleUpdateHoldings = () => {

    if (batchStatus?.name === BATCH_TRANSFER_STATUS.ACTIVE) {
      dispatch(setSelectedHoldings([...selectedHoldings, ...Array.from(apiRef.current.getSelectedRows(), ([, value]) => ({ ...value }))]))
      setAddNewRowsToggle(false);
      dispatch(setIsEditMode({ key: "editSelectedHoldings", value: false }));
      return
    }

    const payload = {
      batchName: batchDetails?.batchName,
      comment: batchDetails?.comment,
      status: batchStatus?.ID,
      selectedHoldingIds: Array.from(apiRef.current.getSelectedRows(), ([, value]) => value.id),
    };

    dispatch(updateTransferBatch({ id: params?.id, payload, transferTypeId: transferType?.id }))
      .then(() => {
        setAddNewRowsToggle(false);
        dispatch(setIsEditMode({ key: "editSelectedHoldings", value: false }));
        dispatch(getBatchHoldings(params.id));
      })
      .catch((e) => {
        const data = {
          title: ERROR,
          message1: t(t1, e?.response?.data?.data),
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      });
  };
  const selectedHoldingsData = sortHoldingsTable(rows);
  const valueOptions = useMemo(
    () => getFilterForColumn(selectedHoldingsData, t, t1, ['type', 'subType']),
    [selectedHoldingsData, t1]
  );
  const columns = useMemo(
    () => [...SelectedHoldingsSchema(t1, batchStatus?.name, isAdmin, addNewRowsToggle, valueOptions)],
    [t1, batchStatus?.name, isAdmin, addNewRowsToggle, valueOptions]
  );

  return (
    <StyledForm>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <StyledCardTitle variant="h2">{`${t(t1, "SELECTED_HOLDINGS")}`}</StyledCardTitle>
        {!!isAdmin && isEditable(batchStatus?.name) && (
          <CustomizeButton
            variant="contained"
            disableElevation
            label={t(t1, "UPLOAD_BTN")}
            handleClick={() => {
              setModalOpen(true);
            }}
          />
        )}
      </Box>
      <MUITableGrid
        isExport
        isSearch
        isSettings
        rows={selectedHoldingsData}
        columns={columns}
        fieldsMetaData={flp?.metaData}
        fileName={t(t1, "SELECTED_HOLDINGS")}
        count={rows?.length}
        page={1}
        customTableStyle={{ marginTop: "1rem" }}
        initialSortedFields={[{ field: "vintage", sort: "asc" }]}
        checkboxSelection={addNewRowsToggle}
        selectionModel={selectedRows}
        onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
        apiRef={apiRef}
        enablePrintView
        customPinnedColumns={{ left: [GRID_CHECKBOX_SELECTION_COL_DEF.field], right: ["QuickActions"] }}
      />
      {((!!isAdmin && !addNewRowsToggle && (batchStatus?.name === BATCH_TRANSFER_STATUS.ACTIVE || isEditable(batchStatus?.name))) || addNewRowsToggle) && (
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          mt={2}
        >
          <Grid item>
            {!!isAdmin && !addNewRowsToggle && (batchStatus?.name === BATCH_TRANSFER_STATUS.ACTIVE || isEditable(batchStatus?.name)) && (
              <CustomizeButton
                variant="outlined"
                csName="rc"
                handleClick={handleAddNewRows}
                label={t(t1, "ADD_MORE")}
                customStyle={{ marginLeft: 0, marginBottom: "0px" }}
              />
            )}
          </Grid>
          {addNewRowsToggle && (
            <Grid
              item
              display="flex"
            >
              <Grid item>
                <CustomizeButton
                  variant="outlined"
                  csName="rc"
                  handleClick={() => {
                    setOpenConfirmDialog(true);
                  }}
                  label={t(t1, "CANCEL_BUTTON")}
                />
              </Grid>
              <Grid item>
                <CustomizeButton
                  label={t(t1, "ADD_HOLDINGS")}
                  disabled={addNewRowsToggle && !selectedRows.length}
                  handleClick={handleUpdateHoldings}
                  customStyle={{
                    padding: 0,
                    width: "150px",
                    lineHeight: 1,
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {modalOpen && (
        <UploadTransfersModal
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          transferType={transferType}
          accountDetailPCRA={accountDetailPCRA}
        />
      )}
      <CustomeDialogMessage
        title={t(t1, "CANCEL_CREATE_MODAL_TITLE")}
        continueBtn={t(t1, "CONTINUE")}
        open={openConfirmDialog}
        handleModalCancel={() => {
          setOpenConfirmDialog(false);
        }}
        handleClose={() => {
          setOpenConfirmDialog(false);
        }}
        message1={t(t1, "CONFIRM_DELETE_MESSAGE")}
        handleContinue={() => {
          setAddNewRowsToggle(false);
          dispatch(setIsEditMode({ key: "editSelectedHoldings", value: false }));
          setOpenConfirmDialog(false);
        }}
        borderColor="#CA2C1C"
        warning
      />
    </StyledForm>
  );
}

export default React.memo(SelectedHoldingsGrid);
