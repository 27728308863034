import { MUITableGrid } from "components/MUITableGrid";
import { StyledCardTitle, StyledForm } from "pages/ManageBatchTransfer/ManageBatchTransfer.style";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BATCH_TRANSFER_STATUS, formatNumber, getCurrentPageName, t, getFilterForColumn } from "utils/constants";
import { Box, Grid, List, Typography } from "@mui/material";
import { CustomizeButton } from "components/CustomizeButton";
import { useJurisdictionUserRole } from "hooks/useUserRole";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { FormProvider } from "components/FormProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getErrorMessage, isEditable, receivingAccountValidationSchema, compareArrays } from "pages/ManageBatchTransfer/constants";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteTransferFromBatch, editTransfer, getBatchHoldings, getBatchTransferAccountDetails, getBatchandAccountDetailsById, setIsEditMode, setSelectedTransferIds } from "store/batchTransferSlice";
import { setModelMessage } from "store/commonSlice";
import { CustomeDialogMessage } from "components/CustomeDialogMessage";
import { StyledErrorListItems } from "pages/AddBudgetRecord/AddBudgetRecord.style";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { BatchTransfersReceivingAccountSchema } from "./columnSchema";

function BatchTransfers({ batchTransfersDetails: { batchReceivingAccounts, selectedTransferIds, transferType }, batchStatus, flp }) {
  const { isAdmin } = useJurisdictionUserRole();
  const { t: t1 } = useTranslation(getCurrentPageName());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [cancelConfirmDialog, setCancelConfirmDialog] = useState(false);
  const { currencyList } = useSelector(({ batchTransfer }) => batchTransfer);
  const [validationErrors, setValidationErrors] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const [removeRowsToggle, setRemoveRowsToggle] = useState(false);

  const apiRef = useGridApiRef();

  const { selectedLanguage } = useSelector(({ jurisdiction }) => jurisdiction);
  const { accountDetailPCRA } = useSelector(({ batchTransfer }) => batchTransfer);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      receivingAccountDetails: batchReceivingAccounts?.map((receivingAccount, index) => ({ ...receivingAccount, rowId: index, editMode: false }))
    },
    resolver: yupResolver(receivingAccountValidationSchema(t1, selectedLanguage)),
  });
  const {
    reset,
    formState: { errors },
    clearErrors,
    setValue,
    watch
  } = methods;

  const { receivingAccountDetails = [] } = watch()

  useEffect(() => {
    setValue("receivingAccountDetails", batchReceivingAccounts?.map((receivingAccount, index) => ({
      ...receivingAccount,
      price: selectedLanguage === "French" && receivingAccount.price ? receivingAccount.price.replace(".", ",") : receivingAccount.price,
      rowId: index,
      editMode: false
    })))
  }, [batchReceivingAccounts, setValue, selectedLanguage]);

  const onRowEditModeChange = useCallback(
    (id) => {
      const data = receivingAccountDetails.map((row) => (row?.rowId === id ? { ...row, editMode: true } : { ...row }));
      setValue("receivingAccountDetails", [...data])
    },
    [receivingAccountDetails, setValue]
  );

  const isInEditMode = receivingAccountDetails.some((rowItem) => rowItem.editMode) || removeRowsToggle

  const arrBatchTransfer = useMemo(
    () => receivingAccountDetails?.length && receivingAccountDetails[0]?.id ? [...receivingAccountDetails] : [],
    [receivingAccountDetails]
  );

  const valueOptions = useMemo(
    () => getFilterForColumn(arrBatchTransfer, t, t1, ['type', 'subType', 'accountType', 'accountStatus']),
    [arrBatchTransfer, t1]
  );

  const columns = useMemo(
    () => [
      ...BatchTransfersReceivingAccountSchema(
        t1,
        batchStatus,
        isAdmin,
        onRowEditModeChange,
        removeRowsToggle,
        setOpenConfirmDialog,
        currencyList,
        transferType?.name,
        isInEditMode,
        selectedLanguage,
        valueOptions
      ),
    ],
    [t1, batchStatus, isAdmin, onRowEditModeChange, removeRowsToggle, currencyList, transferType?.name, isInEditMode, selectedLanguage, valueOptions]
  );

  const totalProposedQuantity = useMemo(
    () =>
      batchReceivingAccounts.reduce((acc, holding) => {
        const qty = holding.proposedQuantity?.replaceAll(",", "");
        return acc + BigInt(qty ?? 0);
      }, 0n),
    [batchReceivingAccounts]
  );

  const handleOnRemoveRows = useCallback(() => {
    const selectedTransferIds = Array.from(apiRef.current.getSelectedRows(), ([, value]) => (value?.transferUuid));
    dispatch(setSelectedTransferIds(selectedTransferIds.join(',')))
    setOpenConfirmDialog(true)
    clearErrors()
  }, [apiRef, clearErrors, dispatch]);

  const handleDeleteRows = useCallback(() => {
    setOpenConfirmDialog(false)
    dispatch(deleteTransferFromBatch({ id: params.id, transferIds: selectedTransferIds }))
      .then(() => {
        dispatch(
          setModelMessage({
            title: t(t1, "SUCCESS_TITLE"),
            message1: t(t1, "DELETE_SUCESS_MESSAGE"),
          })
        );
        methods.reset({});
        dispatch(getBatchandAccountDetailsById(params.id));
        dispatch(getBatchHoldings(params.id));
        dispatch(getBatchTransferAccountDetails(params.id));
        dispatch(setSelectedTransferIds(""));
        dispatch(setIsEditMode({ key: "editTransferRecords", value: false }))
        setValidationErrors([]);
      })
      .catch((e) => {
        dispatch(setSelectedTransferIds(""));
        dispatch(
          setModelMessage({
            title: t(t1, "ERROR"),
            message1: t(t1, e?.response?.data?.data),
            error: true,
          }))
      });
    setSelectedRows([])
    setRemoveRowsToggle(false);
  }, [dispatch, methods, params.id, selectedTransferIds, t1])

  const onSubmit = () => {
    if (removeRowsToggle) {
      handleOnRemoveRows();
    } else {
      handleEdit()
      setRemoveRowsToggle(false);
    }
  };

  useEffect(() => {
    dispatch(setIsEditMode({ key: "editTransferRecords", value: receivingAccountDetails.some((rowItem) => rowItem.editMode) }))
  }, [dispatch, receivingAccountDetails])

  const handleEdit = useCallback(() => {
    const editedRows = receivingAccountDetails.filter((item) => item.editMode)
    if (editedRows.length) {
      const payload = editedRows.map(({ holdingId, currency, price, entityReference, accountNumber, proposedQuantity, batchHoldingTransferId, batchHoldingDetailsId, transferUuid }) => ({
        batchHoldingTranferDetailsId: batchHoldingTransferId,
        holdingId,
        transferId: transferUuid,
        currencyJurisdictionDetailsId: currency || undefined,
        entityReferenceCode: entityReference || undefined,
        receivingAccountNumber: accountNumber || undefined,
        price: price ? Number(price.replace(",", ".")) : undefined,
        proposedQuantity,
        batchHoldingDetailsId
      }));
      dispatch(editTransfer({ id: params?.id, transferTypeId: transferType?.id, payload }))
        .then(() => {
          dispatch(
            setModelMessage({
              title: t(t1, "SUCCESS_TITLE"),
              message1: t(t1, "UPDATE_RECEIVING_ACCOUNT_SUCCESS"),
            })
          );
          dispatch(getBatchandAccountDetailsById(params.id));
          dispatch(getBatchHoldings(params.id));
          dispatch(getBatchTransferAccountDetails(params.id))
          clearErrors();
          setValidationErrors([]);
        })
        .catch((e) => {
          const errorList = getErrorMessage(e?.response?.data?.data, t1, accountDetailPCRA);
          setValidationErrors(errorList);
        });
    }
  }, [clearErrors, dispatch, params.id, receivingAccountDetails, t1, transferType?.id])

  const onInvalid = () => {
    if (removeRowsToggle) {
      handleOnRemoveRows();
    }
  };

  const errorList = useMemo(
    () =>
      ["proposedQuantity", "price", "currency"].map((key) => ({
        key,
        message: errors.receivingAccountDetails?.find((error) => error?.[key])?.[key]?.message,
      })),
    [errors]
  );

  useEffect(() => {
    if (!removeRowsToggle) {
      clearErrors()
      setValidationErrors([]);
    }
  }, [clearErrors, removeRowsToggle])

  const shouldContinueDisable =
    removeRowsToggle ? !selectedRows.length : receivingAccountDetails.some((rowItem) => !rowItem?.proposedQuantity || !rowItem?.accountNumber)

  return (
    <StyledForm sx={{ paddingBottom: (batchStatus !== BATCH_TRANSFER_STATUS.PROPOSED) ? "40px" : "20px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <StyledCardTitle variant="h2">{`${t(t1, "BATCH_TRANSFERS")}`}</StyledCardTitle>
        {!removeRowsToggle && !!isAdmin && isEditable(batchStatus) && !receivingAccountDetails.some((rowItem) => rowItem.editMode) && (
          <CustomizeButton
            variant="contained"
            disableElevation
            label={t(t1, "REMOVE")}
            name="priv_modal_Edit"
            csName="rc"
            handleClick={() => {
              setRemoveRowsToggle(true)
              dispatch(setIsEditMode({ key: "editTransferRecords", value: true }))
            }}
          />
        )}
      </Box>
      <FormProvider
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit, onInvalid)}
      >
        <MUITableGrid
          isExport
          isSearch
          isSettings
          rows={receivingAccountDetails?.length && receivingAccountDetails[0]?.id ? [...receivingAccountDetails] : []}
          columns={columns}
          page={1}
          customTableStyle={{ marginTop: "1rem" }}
          initialSortedFields={[{ field: "vintage", sort: "asc" }]}
          checkboxSelection={removeRowsToggle}
          fileName={t(t1, "BATCH_TRANSFERS")}
          selectionModel={selectedRows}
          onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
          fieldsMetaData={flp?.metaData}
          apiRef={apiRef}
          enablePrintView
          pinnedColumns={{ left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "transferId"], right: ["QuickActions"] }}
        />
        {batchStatus === BATCH_TRANSFER_STATUS.PROPOSED && (
          <Box
            justifyContent="end"
            textAlign="right"
            paddingY={2.5}
          >
            <Typography
              fontWeight={700}
              variant="body2"
            >
              {t(t1, "TOTAL_PROPOSED_QUANTITY")}
            </Typography>
            <Typography variant="body2">{formatNumber(totalProposedQuantity)}</Typography>
          </Box>
        )}
        {(errors?.receivingAccountDetails?.length > 0 || validationErrors.length > 0) && (
          <Grid
            container
            mt={2}
          >
            <List dense>
              {errors?.receivingAccountDetails?.some((item) => item?.proposedQuantity?.type === "required") && (
                <StyledErrorListItems key={"quantity__required_error"}>{`${t(
                  t1,
                  "VALIDATION_PROPOSED_QUANTITY_REQUIRED"
                )}`}</StyledErrorListItems>
              )}
              {errors?.receivingAccountDetails?.some((item) => item?.accountNumber) && (
                <StyledErrorListItems key={"account__required_error"}>{`${t(
                  t1,
                  "ACCOUNT_NUMBER_FORMAT_INCORRECT"
                )} ${JSON.stringify(errors?.receivingAccountDetails?.reduce((prev, curr) => curr?.accountNumber?.message ? [...prev, curr?.accountNumber?.message] : [], []))}`}</StyledErrorListItems>
              )}
              {errorList.map((error) => (error.message && <StyledErrorListItems key={error.key}>{error.message}</StyledErrorListItems>))}
              {validationErrors.map((error) => (
                <StyledErrorListItems key={error.messageKey}>{error.messageKey}</StyledErrorListItems>
              ))}
            </List>
          </Grid>
        )}
        {(receivingAccountDetails.some((rowItem) => rowItem.editMode) || removeRowsToggle) && (
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            mt={2}
          >
            <Grid
              item
              display="flex"
            >
              <Grid item>
                <CustomizeButton
                  variant="outlined"
                  csName="rc"
                  handleClick={() => {
                    setCancelConfirmDialog(true);
                  }}
                  label={t(t1, "CANCEL_BUTTON")}
                />
              </Grid>
              <Grid item>
                <CustomizeButton
                  label={t(t1, "SAVE_EDIT_BUTTON")}
                  type="submit"
                  disabled={shouldContinueDisable || compareArrays(receivingAccountDetails,batchReceivingAccounts)&&!removeRowsToggle}
                  customStyle={{
                    padding: 0,
                    width: "150px",
                    lineHeight: 1,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </FormProvider>
      <CustomeDialogMessage
        title={t(t1, "CONFIRM_DELETE")}
        continueBtn={t(t1, "CONTINUE")}
        open={openConfirmDialog}
        handleModalCancel={() => {
          dispatch(setSelectedTransferIds(""));
          setOpenConfirmDialog(false);
        }}
        handleClose={() => {
          dispatch(setSelectedTransferIds(""));
          setOpenConfirmDialog(false);
        }}
        message1={t(t1, "CONFIRM_DELETE_MESSAGE")}
        handleContinue={handleDeleteRows}
        borderColor="#283459"
      />
      <CustomeDialogMessage
        title={t(t1, "CANCEL_CREATE_MODAL_TITLE")}
        continueBtn={t(t1, "CONTINUE")}
        open={cancelConfirmDialog}
        handleModalCancel={() => {
          setCancelConfirmDialog(false);
        }}
        handleClose={() => {
          setCancelConfirmDialog(false);
        }}
        message1={t(t1, "CONFIRM_DELETE_MESSAGE")}
        handleContinue={() => {
          dispatch(setSelectedTransferIds(""));
          clearErrors();
          setValidationErrors([]);
          setRemoveRowsToggle(false);
          setSelectedRows([]);
          dispatch(setIsEditMode({ key: "editTransferRecords", value: false }))
          reset({
            receivingAccountDetails: batchReceivingAccounts?.map((receivingAccount, index) => ({
              ...receivingAccount,
              rowId: index,
              editMode: false,
            })),
          });
          setCancelConfirmDialog(false);
        }}
        borderColor="#CA2C1C"
        warning
      />
    </StyledForm>
  );
}

export default BatchTransfers;
