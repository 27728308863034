import { Box, Typography, Button, Modal, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ReCAPTCHA from "react-google-recaptcha";
import {  t, getCurrentPageName } from "utils/constants";
import { useTranslation, withTranslation } from "react-i18next";
import Style from "./styles";

function AuthCommonModal(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const [verifyCaptcha, setVerifyCaptcha] = useState(false);
  const captchaRef = useRef(null);

  const verifyCaptchaFun = () => {
    setVerifyCaptcha(true);
  };
  return (
    <Modal
      open={props.isModal}
      onClose={(_, reason) => {
        // console.log('reason',reason );
        if (reason !== "backdropClick") {
          props.isClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={Style.ForgotPasswordModal}>
        <Typography id="modal-modal-title" variant="h2">
          {props.header}
          <CloseIcon color="primary" onClick={props.isClose} />
        </Typography>
        <Typography sx={{ mt: 4 }} variant="h6">
          {props.body}
        </Typography>
        <TextField
          id="emailId"
          name="emailId"
          value={props.value}
          placeholder={props.placeHolder}
          onChange={props.inputChange}
          inputProps={{
            "data-testid": "forgotId",
          }}
        />
        <Box sx={{ height: "20px" }}>
          {props.errorMessage && <span>{props.errorMessage}</span>}
        </Box>
        <Box sx={{ pt: "5px" }}>
          <ReCAPTCHA ref={captchaRef} onChange={verifyCaptchaFun}
            sitekey={process.env.REACT_APP_SITE_KEY}
            onExpired={() => setVerifyCaptcha(false)}
          />
        </Box>
        <Button
          className="ContinueBtn"
          disabled={!props?.value || Number(props.value?.length) === 0 || props.errorMessage}
          variant="contained"
          color="primary"
          onClick={props.handleClick}
        >
          {t(t1, "CONTINUE")}
        </Button>
      </Box>
    </Modal>
  );
}

export default AuthCommonModal;
