import { Box, Button, Grid, Link } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";
import { PageTitle } from "components";
import { Loader } from "components/Loader";
import { MUITableGrid } from "components/MUITableGrid";
import { useEffectOnce } from "hooks/useEffectOnce";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setLoader, setModelMessage } from "store/commonSlice";
import { getAssociateRetiredFacility, getFacilities, setAssociateFacility, updateRatiredFacilityStatus } from "store/entityProfileSlice";
import { ASSOCIATE_FACILITY_STATUS, getCurrentPageName, isEmpty, t, JURISDICTIONS_NAMES, showOnlyPermittedFields, ShowInlinePurposeAddressFacility, ENTITY_TYPE_NAMES, getProposedEntityType, SUCCESS } from "utils/constants";
import logger from "utils/logger";

export default function AssociateRetiredFacility() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { loader } = useSelector(({ common }) => common);

  const { t: t1 } = useTranslation(getCurrentPageName());
  // eslint-disable-next-line prefer-const
  let { retiredFacility, facilities, facilitiesApiResponse } = useSelector(({ entityProfile }) => entityProfile);
  const { selectedJurisdiction, EntitiesData } = useSelector(({ entityApplication }) => entityApplication);
  const { entities } = useSelector(({ entityProfile }) => entityProfile);
  const { proposeChanges } = EntitiesData?.data || {};

  const setExcelData = React.useCallback((retiredFacility) =>{
    const rows = retiredFacility?.map((retiredFacility, index) => {
          return {
            id: index,
            facilityId: retiredFacility?.id,
            facilityName: retiredFacility?.facilityName,
            operatingName: retiredFacility?.operatingName,
            ghgEmissionsReportingId: retiredFacility?.ghgEmissionsReportingId,
            physicalAddress: ShowInlinePurposeAddressFacility(retiredFacility?.physicalAddress,t,t1),
          };
        });
     return rows
  },[retiredFacility]);
  
  const { data, fieldAccess, metaData}  = retiredFacility;
  retiredFacility = setExcelData(data);

  const { id } = useParams();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseWithRedirect = () => {

    if (!isEmpty(searchParams.get('redirect'))) {
      navigate(searchParams.get('redirect'))
    }
    navigate(`/jurisdiction-entity-actions/${id}`)
  }
  const navigateAway = ()=>{
    navigate("/permissiondenied");
  }
  useEffectOnce(() => {
    dispatch(getAssociateRetiredFacility(t, t1,navigateAway));
    dispatch(getFacilities(id, t, t1));
  });

  if(
    entities?.response?.data?.messageKey === "USER_IS_NOT_AUTHORIZED" || 
    (
      entities?.messageKey === SUCCESS && 
      entities?.data?.entityType?.name && 
      !(
        !getProposedEntityType(ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION, proposeChanges) && 
        (
          entities?.data?.entityType?.name === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY || 
          getProposedEntityType(ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY, proposeChanges)
        )
      )
    ) ||
    (facilitiesApiResponse?.messageKey === SUCCESS && facilities.length > 0 && entities?.data?.entityStatus?.name ===  'PENDING' &&  facilities?.every(facility => !facility.facilityIsInRegistry))
  ) {
    navigateAway();
  }


  const saveAssociateFacility = (rowData) => {
    const statusId = ASSOCIATE_FACILITY_STATUS.ACTIVE;
    dispatch(setLoader(true))
    updateRatiredFacilityStatus({ entityId: id, facilityId: rowData?.row?.facilityId, facilityStatus: statusId }).then(() => {
      dispatch(setAssociateFacility(statusId))
      dispatch(setLoader(false))
      if (!isEmpty(searchParams.get('redirect'))) {
        navigate(searchParams.get('redirect'), { state: { showPopup: true } })
        const data = {
          title: t(t1, "SUCCESS"),
          message1: t(t1, "FACILITY_IS_ASSOCIATED_SUCCESSFULLY"),
          error: false,
          isDefaultDisplay: false
        };
        dispatch(setModelMessage(data));
      } else {
        navigate(`/jurisdiction-entity-actions/${id}`, { state: { showPopup: true } })
      }
      handleClickOpen()
    })
      .catch((error) => {
        dispatch(setLoader(false))
        logger({ error, name: "updateRetiredFacilityStatus" });
      });

  }
  const headerNameLabel = selectedJurisdiction === JURISDICTIONS_NAMES.WASHINGTON_STATE ? t(t1, "EPA_OR_WASHINGTON_GREENHOUSE_GAS_REPORTING_ID") : t(t1, "GHG_EMISSIONS_REPORTING_ID");
  
  const associateRetiredFacilityColumn = [
    {
      id: 1,
      field: "facilityName",
      headerName: t(t1, "FACILITY_NAME"),
      flex: 1,
      align: "center",
      groupable: false,
      hideable: false,
      renderCell: (rowData) => (
        <Link 
        className="link-cell"
        sx={{ cursor: "pointer" }} 
        onClick={() => saveAssociateFacility(rowData)} target="_blank" tabIndex={0}>
          {rowData.value}
        </Link>

      ),
    },
    { id: 2, field: "operatingName", headerName: t(t1, "OPERATING_NAME"), flex: 1, align: "center" },
    {
      id: 3,
      field: "ghgEmissionsReportingId",
      headerName: headerNameLabel,
      flex: 1,
      align: "center",
    },
    {
      id: 4, field: "physicalAddress", headerName: t(t1, "PHYSICAL_ADDRESS"), flex: 1, align: "center",resizable: false,
      renderCell: (data) => {
        return <span>{data?.row?.physicalAddress}</span>
      }
    },
  ];


  return (
    <Container maxWidth="xl">

      <Grid container spacing={2.5}>
        <Grid item md={12}>
          <Box sx={{ display: "flex", mt: 3, mb: 3 }}>
            <PageTitle titleNoSpace title={t(t1, "ASSOCIATE_FACILITIES")} />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ background: "white", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.35)", marginTop: "0", padding: "20px 20px 40px 20px", marginBottom: "20px" }}>
        <Grid item md={12} sx={{ mt: 0 }}>
          <Typography sx={{ display: "flex", mt: 0, mb: 2.5, fontSize:"24px", fontWeight:"300", color:"#000", lineHeight:"normal" }}>
            {t(t1, "RETIRED_FACILITIES")}
          </Typography>
          <Box>
            <MUITableGrid isExport isSearch
              initialSortedFields={[
                { field: "legal_name", sort: "asc" },
                { field: "ghgEmissionsReportingId", sort: "asc" },
              ]}
              isSettings 
              rows={retiredFacility} 
              columns={showOnlyPermittedFields(associateRetiredFacilityColumn, metaData,fieldAccess, 'retiredFacilities')}
              fileName={t(t1, "RETIRED_FACILITIES")}
              />
          </Box>
        </Grid>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            {t(t1, 'FACILITY_IS_ASSOCIATED_SUCCESSFULLY.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithRedirect} autoFocus>{t(t1, 'OK')}</Button>
        </DialogActions>
      </Dialog>
      <Loader loader={loader} />
    </Container>



  );
}
