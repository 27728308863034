import { createSlice } from "@reduxjs/toolkit";
import fetcher, { fetcherGet, fetcherPatch, fetcherPost } from "utils/fetcher";
import { APIS } from "utils/constants";
import {
  COMPONENT_APPROVE_STATUS,
  COMPONENT_CANCEL_PROPOSED_STATUS,
  COMPONENT_EDIT_JURISDICTION,
  COMPONENT_JURISDICTION_DETAILS,
  COMPONENT_REVISIONS_REQUESTED,
  COMPONENT_SETUP_NEW_JURISDICTION,
  PAGE_EDIT_JURISDICTION,
  PAGE_JURISIDTCION_ACCOUNT_DETAILS,
  PAGE_MANAGE_JURISDICTION_DETAILS,
  PAGE_SETUP_NEW_JURISDICTION,
  transformJurisdictionCurrencyAndLanguageResponse,
  transformJurisdictionInoResponse,
  transformResponseToDropdown,
} from "pages/CreateParticipatingJurisdiction/constants";
import { MANAGE_JURI_STATUS_COMPONENTS, MANAGE_JURI_STATUS_PAGES } from "pages/ManageJurisdictionStatus/support";
import { getPermissionObj } from "utils/support";
import { transformData } from "pages/Approvals/support";
import { setLoader } from "./commonSlice";

const initialState = {
  jurisdictioninformation: {
    addressLine1: null,
    addressLine2: null,
    city: null,
    region: null,
    zipCode: null,
    dateOfJoining: null,
    languageDetailsIds: [],
    currencyDetailIds: [],
    jurisdictionDisplayName:"",
    jurisdictionId:"",
    shortCode:"",
    purchaseLimitParameter:null
  },
  jurisdictionStatus: {},
  jurisdictionOrgId: null,
  languages: [],
  currencies: [],
  countries: [],
  states: [],
  isEditMode: false,
  linkedJurisdictions: { isLoading: false, data: null },
  jurisdictionRequestStatusChangeHistory: { isLoading: false, data: null },
  openAddUserPopup: false,
  openAddAccountPopup: false,
  openEditAccountPopup: false,
  openEditUserPopup: false,
  userIdToEdit: null,
  accountIdToEdit: null,
  requestStatus: null,
  linkageDetails: null,
  openCancelDialog: false,
  selectedLinkedJurisdiction: [],
  proposedJurisdictions: [],
  requestStatusName: null,
  linkRequestDetails: [],
  jurisdictionPLStyle: {},
};

const onboardJurisdictionSlice = createSlice({
  name: "onboardJurisdiction",
  initialState,
  reducers: {
    setLanguages: (state, action) => {
      state.languages = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setAllCountries: (state, action) => {
      state.countries = action.payload;
    },
    setStates: (state, action) => {
      state.states = action.payload;
    },
    setJurisditionInformation: (state, action) => {
      state.jurisdictioninformation = { ...state.jurisdictioninformation, ...action.payload };
    },
    setJurisditionStatus: (state, action) => {
      state.jurisdictionStatus = action.payload;
    },
    setIsEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    resetJurisdictionInformation: (state) => {
      state.jurisdictioninformation = initialState.jurisdictioninformation;
      state.isEditMode = initialState.isEditMode;
    },
    setLinkedJurisdictions: (state, action) => {
      state.linkedJurisdictions = action.payload;
    },
    setJurisdictionRequestStatusChangeHistory: (state, action) => {
      state.jurisdictionRequestStatusChangeHistory = action.payload;
    },
    setOpenAddUserPopup: (state, action) => {
      state.openAddUserPopup = action.payload;
    },
    setOpenEditUserPopup: (state, action) => {
      state.openEditUserPopup = action.payload;
    },
    setOpenAddAccountPopup: (state, action) => {
      state.openAddAccountPopup = action.payload;
    },
    setOpenEditAccountPopup: (state, action) => {
      state.openEditAccountPopup = action.payload;
    },
    setUserIdToEdit: (state, action) => {
      state.userIdToEdit = action.payload;
    },
    setAccountIdToEdit: (state, action) => {
      state.accountIdToEdit = action.payload;
    },
    setJurisdictionOrgId: (state, action) => {
      state.jurisdictionOrgId = action.payload;
    },
    setLanguageAndCurrencyDetailIDs: (state, action) => {
      state.jurisdictioninformation.currencyDetailIds = action.payload.currencyDetailIds;
      state.jurisdictioninformation.languageDetailsIds = action.payload.languageDetailsIds;
    },
    setRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
    setLinkageDetails: (state, action) => {
      state.linkageDetails = action.payload;
    },
    setOpenCancelDialog: (state, action) => {
      state.openCancelDialog = action.payload;
    },
    setSelectedLinkedJurisdiction: (state, action) => {
      state.selectedLinkedJurisdiction = action.payload;
    },
    setProposedJurisdictions: (state, action) => {
      state.proposedJurisdictions = action.payload;
    },
    setRequestStatusName: (state, action) => {
      state.requestStatusName = action.payload;
    },
    setLinkRequestDetails: (state, action) => {
      state.linkRequestDetails = action.payload;
    },
    setJurisdictionPLStyle: (state, action) =>{
      state.jurisdictionPLStyle =action.payload
    }
  },
});

export default onboardJurisdictionSlice.reducer;

export const {
  setLanguages,
  setCurrencies,
  setAllCountries,
  setStates,
  setJurisditionInformation,
  setJurisditionStatus,
  setIsEditMode,
  resetJurisdictionInformation,
  setLinkedJurisdictions,
  setJurisdictionRequestStatusChangeHistory,
  setOpenAddUserPopup,
  setOpenEditUserPopup,
  setOpenAddAccountPopup,
  setOpenEditAccountPopup,
  setUserIdToEdit,
  setAccountIdToEdit,
  setJurisdictionOrgId,
  setLanguageAndCurrencyDetailIDs,
  setRequestStatus,
  setLinkageDetails,
  setOpenCancelDialog,
  setSelectedLinkedJurisdiction,
  setProposedJurisdictions,
  setRequestStatusName,
  setLinkRequestDetails,
  setJurisdictionPLStyle
} = onboardJurisdictionSlice.actions;

export function getAllLanguages() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const response = await fetcherGet(`${APIS.ONBOARD_JURISDICTION}/languages`, {});
      const { data } = response;
      dispatch(setLanguages(transformResponseToDropdown(data)));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLanguages([]));
      dispatch(setLoader(false));
    }
  };
}

export function getAllCurrencies() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const response = await fetcherGet(`${APIS.ONBOARD_JURISDICTION}/currencies`, {});
      const { data } = response;
      dispatch(setCurrencies(transformResponseToDropdown(data)));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setCurrencies([]));
      dispatch(setLoader(false));
    }
  };
}

export function getAllCountriesData() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const response = await fetcherGet(`${APIS.ONBOARD_JURISDICTION}/countries`, {});
      const { data } = response;
      dispatch(setAllCountries(data));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setAllCountries([]));
      dispatch(setLoader(false));
    }
  };
}

export function getStates(countryId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const response = await fetcherGet(`${APIS.ONBOARD_JURISDICTION}/states/${countryId}`, {});
      const { data } = response;
      dispatch(setStates(data));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setStates([]));
      dispatch(setLoader(false));
    }
  };
}

export function OnboardJurisdiction(payload) {
  return (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.ONBOARD_JURISDICTION}/jurisdictionOnboarding/addOnboardingJurisdiction`,
      {
        ...payload,
      },
      {
        pageName: PAGE_SETUP_NEW_JURISDICTION,
        componentName: COMPONENT_SETUP_NEW_JURISDICTION,
      }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getJurisdictionInformation(jurisdictionId, t1) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setJurisditionInformation({}));
    dispatch(setJurisditionStatus({}));
    dispatch(setJurisdictionOrgId(null));
    try {
      const response = await fetcherGet(`${APIS.JURISDICTIONS}?jurisdictionId=${jurisdictionId}`);
      const { data } = response;
      const { Status, dynamoId } = data[0];
      const orgId = dynamoId && dynamoId.replace("#", "%23");
      let resp = {};
      try{
        resp = await fetcherGet(`publicconfig/config?OrgID=${orgId}`, {}, false);
      }
      catch(e){
        console.log(e);
      }
      const purchaseLimitParameter = resp["purchase-limit-settings"]?.purchase_limit_style?.AttributeValue;
      dispatch(setJurisditionStatus(Status));
      dispatch(setJurisditionInformation(transformJurisdictionInoResponse({ ...data[0], purchaseLimitParameter }, t1)));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setJurisditionInformation({}));
      dispatch(setLoader(false));
    }
  };
}

export function getLinkedJurisdictions() {
  return async (dispatch) => {
    dispatch(setLinkedJurisdictions({ isLoading: true, data: null }));
    try {
      const response = await fetcherGet(`${APIS.GET_LINKED_JURISDICTIONS}`);
      dispatch(setLinkedJurisdictions({ isLoading: false, data: response?.data }));
    } catch (error) {
      dispatch(setLinkedJurisdictions({ isLoading: false, data: null }));
    }
  };
}

export function getJurisdictionRequestStatusChangeHistory(
  id,
  pageName = MANAGE_JURI_STATUS_PAGES.CREATE_PARTICIPATING_JURISDICTION,
  componentName = MANAGE_JURI_STATUS_COMPONENTS.REQUEST_STATUS_CHANGE_HISTORY
) {
  return async (dispatch) => {
    dispatch(setJurisdictionRequestStatusChangeHistory({ isLoading: true, data: null }));
    try {
      const response = await fetcherGet(
        `${APIS.GET_JURISDICTION_REQUEST_STATUS_CHANGE_HISTORY}/${id}`,
        {},
        { pageName, componentName }
      );
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setJurisdictionRequestStatusChangeHistory({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
    } catch (error) {
      dispatch(setJurisdictionRequestStatusChangeHistory({ isLoading: false, data: null }));
    }
  };
}

export function updateJurisdiction(payload) {
  return (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPatch(
      `${APIS.ONBOARD_JURISDICTION}/participatingJurisdiction/updateJurisdiction`,
      {
        ...payload,
      },
      {
        pageName: PAGE_EDIT_JURISDICTION,
        componentName: COMPONENT_EDIT_JURISDICTION,
      }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getJurisdictionCurrencyLanguageAndRequestStatus(jurisdictionId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setLanguageAndCurrencyDetailIDs({ languageDetailsIds: [], currencyDetailIds: [] }));
    dispatch(setRequestStatus(null));
    dispatch(setLinkageDetails(null));
    dispatch(setRequestStatusName(null));
    dispatch(setSelectedLinkedJurisdiction([]));
    dispatch(setProposedJurisdictions([]));
    try {
      const response = await fetcherGet(
        `${APIS.ONBOARD_JURISDICTION}/jurisdictionStatus/jurisdiction/${jurisdictionId}/getJurisdictionDetails`,
        {},
        {
          pageName: PAGE_JURISIDTCION_ACCOUNT_DETAILS,
          componentName: COMPONENT_JURISDICTION_DETAILS,
        }
      );
      if (response?.data) {
        const { languageData, currencyData, pendingStatus, linkedJurisdictions, proposedJurisdictions, requestStatusName } = response.data;
        const { RequestStatus, ...linkageDetails } = pendingStatus ?? {};
        dispatch(
          setLanguageAndCurrencyDetailIDs(
            transformJurisdictionCurrencyAndLanguageResponse({ languageData, currencyData })
          )
        );
        dispatch(setRequestStatus(RequestStatus));
        dispatch(setLinkageDetails(linkageDetails));
        dispatch(setRequestStatusName(requestStatusName));
        dispatch(setSelectedLinkedJurisdiction(linkedJurisdictions));
        dispatch(setProposedJurisdictions(proposedJurisdictions));
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLanguageAndCurrencyDetailIDs({ languageDetailsIds: [], currencyDetailIds: [] }));
      dispatch(setRequestStatus(null));
      dispatch(setLoader(false));
      dispatch(setLinkageDetails(null));
      dispatch(setRequestStatusName(null));
      dispatch(setSelectedLinkedJurisdiction([]));
      dispatch(setProposedJurisdictions([]));
    }
  };
}

export function cancelJurisdictionProposal(
  payload,
  jurisdictionId,
  pageName = PAGE_MANAGE_JURISDICTION_DETAILS,
  componentName = COMPONENT_CANCEL_PROPOSED_STATUS
) {
  return (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.ONBOARD_JURISDICTION}/jurisdictionStatus/jurisdiction/${jurisdictionId}/cancelProposedStatus`,
      {
        ...payload,
      },
      { pageName, componentName }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function approvalStatusChangeAPI(
  payload,
  pageName = PAGE_MANAGE_JURISDICTION_DETAILS,
  componentName = COMPONENT_APPROVE_STATUS
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.APPROVE_STATUS_CHANGE_API}`, "POST", payload, false, {pageName, componentName}).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function revisionsRequestedStatusChangeAPI(
  jurisdictionId,
  payload,
  pageName = PAGE_MANAGE_JURISDICTION_DETAILS,
  componentName = COMPONENT_REVISIONS_REQUESTED
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(`${APIS.REVISIONS_REQUESTED_API}`.replace(":jurisdictionId", jurisdictionId), payload, {
      pageName,
      componentName,
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getLinkRequestDetails(jurisdictionId, t1) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setLinkRequestDetails([]));
    try {
      const response = await fetcherGet(`${APIS.LINK_REQUEST_DETAILS}`.replace(":jurisdictionId", jurisdictionId));
      // const response = linkRequestDetails();
      const { data } = response;
      const transformedData = transformData(data?.linkRequests, t1);
      dispatch(setLinkRequestDetails(transformedData));
    } catch (error) {
      dispatch(setLinkRequestDetails([]));
      dispatch(setLoader(false));
    }
  };
}

export function getjurisdictionPLStyle() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setJurisdictionPLStyle({}));
    try {
      const response = await fetcherGet(`${APIS.ONBOARD_JURISDICTION}/getOrgConfigurations`);
      if(response?.data?.length){
        const jurisdictionPLStyle = response.data.reduce((obj,item)=>({...obj,[item.orgId]: item?.purchase_limit_style?.AttributeValue}),{})
        dispatch(setJurisdictionPLStyle(jurisdictionPLStyle));
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setJurisdictionPLStyle({}));
    }
  };
}