
const Style = {
  formCardPadding:{paddingBottom:"40px !important", minHeight:"181px"},
  otherCard:{paddingBottom:"20px"},
  marginBottomForFormCard: { marginBottom: "20px !important" },
  ".MuiTypography-root":{
    fontWeight: 0,
    
  },
  formField: {
    width: 1,
    color: "common.textColor",
    ".MuiOutlinedInput-input": {
      py: 0.5,
      fontSize: "14px",
      "& .MuiTypography-root": {
        fontSize: "14px"
      }
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderRadius: "0",
      borderColor: "common.GrayColor",
    },
    "&.MuiOutlinedInput-root": {
      "&.MuiInputBase-root.Mui-focused": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#333",
          borderWidth: 1,
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: '4px'
    },
    "& .Mui-error": {
      marginLeft: 0
    }
  },
  inputStyle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  FormCardStyle: {
    boxShadow: 'none !important',
    background: 'none !important',
    padding: '0px',
    marginBottom: '0',
    paddingBottom: '0',
  },
  onEditPage:{
    paddingBottom:"20px !important",
  },
  styleForPendingandRevision:{
    marginBottom:'0 !important',
  }

};

export default Style;
