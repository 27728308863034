export const CommonFontFamily = {
  fontFamily: "Open Sans !important",
  // textTransform: "capitalize",  // commented since MUI table is capitalizing unique values in CAG table and everywhere else
  width: "auto !important"
};

const Style = {
  TableBox: {
    width: "100%",
    "& .MuiDataGrid-iconButtonContainer": {
      display: "none",
    },
    "& .pointer-cursor": {
      cursor: "pointer",
      "& p": {
        width: "100%",
        height: "100%",
        "& a": {
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  },
  Table: {
    borderBottom: "1px solid #ccc",
    "& .MuiButtonBase-root": {
      fontWeight: 400,
    },
  },
  TableGrid: {
    ...CommonFontFamily,
    "& .MuiDataGrid-main": {
      borderBottom: "1px solid #ccc !important",
    },
    "& .no-search-in-table": {
      display: "none",
    },

    "& .MuiDataGrid-pinnedColumnHeaders": {
      background: "#e6eff2",
    },
    /* Conflict Resolution Candidate
   "& .MuiDataGrid-groupingCriteriaCell":{
     whiteSpace: "nowrap !important",
     overflow: "hidden !important",
   },
*/
    "& .MuiDataGrid-columnHeaders": {
      "& .MuiDataGrid-columnHeader": {
        "&:focus-within": { outline: "none !important" },
      },
      borderRadius: "0px",
      background: "#e6eff2",
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "center",
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        width: "100%",
        justifyContent: "center",
        // display: "block",
        "& .MuiDataGrid-columnHeaderTitleContainerContent": {
          overflow: "hidden",
          lineHeight: "normal",
          whiteSpace: "normal"
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          display: "inline-block",
          textOverflow: "initial",
          // overflow: "hidden",
          lineHeight: "normal",
          whiteSpace: "normal",
          fontWeight: "600"
        },
      },
      "& .MuiDataGrid-columnSeparator": {
        height: "100%",
        minHeight: "100% !important",
        "& svg": {
          height: "100%",
          "& path": {
            d: 'path("M 11 0 V 110 h 1 v -193 Z")',
            color: "#ccc",
          },
        },
      },
    },
    "& .MuiDataGrid-row": {
      width: "100%",
      textAlign: "center",
      "&:nth-of-type(odd)": {
        background: "#fff",
      },
      "&:nth-of-type(even)": {
        background: "#f2f2f2",
      },
    },
    "& .account-name--style": {
      padding: "0px"
    },
    "& .column-cell--style-bg-greyed": {
      background: "#E8E8E8"
    },
    "& .column-cell--style-bg-white": {
      background: "#fff"
    },
    "& .MuiDataGrid-cell": {
      borderRight: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      overflow: "hidden !important",
      whiteSpace: "unset !important",
      "& .custom-popup-links": {
        position: "absolute",
        right: 80,
        top: 0,
        zIndex: 1,
      },
      "& .MuiDataGrid-cellContent, .MuiTypography-root, > a": {
        whiteSpace: "nowrap ",
        overflow: "hidden ",
        textOverflow: "ellipsis ",
      },
      "&:focus-within": { outline: "none !important" },
    },
    "& .link-cell": {
      cursor: "pointer",
      textDecoration: 'underline',
      textDecorationColor: "primary.light",
      fontSize: "14px",
      color: "primary.light",
      fontWeight: "400",
      '&:focus': {
        fontWeight: 700
        // border: "1px solid #ccc",
        // backgroundColor: 'rgba(224, 183, 60, 0.55)',
      },
      '&:focus-within': {
        fontWeight: 700
        // border: "1px solid #ccc",
        // backgroundColor: 'rgba(224, 183, 60, 0.55)',
      }
    },
  },
  PrintTableGrid: {
    "@media print": {
      ".MuiDataGrid-main": { display: "none" },
    },
  },
  Pagination: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "10px 0px",
    "& .Mui-selected": {
      backgroundColor: "#4d8da4 !important",
      fontSize: "15px !important",
      color: "#fff !important",
      border: "none !important",
      "&:hover": {
        backgroundColor: "#4d8da4 !important",
      },
    },
  },
  NoRows: {
    "& .MuiDataGrid-virtualScrollerContent": {
      height: "42px!important"
    }
  }
};
export default Style;
