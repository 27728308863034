import { Box, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PageTitle } from "components";
import { useEffectOnce } from "hooks/useEffectOnce";
import AddFacility from "pages/EntityRegistration/Registration/components/AddFacility/AddFacility";
import { getFieldPermission } from "store/entityApplicationSlice";
import { COMPONENT_MODES, getCurrentPageName, t } from "utils/constants";

export default function Facilityform(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  useEffectOnce(() => {
    dispatch(getFieldPermission({ pageName: "Page_FacilityForm" }));
  })
  return (
    <Container
      maxWidth="xl"
    >
      <Grid
        item
        md={9}
      >
        <Box sx={{
          pt: 5
        }} >
          <PageTitle title={t(t1, "ADD_NEW_FACILITY")} />
          <AddFacility
            showOnlyFacilityForm
            displayHead={false}
            mode={COMPONENT_MODES.ADD}
            hideDataGrid={false}
          />
        </Box>
      </Grid>
    </Container>
  );
}