import { BATCH_TRANSFER_TYPES, allowedFileUploadFormats, removeTrailingEmptyObjects, t } from "utils/constants";
import { read, utils } from "xlsx";

function modifyKeysBatch({ obj, t1, headerMap, fileType, currencyList, selectedLanguage }) {
  const newObj = {};
  // check if data is not present in consecutive rows
  if (Object.values(obj).every((value) => value === undefined)) throw new Error(t(t1, "CONSECUTIVE_ROW_ERROR"));
  Object.keys(obj).forEach((key) => {
    const column = key.trim()?.toLowerCase();
    // check if column headers are not correct
    if (!headerMap[column]) throw new Error(`${t(t1, "FILE_INVALID_MESSAGE")}`);
    // check if file is csv and exemption has comma
    if (
      fileType === allowedFileUploadFormats[0] &&
      (headerMap[column] === "proposedQuantity" || headerMap[column] === "price") &&
      obj[key]?.includes(",")
    ) {
      throw new Error(`${t(t1, "FILE_INVALID_MESSAGE")}`);
    }
    if (headerMap[column] === "currencyJurisdictionDetailsId") {
      newObj[headerMap[column]] = currencyList?.find(
        (item) => item.label?.toLowerCase() === obj[key]?.toLowerCase()
      )?.value ?? key;
    } else if (headerMap[column] === "proposedQuantity") {
      newObj[headerMap[column]] =
        typeof obj[key] === "string" ? obj[key].replaceAll(/[\s,]/g, "") : obj[key]?.toString();
    } else {
      const parsedPrice = selectedLanguage === "French" ? obj[key].replace(",", ".") : obj[key];
      newObj[headerMap[column]] =
        headerMap[column] === "price" && typeof parsedPrice === "string"
          ? parseFloat(parsedPrice.replaceAll(/[\s,]/g, ""))
          : parsedPrice;
    }
  });
  return newObj;
}

function modifyKeysPCRA({ obj, t1, headerMap, fileType, accountDetailPCRA }) {
  const newObj = {};
  // check if data is not present in consecutive rows
  if (Object.values(obj).every((value) => value === undefined)) throw new Error(t(t1, "CONSECUTIVE_ROW_ERROR"));
  Object.keys(obj).forEach((key) => {
    const column = key.trim()?.toLowerCase();
    // check if column headers are not correct
    if (!headerMap[column]) throw new Error(`${t(t1, "FILE_INVALID_MESSAGE")}`);
    // check if file is csv and exemption has comma
    if (
      fileType === allowedFileUploadFormats[0] &&
      (headerMap[column] === "proposedQuantity") &&
      obj[key]?.includes(",")
    ) {
      throw new Error(`${t(t1, "FILE_INVALID_MESSAGE")}`);
    }
    newObj[headerMap[column]] =
      headerMap[column] === "proposedQuantity" && typeof obj[key] === "string"
        ? obj[key].replaceAll(/[\s,]/g, "")
        : obj[key];
  });
  newObj.receivingAccountNumber = accountDetailPCRA?.account_number
  return newObj;
}

export async function convertFileToJson(file, t1, fileType, currencyList, transferTypeName, accountDetailPCRA, selectedLanguage) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: "array", cellDates: true });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const modifyKeys = {
        [BATCH_TRANSFER_TYPES.BATCH_TRANSFER]: modifyKeysBatch,
        [BATCH_TRANSFER_TYPES.JURISDICTION_BATCH_TRANSFER_PCRA]: modifyKeysPCRA
      }
      try {
        const BATCH_TRANSFERS_EXCEL_HEADERS = transferTypeName === BATCH_TRANSFER_TYPES.BATCH_TRANSFER ? {
          [t(t1, "VINTAGE")?.toLowerCase()]: "vintageYear",
          [t(t1, "TYPE")?.toLowerCase()]: "type",
          [t(t1, "SUBTYPE")?.toLowerCase()]: "subType",
          [t(t1, "OFFSET_TYPE")?.toLowerCase()]: "offsetType",
          [t(t1, "OFFSET_PROJECTID")?.toLowerCase()]: "offsetProjectId",
          [t(t1, "SPECIAL_CATEGORY")?.toLowerCase()]: "specialCategory",
          [t(t1, "PROPOSED_QUANTITY")?.toLowerCase()]: "proposedQuantity",
          [t(t1, "RECEIVING_ACCOUNT_NUMBER")?.toLowerCase()]: "receivingAccountNumber",
          [t(t1, "ENTITY_REFERENCE_CODE")?.toLowerCase()]: "entityReferenceCode",
          [t(t1, "PRICE")?.toLowerCase()]: "price",
          [t(t1, "CURRENCY")?.toLowerCase()]: "currencyJurisdictionDetailsId",
        } : {
          [t(t1, "VINTAGE")?.toLowerCase()]: "vintageYear",
          [t(t1, "TYPE")?.toLowerCase()]: "type",
          [t(t1, "SUBTYPE")?.toLowerCase()]: "subType",
          [t(t1, "OFFSET_TYPE")?.toLowerCase()]: "offsetType",
          [t(t1, "OFFSET_PROJECTID")?.toLowerCase()]: "offsetProjectId",
          [t(t1, "SPECIAL_CATEGORY")?.toLowerCase()]: "specialCategory",
          [t(t1, "PROPOSED_QUANTITY")?.toLowerCase()]: "proposedQuantity",
        };
        const columnNames = utils.sheet_to_json(worksheet, { header: 1 })[0];

        columnNames?.forEach((columnName) => {
          if (!BATCH_TRANSFERS_EXCEL_HEADERS[columnName?.toLowerCase()]) throw new Error(`${t(t1, "FILE_INVALID_MESSAGE")}`);
        });

        const jsonData = removeTrailingEmptyObjects(
          utils.sheet_to_json(worksheet, { blankrows: true, defval: undefined, raw: false })
        ).map((obj) => modifyKeys[transferTypeName]({ obj, t1, headerMap: BATCH_TRANSFERS_EXCEL_HEADERS, fileType, currencyList, accountDetailPCRA, selectedLanguage }));
        if (!jsonData.length) reject(new Error(t(t1, "FILE_EMPTY_ERROR_MESSAGE")));
        resolve(jsonData);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (event) => {
      reject(event.target.error);
    };
    reader.readAsArrayBuffer(file);
  });
}
