import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { CustomizeButton } from "components/CustomizeButton";
import { useTranslation } from "react-i18next";
import { getCurrentPageName, t } from "utils/constants";
import Style from "../../../components/DialogMessage/DialogMessage.style";

export default function CustomCancelDialogMessage({
  title,
  message1,
  message2,
  warning = false,
  buttonMessage = true,
  open = null,
  disableContinue = false,
  handleClose = "",
  handleContinue = "",
  continueBtn = "",
  borderColor = "",
  customStyling = false,
  multipleErrorKeys = [],
}) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const theme = useTheme();
  const styleSx = warning ? { ...Style?.ModelWarning } : { ...Style?.ModelError };
  return (
    <Dialog
      sx={{
        ...Style?.ModelError,
        ...styleSx,
        ...Style?.messageModel,
      }}
      fullWidth
      maxWidth={"sm"}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          border: borderColor ? "2px solid" : "2px solid",
          borderColor: borderColor || `${theme.palette.common.red}`,
          borderRadius: "8px",
          padding: "20px",
        }}
        className={customStyling}
      >
        <Box className="messageModel">
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 300 }}
          >
            {`${title}`}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 1 }}
          >
            {message1}
          </Typography>
          {message2 ? (
            <Typography
              variant="body2"
              sx={{ mb: 1 }}
            >
              {message2}
            </Typography>
          ) : null}
          {multipleErrorKeys?.map((message) => (
            <Typography
              variant="body2"
              sx={{ mb: 1 }}
            >
              {message}
            </Typography>
          ))}
          {buttonMessage && (
            <Typography
              variant="body2"
              sx={{ my: 4 }}
            >
              {buttonMessage || `${t(t1, "DIALOG_BUTTONS_INFORMATION_MESSAGE")}`}
            </Typography>
          )}
          <Box
            sx={{ textAlign: "right", mb: 0 }}
            className="bottom-btn"
          >
            <CustomizeButton
              variant="outlined"
              csName="rc"
              label={t(t1, "CANCEL")}
              name="priv_modal_Cancel"
              handleClick={handleClose}
            />
            <CustomizeButton
              csName="submitBtn"
              customStyle={continueBtn ? { minWidth: "auto", width: "auto", fontSize: "14px" } : ""}
              variant="contained"
              disableElevation
              type="submit"
              label={t(t1, "CONTINUE")}
              name="priv_modal_Continue"
              handleClick={handleContinue}
              disabled={disableContinue}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}