import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

function NumberTextField(props) {
  const { onChange, name, rules, value:defaultVal } = props;
  const { control } = useFormContext();

  const handleValidInput=(event,field)=>{
    const { value } = event.target;
    const regex = /^\d{0,18}$/
    if (regex.test(value)) {
      field.onChange(value);
      onChange(value);
    } else {
      field.onChange(defaultVal ?? "")
    }
  }
  return (
    <Controller
      name={name}
      type={"text"}
      rules={{...rules}}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          type="text"
          inputProps={{ style: { textAlign: "center" }}}
          sx={{
            "& .MuiOutlinedInput-input": { padding: 0, p: 0.6, fontSize: "0.875rem" },
          }}
          error={Boolean(fieldState.error)}
          {...field}
          onChange={(e)=>{handleValidInput(e,field)}}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
          onWheel={ event => event.target.blur() }
        />
      )}
    />
  );
}
export default NumberTextField;
