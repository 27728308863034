/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { ENTITY_HOLDING_LIMITS_COMPONENTS } from 'pages/EntityProfile/components/EntityHoldingsInformation/constants';
import { setLoader, setModelMessage } from 'store/commonSlice';
import {
  getEntityContactById,
} from 'store/entityApplicationSlice';
import {
  APIS,
  ENTITY_APPLICTION_STATUS_NAMES,
  RESPONSE_MESSAGE_KEYS,
  SUCCESS,
  isEmpty,
  ERROR,
  CAD_STATUS,
  ShowInlinePendingAddressFacility,
  COMPONENT_NAME_FOR_ROLE,
  ShowInlineAddressFacility,
  ShowInlineAddressAddFacility,
  handleEntityStatusError, ShowInlineAddressSearchFacility,
} from 'utils/constants';
import { fetcherGet, fetcherPut, fetcherPost } from 'utils/fetcher';
import logger from 'utils/logger';

const initialState = {
  selectedLanguage: localStorage.getItem('i18nextLng'),
  entities: [],
  entitiesApiResponse: [],
  entityErrorRes: null,
  facilities: [],
  representatives: [],
  myRepresentativeAssignments: [],
  retiredFacility: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },
  associateFacility: [],
  specialPurposes: [],
  annualAllocations: [],
  denialReasons: [],
  actionStatus: [],
  representativeStatus: [],
  requestStatus: [],
  duplicateEntities: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },
  facilityOwnerHistory: [],
  errorList: [],
  CAGDetails: [],
  CAGGroupList: [],
  CAGHistory: {},
  CAGDetailsByID: [],
  CAGMemberDetails: [],
  CAGJury: [],
  removedCAGMembers: [],
  deletedCAGMember: null,

  entitiesInit: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },
  entityHistory: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },

  pendingEntitiesInit: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },

  myAccounts: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },
  accountApplications: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },

  holdingLimitDetails: {
    applicableAllowance: null,
    baseHoldingLimitsResult: [],
  },

  limitedExemptionDetail: {},

  limitedExemptionHistory: [],

  searchFacilities: [],
  searchFacilitiesList: [],
  entityHoldingLimitsPermissions: {},
  loadingActionStatus: false,
  loadingRepresentativeStatus: false,
  loadingRequestStatus: false,
  loadingEntities: false,
  loadingSearchFacilities: false,
  loadingSearchFacilitiesList: false,
  loadingPendingEntities: false,
  disabledCheck: false,
};

const entitiyProfileSlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setEntitiesInit: (state, action) => {
      state.entitiesInit = action.payload;
    },
    setPendingEntitiesInit: (state, action) => {
      state.pendingEntitiesInit = action.payload;
    },
    setMyAccounts: (state, action) => {
      state.myAccounts = action.payload;
    },
    setAccountApplications: (state, action) => {
      state.accountApplications = action.payload;
    },
    setEntities: (state, action) => {
      state.entities = action.payload;
    },
    setEntitiesApiResponse: (state, action) => {
      state.entitiesApiResponse = action.payload;
    },
    setEntityErrorRes: (state, action) => {
      state.entityErrorRes = action.payload;
    },
    setFacilities: (state, action) => {
      state.facilities = action.payload;
    },
    setFacilitiesApiResponse: (state, action) => {
      state.facilitiesApiResponse = action.payload;
    },
    setMyRepresentativeAssignments: (state, action) => {
      state.myRepresentativeAssignments = action.payload;
    },
    setRepresentatives: (state, action) => {
      state.representatives = action.payload;
    },
    setRepresentativesApiResponse: (state, action) => {
      state.representativesApiResponse = action.payload;
    },
    setLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
      localStorage.setItem('i18nextLng', action.payload);
    },
    setDenialReasons: (state, action) => {
      state.denialReasons = action.payload;
    },
    setSpecialPurpose: (state, action) => {
      state.specialPurposes = action.payload;
    },
    setAnnualAllocation: (state, action) => {
      state.annualAllocations = action.payload;
    },
    setActionStatus: (state, action) => {
      state.actionStatus = action.payload;
    },
    setRepresentativeStatus: (state, action) => {
      state.representativeStatus = action.payload;
    },
    setRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
    setEntityHistory: (state, action) => {
      state.entityHistory = action.payload;
    },
    setFacilityOwnerHistory: (state, action) => {
      state.facilityOwnerHistory = action.payload;
    },
    setErrorlist: (state, action) => {
      state.errorList = action.payload;
    },
    setRetiredFacility: (state, action) => {
      state.retiredFacility = action.payload;
    },
    setAssociateFacility: (state, action) => {
      state.associateFacility = action.payload;
    },
    setDuplicateEntities: (state, action) => {
      state.duplicateEntities = action.payload;
    },
    setCAGDetails: (state, action) => {
      state.CAGDetails = action.payload;
    },
    setCAGMemberDetails: (state, action) => {
      state.CAGMemberDetails = action.payload;
    },
    setCAGGroupList: (state, action) => {
      const data = action.payload;
      if (data) {
        data.sort((a, b) => {
          return b.cagGroupId - a.cagGroupId;
        });
      }
      state.CAGGroupList = data;
    },
    setCAGJury: (state, action) => {
      state.CAGJury = action.payload;
    },
    setCAGHistory: (state, action) => {
      state.CAGHistory = action.payload;
    },
    setCAGDetailsByID: (state, action) => {
      state.CAGDetailsByID = action.payload;
    },
    setRemoveRevert: (state, action) => {
      const CAGMembers = state.CAGDetails.cagMembers;
      const updatedMembers = CAGMembers.map((member) => {
        if (action.payload.id === member.id)
          return { ...member, retire: action.payload.value };
        return member;
      });

      state.CAGDetails = { ...state.CAGDetails, cagMembers: updatedMembers };
    },
    setRemovedCAGMembers: (state, action) => {
      state.removedCAGMembers = state?.CAGDetails?.cagMembers?.map((member) => {
        return { entityId: member?.entityId, retire: member.retire };
      });
    },
    setDeletedCAGMember: (state, action) => {
      state.deletedCAGMember = action.payload;
    },
    makeRemovedCAGMembersEmpty: (state, action) => {
      state.removedCAGMembers = [];
    },
    addRetireMember: (state, action) => {
      if (isEmpty(state?.removedCAGMembers.find((member) => member.entityId === action.payload.entityId))) {
        const allMembers = state?.removedCAGMembers;
        allMembers.push(action.payload);
        state.removedCAGMembers = allMembers;
      }
    },
    setRevertFalse: (state, payload) => {
      const revertMembers = state?.removedCAGMembers.map((item) => {
        return { ...item, retire: false };
      });
      state.removedCAGMembers = revertMembers;
    },
    setRevert: (state, action) => {

      if (state.removedCAGMembers.find((member) => member.entityId === action.payload.entityId)) {
        const updatedList = state.removedCAGMembers.map((item) => {
          if (action.payload.entityId === item.entityId)
            return { ...item, retire: action.payload.value };
          return item;
        });
        state.removedCAGMembers = updatedList;
      } else {
        const withAddedMember = [...state.removedCAGMembers, {
          entityId: action.payload.entityId,
          retire: action.payload.value,
        }];
        state.removedCAGMembers = withAddedMember;
      }
    },
    setHoldingLimitDetails: (state, action) => {
      state.holdingLimitDetails = action.payload;
    },
    setLimitedExemptionDetail: (state, action) => {
      state.limitedExemptionDetail = action.payload;
    },
    setLimitedExemptionHistory: (state, action) => {
      state.limitedExemptionHistory = action.payload;
    },
    setSearchFacilities: (state, action) => {
      state.searchFacilities = action.payload;
    },
    setSearchFacilitiesList: (state, action) => {
      state.searchFacilitiesList = action.payload;
    },
    setEntityHoldingLimitsPermissions: (state, action) => {
      state.entityHoldingLimitsPermissions = { ...state.entityHoldingLimitsPermissions, ...action.payload };
    },
    setLoadingActionStatus: (state, action) => {
      state.loadingActionStatus = action.payload;
    },
    setLoadingRepresentativeStatus: (state, action) => {
      state.loadingRepresentativeStatus = action.payload;
    },
    setLoadingRequestStatus: (state, action) => {
      state.loadingRequestStatus = action.payload;
    },
    setLoadingEntities: (state, action) => {
      state.loadingEntities = action.payload;
    },
    setLoadingSearchFacilities: (state, action) => {
      state.loadingSearchFacilities = action.payload;
    },
    setLoadingSearchFacilitiesList: (state, action) => {
      state.loadingSearchFacilitiesList = action.payload;
    },
    setLoadingPendingEntities: (state, action) => {
      state.loadingPendingEntities = action.payload;
    },
    setDisabledCheck: (state, action) => {
      state.disabledCheck = action.payload;
    },
  },
});

export const {
  setEntities,
  setEntitiesApiResponse,
  setEntityErrorRes,
  setFacilities,
  setFacilitiesApiResponse,
  setMyRepresentativeAssignments,
  setRepresentatives,
  setRepresentativesApiResponse,
  setLanguage,
  setEntitiesInit,
  setSpecialPurpose,
  setAnnualAllocation,
  setDenialReasons,
  setActionStatus,
  setRepresentativeStatus,
  setRequestStatus,
  setEntityHistory,
  setFacilityOwnerHistory,
  setRetiredFacility,
  setAssociateFacility,
  setDuplicateEntities,
  setErrorlist,
  setCAGDetails,
  setCAGGroupList,
  setCAGHistory,
  setCAGDetailsByID,
  setCAGMemberDetails,
  setCAGJury,
  setMyAccounts,
  setAccountApplications,
  setPendingEntitiesInit,
  setRemoveRevert,
  setRemovedCAGMembers,
  setDeletedCAGMember,
  addRetireMember,
  setRevert,
  setHoldingLimitDetails,
  setLimitedExemptionDetail,
  setLimitedExemptionHistory,
  makeRemovedCAGMembersEmpty,
  setSearchFacilities,
  setSearchFacilitiesList,
  setRevertFalse,
  setEntityHoldingLimitsPermissions,
  setLoadingActionStatus,
  setLoadingRepresentativeStatus,
  setLoadingRequestStatus,
  setLoadingEntities,
  setLoadingPendingEntities,
  setLoadingSearchFacilities,
  setLoadingSearchFacilitiesList,
  setDisabledCheck,
} = entitiyProfileSlice.actions;
export default entitiyProfileSlice.reducer;

export const getEntityById = (id, navigateAway = () => {
}) => {
  return (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setEntities([]));
    dispatch(setEntityErrorRes(null));
    dispatch(setEntitiesApiResponse([]));
    if (id !== undefined) {
      return fetcherGet(`${APIS.ENTITY}/${id}`, {}, {
        componentName: 'entityDetails,generalEntityDetails,identifiers,physicalAddress,mailingAddress,additionalContactInformation',
        pageName: 'Page_EntityProfile',
      })
        .then((response) => {
          dispatch(setEntities(response));
          dispatch(setEntitiesApiResponse(response));
          dispatch(setLoader(false));
        })
        .catch((error) => {
          dispatch(setEntityErrorRes(error));
          if (error?.response?.data?.messageKey === 'USER_IS_NOT_AUTHORIZED') {
            navigateAway();
          }
          dispatch(setLoader(false));
          logger({ error });
        });
    }
    ;
  };
};
export const getFacilitiesById = (id, action) => {
  return (dispatch) => {
    fetcherGet(`${APIS.ASSOCIATE_FACILITY}/${id}`)
      .then((response) => {
        const rows = [];
        if (action === 'entityjurisdiction') {
          response?.data?.map((facility) => {
            rows.push({
              id: facility?.id,
              facilityId: facility?.facilityId,
              status: facility?.Status?.name,
              faciltyName: facility?.legal_name,
              operatingName: facility?.operating_name,
              ghg_emission_report_id: facility?.ghg_emission_report_id,
              physicalAddress: facility?.PhysicalAddress,
              facilityInRegistry: facility?.is_facility,
              updatedBy: facility?.updatedBy,
              proposedBy: facility?.proposedBy,
            });
            return facility;
          });
        } else {
          response?.data?.map((facility) => {
            rows.push({
              id: facility?.id,
              facilityId: facility?.facilityId,
              status: facility?.Status?.name,
              faciltyName: facility?.legal_name,
              operatingName: facility?.operating_name,
              ghg_emission_report_id: facility?.ghg_emission_report_id,
              physicalAddress: facility.PhysicalAddress,
              updatedBy: facility?.updatedBy,
              proposedBy: facility?.proposedBy,
            });
            return facility;
          });
        }

        dispatch(setFacilities(rows));
      })
      .catch((error) => {
        logger({ error });
      });
  };
};

export const getFacilities = (id, t, t1) => (dispatch) => {
  fetcherGet(`${APIS.ENTITY}/${id}${'/facility'}`, {}, {
    componentName: 'addedFacilities',
    pageName: 'Page_EntityProfile',
  })
    .then((response) => {

      const rows = [];

      response?.data?.map((facility) => {
        rows.push({
          id: facility?.id,
          facilityName: facility?.facilityName,
          facilityId: facility?.facilityId,
          facilityStatus: t(t1, facility?.facilityStatus),
          facilityIsInRegistry: facility?.facilityIsInRegistry,
          operatingName: facility?.operatingName,
          ghgEmissionsReportingId: facility?.ghgEmissionsReportingId,
          physicalAddress: ShowInlinePendingAddressFacility(facility?.physicalAddress, t, t1),
          effectiveDate: facility?.effectiveDate,
          retirementDate: facility?.retirementDate,
          updatedBy: facility?.updatedBy,
          proposedBy: facility?.proposedBy,
        });

        return facility;
      });


      dispatch(setFacilitiesApiResponse(response));
      dispatch(setFacilities(rows));
    })
    .catch((error) => {
      logger({ error });
    });
};

export const getMyRepresentativeAssignments = (pageDetails) => (dispatch) => {
  fetcherGet(`${APIS.MY_REPRESENTATIVE_ASSIGNMENTS}`, {}, pageDetails)
    .then((response) => {
      dispatch(setMyRepresentativeAssignments({ ...response }));
    })
    .catch((error) => {
      logger({ error, name: 'getMyRepresentativeAssignments' });
      if (error?.response?.data?.messageKey === 'DATA_NOT_FOUND') {
        dispatch(setMyRepresentativeAssignments([]));
      }
      dispatch(setLoader(false));
    });
};

export const getRepresentatives = (id, action, representativePageDetail = {
  componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE,
  pageName: 'Page_EntityProfile',
}) => {
  return (dispatch) => {
    fetcherGet(`${APIS.REPRESENTATIVES}/${id}`, {}, representativePageDetail)
      .then((response) => {
        const rows = [];
        if (action === 'representativesentityapprove') {
          response?.data?.map((representative) => {
            rows.push({
              id: representative?.id,
              name: representative?.name,
              role: representative?.role,
              status: representative?.status,
              updateDate: representative?.updateDate,
              updatedBy: representative?.updatedBy,
              userReferenceCode: representative?.userReferenceCode,
              jurisdiction: representative?.jurisdiction,
              uId: representative?.uId,
            });
            return representative;
          });
        } else {
          response?.data?.map((representative) => {
            rows.push({
              id: representative?.id,
              name: representative?.name,
              status: representative?.status,
              updateDate: representative?.updateDate,
              updatedBy: representative?.updatedBy,
              userReferenceCode: representative?.userReferenceCode,
              uId: representative?.uId,
            });
            return representative;
          });
        }
        dispatch(setRepresentatives(rows));
        dispatch(setRepresentativesApiResponse(response));
      })
      .catch((error) => {
        logger({ error });
      });
  };
};

export const getEntities = (pageDetails = {}) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingEntities(true));
      const response = await fetcherGet(`${APIS.ENTITY}`, {}, pageDetails);
      const rows = response?.data?.map((entity, index) => {
        const {
          id,
          LegalName,
          OperationalName,
          Status,
          EntityID,
          Jurisdiction,
          updatedAt,
          EntityType,
          PhysicalAddress,
          request_status_update_date,
          RequestStatus,
          entity_denial_reason,
          comment,
          entityAccount,
          entity_reference_code,
          cagId,
        } = entity;

        return {
          ...entity,
          id: index,
          entityObjectId: id,
          LegalName,
          OperationalName,
          Status: Status?.Name,
          EntityID,
          Jurisdiction: Jurisdiction?.Name,
          updatedAt,
          EntityType: EntityType?.Name,
          PhysicalAddressOriginal: PhysicalAddress,
          PhysicalAddress: `${PhysicalAddress?.Street1} ${PhysicalAddress?.Street2} ${PhysicalAddress?.City} ${PhysicalAddress?.State?.Name} ${PhysicalAddress?.Country?.Name} ${PhysicalAddress?.PostalCode}`,
          requestStatusUpdateDate: request_status_update_date,
          RequestStatus: RequestStatus?.name,
          entity_denial_reason,
          comment,
          entityAccount,
          EntityReferanceCode: entity_reference_code,
          EntityAccountNumber: entityAccount?.account_number,
          EntityAccountType: entityAccount?.account_type,
          EntityAccountStatus: entityAccount?.account_status,
          CAG: cagId?.cagGroupId,
          CAGid: cagId?.id,
        };
      });

      const pendingEntities = [];
      rows.forEach(row => {
        if (row.entityStatus === 'PENDING') {
          pendingEntities.push(row);
        }
      });
      dispatch(setPendingEntitiesInit({ ...response, data: pendingEntities }));
      dispatch(setEntitiesInit({ ...response, data: rows }));
      dispatch(setLoadingEntities(false));
    } catch (error) {
      logger({ error });
      dispatch(setLoadingEntities(false));
    }
  };
};

export const getSearchFacilitiesList = (pageDetails, t, t1) => async (dispatch) => {
  try {
    const response = await fetcherGet(`${APIS.SEARCH_FACILITY_LIST}`, {});
    const formattedFacilities = response?.data?.map((item) => {
      return {
        id: item.entityid,
        entityId: item.entityid,
        legalName: item.legalname,
        facilityId: item.facilityid,
        facilityName: item.facilityname,
        operatingName: item.operatingname,
        ghgEmissionsReportingId: item.ghgemissionsreportingid,
        entityOperatingName: item.entityoperatingname,
        facilityStatus: t(t1, item.facilitystatus),
        updatedBy: item.updatedby,
        naicsCode: item.naicscode,
        entityUuid: item.entityuuid,
        facilityUuid: item.facilityuuid,
        physicalAddress: ShowInlineAddressSearchFacility(
          {
            street1: item.street1,
            street2: item.street2,
            city: item.city,
            region: item.region,
            postalCode: item.postal_code,
            state: item.state,
            country: item.country,
          },
          t,
          t1,
          item.country,
          item.state,
        ),
      };
    });
    dispatch(setSearchFacilitiesList(formattedFacilities));
  } catch (error) {
    logger({ error });
  }
};

export const getMyAccounts = (pageDetails) => {
  return (dispatch) => {
    fetcherGet(`${APIS.MY_ACCOUNTS}`, {}, pageDetails)
      .then((response) => {
        const rows = response?.data?.map((entity, index) => {
          return {
            ...entity,
            id: index,
            entityObjectId: entity?.id,
            LegalName: entity?.legalName,
            OperationalName: entity?.operatingName,
            Status: entity?.entityStatus,
            EntityID: entity?.entityId,
            Jurisdiction: entity?.jurisdiction,
            updatedAt: entity?.updatedAt,
            EntityType: entity?.EntityType?.Name,
            PhysicalAddressOriginal: entity?.PhysicalAddress,
            PhysicalAddress: `${entity?.PhysicalAddress?.Street1} ${entity?.PhysicalAddress?.Street2} ${entity?.PhysicalAddress?.City} ${entity?.PhysicalAddress?.State?.Name} ${entity?.PhysicalAddress?.Country?.Name} 
            ${entity?.PhysicalAddress?.PostalCode}`,
            requestStatusUpdateDate: entity?.request_status_update_date,
            RequestStatus: entity?.RequestStatus?.name,
            entity_denial_reason: entity?.entity_denial_reason,
            comment: entity?.comment,
            entityAccount: entity?.entityAccount,
            EntityReferanceCode: entity?.entity_reference_code,
            EntityAccountNumber: entity?.entityAccountNumber,
            EntityAccountType: entity?.entityAccountType,
            EntityAccountStatus: entity?.accountStatus,
            CAG: entity?.cag?.cagGroupId,
            CAGid: entity?.cag?.id,
          };
        });
        dispatch(setMyAccounts({ ...response, data: rows }));
      })
      .catch((error) => {
        logger({ error });
      });
  };
};

export const getAccountApplications = (pageDetails) => {
  return (dispatch) => {
    fetcherGet(`${APIS.ACCOUNT_APPLICATIONS}`, {}, pageDetails)
      .then((response) => {
        const rows = response?.data?.map((entity, index) => {
          return {
            ...entity,
            id: index,
            entityObjectId: entity?.id,
            LegalName: entity?.legalName,
            OperationalName: entity?.operatingName,
            Status: entity?.entityStatus,
            EntityID: entity?.entityId,
            Jurisdiction: entity?.jurisdiction,
            updatedAt: entity?.updateDate,
            EntityType: entity?.entityType,
            PhysicalAddressOriginal: entity?.PhysicalAddress,
            PhysicalAddress: `${entity?.PhysicalAddress?.Street1} ${entity?.PhysicalAddress?.Street2} ${entity?.PhysicalAddress?.City} ${entity?.PhysicalAddress?.State?.Name} ${entity?.PhysicalAddress?.Country?.Name} 
            ${entity?.PhysicalAddress?.PostalCode}`,
            requestStatusUpdateDate: entity?.requestStatusUpdateDate,
            RequestStatus: entity?.requestStatus,
            entity_denial_reason: entity?.entity_denial_reason,
            comment: entity?.comment,
            entityAccount: entity?.entityAccount,
            EntityReferanceCode: entity?.entityReferenceCode,
            EntityAccountNumber: entity?.entityAccount?.account_number,
            EntityAccountType: entity?.entityAccount?.account_type,
            EntityAccountStatus: entity?.entityAccount?.account_status,
            CAG: entity?.cag?.cagGroupId,
            CAGid: entity?.cag?.id,
          };
        });
        dispatch(setAccountApplications({ ...response, data: rows }));
      })
      .catch((error) => {
        logger({ error });
      });
  };
};

export const getAssociateRetiredFacility = (t, t1, navigateAway) => {
  return (dispatch) => {
    fetcherGet(APIS.RETIRED_FACILITY, {}, {
      componentName: 'retiredFacilities',
      pageName: 'Page_AssociatedRetiredFacility',
    })
      .then((response) => {
        dispatch(setRetiredFacility(response));
      })
      .catch((error) => {
        if (error?.response?.data?.messageKey === 'USER_IS_NOT_AUTHORIZED') {
          navigateAway();
        }
        logger({ error });
      });
  };
};

export function updateRatiredFacilityStatus(data, pageDetail) {
  return fetcherPut(APIS.ASSOCIATE_FACILITY, data, pageDetail);
}

export const getSpecialPurpose = (navigateAway = () => {
}) => {
  return (dispatch) => {
    fetcherGet(APIS.SPECIAL_PURPOSE_ACCOUNT)
      .then((response) => {
        dispatch(setSpecialPurpose(response.data));
      })
      .catch((error) => {
        if (error?.response?.data?.messageKey === 'USER_IS_NOT_AUTHORIZED') {
          navigateAway();
        }
        logger({ error });
      });
  };
};

export const getAnnualAllocation = (navigateAway = () => {
}) => {
  return (dispatch) => {
    fetcherGet(APIS.ANNUAL_ALLOCATION_ACCOUNT)
      .then((response) => {
        dispatch(setAnnualAllocation(response.data));
      })
      .catch((error) => {
        if (error?.response?.data?.messageKey === 'USER_IS_NOT_AUTHORIZED') {
          navigateAway();
        }
        logger({ error });
      });
  };
};

export const getDenialReasons = (navigateAway = () => {
}) => {
  return (dispatch) => {
    fetcherGet(APIS.DENIAL_REASON)
      .then((response) => {
        dispatch(setDenialReasons(response.data));
      })
      .catch((error) => {
        if (error?.response?.data?.messageKey === 'USER_IS_NOT_AUTHORIZED') {
          navigateAway();
        }
        logger({ error });
      });
  };
};

export const getActionStatus = () => {
  return (dispatch) => {
    // fetcherGet(APIS.ENTITY_STATUSES)
    dispatch(setLoadingActionStatus(true));
    fetcherGet(`${APIS.ENTITY_STATUSES}?type=ENTITY`)
      .then((response) => {
        dispatch(setActionStatus(response.data));
        dispatch(setLoadingActionStatus(false));
      })
      .catch((error) => {
        logger({ error });
        dispatch(setLoadingActionStatus(false));
      });
  };
};

export const getRepresentativeStatus = () => {
  return (dispatch) => {
    // fetcherGet(APIS.ENTITY_STATUSES)
    dispatch(setLoadingRepresentativeStatus(true));
    fetcherGet(`${APIS.ENTITY_STATUSES}?type=REPRESENTATIVE`)
      .then((response) => {
        dispatch(setRepresentativeStatus(response.data));
        dispatch(setLoadingRepresentativeStatus(false));
      })
      .catch((error) => {
        logger({ error });
        dispatch(setLoadingRepresentativeStatus(false));
      });
  };
};

export const getRequestStatus = () => {
  return (dispatch) => {
    dispatch(setLoadingRequestStatus(true));
    fetcherGet(APIS.REQUEST_STATUSES)
      .then((response) => {
        dispatch(setRequestStatus(response.data));
        dispatch(setLoader(false));
        dispatch(setLoadingRequestStatus(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
        dispatch(setLoadingRequestStatus(false));
        logger({ error });
      });
  };
};

export const saveEntityStatus = async (actionData) => {
  return fetcherPut(APIS.REQUEST_STATUS, actionData);
};

export const postEntityAction = (
  entityActionData,
  actionTypes,
  actionStatusObject,
  requestStatusObject,
  entityId,
  navigate,
  t, t1,
  isRegistrarUser = false,
) => {
  const actionData = {
    entityId,
    comment: entityActionData?.cancelComment || entityActionData?.comment,
  };

  if (entityActionData.entityAction === actionTypes.requestReview) {
    actionData.requestStatusId = requestStatusObject.find(
      (item) => item.name === ENTITY_APPLICTION_STATUS_NAMES.ENTITY_APPLICATION_REVISIONS_REQUESTED,
    ).id;
    actionData.statusId = actionStatusObject.find((item) => item.Name === 'REVISIONS_REQUESTED').ID;
  } else if (entityActionData.entityAction === actionTypes.cancel) {
    if (isRegistrarUser) {
      actionData.requestStatusId = requestStatusObject.find(
        (item) => item.name === ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_CANCELLED_BY_JURISDICTION,
      ).id;
    } else {
      actionData.requestStatusId = requestStatusObject.find(
        (item) => item.name === ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_CANCELLED_BY_USER,
      ).id;
    }

    actionData.statusId = actionStatusObject.find((item) => item.Name === 'CANCELLED').ID;
  } else if (entityActionData.entityAction === actionTypes.deny) {
    actionData.requestStatusId = requestStatusObject.find(
      (item) => item.name === ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_DENIED_JURIDICTION,
    ).id;
    actionData.statusId = actionStatusObject.find((item) => item.Name === 'DENIED').ID;
    actionData.denialReasonId = entityActionData.reason_for_denial;
  } else if (entityActionData.entityAction === actionTypes.approve) {
    actionData.requestStatusId = requestStatusObject.find(
      (item) => item.name === ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_APPROVED,
    ).id;
    actionData.statusId = actionStatusObject.find((item) => item.Name === 'ACTIVE').ID;
    if (entityActionData?.entity_annual_allocation_holding_accounts) {
      actionData.entityAnnualAllocationId = entityActionData.entity_annual_allocation_holding_accounts;
    }
    actionData.entitySpecialPurposeId = entityActionData.entity_special_purpose_holding_accounts;
  } else if (entityActionData.entityAction === actionTypes.close) {
    actionData.requestStatusId = actionStatusObject.find((item) => item.Name === 'CLOSED').ID;
    actionData.statusId = actionStatusObject.find((item) => item.Name === 'CLOSED').ID;
  } else if (entityActionData.entityAction === actionTypes.active) {
    actionData.requestStatusId = requestStatusObject.find(
      (item) => item.name === ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_APPROVED,
    ).id;
    actionData.statusId = actionStatusObject.find((item) => item.Name === 'ACTIVE').ID;
  }
  return (dispatch) => {
    dispatch(setLoader(true));
    saveEntityStatus(actionData).then((response) => {
      if (response.messageKey === RESPONSE_MESSAGE_KEYS.ENTITY_IS_ACTIVE) {
        const data = {
          title: SUCCESS,
          message1: RESPONSE_MESSAGE_KEYS.ENTITY_IS_ACTIVE,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
        dispatch(getEntityById(entityId));
        if (navigate) navigate('/jurisdiction-user');
      } else if (response.messageKey === RESPONSE_MESSAGE_KEYS.ENTITY_IS_CLOSED) {
        const data = {
          title: SUCCESS,
          message1: RESPONSE_MESSAGE_KEYS.ENTITY_IS_CLOSED,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
        dispatch(getEntityById(entityId));
        dispatch(getEntityContactById(entityId));
        if (navigate) navigate(`/entity-profile/${entityId}`);
      } else if (response.messageKey === RESPONSE_MESSAGE_KEYS.APPROVE_STATUS_UPDATED) {
        const data = {
          title: SUCCESS,
          message1: RESPONSE_MESSAGE_KEYS.APPROVE_STATUS_UPDATED,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
        dispatch(getEntityById(entityId));
        if (navigate) navigate('/jurisdiction-user');
      } else if (response.messageKey === RESPONSE_MESSAGE_KEYS.CANCEL_STATUS_UPDATED) {
        const data = {
          title: SUCCESS,
          message1: RESPONSE_MESSAGE_KEYS.CANCEL_STATUS_UPDATED,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
        dispatch(getEntityById(entityId));
        dispatch(getEntityContactById(entityId, false, true));

        if (isRegistrarUser) {
          navigate(`/entity-profile/${entityId}`);
        } else {
          navigate(`/home`);
        }

      } else if (response.messageKey === RESPONSE_MESSAGE_KEYS.REQUEST_REVISION_STATUS_UPDATED) {
        const data = {
          title: SUCCESS,
          message1: RESPONSE_MESSAGE_KEYS.REQUEST_REVISION_STATUS_UPDATED,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
        dispatch(getEntityById(entityId));
        navigate('/jurisdiction-user');
      } else if (response.messageKey === RESPONSE_MESSAGE_KEYS.DENY_STATUS_UPDATED) {
        const data = {
          title: SUCCESS,
          message1: RESPONSE_MESSAGE_KEYS.DENY_STATUS_UPDATED,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
        dispatch(getEntityById(entityId));
        if (navigate) navigate('/jurisdiction-user');
      } else if (!response.messageKey) {
        const data = {
          title: ERROR,
          message1: response.data,
          error: true,
          isDefaultDisplay: true,
        };
        dispatch(setLoader(false));
        dispatch(setModelMessage(data));
      }
      return response;
    })
      .catch((error) => {
        const hasNavigated = handleEntityStatusError(error, entityId, dispatch, navigate);
        if (hasNavigated) return;
        logger({ error });
        let responseData = null;
        let responseDataForClose = [];
        if (error.response.data.messageKey === 'APPLICATION_IS_LOCKED_BY_ANOTHER_USER') {
          window.location.reload();
        } else if (error.response.data.messageKey === RESPONSE_MESSAGE_KEYS.ENTITY_ACCOUNTS_ARE_NOT_CLOSED) {
          responseData = {
            title: 'ERROR!',
            message1: 'There are active Entity Accounts associated with the Entity that are not closed',
            error: true,
            isDefaultDisplay: false,
          };
          dispatch(setLoader(false));
        } else if (error?.response?.data?.messageKey === 'ASSOCIATE_FACILITY_TO_AN_ENTITY') {
          responseData = {
            title: ERROR,
            message1: error?.response?.data?.messageKey,
            error: true,
            isDefaultDisplay: false,
          };
          dispatch(setLoader(false));
        } else if (error?.response?.data?.messageKey?.messageKey === RESPONSE_MESSAGE_KEYS.CANNOT_CLOSE_THIS_ENTITY_AS_THIS_IS_ASSOCIATED_WITH_A_CORPORATE_ASSOCIATION_GROUP) {
          responseData = {
            title: 'ERROR!',
            message1: RESPONSE_MESSAGE_KEYS.CANNOT_CLOSE_THIS_ENTITY_AS_THIS_IS_ASSOCIATED_WITH_A_CORPORATE_ASSOCIATION_GROUP,
            error: true,
            isDefaultDisplay: false,
          };
          dispatch(setLoader(false));
        } else if (error?.response?.data?.messageKey?.messageKey === 'REPRESENTATIVE_VALIDATIONS_FAILED') {
          const data = error?.response?.data?.messageKey;
          let errorMessage = '';
          if (data?.messageKey === 'REPRESENTATIVE_VALIDATIONS_FAILED') {
            if (data && data.data && data.data.length > 0) {
              data.data.forEach(errorObj => {
                switch (errorObj.messageKey) {
                  case 'PAR_MINIMUM_VALIDATION_FAILED':
                    errorMessage += `PAR Minimum value has not reached. Min PAR required is ${errorObj.parCountMinimumSettings}\n`;
                    break;
                  case 'PAR_MAXIMUM_VALIDATION_FAILED':
                    errorMessage += `PAR Maximum value reached. Max PAR allowed is ${errorObj.parCountMaximumSettings}\n`;
                    break;
                  case 'AAR_MINIMUM_VALIDATION_FAILED':
                    errorMessage += `AAR Minimum value has not reached. Min AAR required is ${errorObj.aarCountMinimumSettings}\n`;
                    break;
                  case 'AAR_MAXIMUM_VALIDATION_FAILED':
                    errorMessage += `AAR Maximum value reached. Max AAR allowed is ${errorObj.aarCountMaximumSettings}\n`;
                    break;
                  case 'AVA_MINIMUM_VALIDATION_FAILED':
                    errorMessage += `AVA Minimum value has not reached. Min AVA required is ${errorObj.avaCountMinimumSettings}\n`;
                    break;
                  case 'AVA_MAXIMUM_VALIDATION_FAILED':
                    errorMessage += `AVA Maximum value reached. Max AVA allowed is ${errorObj.avaCountMaximumSettings}\n`;
                    break;
                  default:
                    errorMessage += 'Unknown error\n';
                    break;
                }
              });
            }
          } else if (data?.data === 'REPRESENTATIVE_STATUS_IS_NOT_ACTIVE') {
            errorMessage = 'Representative Not Active';
          }
          responseData = {
            title: 'ERROR!',
            message1: errorMessage,
            error: true,
            isDefaultDisplay: false,
          };
          dispatch(setLoader(false));
        } else {
          let message = error?.response?.data?.messageKey;
          if (error?.response?.data?.messageKey === 'FACILITIES_ASSOCIATED_WITH_ANOTHER_ENTITY') {
            message = t(t1, error?.response?.data?.messageKey);
            message = message.replace('[IDS]', error?.response?.data?.data);
          }

          responseData = {
            title: ERROR,
            message1: message,
            error: true,
            isDefaultDisplay: true,
          };
          dispatch(setLoader(false));

          responseDataForClose = {
            title: ERROR,
            message1: error?.response?.data?.messageKey,
            error: false,
            isDefaultDisplay: true,
          };
        }
        if (actionData?.requestStatusId === '4d88e7d0-9e12-40c9-aec7-b2684cd95f57') {
          dispatch(setErrorlist(responseDataForClose));
        } else if (responseData) {
          dispatch(setModelMessage(responseData));
        }
      });
  };
};

export const getEntityHistory = (entityId, pageDetails) => {
  return (dispatch) => {
    fetcherGet(`${APIS.ENTITY_STATUS_HISTORY}/${entityId}`, {}, pageDetails)
      .then((response) => {
        let entityHistory = [];
        entityHistory = response?.data?.map((historyItem, index) => {
          return {
            id: historyItem?.id || index,
            ...historyItem,
          };
        });
        dispatch(setEntityHistory({ ...response, data: entityHistory }));
      })
      .catch((error) => {
        dispatch(setEntityHistory({
          data: [],
          metaData: [],
          fieldAccess: [],
        }));
        logger({ error });
      });
  };
};

export const getFacilityOwnerHistory = (Id, t1, t) => {
  return (dispatch) => {
    fetcherGet(`${APIS.FACILITY}/${Id}${'/history'}`)
      .then((response) => {
        let facilityOwnerHistory = [];
        facilityOwnerHistory = response?.data?.map((historyItem) => {
          return {
            id: historyItem?.id,
            entityId: historyItem?.entity?.entityId,
            entityLegalName: historyItem?.entity?.legalName,
            entityOperatingName: historyItem?.entity?.operatingName,
            status: historyItem?.status?.name,
            effectiveDate: historyItem?.facility?.effectiveDate,
            // effectiveDate: historyItem?.createdAt, // using createdAt instead due to an issue in BE maintaining effective date history
            retiredDate: historyItem?.retiredDate,
            comment: historyItem?.comment,
            entityNavigateLink: historyItem?.entity?.id,
            entityStatus: historyItem?.entity?.Status?.name,
            canViewEntity: historyItem?.entity?.canViewEntity,
          };
        });
        dispatch(setFacilityOwnerHistory(facilityOwnerHistory));
      })
      .catch((error) => {
        logger({ error });
      });
  };
};

export const getDuplicateEntities = (entityId, pageDetail) => {
  return (dispatch) => {
    fetcherGet(`${APIS.DUPLICATE_ENTITIES}/${entityId}`, {}, pageDetail)
      .then((response) => {

        dispatch(setDuplicateEntities(response));
      })
      .catch((error) => {
        dispatch(setDuplicateEntities({
          data: [],
          metaData: [],
          fieldAccess: [],
        }));
        logger({ error });
      });
  };
};

export const getCAGDetails = (entityId, navigateAway) => {
  return (dispatch) => {
    fetcherGet(`${APIS.CAG_DETAILS}/${entityId}`)
      .then((response) => {
        dispatch(setCAGDetails(response?.data));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        if (error?.response?.data?.messageKey === 'USER_IS_NOT_AUTHORIZED') {
          navigateAway();
        }
        logger({ error });
        dispatch(setLoader(false));
      });
  };
};

export const getCAGGroupLists = () => {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherGet(APIS.CAG_GROUP_LIST)
      .then((response) => {
        const caglistData = response?.data?.map((item) => {
          return { ...item, isCrossJurisdictionGroup: (item.isCrossJurisdictionGroup === true ? 'YES' : 'NO') };
        });
        dispatch(setCAGGroupList(caglistData));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
        logger({ error });
      });
  };
};

export const getCAGDetailsByEntityId = (id) => {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherGet(`${APIS.CAG_DETAILS_BY_ENTITYID}/${id}`)
      .then((response) => {
        dispatch(setCAGDetailsByID(response?.data));
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
        logger({ error });
      });
  };
};

export const getCAGHistory = (cagId) => {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherGet(`${APIS.CAG_HISTORY}/${cagId}`).then((response) => {
      dispatch(setCAGHistory(response.data));
      dispatch(setLoader(false));

    })
      .catch((error) => {
        logger({ error });
        dispatch(setLoader(false));
      });
  };
};

export const getCAGJurisdictions = () => {
  return (dispatch) => {
    fetcherGet(`${APIS.CAGJURYS}`).then((response) => {
      dispatch(setCAGJury(response.data));
    })
      .catch((error) => {
        logger({ error });
      });
  };
};

export function activeCloseCAG(id, payload) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcherPost(`${payload?.status === CAD_STATUS.ACTIVE ? APIS.ACTIVATE_CAG : APIS.CLOSE_CAG}/${id}`, payload, {})
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1:
            payload?.status === CAD_STATUS.ACTIVE
              ? RESPONSE_MESSAGE_KEYS.ACTIVATE_CAG_SUCCESSFULLY
              : RESPONSE_MESSAGE_KEYS.CLOSE_CAG_SUCCESSFULLY,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
        dispatch(getCAGGroupLists());
        dispatch(getCAGDetails(id));
        dispatch(getCAGHistory(id));
      })
      .catch((error) => {
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        logger({ error, name: 'Active/Close Update' });
        dispatch(setLoader(false));
      });
  };
}

export function getHoldingLimitDetails(entityId) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/entity/${entityId}/baseHoldingLimit`,
      {},
      { componentName: ENTITY_HOLDING_LIMITS_COMPONENTS.BASE_HOLDING_LIMIT, pageName: 'Page_EntityProfile' },
    )
      .then(({ data = {}, ...permissionData }) => {
        const baseHoldings = data?.baseHoldingLimitsResult?.map((holding, index) => ({
          ...holding,
          id: index + 1,
        }));
        const sanitizedData = { ...data, baseHoldingLimitsResult: baseHoldings };
        dispatch(setHoldingLimitDetails(sanitizedData || {}));
        dispatch(setEntityHoldingLimitsPermissions({ baseHoldingLimit: permissionData }));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getLimitedExemptionDetail(entityId, entityJuriId) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/${entityJuriId}/entity/${entityId}/limitedExemptionDetail`,
      {},
      { componentName: ENTITY_HOLDING_LIMITS_COMPONENTS.LIMITED_EXEMPTION_DETAIL },
    )
      .then(({ data = {}, ...permissionData }) => {
        dispatch(setLimitedExemptionDetail(data || {}));
        dispatch(setEntityHoldingLimitsPermissions({ limitedExemptionDetail: permissionData }));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getLimitedExemptionHistory(entityId) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/entity/${entityId}/limitedExemptionHistory`,
      {},
      { componentName: ENTITY_HOLDING_LIMITS_COMPONENTS.LIMITED_EXEMPTION_HISTORY },
    )
      .then(({ data = [], ...permissionData }) => {
        dispatch(setLimitedExemptionHistory(data || []));
        dispatch(setEntityHoldingLimitsPermissions({ limitedExemptionHistory: permissionData }));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}
