import { FAQ_CONTACT_INFO_PAGE, FAQ_QUESTION_PAGE } from "utils/constants";

export const prepareFAQDataForSaveOrPublish = (unsavedData, isJSAUser, isPublish, faqFetchedData) => {
  const keysArray = Object.keys(unsavedData);
  const keyDataToUpdate = [];
  if (isPublish) {
    faqFetchedData?.contactInfo.forEach((keyInfo) => {
      if (keyInfo?.hasUnpublishedChanges) {
        const contactInfo = {
          pageName: keyInfo.pageName || FAQ_CONTACT_INFO_PAGE,
          itemId: keyInfo.itemId,
          displayedValue: keyInfo.displayedValue,
        }
        keyDataToUpdate.push(contactInfo);
      }
    })
    faqFetchedData?.faq?.forEach((keyInfo) => {
      if (keyInfo?.hasUnpublishedChanges) {
        const faqInfo = {
          pageName: keyInfo.pageName || FAQ_QUESTION_PAGE,
          answer: keyInfo.displayedAnswer,
          itemId: keyInfo.itemId,
          question: keyInfo.question,
        }
        keyDataToUpdate.push(faqInfo);
      }
    });
  }
  keysArray.forEach((key) => {
    const keyInfo = unsavedData[key];
    let editedInfo;
    const index = keyDataToUpdate.findIndex(item => item?.itemId === keyInfo.itemId);
    // key not present means new key or not previously saved key
    if (index === -1) {
      // handle key info for questions only
      if (keyInfo?.question) {
        editedInfo = {
          pageName: keyInfo.pageName || FAQ_QUESTION_PAGE,
          answer: keyInfo.displayedAnswer,
          question: keyInfo.question,
        }
        if (!keyInfo?.isNew) {
          editedInfo.itemId = keyInfo.itemId;
        }
        keyDataToUpdate.push(editedInfo);
        // handle key info payload for contact information card
      } else {
        editedInfo = {
          pageName: keyInfo.pageName || FAQ_CONTACT_INFO_PAGE,
          itemId: keyInfo.itemId,
          displayedValue: keyInfo.displayedValue,
        }
        keyDataToUpdate.push(editedInfo);
      }
    } else if (keyInfo?.question) {
      keyDataToUpdate.splice(index, 1, {
        pageName: keyInfo.pageName || FAQ_QUESTION_PAGE,
        answer: keyInfo.displayedAnswer,
        question: keyInfo.question,
        itemId: keyInfo.itemId
      })
    } else {
      keyDataToUpdate.splice(index, 1, {
        pageName: keyInfo.pageName || FAQ_CONTACT_INFO_PAGE,
        itemId: keyInfo.itemId,
        displayedValue: keyInfo.displayedValue,
      })
    }
  });
  console.log('keyDataToUpdate =', keyDataToUpdate);
  return keyDataToUpdate;
}
