import PropTypes from "prop-types";
import { Container } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { removeAccessToken } from "store/authSlice";
import { setEntities, getEntitySingleFacilityById } from "store/entityApplicationSlice";
import { getEntityById } from "store/entityProfileSlice";
import { PermissionDenied } from "components/PermissionDenied";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import { useEffectOnce } from "hooks/useEffectOnce";
import { useTranslation } from "react-i18next";
import {
  CancelByJurisdiction,
  ClosedStatus,
  InactiveStatus,
  ProfileAccessOnly,
  RouteConst,
  ENTITY_APPLICTION_STATUS_NAMES,
  USER_ROLES,
  FACILITY_STATUS,
  isEmpty,
  getCurrentPageName,
  AuctionAdministrator,
  JuriAdmin,
  JurisdictionUser,
  RegistrarUser,
  AuthorityRole,
  JSA_ROLE,
  JURISDICTIONS_ID,
  REPRESENTATIVES_ROLES,
  REPRESENTATIVE_STATUS,
  REPRESENTATIVES_ROLES_ACTIVE,
  CWI_ACTIONS,
  MarketMonitor,
  MMAssignedTo,
  FSAHome,
  FSAAssignedTo,
  CancelStatus,
} from "utils/constants";
import { getEntity, getReceivingEntityDetailsAR } from "store/eventApplicationSlice";
import { Loader } from "components/Loader";
import { getServiceProviderMUIAuc } from "store/serviceProSelectionMgmSlice";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  privId: PropTypes.string,
  children: PropTypes.node,
};

export default function RoleBasedGuard({
  privId,
  isHeader = false,
  children,
  checkAccess = false,
  checkAccessForAuction = false,
  checkAccessForFacility = false,
  jurisdictionEntityActionPage = false,
  entityRegistrationPage = false,
  checkEntityAccessForUser = false,
  checkEntityAccessForId = false,
  checkAccessFSA = false,
}) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const PageName = getCurrentPageName();
  const { signOut, isSignedIn } = useAuth();
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { menu, header } = useSelector(({ auth }) => auth);
  const { entities, entityErrorRes } = useSelector(({ entityProfile }) => entityProfile);
  const { SingleEntityData } = useSelector(({ entityApplication }) => entityApplication);
  const { Jurisdictions, selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const navigate = useNavigate();
  const location = useLocation();
  const { PrivAttributes } = menu;
  const { id, entityId, action } = useParams();
  const dispatch = useDispatch();
  const { loader } = useSelector(({ common }) => common);
  const { receivingEntityDetailsAR, entityDetails = [], initiateParticipation } = useSelector(({ eventApplication }) => eventApplication);
  const ARData = receivingEntityDetailsAR?.data.find((data) => data?.uId === userDetails?.UID);

  let privAtr;
  if (isHeader) privAtr = header.PrivAttributes;
  else privAtr = PrivAttributes;
  // if registration is cancelled by registrar or the user is inactive for some time, then the following useEffect hook will log out the user
  useEffect(() => {
    if (
      [InactiveStatus, ClosedStatus, CancelStatus]?.includes(userDetails?.ApplicantAccountStatus) ||
      userDetails?.RequestStatus === CancelByJurisdiction
    ) {
      signOut();
      navigate("/");
      removeAccessToken();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails?.IsPassphraseChangeRequired && isSignedIn) {
      navigate("/reset-password");
    }
  }, [userDetails, location?.pathname]);

  useEffect(() => {
    if (checkAccess) {
      dispatch(setEntities([]));
      dispatch(getEntityById(entityId || id));
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (privId !== 'priv_lnk_view_auction_event' && privId !== 'priv_lnk_create_edit_event' && privId !== 'priv_lnk_event_application_management') {
      dispatch(getEntityById(entityId || id));
    }
    if (id && entityId) dispatch(getEntitySingleFacilityById(id, entityId));
  }, [privId]);

  useEffect(() => {
    if ((privId === 'priv_lnk_entity_revision_requested' || privId === 'priv_lnk_wiring_CWI' || privId === 'priv_lnk_event_applications_details' || privId === 'priv_lnk_event_applications_create_edit' || privId === "priv_lnk_event_applications_only_create") && (entityId || id)) {
      dispatch(getReceivingEntityDetailsAR(entityId || id));
    }
  }, [entityId, id, privId]);

  const ENTITY_ID = id;

  useEffect(() => {
    if (privId === "priv_lnk_event_applications_create_edit" || privId === "priv_lnk_event_applications_only_create" && ENTITY_ID) {
      dispatch(getEntity(ENTITY_ID));
    }
  }, [ENTITY_ID]);

  // To check is User Active
  const checkIsActiveUser = userDetails?.UserLoginStatus === "Active" && userDetails?.UserAccountStatus === "Active" && userDetails?.ApplicantAccountStatus !== "Pending" && userDetails?.ApplicantAccountStatus !== "Registration Denied";
  const SelectedServiceProviderMUIAuc = useSelector(
    (store) => store?.serviceProSelectionMgm?.selectedServiceProviderMUIAuc
  );
  const assignedToData = SelectedServiceProviderMUIAuc?.data?.data?.serviceProviders;
  const isMMCheckAssignTo = MMAssignedTo(assignedToData, userDetails);
  const isFSACheckAssignTo = FSAAssignedTo(assignedToData, userDetails)

  useEffect(() => {
    if (userDetails?.RoleDetail?.Role[0]?.name === MarketMonitor || userDetails?.RoleDetail?.Role[0]?.name === FSAHome) {
    dispatch(getServiceProviderMUIAuc(PageName));
    }
  }, [userDetails, location?.pathname]);

  if (
    (isSignedIn && (privId === "priv_lnk_NAICS_Code_add_edit" ||
      privId === "priv_lnk_CAG_add_edit" ||
      privId === "priv_lnk_NAICS_Code" ||
      privId === "priv_lnk_market_monitor" ||
      privId === "priv_account_application_attestation_form" ||
      privId === "priv_lnk_event_application_management" ||
      privId === "priv_Link_ear_report" ||
      privId === "priv_lnk_view_auction_event" ||
      privId === "priv_lnk_CAG" ||
      privId === "priv_lnk_Jurisdiction_User_Actions" ||
      privId === "priv_lnk_FSI_Edit" ||
      privId === "priv_lnk_event_applications" ||
      privId === "priv_lnk_entity_legal_name_operating_name_change" ||
      privId === "priv_lnk_facility_name_operating_name_change" ||
      privId === "priv_lnk_facility_ghg_identifier_change" ||
      privId === "priv_lnk_event_applications_details" ||
      privId === "priv_lnk_allreports" ||
      privId === "priv_lnk_event_applications_create_edit" ||
      privId === "priv_lnk_event_applications_only_create" ||
      privId === "priv_lnk_event_applicationsAr"

    )) &&
    userDetails?.RoleDetail === undefined
  ) {
    return (
      <Container>
        <Loader loader={loader} />
      </Container>
    );
  }
if (
  isSignedIn &&
  privId === "priv_lnk_fsa_home_reports" &&
  (userDetails?.RoleDetail?.Role[0]?.name === "MarketMonitor" || userDetails?.RoleDetail === undefined ||
    userDetails?.RoleDetail?.Role[0]?.name === "FSAHome") &&
  isFSACheckAssignTo === undefined
) {
  return (
    <Container>
      <Loader loader={loader} />
    </Container>
  );
}
  if (
    (isSignedIn &&
      (privId === "priv_lnk_event_applications_details" ||
        privId === "priv_lnk_event_applications_create_edit" ||
        privId === "priv_lnk_event_applications_only_create"))
    && ARData === undefined
  ) {
    return (
      <Container>
        <Loader loader={loader} />
      </Container>
    );
  }

  if (privId === "priv_lnk_allreports" && !checkIsActiveUser) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  if (
    checkAccess &&
    [
      USER_ROLES.INDIVIDUAL_USER,
      USER_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE,
      USER_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE,
      USER_ROLES.ACCOUNT_VIEWING_AGENT,
    ].includes(userDetails?.RoleDetail?.Role[0]?.id)
  ) {
    const applicationStatus = entities?.data?.entityStatus?.name;
    if ([ENTITY_APPLICTION_STATUS_NAMES.CANCELLED, ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_DENIED].includes(
      applicationStatus) || !isEmpty(entityErrorRes)) {
      return (
        <Container>
          <PermissionDenied />
        </Container>
      );
    }
  }

  // Cross jurisdiction access.
  const allUsers =
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.REGISTRAR ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.ADMIN ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.USER ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.JURISDICTION_SYSTEM_ADMIN ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.WCI_ADMIN_DEFAULT_ORG ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.JURISDICTION_PROGRAM_STAFF ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.FINANCIAL_SERVICE_PROVIDERS ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.FINANCIAL_SERVICE_PROVIDERS_QA;

  /* NOTE: FINANCIAL_SERVICE_PROVIDERS id's are different with respect to environment 
    for quick fix we have added both the ids. We need to fix in consistency of ids in environments and revisit this code. */
  if (checkAccess && !isEmpty(entities)) {
    if (
      (entities?.response?.data?.data === "FORBIDDEN_ERROR" ||
        entities?.response?.data?.messageKey === "USER_IS_NOT_AUTHORIZED") &&
      allUsers
    ) {
      return (
        <Container>
          <PermissionDenied />
        </Container>
      );
    }
  }

  const allIndividualUser = allUsers || userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.INDIVIDUAL_USER;

  if (
    checkEntityAccessForUser &&
    !userDetails.loading &&
    userDetails?.RoleDetail?.Role[0]?.id &&
    userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.INDIVIDUAL_USER
  ) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }
  if (checkEntityAccessForId && !isEmpty(entities)) {
    if (
      (entities?.response?.data?.data === "FORBIDDEN_ERROR" ||
        entities?.response?.data?.messageKey === "USER_IS_NOT_AUTHORIZED") &&
      allIndividualUser
    ) {
      return (
        <Container>
          <PermissionDenied />
        </Container>
      );
    }
    if (
      (entities?.response?.data?.data === "FORBIDDEN_ERROR" ||
        entities?.response?.data?.messageKey === "USER_IS_NOT_AUTHORIZED") &&
      userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.INDIVIDUAL_USER
    ) {
      return (
        <Container>
          <PermissionDenied />
        </Container>
      );
    }
    if (
      userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.INDIVIDUAL_USER &&
      !isEmpty(userDetails?.RoleDetail?.Role[0]?.id)
    ) {
      return (
        <Container>
          <PermissionDenied />
        </Container>
      );
    }
  }
  if (
    entityRegistrationPage &&
    userDetails?.IsUser &&
    userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.INDIVIDUAL_USER
  ) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const facilityStatus = SingleEntityData?.facilityStatus;
  if (checkAccessForFacility && userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.INDIVIDUAL_USER) {
    if (facilityStatus === FACILITY_STATUS.RETIRED) {
      return (
        <Container>
          <PermissionDenied />
        </Container>
      );
    }
  }

  if (checkAccessForAuction && userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.REGISTRAR) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }
  if (checkAccessFSA) {
    if (
      ![USER_ROLES.FINANCIAL_SERVICE_PROVIDERS, USER_ROLES.FINANCIAL_SERVICE_PROVIDERS_QA].includes(
        userDetails?.RoleDetail?.Role[0]?.id
      )
    ) {
      return (
        <Container>
          <PermissionDenied />
        </Container>
      );
    }
  }


  if (
    privAtr?.[privId]?.PrivRole === "NA" ||
    (userDetails?.ApplicantAccountStatus === ProfileAccessOnly &&
      ![
        RouteConst.UserProfile,
        RouteConst.UserDetails,
        RouteConst.ChangeJurisdiction,
        RouteConst.Home,
        RouteConst.ResetPassword,
        RouteConst.EntityRegistration,
      ]?.includes(location?.pathname))
  ) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const entityData = entities?.data;
  if (privId === "priv_account_application_attestation_form" && entityData?.message === "Request failed with status code 403") {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const loggedinjurisdictionid = localStorage.getItem("jurisdictionID");
  const showNAICSManagement = loggedinjurisdictionid !== JURISDICTIONS_ID.QUEBEC && loggedinjurisdictionid !== JURISDICTIONS_ID.NOVA_SCOTIA ||
    (userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.ADMIN
      && userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.REGISTRAR
      && userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.USER
      && userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.JURISDICTION_SYSTEM_ADMIN
      && userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.JURISDICTION_PROGRAM_STAFF);
  const naicsAccess = showNAICSManagement;
  if (privId === "priv_lnk_NAICS_Code" && naicsAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const addeditNAICSManagement = loggedinjurisdictionid !== JURISDICTIONS_ID.QUEBEC && loggedinjurisdictionid !== JURISDICTIONS_ID.NOVA_SCOTIA
    || (userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.ADMIN);
  if (privId === "priv_lnk_NAICS_Code_add_edit" && addeditNAICSManagement) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const ViewCAG = (
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.ADMIN ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.REGISTRAR ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.USER ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.JURISDICTION_SYSTEM_ADMIN ||
    userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.JURISDICTION_PROGRAM_STAFF
  );

  if (privId === "priv_lnk_CAG" && !ViewCAG) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const addeditCAG = (userDetails?.RoleDetail?.Role[0]?.id !== USER_ROLES.REGISTRAR);
  if (privId === "priv_lnk_CAG_add_edit" && addeditCAG) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const facilityForm = userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.ADMIN;
  if (privId === "priv_lnk_facility_form" && !facilityForm) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }
  const checkAuctionAdminReportAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin ||
      userDetails?.RoleDetail?.Role[0]?.name === AuthorityRole ||
      userDetails?.RoleDetail?.Role[0]?.name === RegistrarUser ||
      userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.JURISDICTION_PROGRAM_STAFF ||
      userDetails?.RoleDetail?.Role[0]?.name === JSA_ROLE ||
      userDetails?.RoleDetail?.Role[0]?.name === AuctionAdministrator);

  if (
    privId === "priv_lnk_BidLimitationsReconciliationReport" &&
    userDetails?.RoleDetail &&
    !checkAuctionAdminReportAccess
  ) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkEventAppCreateEditAccess =
    checkIsActiveUser &&
    receivingEntityDetailsAR?.data &&
    userDetails?.RoleDetail &&
    (ARData?.status === REPRESENTATIVE_STATUS.ACTIVE || ARData?.proposeChanges?.status === REPRESENTATIVE_STATUS.PROPOSED) &&
    (ARData?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE ||
      ARData?.role?.name === REPRESENTATIVES_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE);

  if (
    privId === "priv_lnk_event_applications_create_edit" &&
    entityDetails?.data &&
    receivingEntityDetailsAR?.data &&
    userDetails?.RoleDetail && (entityDetails?.data?.entityAccounts?.[0]?.accountType === "EXCHANGE_CLEARING_HOLDING_ACCOUNT" ||
      !checkEventAppCreateEditAccess)
  ) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkEventAppOnlyCreateAccess =
    checkIsActiveUser &&
    receivingEntityDetailsAR?.data &&
    userDetails?.RoleDetail &&
    initiateParticipation?.data &&
    (ARData?.status === REPRESENTATIVE_STATUS.ACTIVE || ARData?.proposeChanges?.status === REPRESENTATIVE_STATUS.PROPOSED) &&
    (ARData?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE ||
      ARData?.role?.name === REPRESENTATIVES_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE);

  if (
    privId === "priv_lnk_event_applications_only_create" &&
    entityDetails?.data &&
    receivingEntityDetailsAR?.data &&
    initiateParticipation?.data &&
    userDetails?.RoleDetail && (entityDetails?.data?.entityAccounts?.[0]?.accountType === "EXCHANGE_CLEARING_HOLDING_ACCOUNT" ||
      !checkEventAppOnlyCreateAccess || initiateParticipation?.data?.initiateButton === false)
  ) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkEventAppViewAccess =
    checkIsActiveUser &&
    receivingEntityDetailsAR?.data &&
    userDetails?.RoleDetail &&
    (ARData?.status === REPRESENTATIVE_STATUS.ACTIVE || ARData?.proposeChanges?.status === REPRESENTATIVE_STATUS.PROPOSED) &&
    (ARData?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE ||
      ARData?.role?.name === REPRESENTATIVES_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE ||
      ARData?.role?.name === REPRESENTATIVES_ROLES_ACTIVE.ACCOUNT_VIEWING_AGENT);

  if (
    privId === "priv_lnk_event_applications_details" &&
    receivingEntityDetailsAR?.data &&
    userDetails?.RoleDetail &&
    !checkEventAppViewAccess
  ) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkCAGViewAddAccess = userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser;
  if (location.pathname.includes("/corporate-associations") && userDetails?.RoleDetail && !checkCAGViewAddAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkWiringCWIAccess =
    (ARData?.status === REPRESENTATIVE_STATUS.ACTIVE || ARData?.proposeChanges?.status === REPRESENTATIVE_STATUS.PROPOSED) &&
    (ARData?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE ||
      ARData?.role?.name === REPRESENTATIVES_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE);

  if (
    ((privId === "priv_lnk_wiring_CWI" && receivingEntityDetailsAR?.data && action === CWI_ACTIONS.ADD) ||
      action === CWI_ACTIONS.EDIT) &&
    !checkWiringCWIAccess
  ) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkWiringCWIViewAccess =
    (ARData?.status === REPRESENTATIVE_STATUS.ACTIVE || ARData?.proposeChanges?.status === REPRESENTATIVE_STATUS.PROPOSED) &&
    (ARData?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE ||
      ARData?.role?.name === REPRESENTATIVES_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE ||
      ARData?.role?.name === REPRESENTATIVES_ROLES_ACTIVE.ACCOUNT_VIEWING_AGENT);

  if (
    privId === "priv_lnk_wiring_CWI" &&
    action === CWI_ACTIONS.VIEW &&
    userDetails?.RoleDetail &&
    receivingEntityDetailsAR?.data &&
    !checkWiringCWIViewAccess
  ) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkEntityRevisionRequestedAccess =
    (userDetails?.RoleDetail && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) ||
    ARData?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE ||
    ARData?.role?.name === REPRESENTATIVES_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE ||
    ARData?.role?.name === REPRESENTATIVES_ROLES_ACTIVE.ACCOUNT_VIEWING_AGENT;

  if (
    privId === "priv_lnk_entity_revision_requested" &&
    userDetails?.RoleDetail &&
    !isEmpty(receivingEntityDetailsAR?.data) &&
    !checkEntityRevisionRequestedAccess
  ) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  /*
   * FSI Start
   */

  const checkFSIEditPageRole = userDetails?.RoleDetail && userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin;

  if (privId === "priv_lnk_FSI_Edit" && userDetails?.RoleDetail && !checkFSIEditPageRole) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkFSIViewPageRole =
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser ||
      userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin);

  if (privId === "priv_lnk_FSI_View" && userDetails?.RoleDetail && !checkFSIViewPageRole) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }
  // End FSI

  const checkEntityActionsAccess = userDetails?.RoleDetail && userDetails?.RoleDetail?.Role[0]?.name === RegistrarUser;

  if (privId === "priv_lnk_juri_entity_actions" && userDetails?.RoleDetail && !checkEntityActionsAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkServiceProAccess = userDetails?.RoleDetail && (userDetails?.RoleDetail?.Role[0]?.name === RegistrarUser || userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser ||
    userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin);

  if (privId === "priv_lnk_service_pro_selection_mgm_view" && userDetails?.RoleDetail && !checkServiceProAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkServiceProViewAccess = userDetails?.RoleDetail && userDetails?.RoleDetail?.Role[0]?.name === RegistrarUser;

  if (privId === "priv_lnk_service_pro_selection_mgm" && userDetails?.RoleDetail && !checkServiceProViewAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }
  const checkCreateEditEventAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin);

  if (privId === "priv_lnk_create_edit_event" && userDetails?.RoleDetail && !checkCreateEditEventAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkViewEventAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin ||
      userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser);

  if (privId === "priv_lnk_view_auction_event" && userDetails?.RoleDetail && !checkViewEventAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkEventApplicationsAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin ||
      userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser ||
      userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.INDIVIDUAL_USER);

  if (privId === "priv_lnk_event_applications" && userDetails?.RoleDetail && !checkEventApplicationsAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }


  const checkAuctionAdministratorAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === AuctionAdministrator);

  if (privId === "priv_lnk_auction_administrator" && userDetails?.RoleDetail && !checkAuctionAdministratorAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkMarketMonitorAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === MarketMonitor);

  if ((privId === "priv_lnk_market_monitor" && userDetails?.RoleDetail) && (!checkMarketMonitorAccess || isMMCheckAssignTo === false)) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }


  const checkEventAppDataExpReportAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === MarketMonitor || userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin ||
      userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser);

  if (privId === "priv_lnk_event_app_data_exp_report" && userDetails?.RoleDetail && !checkEventAppDataExpReportAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkEventPartReportAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === MarketMonitor || userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin ||
      userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser);

  if ((privId === "priv_lnk_event_participant_report" && userDetails?.RoleDetail) && (!checkEventPartReportAccess || isMMCheckAssignTo === false)) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }


  const checkFSAAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === FSAHome);

  if ((privId === "priv_lnk_fsa_home_reports" && userDetails?.RoleDetail) && (!checkFSAAccess || isFSACheckAssignTo === false)) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkWISReportAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    userDetails?.OrgDetails[0]?.OrgName === "California" &&
    (userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser);

  if ((privId === "priv_lnk_wiring_inst_status_report" && userDetails?.RoleDetail) && !checkWISReportAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }


  const checkEAMAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin ||
      userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser
    );

  if ((privId === "priv_lnk_event_application_management" && userDetails?.RoleDetail) && !checkEAMAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkEARReportccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.name === JuriAdmin ||
      userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser
    );

  if ((privId === "priv_Link_ear_report" && userDetails?.RoleDetail) && !checkEARReportccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  const checkEAReportAccess = checkIsActiveUser &&
    userDetails?.RoleDetail &&
    (userDetails?.RoleDetail?.Role[0]?.id === USER_ROLES.INDIVIDUAL_USER
    );

  if ((privId === "priv_lnk_event_applicationsAr" && userDetails?.RoleDetail) && !checkEAReportAccess) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }

  if (privId === "priv_lnk_Search_Users" && userDetails?.RoleDetail && userDetails?.RoleDetail?.RoleType[0]?.name !== JurisdictionUser) {
    return (
      <Container>
        <PermissionDenied />
      </Container>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
