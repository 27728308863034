import { Box, Typography } from '@mui/material';
import React from 'react'

function LinkExpired() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "60vh",
      }}
    >
      <Typography
        align="center"
        component="div"
        gutterBottom
        sx={{ fontSize: "36px", mb:"24px" }}
      >
        This link is no longer valid.
      </Typography>
      <Typography
        align="center"
        component="div"
        gutterBottom
        sx={{ fontSize: "24px" }}
      >
        Please contact your jurisdiction for a new link
      </Typography>
    </Box>
  );
}

export default LinkExpired
