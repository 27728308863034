import { createSlice } from "@reduxjs/toolkit";
import { APIS } from "utils/constants";
import fetcher, { fetcherGet } from "utils/fetcher";
import logger from "utils/logger";
import { getPermissionObj } from "utils/support";
import { holdingLimitAndLimitedExemptionMockData } from "pages/Reports/HoldingLimitAndLimitedExemption/__mocks__/HoldingLimitAndLimitedExemptionMockData";
import { jurisdictionBatchTransferMockData } from "pages/Reports/JurisdictionBatchTransfer/__mocks__/JurisdictionBatchTransferMockData";
import { purchaseLimitMockData } from "pages/Reports/PurchaseLimits/__mocks__/PurchaseLimit.mock";
import { setLoader } from "./commonSlice";

const initialState = {
  entityAccountBalance: { isLoading: false, data: [], metaData: null, fieldAccess: null },
  holdingLimitAndLimitedExemption: { isLoading: false, data: [], metaData: null, fieldAccess: null },
  jurisdictionBatchTransfer: { isLoading: false, data: [], metaData: null, fieldAccess: null },
  purchaseLimits: { isLoading: false, data: [], metaData: null, fieldAccess: null },
  transferEventAudit: { isLoading: false, data: [], metaData: null, fieldAccess: null },
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setEntityAccountBalance: (state, action) => {
      state.entityAccountBalance = { ...state.entityAccountBalance, ...action.payload };
    },
    setHoldingLimitAndLimitedExemption: (state, action) => {
      state.holdingLimitAndLimitedExemption = { ...state.holdingLimitAndLimitedExemption, ...action.payload };
    },
    setJurisdictionBatchTransfer: (state, action) => {
      state.jurisdictionBatchTransfer = { ...state.jurisdictionBatchTransfer, ...action.payload };
    },
    setPurchaseLimits: (state, action) => {
      state.purchaseLimits = { ...state.purchaseLimits, ...action.payload };
    },
    setTransferEventAudit: (state, action) => {
      state.transferEventAudit = { ...state.transferEventAudit, ...action.payload };
    },
  },
});

export default reportsSlice.reducer;

export const {
  setEntityAccountBalance,
  setHoldingLimitAndLimitedExemption,
  setJurisdictionBatchTransfer,
  setPurchaseLimits,
  setTransferEventAudit,
} = reportsSlice.actions;

export function getEntityAccountBalance(pageDetails = {
  pageName: "Page_Reports",
  componentName: "entityAccountBalanceReport",
}) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setEntityAccountBalance({ isLoading: true }));
    try {
      const response = await fetcherGet(`${APIS.TRANSFERS_BASE}/entityTransfer/${jurisdictionId}/entityAccountBalance`, {}, pageDetails);
      dispatch(
        setEntityAccountBalance({
          isLoading: false,
          data: response?.data,
          fieldAccess: response?.fieldAccess,
          metaData: response?.metaData,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setEntityAccountBalance({ isLoading: false, data: null }));
    }
  };
}

export function getTransferEventAuditInformation() {
  return async (dispatch) => {
    dispatch(setTransferEventAudit({ isLoading: true }));
    try {
      const pageDetails = { pageName: "Page_TransferEventAuditReport", componentName: "transferEventAuditReport" }
      const response = await fetcherGet(`${APIS.GET_TRANSFER_AUDIT_EVENT}`, {}, pageDetails);
      dispatch(
        setTransferEventAudit({
          isLoading: false,
          data: response?.data,
          fieldAccess: response?.fieldAccess,
          metaData: response?.metaData,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTransferEventAudit({ isLoading: false, data: null }));
    }
  };
}

export function getHoldingLimitAndLimitedExemption(pageDetails = {
  pageName: "Page_Reports",
  componentName: "holdingLimitAndLimitedExemption",
}) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    dispatch(setHoldingLimitAndLimitedExemption({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/holdingLimitAndExemptionReport`, {}, pageDetails);
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setHoldingLimitAndLimitedExemption({
          isLoading: false,
          data,
          fieldAccess,
          metaData,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setHoldingLimitAndLimitedExemption({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function getPurchaseLimitsInformation(
  purchaseLimitStyle,
  pageDetails = {
    pageName: "Page_Reports",
    componentName: "purchaseLimit",
  }
) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    dispatch(setPurchaseLimits({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.MANAGE_PURCHASE_LIMITS}/jurisdiction/${jurisdictionId}/entityPurchaseLimitReport?type=${purchaseLimitStyle}`,
        {},
        pageDetails
      );
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setPurchaseLimits({
          isLoading: false,
          data,
          fieldAccess,
          metaData,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setPurchaseLimits({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function getJurisdictionBatchTransfer(pageDetails = {
  pageName: "Page_Reports",
  componentName: "jurisdictionBatchTransfer",
}) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setJurisdictionBatchTransfer({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.TRANSFERS_BASE}/batchTransfer/jurisdiction/${jurisdictionId}/batchTransferReport`, {}, pageDetails);
      const { data, fieldAccess, metaData } = response;
      // const { data, fieldAccess, metaData } = jurisdictionBatchTransferMockData();
      dispatch(
        setJurisdictionBatchTransfer({
          isLoading: false,
          data,
          fieldAccess,
          metaData,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setJurisdictionBatchTransfer({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}