import {Box, Typography} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  t,
} from "utils/constants";
import Style from "./ApplicationChecklist.style";

const PageName = "Page_ApplicationWithAttenstation";

function EntityUserInputForm() {
  const { t: t1 } = useTranslation(PageName);
  return <>
    <Typography sx={Style.entityIdentificationInline} mt={3}>{t(t1, "PRINT_NAME")}:</Typography>
    <Box sx={Style.underlineDiv} />
    <Typography sx={Style.entityIdentificationInline}>{t(t1, "DATE")}:</Typography>
    <Box sx={Style.underlineDiv} />
    <Typography sx={Style.entityIdentificationInline}>{t(t1, "EMPLOYER_NAME")}:</Typography>
    <Box sx={Style.underlineDiv} />
    <Typography sx={Style.entityIdentificationInline}>{t(t1, "TITLE")}:</Typography>
    <Box sx={Style.underlineDiv} />
    <Typography sx={Style.entityIdentificationInline}>{t(t1, "SIGNATURE")}</Typography>
    <Box sx={Style.underlineDiv} />
  </>;
}

export default EntityUserInputForm;
