import { COMMENT_MAX_CHAR_LENGTH, t } from "utils/constants";
import { array, object, string } from "yup";

export const BATCH_TRUE_UP_STATUSES = {
  APPROVED: "APPROVED",
  PROPOSED: "PROPOSED",
  REVISIONS_REQUESTED: "REVISIONS_REQUESTED",
  SAVED: "SAVED",
  DENIED: "DENIED",
  CANCELLED: "CANCELLED",
};

export const TRUE_UP_REGEX = {
  TRUE_UP_QUANTITY: /^[0-9]*$/,
  COMMENT: /^[\s\S]*$/
}


export const trueUpRecordValidationSchema = (t1) =>
  object().shape({
    trueUps: array().of(
      object().shape({
        trueUpsQuantity: string()
        .matches(TRUE_UP_REGEX.TRUE_UP_QUANTITY, t(t1, "ERR_QUANTITY_IS_NOT_VALID_NUMERIC"))
        .max(
          15,
          `${t(t1, "ERR_QUANTITY_MAX_LENGTH_CHAR")}`
        )
        .required(`${t(t1, "ERR_QUANTITY_IS_REQUIRED")}`),
        comment: string()
          .nullable()
          .max(
            COMMENT_MAX_CHAR_LENGTH,
            `${t(t1, "ERR_COMMENTS_MAX_LENGTH_CHAR")}`
          ),
      })
    ),
  });

// transforming batch data to include actionBy field and to sort acc to updatedAt
export function transformBatchData(response) {
  const { data } = response;
  const result = {};

  if (Array.isArray(data)) {
      result.data = data.map(item => {
        const { UpdatedByUser, ...allOtherAttributes } = item;
        return {
            ...allOtherAttributes,
            actionBy: `${UpdatedByUser?.firstName ?? ""} ${UpdatedByUser?.familyName ?? ""}`.trim()
        };
      })?.sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt));
  } else if (typeof data === 'object' && data !== null) {
      const { UpdatedByUser, ...allOtherAttributes } = data;
      result.data = {
          ...allOtherAttributes,
          actionBy: `${UpdatedByUser?.firstName ?? ""} ${UpdatedByUser?.familyName ?? ""}`.trim()
      };
  }

  return result;
}

export function isInEditableStatus(status) {
  return status === BATCH_TRUE_UP_STATUSES.SAVED || status === BATCH_TRUE_UP_STATUSES.REVISIONS_REQUESTED;
}

export function isInProposedOrRevReqStatus(status) {
  return status === BATCH_TRUE_UP_STATUSES.PROPOSED || status === BATCH_TRUE_UP_STATUSES.REVISIONS_REQUESTED;
}

export function isInProposalForAuthority(status) {
  return status === BATCH_TRUE_UP_STATUSES.PROPOSED
}

export const addTrueUpRecordsValidationMapping = {
  listOfIncorrectFacilityNumber: "ERR_INCORRECT_FACILITY_ID",
  listOfFacilityNotInJurisdiction: "ERR_INCORRECT_FACILITY_ID",
  listOfFacilityWithNoPriorRelationship: "ERR_INCORRECT_FACILITY_ID",
  listOfDuplicateRecords: "ERR_DUPLICATE_FACILITY_ID",
  listOfAlreadyProposedFacility: "ERR_ALREADY_PROPOSED_FACILITY_ID"
}