import authRoles from "core/auth/authRole";
import RoleDetails from "./RoleDetails";

const RoleDetailsConfig = {
  route: {
    path: "/role-details",
    element: <RoleDetails />,
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.onlyGuest,
};

export default RoleDetailsConfig;
