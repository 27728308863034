const Style = {
  bottomBar: {
    bgcolor: "primary.main",
    height: "40px",
    display: 'flex', alignItems: 'center'
  },
  menuWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  menuItem: {
    color: "common.white",
    width: "auto",
  },
};

export default Style;
