import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { CounterField } from "components/CounterField";
import ReactHookFormSelectSearch from "components/ReactHookFormSelectSearch/ReactHookFormSelectSearch";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrentPageName, t } from "utils/constants";
import {
  StyledAccountLabelTypograpy,
  StyledBox,
  StyledTransferExpirySettings,
  StyledTypograpyTransferDetail,
} from "./ConfigurationSettings.style";
import { generateYearObjects, transferExpiryRegex } from "./constants";
import Style from "./styles";

function Transfers({ updateErrorValue, configureState, methods }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;

  return (
    <Grid
      item
      sm={12}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: 600, textTransform: "uppercase" }}
        gutterBottom
      >
        {t(t1, "TRANSFERS")}
      </Typography>
      <div className="paneldetail">
        {/** Vintage Year start here */}
        <Accordion sx={Style.accordianStyling}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            className="custompanel"
            id="panel5a-header"
            sx={{ minHeight: "43px !important", maxHeight: "43px !important" }}
          >
            <Typography>{t(t1, "VINTAGE_YEAR")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="default-accounts-main">
              <Box>
                <p className="configuration-setting-intro-text">{t(t1, "VINTAGE_YEAR_TRANSFERS_CONTENT")}</p>
              </Box>
              <Box>
                <StyledAccountLabelTypograpy>{t(t1, "CURRENT_VINTAGE_YEAR_STARTS_AT")}</StyledAccountLabelTypograpy>
                <StyledBox>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    lg={3}
                    xl={3}
                  >
                    <StyledTypograpyTransferDetail>{t(t1, "FOR_ALLOWANCES")}</StyledTypograpyTransferDetail>
                    <ReactHookFormSelectSearch
                      gridMd={5}
                      className="fc-select-rounded"
                      name="transfer-settings.current_vintage_year_allowance.AttributeValue"
                      data-testid="current_vintage_year_allowance"
                      privId={`priv_txt_current_vintage_year_allowance`}
                      inputValue={methods.watch("transfer-settings.current_vintage_year_allowance.AttributeValue")}
                      placeholder={t(t1, "SELECT_PLACEHOLDER")}
                      disabled={PrivAttributes?.priv_txt_current_vintage_year_allowance?.PrivRole === "RO"}
                      options={generateYearObjects(2001) ?? []}
                      rules={{
                        required: true,
                      }}
                      requiredMessage={'ERR_THIS_FIELD_IS_REQUIRED'}
                    />
                  </Grid>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    lg={3}
                  >
                    <StyledTypograpyTransferDetail>{t(t1, "FOR_OFFSET")}</StyledTypograpyTransferDetail>
                    <ReactHookFormSelectSearch
                      gridMd={5}
                      className="fc-select-rounded"
                      name="transfer-settings.current_vintage_year_offset.AttributeValue"
                      data-testid="current_vintage_year_offset"
                      privId={`priv_txt_current_vintage_year_offset`}
                      inputValue={methods.watch("transfer-settings.current_vintage_year_offset.AttributeValue")}
                      placeholder={t(t1, "SELECT_PLACEHOLDER")}
                      // disabled={!isJSA}
                      disabled={PrivAttributes?.priv_txt_current_vintage_year_offset?.PrivRole === "RO"}
                      options={generateYearObjects(2001) ?? []}
                      rules={{
                        required: true,
                      }}
                      requiredMessage={'ERR_THIS_FIELD_IS_REQUIRED'}
                    />
                  </Grid>
                </StyledBox>
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
        {/** Vintage Year ends here */}
        {/** Transfer Expiry starts here */}
        <Accordion sx={Style.accordianStyling}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            className="custompanel"
            id="panel3a-header"
            sx={{ minHeight: "43px !important", maxHeight: "43px !important" }}
          >
            <Typography>{t(t1, "TRANSFER_EXPIRY")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="default-accounts-main">
              <Box>
                <p className="configuration-setting-intro-text">{t(t1, "TRANSFER_EXPIRY_CONTENT")}</p>
              </Box>
              <Box>
                <StyledBox>
                  <StyledTransferExpirySettings>{t(t1, "ENTITY_TRANSFER")}</StyledTransferExpirySettings>
                  <div className="plus-minus-btn-main">
                    <List>
                      <ListItem>
                        <CounterField
                          inputClass="hideBrowserDefault"
                          name="transfer-settings.entity_transfer_proposed_expiry.AttributeValue"
                          privId="priv_submit_entity_transfer_proposed_expiry"
                          label={t(t1, "ENTITY_EXPIRY_PROPOSED")}
                          defaultValue={
                            configureState["transfer-settings"]?.entity_transfer_proposed_expiry?.AttributeValue || "0"
                          }
                          updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                          type="text"
                          minValue={0}
                          maxValue={999}
                          disabled={PrivAttributes?.priv_submit_entity_transfer_proposed_expiry?.PrivRole === "RO"}
                          rules={{
                            required: true,
                            pattern: {
                              value: transferExpiryRegex,
                              message: `${t(t1, "ERR_INVALID_ENTITY_EXPIRY_PROPOSED")}`,
                            },
                          }}
                          isRequiredMsg = {'ERR_ENTITY_EXPIRY_PROPOSED_IS_REQUIRED'}
                        />
                      </ListItem>
                      <ListItem>
                        <CounterField
                          inputClass="hideBrowserDefault"
                          name="transfer-settings.entity_transfer_approved_expiry.AttributeValue"
                          privId="priv_submit_entity_transfer_approved_expiry"
                          label={t(t1, "ENTITY_EXPIRY_APPROVED")}
                          defaultValue={
                            configureState["transfer-settings"]?.entity_transfer_approved_expiry?.AttributeValue || "0"
                          }
                          updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                          type="text"
                          minValue={0}
                          maxValue={999}
                          disabled={PrivAttributes?.priv_submit_entity_transfer_approved_expiry?.PrivRole === "RO"}
                          rules={{
                            required: true,
                            pattern: {
                              value: transferExpiryRegex,
                              message: `${t(t1, 'ERR_INVALID_ENTITY_EXPIRY_APPROVED')}`,
                            },
                          }}
                          isRequiredMsg = {'ERR_ENTITY_EXPIRY_APPROVED_IS_REQUIRED'}
                        />
                      </ListItem>
                    </List>
                  </div>
                </StyledBox>
                <StyledBox>
                  <StyledTransferExpirySettings>{t(t1, "JURISDICTION_TRANSFER")}</StyledTransferExpirySettings>
                  <div className="plus-minus-btn-main">
                    <List>
                      <ListItem>
                        <CounterField
                          inputClass="hideBrowserDefault"
                          name="transfer-settings.jurisdiction_transfer_administrative_expiry.AttributeValue"
                          privId="priv_submit_jurisdiction_transfer_administrative_expiry"
                          label={t(t1, "JURISDICTION_EXPIRY_PROPOSED")}
                          defaultValue={
                            configureState["transfer-settings"]?.jurisdiction_transfer_administrative_expiry
                              ?.AttributeValue || "0"
                          }
                          updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                          type="text"
                          minValue={0}
                          maxValue={999}
                          disabled={PrivAttributes?.priv_submit_jurisdiction_transfer_administrative_expiry?.PrivRole === "RO"}
                          rules={{
                            required: true,
                            pattern: {
                              value: transferExpiryRegex,
                              message: `${t(t1, "ERR_INVALID_JURISDICTION_EXPIRY_PROPOSED")}`,
                            },
                          }}
                          isRequiredMsg ={'ERR_JURISDICTION_EXPIRY_PROPOSED_IS_REQUIRED'}
                        />
                      </ListItem>
                      <ListItem>
                        <CounterField
                          inputClass="hideBrowserDefault"
                          name="transfer-settings.jurisdiction_transfer_replenishment_expiry.AttributeValue"
                          privId="priv_submit_jurisdiction_transfer_replenishment_expiry"
                          label={t(t1, "JURISDICTION_EXPIRY_APPROVED")}
                          defaultValue={
                            configureState["transfer-settings"]?.jurisdiction_transfer_replenishment_expiry
                              ?.AttributeValue || "0"
                          }
                          updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                          type="text"
                          minValue={0}
                          maxValue={999}
                          disabled={PrivAttributes?.priv_submit_jurisdiction_transfer_replenishment_expiry?.PrivRole === "RO"}
                          rules={{
                            required: true,
                            pattern: {
                              value: transferExpiryRegex,
                              message: `${t(t1, "ERR_INVALID_JURISDICTION_EXPIRY_APPROVED")}`,
                            },
                          }}
                          isRequiredMsg = {'ERR_JURISDICTION_EXPIRY_APPROVED_IS_REQUIRED'}
                        />
                      </ListItem>
                    </List>
                  </div>
                </StyledBox>
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
        {/** Transfer Expiry ends here */}
      </div>
    </Grid>
  );
}

export default Transfers;
