import { JURISDICTIONS_NAMES, isEmpty } from './constants';

export const getStateCountryID = (stateCountryObject, stateCountry) => stateCountry;

export const getOrganizationID = (organizations, org) => org;

export const getIdentifiers = (Country, StateProvince, newStepsData, selectedJury) => {
  const identifierObject = {
    incorporationCountry: getStateCountryID(Country, newStepsData?.ENTITY_INFORMATION?.country),
    incorporationStateOrProvince: getStateCountryID(StateProvince, newStepsData?.ENTITY_INFORMATION?.province),
    dateOfIncorporation: newStepsData?.ENTITY_INFORMATION?.date,
    incorporationRegion: newStepsData?.ENTITY_INFORMATION?.incorporationRegion,
    governmentIssedTaxpayerOrEmployerIdentificationNumber: newStepsData?.ENTITY_INFORMATION?.IdentificationNumber,
  };
  if (selectedJury === JURISDICTIONS_NAMES.CALIFORNIA) {
    const idNumberAssignedByTheCaliforniaSecretaryOfState = newStepsData?.ENTITY_INFORMATION?.idNumberAssignedByTheCaliforniaSecretaryOfState;
    identifierObject.idNumberAssignedByTheCaliforniaSecretaryOfState = idNumberAssignedByTheCaliforniaSecretaryOfState === '' ? null : idNumberAssignedByTheCaliforniaSecretaryOfState;
  }

  if (
    [JURISDICTIONS_NAMES.CALIFORNIA, JURISDICTIONS_NAMES.QUEBEC, JURISDICTIONS_NAMES.WASHINGTON_STATE].includes(
      selectedJury,
    )
  ) {
    const idNumberAssignedByIncorporatingAgency = newStepsData?.ENTITY_INFORMATION?.IDByAgency;
    identifierObject.idNumberAssignedByIncorporatingAgency = idNumberAssignedByIncorporatingAgency === '' ? null : idNumberAssignedByIncorporatingAgency;
  }

  if ([JURISDICTIONS_NAMES.QUEBEC, JURISDICTIONS_NAMES.WASHINGTON_STATE].includes(selectedJury)) {
    const dunsNumber = newStepsData?.ENTITY_INFORMATION?.dunsNumber;
    identifierObject.dunsNumber = dunsNumber === '' ? null : dunsNumber;
  }

  if (selectedJury === JURISDICTIONS_NAMES.WASHINGTON_STATE) {
    const unitedBusineddId = newStepsData?.ENTITY_INFORMATION?.UnitedBusinessID;
    identifierObject.washingtonUnifiedBusinessIdentifierNumberUBI = unitedBusineddId === '' ? null : unitedBusineddId;
  }

  if (selectedJury === JURISDICTIONS_NAMES.QUEBEC) {
    const enterpriseNumber = newStepsData?.ENTITY_INFORMATION?.québecEnterpriseNumber;
    identifierObject.québecEnterpriseNumber = enterpriseNumber === '' ? null : enterpriseNumber;
  }

  if (selectedJury === JURISDICTIONS_NAMES.NOVA_SCOTIA) {
    const businessNumber = newStepsData?.ENTITY_INFORMATION?.businessNumber;
    const registerId = newStepsData?.ENTITY_INFORMATION?.registryID;
    identifierObject.businessNumberAssignedByTheCanadaRevenueAgency = businessNumber === '' ? null : businessNumber;
    identifierObject.registryIdAssignedByServiceNovaScotiaUnderTheRegistryOfJointStockCompanies = registerId === '' ? null : registerId;
  }

  return identifierObject;
};

export const processFacility = (facility, isProposed, oldFacility) => ({
  facilityId: facility?.facilityId || oldFacility?.facilityId,
  status: isProposed ? facility?.status?.Name : facility?.Status?.name,
  id: facility.facility_id || facility.id,
  isFacilityInRegistry: facility?.is_facility,
  GHGID: facility?.ghg_emission_report_id || facility?.ghgEmissionsReportingId,
  NAICSCode: facility?.naics_code,
  updatedAt: facility?.updatedAt,
  createdAt: facility?.createdAt,
  created_by: facility?.created_by,
  updated_by: facility?.updated_by,
  legalName: facility?.legal_name,
  operatingName: facility?.operating_name,
  useEntityData: facility?.is_use_entity,
  mailingSameAsPhysical: facility?.MailingAddress?.SameAsPhysicalAddress,
  physicalAddress:
    !isProposed || (isProposed && facility?.PhysicalAddress)
      ? {
        id: facility?.PhysicalAddress?.ID,
        street1: facility?.PhysicalAddress?.Street1,
        street2: facility?.PhysicalAddress?.Street2,
        city: facility?.PhysicalAddress?.City,
        // country: facility?.PhysicalAddress?.CountryID,
        Country: {
          ID: facility?.PhysicalAddress?.Country?.ID,
          Name: facility?.PhysicalAddress?.Country?.Name,
        },
        State: {
          ID: facility?.PhysicalAddress?.State?.ID,
          Name: facility?.PhysicalAddress?.State?.Name,
        },
        stateOrProvince: facility?.PhysicalAddress?.StateID,
        postalCode: facility?.PhysicalAddress?.PostalCode,
        region: facility?.PhysicalAddress?.Region,
      }
      : {
        id: oldFacility?.PhysicalAddress?.ID,
        street1: oldFacility?.PhysicalAddress?.Street1,
        street2: oldFacility?.PhysicalAddress?.Street2,
        city: oldFacility?.PhysicalAddress?.City,
        // country: facility?.PhysicalAddress?.CountryID,
        Country: {
          ID: oldFacility?.PhysicalAddress?.Country?.ID,
          Name: oldFacility?.PhysicalAddress?.Country?.Name,
        },
        State: {
          ID: oldFacility?.PhysicalAddress?.State?.ID,
          Name: oldFacility?.PhysicalAddress?.State?.Name,
        },
        stateOrProvince: oldFacility?.PhysicalAddress?.StateID,
        postalCode: oldFacility?.PhysicalAddress?.PostalCode,
        region: oldFacility?.PhysicalAddress?.Region,
      },
  mailingAddress:
    !isProposed || (isProposed && facility?.PhysicalAddress)
      ? {
        id: facility?.MailingAddress?.ID,
        street1: facility?.MailingAddress?.Street1,
        street2: facility?.MailingAddress?.Street2,
        city: facility?.MailingAddress?.City,
        country: facility?.MailingAddress?.CountryID,
        stateOrProvince: facility?.MailingAddress?.StateID,
        postalCode: facility?.MailingAddress?.PostalCode,
        region: facility?.MailingAddress?.Region,
      }
      : {
        id: oldFacility?.MailingAddress?.ID,
        street1: oldFacility?.MailingAddress?.Street1,
        street2: oldFacility?.MailingAddress?.Street2,
        city: oldFacility?.MailingAddress?.City,
        country: oldFacility?.MailingAddress?.CountryID,
        stateOrProvince: oldFacility?.MailingAddress?.StateID,
        postalCode: oldFacility?.MailingAddress?.PostalCode,
        region: oldFacility?.MailingAddress?.Region,
      },
  contactInfo:
    !isProposed || (isProposed && facility?.PhysicalAddress)
      ? {
        id: facility?.facility_contact_id,
        firstName: facility?.contact?.first_name,
        lastName: facility?.contact?.last_name,
        jobTitle: facility?.contact?.job_title,
        phone: facility?.contact?.phone_number,
        mobilePhone: facility?.contact?.mobile_number,
        email: facility?.contact?.email_address,
      }
      : null,
  rows: [],
});

export const mapGetFacility = (facilities) => {
  if (facilities && !isEmpty(facilities)) {
    const newFacilities = facilities?.map((facility) => {
      const processedFacility = processFacility(facility);
      const processedProposedFacility = facility?.proposeChanges
        ? processFacility(facility?.proposeChanges, true, facility)
        : null;
      const content = facility.proposeChanges ? processedProposedFacility : processedFacility;
      return {
        ...content,
        isNewProposedFacility: facility?.proposeChanges?.action === 'add',
        proposed_facility_id: facility?.proposeChanges?.id,
        proposeChanges: processedProposedFacility,
      };
    });
    return newFacilities;
  }
  return [];
};

export const processProposedUpsertData = (facility, existingFacility) => {
  const PhysicalAddress = facility?.PhysicalAddress || existingFacility?.PhysicalAddress;
  const MailingAddress = facility?.MailingAddress || existingFacility?.MailingAddress;
  const contact = facility?.contact || existingFacility?.contact;
  return {
    physical: PhysicalAddress
      ? {
        Street1: PhysicalAddress?.Street1,
        Street2: PhysicalAddress?.Street2,
        City: PhysicalAddress?.City,
        StateID: PhysicalAddress?.StateID,
        Region: PhysicalAddress?.Region,
        PostalCode: PhysicalAddress?.PostalCode,
        CountryID: PhysicalAddress?.CountryID,
        SameAsPhysicalAddress: PhysicalAddress?.SameAsPhysicalAddress,
        Type: 'Physical',
      }
      : null,
    mailing: MailingAddress
      ? {
        Street1: MailingAddress?.Street1,
        Street2: MailingAddress?.Street2,
        City: MailingAddress?.City,
        StateID: MailingAddress?.StateID,
        Region: MailingAddress?.Region,
        PostalCode: MailingAddress?.PostalCode,
        CountryID: MailingAddress?.CountryID,
        SameAsPhysicalAddress: MailingAddress?.SameAsPhysicalAddress,
        Type: 'Mailing',
      }
      : null,
    facilityContact: contact
      ? {
        mobile_number: contact?.mobile_number || null,
        phone_number: contact?.phone_number || null,
        first_name: contact?.first_name,
        last_name: contact?.last_name,
        email_address: contact?.email_address,
        job_title: contact?.job_title,
      }
      : null,
    facility: {
      facility_id: facility?.action === 'add' ? facility?.id : facility?.facility_id,
      entity_id: facility?.entity_id,
      status_id: facility?.status_id,
      action: facility?.action,
      is_facility: facility?.is_facility,
      is_use_entity: facility?.is_use_entity,
      ghg_emission_report_id: facility?.ghg_emission_report_id,
      legal_name: facility?.legal_name,
      operating_name: facility?.operating_name,
      entity_type: 'd601fc39-a224-4593-80b1-cff41777b0df',
    },
  };
};

export const processProposedChangeFacilityData = (facility) => {
  let upsertFacility = {};
  if (!isEmpty(facility?.proposeChanges)) {
    upsertFacility = { ...facility?.proposeChanges };  // deep clone
    if (isEmpty(upsertFacility?.contact)) {
      upsertFacility.contact = facility?.contact;
    }
    if (isEmpty(upsertFacility?.mailingAddress)) {
      upsertFacility.mailingAddress = facility?.mailingAddress;
    }
    if (isEmpty(upsertFacility?.physicalAddress)) {
      upsertFacility.physicalAddress = facility?.physicalAddress;
    }
    if (isEmpty(upsertFacility?.facilityId)) {
      upsertFacility.facilityId = facility?.facilityId;
    }
    if (isEmpty(upsertFacility?.createdBy)) {
      upsertFacility.createdBy = facility?.createdBy;
    }
    if (!isEmpty(facility?.facilityStatus)) {
      upsertFacility.facilityStatus = facility?.facilityStatus;
    }

    upsertFacility.proposedChanges = facility?.proposeChanges;
    upsertFacility.oldGHG = facility?.ghgEmissionsReportingId ? facility?.ghgEmissionsReportingId : facility?.proposeChanges?.ghgEmissionsReportingId;
    upsertFacility.oldFacilityName = facility?.facilityName ? facility?.facilityName : facility?.proposeChanges?.facilityName;
    upsertFacility.oldOperatingName = facility?.operatingName ? facility?.operatingName : facility?.proposeChanges?.operatingName;
    upsertFacility.oldNAICS = facility?.naicsCode ? facility?.naicsCode : facility?.proposeChanges?.naicsCode;

    upsertFacility = { ...upsertFacility };
    upsertFacility.proposedFacilityId = facility?.proposeChanges?.id;

  }
  return upsertFacility;
};

export const processEditFacilityData = (proposedFacility, existingFacility) => {
  const PhysicalAddress = proposedFacility?.physicalAddress || existingFacility?.physicalAddress;
  const MailingAddress = proposedFacility?.mailingAddress || existingFacility?.mailingAddress;
  const contact = proposedFacility?.contactInfo || existingFacility?.contactInfo;

  return {
    GHGID: proposedFacility?.GHGID || existingFacility?.GHGID,
    NAICSCode: proposedFacility?.NAICSCode || existingFacility?.NAICSCode,
    isFacilityInRegistry: proposedFacility?.isFacilityInRegistry || existingFacility?.isFacilityInRegistry,
    is_new: false,
    legalName: proposedFacility?.legalName || existingFacility?.legalName,
    mailingSameAsPhysical: proposedFacility?.mailingSameAsPhysical || existingFacility?.mailingSameAsPhysical,
    operatingName: proposedFacility?.operatingName || existingFacility?.operatingName,

    facility_id: proposedFacility?.facility_id || existingFacility?.facility_id || proposedFacility?.id,
    entity_id: proposedFacility?.entity_id,
    status_id: proposedFacility?.status_id,
    action: proposedFacility?.action || 'edit',

    mailingAddress: MailingAddress
      ? {
        street1: MailingAddress?.street1,
        street2: MailingAddress?.street2,
        city: MailingAddress?.city,
        stateOrProvince: MailingAddress?.stateOrProvince,
        region: MailingAddress?.region,
        postalCode: MailingAddress?.postalCode,
        country: MailingAddress?.country,
        mailingSameAsPhysical: existingFacility?.mailingSameAsPhysical,
        Type: 'Mailing',
      }
      : null,

    physicalAddress: PhysicalAddress
      ? {
        street1: PhysicalAddress?.street1,
        street2: PhysicalAddress?.street2,
        city: PhysicalAddress?.city,
        stateOrProvince: PhysicalAddress?.stateOrProvince,
        region: PhysicalAddress?.region,
        postalCode: PhysicalAddress?.postalCode,
        country: PhysicalAddress?.Country?.ID,
        mailingSameAsPhysical: existingFacility?.mailingSameAsPhysical,
        Type: 'Physical',
      }
      : null,
    contactInfo: contact
      ? {
        mobilePhone: contact?.mobilePhone || null,
        phone: contact?.phone || null,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        email: contact?.email,
        jobTitle: contact?.jobTitle,
      }
      : null,
  };
};

export const prepareCAGCheckData = (members, CAGdetails, removedCAGMembers) => {
  const formattedMembersData = members.map((member) => {
    let hl = Number(member?.holding_limit_share);
    let pl = Number(member?.purchase_limit_share);
    let action = 'add';

    if (member?.entityUuid) {
      if (member?.proposed_hl) {
        hl = member?.proposed_hl;
      } else {
        hl = member?.holdingLimitSharePercent;
      }
// ------------
      if (member?.proposed_pl) {
        pl = member?.proposed_pl;
      } else {
        pl = member?.purchaseLimitSharePercent;
      }
// -------------
      if ((member?.proposed_pl || member?.proposed_hl || member?.retire) && member?.action !== 'add') {
        action = 'edit';
      } else {
        action = member?.action || null;
      }
    }
    // else {
    //   hl = member?.holding_limit_share;
    //   pl = member?.purchase_limit_share;
    //   action = "add";
    // }
    const retirementStatus = removedCAGMembers.find((item) => (item.entityId === member?.entityId));
    return {
      cagId: member?.id ? CAGdetails?.id : null,
      id: member?.id || null,
      entityId: member?.entityId || member?.entity_id,
      holdingLimitSharePercentage: hl || 0,
      purchaseLimitSharePercentage: pl || 0,
      retire: retirementStatus?.retire || false,
      action,
      existingPlValue: member?.existingPlValue,
      existingHlValue: member?.existingHlValue,
    };
  });
  return formattedMembersData;
};

export const prepareSubmitData = (groupData, memberData, removedMembersData) => {
  let prepareData = {
    cagGroupName: groupData.cagGroupName,
    cagGroupReference: groupData.cagGroupReference,
    isCrossJurisdictionGroup: groupData.isCrossJurisdictionGroup,
    jurisdictions: groupData.jurisdictions,
    cagMembers: memberData,
  };
  if (removedMembersData) {
    prepareData = { ...prepareData, removedCagMember: removedMembersData };
  }
  return prepareData;
};


export const sanityPermissionPostPutData = (inputObject, fieldPermissions) => {


  const result = {};

  Object.entries(inputObject).map((compNames) => {
    const [name, dataObjs] = compNames;
    const compData = fieldPermissions.find((item) => item.componentName === name);

    Object.keys(dataObjs).forEach((dataKey) => {
      const compField = compData.componentFields.find((compField) => compField.fieldName === dataKey);
      if (!compField.fieldPermission.includes('write')) {
        delete dataObjs[dataKey];
      }
    });
    result[name] = dataObjs;
    return compNames;
  });

  return result;
};