import { useEffect, useRef, useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import SyncIcon from "@mui/icons-material/Sync";

const SUBMIT_FILTER_STROKE_TIME = 500;

function InputNumberInterval(props) {
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = useRef();
  const [filterValueState, setFilterValueState] = useState([null,null]);

  const [applying, setIsApplying] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  useEffect(() => {
    const itemValue = item?.value ?? [null,null]
/* Conflict Resolution Candidate
    // console.log(itemValue,"itemValue")
 */
    console.log(itemValue,"itemValue")
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound, upperBound) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [lowerBound, upperBound] });
    }, SUBMIT_FILTER_STROKE_TIME);
  };

  const InputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <CalendarTodayIcon fontSize="1rem" />
      </InputAdornment>
    ),
  };

/* Conflict Resolution Candidate
  // console.log(item.value,"value")
 */
  console.log(item.value,"value")

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: "From", end: "To" }}>
        <DateRangePicker
          
          value={filterValueState}
          onChange={(newValue) => {
            updateFilterValue(newValue[0], newValue[1]);
          }}
          // showToolbar
          // views={['day', 'month', 'year']}
          // disableAutoMonthSwitching
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} variant="standard" InputProps={InputProps} />
              <Box sx={{ mx: 1 }}>to</Box>
              <TextField {...endProps} variant="standard" InputProps={InputProps} />
              {applying && (
                <Box sx={{ "& svg": { verticalAlign: "bottom" } }}>
                  <SyncIcon />
                </Box>
              )}
            </>
          )}
        />
      </LocalizationProvider>
      // <>
      // <TextField
      //   type="date"
      //   variant="standard"
      //   label="from"
      //   defaultValue="2019-05-24"
      //   onChange={(event)=>{
      //     console.log(event.target.value,"from date")
      //   }}
      // />
      // <TextField
      //   type="date"
      //   variant="standard"
      //   label="to"
      //   defaultValue="2019-05-24"
      // />
      // </>
    )
}

export const betweenOperators = [
  {
    label: "Between",
    value: "between",
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem?.value) || filterItem?.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      return ({ value }) => {
        return (
          value !== null && +filterItem.value[0].$d <= +new Date(value) && +new Date(value) <= +filterItem.value[1].$d
        );
      };
    },
    InputComponent: InputNumberInterval,
  },
];
