import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { fetcherGet, fetcherPost, fetcherPatch, fetcherDelete } from "utils/fetcher";
import { APIS, APPROVAL_TYPES, LIMITED_EXEMPTION_STATUSES, formatDateUpdated } from "utils/constants";
import { LIMITED_EXEMPTION_COMPONENTS, LIMITED_EXEMPTION_PAGE } from "pages/LimitedExemption/constants";
import { setLoader } from "./commonSlice";

const initialState = {
  batches: [],
  batch: {},
  batchViewMode: true,
  approvedBudgetYears: [],
  batchExemptions: [],
  exemptions: [],
  batchHistory: [],
  exemption: {},
  statuses: {},
  fileUploadErrors: [],
  proposedBatchExemptions: [],
  batchListPermissions: {},
  exemptionsPermissions: {},
  batchExemptionsPermissions: {},
  batchHistoryPermissions: {},
  exemptionLoader:false,
};

const LimitedExemptionSlice = createSlice({
  name: "limitedExemption",
  initialState,
  reducers: {
    setBatches: (state, action) => {
      state.batches = action.payload;
    },
    setBatch: (state, action) => {
      state.batch = action.payload;
    },
    setBatchViewMode: (state, action) => {
      state.batchViewMode = action.payload;
    },
    setApprovedBudgetYears: (state, action) => {
      const years = [...action.payload].map((i) => ({ name: `${i}`, value: i }));
      state.approvedBudgetYears = years;
    },
    setExemptions: (state, action) => {
      state.exemptions = [...action.payload];
    },
    setBatchExemptions: (state, action) => {
      state.batchExemptions = [...action.payload];
    },
    setExemption: (state, action) => {
      state.exemption = { ...action.payload };
    },
    setBatchHistory: (state, action) => {
      state.batchHistory = [...action.payload];
    },
    setStatuses: (state, action) => {
      state.statuses = { ...action.payload };
    },
    setFileUploadErrors: (state, action) => {
      state.fileUploadErrors = action.payload;
    },
    setProposedBatchExemptions: (state, action) => {
      state.proposedBatchExemptions = action.payload;
    },
    setBatchListPermissions: (state, action) => {
      state.batchListPermissions = action.payload;
    },
    setExemptionsPermissions: (state, action) => {
      state.exemptionsPermissions = action.payload;
    },
    setBatchExemptionsPermissions: (state, action) => {
      state.batchExemptionsPermissions = action.payload;
    },
    setBatchHistoryPermissions: (state, action) => {
      state.batchHistoryPermissions = action.payload;
    },
    setExemptionLoader: (state, action) => {
      state.exemptionLoader = action.payload;
    },
  },
});

export const {
  setBatches,
  setBatch,
  setBatchViewMode,
  setApprovedBudgetYears,
  setExemptions,
  setBatchHistory,
  setExemption,
  setBatchExemptions,
  setStatuses,
  setFileUploadErrors,
  setProposedBatchExemptions,
  setBatchListPermissions,
  setExemptionsPermissions,
  setBatchExemptionsPermissions,
  setBatchHistoryPermissions,
  setExemptionLoader,
} = LimitedExemptionSlice.actions;
export default LimitedExemptionSlice.reducer;

export function getApprovedBudgetYears( 
  pageName = LIMITED_EXEMPTION_PAGE,
  componentName = LIMITED_EXEMPTION_COMPONENTS.GET_BUDGET_YEARS) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `budgets/api/jurisdictions/${jurisdictionId}/budget-year?budgetStatus=${LIMITED_EXEMPTION_STATUSES.APPROVED}`,
      {},
      { pageName, componentName }
    )
      .then(({ data }) => {
        dispatch(setApprovedBudgetYears([...new Set(data?.map(({ budgetYear }) => budgetYear).sort())]));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getBatchList() {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batches`,
      {},
      { componentName: LIMITED_EXEMPTION_COMPONENTS.BATCH_LIST }
    )
      .then(({ data = {}, ...permissionsData }) => {
        dispatch(setBatches(data || []));
        dispatch(setBatchListPermissions(permissionsData || {}));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function saveBatch(payload) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batch`,
      {
        ...payload,
      },
      { componentName: LIMITED_EXEMPTION_COMPONENTS.CREATE_BATCH }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function updateBatch({ id, ...payload }) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPatch(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batch/${id}`,
      {
        ...payload,
      },
      { componentName: LIMITED_EXEMPTION_COMPONENTS.EDIT_BATCH }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getBatch(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batches`,
      {
        batchId,
      },
      { componentName: LIMITED_EXEMPTION_COMPONENTS.BATCH_LIST }
    )
      .then(({ data = {} }) => {
        const batchDetails = data?.[0] || {};
        dispatch(setBatch({ ...batchDetails }));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function addLimitedExemption({ batchId, id = "", ...payload }) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    const method = id ? fetcherPatch : fetcherPost;
    dispatch(setLoader(true));
    return method(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batch/${batchId}/exemption/${id}`,
      {
        ...payload,
      },
      { componentName: LIMITED_EXEMPTION_COMPONENTS.ADD_EXEMPTION }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getBatchExemptions(batchId) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/exemption`,
      {
        batchId,
      },
      { componentName: LIMITED_EXEMPTION_COMPONENTS.EXEMPTIONS }
    )
      .then(({ data = [], ...permissionsData }) => {
        dispatch(setBatchExemptions(data));
        dispatch(setBatchExemptionsPermissions(permissionsData || {}));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getExemptions() {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/exemption`,
      {},
      { componentName: LIMITED_EXEMPTION_COMPONENTS.EXEMPTIONS }
    )
      .then(({ data = {}, ...permissionsData }) => {
        dispatch(setExemptions(data));
        dispatch(setExemptionsPermissions(permissionsData || {}));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getBatchHistory(batchId) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    return fetcherGet(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batch/${batchId}/history`,
      {},
      {
        componentName: LIMITED_EXEMPTION_COMPONENTS.BATCH_HISTORY,
      }
    ).then(({ data = [], ...permissionsData }) => {
      dispatch(setBatchHistory(data));
      dispatch(setBatchHistoryPermissions(permissionsData || {}));
    });
  };
}

export function deleteBatch(batchId) {
  return (dispatch) => {
    dispatch(setLoader(false));
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    return fetcherDelete(`${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batch/${batchId}`).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function deleteExemptionFromBatch(exemptionId, batchId) {
  return (dispatch) => {
    dispatch(setLoader(false));
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    return fetcherDelete(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batch/${batchId}/exemption/${exemptionId}`
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getStatusList() {
  return (dispatch) =>
    fetcherGet(`${APIS.STATUS_LIST}`, { type: "LIMITED_EXEMPTION" }).then(({ data = [] }) => {
      dispatch(setStatuses(_.keyBy(data, "Name")));
    });
}

export function batchStatusUpdate({ id, ...payload }) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPatch(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batch/${id}/status-update`,
      {
        ...payload,
      },
      { componentName: LIMITED_EXEMPTION_COMPONENTS.STATUS_UPDATE }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function addLimitedExemptionBulk({ batchId, jsonData }, fileName) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batch/${batchId}/exemption/bulk-add`,
      [...jsonData],
      { "file-name": fileName, componentName: LIMITED_EXEMPTION_COMPONENTS.BULK_UPLOAD }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getProposedBatchList(jurisdictionId) {
  return (dispatch) =>
    fetcherGet(
      `${APIS.LIMITED_EXEMPTIONS}/jurisdiction/${jurisdictionId}/batches`,
      {
        status: LIMITED_EXEMPTION_STATUSES.PROPOSED,
      },
      { pageName: LIMITED_EXEMPTION_PAGE, componentName: LIMITED_EXEMPTION_COMPONENTS.BATCH_LIST }
    ).then((response) => {
      const pendingApprovals = response?.data
        ?.filter((batchExemption) => batchExemption?.Status?.name === LIMITED_EXEMPTION_STATUSES.PROPOSED)
        ?.map((batchExemption) => ({
          id: batchExemption?.id,
          displayId: batchExemption?.batchId,
          keyInformation: batchExemption?.batchName,
          lastUpdatedAt: formatDateUpdated(batchExemption?.updatedAt),
          type: APPROVAL_TYPES.BATCH_EXEMPTION,
          status: batchExemption?.Status?.name,
        }));
      dispatch(setProposedBatchExemptions(pendingApprovals));
    });
}
