import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { CustomizeButton } from "components/CustomizeButton";
import { useTranslation } from "react-i18next";
import { getCurrentPageName, t } from "utils/constants";
import { Grid } from "@mui/material";
import { FormProvider } from "components/FormProvider";
import { Loader } from "components/Loader";
import { useSelector } from "react-redux";
import Style from "../../../../components/CustomeDialogMessage/CustomeDialogMessage.style";

export default function CustomeDialogMessage({
  open = null,
  handleModalCancel,
  title,
  warning = false,
  continueBtn = "",
  borderColor = "",
  children,
  handleClose,
  handleContinue,
  disableContinue = false,
  buttonDisplay = false,
  closeBtnDisplay = false,
  customStyling = false,
  maxWidth = "sm",
  handleCustomButtonClick,
  customButtonLabel,
  showCustomBtn,
  methods,
  onInvalid,
  cancelBtn,
  customDialogStyle = {}
}) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { loader } = useSelector(({ common }) => common);
  const styleSx = warning ? { ...Style?.ModelWarning } : { ...Style?.ModelError };
  return (
    <Dialog
      sx={{
        ...Style?.ModelError,
        ...styleSx,
        ".messageModel": {
          margin: "20px",
          borderRadius: 8,
          "& .MuiTypography-subtitle1": {
            fontSize: "24px",
            lineHeight: "24px",
            marginBottom: "15px",
          },
          "& .bottom-btn": {
            marginTop: "15px",
            display: "flex",
            justifyContent: "flex-end",
            "@media (max-width: 1100px)": {
              marginTop: "30px",
            },
            "& .MuiButton-root": {
              height: "40px",
              marginLeft: "8px",
              boxShadow: "none",
              border: "1px solid #d7d7d7",
              lineHeight: "normal",
              outline: 0,
            },

            "& .submitBtn": {
              fontWeight: 400,
            },
          },
        },
        ...customDialogStyle
      }}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleModalCancel();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={customStyling}
      scroll={'body'}
    >
      <Box
        sx={{
          border: "2px solid",
          borderColor: borderColor || "#4d8da4",
          borderRadius: "8px",
        }}
      >
        <FormProvider
          methods={methods}
          onSubmit={methods?.handleSubmit(handleContinue, onInvalid)}
        >
          <Box className="messageModel">
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 300 }}
            >
              {title}
            </Typography>
            {closeBtnDisplay && buttonDisplay ? (
              <IconButton
                aria-label="close"
                onClick={handleModalCancel}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}

            {children}
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
            >
              <Grid
                item
                mt={1}
              >
                {showCustomBtn && (<CustomizeButton
                  variant="outlined"
                  csName="rc customBtn-margin"
                  handleClick={handleCustomButtonClick}
                  label={customButtonLabel}
                  customStyle={{ marginLeft: "0px", maxWidth: "150px", maxHeight: "40px", lineHeight: "normal" }}

                />)}
              </Grid>
              <Box
                sx={{ textAlign: "right", mb: 0 }}
                className="bottom-btn"
              >
                <CustomizeButton
                  variant="outlined"
                  csName="rc"
                  label={cancelBtn}
                  name="priv_btn_Reset"
                  handleClick={handleClose}
                />
                {<CustomizeButton
                  csName="submitBtn"
                  customStyle={continueBtn ? { width: "auto", fontSize: "14px", mr: 0 } : ""}
                  variant="contained"
                  disableElevation
                  type="submit"
                  label={continueBtn ? t(t1, continueBtn) : `${t(t1, "CONTINUE")}`}
                  name="priv_btn_Search"
                  disabled={disableContinue}
                />}
              </Box>
            </Grid>
          </Box>
        </FormProvider>
      </Box>
      <Loader loader={loader} />
    </Dialog>
  );
}
