import React from "react";
import Button from "@mui/material/Button";
import { theme } from "config";

export default function ButtonFillEdit({ children, styleSx = {}, handleClick, disabled }) {
  
  return (
    <Button
      variant="contained"
      disableElevation
      disabled={disabled}
      onClick={handleClick}
      sx={{
        py: 1,
        minWidth: 155,
        border: "1px solid",
        borderColor: "primary.main",
        "&:hover": {
          bgcolor: "primary.light",
          borderColor: "primary.light",
        },
        "&.Mui-disabled": {
          border: "1px solid #aaaaaa",
          color: theme.palette.common.white,
          backgroundColor: "#aaaaaa",
          textFillColor: "#fff"
        },
        ...styleSx,
      }}
    >
      {children}
    </Button>
  );
}
