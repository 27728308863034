/* eslint-disable no-nested-ternary */
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import { useClickOutside } from "hooks";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, FormControlLabel, FormGroup, List, ListItem, Button } from "@mui/material";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setSelectedRole, setSelectedRoleTypeFilter, setSelectedRolesFilter, getRolesDetails } from "store/privilegeSlice";
import { useState, useRef } from "react";
import { t, getCurrentPageName } from "utils/constants";
import { selectedJurisdiction } from "store/jurisdictionSlice";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Style from "./CustomizeLinks.style";

export default function CustomizeLinks(props) {
  const {
    formSteps: { isEditField, activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { user, userRole } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const subMenuRef = useRef(null);

  const {
    name,
    label = "",
    subLabel = "",
    roleType = "",
    isNested = "false",
    rules,
    lists,
    classes,
    handleClick,
  } = props;
  const [infoMenu, setInfoMenu] = useState(false);
  const [selectedId, setSselectedId] = useState(null);
  const { roles } = useSelector(({ privilege }) => privilege);
  let readData;
  // if (fields?.[name]?.hidden) {
  //   return null;
  // }
  const closeSubMenu = () => {
    setInfoMenu(false);
  };

  const handleRoleClick = (data) => {
    const list = { ...data };
    list.roleType = props.roleType;
    dispatch(setSelectedRole(list));
    dispatch(setSelectedRoleTypeFilter([]));
    dispatch(setSelectedRolesFilter([]));
    navigate(data.to ? data.to : "/role-details", { state: { data: list } })
  };

/* Conflict Resolution Candidate
  const handleRoles = (data) => {
    const list = { ...data };

    list.roleType = roles.find((v) => v.name === props?.roleType);
    list.name = '';
    list.id = '';
    list.description = '';
    // console.log(list,'list');
    // list.role =
    dispatch(setSelectedRole(list));
    navigate(data.to ? data.to : "/create-role-details", { state: { data: list } })
    dispatch(getRolesDetails(typeof selectedJurisdiction === "object" ? "" : selectedJurisdiction, data.id));

  }
*/
  const handleInfoList = (id) => {
    setSselectedId(id);
    const isOpen = lists.filter((item) => {
      if (item.id === id) {
        return true;
      }
      return null;
    });
    if (isOpen) {
      setInfoMenu(!infoMenu);
    }
  };

  const readOnly = PrivAttributes?.[name]?.readOnly; // This will set read only field
  // useClickOutside(subMenuRef, closeSubMenu);

  return (
    <>
      {label ? (
        <InputLabel htmlFor="customize-textfield" sx={Style.FieldLabel}>
          {label}
          {rules?.required ? (
            <Typography
              variant="body2"
              sx={{
                display: "inline-block",
                color: "common.red",
                ml: 0.3,
                fontWeight: 700,
              }}
            >
              *
            </Typography>
          ) : null}
          {subLabel ? <Box component="span">{subLabel}</Box> : null}
        </InputLabel>
      ) : null}
      <List className={classes}>
        {lists &&
          lists?.length > 0 &&
          lists?.map((option, index) => {
            if (props.listLinks) {
              readData = { isModal: true };
              option = { ...option, ...readData };
            }
            if (option.Access === "NA") {
              return null;
            }
            return (
              // eslint-disable-next-line react/jsx-no-comment-textnodes
              <ListItem key={`listItem-${index}`} onMouseLeave={closeSubMenu}>
                {option.Access === "RO" ? (
                  <Link
                    to={option.to ? option.to : "/"}
                    className={Style.disabledLink}
                    onClick={(e) => handleClick(e, "open", option.name)}
                  >
                    {option.name}
                  </Link>
                ) : option.isModal ? (
                  <Link
                    to={option.to ? option.to : "/"}
                    className={Style.disabledLink}
                    onClick={(e) => handleClick(e, "open", option.name, option.id)}
                  >
                    <span>{option.name}</span>
                  </Link>
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <Typography sx={{ cursor: 'pointer' }} onClick={() => handleRoleClick(option)}>
                      {option.name}
                    </Typography>
                    {/* <Link to={option?.to ? option?.to: '/role-details'} onClick={() => handleRoleClick(option)}>
                      {option.name}
                    </Link>  */}
                    {isNested === "true" &&
                      roles.length > 0 &&
                      PrivAttributes?.priv_lnkbtn_RoleActions?.PrivRole !== "NA" && (
                        <Box
                          sx={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                          <Button
                            sx={{ "&:hover": { background: "transparent" } }}
                            disabled={PrivAttributes?.priv_lnkbtn_RoleActions?.PrivRole === "RO"}
                            onClick={() => handleInfoList(option.id)}
                          >
                            <MoreHorizIcon />
                          </Button>
                          {infoMenu && option.id === selectedId && (
                            <Box ref={subMenuRef} style={Style.InfoBox}>
                              <List style={Style.infoListBox}>
                                <ListItem sx={Style.infoListBoxItem}>
                                  <Typography className="divLinkStyle" onClick={() => handleRoleClick(option)}>
                                    {t(t1, "VIEW_ROLE_DETAILS")}
                                  </Typography>
                                </ListItem>
                                {/* Conflict Resolution Candidate
                                <ListItem sx={Style.infoListBoxItem}>
                                  <Typography className="divLinkStyle" onClick={() => handleRoles(option)}>COPY SYSTEM DEFAULT ROLE</Typography>
                                </ListItem>
                                */}
                                {/* <ListItem sx={Style.infoListBoxItem}>
                                  <NavLink to={"/user-role"} onClick={() => handleRoleClick(option)}>{t(t1, "VIEW_USERS_WITHIN_THIS_ROLE")}</NavLink>
                                </ListItem> */}
                              </List>
                            </Box>
                          )}
                        </Box>
                      )}
                  </Box>
                )}
              </ListItem>
            );
          })}
      </List>
    </>
  );
}
