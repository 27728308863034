import { useTheme } from '@emotion/react';
import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TermsConditionsModal } from 'components/TermsConditionsModal';
import useAuth from 'hooks/useAuth';
import {
  getCurrentPageName,
  JurisdictionUser,
  IndividualUser,
  SYSTEM_USER,
  t,
  MarketMonitor,
  FSAHome,
  AuctionAdministrator,
} from 'utils/constants';

import Banner from '../Banner';
import Style from './styles';

function LandingPageKnown() {
  const navigate = useNavigate();

  const { isSignedIn, user: logedInUser } = useAuth();
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const [updateTCModal, setUpdateTCModal] = useState(false);
  const { t: t1 } = useTranslation(getCurrentPageName(), {
    useSuspense: true,
  });
  const theme = useTheme();

  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;
  useEffect(() => {
    if (Object.keys(userDetails)?.length > 0 && isSignedIn) {
      if (userDetails?.AcceptTAndC === false || userDetails?.AcceptTAndU === false) {
        setUpdateTCModal(true);
      } else if (userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) {
        navigate('/jurisdiction-user');
      } else if (userDetails?.RoleDetail?.Role[0]?.name === FSAHome) {
        navigate('/fsa-home');
      } else if (userDetails?.RoleDetail?.Role[0]?.name === MarketMonitor) {
        navigate('/market-monitor');
      } else if (userDetails?.RoleDetail?.Role[0]?.name === AuctionAdministrator) {
        navigate('/auction-administrator');
      } else if ([SYSTEM_USER, IndividualUser].includes(userDetails?.RoleDetail?.RoleType[0]?.name)) {
        navigate('/home');
      }

    } else {
      setUpdateTCModal(false);
    }
  }, [userDetails]);

  return (
    <>
      {PrivAttributes?.priv_banner_horizontal?.PrivRole !== 'NA' && <Banner />}
      <Divider />
      <Container maxWidth="xl">
        <Stack className={Style.WelcomeText} direction="row" alignItems="center" sx={{ mb: 5, ...Style.WelcomeText }}>
          <Grid container spacing={0}>
            <Grid item lg={9} md={8} sm={12}>
              <Box sx={{ flexGrow: 1 }}>
                {PrivAttributes?.priv_h2_Welcome?.PrivRole !== 'NA' && (
                  <Typography component="span" variant="h3" gutterBottom sx={Style.WelcomeTextTital}>
                    {t(t1, 'WELCOME')}
                  </Typography>
                )}
                {PrivAttributes?.priv_h2_welcomeContent?.PrivRole !== 'NA' && (
                  <Typography component="span" sx={{ color: 'text.secondary', ...Style.WelcomeTextPara }}>
                    {t(t1, 'HOME_MAIN_CONTENT')}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={12}>
              {PrivAttributes?.priv_sec_NOTICES?.PrivRole !== 'NA' && (
                <Box sx={Style.noticebox}>
                  <Typography variant="h4" gutterBottom sx={{ '& p': { margin: 0 } }}>
                    {t(t1, 'NOTICES')}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Container>
      <Container maxWidth="xl">
        <Divider color={theme.palette.text.primary} />
      </Container>
      <Container maxWidth="xl">
        <Stack sx={Style.JurisdictionText} direction="row" alignItems="center">
          {PrivAttributes?.priv_h2_Jurisdiction_Specific_Content?.PrivRole !== 'NA' && (
            <Box sx={{ flexGrow: 1 }}>
              <Typography sx={{ color: 'text.secondary', ...Style.WelcomeTextPara }}>
                {t(t1, 'JURISDICTION_SPECIFIC_CONTENT')}
              </Typography>
            </Box>
          )}
        </Stack>
      </Container>
      {updateTCModal && <TermsConditionsModal setUpdateTCModal={setUpdateTCModal} />}
    </>
  );
}

export default LandingPageKnown;
