import { createSlice } from '@reduxjs/toolkit';

const jurisdictionUserHomeEntityGridFilterSlice = createSlice({
  name: 'jurisdictionUserHomeEntityGridFilter',
  initialState: {
    entityGridFilter: null,
  },
  reducers: {
    setEntityGridFilter: (state, action) => {
      state.entityGridFilter = action.payload;
    },
    setClearEntityGridFilter: (state) => {
      state.entityGridFilter = null;
    },
  },
});

export const { setEntityGridFilter, setClearEntityGridFilter } = jurisdictionUserHomeEntityGridFilterSlice.actions;
export default jurisdictionUserHomeEntityGridFilterSlice.reducer;
