import theme from "../../config/theme";

const Style = {
  readOnly: {
    minWidth: "150px",
    minHeight: "40px",
    marginLeft: "10px",
    boxShadow: "none",
    border: "1px solid #aaaaaa",
    color: theme.palette.common.white,
    backgroundColor: "#aaaaaa",
    textFillColor: "#fff"
  },
  normalButton: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    minWidth: "150px",
    minHeight: "40px",
    marginLeft: "10px",
    marginRight: "0px",
    boxShadow: "none",
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "6px 6px",
    outline: 0,
    "&:hover": {
      bgcolor: "primary.light",
      borderColor: "primary.light"
    },
    "&.Mui-disabled": {
      border: "1px solid #aaaaaa",
      color: theme.palette.common.white,
      backgroundColor: "#aaaaaa",
      textFillColor: "#fff"
    },
    "&.rc": {
      color: theme.palette.primary.light,
      backgroundColor: "#fff",
      border: `1px solid ${theme.palette.common.borderButtonColor} !important`,
      "&:hover": {
        fontWeight: 500
      },
    },
  },
};
export default Style;
