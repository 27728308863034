import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { uniq } from "underscore";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { t, getCurrentPageName } from "utils/constants";
import CustomPopper from "components/_CustomPopper/customPopper";
import Style from "./GridSelectSearch.style";

export default function GridSelectSearchCustom(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control } = useFormContext();

  const {
    name,
    type = "text",
    label = "",
    rules,
    noValueText,
    options,
    gridMd = 4,
    disabled = false,
    className = "",
    isAstricDisplay = false,
    customeStyle = "",
    onChangeValue,
    ListboxProps,
    isRequiredMsg=''
  } = props;

  if (rules && rules?.required && label) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }

  return (
    <Grid item md={gridMd} className={className} sx={{ ...customeStyle }}>
      {label ? (
        <InputLabel disabled={disabled} htmlFor="customize-select" sx={Style.FieldLabel}>
          {label}
          {rules?.required || isAstricDisplay ? (
            <Typography
              variant="body2"
              sx={{
                display: "inline-block",
                color: "common.red",
                ml: 0.3,
                fontWeight: 700,
              }}
            >
              *
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
        <Controller
          name={name}
          type={type}
          rules={rules}
          control={control}
          render={({ field: { onChange, onBlur, value, ...field }, fieldState }) => (
            <>
              <Autocomplete
                PopperComponent={CustomPopper}
                clearText=""
                closeText=""
                openText=""
                disabled={disabled}
                id="page-select-demo"
                sx={Style.formField}
                ListboxProps={ListboxProps}
                options={uniq(options, option => option.name)}
                onBlur={onBlur}
                className="fc-search"
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(fieldState.error)}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: noValueText,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data?.value);
                  onChangeValue(data?.value);
                  return data?.name;
                }}
              />
              {rules && (
                <FormHelperText error>
                  {fieldState?.error ? fieldState?.error?.message : " "}
                </FormHelperText>
              )}
            </>
          )}
        />
    </Grid>
  );
}