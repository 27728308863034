import { createSlice } from "@reduxjs/toolkit";
import { APIS } from "utils/constants";
import fetcher from "utils/fetcher";

const initialState = { 
  selectedEventApplicationList: { isLoading: false, data: [] },  
}

const eventApplicationReportSlice = createSlice({
  name: "eventApplicationReport",
  initialState,
  reducers: {   
    setSelectedEventApplicationlList: (state, action) => {
      state.selectedEventApplicationList = action.payload;
    },   
  }
});

export default eventApplicationReportSlice.reducer;
export const { setSelectedEventApplicationlList} = eventApplicationReportSlice.actions;


export function getEventApplicationList(jurisdictionId,PageName, eventId='') {
  const pageDetails = { componentName: "viewEventApplication", pageName: PageName };
    return (dispatch) => {
      dispatch(setSelectedEventApplicationlList({ isLoading: true }));
      
      fetcher(`${APIS.EVENT_APPLICATION_API}/${jurisdictionId}/event-applications?eventId=${eventId}&isArReport=true`, "GET", {}, true,pageDetails)
        .then((response) => {
          dispatch(setSelectedEventApplicationlList({ isLoading: false, data: response.data }));          
        })
        .catch((err) => {
          dispatch(setSelectedEventApplicationlList({ isLoading: false }));
        });
    };
  }