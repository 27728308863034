import authRoles from "core/auth/authRole";
import PrivilegeManagement from "./PrivilegeManagement";

const PrivilegeManagementConfig = {
  route: {
    path: "/privilege",
    element: <PrivilegeManagement />,
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.onlyGuest,
};

export default PrivilegeManagementConfig;
