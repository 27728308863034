import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import {  t, getCurrentPageName } from "utils/constants";

export default function Faq() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  return (
    <>
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        {t(t1, "FAQ")}
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 700, mb: 1 }}>
        {t(t1, "FAQ_WHY_IS_THIS_IMPORTANT")}
      </Typography>
      <Typography variant="caption" display="block" sx={{ mb: 2 }}>
        {t(t1, "FAQ_WHY_IS_THIS_IMPORTANT_ANSWER")}
      </Typography>
    </>
  );
}
