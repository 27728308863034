import { getCurrentORGID, languageCode } from './constants';

export const postLoginPages = [
  'Page_AccountDetails',
  'Page_AllReports',
  'Page_AllowanceIssuance',
  'Page_AllowanceIssuanceDetails',
  'Page_ApplicationChecklist',
  'Page_ApplicationWithAttenstation',
  'Page_Approvals',
  'Page_AssociatedRetiredFacility',
  'Page_AuctionAdmin',
  'Page_AuctionAdministratorAllReport',
  'Page_AuctionAdministrtorAllReport',
  'Page_AuctionAndReserveSale',
  'Page_BidLimitationsReconciliationReport',
  'Page_CAG',
  'Page_CMSCompareReports',
  'Page_ChangeJusrisdiction',
  'Page_CommonLabels',
  'Page_ConfigurationSetting',
  'Page_ConsignmentWiringInstructionsReport',
  'Page_ConsignmentWiringInstructionsStatusReport',
  'Page_ContactInformation',
  'Page_ContactUs',
  'Page_Content_Management_System',
  'Page_CreateParticipatingJurisdiction',
  'Page_CrossJurisdiction',
  'Page_DocFiles',
  'Page_EADEReport',
  'Page_EURelationshipsReconciliationReport',
  'Page_EVENT_PARTICIPANT_REPORT',
  'Page_EarReports',
  'Page_EntityLegalNameOperatingNameChange',
  'Page_EntityProfile',
  'Page_EntityRegistration',
  'Page_EventApplication',
  'Page_EventApplicationAr',
  'Page_EventApplicationJuri',
  'Page_EventApplicationManagement',
  'Page_EventCreationAndManagement',
  'Page_EventParticipantReport',
  'Page_ExchangeContractDescCode',
  'Page_FSAAllReportHome',
  'Page_FSAAllReports',
  'Page_FSAHome',
  'Page_FSAReport',
  'Page_FSA_REPORT',
  'Page_FacilityGHGIdentifierChange',
  'Page_FacilityLegalOpNameChange',
  'Page_FinancialServicesDeliveryInstruction',
  'Page_FormsDocuments',
  'Page_IndividualUsersAllReports',
  'Page_IssuanceAccount',
  'Page_IssuanceRecords',
  'Page_JurisdictionAccountDetails',
  'Page_JurisdictionAccounts',
  'Page_JurisdictionEntityAction',
  'Page_JurisdictionUserActions',
  'Page_JurisdictionUserHome',
  'Page_LandingPageKnown',
  'Page_LegalDocuments',
  'Page_ManageAdministrativeTransfers',
  'Page_ManageBatchTransfer',
  'Page_ManageCronJobs',
  'Page_ManageEntityTransfers',
  'Page_ManageHoldingLimit',
  'Page_ManageJurisdictionAccounts',
  'Page_ManageJurisdictionBudget',
  'Page_ManageJurisdictionStatus',
  'Page_ManageLimitedExemption',
  'Page_ManagePurchaseLimit',
  'Page_ManageReplenishmentTransfer',
  'Page_ManageTransfers',
  'Page_MarketMonitor',
  'Page_MarketMonitorAllReport',
  'Page_MegaMenu',
  'Page_ModifiedRecordsForTransfers',
  'Page_NAICSCodeDisplayManagement',
  'Page_NAICSCodeManagement',
  'Page_OffsetIssuance',
  'Page_OffsetIssuanceDetails',
  'Page_OffsetManagement',
  'Page_PM_CS',
  'Page_PM_CopyRole',
  'Page_PM_Home',
  'Page_PM_RoleDetail_PageControl',
  'Page_PM_RoleDetail_Users',
  'Page_PM_SysDefRoles',
  'Page_QualifiedBidderReconciliationReport',
  'Page_RegistryEntityFacilityReport',
  'Page_Reports',
  'Page_ResetPass&SecurityQuestion',
  'Page_SecurityQuestions',
  'Page_ServiceProviderSelectionManagement',
  'Page_SetUpJurisdictionAccounts',
  'Page_SetupInitialUsers',
  'Page_TransferDetailsPage',
  'Page_UserDetail',
  'Page_UserHome',
  'Page_UserProfile',
  'Page_UserRegistration',
  'Page_WiringInstructions',
  'Page_WiringInstructionsStatusReport',
];

export const withoutLoginPages = [
  'Page_UserRegistration',
  'Page_LandingPageKnown',
  'Page_ContactUs',
  'Page_ResetPass&SecurityQuestion',
];

export const reloadRequired = () =>
  new Promise((resolve, reject) => {
    let keyMissing = false;
    const missingPages = [];

    getPagesKeyMap().then(({ keyMap }) => {
      const orgId = getCurrentORGID();
      const languageId = languageCode();
      for (let i = 0; i < postLoginPages.length; i += 1) {
        const pageName = postLoginPages[i];
        const namespace = `${orgId}#${languageId}-${pageName}`;

        if (!keyMap[namespace]) {
          keyMissing = true;
          missingPages.push(pageName);
        }
      }
      resolve({ keyMissing, missingPages });
      if (Object.keys(keyMap).length === 0) {
        reject();
      }
    });
  });

export const getPagesKeyMap = () =>
  new Promise((resolve, reject) => {
    const localforage = window.i18forage;
    localforage.keys().then((keys) => {
      const keyMap = {};
      keys.forEach((key) => (keyMap[key] = key));

      resolve({ keyMap, keys });
      if (Object.keys(keyMap).length === 0) {
        reject(keyMap);
      }
    });
  });

export const clearPages = (pageNames) =>
  new Promise((resolve, reject) => {
    getPagesKeyMap().then(({ keyMap, keys }) => {
      pageNames.forEach((pageName) => {
        const localforage = window.i18forage;
        const namespaces = keys.filter((key) => key.includes(pageName));
        namespaces.forEach((namespace) => {
          localforage
            .removeItem(keyMap[namespace])
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      });
    });
  });
