import { Loadable } from "components/Loadable";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { lazy } from "react";

// PROPOSE
const ProposeEntityTransfer = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.ProposeEntityTransfer,
    }))
  )
);
// DETAILS
const EntityTransferDetails = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EntityTransfersDetails,
    }))
  )
);

export const manageEntityTransfersRoutes = {
  path: "/entity-transfers",
  children: [
    // PROPOSE
    {
      path: "propose/entity/:entityId/:entityNo/account/:accId/holdings/:holdingId/transfer-type/:transferType",
      element: (
        <RoleBasedGuard privId={""}>
          <ProposeEntityTransfer />
        </RoleBasedGuard>
      ),
    },

    // DETAILS
    {
      path: "details/:entityId/:transferId/:accId/:transferType",
      element: (
        <RoleBasedGuard privId={""}>
          <EntityTransferDetails />
        </RoleBasedGuard>
      ),
    },
    {
      path: "details/:entityId/:transferId/:accId/:transferType/:recevingEntityId",
      element: (
        <RoleBasedGuard privId={""}>
          <EntityTransferDetails />
        </RoleBasedGuard>
      ),
    },
  ],
};
