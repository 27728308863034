import { Loadable } from "components/Loadable";

import RoleBasedGuard from "guards/RoleBasedGuard";
import { lazy } from "react";


const EAReport = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EAReport
    }))
  )
);

export const eventApplicationReportRoute = {
  path: "/event-applications-ar",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_event_applicationsAr"}>
          <EAReport />
        </RoleBasedGuard>
      ),
    },
  
  ],
};