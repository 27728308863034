import { Typography } from "@mui/material";
import { BATCH_TRANSFER_STATUS, formatNumber, t, customSorting } from "utils/constants";
import { isEditable } from "pages/ManageBatchTransfer/constants";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-premium";
import QuickActionsMenu from "./quickActionsMenu";

export const SelectedHoldingsSchema = (t1, status, isAdmin, addNewRowsToggle, valueOptions) => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    hideable: false,
  },
  {
    id: 1,
    field: "vintage",
    headerName: t(t1, "VINTAGE"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 0.5,
    align: "center",
    renderCell: (cellValues) => <Typography variant="body2">{cellValues?.row?.vintage}</Typography>,
  },
  {
    id: 3,
    field: "type",
    headerName: t(t1, "TYPE"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 0.5,
    align: "center",
    type: 'singleSelect',
    valueOptions: valueOptions?.type?.sort(),
  },
  {
    id: 4,
    field: "subType",
    headerName: t(t1, "SUB_TYPE_HEADER"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 0.5,
    align: "center",
    type: 'singleSelect',
    valueOptions: valueOptions?.subType?.sort(),
  },
  {
    id: 5,
    field: "offsetType",
    headerName: t(t1, "OFFSET_TYPE"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 0.5,
    align: "center",
  },
  {
    id: 6,
    field: "offsetProjectId",
    headerName: t(t1, "OFFSET_PROJECT_ID"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: "center",
  },
  {
    id: 7,
    field: "specialCategory",
    headerName: t(t1, "SPECIAL_CATEGORY"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: "center",
  },
  {
    id: 8,
    field: "quantity",
    headerName: t(t1, "QUANTITY"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: "center",
    sortComparator: customSorting,
    valueFormatter: ({ value }) => formatNumber(value),
    stringAsNumber: true
  },
  ...(status !== BATCH_TRANSFER_STATUS.ACTIVE
    ? [
      {
        id: 9,
        field: "availableHoldingQuantity",
        headerName: t(t1, "AVAILABLE_QUANTITY"),
        minWidth: 100,
        maxWidth: 1000,
        flex: 1.3,
        align: "center",
        sortComparator: customSorting,
        valueFormatter: ({ value }) => formatNumber(value),
        stringAsNumber: true
      },
    ]
    : []),
  ...(isAdmin && !addNewRowsToggle && (status === BATCH_TRANSFER_STATUS.ACTIVE || isEditable(status))
    ? [
      {
        id: 10,
        field: "QuickActions",
        headerName: t(t1, "QUICK_ACTIONS"),
        minWidth: 120,
        maxWidth: 120,
        align: "center",
        disableExport: true,
        hideable: false,
        filterable: false,
        renderCell: (params) => (
          <QuickActionsMenu
            params={params}
            status={status}
          />
        ),
      },
    ]
    : []),
];

export const holdingsColumnsBatch = (t1, valueOptions = {}) => [
  {
    id: 1,
    field: "vintage",
    headerName: t(t1, "VINTAGE"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: "center",
    renderCell: (cellValues) => <Typography variant="body2">{cellValues?.row?.vintage}</Typography>,
  },
  {
    id: 2,
    field: "jurisdiction",
    headerName: t(t1, "JURISDICTION"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: "center",
  },
  {
    id: 3,
    field: "type",
    headerName: t(t1, "TYPE"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: "center",
    type: 'singleSelect',
    valueOptions: valueOptions?.type?.sort(),
  },
  {
    id: 4,
    field: "subType",
    headerName: t(t1, "SUB_TYPE_HEADER"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: "center",
    type: 'singleSelect',
    valueOptions: valueOptions?.subType?.sort(),
  },
  {
    id: 5,
    field: "offsetType",
    headerName: t(t1, "OFFSET_TYPE"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: "center",
  },
  {
    id: 6,
    field: "offsetProjectID",
    headerName: t(t1, "OFFSET_PROJECT_ID"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: "center",
  },
  {
    id: 7,
    field: "specialCategory",
    headerName: t(t1, "SPECIAL_CATEGORY"),
    minWidth: 100,
    maxWidth: 1000,
    align: "center",
    flex: 1,
  },
  {
    id: 8,
    field: "quantity",
    headerName: t(t1, "HOLDING_QUANTITY"),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: "center",
    sortComparator: customSorting,
    valueFormatter: (params) => formatNumber(params.value),
    stringAsNumber: true
  },
];