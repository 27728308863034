export const MANAGE_JURI_STATUS_COMPONENTS = {
  REQUEST_STATUS_CHANGE_HISTORY: "requestStatusChange",
};

export const MANAGE_JURI_STATUS_PAGES = {
  CREATE_PARTICIPATING_JURISDICTION: "Page_CreateParticipatingJurisdiction",
  PAGE_MANAGE_JURISDICTION_STATUS: "Page_ManageJurisdictionStatus",
};


export const resetTransferConfigPayload = (fromJurisdictionId, toJurisdictionId, ijTransfersDefaultValues, cjTransfersDefaultValues) => ({
    fromJurisdictionId,
    toJurisdictionId,
    cjStopAllFutureTransfers: cjTransfersDefaultValues.cj_stop_all_future_transfers,
    cjStopAllTransfersInProgress: cjTransfersDefaultValues.cj_stop_all_transfers_in_progress,
    cjSavedStatusAdministrativeTransfer: cjTransfersDefaultValues.cj_saved_status_administrative_transfer,
    cjProposedStatusAdministrativeTransfer: cjTransfersDefaultValues.cj_proposed_status_administrative_transfer,
    cjSavedStatusBatchTransfer: cjTransfersDefaultValues.cj_saved_status_batch_transfer,
    cjProposedStatusBatchTransfer: cjTransfersDefaultValues.cj_proposed_status_batch_transfer,
    cjProposedStatusEntityTransfer: cjTransfersDefaultValues.cj_proposed_status_entity_transfer,
    cjApprovedStatusEntityTransfer: cjTransfersDefaultValues.cj_approved_status_entity_transfer,

    ijStopAllFutureTransfers: ijTransfersDefaultValues.ij_stop_all_future_transfers,
    ijStopAllTransfersInProgress: ijTransfersDefaultValues.ij_stop_all_transfers_in_progress,
    ijProposedStatusWithinEntity: ijTransfersDefaultValues.ij_proposed_status_within_entity,
    ijApprovedStatusWithinEntity: ijTransfersDefaultValues.ij_approved_status_within_entity,
    ijProposedStatusJurisdictionEntity: ijTransfersDefaultValues.ij_proposed_status_jurisdiction_entity,
    ijApprovedStatusJurisdictionEntity: ijTransfersDefaultValues.ij_approved_status_jurisdiction_entity,

    effectiveDateIj: ijTransfersDefaultValues.ij_effective_date,
    effectiveDateCj: cjTransfersDefaultValues.cj_effective_date,
    isConflictForAllLinkedJurisdictions: false,
  })


  export const getFilteredIds = (ids, id) => ids?.filter(i => i !== id);

  export function processArrayWithId(data) {
    return data
      ?.filter((item) => Object.keys(item).length !== 0)
      ?.map((item, index) => ({
        id: item.id || index,
        ...item,
      }))
      ?.sort((a, b) => new Date(b?.updated_at) - new Date(a?.updated_at));
  }