import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { InputLabel, Box, TextField, Typography, Tooltip } from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { t, getCurrentPageName } from "utils/constants";
import { styled } from "@mui/material/styles";
import Style from "./CustomizeTextfield.style";

export default function CustomizeTextfield(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;
  const {
    formSteps: { isEditField, activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isInputFocused, setInputFocus] = useState(false);
  const inputElRef = useRef(null);

  const {
    name,
    type = "text",
    label = "",
    subLabel = "",
    defaultValue = "",
    rules,
    placeholder,
    InputProps,
    stepNumber,
    validation = "",
    inputClass = "",
    onCut = null,
    onCopy = null,
    onPaste = null,
    matchWith = "passphrase",
    /* Conflict Resolution Candidate
        privId="", 
        onKeyUp = "",
        inputRef
     */
    privId = "",
    isRequiredMsg=''
  } = props;

  let readOnly = false;
  if (privId === "") {
    if (PrivAttributes?.[name]?.PrivRole === "NA") {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === "RO";
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === "NA") {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === "RO";
  }

  if (rules && label && rules.required === true) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }
  /* Conflict Resolution Candidate
    const insetValue = `${
      t(t1, "CONFIRM_PASSPHRASE") === label ? "-16px auto auto 0px !important" : "-25px auto auto 0px !important"
    }`;
  */

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
      PopperProps={{ container: inputElRef.current?.children[0].children[0], placement: "right-start" }}
      open={isInputFocused}
      {...props}
      classes={{ popper: className }}
      placement="right-start"
      arrow
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #797979",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 11,
      // inset:insetValue,
      position: "relative",
      borderRadius: 0,
      paddingRight: "5px",
      width: "100%",
      maxWidth: "100%",
      "& .MuiBox-root": {
        padding: "0",
        margin: "8px 0",
      },
      "& .MuiTooltip-arrow": {
        all: "unset",
        border: "solid #797979",
        left: "-9px",
        top: `${t(t1, "CONFIRM_PASSPHRASE") === label ? "14px !important" : "23px !important"}`,
        borderWidth: "0 1px 1px 0",
        display: "inline-block",
        padding: "8px",
        transform: "rotate(135deg) !important",
        backgroundColor: "#fff",
      },
    },
  }));

  // Popoer config
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleFocusEvent = (e, type) => {
    if (type === "focus") {
      setInputFocus(true);
    }

    if (type === "blur") {
      setInputFocus(false);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // Poper config end
  return (
    <>
      {label ? (
        <InputLabel htmlFor="customize-textfield" sx={Style.FieldLabel}>
          {label}
          {rules?.required ? (
            <Typography
              variant="body2"
              sx={{
                display: "inline-block",
                color: "common.red",
                ml: 0.3,
                fontWeight: 700,
              }}
            >
              *
            </Typography>
          ) : null}
          {subLabel ? <Box component="span">{subLabel}</Box> : null}
        </InputLabel>
      ) : null}
      {(!isEditField[stepNumber] && activeStep === 4) || readOnly ? (
        <Typography variant="body2" sx={Style.valuetext}>
          {getValues(name) || "N/A"}
        </Typography>
      ) : (
        <Controller
          name={name}
          type={type}
          defaultValue={defaultValue}
          rules={rules}
          control={control}
          render={({ field, fieldState }) => (
            <div ref={inputElRef} style={{ position: "relative" }}>
              <TextField
                // className={inputClass}
                sx={Style.formField}
                type={type}
                id={name}
                autoComplete='new-password'
                className={`in-tp-cls ${inputClass} ${t(t1, "CONFIRM_PASSPHRASE") === label ? "password-label" : ""}`}
                variant="outlined"
                placeholder={placeholder}
                onCut={onCut}
                onCopy={onCopy}
                onPaste={onPaste}
                /* Conflict Resolution Candidate
                                inputRef={inputRef}
                 */
                // InputProps={{ "data-testid": name, ...InputProps }}
                InputProps={{ inputProps: { "data-testid": name }, ...InputProps }}
                error={Boolean(fieldState.error)}
                helperText={fieldState?.error?.message || " "}
                onFocus={(e) => handleFocusEvent(e, "focus")}
                onBlurCapture={(e) => handleFocusEvent(e, "blur")}
                /* Conflict Resolution Candidate
                                onKeyUp={({ target: value }) => onKeyUp && onKeyUp(value)}
                 */
                {...field}
              />

              {(t(t1, "PASSPHRASE") === label || t(t1, "CONFIRM_PASSPHRASE") === label) && (
                <LightTooltip
                  title={
                    t(t1, "PASSPHRASE") === label ? (
                      <Box as="ul" sx={{ margin: "10px 0", padding: "0 20px 0 10px" }}>
                        <Typography as="li" sx={Style.popperStyle}>
                          {validation?.length && getValues(name).length > 0 ? (
                            <CheckOutlinedIcon sx={{ color: "green" }} />
                          ) : (
                            <CloseOutlinedIcon sx={{ color: "red" }} />
                          )}
                          <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE1")}</span>
                        </Typography>
                        <Typography as="li" sx={Style.popperStyle}>
                          {validation?.alphaNum && getValues(name).length > 0 ? (
                            <CheckOutlinedIcon sx={{ color: "green" }} />
                          ) : (
                            <CloseOutlinedIcon sx={{ color: "red" }} />
                          )}
                          <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE2")}</span>
                        </Typography>
                        <Typography as="li" sx={Style.popperStyle}>
                          {validation?.upperLowercase && getValues(name).length > 0 ? (
                            <CheckOutlinedIcon sx={{ color: "green" }} />
                          ) : (
                            <CloseOutlinedIcon sx={{ color: "red" }} />
                          )}
                          <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE3")}</span>
                        </Typography>
                        <Typography as="li" sx={Style.popperStyle}>
                          {validation?.number && getValues(name).length > 0 ? (
                            <CheckOutlinedIcon sx={{ color: "green" }} />
                          ) : (
                            <CloseOutlinedIcon sx={{ color: "red" }} />
                          )}
                          <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE4")}</span>
                        </Typography>
                        <Typography as="li" sx={Style.popperStyle}>
                          {validation?.speciaChar && getValues(name).length > 0 ? (
                            <CheckOutlinedIcon sx={{ color: "green" }} />
                          ) : (
                            <CloseOutlinedIcon sx={{ color: "red" }} />
                          )}
                          <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE5")}</span>
                        </Typography>
                        <Typography as="li" sx={Style.popperStyle}>
                          {validation?.nonPrinting && getValues(name).length > 0 ? (
                            <CheckOutlinedIcon sx={{ color: "green" }} />
                          ) : (
                            <CloseOutlinedIcon sx={{ color: "red" }} />
                          )}
                          <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE6")}</span>
                        </Typography>
                      </Box>
                    ) : (
                      <Box as="ul" sx={{ margin: "10px 0", padding: "0 20px 0 10px" }}>
                        <Typography as="li" sx={Style.popperStyle}>
                          {getValues().confirmPassphrase === getValues(matchWith) ? (
                            <CheckOutlinedIcon sx={{ color: "green" }} />
                          ) : (
                            <CloseOutlinedIcon sx={{ color: "red" }} />
                          )}
                          <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_MATCH_MESSAGE")}</span>
                        </Typography>
                      </Box>
                    )
                  }
                  sx={{ inset: "-24px auto auto 0px !important" }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "5px" }}>
                    <InfoIcon sx={{ fontSize: "0" }} />
                  </Box>
                </LightTooltip>
              )}
            </div>
          )}
        />
      )}
    </>
  );
}
