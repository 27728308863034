/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { object } from "yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Table,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
  Box,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import { setNewRole } from "store/privilegeSlice";
import { NotFound } from "components/NotFound";
import { Loader } from "components/Loader";
import { useTranslation } from "react-i18next";
import {  t, getCurrentPageName } from "utils/constants";
import { PageAccessStyle } from "./PageAccess.style";

export default function PageAccess(props) {
  const { user } = useSelector(({ auth }) => auth);
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const [initialSet, setinitialSet] = useState(false);
  const [roleDetailsAdd, setRoleDetailsAdd] = useState(null);
  const [keyWiseExpand, setKeyWiseExpand] = useState(false);
  const { fields } = user; // Here we get fields permission like hidden, read Only or write

  const {
    isExport = false,
    name,
    label = "",
    labelText = "",
    rules,
    gridsm = 12,
    accordionClass = "",
    accordionCss = "",
    panelClass = "",
    roleDetails = [],
    type,
    userRole,
    loader,
    setUpdatedPages='',
    updatedPage='',
    isRequiredMsg=''
  } = props;

  useEffect(() => {
    setRoleDetailsAdd(null);
    if (props.roleDetails) {
      setRoleDetailsAdd(props.roleDetails);
    }
  }, [props.roleDetails]);

  if (userRole?.[name]?.PrivRole === "NA") {
    return null;
  }
  let readOnly = false;
  if (userRole?.[name]?.PrivRole === "RO") {
    readOnly = true;
  }

  // const readOnly = userRole?.[name]?.PrivRole === 'RO' ? true:false;// This will set read only field

  if (rules && label && rules.required === true) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }

  // To handle accordion as per radio checkbox
  const handleExpand = (panel) => (event, isExpanded) => {
    setKeyWiseExpand(isExpanded ? panel : false);
  };
  // To handle radio controls events
  const handleOnChangeRadio = (ev, object, id) => {
    const Obj = JSON.parse(JSON.stringify(roleDetailsAdd));
    if(setUpdatedPages && updatedPage.indexOf(Obj.Items[id].PageName) === -1){
      setUpdatedPages([...updatedPage,Obj.Items[id].PageName])
    }
    Obj.Items[id].PrivAttributes[object[0]].PrivRole = ev.target.value;
    dispatch(setNewRole(JSON.parse(JSON.stringify(Obj.Items))));
    setRoleDetailsAdd(Obj);
    setKeyWiseExpand(id);
  };
  // Custom accordian component
  const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&.modal-accordion-style": {
      backgroundColor: "#e6eff2",
      border: 0,
      borderBottom: "1px solid #4d8da4",
    },
    "&.modal-accordion-style:last-child": {
      borderBottom: "0",
    },
    "&.modal-accordion-style .MuiButtonBase-root": {
      flexDirection: "row-reverse",
    },
    "&.modal-accordion-style .MuiTableHead-root .MuiTableCell-root": {
      borderBottom: "1px solid #d7d7d7",
      background: "#f2f2f2",
      lineHeight: "1.4",
      padding: "8px 15px",
    },
    "&.modal-accordion-style .MuiTableBody-root .MuiTableCell-root": {
      borderBottom: "1px solid #d7d7d7",
      background: "#fff",
      lineHeight: "1.4",
      padding: "0 15px",
    },
    "&.modal-accordion-style .MuiAccordionSummary-content .MuiTypography-root": {
      color: "#4D8DA4",
      fontSize: "14px",
    },
    "&.modal-accordion-style .MuiTableCell-root:first-of-type": {
      width: "calc(100% - 140px)",
    },
    "&.modal-accordion-style .MuiTableCell-root": {
      border: "1px solid #d7d7d7",
      width: "140px",
    },
  }));


  function handleClickCheckbox(e, id) {

    const Obj = JSON.parse(JSON.stringify(roleDetailsAdd));
    
    Obj.Items[id].IsReviewed = e.target.checked;
    dispatch(setNewRole(JSON.parse(JSON.stringify(Obj.Items))));
    setRoleDetailsAdd(Obj);
    // setKeyWiseExpand(id);
    e.stopPropagation();
  }

  return (
    <>
      <Grid item sm={gridsm} sx={PageAccessStyle.panel}>
        {label ? (
          <div className="custom-tital" style={{ marginBottom: "10px" }}>
            <Typography variant="h5" sx={{ fontWeight: 200, marginBottom: "5px" }}>
              {label || `${t(t1, "DEFAULT_PAGES_AND_CONTROLS")}`}
            </Typography>
            {labelText && (
              <Typography variant="h5" sx={{ fontWeight: 200, marginBottom: "15px" }}>
                {labelText || `${t(t1, "DEFAULT_SUB_LABEL")}`}
              </Typography>
            )}
          </div>
        ) : null}
        {!loader && roleDetailsAdd?.Items?.length > 0 && props.roleDetails?.Items?.length > 0 && (
          <div className={`paneldetail ${panelClass}`}>
            {roleDetailsAdd?.Items?.map((page, key) => (
              <Accordion
                key={key}
                className={accordionClass}
                expanded={keyWiseExpand === key}
                onChange={handleExpand(key)}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  className="custompanel"
                  sx={{height:'44px !important', ...accordionCss}}
                >
                  <Grid item xs={11}>
                    <Typography style={{ textTransform: "uppercase" }}>
                      {type !== "add" ? page.PageName?? page.SK: page.SK}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Box sx={PageAccessStyle.Checkbox}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            filled
                            size="12px"
                            onClick={(e) => handleClickCheckbox(e, key)}
                            checked={page.IsReviewed}
                          />
                        }
                        label="Reviewed"
                        name="is_reviewed"
                      />
                    </Box>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <RadioGroup aria-labelledby="rp-radio" name="rp-radio" value="noAccess1"></RadioGroup> */}

                  <TableContainer className="radio-table">
                    <Table
                      // sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className="control-head">{t(t1, "CONTROLS")}</TableCell>
                          <TableCell align="center">{t(t1, "NO_ACCESS")}</TableCell>
                          <TableCell align="center">{t(t1, "READ_ONLY")}</TableCell>
                          <TableCell align="center">{t(t1, "READ_WRITE_EXECUTE")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(page.PrivAttributes).map((control) => (
                          <TableRow key={control[0]}>
                            <TableCell>{control[0]}</TableCell>
                            <TableCell colSpan={3} align="center">
                              <RadioGroup
                                row
                                defaultValue={control[1]?.PrivRole}
                                onChange={(e) => handleOnChangeRadio(e, control, key)}
                              >
                                <FormControlLabel
                                  value="NA"
                                  checked={control[1]?.PrivRole === "NA"}
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#d7d7d7",
                                        "&.Mui-checked": {
                                          color: "#283459",
                                        },
                                      }}
                                    />
                                  }
                                  disabled={(type !== "add" && type !== "edit") || readOnly}
                                />
                                <FormControlLabel
                                  value="RO"
                                  checked={control[1]?.PrivRole === "RO"}
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#d7d7d7",
                                        "&.Mui-checked": {
                                          color: "#283459",
                                        },
                                      }}
                                    />
                                  }
                                  disabled={(type !== "add" && type !== "edit") || readOnly}
                                />
                                <FormControlLabel
                                  value="RWX"
                                  checked={control[1]?.PrivRole === "RWX"}
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#d7d7d7",
                                        "&.Mui-checked": {
                                          color: "#283459",
                                        },
                                      }}
                                    />
                                  }
                                  disabled={(type !== "add" && type !== "edit") || readOnly}
                                />
                              </RadioGroup>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}

        {/* {isExport && !loader && roleDetailsAdd?.Items?.length > 0 && (
          <Link className="export-link" to="/">
            {t(t1, "EXPORT_CSV_TXT")}
          </Link>
        )} */}
      </Grid>
      {roleDetailsAdd?.Items?.length === 0 && <NotFound />}
      {loader && <Loader loader={loader} />}
    </>
  );
}
