const defaultLayout = {
  header: {
    display: true,
  },
  sidebar: {
    display: false,
  },
  footer: {
    display: true,
  },
};

export default defaultLayout;
