import { useTheme } from "@emotion/react";
import { Divider } from "@mui/material";
// MUI component and custom components
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
// Library hooks and custom hooks
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// Redux hooks and slice
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useEffectOnce } from "hooks/useEffectOnce";
import { setDialogMessage } from "store/commonSlice";
import { getFileUrl } from "store/configSlice";
import { setRedirectionPath } from "store/createUserProfileSlice";
// Project global constants
import { APIS, defaultORGID, docFilesPageName, enLanguage, getCurrentPageName, getFormLabels, isRolesAssigned, t, USER_ROLE_TYPES, USER_ROLES } from "utils/constants";
import { setCopyrightYear } from "utils/support";

import Style from "./Footer.style";

export default function Footer() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate()

  // Redux states
  const { footer } = useSelector(({ auth }) => auth);
  const { defaultFileUrl } = useSelector(({ config }) => config);
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const { PrivAttributes } = footer;
  const { formSteps: { activeStep } } = useSelector(({ createUserProfile }) => createUserProfile);

  // React states
  const [filteredForms, setFilteredForms] = useState([]);

  // Disclaimer is not required on any page other than home page - RPM2-2162

  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isIndividualUser, isJurisdictionRegistrarUser, isJurisdictionAdminUser } = useMemo(
    () => ({
      isIndividualUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]),

      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR]

      ),
      isJurisdictionAdminUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.ADMIN]
      ),
    }),
    [userDetails]
  );



  // const footerText = [t(t1, "ABOUT_ABC_INC"), t(t1, "COPYRIGHT_2019"), t(t1, "TERMS_AND_CONDITIONS"), t(t1, "CONTACT_US")];
  const footerText = [
    { to: "https://wci-inc.org/", name: t(t1, "ABOUT_ABC_INC"), type: 'a', width: '139px', Access: PrivAttributes?.priv_lnk_AboutWCI?.PrivRole },
    { to: "", name: setCopyrightYear(t(t1, "COPYRIGHT_2019")), type: 'a', width: '155px', Access: 'NA' },
    { to: filteredForms?.[0]?.LabelPublished?.replace("#", "%23"), name: t(t1, "TERMS_AND_CONDITIONS"), type: 'a', width: '184px', Access: PrivAttributes?.priv_lnk_WCIIncTearm?.PrivRole },
    { to: filteredForms?.[1]?.LabelPublished?.replace("#", "%23"), name: t(t1, "WCI_PRIVACY_POLICY"), type: 'a', width: '139px' },
    // { to: "https://wcitestbucket.s3.us-east-2.amazonaws.com/amazon-s3-bucket/fundingagreementcalifornia-20211217-en-signatureonfile.pdf", name: t(t1, "TERMS_AND_CONDITIONS"),type:'a', width:'184px', Access: PrivAttributes?.priv_lnk_WCIIncTearm?.PrivRole },
    { to: "/contact-us", name: t(t1, "CONTACT_US"), type: 'a', width: '111px', Access: PrivAttributes?.priv_lnk_ContactUs?.PrivRole },
  ];

  // Add required key in array to display form/url
  const formsLabelList = [
    "WCI_TERMS_OF_USE",
    "LEGAL_DOCUMENT_1"
  ];

  const disclaimerInPath = ['/create-user-profile', '/user-registration'];

  useEffect(() => {
    const filterForms = defaultFileUrl?.data?.filter((eachFile) => formsLabelList?.includes(eachFile?.SK?.split("#")?.pop()));
    setFilteredForms(filterForms);
  }, [defaultFileUrl]);

  const initialFunctionCall = () => {
    const getDocFilePayload = {
      OrgID: selectedJurisdiction || defaultORGID,
      QueryType: getFormLabels,
      PageLanguage: enLanguage(),
      Page: docFilesPageName,
    };
    dispatch(getFileUrl(getDocFilePayload));
  };
  const onFooterLinkClicked = (route) => {
    if (location.pathname === "/create-user-profile" && activeStep !== 6) {
/* Conflict Resolution Candidate
      dispatch(setRedirectionPath({route ,jurisdiction :""}))
 */
      dispatch(setRedirectionPath(route))
      dispatch(setDialogMessage(true))
    }
    else {
      navigate(route)
    }
  }

  useEffectOnce(() => {
    if (Object.keys(defaultFileUrl)?.length === 0) initialFunctionCall();
  }, []);

  return (
    <Box className="footerMain">
       {((isIndividualUser &&  location.pathname === `/home`) || ((isJurisdictionRegistrarUser || isJurisdictionAdminUser)  && location.pathname === `/jurisdiction-user`) || (disclaimerInPath.includes(location.pathname))) && (
      <Container maxWidth="xl">
        <Divider color={theme.palette.text.primary} />
      </Container>
       )}
      {PrivAttributes?.priv_Text_Disclaimer?.PrivRole !== "NA" && (

        <Box className="disclaimer" sx={{ padding: "20px 0" }}>
            {((isIndividualUser &&  location.pathname === `/home`) || ((isJurisdictionRegistrarUser || isJurisdictionAdminUser)  && location.pathname === `/jurisdiction-user`) || (disclaimerInPath.includes(location.pathname))) && (
          <Container maxWidth="xl">
            <Typography>
              <Box component="span" sx={{ fontWeight: 700 }}>
                {t(t1, "DISCLAIMER")}:{" "}
              </Box>
              {t(t1, "FOOTER_DISCLAIMER_CONTENT")}
            </Typography>
          </Container>
            )}
        </Box>

      )}

      <Box sx={Style.bottomBar}>
        <Container maxWidth="xl">
          <List sx={Style.menuWrapper}>
            {footerText.map((footer, index) => (
              <ListItem disablePadding key={index} sx={{ ...Style.menuItem, width: ["auto",footer.width],pr:[0.5,0] }}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  footer.Access !== "NA" ? (
                    footer.type === "link" ? (
                      <Typography as="a" sx={{ color: "#fff", textDecoration: "none", cursor: "pointer" }} variant="body2" onClick={() => onFooterLinkClicked(footer.to)}>{footer.name}</Typography>
                    ) : (
                      <a
                        href={footer.to}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        <Typography variant="body2"  sx={{fontSize: ["7px !important", "14px !important"]}}>{footer.name}</Typography>
                      </a>
                    )
                  ) : <Typography variant="body2" sx={{fontSize: ["7px !important", "14px !important"]}}>{footer.name}</Typography>
                }
              </ListItem>
            ))}
          </List>
        </Container>
      </Box>
    </Box>
  );
}
