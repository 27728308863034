import { Box, Link, Typography } from "@mui/material";
import { BATCH_TRANSFER_TYPES, CURRENCY_OPTIONS, CUSTOM_INPUT_ALLOWED_PATTERN, formatNumber, t, customSorting } from "utils/constants";
import { isComplete, isEditable } from "pages/ManageBatchTransfer/constants";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-premium";
import { StyledRequiredAstrik } from "pages/WiringInstructions/WiringInstructions.style";
import { Link as RouterLink } from "react-router-dom";
import { LinkStyle } from "config/common.styles";
import QuickActionsMenu from "./quickActionsMenu";
import { CustomNumberTextField, CustomSelectField } from "../AddReceivingAccount/customCelEditor";

export const BatchTransfersReceivingAccountSchema = (
  t1,
  status,
  isAdmin,
  onRowEditModeChange,
  removeRowsToggle,
  setOpenConfirmDialog,
  currencyList,
  transferType,
  isInEditMode,
  selectedLanguage,
  valueOptions
) => [
    ...(isComplete(status)
      ? [
        {
          id: 0,
          field: "transferId",
          headerName: t(t1, "TRANSFER_ID"),
          minWidth: 100,
          maxWidth: 1000,
          flex: 0.5,
          align: "center",
          hideable: false,
          valueGetter: (params) => params?.row?.transferId,
          renderCell: (params) => (
            <Link
              color="primary.light"
              component={RouterLink}
              to={`/transfer-detail/${params?.row?.transferId}`}
              sx={LinkStyle}
            >
              {params?.row?.transferId}
            </Link>
          ),
        },
      ]
      : []),
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
    },
    {
      id: 1,
      field: "vintage",
      headerName: t(t1, "VINTAGE"),
      minWidth: 100,
      maxWidth: 1000,
      flex: 0.5,
      align: "center",
      renderCell: (cellValues) => <Typography variant="body2">{cellValues?.row?.vintage}</Typography>,
    },
    {
      id: 3,
      field: "type",
      headerName: t(t1, "TYPE"),
      minWidth: 100,
      maxWidth: 1000,
      flex: 0.5,
      align: "center",
      type: 'singleSelect',
      valueOptions: valueOptions?.type?.sort(),
    },
    {
      id: 4,
      field: "subType",
      headerName: t(t1, "SUB_TYPE_HEADER"),
      minWidth: 100,
      maxWidth: 1000,
      flex: 0.5,
      align: "center",
      type: 'singleSelect',
      valueOptions: valueOptions?.subType?.sort(),
    },
    {
      id: 5,
      field: "offsetType",
      headerName: t(t1, "OFFSET_TYPE"),
      minWidth: 100,
      maxWidth: 1000,
      flex: 0.5,
      align: "center",
    },
    {
      id: 6,
      field: "offsetProjectId",
      headerName: t(t1, "OFFSET_PROJECT_ID"),
      minWidth: 100,
      maxWidth: 1000,
      width: 150,
      align: "center",
    },
    {
      id: 7,
      field: "specialCategory",
      headerName: t(t1, "SPECIAL_CATEGORY"),
      minWidth: 100,
      maxWidth: 1000,
      width: 150,
      align: "center",
    },
    {
      id: 8,
      field: "quantity",
      headerName: t(t1, "QUANTITY"),
      minWidth: 100,
      maxWidth: 1000,
      width: 150,
      align: "center",
      valueFormatter: ({ value }) => formatNumber(value),
      sortComparator: customSorting,
      stringAsNumber: true,
    },
    {
      id: 9,
      field: "availableHoldingQuantity",
      headerName: t(t1, "AVAILABLE_QUANTITY"),
      minWidth: 100,
      maxWidth: 1000,
      width: 220,
      align: "center",
      valueFormatter: ({ value }) => formatNumber(value),
      sortComparator: customSorting,
      stringAsNumber: true,
    },
    {
      id: 10,
      field: "accountType",
      headerName: t(t1, "RECEIVING_ACCOUNT_TYPE"),
      minWidth: 100,
      maxWidth: 1000,
      width: 220,
      align: "center",
      valueGetter: ({ value }) => value,
      type: 'singleSelect',
      valueOptions: valueOptions?.accountType?.sort(),
    },
    {
      id: 11,
      field: "accountStatus",
      headerName: t(t1, "RECEIVING_ACCOUNT_STATUS"),
      minWidth: 100,
      maxWidth: 1000,
      width: 230,
      align: "center",
      valueGetter: ({ value }) => t(t1, value),
      type: 'singleSelect',
      valueOptions: valueOptions?.accountStatus?.sort(),
    },
    ...(transferType === BATCH_TRANSFER_TYPES.BATCH_TRANSFER
      ? [
        {
          id: 12,
          field: "legalName",
          headerName: t(t1, "LEGAL_NAME"),
          minWidth: 100,
          maxWidth: 1000,
          flex: 0.5,
          align: "center",
          valueGetter: ({ value }) => value,
        },
        {
          id: 13,
          field: "operationalName",
          headerName: t(t1, "OPERATING_NAME"),
          minWidth: 100,
          maxWidth: 1000,
          width: 150,
          align: "center",
          valueGetter: ({ value }) => value,
        },
      ]
      : []),
    {
      id: 14,
      field: "accountNumber",
      headerName: t(t1, "RECEIVING_ACCOUNT_NUMBER"),
      renderHeader: () =>
        isInEditMode && transferType === BATCH_TRANSFER_TYPES.BATCH_TRANSFER ? (
          <Box sx={{ fontWeight: 600 }}>
            {t(t1, "RECEIVING_ACCOUNT_NUMBER")}
            <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>
          </Box>
        ) : (
          <Box sx={{ fontWeight: 600 }}>{t(t1, "RECEIVING_ACCOUNT_NUMBER")}</Box>
        ),
      minWidth: 100,
      maxWidth: 1000,
      hideable: false,
      width: 200,
      align: "center",
      renderCell: (params) =>
        params?.row?.editMode && transferType === BATCH_TRANSFER_TYPES.BATCH_TRANSFER ? (
          <div onDoubleClick={(event) => event.stopPropagation()}>
            <CustomNumberTextField
              name={`receivingAccountDetails.[${params.row.rowId}].accountNumber`}
              value={params.row.accountNumber ?? ""}
              regex={CUSTOM_INPUT_ALLOWED_PATTERN.alphaNumPattern}
              rules={{
                validate: {
                  fieldRequired: () => {
                    const { accountNumber } = params.row;
                    return !accountNumber
                      ? `${t(t1, "ERR_RECEIVING_ACCOUNT_NUMBER_VALIDATION_BUDGET_IS_REQUIRED")}`
                      : true;
                  },
                }
              }}
            />
          </div>
        ) : (
          <Typography variant="body2">{params?.row?.accountNumber}</Typography>
        ),
    },
    ...(transferType === BATCH_TRANSFER_TYPES.BATCH_TRANSFER
      ? [
        {
          id: 15,
          field: "entityReference",
          headerName: t(t1, "ENTITY_REFERENCE_CODE"),
          minWidth: 100,
          maxWidth: 1000,
          width: 200,
          renderCell: (params) =>
            params?.row?.editMode ? (
              <div onDoubleClick={(event) => event.stopPropagation()}>
                <CustomNumberTextField
                  name={`receivingAccountDetails.[${params.row.rowId}].entityReference`}
                  value={params.row.entityReference ?? ""}
                  regex={CUSTOM_INPUT_ALLOWED_PATTERN.bigInt}
                />
              </div>
            ) : (
              <Typography variant="body2">{params?.row?.entityReference}</Typography>
            ),
        },
      ]
      : []),
    {
      id: 16,
      field: "proposedQuantity",
      headerName: t(t1, "PROPOSED_QUANTITY"),
      renderHeader: () =>
        isInEditMode ? (
          <Box sx={{ fontWeight: 600 }}>
            {t(t1, "PROPOSED_QUANTITY")}
            <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>
          </Box>
        ) : (
          <Box sx={{ fontWeight: 600 }}>{t(t1, "PROPOSED_QUANTITY")}</Box>
        ),
      minWidth: 100,
      maxWidth: 1000,
      width: 160,
      hideable: false,
      align: "center",
      stringAsNumber: true,
      valueFormatter: ({ value }) => formatNumber(value),
      renderCell: (params) =>
        params?.row?.editMode ? (
          <div onDoubleClick={(event) => event.stopPropagation()}>
            <CustomNumberTextField
              name={`receivingAccountDetails.[${params.row.rowId}].proposedQuantity`}
              value={params.row.proposedQuantity ?? ""}
              regex={CUSTOM_INPUT_ALLOWED_PATTERN.bigInt}
              rules={{
                validate: {
                  fieldRequired: () => {
                    const { proposedQuantity } = params.row;
                    return !proposedQuantity
                      ? `${t(t1, "ERR_PROPOSED_QUANTITY_VALIDATION_BUDGET_REQUIRED")} `
                      : true;
                  },
                },
              }}
            />
          </div>
        ) : null,
    },
    ...(transferType === BATCH_TRANSFER_TYPES.BATCH_TRANSFER
      ? [
        {
          id: 17,
          field: "price",
          headerName: t(t1, "PRICE"),
          minWidth: 100,
          maxWidth: 1000,
          flex: 0.5,
          align: "center",
          stringAsNumber: true,
          renderCell: (params) =>
            params?.row?.editMode ? (
              <div onDoubleClick={(event) => event.stopPropagation()}>
                <CustomNumberTextField
                  name={`receivingAccountDetails.[${params.row.rowId}].price`}
                  value={params.row.price ?? ""}
                  regex={
                    selectedLanguage === "French" ?
                      CUSTOM_INPUT_ALLOWED_PATTERN.floatFrench
                      : CUSTOM_INPUT_ALLOWED_PATTERN.float
                  }
                  rules={{
                    validate: {
                      fieldRequired: () => {
                        const { price, currency, accountNumber } = params.row;
                        return currency && !price
                          ? `${t(t1, "ACCOUNT_NUMBER")} ${accountNumber} : ${t(t1, "PRICE")} ${t(
                            t1,
                            "VALIDATION_PRICE_REQUIRED"
                          )} `
                          : true;
                      },
                      isValid: () => {
                        const { price, accountNumber } = params.row;
                        return price && price === "0"
                          ? `${t(t1, "ACCOUNT_NUMBER")} ${accountNumber} : ${t(t1, "PRICE")} ${t(
                            t1,
                            "VALIDATION_PRICE_NON_ZERO"
                          )} `
                          : true;
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <Typography variant="body2">
                {selectedLanguage === "French" && params?.row?.price}
                {selectedLanguage !== "French" && (params?.row?.price ? formatNumber(Number(params.row.price)) : params?.row?.price)}
              </Typography>
            ),
        },
        {
          id: 18,
          field: "currency",
          headerName: t(t1, "CURRENCY"),
          minWidth: 100,
          maxWidth: 1000,
          flex: 0.5,
          valueGetter: (params) => currencyList?.find((currency) => currency.value === params.row.currency)?.label ?? "",
          renderCell: (params) =>
            params?.row?.editMode ? (
              <div onDoubleClick={(event) => event.stopPropagation()}>
                <CustomSelectField
                  name={`receivingAccountDetails.[${params.row.rowId}].currency`}
                  value={params.row.currency}
                  type="select"
                  label={t(t1, "SELECT_CURRENCY")}
                  options={[...currencyList]}
                  placeholder={t(t1, CURRENCY_OPTIONS[0].label)}
                  rules={{
                    validate: {
                      fieldRequired: () => {
                        const { currency, price, accountNumber } = params.row;
                        return price && !currency
                          ? `${t(t1, "ACCOUNT_NUMBER")} ${accountNumber} : ${t(t1, "CURRENCY")} ${t(
                            t1,
                            "VALIDATION_CURRENCY_REQUIRED"
                          )} `
                          : true;
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <Typography variant="body2">{params?.row?.currencyName}</Typography>
            ),
        },
      ]
      : []),
    ...(isAdmin && !removeRowsToggle && isEditable(status)
      ? [
        {
          id: 19,
          field: "QuickActions",
          headerName: t(t1, "QUICK_ACTIONS"),
          minWidth: 120,
          maxWidth: 120,
          flex: 1,
          align: "center",
          disableExport: true,
          filterable: false,
          hideable: false,
          renderCell: (params) => (
            <QuickActionsMenu
              params={params}
              status={status}
              onRowEditModeChange={onRowEditModeChange}
              setOpenConfirmDialog={setOpenConfirmDialog}
            />
          ),
        },
      ]
      : []),
  ];
