import { useEffect } from "react";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DialogMessage } from "components/DialogMessage";
import { ModelMessage } from "components/ModelMessage";
import { PageTitle } from "components/PageTitle";
import { UserSteps } from "components/UserSteps";
import { useEffectOnce } from "hooks/useEffectOnce";
import { useTranslation } from "react-i18next";
import { t, getCurrentPageName, HomePageName } from "utils/constants";
import {
  fetchUserDetailsByLinkId,
  getCountries,
  resetCreaeUserProfileState,
  setFilledData,
  setNextActiveStep,
  setSubmittedState,
  updateEditFieldUserProfileState,
} from "store/createUserProfileSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { setDialogMessage } from "store/commonSlice";
// import { t1 } from "config/lang/i18n";
import { getJurisdictions } from "store/jurisdictionSlice";
import { Loader } from "components/Loader";
import _ from 'lodash';
import { LinkExpired } from "components/LinkExpired";
import { EditAndReview } from "../EditAndReview";
import { PersonalInformation } from "../PersonalInformation";
import { ResidenceAndMailing } from "../ResidenceAndMailing";
import { SelectJurisdiction } from "../SelectJurisdiction";
import { SubmitDocuments } from "../SubmitDocuments";
import { UserLogin } from "../UserLogin";

export default function UserRegistration() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const linkId = searchParams.get('LinkID');

  const {
    formSteps: { activeStep },
    prePopulatedDetails: {isLoading, data},
    isLinkExpired
  } = useSelector(({ createUserProfile }) => createUserProfile);
  // const { dialogMessage, modelMessage } = useSelector(({ common }) => common);

  useEffectOnce(() => {
    dispatch(getCountries());
    dispatch(setDialogMessage(false));
    dispatch(resetCreaeUserProfileState());
  }, []);

  useEffectOnce(() => {
    dispatch(getJurisdictions());
    if (linkId) {
      dispatch(fetchUserDetailsByLinkId(linkId));
    }
  });

  // const cancelCreateUser = () => {
  //   navigate("/");
  //   dispatch(resetCreaeUserProfileState());
  // };

  if (isLinkExpired || data?.is_link_valid === false) {
    return <LinkExpired />;
  }

  if (linkId && isLoading) {
    return <Loader loader={isLoading} />;
  }

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2.5}
      >
        <Grid
          item
          md={3}
        >
          <Box sx={{ paddingTop: "25px", position: "sticky", top: 0 }}>
            <UserSteps />
          </Box>
        </Grid>
        <Grid
          item
          md={9}
        >
          <Box sx={{ pt: 3 }}>
            <PageTitle
              title={
                activeStep === 6
                  ? `2. ${t(t1, "SUBMITTED_DOCUMENT_SUBMIT_REQUIRED_DOCUMENTS")}`
                  : `1. ${t(t1, "CREATE_USER_PROFILE")}`
              }
            />
            {activeStep === 1 ? <SelectJurisdiction /> : null}
            {activeStep === 2 ? <PersonalInformation /> : null}
            {activeStep === 3 ? <ResidenceAndMailing /> : null}
            {activeStep === 4 ? <EditAndReview /> : null}
            {activeStep === 5 ? <UserLogin /> : null}
            {activeStep === 6 ? <SubmitDocuments /> : null}
            {/* {dialogMessage ? (
              <DialogMessage
                warning
                title={t(t1, "WARNING")}
                message1={t(t1, "DIALOG_ERASE_MESSAGE")}
                message2={t(t1, "DIALOG_RESTART_MESSAGE")}
                handleModalClick={cancelCreateUser}
              />
            ) : null} */}
            {/* {modelMessage ? (
              <ModelMessage
                error={false}
                title={t(t1, "SUCCESS")}
                message1="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
                message2="Lorem Ipsum is simply dummy text of the printing and typesetting industry printing and typesetting industry"
              />
            ) : null} */}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
