import React from "react";
import Typography from "@mui/material/Typography";

export default function PageTitle({ title, titleNoSpace = false, customeStyle = "" }) {
  return (
    <Typography variant="h1" sx={{ mb: titleNoSpace ? 0 : 3.4, ...customeStyle }}>
      {title}
    </Typography>
  );
}
