const Style = {
  ModelWrapper: {
    "& .MuiDialog-paper": {
      boxShadow: 2,
      border: "2px solid #4d8da4",
      borderRadius: 3,
      py: 2,
      px: 2.5,
    },
    ".MuiBackdrop-root": {
      bgcolor: "common.warning04",
    },
  },
  ModelWarning: {
    ".MuiDialog-paper": {
      borderColor: "#4d8da4",
      borderRadius: "8px",
    },
  },
  ModelError: {
    ".MuiDialog-paper": {
      borderColor: "#4d8da4",
      borderRadius: "8px",
      overflowY:"inherit",
      maxHeight:"none",
    },
  },

};

export default Style;
