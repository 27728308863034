import { BrowserRouter, useLocation, useRoutes } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { I18nextProvider } from 'react-i18next';
import { AppProvider } from 'context/AppContext';
import { routes } from 'config/routes';
import { theme } from 'config';
import { Layout } from 'core/Layout';
import { fetchUpdatedCMSPages } from 'store/cmsSlice';
import {
  getCurrentORGID,
  languageCode,
  PAGES,
  REACT_APP_MUI_LICENSE_KEY,
} from 'utils/constants';
import i18n from 'config/lang/i18n';
import { ErrorBoundary } from 'components';
import { Loader } from 'components/Loader';
import { AuthProvider } from 'context/AwsContext';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { setI18nLoaded } from 'store/userHomeSlice';
import { MotionLazyContainer } from './components/MotionLazyContainer';
import './App.css';
import store from './store/store';

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE_KEY);

function Routes() {
  // Router hooks and methods
  const location = useLocation();
  const dispatch = useDispatch();

  // Constants
  let currentPageName = PAGES?.find((eachPage) => eachPage?.path?.split('/*')?.[0] === location?.pathname)?.name; // To find current page name from constants

  if (!currentPageName) {
    currentPageName = PAGES?.find(
      (eachPage) => eachPage?.path?.split('/')?.[1] === location?.pathname.split('/')[1],
    )?.name; // To find current page name from constants
  }

  localStorage?.setItem('pageName', currentPageName);
  // Before API load english translation added - for Automation testing
  // localStorage?.setItem(currentPageName, JSON.stringify(enCommon));
  // Redux states
  const { entities } = useSelector(({ entityProfile }) => entityProfile);
  const { jurisdictionDetails } = useSelector(({ jurisdiction }) => jurisdiction);

  // scroll to top on every route changes
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    // To set current pagename when shifting tab
    const setPageNameOnInteraction = () => {
      const currentPage = PAGES?.find(
        (eachPage) => eachPage?.path?.split('/*')?.[0] === `/${location?.pathname.split('/')?.[1]}`,
      )?.name; // To find current page name from constants changing this to match current routes registered in constansts
      localStorage.setItem('pageName', currentPage);
      i18n.changeLanguage(`${decodeURIComponent(getCurrentORGID())}#${languageCode()}`);
    };

    // Detects when a browser tab becomes active/inactive.
    document.addEventListener('visibilitychange', setPageNameOnInteraction);

    return () => {
      document.removeEventListener('visibilitychange', setPageNameOnInteraction);
    };
  }, [location, entities?.data, jurisdictionDetails]);

  // This useEffect will call while locations get changes and call the page wise data
  useEffectOnce(() => {
    // check if timestamp present if yes then call api to fetch updated pages
    dispatch(fetchUpdatedCMSPages(dispatch));

    i18n.on('loaded', () => {
      dispatch(setI18nLoaded(true));
    });

  }, []);

  return useRoutes(routes);
}

export default function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <MotionLazyContainer>
          <Suspense fallback={<Loader loader />}>
            <I18nextProvider i18n={i18n}>
              <AppProvider
                value={{
                  routes,
                }}
              >
                <Provider store={store}>
                  <ThemeProvider theme={theme}>
                    <BrowserRouter>
                      <CssBaseline />
                      <Layout>
                        <Routes />
                      </Layout>
                    </BrowserRouter>
                  </ThemeProvider>
                </Provider>
              </AppProvider>
            </I18nextProvider>
          </Suspense>
        </MotionLazyContainer>
      </AuthProvider>
    </ErrorBoundary>
  );
}
