import { createSlice } from "@reduxjs/toolkit";
import { ACTIVATION, DEACTIVATION, INITIAL_USER_COMPONENTS, INITIAL_USER_PAGES, transformEditInitailUser, transformRolesRes } from "pages/SetupInitialUser/support";
import { APIS, ERROR, SUCCESS, t } from "utils/constants";
import fetcher, { fetcherGet, fetcherPost, fetcherPut } from "utils/fetcher";
import { getPermissionObj } from "utils/support";
import { setLoader, setModelMessage } from "./commonSlice";

const initialState = {
  jurisdictionDetails: { isLoading: false, data: [] },
  initialUsers: { isLoading: false, data: [] },
  editInitialUserData: { isLoading: false, data: [] },
  allRoles: { isLoading: false, data: [] },
  initialUserHistory: { isLoading: false, data: [] },
};

const setInitialUserSlice = createSlice({
  name: "setInitialUser",
  initialState,
  reducers: {
    setJurisdictionDetails: (state, action) => {
      state.jurisdictionDetails = action.payload;
    },
    setInitialUsers: (state, action) => {
      state.initialUsers = action.payload;
    },
    setInitialUserData: (state, action) => {
      state.editInitialUserData = action.payload;
    },
    setAllRoles: (state, action) => {
      state.allRoles = action.payload;
    },
    setInitialUserHistory: (state, action) => {
      state.initialUserHistory = action.payload;
    },
  },
});

export default setInitialUserSlice.reducer;

export const { setJurisdictionDetails, setInitialUsers, setInitialUserData, setAllRoles, setInitialUserHistory } =
  setInitialUserSlice.actions;

export function getJurisdictionDetails(jurisdictionId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setJurisdictionDetails({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.JURISDICTIONS}?jurisdictionId=${jurisdictionId}`);
      const { data } = response;
      dispatch(
        setJurisdictionDetails({
          isLoading: false,
          data,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setJurisdictionDetails({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function getInitialUsersAPI(
  jurisdictionId, 
  pageName = INITIAL_USER_PAGES.MANAGE_INITIAL_USERS,
  componentName = INITIAL_USER_COMPONENTS.INITIAL_USERS
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setInitialUsers({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_INITIAL_USERS}/${jurisdictionId}`, {}, { pageName, componentName });
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setInitialUsers({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setInitialUsers({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function invalidateLink(t1, userId, jurisdictionId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      await fetcherGet(`${APIS.INVALIDATE_LINK.replace(":userId", userId)}`);
      const data = {
        title: SUCCESS,
        message1: t(t1, "INVALIDATE_LINK_SUCCESS"),
        error: false,
      };
      dispatch(getInitialUsersAPI(jurisdictionId));
      dispatch(getInitialUsersHistory(jurisdictionId));
      dispatch(setLoader(false));
      dispatch(setModelMessage(data));
    } catch (error) {
      dispatch(setLoader(false));
      const data = {
        title: ERROR,
        message1: t(t1, error?.response?.data?.data) || t(t1, "INTERNAL_SERVER_ERR"),
        error: true,
      };
      dispatch(setModelMessage(data));
    }
  };
}

export function activateOrUnlockUser(t1, payload, jurisdictionId, activation, flpData) {
  return async (dispatch) => {
    const newPayload = { ...payload };
    delete newPayload.uuid;
    dispatch(setLoader(true));
    try {
      const response = await fetcherPut(`${APIS.ACTION_REQUEST_USER_HISTORY}`, newPayload, flpData);
      const data = {
        title: SUCCESS,
        message1: response.message,
        error: false,
      };
      if (activation) {
        const emailPayload = {
          initialUserId: payload.uuid,
          action: ACTIVATION
        };
        await fetcherGet(`${APIS.ACTIVATE_DEACTIVATE}/${payload.uuid}/${ACTIVATION}`);
        await fetcherPost(`${APIS.SEND_EMAIL}`, emailPayload);
      }
      dispatch(getInitialUsersAPI(jurisdictionId));
      dispatch(getInitialUsersHistory(jurisdictionId));
      dispatch(setLoader(false));
      dispatch(setModelMessage(data));
    } catch (error) {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: t(t1, error?.response?.data?.data) || t(t1, "INTERNAL_SERVER_ERR"),
          error: true,
        };
        dispatch(setModelMessage(data));
    }
  };
}

export function deactivateUser(t1, payload, jurisdictionId, flpData) {
  return async (dispatch) => {
    const newPayload = { ...payload };
    delete newPayload.uuid;
    dispatch(setLoader(true));
    try {
      const response = await fetcherPut(`${APIS.ACTION_REQUEST_USER_HISTORY}`, newPayload, flpData);
      const data = {
        title: SUCCESS,
        message1: response.message,
        error: false,
      };
      const emailPayload = {
        initialUserId: payload.uuid,
        action: DEACTIVATION
      };
      await fetcherGet(`${APIS.ACTIVATE_DEACTIVATE}/${payload.uuid}/${DEACTIVATION}`);
      await fetcherPost(`${APIS.SEND_EMAIL}`, emailPayload);
      dispatch(getInitialUsersAPI(jurisdictionId));
      dispatch(getInitialUsersHistory(jurisdictionId));
      dispatch(setLoader(false));
      dispatch(setModelMessage(data));
    } catch (error) {
      dispatch(setLoader(false));
      const data = {
        title: ERROR,
        message1: t(t1, error?.response?.data?.data) || t(t1, "INTERNAL_SERVER_ERR"),
        error: true,
      };
      dispatch(setModelMessage(data));
    }
  };
}

export function addInitialUserAPI(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.ADD_INITIAL_USER}`,
      {
        ...payload,
      },
      {}
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// to get edit prefilled data
export function getEditInitialUserDataAPI(
  jurisdictionId,
  initialUserId,
  pageName = INITIAL_USER_PAGES.EDIT_INITIAL_USER_PAGE,
  componentName = INITIAL_USER_COMPONENTS.EDIT_INITIAL_USER
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setInitialUserData({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.GET_EDIT_INITIAL_USER_DATA}/${jurisdictionId}?userId=${initialUserId}`,
        {},
        {}
      );
      dispatch(
        setInitialUserData({
          isLoading: false,
          data: transformEditInitailUser(response.data),
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setInitialUserData({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function getAllRolesAPI() {
  return async (dispatch) => {
    dispatch(setAllRoles({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_ROLE}`, {}, {});
      dispatch(
        setAllRoles({
          isLoading: false,
          data: transformRolesRes(response.data),
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setAllRoles({ isLoading: false, data: [] }));
    }
  };
}

// update the Initial user
export function updateInitialUserAPI(payload, pageDetail) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.UPDATE_INITIAL_USER}`, "PUT", payload, false, {}).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// get initial users history API
export function getInitialUsersHistory(
  jurisdictionId,
  pageName = INITIAL_USER_PAGES.MANAGE_INITIAL_USERS,
  componentName = INITIAL_USER_COMPONENTS.INITIAL_USER_HISTORY
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setInitialUserHistory({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.GET_INITIAL_USERS_HISTORY}/${jurisdictionId}/getInitialUserHistory`,
        {}, 
        { pageName, componentName }
      );
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setInitialUserHistory({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setInitialUserHistory({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function resendAccountSetUpLink(id) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(`${APIS.RESEND_ACCOUNT_SETUP_LINK}/${id}`).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export async function validateInitialUserAPI(emailId) {
  try {
    const response = await fetcherGet(`${APIS.VALIDATE_INITIAL_USER}?email=${emailId}`);
    return response?.data;
  } catch (error) {
    return null;
  }
}