import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { USER_ROLES, USER_ROLE_TYPES, isRolesAssigned } from "utils/constants";

export default function useJurisdictionUserRole() {
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const [controlledUserDetails, setControlledUserDetails] = useState(userDetails);

  useEffect(() => {
    // Prevent user detail reset on passphrase confirmation
    if (userDetails.RoleDetail) {
      setControlledUserDetails(userDetails);
    }
  }, [userDetails]);
  
  return useMemo(
    () => ({
      userDetails: controlledUserDetails,
      isAdmin: isRolesAssigned(controlledUserDetails, [USER_ROLE_TYPES.JURISDICTION_USER], [USER_ROLES.ADMIN]),
      isAuthority: isRolesAssigned(controlledUserDetails, [USER_ROLE_TYPES.JURISDICTION_USER], [USER_ROLES.USER]),
      isJurisdictionRegistrarUser: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR]
      ),
      isJurisdictionUser: isRolesAssigned(controlledUserDetails, [USER_ROLE_TYPES.JURISDICTION_USER]),
      isIndividualUser: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.INDIVIDUAL_USER],
        [USER_ROLES.INDIVIDUAL_USER]
      ),
      isAAR: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.INDIVIDUAL_USER],
        [USER_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE]
      ),
      isPAR: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.INDIVIDUAL_USER],
        [USER_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE]
      ),
      isAVA: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.INDIVIDUAL_USER],
        [USER_ROLES.ACCOUNT_VIEWING_AGENT]
      ),
      isPublicUser: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.INDIVIDUAL_USER],
        [USER_ROLES.PUBLIC_USER]
      ),
      isApplicant: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.INDIVIDUAL_USER],
        [USER_ROLES.APPLICANT]
      ),
      isJPS: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.JURISDICTION_PROGRAM_STAFF]
      ),
      isJSA: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.JURISDICTION_SYSTEM_ADMIN]
      ),
      isWCIincAdmin: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.SYSTEM_USER],
        [USER_ROLES.WCI_ADMIN_DEFAULT_ORG]
      ),
      isFSAQA: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.SERVICE_PROVIDERS],
        [USER_ROLES.FINANCIAL_SERVICE_PROVIDERS_QA]
      ),
      isFSA: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.SERVICE_PROVIDERS],
        [USER_ROLES.FINANCIAL_SERVICE_PROVIDERS]
      ),
      isMarketMonitor: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.SERVICE_PROVIDERS],
        [USER_ROLES.MARKET_MONITOR]
      ),
      isAuctionAdministrator: isRolesAssigned(
        controlledUserDetails,
        [USER_ROLE_TYPES.SYSTEM_USER],
        [USER_ROLES.AUCTION_ADMINISTRATOR]
      ),
     
    }),
    [controlledUserDetails]
  );
}
