import { createSlice } from "@reduxjs/toolkit";
import { fetcherGet, fetcherPatch } from "utils/fetcher";
import {
  APIS,
  APPROVAL_TYPES,
  HOLDING_LIMITS_STATUSES,
  formatDateUpdated,
  holdingLimitCheckTypes,
} from "utils/constants";
import _ from "lodash";
import { HOLDING_LIMITS_COMPONENTS, HOLDING_LIMIT_PAGE } from "pages/ManageHoldingLimits/constants";
import { setLoader } from "./commonSlice";

const initialState = {
  holdingLimits: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },
  holdingLimitsDetail: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },
  holdingLimitsHistory: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },
  holdingLimitsProposalHistory: {
    data: [],
    metaData: [],
    fieldAccess: [],
  },
  statuses: {},
  proposedHoldingLimits: [],
  holdingLimitsCheckTransfer: [],
  holdingLimitsCheckPeriodic: [],
  holdingLimitsCheckComplete: [],
  holdingLimitsChecksFLP: {},
  nextHoldingCheckCronExpression: '',
};

const HoldingLimitsSlice = createSlice({
  name: "holdingLimits",
  initialState,
  reducers: {
    setHoldingLimits: (state, action) => {
      state.holdingLimits = action.payload;
    },
    setHoldingLimitsDetail: (state, action) => {
      state.holdingLimitsDetail = action.payload;
    },
    setHoldingLimitsHistory: (state, action) => {
      state.holdingLimitsHistory = action.payload;
    },
    setHoldingLimitsProposalHistory: (state, action) => {
      state.holdingLimitsProposalHistory = action.payload;
    },
    setStatuses: (state, action) => {
      state.statuses = { ...action.payload };
    },
    setProposedHoldingLimits: (state, action) => {
      state.proposedHoldingLimits = action.payload;
    },
    setHoldingLimitsCheckTransfer: (state, action) => {
      state.holdingLimitsCheckTransfer = action.payload;
    },
    setHoldingLimitsCheckPeriodic: (state, action) => {
      state.holdingLimitsCheckPeriodic = action.payload;
    },
    setHoldingLimitsCheckComplete: (state, action) => {
      state.holdingLimitsCheckComplete = action.payload;
    },
    setHoldingLimitsCheckFLP: (state, action) => {
      state.holdingLimitsChecksFLP = { ...state.holdingLimitsChecksFLP, ...action.payload };
    },
    setNextHoldingCheckCronExpression: (state, action) => {
      state.nextHoldingCheckCronExpression = action.payload;
    },
  },
});

export const {
  setHoldingLimits,
  setHoldingLimitsDetail,
  setHoldingLimitsHistory,
  setHoldingLimitsProposalHistory,
  setStatuses,
  setProposedHoldingLimits,
  setHoldingLimitsCheckPeriodic,
  setHoldingLimitsCheckTransfer,
  setHoldingLimitsCheckComplete,
  setHoldingLimitsCheckFLP,
  setNextHoldingCheckCronExpression
} = HoldingLimitsSlice.actions;
export default HoldingLimitsSlice.reducer;

export function getHoldingLimitsList() {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/holdingLimitDetail`,
      {},
      { componentName: HOLDING_LIMITS_COMPONENTS.HOLDING_LIMIT_DETAILS }
    )
      .then((response) => {
        dispatch(setHoldingLimits(response));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getHoldingLimitsDetail(id) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/holdingLimitDetail`,
      {
        holdingLimitId: id,
      },
      { componentName: HOLDING_LIMITS_COMPONENTS.HOLDING_LIMIT_DETAILS }
    )
      .then((response) => {
        dispatch(setHoldingLimitsDetail(response));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getHoldingLimitsHistory(id) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/holdingLimitHistory/${id}`,
      {},
      { componentName: HOLDING_LIMITS_COMPONENTS.HOLDING_LIMIT_HISTORY }
    )
      .then((response) => {
        dispatch(
          setHoldingLimitsHistory({
            ...response,
            data: response?.data?.filter((hl) =>
              [HOLDING_LIMITS_STATUSES.CANCELLED, HOLDING_LIMITS_STATUSES.APPROVED].includes(hl?.Status?.name)
            ),
          })
        );
        let isCancelled = false;
        const proposalHistory = response.data.reduce((acc, hl) => {
          if (
            (hl?.Status?.name === HOLDING_LIMITS_STATUSES.PROPOSED && !isCancelled) ||
            [
              HOLDING_LIMITS_STATUSES.DENIED,
              HOLDING_LIMITS_STATUSES.APPROVED,
              HOLDING_LIMITS_STATUSES.REQUEST_REVISION,
            ].includes(hl?.Status?.name)
          ) {
            acc.push(hl);
            isCancelled = false;
          }
          if (hl?.Status?.name === HOLDING_LIMITS_STATUSES.CANCELLED) {
            isCancelled = true;
          }
          return acc;
        }, []);
        dispatch(
          setHoldingLimitsProposalHistory({
            ...response,
            data: proposalHistory,
          })
        );
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getStatusList() {
  return (dispatch) =>
    fetcherGet(`${APIS.STATUS_LIST}`, { type: "HOLDING_LIMIT" }).then(({ data = [] }) => {
      dispatch(setStatuses(_.keyBy(data, "Name")));
    });
}

export function proposeHoldingLimit({ id, description, quantity, comment }) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPatch(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/holdingLimit/${id}`,
      {
        description,
        quantity,
        comment,
      },
      { componentName: HOLDING_LIMITS_COMPONENTS.EDIT_HOLDING_LIMIT_DETAIL }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function handleHoldingLimitAction({ id, statusId, comment }) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPatch(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/statusUpdate/${id}`,
      {
        statusId,
        comment,
      },
      { componentName: HOLDING_LIMITS_COMPONENTS.EDIT_HOLDING_LIMIT_STATUS }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getProposedHoldingLimits(jurisdictionId) {
  return (dispatch) =>
    fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/holdingLimitDetail`,
      {},
      { pageName: HOLDING_LIMIT_PAGE, componentName: HOLDING_LIMITS_COMPONENTS.HOLDING_LIMIT_DETAILS }
    ).then((response) => {
      const pendingApprovals = response?.data
        ?.filter((holdingLimit) => holdingLimit?.Status?.name === HOLDING_LIMITS_STATUSES.PROPOSED)
        ?.map((holdingLimit) => ({
          ...holdingLimit,
          displayId: holdingLimit?.budgetYear,
          keyInformation: holdingLimit?.proposedDescription,
          lastUpdatedAt: formatDateUpdated(holdingLimit?.updatedAt),
          type: APPROVAL_TYPES.HOLDING_LIMIT,
          status: holdingLimit?.Status?.name,
        }));
      dispatch(setProposedHoldingLimits(pendingApprovals));
    });
}

export function getHoldingLimitsCheckComplete(id = 0) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/completedTransfer${id ? `?completedTransferUuid=${id}` : ""
      }`,
      {},
      { componentName: HOLDING_LIMITS_COMPONENTS.COMPLETED_TRANSFER_HOLDINGS }
    )
      .then((response) => {
        const { fieldAccess, metaData } = response || {};
        dispatch(setHoldingLimitsCheckComplete(response?.data));
        dispatch(setHoldingLimitsCheckFLP({ completedTransfer: { fieldAccess, metaData } }));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getHoldingLimitsCheckPeriodic(id = 0) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/periodicCheck${id ? `?periodicCheckId=${id}` : ""
      }`,
      {},
      { componentName: HOLDING_LIMITS_COMPONENTS.PERIODIC_HOLDING }
    )
      .then((response) => {
        const { fieldAccess, metaData } = response || {};
        dispatch(setHoldingLimitsCheckPeriodic(response?.data));
        dispatch(setHoldingLimitsCheckFLP({ periodicCheck: { fieldAccess, metaData } }));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getNextHoldingCheckCronExp() {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/nextHoldingCheckCronExp`,
      {},
      { pageName: 'Page_ManageHoldingLimit', componentName: 'getnextHoldingCheckCronExp' }
    )
      .then((response) => {
        dispatch(setNextHoldingCheckCronExpression(response?.data));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getHoldingLimitsCheckTransfer(id = 0) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/transferWarning${id ? `?transferWarningUuid=${id}` : ""
      }`,
      {},
      { componentName: HOLDING_LIMITS_COMPONENTS.TRANSFER_WARNINGS }
    )
      .then((response) => {
        const { fieldAccess, metaData } = response || {};
        const transferWarnings = response?.data?.map((warning) => ({
          ...warning,
          EventFailedCheck: {
            ...warning?.EventFailedCheck,
            name: statusCheck(warning?.EventFailedCheck?.name)
          },
          EventPassedCheck: {
            ...warning?.EventPassedCheck,
            name: statusCheck(warning?.EventPassedCheck?.name)
          },
        }))
        dispatch(setHoldingLimitsCheckTransfer(transferWarnings));
        dispatch(setHoldingLimitsCheckFLP({ transferWarning: { fieldAccess, metaData } }));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

const statusCheck = (name) => {
  if (name === HOLDING_LIMITS_STATUSES.PROPOSED) return HOLDING_LIMITS_STATUSES.PROPOSE;
  if (name === HOLDING_LIMITS_STATUSES.APPROVED || name === HOLDING_LIMITS_STATUSES.DENIED) return `${name}_LABEL`;
  return name;
}

export function postHoldingLimitsCheck(transferId, payload, holdingType) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    const comment = payload || "";
    if (holdingType === holdingLimitCheckTypes.COMPLETED_TRANSFER_HOLDING_DETAILS)
      return fetcherPatch(
        `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/completedTransfer/${transferId}/updateCompletedTransfer`,
        { comment },
        { componentName: HOLDING_LIMITS_COMPONENTS.COMPLETED_TRANSFER_HOLDING_DETAILS }
      );

    if (holdingType === holdingLimitCheckTypes.PERIODIC_HOLDING_DETAILS)
      return fetcherPatch(
        `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/periodicCheck/${transferId}`,
        {
          comment,
        },
        { componentName: HOLDING_LIMITS_COMPONENTS.PERIODIC_HOLDING_DETAILS }
      );

    return fetcherPatch(
      `${APIS.MANAGE_HOLDING_LIMITS}/jurisdiction/${jurisdictionId}/transferWarning/${transferId}/updateTransferWarning`,
      { comment },
      { componentName: HOLDING_LIMITS_COMPONENTS.TRANSFER_WARNINGS_DETAILS }
    );
  };
}
