import authRoles from "core/auth/authRole";
/* Conflict Resolution Candidate
import { enLanguage, getCurrentORGID } from "utils/constants";
 */
import LandingPageUnknown from "./LandingPageUnknown";
import LandingPageKnown from "../LandingPageKnown/LandingPageKnown";

const LandingPageUnknownConfig = {
  route: {
    path: "/",
    element:
      localStorage.getItem("i18nextLng") && localStorage.getItem("jurisdiction") ? (
        <LandingPageUnknown />
      ) : (
        <LandingPageKnown />
      ),
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.onlyGuest,
};

export default LandingPageUnknownConfig;
