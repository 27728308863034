import { Box, Typography } from '@mui/material';
import React from 'react'

export default function PermissionDenied() {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography align="center" variant="h5" component="div" gutterBottom>Permission Denied</Typography>
      <Typography align="center" variant="h5" component="div" gutterBottom> You do not have permission to access this page</Typography>
    </Box>
  )
}