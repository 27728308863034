import React, { useEffect, useState } from 'react';
import i18n from 'config/lang/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import { useEffectOnce } from 'hooks/useEffectOnce';
import MenuItem from '@mui/material/MenuItem';
import { getCMSDetails, getFileUrl } from 'store/configSlice';
import { setLanguage } from 'store/jurisdictionSlice';
import Select from '@mui/material/Select';
import {
  defaultORGID,
  getAllLang,
  PAGES,
  enLanguage,
  docFilesPageName,
  getFormLabels,
  DEFAULT_LANGUAGE_CODE,
  getCurrentORGID,
  languageCode,
} from 'utils/constants';

export default function SelectLanguage() {
  const dispatch = useDispatch();
  const [languagesData, setLanguageData] = useState([]);
  const { cmsLanguagePage } = useSelector(({ config }) => config);
  const {
    filledData,
  } = useSelector(({ createUserProfile }) => createUserProfile);

  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const languages = [];
  const language = localStorage.getItem('i18nextLng');

  // router hooks and methods
  const location = useLocation();
  const currentPageName = PAGES?.find((eachPage) => eachPage?.path === location?.pathname)?.name; // To find current page name from constants

  const handleLanguageChange = (event) => {
    languageChange(event.target.value);
  };

  const languageChange = (language) => {
    if (currentPageName === 'Page_Content_Management') window.location.reload();
    dispatch(setLanguage(language));
    localStorage.setItem(
      'languageCode',
      languagesData?.find((item) => language === item.value)?.languageCode || DEFAULT_LANGUAGE_CODE,
    );
    const getDocFilePayload = {
      OrgID: filledData?.[1]?.jurisdiction?.OrgID || selectedJurisdiction,
      QueryType: getFormLabels,
      PageLanguage: language || enLanguage(),
      Page: docFilesPageName,
    };
    if (getDocFilePayload.OrgID) {
      dispatch(getFileUrl(getDocFilePayload));
    }
    i18n.changeLanguage(`${decodeURIComponent(getCurrentORGID())}#${languageCode()}`);
  };

  useEffectOnce(() => {
    const LanguageParams = {
      OrgID: selectedJurisdiction || defaultORGID,
      QueryType: getAllLang,
    };

    dispatch(getCMSDetails(LanguageParams));
  }, []);

  useEffect(() => {
    cmsLanguagePage?.data?.Languages?.map((item, index) => {
      languages.push({
        value: item?.SK?.split('#')?.pop(),
        title: item?.SK?.split('#')?.pop(),
        languageCode: item?.LanguageCode || DEFAULT_LANGUAGE_CODE,
      });
      return null;
    });

    // Check to see if the current language is available in the list of languages
    // If not let's set the language to the first value
    if (!languages.find(languageItem => languageItem.value === language)) {
      languageChange(languages[0]?.value);
    }

    setLanguageData(languages);
  }, [cmsLanguagePage]);

  return (
    <FormControl>
      <Select
        labelId="simple-select-label"
        id="simple-select"
        value={(language === undefined || language === '' || languagesData.length === 0) ? '' : language}
        onChange={handleLanguageChange}
        sx={{
          fontSize: '0.875rem',
          color: 'common.textColor',
          ml: 0.5,
          '.MuiSelect-select': {
            p: 0,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
            outline: 'none',
          },
        }}
      >
        {languagesData.map((language, index) => (
          <MenuItem
            key={`lang_${index}`}
            value={language.value}
          >
            {language.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
