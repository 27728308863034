import CryptoJS from 'crypto-js';
import { SECRET_KEY } from './constants';

// Encrypt object
export const encrypt = (str) => CryptoJS.AES.encrypt(str, SECRET_KEY).toString();

// Decrypt token
export const decrypt = (str) => {
  if (str) {
    const bytes = CryptoJS.AES.decrypt(str, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return '';
};
