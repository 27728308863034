import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ErrorIcon from "@mui/icons-material/Error";

import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, Grid, TextField, Tooltip, tooltipClasses } from "@mui/material";
import { t, months, getCurrentPageName, isEmpty } from "utils/constants";
import CustomPopper from "components/_CustomPopper/customPopper";
import styled from "@emotion/styled";
import Style from "./GridSelectSearch.style";

export default function GridSelectSearchMultiple(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  const {
    formSteps: { isEditField, activeStep },
    userProfileEdit,
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write
  const getUserDetails = useSelector(({ userHome }) => userHome.userDetails);

  const {
    name,
    type = "text",
    label = "",
    rules,
    noValueText,
    options,
    stepNumber,
    InputProps,
    onChangeSelect,
    defaultValue,
    labelValue = "",
    gridMd = 4,
    disabled = false,
    className = "",
    isViewOnly = "",
    isAstricDisplay = false,
    isShowOld = false,
    RevisionDate = "",
    privId = "",
    customeStyle = "",
    multiple = false,
    filterSelectedOptions = false,
    readOnly,
    isRequiredMsg=''
  } = props;
/* Conflict Resolution Candidate

  const d = RevisionDate ? new Date(RevisionDate) : new Date();
  const revisionDate = `${t(t1, "REVISION_MADE_ON")} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
 */

  const [showPlaceHolder, setShowPlaceHolder]= useState(getValues(name)?.length);

  const d = new Date(RevisionDate);
  const revisionDate =
    RevisionDate && `${t(t1, "REVISION_MADE_ON")} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  let onlyRead = false;
  if (privId === "") {
    if (PrivAttributes?.[name]?.PrivRole === "NA") {
      return null;
    }
    onlyRead = PrivAttributes?.[name]?.PrivRole === "RO";
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === "NA") {
      return null;
    }
    onlyRead = PrivAttributes?.[privId]?.PrivRole === "RO";
  }

  if (rules && rules?.required && label) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }

  const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        padding: "1px 7px",
        border: "1px solid #000",
        borderRadius: "0px",
      },
    })
  );
/* Conflict Resolution Candidate
  return (
    <Grid item md={gridMd} className={className} sx={{ ...customeStyle,"& .MuiOutlinedInput-input": { color:  ['',undefined].includes(getValues(`${name}`)) ? "#ccc !important": '#333' } }}>
 */

  return (
    <Grid item md={gridMd} className={className} sx={{ ...customeStyle }}>
      {label ? (
        <InputLabel disabled={disabled} htmlFor="customize-select" sx={Style.FieldLabel}>
          {label}
          {rules?.required || isAstricDisplay ? (
            <Typography
              variant="body2"
              sx={{
                display: "inline-block",
                color: "common.red",
                ml: 0.3,
                fontWeight: 700,
              }}
            >
              *
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      {(!isEditField[stepNumber] && activeStep === 4) || isViewOnly || onlyRead ? (
        <Typography variant="body2" sx={Style.valuetext}>
          {getValues(name) || "N/A"}
        </Typography>
      ) : (
        <Controller
          name={name}
          type={type}
          rules={rules}
          control={control}
          render={({ field: { onChange, onBlur, value, ...field }, fieldState }) => (
            <>
              <Autocomplete
                PopperComponent={CustomPopper}
                clearText=""
                multiple
                filterSelectedOptions
                closeText=""
                openText=""
                disableClearable
                disabled={disabled}
               defaultValue={multiple?getValues(name): options[0]}
                getOptionDisabled={(option) => option.value === ""}
                // value={options?.find((item) => {
                //   if (getValues(`${name}`)) {
                //     return item.name === getValues(`${name}`);
                //   }
                //   return options[0];
                // })}
                id="country-select-demo"
                sx={Style.formField}
                options={options.filter((item)=> {
                  const values = Array.isArray(getValues(name)) &&  getValues(name)?.map(item=>item.value) 
                  return ( isEmpty(values) || !isEmpty(values) && Array.isArray(values) && !values?.includes(item.value))                
                })}
                // options={options}
                onBlur={onBlur}
                readOnly={readOnly}
                size="large"
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(fieldState.error)}
                    {...params}
                    placeholder={showPlaceHolder ? "" : ` ${noValueText}`}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(_, data) => {
                  setShowPlaceHolder(data?.length);
                  onChange(data);
                  onChangeSelect(data)
                  return data;
                }}
                {...field}
                // onInputChange={(_,data) =>{
                //   onChange(data.value);
                //   return data.name;
                // }}
              />
              {/* {fieldState.isTouched && rules?.required && value === "" ? (
                <FormHelperText error>{`${label} ${t(t1, "IS_REQUIRED")}`}</FormHelperText>
              ) : null} */}
              {rules && (
                <FormHelperText error>
                  {/* Conflict Resolution Candidate
                  {fieldState?.error ? fieldState?.error?.message : " "}
                  */}
                  {fieldState.isTouched && rules?.required && value === "" ? `${t(t1, isRequiredMsg)}` : " "}
                </FormHelperText>
              )}
            </>
          )}
        />
      )}
      {isViewOnly && isShowOld && (
        <StyledTooltip
          title={
            <Typography color="black" sx={{ fontSize: "14px" }}>
              {revisionDate}
            </Typography>
          }
        >
          <Box mt={1 / 4} sx={Style.updatedFieldBox}>
            <ErrorIcon sx={{ color: "#4d8da4", fontSize: "20px", mx: 1 / 4 }} />
            <Box sx={Style.coloredBox}>
              <Typography
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {isShowOld}
              </Typography>
            </Box>
          </Box>
        </StyledTooltip>
      )}
    </Grid>
  );
}