import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  uploadSuccess,
  addUpdateLabels,
  getAllData,
  getNewLanguage,
  publishType,
  getAllLabels,
  getFormLabels,
  getAllPageLabels,
  defaultORGID,
  enLanguage,
  publishSavedData,
  getAllLang,
  docFilesPageName,
  LandingPageName,
  searchInOrg,
  APIS,
  ERROR,
  SUCCESS,
  DEFAULT_LANGUAGE_CODE,
} from "utils/constants";
import fetcher from "utils/fetcher";
import { checkIsAuthenticated } from "./authSlice";
import { setModelMessage, setLoader } from "./commonSlice";

const initialState = {
  config: {},
  cmsConfig: {},
  cmsLanguagePage: {},
  cmsLandingConfig: {},
  cmsDocConfig: {},
  defaultFileUrl: {},
  defaultImageUrl: {},
  fileUploadUrl: {},
  s3UploadedImg: {},
  fileUploaded: {},
  cmsFileDeleted: {},
  fileUploadedState: 0,
  fileUrlUploaded: {},
  jurisdictionPLStyle: {},
  notificationSettingsConfig: {}
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfigData: (state, action) => {
      state.config = action.payload;
    },
    setCMSConfigData: (state, action) => {
      state.cmsConfig = action.payload;
    },
    setCMSLandingConfigData: (state, action) => {
      state.cmsLandingConfig = action.payload;
    },
    setCMSDocConfigData: (state, action) => {
      state.cmsDocConfig = action.payload;
    },
    setCMSLanguagePageData: (state, action) => {
      state.cmsLanguagePage = action.payload;
    },
    setDefaultFileUrl: (state, action) => {
      state.defaultFileUrl = action.payload;
    },
    setDefaultImageUrl: (state, action) => {
      state.defaultImageUrl = action.payload;
    },
    setFileUploadUrl: (state, action) => {
      state.fileUploadUrl = action.payload;
    },
    setUploadedImgToServer: (state, action) => {
      state.s3UploadedImg = action.payload;
    },
    setFileUploaded: (state, action) => {
      state.fileUploaded = action.payload;
    },
/* Conflict Resolution Candidate
    setInitialFileUploadedState: (state, action) => {
      state.fileUploadedState = 0;
    },
    setFileUploadedState: (state, action) => {
      state.fileUploadedState += 1;
    },
    setFileDeletedState: (state, action) => {
      state.fileUploadedState -= 1;
    },
 */
    setFileUploadedState: (state, action) => {
      state.fileUploadedState += 1;
    },
    setFileUrlUploaded: (state, action) => {
      state.fileUrlUploaded = action.payload;
    },
    setCmsFileDeleted: (state, action) => {
      state.cmsFileDeleted = action.payload;
    },
    setJurisdictionPLStyle: (state, action) => {
      state.jurisdictionPLStyle = {...state.jurisdictionPLStyle, ...action.payload};
    },
    setNotificationSettingsConfig: (state, action) => {
      state.notificationSettingsConfig = action.payload;
    }
  },
});

export default configSlice.reducer;
export const {
  setConfigData,
  setCMSConfigData,
  setCMSLandingConfigData,
  setDefaultFileUrl,
  setCMSLanguagePageData,
  setCMSDocConfigData,
  setFileUploadUrl,
  setUploadedImgToServer,
  setFileUploaded,
/* Conflict Resolution Candidate
  setInitialFileUploadedState,
  setFileUploadedState,
  setFileDeletedState,
 */
  setFileUploadedState,
  setFileUrlUploaded,
  setCmsFileDeleted,
  setDefaultImageUrl,
  setJurisdictionPLStyle,
  setNotificationSettingsConfig
} = configSlice.actions;

export function getConfigurations(jurisdiction, isPublic = false) {
  jurisdiction = jurisdiction && jurisdiction.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true));
    // const endPoint = isPublic ? "publicconfig/config" : "config/config"
    fetcher(`publicconfig/config?OrgID=${jurisdiction}`, "GET", {}, false)
      .then((response) => {
        dispatch(setLoader(false));
        // console.log('res',response);
        dispatch(setConfigData(response));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        
      });
  };
}

export function SubmitConfigurations(data, jurisdiction, accountConfig) {
  jurisdiction = jurisdiction.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`${APIS.CONFIG}?OrgID=${jurisdiction}`, "PUT", accountConfig, false)
      .then(() => {
       dispatch(SubmitHomeConfigurations(data, jurisdiction, false, ()=> {
         dispatch(getConfigurations(jurisdiction));
       }))
      })
      .catch((error) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function SubmitHomeConfigurations(data, jurisdiction, isForEmailLanguagePref, callback = '') {
  jurisdiction = jurisdiction.replace("#", "%23");
  return (dispatch) => {
    if (!isForEmailLanguagePref) dispatch(setLoader(true));
    fetcher(`config/config?OrgID=${jurisdiction}${isForEmailLanguagePref ? '&JurisdictionEmail=true' : ''}`, "PUT", data, false)
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        dispatch(setLoader(false));
        dispatch(setModelMessage(data));
        if (callback) callback();
      })
      .catch((err) => {
        if (callback) callback(true);
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
/* Conflict Resolution Candidate
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
 */
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

const cmsDetailsEndPointFetcher = () => checkIsAuthenticated() ? "cms/v2/api/details" : "cms/v2/public/api/details"
// ------------------  Language and Localization Page ------------------
export function getCMSDetails(params, isLoader = false) {
  return (dispatch) => {
    const endPoint = cmsDetailsEndPointFetcher()
    // console.log([getNewLanguage, publishType, getAllPageLabels].includes(params.QueryType));
    if ([getNewLanguage, publishType, getAllPageLabels].includes(params.QueryType)) {
      dispatch(setLoader(true));
    }
    fetcher(`${endPoint}`, "POST", {
      ...params,
      LanguageCode : localStorage.getItem('languageCode') || DEFAULT_LANGUAGE_CODE,
    }, false)
      .then((response) => {
        dispatch(setLoader(false));
        if ([getAllLang, getAllData]?.includes(params?.QueryType)) {
          const newPages = [];
          if (response?.data?.Pages && response?.data?.Pages.length > 0) {
            const pages = response?.data?.Pages;
            // eslint-disable-next-line no-restricted-syntax
            for (const k in pages) {
              if (pages[k].SK !== "Page#Page_LandingPageKnown") {
                newPages.push(pages[k]);
              }
            }
          }
          const newResponse = { ...response, data: { ...response.data, Pages: newPages } };
          dispatch(setCMSLanguagePageData(newResponse));
        } else if (![addUpdateLabels, publishType].includes(params.QueryType)) {
/* Conflict Resolution Candidate
          if (!params?.Clone) {
            dispatch(setCMSConfigData(response));
          }
 */
          dispatch(setCMSConfigData(response));
        }

        if ([getNewLanguage, publishType].includes(params.QueryType) || isLoader) {
          const data = {
            title: SUCCESS,
            message1: response.message,
            error: false,
          };
          dispatch(setModelMessage(data));
          // dispatch(setLoader(false));
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        if (params?.QueryType === searchInOrg) {
          dispatch(setCMSConfigData({}));
        }
        // dispatch(setCMSLanguagePageData({}));
        const data = {
          title: ERROR,
/* Conflict Resolution Candidate
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
 */
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        if ([getNewLanguage, publishType].includes(params.QueryType)) {
          if (params.QueryType === getNewLanguage) {
            data.message1 = err?.response?.data?.message
          }
          dispatch(setModelMessage(data));
        }
      });
  };
}

// ------------------  Landing Page ------------------
export function getCMSLandingDetails(params, methodType = "POST") {
  let getParams = "";
  return (dispatch) => {
    const endPoint = cmsDetailsEndPointFetcher()

    dispatch(setLoader(true));

    if ([addUpdateLabels, publishSavedData].includes(params.QueryType)) {
      dispatch(setLoader(true));
    }
    if (params.QueryType === addUpdateLabels) {
      getParams = params;
    }
    fetcher(`${endPoint}`, methodType, {
      ...params,
      LanguageCode : localStorage.getItem('languageCode') || DEFAULT_LANGUAGE_CODE,
    }, false)
      .then((response) => {
        dispatch(setLoader(false));
        // console.log(response?.data);
        if (params?.QueryType === getAllLabels) {
          dispatch(setLoader(false));
          dispatch(setCMSLandingConfigData(response));
          // dispatch(setCMSLandingConfigData(response?.data?.filter((eachFile) => [
          //   'ANNOUNCEMENTS',
          //   'HEADER_IMAGE_TXT',
          //   'WELCOME',
          //   'HOME_MAIN_CONTENT',
          //   'NOTICES',
          //   'JURISDICTION_SPECIFIC_CONTENT',
          //   'TERMS_AND_CONDITIONS_CONTENT',
          // ]?.includes(eachFile?.SK?.split("#")?.pop()))));
        }
        if ([uploadSuccess, addUpdateLabels, publishSavedData].includes(params.QueryType)) {
          const data = {
            title: SUCCESS,
            message1: response.message,
            error: false,
          };
          dispatch(setLoader(false));
          if ([addUpdateLabels, publishSavedData].includes(params.QueryType)) dispatch(setModelMessage(data));

          dispatch(setFileUploaded(response));
          dispatch(setFileUploadedState());

          // dispatch(getCMSLandingDetails({ "OrgID": params.OrgID || "ORG#00101", "PageLanguage": enLanguage(), Page: 'Page_LandingPageKnown', "QueryType": getAllLabels }));
          // dispatch(getFileUrl({ "OrgID": params.OrgID || "ORG#00101", "PageLanguage": enLanguage(), Page: 'Page_LandingPageKnown', "QueryType": getFormLabels }));
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        
        const data = {
           title: ERROR,
/* Conflict Resolution Candidate
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
 */
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        // dispatch(setModelMessage(data));
      });
  };
}

export function getFileUrl(params) {
  return (dispatch) => {
    const endPoint = cmsDetailsEndPointFetcher()
    dispatch(setLoader(true))
    fetcher(`${endPoint}`, "POST", {
      ...params,
      LanguageCode : localStorage.getItem('languageCode') || DEFAULT_LANGUAGE_CODE,
    }, false)
      .then((response) => {
        dispatch(setLoader(false))
        if (params.Page === LandingPageName) {
          dispatch(setDefaultImageUrl(response));
        } else {
          dispatch(setDefaultFileUrl(response));
          // console.log(response);
        }

      })
      .catch((err) => {
        dispatch(setLoader(false))
        
        if (params.Page === LandingPageName) {
          dispatch(setDefaultImageUrl({}));
        } else {
          dispatch(setDefaultFileUrl({}));
        }

        const data = {
           title: ERROR,
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        // dispatch(setModelMessage(data));
      });
  };
}

export function getFileUploadUrl(params, formData, fileDataObj, labelName) {
  return (dispatch) => {
    const endPoint = cmsDetailsEndPointFetcher()
    let contentType;
    fetcher(`${endPoint}`, "POST", params, false)
      .then((response) => {
        dispatch(setFileUploadUrl(response));
        // console.log(params, fileDataObj);
        if (fileDataObj && response?.data?.url) {
          // To upload Image to aws s3 server
          // const s3ImgPayload = {
          //   "data": response?.data?.fields,
          //   "file": fileDataObj,
          // }

          // Append each key and value from response.fields
          Object?.entries(response?.data?.fields)?.forEach((key) => {
            // console.log(key);
            if (key?.[0] !== 'Content-Type')
              formData.append(key?.[0], key?.[1]);
            else
              contentType = key?.[1]
          });
          formData.append("file", fileDataObj);

          const fileUploadPayload = {
            QueryType: uploadSuccess,
            file_list: [
              {
                OrgID: params?.OrgID,
                PageLanguage: params?.PageLanguage,
                FileType: params?.FileType,
                object_name: response?.object_name,
                LabelName: params?.LabelName,
                NotifyUser: params?.NotifyUser,
                Publish: false,
                Page: params?.Page
              },
            ],
          };

          dispatch(UploadImgToServer(response?.data?.url, formData, fileUploadPayload, labelName, contentType));
        }
      })
      .catch((err) => {
        
        const data = {
           title: ERROR,
/* Conflict Resolution Candidate
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
 */
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        // dispatch(setModelMessage(data));
      });
  };
}

export function UploadImgToServer(api, serverFilePayload, fileUploadPayload, labelName, contentType) {
  return (dispatch) => {
    axios({ url: api, method: "POST", data: serverFilePayload, headers: { "Content-Type": contentType,'accept': contentType } })
      .then((response) => {
        // console.log(response?.data);
        if (labelName === "HEADER_IMAGE_TXT") {
          dispatch(setUploadedImgToServer(response?.data));
          dispatch(getCMSLandingDetails(fileUploadPayload));
        } else {
          // console.log(fileUploadPayload);
          dispatch(getCMSDocDetails(fileUploadPayload));
        }
      })
      .catch((err) => {
        
        const data = {
           title: ERROR,
/* Conflict Resolution Candidate
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
 */
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        // dispatch(setModelMessage(data));
      });
  };
}

export function UploadMultipleFile(params) {
  return (dispatch) => {
    const endPoint = cmsDetailsEndPointFetcher()
    fetcher(`${endPoint}`, "POST", params, false)
      .then((response) => {
        // console.log(params, response);
        // dispatch(setFileUploadUrl(response));
      })
      .catch((err) => {
        
        const data = {
           title: ERROR,
/* Conflict Resolution Candidate
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
 */
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        // dispatch(setModelMessage(data));
      });
  };
}
// ------------------  Landing Page ------------------

// ------------------  Document and Attachments Page ------------------
export function getCMSDocDetails(params, isLoader = false) {
  return (dispatch) => {
    const endPoint = cmsDetailsEndPointFetcher()
    if ([getAllLabels, uploadSuccess, publishSavedData].includes(params.QueryType)) {
      dispatch(setLoader(true));
    }
    fetcher(`${endPoint}`, "POST", params, false)
      .then((response) => {
        dispatch(setLoader(false));
        if ([publishSavedData].includes(params.QueryType)) {
          const data = {
            title: SUCCESS,
            message1: response.message,
            error: false,
          };
          dispatch(setModelMessage(data));
          dispatch(setLoader(false));
        }

        if ([getAllLabels].includes(params.QueryType)) {
          dispatch(
            setCMSDocConfigData(
              response?.data?.filter((eachData) =>
                ['SUBMITTED_DOCUMENT_TITLE_FIRST',
                  'SUBMITTED_DOCUMENT_TITLE_THIRD',
                  'TERMS_AND_CONDITIONS_TEXT',
                  'ATTESTATION_FORM',
                  'WCI_TERMS_OF_USE',
                  'WCI_PRIVACY_POLICY'
                ]?.includes(eachData?.SK?.split("#")?.pop())
              )
            )
          );

          const data = {
            title: SUCCESS,
            message1: response.message,
            error: false,
          };
          // dispatch(setModelMessage(data));
          dispatch(setLoader(false));
        }

        if ([uploadSuccess].includes(params.QueryType)) {
          const data = {
            title: SUCCESS,
            message1: response.message,
            error: false,
          };
          dispatch(setLoader(false));
          dispatch(setModelMessage(data));

          dispatch(setFileUploaded(response));
          dispatch(setFileUploadedState());

          dispatch(
            getCMSDocDetails({
              OrgID: params.OrgID || defaultORGID,
              PageLanguage: enLanguage(),
              Page: docFilesPageName,
              QueryType: getAllLabels,
            })
          );
/* Conflict Resolution Candidate
          dispatch(getFileUrl({ "OrgID": params.OrgID || defaultORGID, "PageLanguage": enLanguage(), Page: 'Page_DocFiles', "QueryType": getFormLabels }));
 */
          // dispatch(getFileUrl({ "OrgID": params.OrgID || defaultORGID, "PageLanguage": enLanguage(), Page: 'Page_UserProfile', "QueryType": getFormLabels }));
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
           title: ERROR,
/* Conflict Resolution Candidate
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
 */
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        // dispatch(setModelMessage(data));
      });
  };
}

export function UploadFileUrl(params) {
  return (dispatch) => {
    const endPoint = cmsDetailsEndPointFetcher()
    // dispatch(setLoader(true));
    fetcher(`${endPoint}`, "POST", params, false)
      .then((response) => {
        dispatch(setLoader(false));
        dispatch(setFileUrlUploaded(response));
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        dispatch(setModelMessage(data));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
           title: ERROR,
/* Conflict Resolution Candidate
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
 */
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        // dispatch(setModelMessage(data));
      });
  };
}

export function deleteCmsFile(params) {
  return (dispatch) => {
    const endPoint = cmsDetailsEndPointFetcher()
    dispatch(setLoader(true));
    fetcher(`${endPoint}`, "POST", params, false)
      .then((response) => {
        dispatch(setLoader(false));
        dispatch(setCmsFileDeleted(response));
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false,
        };
        const getDocLabelPayload = {
          OrgID: params?.OrgID,
          QueryType: getAllLabels,
          PageLanguage: enLanguage(),
          Page: docFilesPageName,
        };
        const getDocFilePayload = {
          OrgID: params?.OrgID,
          QueryType: getFormLabels,
          PageLanguage: enLanguage(),
          Page: docFilesPageName,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
        dispatch(getCMSDocDetails(getDocLabelPayload));
        dispatch(getFileUrl(getDocFilePayload));
        // dispatch(setFileDeletedState());
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
           title: ERROR,
/* Conflict Resolution Candidate
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        dispatch(setModelMessage(data));
 */
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        // dispatch(setModelMessage(data));
      });
  };
}

export function getJurisdictionPLStyle(jurisdiction,orgName) {
  jurisdiction = jurisdiction && jurisdiction.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`publicconfig/config?OrgID=${jurisdiction}`, "GET", {}, false)
      .then((response) => {
        dispatch(setLoader(false));
        const obj={[orgName] :response["purchase-limit-settings"]?.purchase_limit_style?.AttributeValue};
        dispatch(setJurisdictionPLStyle(obj));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        
      });
  };
}

// Fetches notification settings roles and their associated email details
export function getNotificationSettingsConfig(jurisdictionId) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`roles/api/get-ju-emails?jud=${jurisdictionId}`, "GET", {}, false)
      .then((response) => {
        dispatch(setLoader(false));
        dispatch(setNotificationSettingsConfig(response?.data?.result));
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
}

// Submits updated/edited notification settings roles and associated email details
export function submitNotificationSettingsConfig(jurisdictionId, params) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`roles/api/update-ju-emails?jud=${jurisdictionId}`, "POST", params, false)
      .then((response) => {
        dispatch(setNotificationSettingsConfig(params));
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
        };
        dispatch(setLoader(false));
        dispatch(setModelMessage(data));
      })
      .catch((error) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: error?.response?.data?.messageKey,
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}