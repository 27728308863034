import {
  Box,
  Divider,
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  MenuItem,
  ListItemText,
  Modal,
  TextField,
  ListSubheader,
  Select,
  Tabs,
  Tab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormCard, GridSelectSearch, GridTextfield, ModelMessage } from "components";
import { useForm, FormProvider } from "react-hook-form";
import {
  getRolesDetails,
  addRoleType,
  getRoles,
  setSelectedRoleTypeFilter,
  setSelectedRolesFilter,
  getPageRoleDetail,
  searchPageControl,
} from "store/privilegeSlice";
import { useTheme } from "@emotion/react";

import { CustomizeTextfield } from "components/CustomizeTextfield";
import { CustomizeListItemCheckbox } from "components/CustomizeListItemCheckbox";
import { GridSelectCheckbox } from "components/GridSelectCheckbox";
import { CustomizeLinks } from "components/CustomizeLinks";
import { useAuthentication } from "hooks/useAuthentication";
import { CustomizeButton } from "components/CustomizeButton";
import { setModelMessage } from "store/commonSlice";
import { useEffectOnce } from "hooks/useEffectOnce";
import { setIsWciAdmin } from "store/authSlice";

import { allowCommaDot, t, getCurrentPageName, formatDate, ShowInlineAddressForTable, WCIIncAdmin } from "utils/constants";
import { TabPanel } from "@mui/lab";
import { MUITableGrid } from "components/MUITableGrid";
import { getSearchUserDetails } from "store/userSlice";
import { Loader } from "components/Loader";
import { PrivilegeHeader } from "./components/PrivilegeHeader";

import Style from "./styles";

function PrivilegeManagement() {
  useAuthentication({ redirectTo: "/privilege" });
  const textSearchRef = useRef(null);
  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      PageName: "",
      priv_txt_SearchUser: "",
    },
  });

  const methodsRoleType = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const {
    reset,
    watch,
    setError,
    handleSubmit,
    clearErrors,
    resetField,
    formState: { errors, isSubmitting },
  } = methods;

  const searchUserWatch = watch("priv_txt_SearchUser", "");
  const searchPageWatch = watch("PageName", "");

  // const {
  //   reset,
  //   watch,
  //   setError,
  //   handleSubmit,
  //   clearErrors,
  //   resetField,
  //   formState: { errors, isSubmitting },
  // } = methodsRoleType;

  const dispatch = useDispatch();
  const { t: t1 } = useTranslation(getCurrentPageName());
  const navigate = useNavigate();
  const theme = useTheme();
  const themeColor = theme?.palette?.primary?.main;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const { user, userRole, isWciAdmin } = useSelector(({ auth }) => auth);
  const { modelMessage } = useSelector(({ common }) => common);
  const { PrivAttributes } = user;
  const { roles, isBlankRole, Pages, PageControls, loading } = useSelector(({ privilege }) => privilege);
  const [isModalOpen, setModalOpen] = useState(false);
  const { selectedJurisdiction, Jurisdictions } = useSelector(({ jurisdiction }) => jurisdiction);
  const [selected, setSelected] = useState([t(t1, "PM_MENU_SELECT_ROLE")]);
  const [roleTypeSelected, setRoleTypeSelected] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [Userrows, setUserrows] = useState([]);
  const [controlrows, setControlrows] = useState([]);
  // const [displayTable, setDisplayTable] = useState(false);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { searchUserDetails, searchPendingUserDetails } = useSelector(({ user }) => user);
  const userRows = [];
  const ControlsRows = [];
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const pageData = [{ name: `${t(t1, "SELECT_PAGE")}...`, value: "" }];

  // const watchPage = watch('PageName', '');

  if (Pages.Items?.length > 0) {
    Pages.Items?.map((item) => {
      pageData.push({ name: item?.SK, value: item?.SK });
      return pageData;
    });
  }

  function SelectRenderValue(selected) {
    if (selected[1]) {
      selected = selected.slice(1);
      selected = selected.join(", ");
      return selected;
    }
    return t(t1, "PM_MENU_SELECT_ROLE");
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  useEffect(() => {
    dispatch(getPageRoleDetail());
    const data = {};
    data.OrgID = selectedJurisdiction;
    dispatch(getSearchUserDetails(data));
    // dispatch(getRolesDetails(typeof selectedJurisdiction === "object" ? "" : selectedJurisdiction));
    if (
      PrivAttributes?.priv_lnkbtn_SysDefaultRoles?.PrivRole === "RWX" &&
      selectedJurisdiction?.replace("#", "%23") === process.env.REACT_APP_DEFAULT_ORGID
    ) {
      dispatch(setIsWciAdmin(true));
      dispatch(getRoles());
    } else {
      dispatch(getRoles(selectedJurisdiction));
      dispatch(setIsWciAdmin(false));
    }
  }, [PrivAttributes?.priv_lnkbtn_SysDefaultRoles?.PrivRole, dispatch]);

  let routesList = [];
  const routesOtherList = [
    { to: "/roles", name: t(t1, "PM_LINK_COPY_SYSTEM_DEFAULT_ROLES"), readOnly: false },
    { to: "/", name: t(t1, "PM_LINK_MANAGE_ROLES"), readOnly: false },
  ];
  routesList = routesOtherList;
  let defalutName = t(t1, "PM_LINK_COPY_SYSTEM_DEFAULT_ROLES");
  if (userRole === "ROLE#00104") {
    defalutName = t(t1, "PM_MENU_DEFAULT_ROLE");
  }

  const routesAdminList = [
    {
      to: "/roles",
      name: t(t1, "PM_LINK_COPY_SYSTEM_DEFAULT_ROLES"),
      isModal: false,
      id: "role",
      Access: isBlankRole === true ? PrivAttributes?.priv_lnkbtn_CopysystemRole?.PrivRole : "NA",
    },
    {
      to: "/roles",
      name: t(t1, "PM_MENU_DEFAULT_ROLE"),
      isModal: false,
      id: "role",
      Access: isWciAdmin ? PrivAttributes?.priv_lnkbtn_SysDefaultRoles?.PrivRole : "NA",
    },
    {
      to: "/roles",
      name: t(t1, "PM_LINK_MANAGE_ROLES"),
      isModal: false,
      id: "role",
      Access: isBlankRole === false ? PrivAttributes?.priv_lnkbtn_ManageRole?.PrivRole : "NA",
    },
    {
      to: "/",
      name: t(t1, "PM_MENU_CREATE_ROLE_TYPE"),
      isModal: true,
      id: "roleType",
      Access: PrivAttributes?.priv_lnkbtn_CreateNewRoleType?.PrivRole,
    },
    {
      to: "/create-role-details",
      name: t(t1, "PM_MENU_CREATE_ROLE"),
      isModal: false,
      id: "role",
      Access: PrivAttributes?.priv_lnkbtn_CreateNewRole?.PrivRole,
    },
    {
      to: "/",
      name: t(t1, "PM_MENU_CREATE_PAGE"),
      isModal: false,
      id: "role",
      Access: PrivAttributes?.priv_lnkbtn_CreateNewPage?.PrivRole,
    },
    {
      to: "/",
      name: t(t1, "PM_MENU_CREATE_CONTROL"),
      isModal: false,
      id: "role",
      Access: PrivAttributes?.priv_lnkbtn_CreateNewControl?.PrivRole,
    },
  ];
  routesList = routesAdminList;

  const handleModalToggle = (e, state, data, id) => {
    e.preventDefault();
    if (state === "open" && id === "roleType") {
      methodsRoleType.resetField("roleTypeName");
      methodsRoleType.resetField("roleTypeDescription");
      setModalOpen(true);
    }
    if (e.target.tagName === "svg" || e.target.tagName === "path") {
      setModalOpen(false);
    }
  };

  const handleReset = () => {
    resetField("priv_txt_SearchUser");
    resetField("priv_txt_RoleTypeName");
    resetField("priv_chklst_RoleTypes");
    resetField("priv_txt_RoleTypeDesc");
    setSelected([]);
    setRoleTypeSelected([]);
    reset({ priv_chklst_RoleTypes: false });
  };
  const handleSearch = (val) => {
    // setDisplayTable(true)
    // if(selectedTab === 0) {
    //   const data = {};
    //   data.OrgID = selectedJurisdiction;
    //   dispatch(getSearchUserDetails(data));
    //   // dispatch(setSelectedRoleTypeFilter(roleTypeSelected));
    //   // dispatch(setSelectedRolesFilter(selected));
    //   // navigate("/roles");
    // }
    // else {
    //   const data = {};
    //   data.OrgID = selectedJurisdiction;
    //  // data.PageName =
    // }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === roles.length ? [] : roles);
      return;
    }
    setSelected(value);
  };

  useEffect(() => {
    if (modelMessage.title === t(t1, "SUCCESS")) {
      dispatch(setSelectedRoleTypeFilter([]));
      dispatch(setSelectedRolesFilter([]));
      navigate("/roles");
    }
  }, [modelMessage]);

  const submit = async (value) => {
    const data = {};
    data.name = value.roleTypeName;
    data.description = value.roleTypeDescription;
    data.org_id = selectedJurisdiction.replace("%23", "#");
    data.title = t(t1, "SUCCESS");
    dispatch(addRoleType(data));
    setModalOpen(false);
  };

  const searchSubmit = (value) => {
    // console.log('value', value);
    if (selectedTab === 0) {
      const data = {};
      data.OrgID = selectedJurisdiction;
      data.PageName = value.PageName;
      dispatch(searchPageControl(data));
      // dispatch(setSelectedRoleTypeFilter(roleTypeSelected));
      // dispatch(setSelectedRolesFilter(selected));
      // navigate("/roles");
    } else {
      searchUserDetails?.items?.map((item, key) => {
        const jurisName = Jurisdictions.find((each, index) => each.OrgID === item?.OrgID);
        // console.log('item?.RoleDetail?.Role', item?.RoleDetail?.Role)
        const role = item?.RoleDetail?.Role?.find(
          (each, index) => each.name?.toLowerCase() === value.priv_txt_SearchUser?.toLowerCase()
        );
        // console.log('role', role);
        if (
          item?.Name?.toLowerCase() === value.priv_txt_SearchUser?.toLowerCase() ||
          item?.FamilyName?.toLowerCase() === value.priv_txt_SearchUser?.toLowerCase() ||
          item?.ReferenceCode?.toLowerCase() === value.priv_txt_SearchUser?.toLowerCase() ||
          role
        )
          userRows.push({
            id: item?.UID?.split("#")[1],
            Name: item?.Name,
            FamilyName: item?.FamilyName,
            jurisdiction: jurisName?.OrgName,
            Email: item?.Email,
            birthDate: formatDate(item?.BirthDate),
            UserStatus: item?.UserAccountStatus,
            RoleName: item?.RoleDetail?.Role?.map((eachRole) => eachRole.name).join(", "),
            AccountStatus: item?.ApplicantAccountStatus,
            ReferenceCode: item?.ReferenceCode,
            Employer: item?.Employer,
            UserID: item?.Username,
            RequestStatus: item?.RequestStatus,
            RoleType: item?.RoleDetail?.RoleType?.map((eachRole) => eachRole.name).join(", "),
            RegStartDate: formatDate(item?.RegistrationDate),
            PhysicalAddress: ShowInlineAddressForTable(item, t, t1),
          });
        return Userrows;
      });
      setUserrows(userRows);
    }

    // value.preventDefault();
  };
  const menuItems = [];

  roles?.forEach((role) => {
    menuItems.push(
      <ListSubheader key={role.id} disableSticky>
        {role.name}
      </ListSubheader>
    );
    role?.roles.forEach((roleType) => {
      menuItems.push(
        <MenuItem key={roleType.name} value={roleType.name}>
          <Checkbox checked={selected.indexOf(roleType.name) > -1} />
          <ListItemText primary={roleType.name} />
        </MenuItem>
      );
    });
  });

  const handleCheckboxChange = (e) => {
    let checkedArray = [...roleTypeSelected];
    if (e.target.checked) {
      checkedArray.push(e.target.value);
    } else {
      checkedArray = checkedArray.filter((employee) => employee !== e.target.value);
    }
    setRoleTypeSelected(checkedArray);
  };

  useEffect(() => {
    searchUserDetails?.items?.map((item, key) => {
      const jurisName = Jurisdictions.find((each, index) => each.OrgID === item?.OrgID);
      userRows.push({
        id: item?.UID?.split("#")[1],
        Name: item?.Name,
        FamilyName: item?.FamilyName,
        jurisdiction: jurisName?.OrgName,
        Email: item?.Email,
        birthDate: formatDate(item?.BirthDate),
        UserStatus: item?.UserAccountStatus,
        RoleName: item?.RoleDetail?.Role?.map((eachRole) => eachRole.name).join(", "),
        AccountStatus: item?.ApplicantAccountStatus,
        ReferenceCode: item?.ReferenceCode,
        Employer: item?.Employer,
        UserID: item?.Username,
        RequestStatus: item?.RequestStatus,
        RoleType: item?.RoleDetail?.RoleType?.map((eachRole) => eachRole.name).join(", "),
        RegStartDate: formatDate(item?.RegistrationDate),
        PhysicalAddress: ShowInlineAddressForTable(item, t, t1),
      });
      return Userrows;
    });
    setUserrows(userRows);
  }, [searchUserDetails]);

  useEffect(() => {
    if (PageControls && Object.keys(PageControls).length > 0)
      PageControls?.map((item, key) => {
        ControlsRows?.push({
          id: key,
          controls: item.controls,
          role_id: item.role_id,
          role_name: item.role_name,
          role_type: item.role_type,
          description: item.description,
          access: item.access,
        });
        setControlrows(ControlsRows);
        return ControlsRows;
      });
  }, [PageControls]);

  const searchUserColumns = [
    {
      id: 1,
      field: "ReferenceCode",
      headerName: t(t1, "REFERENCE_ID"),
      minWidth: 200,
      align: "center",
      flex: 1,
    },
    { id: 2, field: "Name", headerName: t(t1, "FIRST_NAME"), minWidth: 160, align: "center", flex: 1 },
    { id: 3, field: "FamilyName", headerName: t(t1, "LAST_NAME"), minWidth: 160, align: "center", flex: 1 },
    {
      id: 4,
      field: "RoleType",
      headerName: t(t1, "ROLE_TYPE"),
      minWidth: 200,
      align: "center",
      flex: 1,
    },
    {
      id: 5,
      field: "RoleName",
      headerName: t(t1, "ROLE"),
      minWidth: 200,
      align: "center",
      flex: 1,
    },
    {
      id: 6,
      field: "AccountStatus",
      headerName: t(t1, "ACCOUNT_STATUS"),
      minWidth: 200,
      align: "center",
      flex: 1,
    },
  ];

  const searchPageColumns = [
    { id: 1, field: "controls", headerName: t(t1, "CONTROL"), minWidth: 270, align: "center" },
    { id: 2, field: "description", headerName: t(t1, "DESCRIPTION"), minWidth: 310, align: "center" },
    {
      id: 3,
      field: "role_type",
      headerName: t(t1, "ROLE_TYPE"),
      minWidth: 270,
      align: "center",
    },
    {
      id: 4,
      field: "role_name",
      headerName: t(t1, "ROLE"),
      minWidth: 350,
      align: "center",
    },
    {
      id: 5,
      field: "access",
      headerName: t(t1, "ACCESS"),
      minWidth: 290,
      align: "center",
    },
  ];

  useEffect(() => {
    textSearchRef?.current?.focus();
  }, [searchUserWatch]);

  // Added condition to call search API as soon as page value is selected using enter/pointer click
  useEffect(() => {
    if (searchPageWatch) {
      searchSubmit({
        PageName: searchPageWatch
      })
    }
  }, [searchPageWatch]);

  return (
    <Container maxWidth="xl" sx={Style.spacing}>
      <Grid container spacing={2.5}>
        <PrivilegeHeader />
        <Grid item sm={12}>
          {roles?.length > 0 ? (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(searchSubmit)}>
                <FormCard indicateRequired={false} customeStyle={{ pb: "20px !important" }}>
                  <Box sx={Style.tabStyling} className="tabs-main">
                    <Typography variant="h6" sx={{ fontWeight: "300" }}>
                      {t(t1, "SEARCH_PRIV_HEADER")}
                    </Typography>
                    <Box sx={{ borderBottom: 1, borderColor: "#4d8da4 !important" }}>
                      <Tabs
                        sx={{
                          "& .MuiButtonBase-root": {
                            marginRight: "20px",
                            maxWidth: "250px",
                            fontWeight: 400,
                            // backgroundColor: '#e6eff2',
                            border: "1px solid #4d8da4",
                            // borderRadius: '5px 5px 0px 0px',
                            borderBottom: "none",
                            height: "40px",
                            color: "#4d8da4",
                            backgroundColor: "#e6eff2",
                            fontSize: "16px",
                            borderRadius: "8px 8px 0px 0px",
                            textTransform: "none",
                            padding: "8px 20px",
                            // "&:hover": { fontWeight: 600 },
                          },
                          "& .MuiButtonBase-root.Mui-selected": {
                            marginRight: "20px",
                            maxWidth: "250px",
                            fontWeight: 400,
                            backgroundColor: "#4d8da4",
                            border: "1px solid #4d8da4",
                            borderRadius: "8px 8px 0px 0px",
                            borderBottom: "none",
                            height: "40px",
                            color: "common.white",
                            fontSize: "16px",
                            textTransform: "none",
                            padding: "8px 20px",
                            // "&:hover": { fontWeight: 600 },
                          },
                        }}
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                      >
                        {PrivAttributes?.priv_tab_PagesAndControls?.PrivRole !== "NA" && (
                          <Tab label={t(t1, "PAGES_AND_CONTROLS")} />
                        )}
                        {PrivAttributes?.priv_tab_RoleAndUsers?.PrivRole !== "NA" &&
                          userDetails?.RoleDetail?.Role?.find((each, index) => each.name !== WCIIncAdmin) && (
                            <Tab label={t(t1, "ROLES_AND_USERS")} />
                          )}
                        {/* <Tab label="Entities" /> */}
                      </Tabs>
                    </Box>
                    {PrivAttributes?.priv_grid_searchUsers?.PrivRole !== "NA" && (
                      <TabPanel value={selectedTab} index={0}>
                        {PrivAttributes?.priv_select_Roles?.PrivRole !== "NA" && (
                          <Grid container sx={{ m: 0, p: 0 }}>
                            <Grid item md={6}>
                              {pageData?.length > 0 && (
                                <GridSelectSearch
                                  className="fc-select-rounded"
                                  name="PageName"
                                  privId="priv_select_page"
                                  noValueText={t(t1, "SELECT_PAGE")}
                                  labelValue={t(t1, "PAGE")}
                                  customeStyle={{
                                    height: "44px !important",
                                    mb: 0,
                                    pb: 0,
                                    "& .MuiOutlinedInput-input": { color: searchPageWatch ? "" : "#ccc !important" },
                                  }}
                                  options={pageData?.map(({ SK: value, SK: name, ...rest }) => ({
                                    value,
                                    name,
                                    ...rest,
                                  }))}
                                // rules={{ required: true }}
                                // InputProps={{
                                //   "aria-required": "true",
                                // }}
                                />
                              )}
                            </Grid>
                            <Grid item md={6}>
                              <div className="bottom-btn">
                                <CustomizeButton
                                  customStyle={{ height: "44px" }}
                                  variant="outlined"
                                  csName="rc"
                                  label={t(t1, "PM_BTN_RESET")}
                                  name="priv_btn_Reset"
                                  handleClick={handleReset}
                                />
                                <CustomizeButton
                                  disabled={!searchPageWatch}
                                  customStyle={{ mr: 0, height: "44px" }}
                                  variant="contained"
                                  type="submit"
                                  label={t(t1, "PM_BTN_SEARCH")}
                                  name="priv_btn_Search"
                                  handleClick={handleSearch}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </TabPanel>
                    )}

                    {PrivAttributes?.priv_grid_searchEntities?.PrivRole !== "NA" && (
                      <TabPanel value={selectedTab} index={1}>
                        <Grid container sx={{ m: 0, p: 0 }}>
                          <GridTextfield
                            refField={textSearchRef}
                            gridMd={6}
                            customeStyle={Style.FontWeight}
                            name={"priv_txt_SearchUser"}
                            label={t(t1, "PM_LBL_SEARCH_USER")}
                            placeholder={t(t1, "SEARCH_USER_PH")}
                            labelStyle={{display:"none"}}
                          />
                          <Grid item md={6}>
                            <div className="bottom-btn">
                              <CustomizeButton
                                customStyle={{ height: "44px" }}
                                variant="outlined"
                                csName="rc"
                                label={t(t1, "PM_BTN_RESET")}
                                name="priv_btn_Reset"
                                handleClick={handleReset}
                              />
                              <CustomizeButton
                                disabled={!searchUserWatch}
                                customStyle={{ mr: 0, height: "44px" }}
                                variant="contained"
                                type="submit"
                                label={t(t1, "PM_BTN_SEARCH")}
                                name="priv_btn_Search"
                                handleClick={handleSearch}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    )}
                  </Box>
                </FormCard>
                {selectedTab === 0 &&
                  PageControls &&
                  controlrows?.length > 0 &&
                  methods?.getValues("PageName") !== undefined && (
                    <Box sx={{ mt: 5 }}>
                      <FormCard
                        indicateRequired={false}
                        title={t(t1, "SEARCH_PAGE_CONTROL_TEXT")}
                        customeStyle={{ ".MuiTypography-h2": { marginBottom: "20px" } }}
                      >
                        <MUITableGrid
                          access={PrivAttributes?.priv_grid_SearchPageAndControls?.PrivRole}
                          rows={controlrows}
                          columns={searchPageColumns}
                          count={controlrows?.length}
                          isSearch
                          customTableStyle={Style.tableGridStyle}
                        />
                      </FormCard>
                    </Box>
                  )}
                {selectedTab === 1 &&
                  Userrows?.length > 0 &&
                  methods.getValues("priv_txt_SearchUser") !== undefined &&
                  methods.getValues("priv_txt_SearchUser") !== "" && (
                    <Box sx={{ mt: 5 }}>
                      <FormCard indicateRequired={false} title={t(t1, "SEARCH_ROLES_USERS_TEXT")}>
                        <MUITableGrid
                          access={PrivAttributes?.priv_grid_searchUsers?.PrivRole}
                          rows={Userrows}
                          columns={searchUserColumns}
                          count={Userrows?.length}
                          isSearch
                          customTableStyle={Style.tableGridStyle}
                        />
                      </FormCard>
                    </Box>
                  )}
              </form>
            </FormProvider>
          ) : null}
          <Grid item sm={12} mt={"40px"}>
            <Typography variant="h4">{t(t1, "MANAGEMENT")}</Typography>
            <CustomizeLinks
              name={"priv_lnkbtn_CreateNewRole"}
              readOnly={false}
              classes="managementlink"
              lists={routesList}
              handleClick={handleModalToggle}
            />
          </Grid>
        </Grid>
      </Grid>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={(e) => handleModalToggle(e)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Style.popupStyle}>
            <FormProvider {...methodsRoleType}>
              <form onSubmit={methodsRoleType.handleSubmit(submit)}>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: 24,
                    textTransform: "uppercase",
                    fontWeight: 600,
                    paddingLeft: "0",
                    position: "relative",
                    paddingTop: "px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <span>{t(t1, "PM_MENU_CREATE_ROLE_TYPE")}</span>
                    <div style={{ cursor: "pointer", position: "relative" }}>
                      <CloseIcon onClick={(e) => handleModalToggle(e)} />
                    </div>
                  </div>
                </Typography>
                <FormCard customClass="create-role-popup" indicateRequired={false} disableElevation>
                  <div style={{ marginBottom: "15px", maxWidth: "380px", maxHeight: "63px" }}>
                    <GridTextfield
                      name={"roleTypeName"}
                      privId={"priv_txt_RoleTypeName"}
                      label={t(t1, "PM_LBL_ROLETYPE_NAME_CONTROL")}
                      placeholder={t(t1, "PM_PH_ENTER_ROLETYPE_NAME")}
                      InputProps={{
                        "aria-required": "true",
                      }}
                      rules={{
                        required: true,
                        /* Conflict Resolution Candidate
                                                validate: (v) =>
                                                  methodsRoleType?.getValues("roleTypeName")
                                                    ? validateFullNameValues(
                                                        `${t(t1, "VALIDATION_MESSAGE")}`,
                                                        v,
                                                        `${t(t1, "PM_LBL_ROLETYPE_NAME_CONTROL")}`
                                                      )
                                                    : null,
                         */
                        pattern: {
                          value: allowCommaDot,
                          message: `${t(t1, "ERR_INVALID_PM_LBL_ROLETYPE_NAME_CONTROL")}`,
                        },
                        maxLength: {
                          value: 50,
                          message: `${t(t1, "ERR_PM_LBL_ROLETYPE_NAME_CONTROL_MAX_LENGTH_CHAR")}`,
                        },
                      }}
                      isRequiredMsg = {'ERR_PM_LBL_ROLETYPE_NAME_CONTROL_IS_REQUIRED'}
                      className="input-group"
                    />
                  </div>
                  <div style={{ marginBottom: "15px", maxWidth: "680px", maxHeight: "63px" }}>
                    <GridTextfield
                      name={"roleTypeDescription"}
                      privId={"priv_txt_RoleTypeDesc"}
                      placeholder={t(t1, `PM_PH_ENTER_ROLETYPE_DESC_NAME`)}
                      label={t(t1, "PM_LBL_ROLETYPE_DESCRIPTION_CONTROL")}
                      rules={{
                        required: true,
                        /* Conflict Resolution Candidate
                                                validate: (v) =>
                                                  methodsRoleType?.getValues("roleTypeDescription")
                                                    ? validateFullNameValues(
                                                        `${t(t1, "VALIDATION_MESSAGE")}`,
                                                        v,
                                                        `${t(t1, "PM_PH_ENTER_ROLETYPE_DESC_NAME")}`
                                                      )
                                                    : null,
                         */
                        pattern: {
                          value: allowCommaDot,
                          message: `${t(t1, "ERR_INVALID_PM_PH_ENTER_ROLETYPE_DESC_NAME")}`,
                        },
                      }}
                      isRequiredMsg ={'ERR_PM_LBL_ROLETYPE_DESCRIPTION_CONTROL_IS_REQUIRED'}
                    />
                  </div>
                  {PrivAttributes?.priv_btn_Save?.PrivRole !== "NA" && (
                    <Box sx={{ marginTop: "20px", textAlign: "right" }}>
                      {/* Conflict Resolution Candidate
                      <Button
                        type="submit"
                        disableElevation
                        disabled={methodsRoleType && !methodsRoleType?.formState?.isValid}
                        variant="contained"
                        sx={{ padding: "8px 50px" }}
                      >
                    */}
                      <Button type="submit" disableElevation variant="contained" sx={{ padding: "8px 50px" }}>
                        {t(t1, "BTN_CREATE")}
                      </Button>
                    </Box>
                  )}
                </FormCard>
              </form>
            </FormProvider>
          </Box>
        </Modal>
      )}
      {loading && <Loader loader={loading} />}
    </Container>
  );
}

export default PrivilegeManagement;
