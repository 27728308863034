import { createSlice } from "@reduxjs/toolkit";
import fetcher, { fetcherDelete, fetcherGet, fetcherPatch, fetcherPost } from "utils/fetcher";
import { APIS, BATCH_TRANSFER_STATUS } from "utils/constants";
import {
  BATCH_TRANSFER_COMPONENTS_FLP,
  PAGE_MANAGE_BATCH_TRANSFER,
  transformBatchTransferAccountDetails,
  transformCurrencyOptions,
  transformForPendingApproval,
  transformHoldings,
  transformHoldingsForTransfer,
  transformedJurisdictionAccountData,
} from "pages/ManageBatchTransfer/constants";
import _ from "lodash";
import { setLoader } from "./commonSlice";

const initialState = {
  initiateBatchTransfer: false,
  isBatchAccDetailsLoading: false,
  initiateTransferDetails: {
    transferType: {
      id: "",
      name: "",
    },
    selectedHoldings: [],
    juriAccountInformation: [],
  },
  batchDetails: {
    batchId: null,
    batchName: null,
    comment: "",
    Status: {
      ID: null,
      name: "ACTIVE",
    },
    createdBy: null,
  },
  accountHoldings: { isLoading: false, data: [] },
  selectedHolding: {},
  currencyList: [],
  openAddTransferModel: false,
  batchTransfers: {
    data: [],
    metadata: [],
  },
  batchReceivingAccounts: [],
  statuses: {},
  fileUploadErrors: [],
  selectedTransferIds: "",
  batchTransferHistory: [],
  proposedBatchList: [],
  isEditMode: {
    editBatchDetails: false,
    editSelectedHoldings: false,
    editTransferRecords: false,
  },
  transferTypeList: [],
  accountDetailPCRA: {},
  isSubmitting:false,
  flp: {
    [BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_LIST]: {
      metaData: [],
      fieldAccess: [],
    },
    [BATCH_TRANSFER_COMPONENTS_FLP.BATCH_ACCOUNT_DETAILS]: {
      metaData: [],
      fieldAccess: [],
    },
    [BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_ACCOUNT_DETAILS]: {
      metaData: [],
      fieldAccess: [],
    },
    [BATCH_TRANSFER_COMPONENTS_FLP.SELECTED_HOLDINGS_BATCH]: {
      metaData: [],
      fieldAccess: [],
    },
    [BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_HISTORY]: {
      metaData: [],
      fieldAccess: [],
    },
  },
};

const BatchTransferSlice = createSlice({
  name: "batchTransfer",
  initialState,
  reducers: {
    setInitiateBatchTransfer: (state, action) => {
      state.initiateBatchTransfer = action.payload;
    },
    setInitiateTransferDetails: (state, action) => {
      state.initiateTransferDetails = action.payload;
    },
    setAccountHoldings: (state, action) => {
      state.accountHoldings = action.payload;
    },
    setSelectedHoldings: (state, action) => {
      state.initiateTransferDetails.selectedHoldings = action.payload;
    },
    setjuriAccountInformation: (state, action) => {
      state.initiateTransferDetails.juriAccountInformation = [action.payload];
    },
    setSelectedHolding: (state, action) => {
      state.selectedHolding = action.payload;
    },
    setOpenAddTransferModel: (state, action) => {
      state.openAddTransferModel = action.payload;
    },
    setBatchDetails: (state, action) => {
      state.batchDetails = action.payload;
    },
    setBatchTransfers: (state, action) => {
      state.batchTransfers = action.payload;
    },
    resetBatchInformation: (state) => {
      state.batchDetails = initialState.batchDetails;
      state.batchReceivingAccounts = [];
    },
    setBatchReceivingAccount: (state, action) => {
      state.batchReceivingAccounts = action.payload;
    },
    setCurrencyList: (state, action) => {
      state.currencyList = action.payload;
    },
    setStatuses: (state, action) => {
      state.statuses = { ...action.payload };
    },
    setFileUploadErrors: (state, action) => {
      state.fileUploadErrors = action.payload;
    },
    setSelectedTransferIds: (state, action) => {
      state.selectedTransferIds = action.payload;
    },
    setBatchTransferHistory: (state, action) => {
      state.batchTransferHistory = action.payload;
    },
    setProposedBatchList: (state, action) => {
      state.proposedBatchList = action.payload;
    },
    setIsEditMode: (state, action) => {
      const { key, value } = action.payload;
      state.isEditMode[key] = value;
    },
    resetEditMode:(state)=>{
      state.isEditMode= initialState.isEditMode
    },
    setTransferTypeList: (state, action) => {
      state.transferTypeList = action.payload;
    },
    setTransferType: (state, action) => {
      state.initiateTransferDetails.transferType = action.payload;
    },
    setAccountDetailPCRA: (state, action) => {
      state.accountDetailPCRA = action.payload;
    },
    setIsBatchAccDetailsLoading: (state, action) => {
      state.isBatchAccDetailsLoading = action.payload;
    },
    setIsSubmitting:(state,action)=>{
      state.isSubmitting=action.payload
    },
    setFLP: (state, action) => {
      const { key, value } = action.payload;
      state.flp[key] = value;
    },
  },
});

export const {
  setInitiateBatchTransfer,
  setInitiateTransferDetails,
  setAccountHoldings,
  setSelectedHoldings,
  setSelectedHolding,
  setOpenAddTransferModel,
  setBatchDetails,
  setjuriAccountInformation,
  setBatchTransfers,
  resetBatchInformation,
  setBatchReceivingAccount,
  setCurrencyList,
  setStatuses,
  setFileUploadErrors,
  setSelectedTransferIds,
  setBatchTransferHistory,
  setProposedBatchList,
  setIsEditMode,
  setTransferTypeList,
  setTransferType,
  setAccountDetailPCRA,
  setFLP,
  resetEditMode,
  setIsSubmitting,
  setIsBatchAccDetailsLoading
} = BatchTransferSlice.actions;
export default BatchTransferSlice.reducer;

export function getAccountHoldings(accNo) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const pageDetails = { pageName: "Page_ComplianceAccountDetails", componentName: "holdings" };
      const response = await fetcher(`${APIS.HOLDINGS_API}/${accNo}`, "GET", {}, false, pageDetails);
      dispatch(setAccountHoldings({ isLoading: false, data: transformHoldings(response?.data) }));
    } catch (error) {
      dispatch(setAccountHoldings({ isLoading: false, data: null }));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export function saveBatchTransfer(payload) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPost(`${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batchTransferDetail`, {
      ...payload,
    },{
      pageName: PAGE_MANAGE_BATCH_TRANSFER,
      componentName: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_SAVE,
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getBatchandAccountDetailsById(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    dispatch(setIsBatchAccDetailsLoading(true));
    dispatch(
      setFLP({ key: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_ACCOUNT_DETAILS, value: { metaData: [], fieldAccess: [] } })
    );
    return fetcherGet(
      `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batchTransferDetails?batchId=${batchId}`,
      {},
      {
        pageName: PAGE_MANAGE_BATCH_TRANSFER,
        componentName: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_ACCOUNT_DETAILS,
      }
    )
      .then(({ data = [], metaData, fieldAccess }) => {
        const {
          batchName,
          batchId,
          comment,
          Status,
          TransferType: { id, name },
          BatchTransferHistory: [{ actionBy }],
        } = data[0];
        const { JurisdictionAccounts } = data[0];
        dispatch(setTransferType({ id, name }));
        dispatch(setBatchDetails({ batchName, batchId, comment, Status, createdBy: actionBy }));

        dispatch(setjuriAccountInformation(transformedJurisdictionAccountData(JurisdictionAccounts)));
        dispatch(
          setFLP({ key: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_ACCOUNT_DETAILS, value: { metaData, fieldAccess } })
        );
      })
      .finally(() => {
        dispatch(setLoader(false));
        dispatch(setIsBatchAccDetailsLoading(false));
      });
  };
}

export function getBatchHoldings(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    dispatch(
      setFLP({ key: BATCH_TRANSFER_COMPONENTS_FLP.SELECTED_HOLDINGS_BATCH, value: { metaData: [], fieldAccess: [] } })
    );
    try {
      const response = await fetcher(
        `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batchTransferHoldingsDetails?batchId=${batchId}`,
        "GET",
        {},
        false,
        {
          pageName: PAGE_MANAGE_BATCH_TRANSFER,
          componentName: BATCH_TRANSFER_COMPONENTS_FLP.SELECTED_HOLDINGS_BATCH,
        }
      );
      dispatch(setSelectedHoldings(transformHoldingsForTransfer(response?.data)));
      const { metaData, fieldAccess } = response;
      dispatch(
        setFLP({ key: BATCH_TRANSFER_COMPONENTS_FLP.SELECTED_HOLDINGS_BATCH, value: { metaData, fieldAccess } })
      );
    } catch (error) {
      dispatch(setSelectedHoldings([]));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export function getBatchTransfersList() {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(
      `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batchTransferDetails`,
      {},
      {
        pageName: PAGE_MANAGE_BATCH_TRANSFER,
        componentName: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_LIST,
      }
    )
      .then((response = { data: [] }) => {
        dispatch(setBatchTransfers(response));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function updateTransferBatch({ id, payload, transferTypeId }) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setIsSubmitting(true))
    dispatch(setLoader(true));
    return fetcherPatch(
      `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batch/${id}/batchTransferDetail?transferTypeId=${transferTypeId}`,
      {
        ...payload,
      },
      {
        pageName: PAGE_MANAGE_BATCH_TRANSFER,
        componentName: BATCH_TRANSFER_COMPONENTS_FLP.UPDATE_BATCH_TRANSFER,
      }
    ).finally(() => {
      dispatch(setIsSubmitting(false))
      dispatch(setLoader(false));
    });
  };
}

export function addReceivingAccountToHolding({ id, transferTypeId, payload }) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batch/${id}/batchTransferRecord?transferTypeId=${transferTypeId}`,
      [...payload],
      {
        pageName: PAGE_MANAGE_BATCH_TRANSFER,
        componentName: BATCH_TRANSFER_COMPONENTS_FLP.ADD_RECEIVING_ACCOUNT,
      }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getJurisdictionCurrencyList() {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(`transfers/api/jurisdiction/${jurisdictionId}/getCurrency`)
      .then((response = { data: [] }) => {
        dispatch(setCurrencyList(transformCurrencyOptions(response.data)));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getStatusList() {
  return (dispatch) =>
    fetcherGet(`${APIS.STATUS_LIST}`, { type: "BATCH_TRANSFER" }).then(({ data = [] }) => {
      dispatch(setStatuses(_.keyBy(data, "Name")));
    });
}

export function getBatchTransferAccountDetails(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    dispatch(
      setFLP({
        key: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_ACCOUNT_DETAILS,
        value: { metaData: [], fieldAccess: [] },
      })
    );
    try {
      const { data, metaData, fieldAccess } = await fetcherGet(
        `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batch/${batchId}/getBatchTransferAccountDetails`,
        {},
        {
          pageName: PAGE_MANAGE_BATCH_TRANSFER,
          componentName: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_ACCOUNT_DETAILS,
        }
      );
      dispatch(setBatchReceivingAccount(transformBatchTransferAccountDetails(data)));
      dispatch(
        setFLP({ key: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_ACCOUNT_DETAILS, value: { metaData, fieldAccess } })
      );
    } catch (error) {
      dispatch(setBatchReceivingAccount([]));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export function addTransfersBulk({ id, jsonData, transferTypeId }, fileName) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batch/${id}/batchTransferBulkUpload?transferTypeId=${transferTypeId}`,
      [...jsonData],
      {
      "file-name": fileName,
      pageName: PAGE_MANAGE_BATCH_TRANSFER,
      componentName: BATCH_TRANSFER_COMPONENTS_FLP.BULK_UPLOAD_BATCH_TRANSFER,
    }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function deleteTransferFromBatch({ id, transferIds }) {
  return (dispatch) => {
    dispatch(setLoader(true));
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    return fetcherDelete(
      `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batch/${id}/batchTransferRecord`,{
        transferUuid:transferIds.split(",")
      }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function editTransfer({ id, transferTypeId, payload }) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherPatch(
      `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batch/${id}/batchTransferRecord?transferTypeId=${transferTypeId}`,
      [...payload],
      {
        pageName: PAGE_MANAGE_BATCH_TRANSFER,
        componentName: BATCH_TRANSFER_COMPONENTS_FLP.EDIT_BATCH_TRANSFER,
      }
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getBatchTransferHistory(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    dispatch(
      setFLP({ key: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_HISTORY, value: { metaData: [], fieldAccess: [] } })
    );
    try {
      const { data, metaData, fieldAccess } = await fetcherGet(
        `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batch/${batchId}/batchTransferHistory`,
        {},
        {
          pageName: PAGE_MANAGE_BATCH_TRANSFER,
          componentName: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_HISTORY,
        }
      );
      dispatch(setBatchTransferHistory(data));
      dispatch(setFLP({ key: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_HISTORY, value: { metaData, fieldAccess } }));
    } catch (error) {
      dispatch(setBatchTransferHistory([]));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export function transferStatusUpdate({ id, transferTypeId, ...payload }) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setIsSubmitting(true));
    return fetcherPatch(
      `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batch/${id}/statusUpdate?transferTypeId=${transferTypeId}`,
      {
        ...payload,
      },
      {
        pageName: PAGE_MANAGE_BATCH_TRANSFER,
        componentName: BATCH_TRANSFER_COMPONENTS_FLP.UPDATE_STATUS_BATCH_TRANSFER,
      }
    ).finally(() => {
      dispatch(setIsSubmitting(false));
    });
  };
}

export function transferStatusApproved({ id, transferTypeId, ...payload }) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setIsSubmitting(true));
    return fetcherPatch(
      `${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batch/${id}/approvalBatchTransfer?transferTypeId=${transferTypeId}`,
      {
        ...payload,
      },
      {
        pageName: PAGE_MANAGE_BATCH_TRANSFER,
        componentName: BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_APPROVE,
      }
    ).finally(() => {
      dispatch(setIsSubmitting(false));
    });
  };
}

export function getProposedBatchTransfersList() {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem("jurisdictionID");
    dispatch(setLoader(true));
    return fetcherGet(`${APIS.BATCH_TRANSFERS}/jurisdiction/${jurisdictionId}/batchTransferDetails`)
      .then((response = { data: [] }) => {
        const proposedRecord = transformForPendingApproval(response.data)?.filter(
          (item) => item.status === BATCH_TRANSFER_STATUS.PROPOSED
        );
        dispatch(setProposedBatchList(proposedRecord));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export function getTransferType() {
  return (dispatch) => {
    dispatch(setLoader(true));
    return fetcherGet(`${APIS.BATCH_TRANSFERS}/getTransferTypeDetails?category=BATCH_TRANSFER`)
      .then(({ data }) => {
        const options = data.map(({ name, id }) => ({
          id,
          name,
        }));
        dispatch(setTransferTypeList(options));
      })
      .catch(() => {
        setTransferTypeList([]);
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

export const getPCRADetails = () => (dispatch) => {
  const jurisdictionId = localStorage.getItem("jurisdictionID");
  dispatch(setLoader(true));
  return fetcherGet(
    `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/accountDetails?accountType=Price Containment`
  )
    .then(({ data }) => {
      if (data?.length) {
        dispatch(setAccountDetailPCRA(data[0]));
      } else {
        dispatch(setAccountDetailPCRA({}));
      }
    })
    .catch(() => {
      dispatch(setAccountDetailPCRA({}));
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};
