const Style = {
  SingleParagraph: {
    mb: 1.5,
    "&:last-child": {
      mb: 0,
    },
    "& a": {
      color: "#4d9bc7",
      paddingLeft: '10px'
    },
    "a.disabled": {
      textDecoration: "none",
      pointerEvents: "none",
      cursor: "default"
    }
  },
  InfoUL: {
    listStyleType: 'auto',
    margin: '16px 0',
    paddingLeft: '40px',
    "& li": {
      fontWeight: "600",
      fontSize: " 16px",
      padding: '0',
      letterSpacing: "0.5px",
      display: 'list-item',
      listStyleType: 'disc',
      "& a": {
        color: "#4d9bc7",
        // paddingLeft: '10px'
      },
      "a.disabled": {
        textDecoration: "none",
        pointerEvents: "none",
        cursor: "default"
      }
    },
  },
  InfoULInner: {
    mb: 2/3,
    pl: 5.625, // or 28.125/5
    "& li": {
      fontSize: "18px",
      color: "#333",
      padding: 0,
      paddingLeft: "30px",
      paddingBottom: "15px",
    },
    "& li:last-child": {
      paddingBottom: "0",
    },
  },
  Submited: {
    fontSize: "18px",
    color: "#333",
    fontWeight: "600",
  },
  listStyle: {
    mb: 1,
    position: "relative",
    pl: 2.5,
    fontWeight: 600,
    "&:before": {
      position: "absolute",
      content: '""',
      left: "0",
      top: 8,
      height: 8,
      width: 8,
      borderRadius: "50%",
      backgroundColor: "common.textColor",
    },
  },
  FormListWithIcon: {
    mb: 1,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "a.disabled": {
      textDecoration: "none",
      pointerEvents: "none",
      cursor: "default"
    }
  },
};

export default Style;
