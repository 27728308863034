const Style = {
  menuWrapper: {
    p: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: '40px',
    a: {
      color: 'common.white',
      textDecoration: 'none',
      fontWeight: 400,
      fontSize: '14px',
      padding: '0 5px',
      '@media (max-width: 576px)': {
        padding: '0 2px',
      },
      '&:hover': {
        fontWeight: 700,
      },
      '&:focus': {
        fontWeight: 700,
      },
      '&:focus-visible': {
        fontWeight: 700,
        outline: 'none',
      },
    },
    button: {
      '@media (min-width: 0px)': {
        padding: '0 2px',
      },
      padding: { sm: '0 5px !important' },

      '&:focus': {
        fontWeight: 700,
        borderRadius: '0',
      },
      '&:focus-visible': {
        fontWeight: 700,
        outline: 'none',
      },
    },
    '> .MuiListItem-root': {
      '&:last-child': {
        ml: 'auto',
        mr: 0,
      },
      '&:first-of-type': {
        ml: 0,
      },
    },
  },
  menuItem: {
    py: 0,
    width: 'auto',
    position: 'relative',
    mr: {
      sm: 5,
      md: 8,
    },
    svg: {
      ml: {
        xs: 0,
        md: 1,
      },
      fill: (theme) => theme.palette.common.white,
    },
  },
  subMenuWrapper: {
    marginTop: '7.8px',
    position: 'absolute',
    top: '100%',
    borderRadius: '0 0 8px 8px',
    border: '1px solid',
    borderTop: 'none',
    borderColor: 'common.white',
    minWidth: 200,
    bgcolor: 'primary.main',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
    '> .MuiListItem-root': {
      py: 1,
      px: 2,
    },
  },
  subMenuLeft: {
    left: 0,
  },
  subMenuRight: {
    right: 0,
  },
  SubmenuHeaderLayout: {
    width: '250px !important',
    '& .MuiListItem-root': {
      marginLeft: '19px',
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiTypography-root': {
        fontWeight: 300,
      },
    },
  },
};

export default Style;
