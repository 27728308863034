import { Container } from "@mui/material";
import { Loadable } from "components/Loadable";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { StyledCard } from "pages/AccountDetails/AccountDetails.style";
import { lazy } from "react";



const MarketMonitorAllReport = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.MarketMonitorAllReport,
    }))
  )
);
 
export const MarketMonitorAllReportRoutes = {
  path: "/market-monitor-all-reports",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_market_monitor"}>
          <MarketMonitorAllReport />
        </RoleBasedGuard>
      ),
    },
  ],
};



 