import { QuickLinksMenu } from "components/QuickLinksMenu";
import React from "react";
import { useTranslation } from "react-i18next";
import { BATCH_TRANSFER_STATUS, getCurrentPageName, t } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setOpenAddTransferModel, setSelectedHolding, setSelectedHoldings } from "store/batchTransferSlice";
import { isEditable } from "pages/ManageBatchTransfer/constants";

function QuickActionsMenu({ params, status }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const {
    initiateTransferDetails: { selectedHoldings },
  } = useSelector(({ batchTransfer }) => batchTransfer);
  const quickLinkList = React.useMemo(
    () => [
      ...(isEditable(status)
        ? [
            {
              name: (t(t1, "ADD_RECEIVING_ACCOUNT")).toUpperCase(),
              Access: null,
              onLinkClick: () => {
                dispatch(setSelectedHolding([params.row]));
                dispatch(setOpenAddTransferModel(true));
              },
            },
          ]
        : []),
      ...(status === BATCH_TRANSFER_STATUS.ACTIVE?[{
        name: t(t1, "REMOVE_HOLDING_RECORD"),
        Access: null,
        onLinkClick: () => {
          const filteredHoldings = selectedHoldings.filter((row) => row.id !== params.row.id);
          dispatch(setSelectedHoldings(filteredHoldings));
        },
      }]:[])
    ],
    [dispatch, params.row, selectedHoldings, status, t1]
  );

  return (
    <QuickLinksMenu
      iconClassName="dot-icon"
      popupClassName="quick-link-style"
      linksList={quickLinkList || []}
      isDotIcon
    />
  );
}

export default QuickActionsMenu;
