const Style = {
  stepperWrapper: {
    width: "100%",
    pt: 8,
    pl: 2,
    position: "sticky",
    top: "0",

    ".MuiStep-root": {
      ".MuiStepIcon-text": {
        display: "none",
      },
      '.MuiSvgIcon-root':{
        border:'1px solid',
        borderRadius:'50%'
      },
      '.MuiStepIcon-root.Mui-completed':{
        border: '1px solid #5C9E16 !important',
        color: '#5C9E16'
    },
      pointerEvents: "none",
      ".MuiStepLabel-label": {
        fontSize: "13px",
      },
      ".Mui-completed":{
      color:"rgba(0, 0, 0, 0.6)"
      },
      ".Mui-active":{
        color:"#283459"
      },
      "&.SubStep":{
        ".MuiStepLabel-iconContainer::after": {
          content: "''",
          height: "1px",
          width: "10px",
          position: "absolute",
          top: "50%",
          left: "33px",
          background: "#797979",
        },
      },
      ".MuiStepIcon-root": {
        "&:not(.Mui-active)": {
          color: "common.white",
          border: "1px solid",
          borderColor: "primary.main",
          borderRadius: "50%",
        },
        "&.Mui-completed": {
          border: "none",
          color: "#5C9E16",
        },
      },
      ".MuiStepLabel-iconContainer": {
          pr: 2,
        },
      "&:first-of-type": {
        ".MuiStepIcon-text": {
          display: "block",
        },
        ml: -1.5,
        ".MuiStepLabel-iconContainer": {
          pr: 1,
        },
        ".MuiStepIcon-root": {
          border: "none",
          color: "primary.main",
          width: "2em",
          height: "2em",
        },
        ".MuiStepLabel-label": {
          fontSize: "14px",
        }
      },
      "&:last-of-type": {
        ".MuiStepIcon-text": {
          display: "block",
          fill: "#283459",
        },
        ml: -1.5,
        ".MuiStepLabel-iconContainer": {
          pr: 1,
        },
        ".MuiStepIcon-root": {
          color: "common.white",
          width: "2em",
          height: "2em",
          // border: "1px solid",
          // borderColor: "primary.main",
          "&.Mui-active": {
            color: "primary.main",
            ".MuiStepIcon-text": {
              fill: "#fff",
            },
          },
        },
        ".MuiStepLabel-label": {
          fontSize: "14px",
        }
      },
    },
  },
};

export default Style;
