import { Box, Typography, styled } from "@mui/material";

export const StyledErrorCard = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.red}`,
  margin: theme.spacing(2.5, 0),
  padding: theme.spacing(2.5, 2.5, 1.5, 2.5),
}));
export const StyledErrorCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  margin: theme.spacing(0, 0, 1, 0),
  fontWeight: 300,
  color: theme.palette.common.red,
}));

export const StyledErrorListItems = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  margin: theme.spacing(0, 0, 1, 0),
  fontWeight: 800,
  color: theme.palette.common.red,
}));
