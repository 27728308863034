const Style = {
    addEmployerBtnContainer: {
        display: 'flex', justifyContent: 'end',
        alignItems: 'end',
        width: '100%'
    },
    addEmployerBtnContainerViewProfile: {
        display: 'flex', justifyContent: 'end',
        alignItems: 'end',
        width: '100%'
    },
    addEmployerBtn: {
        textTransform: 'none',
        paddingRight: 0,
        display: 'flex !important',
        justifyContent: 'end !important',
    },
    disabledDate: {
        background: "#ccc"
    }

}

export default Style;