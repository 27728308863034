const Style = {
  fileField: {
    display: "none",
  },
  outlined: {
    borderColor: "#d7d7d7",
    color: "#4d8da4",
    backgroundColor: "#ffffff",
  },
  contained: {
    borderColor: "#d7d7d7",
    backgroundColor: "#4d8da4",
    color: "#ffffff",
  },
  defaultColor: {
    backgroundColor: "#283459",
    borderColor: "#d7d7d7",
    color: "#FFFFFF",
    '&.Mui-disabled': {
      backgroundColor: "#aaaaaa",
      color: "#FFFFFF",
    },
    '&:hover': {
      borderColor: "#d7d7d7",
      color: "#ffffff",
      backgroundColor: "#4d8da4",
    }
  },
  Label: {
    color: "#FFFFFF",
    "& .MuiButton-startIcon": {
      margin: 0,
      "& .MuiButton-startIcon": {
        // fontSize: "14px"
      }
    }
    // fontSize: "16px !important"
    // "& .MuiButton-startIcon:nth-of-type(1)": {
    // }
    // "&:hover": {
    //   outline: "none",
    //   borderColor: "#008ae6",
    // },

    // "&:focus": {
    //   outline: "none",
    //   borderColor: "#008ae6",
    // },
  },
};

export default Style;
