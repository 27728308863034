import { createSelector, createSlice } from "@reduxjs/toolkit";
import {setModelMessage} from "store/commonSlice";
import {ACCOUNT_ACTION_PAGE, APIS, ENTITY_TYPES_ORDER, ERROR, STATUSES, SUCCESS} from "utils/constants";
import {fetcherGet, fetcherPost, fetcherPut} from "utils/fetcher";
import logger from "utils/logger";

const initialState = {
  accounts: [],
  accountDetails: null,
  statusHistory: [],
  statusList: [],
  isLoadingAccounts: false,
  isLoadingStatusHistory: false,
  isLoadingStatusList: false,
  isLoadingAccountDetails: false,
  isLoadingAccountUpdate: false,
  isAddingSpecialAccount: false,
};

const selectAccountDetails = (state) => state.accountDetails?.accountDetails;

export const getIsCWIVerificationRequired = createSelector(
  [selectAccountDetails],
  (accountDetails) => accountDetails?.data?.isCWIVerificationRequired
);

export const getIsCWIProposedExist = createSelector(
  [selectAccountDetails],
  (accountDetails) => accountDetails?.data?.isCWIProposedExist
);

const entityAccountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setAccountDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    setStatusHistory: (state, action) => {
      state.statusHistory = action.payload;
    },
    setStatusList: (state, action) => {
      state.statusList = action.payload;
    },
    setIsLoadingAccounts: (state, action) => {
      state.isLoadingAccounts = action.payload;
    },
    setIsLoadingStatusHistory: (state, action) => {
      state.isLoadingStatusHistory = action.payload;
    },
    setIsLoadingStatusList: (state, action) => {
      state.isLoadingStatusList = action.payload;
    },
    setIsLoadingAccountDetails: (state, action) => {
      state.isLoadingAccountDetails = action.payload;
    },
    setIsLoadingAccountUpdate: (state, action) => {
      state.isLoadingAccountUpdate = action.payload;
    },
    setIsAddingSpecialAccount: (state, action) => {
      state.isAddingSpecialAccount = action.payload;
    },
    resetAccountData: (state) => {
      state.accountDetails = initialState.accountDetails;
      state.accounts = initialState.accounts;
      state.statusHistory = initialState.statusHistory;
      state.statusList = initialState.statusList;
    },
  },
});

export const {
  setAccounts,
  setAccountDetails,
  setStatusHistory,
  setStatusList,
  setIsLoadingAccounts,
  setIsLoadingStatusHistory,
  setIsLoadingStatusList,
  setIsLoadingAccountDetails,
  setIsLoadingAccountUpdate,
  setIsAddingSpecialAccount,
  resetAccountData,
} = entityAccountSlice.actions;
export default entityAccountSlice.reducer;

export const getAccountById = (id, pageDetails) => (dispatch) => {
  dispatch(setIsLoadingAccountDetails(true));
  fetcherGet(`${APIS.ACCOUNTS}/${id}`, {}, pageDetails)
    .then((response) => {
      dispatch(setAccountDetails(response));
      dispatch(setIsLoadingAccountDetails(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingAccountDetails(false));
    });
};

export const getAccounts = (id) => (dispatch) => {
  dispatch(setIsLoadingAccounts(true));
  fetcherGet(`${APIS.ENTITY}/${id}/accounts`)
    .then((response) => {
      const data = response?.data
        ?.map((account) => ({
          ...account,
          order: ENTITY_TYPES_ORDER[account?.account_type],
        }))
        ?.sort((a, b) => (a?.order > b?.order ? 1 : -1));
      dispatch(setAccounts(data));
      dispatch(setIsLoadingAccounts(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingAccounts(false));
    });
};

export const getAccountsStatusList =
  (type = STATUSES.ENTITY_ACCOUNTS) =>
  (dispatch) => {
    dispatch(setIsLoadingStatusList(true));
    fetcherGet(`${APIS.STATUS_LIST}?type=${type}`)
      .then((response) => {
        dispatch(setStatusList(response?.data));
        dispatch(setIsLoadingStatusList(false));
      })
      .catch((error) => {
        logger({ error });
        dispatch(setIsLoadingStatusList(false));
      });
  };

export const getStatusHistoryByAccountId = (id) => (dispatch) => {
  dispatch(setIsLoadingStatusHistory(true));
  fetcherGet(
    `${APIS.ACCOUNTS_STATUS}/${id}/status-history`,
    {},
    { componentName: "statusChange", pageName: "Page_AccountDetails" }
  )
    .then((response) => {
      dispatch(setStatusHistory(response));
      dispatch(setIsLoadingStatusHistory(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingStatusHistory(false));
    });
};

export const updateAccountStatus = (id, payload, page, entityId, pageDetail) => (dispatch) => {
  dispatch(setIsLoadingAccountUpdate(true));
  fetcherPut(`${APIS.ACCOUNTS_STATUS}/${id}`, payload)
    .then((response) => {
      const data = {
        title: SUCCESS,
        message1: response?.messageKey,
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      dispatch(setIsLoadingAccountUpdate(false));
      if (ACCOUNT_ACTION_PAGE.ENTITY_PROFILE === page) {
        dispatch(getAccounts(entityId));
      } else if (ACCOUNT_ACTION_PAGE.ACCOUNT_DETAILS === page) {
        dispatch(getAccountById(id, pageDetail));
        dispatch(getStatusHistoryByAccountId(id));
      }
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingAccountUpdate(false));
      const data = {
        title: ERROR,
        message1: error?.response?.data?.messageKey,
        error: true,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
    });
};

export const addSpecialAccount = (id, payload) => (dispatch) => {
  dispatch(setIsAddingSpecialAccount(true));
  fetcherPost(`${APIS.SPECIAL_ACCOUNTS}/${id}`, payload)
    .then((response) => {
      const data = {
        title: SUCCESS,
        message1: response?.messageKey,
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      dispatch(getAccounts(id));
      dispatch(setIsAddingSpecialAccount(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsAddingSpecialAccount(false));
      const data = {
        title: ERROR,
        message1: error?.response?.data?.messageKey,
        error: true,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
    });
};

export const resetData = () => (dispatch) => {
  dispatch(resetAccountData());
};

