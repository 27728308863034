export function get15UnProposedBudgetYears(givenYears) {
  let currentYear = Math.min(...givenYears, new Date().getFullYear());
  const givenYearsSet = new Set(givenYears);
  const outputYears = [];

  while (outputYears.length < 15) {
    // Check if the current year is in the given years set
    if (!givenYearsSet.has(currentYear)) {
      outputYears.push(currentYear);
    }

    // Increment the current year and continue the loop
    currentYear += 1;
  }

  return outputYears.map((year, index) => ({
    id: index,
    budgetYear: year,
    annualAllowanceBudget: null,
    reserveAdjustedAllowanceBudget: null,
  }));
}

export function isCurrentFieldRequired(arg1, arg2, message) {
  return arg1 !== null && BigInt(arg1) >= 0n && arg2 === null ? message : true;
}

export function isFieldValid(arg1, message) {
  return arg1 === null || BigInt(arg1) >= 0n ? true : message;
}

export function isValueLarger(arg1, arg2, message) {
  return arg1 !== null && arg2 !== null && BigInt(arg1) < BigInt(arg2) ? message : true;
}
