import { createSlice } from "@reduxjs/toolkit";
import fetcher from "utils/fetcher";

const initialState = {
  dialogMessage: false,
  modelMessage: false,
  loader: false,
  isFormEditable: false,
  alternateContactError: false,
  isLocked: false,
  gridFilters: null,
  gridDownloadFormat: null,
  dataRefreshCompleted: false,
  responseId: null,
  securityQuestions: [],
  fsaReportLoader: false,
};

export function securityQuestions(t,t1) {
  return (dispatch) => {
    fetcher('public/security_questions', "GET", {}, false)
      .then((response) => {
        // debugger; // eslint-disable-line
        const sqMapping = response?.map((eachQuestion) => {
          return {
            key: eachQuestion.name,
            value: t(t1, eachQuestion.name)
            
          };
        });
        dispatch(setSecurityQuestions(sqMapping));
      })
      .catch((err) => {
        console.error(err); 
      });
  };
}

export function readSecurityQuestions(securityQuestions) {
   const tmp = [];
    if (securityQuestions?.length > 0) {
      securityQuestions.forEach((question) => {
        const questionOption = {
          title: question.value,
          value: question.key
        };
        tmp.push(questionOption);
      });
    }
    return tmp
}
export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setDialogMessage: (state, action) => {
      state.dialogMessage = action.payload;
    },
    setModelMessage: (state, action) => {
      // console.log(state, action);
      state.modelMessage = action.payload;
    },
    setIsFormEditable: (state, action) => {
      state.isFormEditable = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setAlternateContactError: (state, action) => {
      state.alternateContactError = action.payload;
    },
    setIsLocked: (state, action) => {
      state.isLocked = action.payload;
    },
    setGridDownloadFormat: (state, action) => {
      state.gridDownloadFormat = action.payload;
    },
    setCurrentGridState: (state, action) => {
      state.gridFilters = action.payload;
    },
    setDataRefreshCompleted: (state, action) => {
      state.dataRefreshCompleted = action.payload;
    },
    setResponseID: (state, action) => {
      state.responseId = action.payload;
    },
    setSecurityQuestions: (state, action) => {
      state.securityQuestions = action.payload;
    },
    setFSAReportLoader: (state, action) => {
      state.fsaReportLoader = action.payload;
    },
  },
});

export default commonSlice.reducer;
export const {
  setDialogMessage,
  setModelMessage,
  setLoader,
  setIsFormEditable,
  setAlternateContactError,
  setIsLocked,
  setGridDownloadFormat, 
  setCurrentGridState, 
  setDataRefreshCompleted,
  setResponseID,
  setSecurityQuestions,
  setFSAReportLoader,
} = commonSlice.actions;
