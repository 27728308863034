import { createSlice } from "@reduxjs/toolkit";
import { APIS, getCurrentORGID, isEmpty } from "utils/constants";
import fetcher from "utils/fetcher";
import fetcherMock from "utils/fetcherMock";
import { setLoader } from "./commonSlice";

const TOKEN = "access_token";

const initialState = {
  user: {},
  userRole: "ROLE#003",
  isWciAdmin: false,
  menu: {},
  header: {},
  footer: {},
  loadingPrivileges: false,
  loadingMenu: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      // console.log('setUserDetails', action.payload);
      state.user = action.payload;
    },
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setHeader: (state, action) => {
      state.header = action.payload;
    },
    setFooter: (state, action) => {
      state.footer = action.payload;
    },
    setUserRole: (state, action) => {
      // console.log('state.userRole',action.payload)
      state.userRole = action.payload;
    },
    setIsWciAdmin: (state, action) => {
      state.isWciAdmin = action.payload;
    },
    setLoadingPrivileges: (state, action) => {
      state.loadingPrivileges = action.payload;
    },
    setLoadingMenu: (state, action) => {
      state.loadingMenu = action.payload;
    }
  },
});

export const { loading, login, logout, setUserDetails, setUserRole, setIsWciAdmin, setMenu, setHeader, setFooter, setLoadingPrivileges, setLoadingMenu } = authSlice.actions;
export default authSlice.reducer;

export const checkIsAuthenticated = () => {
  const token = getAccessToken();
  if (token) return true;
  return false;
};

export const getAccessToken = () => localStorage.getItem(TOKEN);

export const storeAccessToken = (token) => {
  localStorage.setItem(TOKEN, token);
};

export const removeAccessToken = () => {
  localStorage.removeItem(TOKEN);
};

export function getUserDetails({ jurisdiction, roleId = "U%23Guest", pageName }) {
  // console.log(roleId);
  // let jurisdictionId = (jurisdiction || getCurrentORGID());
    /* Conflict Resolution Candidate
  // const jurisdictionId = jurisdiction?.replace("#", "%23");
  const jurisdictionId = jurisdiction ? jurisdiction?.replace("#", "%23"): localStorage.getItem('jurisdiction')?.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true));
     */
  const jurisdictionId = jurisdiction?.replace("#", "%23");
  return (dispatch) => {
    const endPoint = roleId === "U%23Guest" ? APIS.PUBLIC_PRIVILEGE : APIS.PRIVILEGE
    dispatch(setLoadingPrivileges(true));
    // if (isEmpty(getAccessToken())) {
    fetcher(`${endPoint}?PK=${jurisdictionId}&SK=${roleId}%23${pageName}`, "GET", {}, false)
      .then((response) => {
        // console.log(response, "Meh Priv Ka Log");
        if (response.Items.length > 0) {
          if (pageName === "Page_Footer") {
            dispatch(setFooter(response.Items[0]));
          }
          else if (pageName === "Page_Header") {
            dispatch(setHeader(response.Items[0]));
          }
          else
            dispatch(setUserDetails(response.Items[0]));
    /* Conflict Resolution Candidate

          }
          dispatch(setLoader(false));
      })
      .catch((err) => {

        dispatch(setLoader(false));
     */
        }
        dispatch(setLoadingPrivileges(false));
      })
      .catch((err) => {
        dispatch(setLoadingPrivileges(false));
      });
    // }
  };
}


export function getMenuAccess(jurisdiction, UserId = "U%23Guest") {
  // let jurisdictionId = (jurisdiction || getCurrentORGID());
    /* Conflict Resolution Candidate
  const jurisdictionId = jurisdiction ? jurisdiction?.replace("#", "%23"): localStorage.getItem('jurisdiction')?.replace("#", "%23");


  return (dispatch) => {
    dispatch(setLoader(true));
     */
  const jurisdictionId = jurisdiction?.replace("#", "%23");

  // console.log(jurisdiction, jurisdictionId, UserId);

  return (dispatch) => {
    const endPoint = UserId === "U%23Guest" ? APIS.PUBLIC_PRIVILEGE : APIS.PRIVILEGE
    dispatch(setLoadingMenu(true));
    fetcher(`${endPoint}?PK=${jurisdictionId}&SK=${UserId}%23Page_MegaMenu`, "GET", {}, false)
      .then((response) => {
        if (response.Items.length > 0) {
          dispatch(setMenu(response.Items[0]));
        }
    /* Conflict Resolution Candidate
        dispatch(setLoader(false));
      })
      .catch((err) => {

        dispatch(setLoader(false));
     */
        dispatch(setLoadingMenu(false));
      })
      .catch((err) => {
        dispatch(setLoadingMenu(false));
      });
  };
}
