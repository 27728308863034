import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { setActiveStep } from "store/createUserProfileSlice";
import { t, getCurrentPageName } from "utils/constants";
import Style from "./UserSteps.style";

export default function UserSteps() {
  const { t: t1, i18n } = useTranslation(getCurrentPageName());
  const steps = [
    t(t1, "CREATE_USER_PROFILE", ""),
    t(t1, "SELECT_JURISDICTION"),
    t(t1, "PERSONAL_INFORMATION", ""),
    t(t1, "RESIDENCE_MAILING"),
    t(t1, "EDIT_REVIEW"),
    t(t1, "CREATE_USER_LOG_IN"),
    t(t1, "SUBMIT_REQUIRED_DOCUMENTS"),
  ];
  const dispatch = useDispatch();
  const {
    formSteps: { activeStep },
    submitted,
  } = useSelector(({ createUserProfile }) => createUserProfile);

  const handleStep = (step) => () => {
    setActiveStep(step);
    dispatch(setActiveStep(step));
  };

  return (
    <Box sx={Style.stepperWrapper}>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {steps?.map((label, index) => (
          <Step key={index} completed={submitted?.[index]} className={index === 6 || index === 0?'':'SubStep'}>
            <StepButton
              color="inherit"
              // onClick={handleStep(index)}
              disableRipple
              sx={{
                "&.Mui-disabled": {
                  color: "#c0c0c0",
                },
                py: 0,
              }}
              disabled={index === 0}
              icon={index === 6 ? 2 : 1}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
