/* eslint-disable camelcase */
export function isCurrentFieldRequired(arg1, message) {
  return arg1 !== 0 && !arg1 ? message : true;
}

export function isFieldValid(arg1, message) {
  const isValidNumber = /^\d+(\.\d{1,2})?$/.test(arg1);
  return isValidNumber || arg1 === 0 ? true : message;
}

export function isGreater(arg, message) {
  return arg > 100 ? message : true;
}

function isObject(obj) {
  return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

export function removeDecimalIfInt(data) {
  const newData = parseFloat(data);
    const num = data - newData;
    if (num === 0)
      return newData.toString();

    return data;
}

export function transformPurchaseLimits(data) {
  let purchase_limits; let currentPurchaseLimit; let futurePurchaseLimit;
  if (isObject(data)) {
    ({ currentPurchaseLimit, futurePurchaseLimit } = data);
    data.currentPurchaseLimit = currentPurchaseLimit?.map((i) => removeDecimalIfInt(i));
    data.futurePurchaseLimit = futurePurchaseLimit?.map((i) => removeDecimalIfInt(i));
    return data
  }

  return data?.map((item) => {
    if (Object.prototype.hasOwnProperty.call(item, 'purchase_limits')) {
      ({ purchase_limits } = item);
      ({ currentPurchaseLimit, futurePurchaseLimit } = purchase_limits);
      item.purchase_limits.currentPurchaseLimit = removeDecimalIfInt(currentPurchaseLimit);
      item.purchase_limits.futurePurchaseLimit = removeDecimalIfInt(futurePurchaseLimit);
    } else {
      ({ currentPurchaseLimit, futurePurchaseLimit } = item);
      item.currentPurchaseLimit = removeDecimalIfInt(currentPurchaseLimit);
      item.futurePurchaseLimit = removeDecimalIfInt(futurePurchaseLimit);
    }
    return item;
  })
}
