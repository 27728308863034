
import { createSlice } from "@reduxjs/toolkit";
import fetcher, { fetcherGet, fetcherPost } from "utils/fetcher";
import { APIS } from "utils/constants";
import { getJurisdictionUUID, getPermissionObj } from "utils/support";
import { setLoader } from "./commonSlice";

const initialState = {
  serviceProSelectionMgm: {
    isLoading: false,
    data: [],
  },
  selectedServiceProSelectionMgmDetails: {
    isLoading: false,
    data: [],
  },
  selectedServiceProviderMUIAuc: {
    isLoading: false,
    data: [],
  },
  selectedModel: [],
  getJuridictionDetails: { isloading: false, data: [] },
};


const serviceProSelectionMgmSlice = createSlice({
  name: "serviceProSelectionMgm",
  initialState,
  reducers: {
    setserviceProSelectionMgm: (state, action) => {
      state.serviceProSelectionMgm = action.payload;
    },
    // get
    setSelectedServiceProSelectionMgmDetails: (state, action) => {
      state.selectedServiceProSelectionMgmDetails = action.payload;
    },
    setSelectedServiceProviderMUIAuc: (state, action) => {
      state.selectedServiceProviderMUIAuc = action.payload;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
    setJuriDictionDetails: (state, action) => {
      state.getJuridictionDetails = action.payload;
    },
    // end get
  },
});

export default serviceProSelectionMgmSlice.reducer;
export const {
  setserviceProSelectionMgm,
  // get
  setSelectedServiceProSelectionMgmDetails,
  setSelectedServiceProviderMUIAuc,
  setSelectedModel,
  setJuriDictionDetails,
  // end get
} = serviceProSelectionMgmSlice.actions;


export function addSPSMForm(payload, jurisdictionId, PageName) {
  const pageDetails = {
    componentName:  "UpdateResetEffectiveDateDetails",
    pageName: PageName,
  };
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(`${APIS.SERVICEPROSELMGM_API}/${jurisdictionId}/reset-effective-date-time`, {
      ...payload,
    }, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// GET
export function getServiceProSelectionMgmDetails(jurisdictionId, PageName) {
  const pageDetails = {
    componentName:  "viewResetEffectiveDateDetails",
    pageName: PageName,
  };
  return (dispatch) => {
    dispatch(setSelectedServiceProSelectionMgmDetails({ isLoading: true, data: [] }));
    fetcher(
      `${APIS.SERVICEPROSELMGM_API}/${jurisdictionId}/reset-effective-date-time`,
      "GET",
      {},
      false,
      pageDetails
    )
      .then((response) => {
        dispatch(setSelectedServiceProSelectionMgmDetails({ isLoading: false, data: response }));
      })
      .catch((err) => {
        dispatch(setSelectedServiceProSelectionMgmDetails({ isLoading: false, data: [] }));
      });
  };
}


export function getServiceProviderMUIAuc(PageName) {
  const pageDetails = {
    componentName:  ["viewAuctionAdministrator","viewServiceProviders","report"],
    pageName: PageName,
  };
  return (dispatch) => {
    dispatch(setSelectedServiceProviderMUIAuc({ isLoading: true, data: [] }));

    let fetchedJurisdictionResponse;

    fetcherGet(APIS.GET_JURISDICTIONS, "GET", {}, false)
      .then((jurisdictionResponse) => {
        fetchedJurisdictionResponse = jurisdictionResponse?.data;
        dispatch(setJuriDictionDetails({ isLoading: false, data: fetchedJurisdictionResponse }));
        return fetcher(`${APIS.SERVICEPROVIDERMUIAUC_API}/service-providers`, "GET", {}, false, pageDetails);
      })
      .then((response) => {
        dispatch(setSelectedServiceProviderMUIAuc({ isLoading: false, data: response }));

        const selectedModel = response?.data?.serviceProviders?.map((item) => {
          if (
            item.jurisdictionAssigned?.some(
              (assignedJurisdiction) =>
                assignedJurisdiction.orgAssignedId === fetchedJurisdictionResponse?.dynamoId
            )
          ) {
            return item.uId;
          }
          return '';
        }).filter((item) => item !== '');

        dispatch(setSelectedModel(selectedModel));
      })
      .catch((err) => {
        dispatch(setSelectedServiceProviderMUIAuc({ isLoading: false, data: [] }));
        dispatch(setJuriDictionDetails({ isLoading: false, data: [] }));
      });
  };
}



export function addEditServiceProviderMUIAuc(payload, PageName) {
  const pageDetails = {
    componentName:  "viewServiceProviders",
    pageName: PageName,
  };
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(`${APIS.SERVICEPROVIDERMUIAUC_API}/service-providers`, {
      ...payload,
    }, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}


