import { createSlice } from "@reduxjs/toolkit";
import { APIS } from "utils/constants";
import fetcher, { fetcherGet } from "utils/fetcher";
import { getPermissionObj, getTransformedHistoryData } from "utils/support";
import { setLoader } from "./commonSlice";

const initialState = {
  exchangeContractCodes: { isLoading: false, data: [] },
  editExchangeContractCodeData: { isLoading: false, data: [] },
  exchangeContractDescCodeHistory: { isLoading: false, data: [] },
};
const exchangeContractCodeSlice = createSlice({
  name: "exchangeContractCode",
  initialState,
  reducers: {
    setExchangeContractCodes: (state, action) => {
      state.exchangeContractCodes = action.payload;
    },
    setEditExchangeContractCode: (state, action) => {
      state.editExchangeContractCodeData = action.payload;
    },
    setExchangeContractDescCodeHistory: (state, action) => {
      state.exchangeContractDescCodeHistory = action.payload;
    },
  },
});

export default exchangeContractCodeSlice.reducer;

export const { setExchangeContractCodes, setEditExchangeContractCode, setExchangeContractDescCodeHistory } =
  exchangeContractCodeSlice.actions;

export function getAllExchangeContractCodes(
  pageDetails = {
    pageName: "Page_ManageExchangeContractCode",
    componentName: "exchangeAndContractDesciptionCodes",
  }
) {
  return async (dispatch) => {
    dispatch(setExchangeContractCodes({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_EXCHANGE_CONTRACT_CODES}`, {}, pageDetails);
      dispatch(setExchangeContractCodes({ isLoading: false, data: response }));
    } catch (error) {
      dispatch(setExchangeContractCodes({ isLoading: false, data: [] }));
    }
  };
}

export function addExchangeContractCode(payload) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const pageDetails = {
      pageName: "Page_AddExchangeContractCode",
      componentName: "addExchangeContractCode",
    };
    return fetcher(`${APIS.POST_EXCHANGE_CONTRACT_CODE}`, "POST", payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getExchangeContractCode(codeId) {
  return async (dispatch) => {
    dispatch(setEditExchangeContractCode({ isLoading: true, data: [] }));
    dispatch(setLoader(true));

    try {
      const pageDetails = {
        pageName: "Page_EditExchangeContractCode",
        componentName: "editExchangeContractCode",
      };
      const response = await fetcherGet(`${APIS.GET_EDIT_EXCHANGE_CONTRACT_CODE}/${codeId}`, {}, pageDetails);
      dispatch(setLoader(false));
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setEditExchangeContractCode({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, "editExchangeContractCode"),
            metaData,
          },
        })
      );
    } catch (error) {
      dispatch(setEditExchangeContractCode({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function editExchangeContractCode(payload) {
  return async (dispatch) => {
    // const jurisdictionId = getJurisdictionUUID();
    // dispatch(setLoader(true));
    // return fetcherPost(`${APIS.EDIT_EXCHANGE_CONTRACT_CODE}/${jurisdictionId}`, {
    //   ...payload,
    // }).finally(() => {
    //   dispatch(setLoader(false));
    // });

    try {
      // const response = await fetcher(`${APIS.GET_EDIT_EXCHANGE_CONTRACT_CODE}`, "GET", {}, false, {});
    } catch (error) {
      dispatch(setLoader(false));
    }
  };
}

export function updateExchangeContractCode(payload, id) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const pageDetails = {
      pageName: "Page_EditExchangeContractCode",
      componentName: "editExchangeContractCode",
    };
    return fetcher(`${APIS.EDIT_EXCHANGE_CONTRACT_CODE}/${id}`, "POST", payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// get exchange contract desc code history API
export function getExchangeContractDescCodeHistory(id) {
  return async (dispatch) => {
    dispatch(setExchangeContractDescCodeHistory({ isLoading: true, data: [] }));
    dispatch(setLoader(true));
    try {
      const pageDetails = {
        pageName: "Page_EditExchangeContractCode",
        componentName: "exchangeContractDescriptionCodeHistory",
      };
      const response = await fetcherGet(`${APIS.GET_EXCHANGE_CONTRACT_CODE_HISTORY}/${id}`, {}, pageDetails);
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setExchangeContractDescCodeHistory({
          isLoading: false,
          data: {
            data: getTransformedHistoryData(data),
            fieldAccess: getPermissionObj(fieldAccess, "exchangeContractDescriptionCodeHistory"),
            metaData,
          },
        })
      );
    } catch (e) {
      dispatch(setExchangeContractDescCodeHistory({ isLoading: false, data: [] }));
    }
  };
}
