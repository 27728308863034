import { createSlice } from "@reduxjs/toolkit";
import { setLoader, setFSAReportLoader } from "store/commonSlice";
import { APIS, COMPONENT_NAME_EVENT, FSI_COMPONENT_NAMES, SUCCESS, eventTypeNames } from "utils/constants";
import { fetcher, fetcherGet, fetcherPut, fetcherPost } from "utils/fetcher";
import { getJurisdictionUUID } from "utils/support";

const initialState = {
  eventTypeList: [],
  entityList: [],
  eventNameList: [],
  FSAreportListingData: { isloading: false, data: [] },
  ConsignmentWiringData: { isloading: false, data: [] },
  getJuridictionDetails: { isloading: false, data: [] },
};

const fsaAllReports = createSlice({
  name: "fsaAllReports",
  initialState,
  reducers: {
    setEventType: (state, action) => {
      state.eventTypeList = action.payload;
    },
    setEntityId: (state, action) => {
      state.entityIdList = action.payload;
    },
    setEventName: (state, action) => {
      state.eventNameList = action.payload;
    },
    // setEventNameInFren: (state, action) => {
    //   state.eventNameInFrenList = action.payload;
    // },
    setEntity: (state, action) => {
      state.entityList = action.payload;
    },
    setFSAReportListingData: (state, action) => {
      state.FSAreportListingData = action.payload;
    },
    setJuriDictionDetails: (state, action) => {
      state.getJuridictionDetails = action.payload;
    },
    setConsignmentWiringData: (state, action) => {
      state.ConsignmentWiringData = action.payload;
    }
  }
});

export const {
  setEventType,
  setEntity,
  setEventName,
  setFSAReportListingData,
  setConsignmentWiringData,
  setJuriDictionDetails,
} = fsaAllReports.actions;

export default fsaAllReports.reducer;
let jurisdictionID = getJurisdictionUUID();
export function getEventType(PageName) {
  const pageDetails = { componentName: [COMPONENT_NAME_EVENT.EVENT_CREATION, FSI_COMPONENT_NAMES.REPORT, FSI_COMPONENT_NAMES.CREATE_REPORT_RECORDS, FSI_COMPONENT_NAMES.CREATE_EVENT_APPLICATION_REPORT_RECORDS], pageName: PageName }
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = APIS.GET_EVENT_TYPE;
    const response = await fetcherGet(`${url}`, {}, pageDetails);
    if (response.messageKey === "SUCCESS") {
      dispatch(setEventType(response.data));
    } else {
      dispatch(setEventType([]));
    }
    dispatch(setLoader(false));

  };
}

export function getEntity(jurisdictionId, PageName) {
  const pageDetails = {
    componentName: [FSI_COMPONENT_NAMES.CREATE_REPORT_RECORDS, FSI_COMPONENT_NAMES.CREATE_EVENT_APPLICATION_REPORT_RECORDS, FSI_COMPONENT_NAMES.REPORT],
    pageName: PageName,
  };
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${APIS.EVENT_APPLICATION_API}/${jurisdictionId}/report-params`;
    const response = await fetcherGet(`${url}`, {}, pageDetails);
    if (response.messageKey === "SUCCESS") {
      dispatch(setEntity(response.data));
    } else {
      dispatch(setEntity([]));
    }
    dispatch(setLoader(false));

  };
}

export function getEntityLegelName(jurisdictionId, PageName) {
  const pageDetails = {
    componentName: [FSI_COMPONENT_NAMES.CREATE_REPORT_RECORDS, FSI_COMPONENT_NAMES.CREATE_EVENT_APPLICATION_REPORT_RECORDS, FSI_COMPONENT_NAMES.REPORT],
    pageName: PageName,
  };
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${APIS.EVENT_APPLICATION_API}/${jurisdictionId}/report-params`;
    const response = await fetcherGet(`${url}`, {}, pageDetails);
    if (response.messageKey === "SUCCESS") {
      dispatch(setEntity(response.data));
    } else {
      dispatch(setEntity([]));
    }
    dispatch(setLoader(false));

  };
}
export function getEventName(jurisdictionId, PageName, eventTypeName = '', isDataExport = false) {
  const pageDetails = {
    componentName: [FSI_COMPONENT_NAMES.CREATE_REPORT_RECORDS, FSI_COMPONENT_NAMES.CREATE_EVENT_APPLICATION_REPORT_RECORDS, FSI_COMPONENT_NAMES.REPORT],
    pageName: PageName,
  };
  let eventTypeData

  if (eventTypeName === "") {
    eventTypeData = ""
  } else if (eventTypeName === eventTypeNames?.Auction) {
    eventTypeData = eventTypeNames?.AUCTION;
  } else {
    eventTypeData = eventTypeNames?.RESERVE_SALE;
  }

  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${APIS.EVENT_APPLICATION_API}/${jurisdictionId}/report-events?eventType=${eventTypeData}&isDataExport=${isDataExport}`;
    const response = await fetcherGet(`${url}`, {}, pageDetails);
    if (response.messageKey === "SUCCESS") {
      dispatch(setEventName(response.data));
    } else {
      dispatch(setEventName([]));
    }
    dispatch(setLoader(false));

  };
}

export function FSAreportListing(reportNamePayload) {
  jurisdictionID = getJurisdictionUUID();

  return (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setFSAReportListingData({ isLoading: true, data: [] }));
    fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-list`, { ...reportNamePayload })
      .then((response) => {
        dispatch(setFSAReportListingData({ isLoading: false, data: response?.data }));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setFSAReportListingData({ isLoading: false, data: [] }));
      });
  };
}

export function ConsignmentWiringListing(reportNamePayload) {
  jurisdictionID = getJurisdictionUUID();

  return (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setConsignmentWiringData({ isLoading: true, data: [] }));
    fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-list`, { ...reportNamePayload })
      .then((response) => {
        dispatch(setConsignmentWiringData({ isLoading: false, data: response?.data }));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setConsignmentWiringData({ isLoading: false, data: [] }));
      });
  };
}


export function generateFSAReportXml(payload) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    jurisdictionID = getJurisdictionUUID();
    return fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-generate`, {
      ...payload,
    });
  };
}

export const getLinkForDownloadFSAReport = (downloadLinkId) => (dispatch) => {
  jurisdictionID = getJurisdictionUUID();
  dispatch(setFSAReportLoader(true));
  return fetcherGet(`${APIS.REPORTS_API}/${jurisdictionID}/report-download/${downloadLinkId}`).finally(() => {
    dispatch(setFSAReportLoader(false));
  });
};



export function getJuridictionDetails() {

  return (dispatch) => {

    dispatch(setJuriDictionDetails({ isLoading: true, data: [] }));
    fetcherGet(APIS.GET_JURISDICTIONS, "GET", {}, false)
      .then((response) => {
        dispatch(setJuriDictionDetails({ isLoading: false, data: response?.data }));

      })
      .catch((err) => {
        dispatch(setJuriDictionDetails({ isLoading: false, data: [] }));
      });
  };
}

export function PostReportStatus(reportNamePayload) {
  jurisdictionID = getJurisdictionUUID();
  return async () => fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-status`, {
    ...reportNamePayload,
  });
}


