import { List, Typography } from "@mui/material";
import { AccordionLink } from "components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrentPageName, t } from "utils/constants";

function ManageBudgetSection() {
  const { t: t1 } = useTranslation(getCurrentPageName());

  const selectedJurisdictionId = useSelector(({ jurisdiction }) => jurisdiction.selectedJurisdictionId);
  return (
    <>
      <Typography
        sx={{ fontWeight: 650, textTransform: "uppercase" }}
        variant="h4"
        gutterBottom
      >
        {t(t1, "BUDGET_RECORDS")}
      </Typography>
      <List className="commonlistul">
        <AccordionLink to={selectedJurisdictionId.length && `/budgets/${selectedJurisdictionId}/propose`} customStyle={{width: '100%'}}>
          {`${t(t1, "PROPOSE_BUDGET_RECORDS")}`}
        </AccordionLink>

        <AccordionLink to={selectedJurisdictionId.length && `/budgets/${selectedJurisdictionId}`} customStyle={{width: '100%'}}>
          {`${t(t1, "VIEW_BUDGET_RECORDS")}`}
        </AccordionLink>
      </List>
    </>
  );
}

export default ManageBudgetSection;
