import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ENTITY_TYPE_NAMES,
  formatDate,
  JURISDICTIONS_NAMES,
  t,
  isEmpty
} from "utils/constants";
import Style from "./ApplicationChecklist.style";

const PageName = "Page_ApplicationWithAttenstation";
const EntityRegistrationPage = "Page_EntityRegistration";

function EntityInformation({ entityData }) {
  const {
    entityId,
    entityStatus,
    legalName,
    operatingName,
    jurisdiction,
    entityType,
    applicationDate,
    typeOfOrganization,
    organizationTypeDetails,
    userClassification,
    classificationDetails,
    purposeOfAccount,
    createdByUser,
    proposeChanges
  } = entityData || {};
  
  const { 
    legalName: proposedLegalName, 
    operatingName: proposedOperatingName, 
    organizationTypeDetails: proposedOrganizationTypeDetails,
    typeOfOrganization: proposedTypeOfOrganization,
    purposeOfAccount: proposedPurposeOfAccount,
    userClassification: proposedUserClassification,
    classificationDetails: proposedClassificationDetails
  } = proposeChanges?.entity || {};

  const entityName = proposeChanges?.entity?.entityType?.name || entityType?.name
  const { t: t1 } = useTranslation(PageName);
  const { t: tEntityRegistration } = useTranslation(EntityRegistrationPage);
  
  return <>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "CITSS_ENTITY_ID")}</Typography>
      <Typography sx={Style.entryValue}>{entityId}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "LEGAL_NAME")}</Typography>
      <Typography sx={Style.entryValue}>{proposedLegalName || legalName}</Typography>
    </Box>

    {[ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION, ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY].includes(entityName) ?
      <Box sx={Style.entry}>
        <Typography sx={Style.entryAttribute}>{t(t1, "OPERATING_NAME")}</Typography>
        <Typography sx={Style.entryValue}>{proposedOperatingName || operatingName}</Typography>
      </Box>
      : null}

    {[ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION].includes(entityName) ?
    <>
      <Box sx={Style.entry}>
        <Typography sx={Style.entryAttribute}>{t(tEntityRegistration, "ORGANIZATION_TYPE")}</Typography>
        <Typography sx={Style.entryValue}>{t(tEntityRegistration, proposeChanges?.entity?.typeOfOrganization  ? proposeChanges?.entity?.typeOfOrganization?.type : typeOfOrganization?.type) || "NA"}</Typography>
      </Box>
       {(proposedTypeOfOrganization?.type === "OTHER_PLEASE_SPECIFY_BELOW" || typeOfOrganization?.type === "OTHER_PLEASE_SPECIFY_BELOW") &&
       <Box sx={Style.entry}>
         <Typography sx={Style.entryAttribute}>{t(tEntityRegistration, "OTHER_ORGANIZATION")}</Typography>
         <Typography sx={Style.entryValue}>{(proposedTypeOfOrganization?.type === "OTHER_PLEASE_SPECIFY_BELOW" || typeOfOrganization?.type === "OTHER_PLEASE_SPECIFY_BELOW") ? proposedOrganizationTypeDetails || organizationTypeDetails?.subClassification : "NA"}</Typography>
       </Box>}
       </>
      : null}

    {[ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL].includes(entityName) ?
      <>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(tEntityRegistration, "PURPOSE_OF_ACCOUNT")}</Typography>
          <Typography sx={Style.entryValue}>{t(tEntityRegistration, proposedPurposeOfAccount?.name || purposeOfAccount?.name) || "NA"}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(tEntityRegistration, "USER_CLASSIFICATION")}</Typography>
          <Typography sx={Style.entryValue}>{t(tEntityRegistration, proposedUserClassification?.name || userClassification?.name)}</Typography>
        </Box>
        {(proposedUserClassification?.name === "OTHER_PLEASE_SPECIFY_BELOW" || userClassification?.name === "OTHER_PLEASE_SPECIFY_BELOW") &&
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(tEntityRegistration, "OTHER_CLASSIFICATION")}</Typography>
          <Typography sx={Style.entryValue}>{proposedUserClassification?.name === "OTHER_PLEASE_SPECIFY_BELOW" || userClassification?.name === "OTHER_PLEASE_SPECIFY_BELOW" ? proposedClassificationDetails || classificationDetails?.subClassification : "NA"}</Typography>
        </Box>}
      </>
      : null}

    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "JURISDICTION")}</Typography>
      <Typography sx={Style.entryValue}>{t(tEntityRegistration, jurisdiction?.name) || "NA"}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "ENTITY_TYPE")}</Typography>
      <Typography sx={Style.entryValue}>{t(tEntityRegistration, entityName)}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "ENTITY_STATUS")}</Typography>
      <Typography sx={Style.entryValue}>{t(tEntityRegistration, entityStatus?.name) || "NA"}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "CREATED_BY")}</Typography>
      <Typography sx={Style.entryValue}>{`${createdByUser?.firstName} ${createdByUser?.lastName}`}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "DATE_CREATED")}</Typography>
      <Typography sx={Style.entryValue}>{formatDate(applicationDate)}</Typography>
    </Box>
  </>;
}

export default EntityInformation;
