import authRoles from "core/auth/authRole";
import ConfigurationSettings from "./ConfigurationSettings";

const ConfigurationSettingsConfig = {
  route: {
    path: "/settings",
    element: <ConfigurationSettings />,
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.onlyGuest,
};

export default ConfigurationSettingsConfig;
