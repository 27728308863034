import { createSlice } from "@reduxjs/toolkit";

import { APIS, COUNTRY_KEY_NAMES, SUCCESS } from "utils/constants";
import fetcher from "utils/fetcher";

/* Conflict Resolution Candidate
import { setLoader, setModelMessage } from "./commonSlice";
 */
import { setLoader, setModelMessage } from "./commonSlice";

const initialState = {
  submitted: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    editMode: 0, // 0 means not in edit mode, >0 means in edit mode
  },
  filledData: {
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
  },
  formSteps: {
    activeStep: 1,
    isEditField: {
      1: false,
      2: false,
      3: false,
      4: false,
    },
  },
  userRegCaptcha: false,
  userReferenceCode: "",
  isValid: "",
  countries: {},
  states: [],
  primaryChecked: "",
  initialPrimaryChecked: "",
  userProfileEdit: false,
  filledDataUserProfile: {},
  isDocReceived: false,
  /* Conflict Resolution Candidate
  redirectionPath: {}
};

export const createUserProfileSlice = createSlice({
 */
  redirectionPath: "",
  prePopulatedDetails: { isLoading: false, data: null },
  isLinkExpired: false,
};

const createUserProfileSlice = createSlice({
  name: "createUserProfile",
  initialState,
  reducers: {
    setSubmittedState: (state, action) => {
      state.submitted[action.payload.step] = action.payload.data;
    },
    setFilledData: (state, action) => {

      if(action.payload.data.date !== undefined && typeof action.payload.data.date !== 'string') {
        // extracting day, month and year from date of birth to fill out in the form
        action.payload.data.day = action.payload.data.date.getDate()
        action.payload.data.month = action.payload.data.date.getMonth()+1 // getMonth returns month from 0 to 11, so adding 1
        action.payload.data.year = action.payload.data.date.getFullYear()

        // Date of birth, which is stored in a Date object in the payload, is not serializable. So converting it to ISO string
        action.payload.data.date = action.payload.data.date.toISOString()
      }

      state.filledData[action.payload.step] = action.payload.data;
    },
    setActiveStep: (state, action) => {
      state.formSteps.activeStep = action.payload;
    },
    setNextActiveStep: (state) => {
      state.formSteps.activeStep += 1;
    },
    updateEditFieldState: (state, action) => {
      state.formSteps.isEditField[action.payload.step] = action.payload.value;
    },
    resetCreaeUserProfileState: () => initialState,
    setReferenceCode: (state, action) => {
      state.userReferenceCode = action.payload;
    },
    setValidStatus: (state, action) => {
      state.isValid = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setState: (state, action) => {
      state.states[action.payload.type === COUNTRY_KEY_NAMES.UNITED_STATES ? 0 : 1] = action.payload.states
    },
    setPrimaryChecked: (state, action) => {
      state.primaryChecked = action.payload;
    },
    setInitialPrimaryChecked: (state, action) => {
      state.initialPrimaryChecked = action.payload;
    },
    setUserRegCaptcha: (state, action) => {
      state.userRegCaptcha = action.payload;
    },
    updateEditFieldUserProfileState: (state, action) => {
      state.userProfileEdit = action.payload;
    },
    setFilledDataUserProfile: (state, action) => {
      state.filledDataUserProfile = action.payload.data;
    },
    setPhysicalDocumentReceived: (state, action) => {
      state.isDocReceived = action.payload;
    },
    setRedirectionPath: (state, action) => {
      state.redirectionPath = action.payload;
    },
    setEditMode: (state, action) => {
      state.submitted.editMode += action.payload;
    },
    setPrePopulatedDetails: (state, action) => {
      state.prePopulatedDetails = action.payload
    },
    setIsLinkExpired: (state, action) => {
      state.isLinkExpired = action.payload;
    }
  },
});

export function validateUser(params) {
  return (dispatch) => {
    dispatch(setLoader(true));
    const parmas = (params.username) ? `username=${params.username.toLowerCase()}` : `email=${params.email.toLowerCase()}`
    fetcher(`auth/validateuser?${parmas}`, "GET", params, false)
      .then((response) => {

        if (response.Users && response.Users[0].Enabled === true) {
          dispatch(setValidStatus(false))
        } else {
          dispatch(setValidStatus(true));
        }
        // setTimeout(() => {
        dispatch(setLoader(false));
        // },1000)
      })
      .catch((err) => {
        dispatch(setLoader(false));
        
      });
  };
}

export default createUserProfileSlice.reducer;

export function saveContactUsInfo(params, OrgID) {
  return (dispatch) => {
    dispatch(setLoader(true))
    fetcher(`publicconfig/contactus?OrgID=${OrgID.replace("#", "%23")}`, "POST", params, false)
      .then((response) => {
        dispatch(setLoader(false))
        const data = {
          title: SUCCESS,
          message1: (response) ? response.message : '',
          error: false
        }
        dispatch(setModelMessage(data));
      })
      .catch((err) => {
        dispatch(setLoader(false))
        
        const data = {
          title: "Failure!",
/* Conflict Resolution Candidate
          message1: (err) ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
 */
          message1: (err) ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setModelMessage(data));
      });
  };
}

export function getCountries(type = "ALL") {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`public/country?country=${type}`, "GET", {}, false)
      .then((response) => {
        if (type === 'ALL') {
          dispatch(setCountries(response));
        } else {
          dispatch(setState({ states: response, type }));
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        

      });
  };
}

export function saveDocumentReceived(UID, params) {
  return (dispatch) => {
    // console.log(UID, params);
    dispatch(setLoader(true));
    const pageDetails = { pageName: "Page_JurisdictionUserActions", componentName: "physicalDocumentReceived" };
    fetcher(`actionrequest/DocumentReceived/${UID}`, "PUT", params, false, pageDetails)
      .then((response) => {
        dispatch(setPhysicalDocumentReceived(params));
        const data = {
          title: SUCCESS,
          message1: (response) ? response.message : '',
          error: false
        }
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: "Failure!",
/* Conflict Resolution Candidate
          message1: (err) ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
 */
          message1: (err) ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setModelMessage(data));
      });
  };
}

export const fetchUserDetailsByLinkId = (linkId) => (dispatch) => {
    dispatch(setPrePopulatedDetails({ isLoading: true, data: null }));
    dispatch(setLoader(true));
    fetcher(`${APIS.FETCH_USER_DETAILS}/${linkId}`, "GET", {}, false, {})
      .then((response) => {
        dispatch(setPrePopulatedDetails({ isLoading: false, data: response.data }));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        dispatch(setPrePopulatedDetails({ isLoading: false, data: null }));
        dispatch(setIsLinkExpired(true));
      });
  }

export const {
  setActiveStep,
  setNextActiveStep,
  setFilledData,
  setSubmittedState,
  updateEditFieldState,
  resetCreaeUserProfileState,
  setReferenceCode,
  setValidStatus,
  setCountries,
  setState,
  setPrimaryChecked,
  setUserRegCaptcha,
  setInitialPrimaryChecked,
  updateEditFieldUserProfileState,
  setFilledDataUserProfile,
  setPhysicalDocumentReceived,
  setRedirectionPath,
  setEditMode,
  setPrePopulatedDetails,
  setIsLinkExpired,
} = createUserProfileSlice.actions;
