import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  FormControlLabel,
  Radio,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { FormCard, GridTextfield } from "components";
import { ModelMessage } from "components/ModelMessage";
import { setModelMessage } from "store/commonSlice";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PageAccess from "components/PageAccess/PageAccess";
import {
  getRolesDetails,
  getPageRoleDetail,
  getRoles,
  postRoleDetails,
  updateRole,
  setSelectedRole,
  setRoleDetails,
} from "store/privilegeSlice";
import { CustomizeButton } from "components/CustomizeButton";
/* Conflict Resolution Candidate
import { getCurrentPageName, isEmpty, PAGES, t } from "utils/constants";
 */
import { getCurrentPageName, PAGES, t } from "utils/constants";
import { useAuthentication } from "hooks/useAuthentication";
import { CustomizeTextfield } from "../../../../components/CustomizeTextfield";
import  RoleDetailBoxCard  from "../RoleDetailBoxCard/RoleDetailBoxCard";
import Style from "../../styles";

function RoleDetails({ type }) {
  const location = useLocation();
  // useAuthentication({ redirectTo: location });
  const defaultValues = {};
  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues,
  });

  const { handleSubmit, watch, resetField } = methods;

/* Conflict Resolution Candidate
  // const watchRoleType = watch("RoleType");
  // const watchRoleName = watch("role_name");
  // const watchRoleDesc = watch("priv_txt_RoleDesc");
 */
  const watchRoleType = watch("RoleType");
  const watchRoleName = watch("role_name");
  const watchRoleDesc = watch("priv_txt_RoleDesc");

  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectValue, setSelectValue] = React.useState("");
  const [updatedPage, setUpdatedPages] = React.useState([]);
  const [roleList, setRoleList] = useState(null);

  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;
  const { roleDetails, role, roles, addRole, postRole, loading, Pages } = useSelector(({ privilege }) => privilege);
  const privilegeData = useSelector(({ privilege }) => privilege);
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const [isAllReviewed, setIsAllReviewed] = useState(false);

  useEffect(() => {
    dispatch(setSelectedRole(location?.state?.data));
/* Conflict Resolution Candidate
    const remaingReviewPages = addRole?.find((role) => role.IsReviewed === false || role.IsReviewed === undefined);
    if (remaingReviewPages === undefined && addRole.length > 0 && localStorage.getItem("jurisdiction").replace("%23", "#") === "ORG#00100") {
      setIsAllReviewed(true);
    }
    else
    {
      setIsAllReviewed(false);
    }
  }, [addRole]);

  useEffect(() => {
    dispatch(setSelectedRole(location?.state?.data));
  }, []);

  useEffect(() => {
    if (roleDetails) {
      if (type !== "add" || !isEmpty(role?.roleType)) {
        setRoleList(roleDetails);
        setDefaultValues({
          RoleType: roles.find((v) => v.name === role?.roleType?.name),
          role_name: role?.name,
          priv_txt_RoleDesc: role?.description,
        });
      } else {
 */
    const remaingReviewPages = addRole?.find((role) => (role.IsReviewed === false || role.IsReviewed === undefined));
    if (!remaingReviewPages && addRole.length > 0 && selectedJurisdiction.replace("%23", "#") === "ORG#00100")
      setIsAllReviewed(true)
  }, [addRole])

  useEffect(() => {
    dispatch(setSelectedRole(location?.state?.data));
  }, [])

  useEffect(() => {
    if (roleDetails) {
      if (type !== "add") {
        setRoleList(roleDetails);
      }
      else {
        setRoleList(Pages);
      }
      // var limited = data.filter((val,i)=>i<10)
    }
  }, [Pages, roleDetails, type]);

  useEffect(() => {
    if (type !== "add") {
      if (role && role.id)
        dispatch(
          getRolesDetails(
            typeof selectedJurisdiction === "object" ? "" : selectedJurisdiction,
            role?.id ? role?.id : "Pages"
          )
        );
      setSelectValue(role?.roleType);
/* Conflict Resolution Candidate
    } else if (!role?.roleType && isEmpty(role?.roleType)){
      dispatch(getPageRoleDetail());
    } else {
      setSelectValue(role?.roleType);
 */
    } else {
      dispatch(getPageRoleDetail());
    }
  }, [selectedJurisdiction, role, type, dispatch]);

  const setDefaultValues = (values) => {
    if (values) {
      methods?.reset({
        ...defaultValues,

/* Conflict Resolution Candidate
        ...values,
      });
      setSelectValue(values?.RoleType);
    } else {
      methods?.reset({
        ...defaultValues,
      });
 */
        ...values
      })
    } else {
      methods?.reset({
        ...defaultValues,
      })
    }
  };

  const filterObject = (obj, value) => {
    // eslint-disable-next-line no-sequences
    const newObj = Object.keys(obj)
      .filter((i) => i === value)
      // eslint-disable-next-line no-sequences
      .reduce((res, key) => ((res[key] = obj[key]), res), {});
    return newObj;
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      // console.log(e.keyCode, "This is keycode")
      handleSearch();
    }
  };

  const handleSearch = () => {
    const pageSearch = watch("priv_txt_SearchPage");
    const controlSearch = watch("priv_txt_SearchControl");
    let filteredItems = privilegeData?.Pages?.Items;
    if (!pageSearch && !controlSearch) {
      setRoleList(privilegeData?.Pages);
    } else {
      if (pageSearch) {
        filteredItems = filteredItems?.filter((role) => (pageSearch ? role?.SK?.toLowerCase() === pageSearch?.toLowerCase() : role));
      }
      if (controlSearch) {
        filteredItems = filteredItems?.map((i) => {
          const controlFilteredItems = filterObject(i?.PrivAttributes, controlSearch);
          return Object.keys(controlFilteredItems).length !== 0 && { ...i, PrivAttributes: controlFilteredItems };
        });
      }
      filteredItems = filteredItems?.filter((i) => i !== false);
      setRoleList({
        ...roleList,
        Items: filteredItems,
        Count: filteredItems?.length,
        ScannedCount: filteredItems?.length,
      });
    }
  };

  const handleReset = () => {
    resetField("priv_txt_SearchControl");
    resetField("priv_txt_SearchPage");
    handleSearch();
  };

  // useEffect(() => {
  //   dispatch(getRolesDetails(typeof(selectedJurisdiction) === "object" ? '': selectedJurisdiction));
  // }, [dispatch]);

  const onSubmit = (data) => {
    delete data.roleDetail;

    delete data.pageSearch;
    delete data.controlSearch;
    delete data.roleDesc;
    delete data.roleName;

    let roleCriteria = addRole;
    if (roleCriteria.length <= 0) {
      roleCriteria = roleList.Items;
    }
    const roleLists = roleCriteria?.map((role) => ({
/* Conflict Resolution Candidate
      page_name: (type === "add" && isEmpty(role?.roleType)) ? role?.SK : role?.PageName,
 */
      page_name: type === "add" ? role.SK : role.PageName,
      is_reviewed: role.IsReviewed || false,
      priv_attr: role.PrivAttributes,
      pageId: role.pageId,
      pageJrrId: role.pageJrrId
    }));
    const payload = {
      // ...data,
      role_name: data.role_name,
      role_desc: data.priv_txt_RoleDesc,
      ...(type === "add" && { role_type_id: data.RoleType.id }), // Added role type uuid after migrating to postgres
      role_type: data.RoleType.name,
      org_id: selectedJurisdiction.replace("%23", "#"),
      role_criteria: roleLists,
      // UpdatedPages: updatedPage,
/* Conflict Resolution Candidate
      attributestatus:
        isAllReviewed && selectedJurisdiction.replace("%23", "#") === "ORG#00100" ? "Publish" : "Pending",
    };
    const roleId = roleCriteria[0]?.SK?.split("#")?.slice(0, -1)?.join("#");
    // if (role === undefined) {
    const roleObject = {
      name: payload?.role_name,
      description: payload?.role_desc,
      id: roleId,
      attributestatus: payload?.attributestatus,
      roleType: payload?.role_type,
    };
    dispatch(setSelectedRole(roleObject));
 */
      // attributestatus: isAllReviewed && selectedJurisdiction.replace("%23", "#") === "ORG#00100" ? "Publish" : "Pending"
    };
    const roleId = roleCriteria[0]?.SK?.split('#')?.slice(0, -1)?.join('#');
    // if (role === undefined) {
      const roleObject = {
        name: payload?.role_name,
        description: payload?.role_desc,
        id: roleId,
        attributestatus: payload?.attributestatus,
        roleType: payload?.role_type
      }
      dispatch(setSelectedRole(roleObject));
    // }
    if ((role?.id || roleId !== "role") && location.pathname !== "/create-role-details") {
      const callBackFun = () => {
        if (location.pathname === "/edit-role-details") {
          // dispatch(setSelectedRole(location?.state?.data));
          navigate("/role-details", { state: { data: roleObject } });
        }
      }

      if (isAllReviewed && selectedJurisdiction.replace("%23", "#") === "ORG#00100")
        dispatch(updateRole(role?.id || roleId, true, payload, callBackFun));
      else
        dispatch(updateRole(role?.id || roleId, false, payload, callBackFun));
    } else {
      const callBackFun = () => {
        setDefaultValues({
          RoleType: '',
          role_name: '',
          priv_lbl_RoleDesc: '',
        });
        setSelectValue("");
        if (location.pathname === "/create-role-details") {
          navigate(location.pathname);
        }
/* Conflict Resolution Candidate
      };
      if (
        isAllReviewed &&
        selectedJurisdiction.replace("%23", "#") === "ORG#00100"
      )
        dispatch(postRoleDetails(true, payload, callBackFun));
      else dispatch(postRoleDetails(false, payload, callBackFun));
 */
      }
      if (isAllReviewed && selectedJurisdiction.replace("%23", "#") === "ORG#00100" && role.attributestatus === "Pending")
        dispatch(postRoleDetails(true, payload, callBackFun));
      else
        dispatch(postRoleDetails(false, payload, callBackFun));
    }

    const list = {};
    list.roleType = payload.role_type;
    list.name = payload.role_name;
    list.name = payload.role_name;
    list.description = payload.role_desc;
    list.id = role?.id;
    list.attributestatus = "Active";
    dispatch(setSelectedRole(list));
    // navigate("/role-details");
  };

  const handleChangeRoleType = (value) => {
    setSelectValue(value);
  };

  useEffect(() => {
    dispatch(getRoles(selectedJurisdiction));
  }, [selectedJurisdiction])

/* Conflict Resolution Candidate
  const cancelDisabled = false;
 */
  const cancelDisabled = !watchRoleType && !watchRoleName && !watchRoleDesc

  return (
    <Container maxWidth="xl" sx={Style.spacing}>
      <Grid container spacing={2.5}>
        <Grid item sm={12}>
          <div className="titalwithbtn">
            <Typography variant="h3">{t(t1, "PM_H1_PRIVILEGE_MANAGEMENT")}</Typography>
          </div>
        </Grid>
        <Grid item sm={12}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box component="form" noValidate autoComplete="off">
                <FormCard indicateRequired={false}>
                  <Typography variant="h6">{t(t1, "SEARCH_PRIV_HEADER")}</Typography>
                  <Grid container spacing={0}>
                    <GridTextfield
                      gridStyles={{ marginRight: '22px', maxWidth: '372px !important' }}
                      customeStyle={{ fontWeight: '400 !important' }}
                      name="priv_txt_SearchControl"
                      placeholder={t(t1, `PM_PH_ENTER_CONTROL_NAME`)}
                      label={t(t1, "PM_LBL_SEARCH_CONTROL")}
                      onKeyDown={handleOnKeyDown}
                    />
                    <GridTextfield
                      customeStyle={{ fontWeight: "400 !important" }}
                      name="priv_txt_SearchPage"
                      placeholder={t(t1, `PM_PH_ENTER_PAGE_NAME`)}
                      label={t(t1, "PM_LBL_SEARCH_PAGE")}
                      onKeyDown={handleOnKeyDown}
                    />
                    <Grid item sm={12}>
                      <div className="bottom-btn">
                        <CustomizeButton
                          variant="outlined"
                          csName="rc"
                          name="priv_btn_Reset"
                          handleClick={handleReset}
                          label={t(t1, "PM_BTN_RESET")}
                        />
                        <CustomizeButton
                          variant="contained"
                          handleClick={handleSearch}
                          name="priv_btn_Search"
                          label={t(t1, "PM_BTN_SEARCH")}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </FormCard>
              </Box>
              <RoleDetailBoxCard
                cancelDisabled={cancelDisabled}
                setDefaultValues={setDefaultValues}
                roledetails={role}
                roles={roles}
                selectValue={selectValue}
                type={type}
                setSelectValue={handleChangeRoleType}
                isAllReviewed={isAllReviewed}
/* Conflict Resolution Candidate
                methods={methods}
 */
              />
            </form>
          </FormProvider>
        </Grid>

        <PageAccess
          name="priv_grd_PageControls"
          label={t(t1, "DEFAULT_PAGES_AND_CONTROLS")}
          roleDetails={roleList}
/* Conflict Resolution Candidate
          type={!isEmpty(role?.roleType)? 'edit': 'add'}
 */
          type={type}
          userRole={PrivAttributes}
          loader={loading}
          updatedPage={updatedPage}
          setUpdatedPages={setUpdatedPages}
          isExport
        />
        <Grid item sm={12}>
          {/* {PrivAttributes?.priv_lnkBtn_ExportToCSV?.PrivRole === "RWX" &&
        (<Link className="export-link" to="/">
            {t(t1, "EXPORT_CSV_TXT")}
          </Link>)} */}
        </Grid>
      </Grid>
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
}

export default RoleDetails;