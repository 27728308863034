import { Loadable } from "components/Loadable";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { lazy } from "react";
import InitialUserWrapper from "./InitialUserWrapper";

const ManageInitialUser = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.ManageInitialUser,
    }))
  )
);

const AddInitialUser = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.AddInitialUser,
    }))
  )
);

const EditInitialUser = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EditInitialUser,
    }))
  )
);

export const setUpInitialUsersRoutes = {
  path: "/set-up-initial-users",
  children: [
    {
      index: true,
      path: ":jurisdictionId",
      element: (
        <RoleBasedGuard privId={""}>
          <ManageInitialUser />
        </RoleBasedGuard>
      ),
    },
    {
      path: "add/:jurisdictionId",
      element: <AddInitialUser />,
    },
    {
      path: "edit/:jurisdictionId/:initialUserId",
      element: (
        <InitialUserWrapper>
          <EditInitialUser />
        </InitialUserWrapper>
      ),
    },
  ],
};
